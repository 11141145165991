import { createSelector } from 'reselect';
import { ClientReducerState } from 'client/reducers';
import { selectCardId } from 'client/reducers/card/selectors';

export const selectUser = (state: ClientReducerState) => state.user;

export const selectUserCountry = createSelector(selectUser, user => user.country);

export const selectUserAnswers = createSelector(selectUser, user => user.answers);

export const selectUserAnswersByCard = createSelector(
	[selectUserAnswers, (state: ClientReducerState, cardId?: string) => cardId ?? selectCardId(state)],
	(answers, cardId) => (answers !== undefined && cardId !== undefined ? answers[cardId] : undefined)
);

export const selectTotalAnswersByCard = createSelector(
	(state: ClientReducerState, cardId?: string) => selectUserAnswersByCard(state, cardId ?? selectCardId(state)),
	answers => answers?.selected.length ?? 0
);
