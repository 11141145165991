import _ from 'lodash';
import React, { Component } from 'react';
import type { HandleThunkActionCreator } from 'react-redux';

import type { CardData, CardTemplateWithoutData } from 'types/story';
import { getCardTemplates } from 'admin/actions/story/templates';
import { CardFacade } from 'common/utils/facades/card-facade';
import { CARD_TYPE } from 'common/constants';
import t from 'common/utils/translate';
import Tooltip, { ThirdPartyCookiesTooltipContent } from 'admin/components/common/Tooltip';
import Text from 'admin/components/common/Text';
import Button from 'admin/components/common/Button';
import { Search } from 'admin/components/common/Search';
import Layout from 'admin/components/pages/Stories/CreateStoryModal/ShareComponents/Layout';
import TemplateItem from 'admin/components/pages/Stories/CreateStoryModal/ShareComponents/TemplateItem';

import TemplateGalleryFilters, {
	getCardTypeFromGroupId,
	ID_PREFIX,
	isOrganizationGroup,
	ORG_GROUP_POSTFIX,
} from './TemplateGalleryFilters';

import { DEFAULT_LAST_CARD_TYPE } from './utils';
import css from './CreateCardModal.scss';

type GroupItem = { name: string; items: CardTemplateWithoutData[] };

type CardType = CardData['type'];

type Props = {
	hidden?: boolean;
	getTemplates: HandleThunkActionCreator<typeof getCardTemplates>;
	onStartFromScratchBtnClick: (type: CardType) => void;
	onTemplateAddClick: (templateId: CardTemplateWithoutData['id']) => void;
	onTemplateOpen: (templateId: CardTemplateWithoutData['id']) => void;
	organizationName: string;
	organizationId: string;
	lastCardType: CardType;
	allowedTypes: CardType[];
	canUseThirdPartyCookies: boolean;
};

type State = {
	templates: CardTemplateWithoutData[];
	search: string;
	availableTypes: { general: string[]; organization: string[] };
};

class TemplateGallery extends Component<Props, State> {
	filtersRef: React.RefObject<TemplateGalleryFilters> = React.createRef();

	templatesByGroupsRef: React.RefObject<HTMLDivElement> = React.createRef();

	searchTimeoutId: ReturnType<typeof setTimeout> | null = null;

	organizationTemplatesIds: string[] = [];

	public readonly state: State = {
		templates: [],
		availableTypes: { general: [], organization: [] },
		search: '',
	};

	async componentDidMount() {
		const { result: beTemplates } = await this.props.getTemplates();
		const feTemplates: State['templates'][number][] = [];

		if (!beTemplates) return;

		this.organizationTemplatesIds = beTemplates.sorted?.organization || [];
		const next = [...beTemplates.templates, ...feTemplates];
		const availableTypes = _.reduce(
			this.props.allowedTypes,
			(acc: { general: string[]; organization: string[] }, type: CardType) => {
				const sortedTemplatesIdsByType = beTemplates.sorted?.categories[type];

				if (
					sortedTemplatesIdsByType &&
					_.size(
						_.filter(sortedTemplatesIdsByType, (id: string) => this.organizationTemplatesIds.includes(id))
					) > 0
				) {
					acc.organization.push(type);
				}

				if (sortedTemplatesIdsByType) {
					acc.general.push(type);
				}

				return acc;
			},
			{ general: [], organization: [] }
		);

		this.setState({ templates: next, availableTypes }, () => {
			this.templatesByGroupsRef.current?.scrollTo(0, 0);
		});
	}

	componentWillUnmount() {
		if (this.searchTimeoutId) {
			clearTimeout(this.searchTimeoutId);
		}
	}

	get enabledTypes() {
		if (this.state.search) {
			return _.reduce(
				this.currentTemplates,
				(acc: { general: string[]; organization: string[] }, item: GroupItem[], key: string) => {
					_.forEach(item, data => {
						if (data.items.length !== 0) {
							acc[key].push(getCardTypeFromGroupId(data.name));
						}
					});
					return acc;
				},
				{ general: [], organization: [] }
			);
		}

		return this.state.availableTypes;
	}

	get currentTemplates(): { general: GroupItem[]; organization: GroupItem[] } {
		const { templates, search } = this.state;

		return _.reduce(
			this.props.allowedTypes,
			(acc: { general: GroupItem[]; organization: GroupItem[] }, type: string) => {
				if (_.size(this.organizationTemplatesIds) > 0) {
					acc.organization.push({
						name: `${type}${ORG_GROUP_POSTFIX}`,
						items: _.filter(templates, (template: State['templates'][number]) => {
							return (
								template.categories.includes(type) &&
								_.toLower(template.name).includes(_.toLower(search)) &&
								this.organizationTemplatesIds.includes(template.id)
							);
						}),
					});
				}

				acc.general.push({
					name: type,
					items: _.filter(templates, (template: State['templates'][number]) => {
						return (
							template.categories.includes(type) &&
							_.toLower(template.name).includes(_.toLower(search)) &&
							!this.organizationTemplatesIds.includes(template.id)
						);
					}),
				});
				return acc;
			},
			{ general: [], organization: [] }
		);
	}

	get isOrgTemplatesExists() {
		return this.state.templates.some((elem: any) => elem.organizationId === this.props.organizationId);
	}

	onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (this.searchTimeoutId) {
			clearTimeout(this.searchTimeoutId);
		}

		this.setState({ search: e.target.value }, () => {
			this.searchTimeoutId = setTimeout(() => {
				if (this.templatesByGroupsRef?.current?.scrollTop === 0) {
					this.filtersRef.current?.onScroll();
				} else {
					this.templatesByGroupsRef.current?.scrollTo({ top: 0, left: 0 });
				}
			}, 300);
		});
	};

	onSearchClear = () => {
		this.setState({ search: '' });
	};

	onStartFromScratchBtnClick = () => {
		this.props.onStartFromScratchBtnClick(this.filtersRef?.current?.activeCardType ?? DEFAULT_LAST_CARD_TYPE);
	};

	renderTemplateItem = (item: State['templates'][number]) => {
		const isDisabled = item.categories.includes(CARD_TYPE.REGISTRATION) && !this.props.canUseThirdPartyCookies;

		return isDisabled ? (
			<Tooltip
				isStatic
				stylePreset="dark-1"
				destroyTooltipOnHide
				key={`template-${item.id}`}
				placement="top"
				content={ThirdPartyCookiesTooltipContent}
			>
				<div>
					<TemplateItem type="card" item={item} onPreviewClick={_.noop} />
				</div>
			</Tooltip>
		) : (
			<TemplateItem
				key={`template-${item.id}`}
				type="card"
				item={item}
				onAddClick={this.props.onTemplateAddClick}
				onPreviewClick={this.props.onTemplateOpen}
			/>
		);
	};

	renderTemplatesGroup = (group: GroupItem, index: number) => {
		const { organizationName } = this.props;

		if (_.size(group.items) === 0) {
			return null;
		}

		const title = CardFacade.getCardNameByType(getCardTypeFromGroupId(group.name));

		return (
			<div key={`template-group-${index}`} id={`${ID_PREFIX}${group.name}`} className={css.templateGroup}>
				<Text
					className={css.templateGroupTitle}
					color={Text.color.blackDark}
					transform={Text.transform.uppercase}
					compact
				>
					{isOrganizationGroup(group.name) ? `${title} ${_.toUpper(organizationName)}` : title}
				</Text>
				<div className={css.templateList}>{_.map(group.items, this.renderTemplateItem)}</div>
			</div>
		);
	};

	render() {
		const { organizationName, lastCardType } = this.props;
		const { availableTypes } = this.state;
		const { currentTemplates } = this;
		const defaultType = (currentTemplates.organization[0] || currentTemplates.general[0])?.name || lastCardType;

		if (this.props.hidden) {
			return null;
		}

		return (
			<>
				<Layout.Panel>
					<Search
						value={this.state.search}
						onSearchChange={this.onSearch}
						placeholder={`${t('story.createCardModal.searchPlaceholder')}...`}
						className={Layout.className.colTwoThirds}
						onClear={this.onSearchClear}
					/>
				</Layout.Panel>
				<Layout.Aside className={Layout.className.pt35}>
					{_.size(this.state.templates) > 0 ? (
						<TemplateGalleryFilters
							ref={this.filtersRef}
							defaultType={defaultType}
							availableTypes={availableTypes}
							enabledTypes={this.enabledTypes}
							organizationName={this.isOrgTemplatesExists ? organizationName : ''}
							scrollingContainerRef={this.templatesByGroupsRef}
						/>
					) : (
						<span />
					)}

					<Button smallText onClick={this.onStartFromScratchBtnClick}>
						{t('story.createCardModal.startFromScratch')}
					</Button>
				</Layout.Aside>
				<Layout.Content className={Layout.className.pt35} ref={this.templatesByGroupsRef}>
					{_.map(currentTemplates.organization, this.renderTemplatesGroup)}
					{_.map(currentTemplates.general, this.renderTemplatesGroup)}
				</Layout.Content>
			</>
		);
	}
}

export { TemplateGallery };
