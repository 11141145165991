/* eslint-disable consistent-return */

import { Action } from 'redux';
import { AdminThunkAction } from 'admin/reducers';
import { updateStoryUrl as updateStoryUrlEndpoint } from 'admin/resources';
import { UPDATE_STORY_URL, SET_LOADING } from 'admin/constants/actions';

export function updateStoryUrl(props: {
	storyId: string;
	clientStoryId: string;
}): AdminThunkAction<Action, IBackendBody<null> | IBackendResponseError['response']['body'] | null> {
	return async (dispatch, getState) => {
		let result: IBackendResponseError['response']['body'] | IBackendBody<null> | null = null;

		if (getState().version.current !== 'latest') {
			return null;
		}

		dispatch({ type: UPDATE_STORY_URL.UPDATE });
		dispatch({ type: SET_LOADING, payload: { [UPDATE_STORY_URL.UPDATE]: true } });

		try {
			const [, clientStoryId] = props.clientStoryId.split('/');
			const response: Response = await updateStoryUrlEndpoint
				.params({ storyId: props.storyId })
				.send({ clientStoryId });

			result = {
				success: response.status === 200,
				result: null,
			};

			dispatch({ type: UPDATE_STORY_URL.SUCCESS, payload: props.clientStoryId });
		} catch (error) {
			dispatch({ type: UPDATE_STORY_URL.FAIL, payload: error });
			result = (error as IBackendResponseError)?.response.body;
		}

		dispatch({ type: SET_LOADING, payload: { [UPDATE_STORY_URL.UPDATE]: false } });

		return result;
	};
}
