/* eslint-disable no-useless-escape */

import React, { FC } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import _ from 'lodash';

import t from 'utils/translate';
import { passwordRegExp } from 'utils/regexp';
import { FORM_MODEL } from 'admin/constants/common';

export type RestorePasswordValues = { password: string };

type Props = {
	className?: string;
	children?: React.ReactNode;
	onSubmit: (values: RestorePasswordValues) => void;
};

const Form: FC<Props & InjectedFormProps<RestorePasswordValues, Props>> = props => {
	return (
		<form className={props.className} onSubmit={props.handleSubmit(props.onSubmit)}>
			{props.children}
		</form>
	);
};

export default reduxForm<RestorePasswordValues, Props>({
	validate: values => {
		const errors: Partial<RestorePasswordValues> = {};
		const _t = (label: string) => t(`restorePassword.${label}`);

		if (!_.get(values, 'password')) {
			_.set(errors, 'password', _t('password.required'));
		} else if (!passwordRegExp.test(_.get(values, 'password'))) {
			_.set(errors, 'password', _t('password.pattern'));
		}

		return errors;
	},
	destroyOnUnmount: true,
	form: FORM_MODEL.RESTORE_PASSWORD,
})(Form);
