import React, { useState } from 'react';
import { useAdminDispatch } from 'admin/reducers';
import type { SpreadsheetRange } from 'types/story';
import Button from 'admin/components/common/Button';
import { Select, TextField } from 'admin/components/common/Form';
import { Column, Grid } from 'admin/components/common/Grid';
import { Icon } from 'admin/components/common/Icon';
import PopConfirm from 'src/routes/admin/components/common/PopConfirm';
import { THEME } from 'admin/components/common/Theme';
import { putSpreadsheetRange, SpreadsheetDocument, deleteSpreadsheetRange } from 'admin/actions/integrations';
import css from './Spreadsheet.scss';

type Props = {
	range: SpreadsheetRange;
	integrationId: string;
	storySheetIntegrationId: string;
	theme: ValuesType<typeof THEME>;
	spreadsheet: SpreadsheetDocument;
	onSave: () => Promise<unknown>;
	sheetOptions: { value: number; label: string }[];
	validateName: (name: string) => string | undefined;
	validateValue: (value: string) => string | undefined;
	isExport: boolean;
};

const RangeEdit: React.FC<Props> = props => {
	const original = {
		name: props.range.label,
		value: props.range.range,
		sheetId: props.range.sheetId,
	};

	const dispatch = useAdminDispatch();
	const [name, setName] = useState(original.name ?? '');
	const [value, setValue] = useState(original.value ?? '');
	const [loading, setLoading] = useState<Nullable<'save' | 'delete'>>(null);
	const [sheet, setSheet] = useState(
		props.spreadsheet.sheets.find(o => o.properties.sheetId === original.sheetId)?.properties
	);

	const isNameChanged = name !== original.name;
	const isValueChanged = value !== original.value;
	const isSheetIdChanged = sheet?.sheetId !== original.sheetId;

	const nameError = isNameChanged ? props.validateName(name) : undefined;
	const valueError = isValueChanged ? props.validateValue(value) : undefined;

	const isChanged = isNameChanged || isValueChanged || isSheetIdChanged;
	const isDisabled = loading !== null || !sheet || !value || !isChanged || !!nameError || !!valueError;

	const onSheetChange = (sheetId: number) => {
		const nextSheet = props.spreadsheet.sheets.find(s => s.properties.sheetId === sheetId);
		if (nextSheet) setSheet(nextSheet.properties);
	};

	const onSaveClick = async () => {
		setLoading('save');

		const range = {
			id: props.range.id,
			sheetId: sheet!.sheetId,
			sheetName: sheet!.title,
			label: name,
			range: value.toUpperCase(),
		};

		const response = await dispatch(
			putSpreadsheetRange({
				integrationId: props.integrationId,
				spreadsheetId: props.storySheetIntegrationId,
				data: { range },
			})
		);

		if (response.success) {
			await props.onSave();
		}

		setLoading(null);
	};

	const onDeleteClick = async () => {
		setLoading('delete');

		const response = await dispatch(
			deleteSpreadsheetRange({
				integrationId: props.integrationId,
				spreadsheetId: props.storySheetIntegrationId,
				rangeId: props.range.id.toString(),
			})
		);

		if (response.success) {
			await props.onSave();
		}

		setLoading(null);
	};

	const onEnterClick = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter' && !isDisabled) {
			event.preventDefault();
			onSaveClick();
		}
	};

	return (
		<Grid columns={props.isExport ? '6' : '14'} columnGap={Grid.gap.small} className={css.range}>
			{props.isExport ? null : (
				<Column colSpan="6">
					<TextField
						name={`name-${props.range.id}`}
						autoComplete="off"
						isLabelUppercase={false}
						theme={props.theme}
						isRequired
						type="text"
						placeholder="Name"
						value={name}
						onChange={e => setName(e.target.value)}
						onKeyDown={onEnterClick}
						error={nameError}
					/>
				</Column>
			)}

			{props.isExport ? null : (
				<Column colSpan="3">
					<TextField
						name={`value-${props.range.id}`}
						autoComplete="off"
						isLabelUppercase={false}
						theme={props.theme}
						isRequired
						type="text"
						placeholder="A1"
						value={value}
						onChange={e => setValue(e.target.value)}
						onKeyDown={onEnterClick}
						error={valueError}
					/>
				</Column>
			)}

			<Column colSpan="4">
				<Select
					options={props.sheetOptions}
					value={sheet?.sheetId}
					placeholder="Select"
					theme={props.theme}
					eventListeners={{ onChange: onSheetChange }}
				/>
			</Column>

			<Column colSpan="1" direction="column" justifyContent="center" rowGap="small" className={css.rangeActions}>
				<Button
					view="empty"
					color="primary"
					shape="circle"
					size="tiny"
					loading={loading === 'save'}
					disabled={isDisabled}
					onClick={onSaveClick}
				>
					<Icon type="check-rounded" color="currentColor" />
				</Button>
				{props.isExport ? null : (
					<PopConfirm
						title="Delete variable"
						description="Are you sure to delete this variable?"
						onConfirm={onDeleteClick}
					>
						<Button view="empty" color="danger" shape="circle" size="tiny" disabled={loading !== null}>
							<Icon type="x-rounded" color="currentColor" />
						</Button>
					</PopConfirm>
				)}
			</Column>
		</Grid>
	);
};

export default RangeEdit;
