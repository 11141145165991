import React from 'react';

export function useIntersectionObserver(
	ref: React.RefObject<HTMLElement>,
	callback: (entry: IntersectionObserverEntry) => void,
	options: IntersectionObserverInit = {}
): void {
	React.useEffect(() => {
		const element = ref.current;
		let observer: IntersectionObserver | null = null;

		if (element) {
			observer = new IntersectionObserver(
				([entry]) => {
					if (entry.isIntersecting && callback) {
						callback(entry);
					}
				},
				{
					threshold: 1.0,
					rootMargin: '0px',
					...options,
				}
			);

			observer.observe(element);
		}

		return () => {
			observer?.disconnect();
		};
	}, [ref, callback, options]);
}
