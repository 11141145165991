import { Action } from 'redux';
import { ClientThunkAction } from 'client/reducers';
import { getPhoneVerificationCode as getPhoneVerificationCodeEndpoint } from 'client/resources';
import { GET_PHONE_VERIFICATION_CODE } from 'client/constants/actions';

interface Params {
	organizationId: string;
	phone: string;
}
export function getPhoneVerificationCode({ organizationId, phone }: Params): ClientThunkAction<Action, any> {
	return async (dispatch, getState) => {
		try {
			const response = await getPhoneVerificationCodeEndpoint.params().send({ organizationId, phone });
			const success = response?.statusText === 'OK';
			const payload = {
				success,
				organizationId,
			};

			dispatch({ type: GET_PHONE_VERIFICATION_CODE.SUCCESS, payload });
			return response;
		} catch (error) {
			dispatch({ type: GET_PHONE_VERIFICATION_CODE.FAIL, payload: error });
			return (error as any)?.response.body;
		}
	};
}
