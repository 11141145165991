import { adminLog } from 'utils/helpers';

const log = adminLog.extend('utils:segment');

type IdentifyParams = {
	userId?: string;
	traits?: any;
	options?: any;
	callback?: () => void;
};

type TrackParams = {
	event: string;
	properties?: { [key: string]: string | number | boolean | undefined };
	options?: { [key: string]: string | number | boolean | undefined };
	callback?: () => void;
};

class SegmentAnalytics {
	identify = ({ userId = '', traits = {}, options = {}, callback }: IdentifyParams) => {
		try {
			// @ts-expect-error ts-migrate FIXME
			if (!window.analytics) {
				log('not initialized');
				return;
			}

			log('identify', userId, traits, options, callback);
			// @ts-expect-error ts-migrate FIXME
			window.analytics.identify(userId, traits, options, callback);
		} catch (error) {
			log('failed to identify event', error);
		}
	};

	track = ({ event, properties = {}, options = {}, callback = () => {} }: TrackParams) => {
		try {
			// @ts-expect-error ts-migrate FIXME
			if (!window.analytics) {
				log('not initialized');
				return;
			}

			log('track', event, properties);
			// @ts-expect-error ts-migrate FIXME
			window.analytics.track(event, properties, options, callback);
		} catch (error) {
			log('failed to track', error);
		}
	};
}

const segmentAnalytics = new SegmentAnalytics();

export { segmentAnalytics };
