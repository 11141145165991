import { IUserOrganization } from 'types/user';
import { createThunkAction } from 'admin/actions/helpers';
import { SET_DEFAULT_ORGANIZATION_DOMAIN } from 'admin/constants/actions';
import { setDefaultOrganizationDomain as setDefaultOrganizationDomainEndpoint } from 'admin/resources';

interface Params {
	organizationId: string;
	domainId: IUserOrganization['domainId'];
}

export type SetDefaultOrganizationDomainResult = Pick<Params, 'domainId'>;

export const setDefaultOrganizationDomain = createThunkAction<SetDefaultOrganizationDomainResult, Params>({
	type: SET_DEFAULT_ORGANIZATION_DOMAIN,
	payloadCreator: async ({ organizationId, domainId }) => {
		await setDefaultOrganizationDomainEndpoint.params({ organizationId }).send({ domainId });
		return { success: true, result: { domainId } };
	},
});
