import dayjs from 'dayjs';

export function getFormattedTimestamp(timestamp, config = { time: true }) {
	const timeReference = dayjs();
	const today = timeReference.clone().startOf('day');
	const weekOld = timeReference.clone().subtract(7, 'days').startOf('day');
	const yearOld = timeReference.clone().subtract(1, 'years').startOf('day');
	const yesterday = timeReference.clone().subtract(1, 'days').startOf('day');
	let format = 'HH:mm';
	let additionalFormat = '';

	if (dayjs(timestamp).isSame(today, 'd')) {
		additionalFormat = 'Today';
	} else if (dayjs(timestamp).isSame(yesterday, 'd')) {
		additionalFormat = 'Yesterday';
	} else if (dayjs(timestamp).isAfter(weekOld)) {
		format = 'dddd - HH:mm';
	} else if (!dayjs(timestamp).isAfter(weekOld)) {
		format = 'MMM DD - HH:mm';
	} else if (!dayjs(timestamp).isAfter(yearOld)) {
		format = 'MMM DD, YYYY - HH:mm';
	}

	if (!config.time) {
		format = format.replace(' - HH:mm', '').replace('HH:mm', '');
	}

	return additionalFormat
		? `${additionalFormat}${format ? ` - ${dayjs(timestamp).format(format)}` : ''}`
		: dayjs(timestamp).format(format);
}
