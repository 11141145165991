import { OrganizationDomains } from 'types/organization';

export const getBaseOrganizationDomain = ({
	hostname,
	customId,
}: {
	hostname: string;
	customId: string;
}): ArrayType<OrganizationDomains> => ({
	id: null,
	domain: `${hostname}/${customId}`,
	type: 'Default',
	status: 'valid',
});

type GetStoryCustomDomainProps = {
	domainId: ArrayType<OrganizationDomains>['id'];
	domains: OrganizationDomains;
};

// get url to story by custom domain id
export const getStoryCustomDomain = (props: GetStoryCustomDomainProps) => {
	return props.domains?.find?.(domain => domain.type === 'Custom' && `${domain.id}` === `${props.domainId}`)?.domain;
};
