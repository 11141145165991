import React, { useState, useRef, useMemo } from 'react';
import type { OnDragEndResponder } from 'react-beautiful-dnd';
import Papa from 'papaparse';
import t from 'utils/translate';
import type { SelectBBOption } from 'types/story';
import { arrayMove } from 'utils/helpers';
import { useAdminSelector } from 'admin/reducers';
import Text from 'admin/components/common/Text';
import Drag from 'admin/components/common/Drag';
import { Icon } from 'admin/components/common/Icon';
import Button from 'admin/components/common/Button';
import Tooltip from 'admin/components/common/Tooltip';
import ScrollBox from 'admin/components/common/ScrollBox';
import { Column, Grid } from 'admin/components/common/Grid';
import { Select } from 'admin/components/common/Form';
import { useTheme } from 'admin/components/common/Theme';
import { UNIFORM_PROPS } from 'common/constants/component-props';
import { TextField } from 'admin/components/common/Form/TextField';
import { toast } from 'admin/components/common/MessageContainer/toast';
import { ModalBody, ModalFooter } from 'admin/components/common/Modal';
import { selectEditableElement } from 'admin/reducers/card-editor-extra/selectors';
import { getPFCompPropName } from 'admin/components/pages/Story/CardEditor/Inspector/PropField';
import { SelectBBOptionsOrder } from 'client/components/common/BuildingBlocks/Fields/constants';
import { getOrderedOptions } from 'client/components/common/BuildingBlocks/Fields/Select/utils';
import { selectCurrentState, selectCurrentPlatform } from 'admin/reducers/card-editor/selectors';
import type { EditSelectOptionsModalProps } from './types';
import css from './EditSelectOptions.scss';

// Create options from a JSON string array.
export const generateOptionsFromString = (str: string): SelectBBOption[] => {
	try {
		const list: string[] = JSON.parse(str);
		return Array.from(new Set(list)).map(option => ({ label: option }));
	} catch (e) {
		toast.info(str, 5);
		return [];
	}
};

const modalText = (path: string): string => t(`story.propSettingsModal.${path}`);

const csvHint = `Upload a CSV file with the following format: The first column should contain the label
    (e.g., option name) and the second column should contain the value (e.g., option value). The
    file should not include a header row. Each row represents one option for the select field.`;

const defaultOption = { value: '', label: '' } satisfies SelectBBOption;

const keys = Object.keys(defaultOption).sort() as (keyof typeof defaultOption)[];

const ORDER_FIELD_OPTIONS = [
	{ label: SelectBBOptionsOrder.alphabet, value: SelectBBOptionsOrder.alphabet },
	{ label: SelectBBOptionsOrder.random, value: SelectBBOptionsOrder.random },
	{ label: SelectBBOptionsOrder.custom, value: SelectBBOptionsOrder.custom },
];

const validate = {
	value: function isOptionValueValid(value: SelectBBOption['value']): boolean {
		return value?.indexOf(',') === -1;
	},
	label: function isOptionLabelValid(label: SelectBBOption['label']): boolean {
		return label !== '';
	},
};

const ModalContent: React.FC<EditSelectOptionsModalProps> = props => {
	const element = useAdminSelector(selectEditableElement);
	const currentState = useAdminSelector(selectCurrentState);
	const currentMediaQuery = useAdminSelector(selectCurrentPlatform);
	const { path, uiConfig } = element;

	const { theme } = useTheme();
	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const [newOption, setNewOption] = useState({ ...defaultOption });
	const [error, setError] = React.useState<Partial<SelectBBOption>>({});
	const [rawOptions, setRawOptions] = useState(
		props.data.generatedOptions ?? uiConfig.componentProps[UNIFORM_PROPS.selectOptions] ?? []
	);
	const [order, setOrder] = useState(
		uiConfig.componentProps[UNIFORM_PROPS.selectOptionsOrder] ?? SelectBBOptionsOrder.alphabet
	);
	const orderedOptions = useMemo(() => getOrderedOptions(rawOptions, order), [rawOptions, order]);
	const isAddOptionDisabled = keys.some(key => Boolean(error[key]) || !validate[key](newOption[key]));

	const onSave = () => {
		const nameParams = { path, currentMediaQuery, currentState };

		props.data.onUpdate({
			options: {
				value: rawOptions,
				name: getPFCompPropName(UNIFORM_PROPS.selectOptions, 'uniform')(nameParams),
			},
			order: {
				value: order,
				name: getPFCompPropName(UNIFORM_PROPS.selectOptionsOrder, 'uniform')(nameParams),
			},
		});

		props.close();
	};

	const onOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.currentTarget.value;
		const name = e.currentTarget.name as ArrayType<typeof keys>;

		if (!validate[name](inputValue)) {
			setError({ ...error, [name]: `Invalid ${name}` });
		} else if (name === 'label' && rawOptions.find(o => o[name] === inputValue)) {
			setError({ ...error, [name]: `This ${name} is already in list` });
		} else if (error[name]) {
			setError({ ...error, [name]: '' });
		}

		setNewOption(prev => ({ ...prev, [name]: inputValue }));
	};

	const onAddOptionClick = () => {
		if (isAddOptionDisabled) return;
		setRawOptions(prev => [...prev, newOption]);
		setNewOption({ ...defaultOption });

		// autofocus 1st field
		(document.querySelector(`.${css.field} input`) as HTMLInputElement | null)?.focus();
	};

	const onDeleteOptionClick = (deleteIndex: number) => {
		setError({});
		setRawOptions(prev => prev.filter((item, idx) => idx !== deleteIndex));
	};

	const onDragEnd: OnDragEndResponder = result => {
		if (!rawOptions.length || !result.destination) return;
		const optionsCopy = [...orderedOptions];
		arrayMove(optionsCopy, result.source.index, result.destination.index);
		setRawOptions(optionsCopy);
		setOrder(SelectBBOptionsOrder.custom);
	};

	const onFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			Papa.parse<[string, string]>(file, {
				complete: result => {
					try {
						const seenLabels = new Set();
						let errorMsg: string = '';

						const parsedOptions = result.data.reduce<SelectBBOption[]>((acc, item) => {
							// Assuming the first column is the label and the second column is the value
							const label = String(item[0]?.trim() ?? '');
							const value = String(item[1]?.trim() ?? '').replaceAll(',', '');
							// Check if the label or value is missing, or if they are duplicates
							if (validate.label(label) && validate.value(value) && !seenLabels.has(label)) {
								seenLabels.add(label);
								acc.push({ label, value });
							} else {
								errorMsg = 'Some choices were excluded due to invalid data format';
							}
							return acc;
						}, []);

						setRawOptions(parsedOptions);
						if (errorMsg) toast.error(errorMsg, 7);
						// eslint-disable-next-line no-param-reassign
						event.target.value = '';
					} catch (e) {
						toast.error("Oops! We couldn't read the file correctly.");
					}
				},
				header: false, // Set to true if your CSV has a header row
				skipEmptyLines: true, // Skips empty lines to ensure data integrity
			});
		}
	};

	const renderNewOption = () => {
		return (
			<Grid columns="12" gap={Grid.gap.medium}>
				{keys.map((key, keyIndex) => (
					<Column key={`new-select-option-${key}`} colSpan="4" alignItems="center">
						<TextField
							className={css.field}
							key={`select-option-${key}`}
							name={key}
							autoFocus={keyIndex === 0}
							isRequired
							value={newOption[key]}
							isLabelUppercase={false}
							error={error[key]}
							theme={theme}
							label={key === 'label' ? 'Text' : 'Custom value [optional]'}
							onChange={onOptionChange}
							onKeyDown={e => (e.code === 'Enter' ? onAddOptionClick() : undefined)}
							autoComplete="off"
						/>
					</Column>
				))}

				<Column colSpan="1" alignItems="center" justifyContent="center">
					<Button
						view="empty"
						color="primary"
						shape="circle"
						size="small"
						style={{ marginTop: 22 }}
						disabled={isAddOptionDisabled}
						onClick={onAddOptionClick}
					>
						<Icon type="plus-rounded" color="currentColor" />
					</Button>
				</Column>
				<Column colSpan="3" alignItems="center" justifyContent="center">
					<input
						type="file"
						accept=".csv, text/csv, .tsv, text/tab-separated-values"
						onChange={onFileUpload}
						ref={fileInputRef}
						hidden
					/>
					<Button
						style={{ marginTop: 20, width: 120 }}
						view="label-only"
						color="primary"
						textSize={Text.size.label}
						textWeight={Text.weight.normal}
						theme={theme}
						onClick={() => fileInputRef.current?.click()}
					>
						Upload from CSV&nbsp;
						<Tooltip isStatic stylePreset="dark-1" destroyTooltipOnHide content={csvHint}>
							<Icon type="info" color="var(--ra-color-white-mid)" width={14} />
						</Tooltip>
					</Button>
				</Column>
			</Grid>
		);
	};

	const renderOption = (opt: SelectBBOption, index: number) => (
		<Drag.Item key={`select-option-${opt.label}-${opt.value}`} index={index} isDraggable>
			{dragHandleProps => (
				<Grid columns="12" gap={Grid.gap.medium} className={css.optionsRow}>
					<Column colSpan="4" alignItems="center" className={css.col}>
						<Icon
							className={css.dragHandle}
							type="sc-reorder"
							width={19}
							color="var(--ra-color-white-soft)"
							{...dragHandleProps}
						/>
						<Text ellipsis size={Text.size.label} title={opt.label}>
							{opt.label}
						</Text>
					</Column>
					<Column colSpan="6" alignItems="center" className={`${css.col}`}>
						<Text ellipsis size={Text.size.label} title={opt.value}>
							{opt.value}
						</Text>
					</Column>
					<Column colSpan="2" alignItems="flex-end" justifyContent="center" className={css.col}>
						<Button
							view="empty"
							color="danger-ds"
							shape="circle"
							size="tiny"
							onClick={() => onDeleteOptionClick(index)}
						>
							<Icon type="minus-rounded" color="currentColor" />
						</Button>
					</Column>
				</Grid>
			)}
		</Drag.Item>
	);

	return (
		<>
			<ModalBody>
				{renderNewOption()}

				<ScrollBox className={css.options} fillHexColor="#4d4d4d" maxHeight="40vh">
					<Drag.Container onDragEnd={onDragEnd}>{orderedOptions.map(renderOption)}</Drag.Container>
				</ScrollBox>

				{orderedOptions.length > 0 && (
					<Column justifyContent="space-between">
						<Select
							label={{ children: <span style={{ marginRight: 10 }}>Order</span> }}
							options={ORDER_FIELD_OPTIONS}
							value={order}
							inline
							theme={theme}
							eventListeners={{ onChange: value => setOrder(value as SelectBBOptionsOrder) }}
							menuPlacement="top"
						/>
						<Button
							view="label-only"
							textSize={Text.size.label}
							textWeight={Text.weight.normal}
							onClick={() => setRawOptions([])}
						>
							Remove all choices
						</Button>
					</Column>
				)}
			</ModalBody>
			<ModalFooter>
				<Column justifyContent="flex-start">
					<Button view="primary" onClick={onSave}>
						{modalText('save')}
					</Button>
					<Button view="secondary-gray" onClick={props.close}>
						{modalText('discard')}
					</Button>
				</Column>
			</ModalFooter>
		</>
	);
};

export default ModalContent;
