import React, { FC } from 'react';
import cn from 'classnames';
import { Switch } from 'antd';

import { Icon } from 'admin/components/common/Icon';
import { Errors } from 'admin/components/common/Form/Errors';
import { InspectorTypes } from 'admin/components/pages/Story/CardEditor/Inspector/types';
import { ReduxFieldInputTypes, ReduxFieldMetaTypes } from 'admin/components/common/Form/utils';
import css from './Toggle.scss';

const onChange = (props: PFToggleProps) => value => {
	props.input.onChange(value);
	props.eventListeners?.onChange?.({ target: { value, name: props.input.name } });
};

export enum ToggleView {
	roundCheck = 'roundCheck',
	roundAlert = 'roundAlert',
}

export type PFToggleProps = {
	input: ReduxFieldInputTypes;
	meta: ReduxFieldMetaTypes;
	className?: string;
	showErrorsOn?: string | boolean;
	eventListeners?: ReturnType<InspectorTypes.GetFieldListeners>;
	checked?: boolean;
	defaultValue?: boolean;
	toggleView?: ToggleView;
};

export const Toggle: FC<PFToggleProps> = ({
	className = '',
	showErrorsOn = 'touched',
	toggleView,
	input,
	meta,
	eventListeners,
	defaultValue = false,
	...props
}) => {
	const checked = props.checked ?? (input.value === '' ? defaultValue : Boolean(input.value));

	return (
		<div className={cn(css.pfToggle, className, { [css[`view-${toggleView}`]]: !!toggleView })}>
			<Switch
				id={`${meta.form}.${input.name}`}
				checked={checked}
				defaultChecked={defaultValue}
				onChange={onChange({ ...props, input, meta, eventListeners })}
				className={css.pfInput}
				size="default"
				prefixCls=""
			/>
			{toggleView !== undefined && !checked && (
				<Icon className={css.icon} color="var(--ra-color-white-soft)" type="empty-rounded" />
			)}
			{toggleView === ToggleView.roundAlert && checked && (
				<Icon className={css.icon} color="var(--ra-color-error-ds)" type="bang-rounded" />
			)}
			{toggleView === ToggleView.roundCheck && checked && (
				<Icon className={css.icon} color="var(--ra-color-brand-light)" type="check-outline-rounded" />
			)}
			<Errors show={typeof showErrorsOn === 'string' ? meta[showErrorsOn] : showErrorsOn}>{meta.error}</Errors>
		</div>
	);
};
