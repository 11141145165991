import React, { FC, ReactNode, useEffect } from 'react';
import { Switch, RouteProps } from 'react-router-dom';

type Props = {
	onUpdate?: () => void;
	location?: RouteProps['location'];
	children: ReactNode;
};

const RouterSwitch: FC<Props> = props => {
	useEffect(() => {
		props.onUpdate?.();
	});

	return <Switch {...props} />;
};

export default RouterSwitch;
