import { DEFAULT_MEDIA_QUERY_PLATFORMS } from 'common/constants';

export const DARK_MODE_ELEMENTS = {
	BACKGROUNDS: 'bgs',
	TEXTS: 'txts',
	IMAGES: 'imgs',
} as const;

export const ALL_PLATFORMS = 'all';
export const PLATFORM_OPTIONS = [
	{ value: ALL_PLATFORMS, label: 'All Platforms' },
	{ value: DEFAULT_MEDIA_QUERY_PLATFORMS.MOBILE, label: 'Mobile' },
	{ value: DEFAULT_MEDIA_QUERY_PLATFORMS.MOBILE_LANDSCAPE, label: 'Mobile (landscape)' },
	{ value: DEFAULT_MEDIA_QUERY_PLATFORMS.TABLET, label: 'Tablet' },
	{ value: DEFAULT_MEDIA_QUERY_PLATFORMS.DESKTOP, label: 'Desktop' },
	{ value: DEFAULT_MEDIA_QUERY_PLATFORMS.FULL_HD, label: 'Full HD' },
];

export const ELEMENT_OPTIONS = [
	{ value: DARK_MODE_ELEMENTS.BACKGROUNDS, label: 'Backgrounds' },
	{ value: DARK_MODE_ELEMENTS.TEXTS, label: 'Texts' },
	{ value: DARK_MODE_ELEMENTS.IMAGES, label: 'Images' },
];

export const DARK_MODE_URL_PARAM = 'darkmode';
export const DARK_MODE_URL_PARAM_VALUE = 'true';

export const defaultProps = {
	enableByUrl: true,
	elements: [DARK_MODE_ELEMENTS.BACKGROUNDS, DARK_MODE_ELEMENTS.TEXTS],
	platforms: [ALL_PLATFORMS],
};
