import { getAIPrompts } from 'admin/resources';
import { createThunkAction, handleAPIv2Error } from 'admin/actions/helpers';
import { selectEditableStoryId } from 'admin/reducers/story-editor/selectors';
import { GeneratorPromptsParams } from './types';

export type GeneratorPrompts = Array<{ id: string; prompt: string }>;

export const getGeneratorPrompts = createThunkAction<GeneratorPrompts, GeneratorPromptsParams>({
	payloadCreator: async (payload, { getState }) => {
		const state = getState();
		const storyId = selectEditableStoryId(state);

		if (!storyId) {
			return { success: false };
		}

		const postResponse = await getAIPrompts.params({ storyId }).send(payload);

		return {
			success: true,
			result: (postResponse.body?.prompts ?? []) as GeneratorPrompts,
		};
	},
	onError: ({ error }) => {
		handleAPIv2Error(error);
	},
});
