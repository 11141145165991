import type { DictionaryType } from 'types/story';
import { generateElementId, generateNodeId } from 'utils/generate-id';
import {
	DEFAULT_MEDIA_QUERY_PLATFORMS,
	COMPONENT_STATES,
	COMPONENT_TYPE as TYPE,
	DEFAULT_MEDIA_QUERY_SIZES,
	COMPONENT_DISPLAY_NAME,
	LAYER_TYPE,
} from 'common/constants';
import { getText } from 'utils/dictionary';
import { convertPxToVw } from 'utils/helpers';
import View from 'client/components/common/BuildingBlocks/Button/Button';

export const BUTTON_SHAPE_TYPES = {
	ROUNDED: 'rounded',
	SQUARED: 'squared',
};

type Props = {
	dictionary?: DictionaryType;
	type?: typeof TYPE.BUTTON_SUBMIT | typeof TYPE.BUTTON;
};

export const BUTTON = ({ dictionary = {}, type = TYPE.BUTTON }: Props = {}) => {
	const _id = generateElementId();

	return {
		view: View,
		get model() {
			return {
				_id,
				type,
				uiConfig: {
					layer: LAYER_TYPE.CONTENT,
					editorProps: {
						name: COMPONENT_DISPLAY_NAME[type],
						selectable: true,
						draggable: true,
						expandable: false,
						expanded: false,
					},
					nodeProps: {
						id: generateNodeId(_id, type),
					},
					componentProps: {
						other: {
							[COMPONENT_STATES.DEFAULT]: {
								[DEFAULT_MEDIA_QUERY_PLATFORMS.DEFAULT]: {
									buttonShapeType: BUTTON_SHAPE_TYPES.SQUARED,
								},
							},
						},
						styles: {
							[COMPONENT_STATES.DEFAULT]: {
								[DEFAULT_MEDIA_QUERY_PLATFORMS.DEFAULT]: {
									backgroundColor: 'rgb(0, 168, 126)',
								},
								[DEFAULT_MEDIA_QUERY_PLATFORMS.DESKTOP]: {
									width: convertPxToVw(240, 2, DEFAULT_MEDIA_QUERY_SIZES.desktop.min),
									height: convertPxToVw(60, 2, DEFAULT_MEDIA_QUERY_SIZES.desktop.min),
									fontSize: convertPxToVw(20, 2, DEFAULT_MEDIA_QUERY_SIZES.desktop.min),
									borderRadius: '6px',
								},
								[DEFAULT_MEDIA_QUERY_PLATFORMS.MOBILE]: {
									width: convertPxToVw(160, 2, 375),
									height: convertPxToVw(40, 2, 375),
									fontSize: convertPxToVw(18, 2, 375),
								},
							},
							[COMPONENT_STATES.HOVER]: {
								[DEFAULT_MEDIA_QUERY_PLATFORMS.DEFAULT]: {
									backgroundColor: 'rgb(0, 196, 148)',
								},
							},
						},
					},
				},
				children: {
					[COMPONENT_STATES.DEFAULT]: {
						[DEFAULT_MEDIA_QUERY_PLATFORMS.DEFAULT]: getText(dictionary, type, 'text'),
					},
				},
			};
		},
	};
};
