import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { DEFAULT_PARAMS, EmbedStoryParams } from 'src/sdk';
import t from 'utils/translate';
import { PARAMS } from 'common/constants';
import { Icon } from 'admin/components/common/Icon';
import { STORY_EMBED_PAGE } from 'admin/constants/routes';
import { Label } from 'admin/components/common/Form';
import Text from 'admin/components/common/Text';
import { getEmbedCode } from 'admin/utils/get-embed-code';
import { useWillUnmount } from 'common/components/useWillUnmount';
import css from './EmbedCode.scss';

export enum EmbedCodeTarget {
	html = 'html',
	js = 'js',
}

type Props = {
	clientStoryId: string;
	params?: EmbedStoryParams &
		Partial<{
			fixedSize: boolean;
			isStretchHeight: boolean;
			width: number;
			height: number;
			type: EmbedCodeTarget;
		}>;
	storyId?: string;
	onParamsChange?: (embedCode: string) => void;
};

const EmbedCode: React.FC<Props> = ({
	onParamsChange,
	storyId = '',
	params: customParams = DEFAULT_PARAMS,
	...props
}: React.ComponentProps<typeof EmbedCode>) => {
	const [isCopiedToClipboard, setIsCopiedToClipboard] = useState(false);

	const copiedLabelTimeout = useRef(0);
	const params = { ...DEFAULT_PARAMS, start: 'in-view', ...customParams } as const;
	const embedCode = getEmbedCode(props.clientStoryId, params)[params.type ?? EmbedCodeTarget.html];

	function onCopyBtnClick() {
		if (isCopiedToClipboard) {
			return;
		}

		if (document && document.body) {
			const el = document.createElement('textarea');
			el.value = embedCode;

			document.body.appendChild(el);

			el.select();

			document.execCommand('copy');
			document.body.removeChild(el);

			setIsCopiedToClipboard(true);
			copiedLabelTimeout.current = window.setTimeout(() => setIsCopiedToClipboard(false), 3000);
		}
	}

	useEffect(() => onParamsChange?.(embedCode), [onParamsChange, embedCode]);

	useWillUnmount(function clearCopiedTimeout() {
		if (copiedLabelTimeout.current) {
			clearTimeout(copiedLabelTimeout.current);
		}
	});

	return (
		<div>
			<div className={css.embedCodeWrap}>
				<Label theme="dark" component="div">
					<Text className={css.label} size={Text.size.label}>
						Embed code
						<button className={css.copyBtn} onClick={onCopyBtnClick} type="button">
							<Icon type="copy-text" width={18} color="#FFF" />
							{t('story.settings.copy')}
						</button>
					</Text>
				</Label>

				{storyId && (
					<Link
						className={css.advLink}
						to={STORY_EMBED_PAGE.replace(`:${PARAMS.STORY_ID}`, storyId)}
						target="_blank"
					>
						<Text className={css.label} size={Text.size.label}>
							<Icon type="open-external" width={18} />
							{t('story.settings.advanced')}
						</Text>
					</Link>
				)}

				<br />
				<code className={css.embedCode}>{embedCode}</code>
				<Text
					tag="div"
					size={Text.size.label}
					className={cn(css.tooltip, isCopiedToClipboard && css.active)}
					text={`${t('story.settings.copied')}!`}
				/>
			</div>
		</div>
	);
};

export default EmbedCode;
