import React, { useId } from 'react';
import cn from 'classnames';
import { THEME } from 'admin/components/common/Theme';
import Text from 'admin/components/common/Text';
import css from './ParamsTable.scss';

type Props = {
	theme: ValuesType<typeof THEME>;
	title: string;
	columns: { label: React.ReactNode; fieldKey: string }[];
	data: Array<{ [key: string]: React.ReactNode }>;
	hideHeader?: boolean;
};

const ParamsTable: React.FC<Props> = props => {
	const id = useId();

	if (props.data.length === 0) return null;

	return (
		<div className={cn(css.paramsTable, css[props.theme])}>
			<Text className={css.title} size="article" tag="div" weight="semibold" text={props.title} />

			{!props.hideHeader && (
				<div className={css.paramsTableItem}>
					{props.columns.map(col => (
						<div className={css.col} key={`params-label-col-${col.label}-${id}`}>
							{typeof col.label === 'string' ? <Text weight="semibold" text={col.label} /> : col.label}
						</div>
					))}
				</div>
			)}
			{props.data.map((row, rowIndex) => {
				const key = `table-row-${rowIndex}-${id}`;
				return (
					<div key={key} className={css.paramsTableItem}>
						{props.columns.map((column, columnIndex) => {
							const content = row[column.fieldKey];
							return (
								<div className={css.col} key={`${key}-${column.fieldKey}`}>
									{typeof content === 'string' ? (
										<Text className={css.text} text={content} />
									) : (
										content
									)}
								</div>
							);
						})}
					</div>
				);
			})}
		</div>
	);
};

export default ParamsTable;
