import _ from 'lodash';
import { pathToRegexp } from 'path-to-regexp';

export function getRouteByPath(path: string, routes: string[]) {
	const matches = _.filter(routes, route => pathToRegexp(route).test(path));

	return matches[0];
}

export function replaceUrlParams(url: string, params: Record<string, string | number | null>) {
	let newUrl = url;
	Object.entries(params).forEach(([key, value]) => {
		if (value !== '') {
			newUrl = newUrl.replace(new RegExp(`:${key}`, 'g'), `${value}`);
		}
	});
	return newUrl;
}
