import { change } from 'redux-form';
import type { StoryModel, StoryVersionType } from 'types/story';
import { UPDATE_EDITABLE_STORY } from 'admin/constants/actions';
import { AUTO_SYNC_TARGET, FORM_MODEL } from 'admin/constants/common';

export type Payload = {
	updateForm?: boolean;
	entire?: boolean;
	path?: string;
	isVersionPath?: boolean;
	data: Partial<StoryModel> | Partial<StoryVersionType> | any;
};

type ActionCreatorPayload = Pick<Payload, 'data' | 'path'>;
export const storyEditorChange = (payload: ActionCreatorPayload) => ({ type: UPDATE_EDITABLE_STORY.UPDATE, payload });
export const storyEditorFormChange = ({ data, path }: Required<ActionCreatorPayload>) =>
	change(FORM_MODEL.EDIT_STORY, path, data);

/**
 * Update current story version or entire story
 *
 * @param payload {Object}
 * @param payload.data {*} story data or specific story field data
 * @param [payload.path] {String} path to where update data in story
 * @param [payload.entire] {Boolean} is update entire story, but not just a current version.
 * @param [payload.updateForm] {Boolean} is update form reducer also
 * @return {{type: *, payload: *}}
 */
export function updateEditableStory({
	data,
	path = '',
	entire = false,
	updateForm = false,
	isVersionPath = true,
}: Payload) {
	return (dispatch, getState) => {
		const version = getState().version.current;

		const pathPattern = isVersionPath ? `storyVersions.${version}${path ? `.${path}` : ''}` : path;
		const _path = entire ? undefined : pathPattern;

		// update form.storyEditor
		if (updateForm && _path !== undefined) {
			dispatch(storyEditorFormChange({ data, path: _path }));
		}

		// update storyEditor
		dispatch({
			...storyEditorChange({ data, path: _path }),
			meta: { autoSync: AUTO_SYNC_TARGET.STORY_EDITOR },
		});
	};
}
