import * as React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import Logo from 'common/components/StoryCardsLogo';
import { ROOT } from 'admin/constants/routes';

import { isMatching, QUERY } from '../Responsive';

import css from './LoginLayout.scss';

type Props = {
	className?: string;
	children?: React.ReactNode | React.ReactNode[];
};

const LoginLayout: React.FC<Props> = ({ className = '', children = null }) => {
	const isDesktop = isMatching(QUERY.DESKTOP);

	return (
		<div className={cn(css.loginLayout, className)}>
			<div className={css.header}>
				<Link to={ROOT}>
					<Logo isMinimal={!isDesktop} color={isDesktop ? 'white' : 'brand'} />
				</Link>
			</div>
			{isDesktop && (
				<div className={css.decorPanel}>
					<svg width="203px" height="254px" viewBox="0 0 203 254">
						<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.34950111">
							<g transform="translate(-246.000000, -289.000000)" fill="#00A87E">
								<path
									// eslint-disable-next-line max-len
									d="M359.679161,289 L246,428.703569 L395.350449,428.703569 L359.679161,472.535186 L359.679161,436.320252 L354.305817,437.752054 C342.514661,440.901179 334.305734,451.595606 334.305734,463.81421 L334.305734,463.81421 L334.305734,543 L449,403.30063 L359.679161,403.30063 L359.679161,289 Z M335,360 L335,403 L300,403 L335,360 Z"
								/>
							</g>
						</g>
					</svg>
				</div>
			)}
			<div className={css.content}>{children}</div>
		</div>
	);
};

export default LoginLayout;
