import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import { useClientSelector } from 'client/reducers';
import { selectIsNodeSelected } from 'client/reducers/editor/selectors';
import { SELECTION_TYPES } from 'client/components/common/SelectionHint/utils';
import css from './Toolbar.scss';

const SelectionToolbar = (props: { children: ReactNode }) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const [pin, setPin] = useState<'top' | 'bottom' | 'topInset'>('top');

	// observe and auto-fix position of the toolbar
	useEffect(() => {
		let rafID = 0;
		let frameCounter = 0;
		function onRAF() {
			// re-check each 2sec (every 120 frame) (performance optimization)
			if (frameCounter % (60 * 2) === 0) {
				const el = ref.current;
				const parent = el?.parentElement;

				if (!el || !parent) return;

				const bbox = el.getBoundingClientRect();
				const pbbox = parent.getBoundingClientRect();
				const transform = { x: 0, y: 0 };

				// check Y
				if (pbbox.top >= bbox.height) {
					setPin('top');
				} else if (pbbox.bottom < window.innerHeight - bbox.height) {
					setPin('bottom');
				} else {
					setPin('topInset');
					transform.y = Math.max(-1 * pbbox.top, 0);
				}

				// check X
				const xEndOffset = window.innerWidth - (pbbox.left + bbox.width);
				if (pbbox.left < 0) {
					// shift to right
					transform.x = Math.abs(bbox.left);
				} else if (xEndOffset < 0) {
					// shift to left
					transform.x = xEndOffset;
				}

				if (transform.x || transform.y) {
					el.style.transform = `translate(${transform.x}px, ${transform.y}px)`;
				} else {
					el.style.transform = '';
				}
			}

			frameCounter += 1;
			rafID = window.requestAnimationFrame(onRAF);
		}

		window.requestAnimationFrame(onRAF);

		return () => {
			window.cancelAnimationFrame(rafID);
		};
	}, []);

	return (
		<div className={cn(css.selectionToolbar, css[pin])} ref={ref} data-toolbar-placement={pin}>
			<div className={css.actions} id="selection-toolbar-actions" />
			{props.children}
		</div>
	);
};

const toolbarSelector = `[data-selection-type="${SELECTION_TYPES.clicked}"] #selection-toolbar-actions`;

/*
 Use this component to render any content into a selection toolbar
 */
export const SelectionToolbarPortal = ({ children, nodeID }: { children: ReactNode; nodeID: string }) => {
	const [toolbar, setToolbar] = useState<HTMLElement | null>(null);
	const isSelected = useClientSelector(state => selectIsNodeSelected(state, nodeID));

	useEffect(() => {
		if (isSelected) {
			setToolbar(document.querySelector(toolbarSelector) as HTMLElement);
		}
	}, [isSelected, children]);

	return toolbar && children ? (
		<div
			style={{ position: 'absolute' }}
			// note: Why stopPropagation?
			// Need to prevent invocation of e.g. dbClick event which is may be fired
			// at building block, that renders some components into toolbar via SelectionToolbarPortal
			onClick={e => {
				e.stopPropagation();
			}}
			onDoubleClick={e => {
				e.stopPropagation(); // e.g. to stop dbClick on image which toggle an edit mode
			}}
			onFocus={e => {
				e.stopPropagation(); // stop onFocus event provided to building blocks by SelectionHintProvider
			}}
		>
			{createPortal(children, toolbar)}
		</div>
	) : null;
};

export default SelectionToolbar;
