import {
	DragDropContext,
	Draggable,
	DraggableProvided,
	Droppable,
	OnDragEndResponder,
	OnDragStartResponder,
} from 'react-beautiful-dnd';
import React, { ReactNode, useId } from 'react';

type DragContainerProps = {
	onDragEnd: OnDragEndResponder;
	onDragStart?: OnDragStartResponder;
	children: ReactNode;
};

const DragContainer = ({ onDragEnd, onDragStart, children }: DragContainerProps) => (
	<DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
		<Droppable droppableId="droppable">
			{provided => (
				<div {...provided.droppableProps} ref={provided.innerRef}>
					{children}
					{provided.placeholder}
				</div>
			)}
		</Droppable>
	</DragDropContext>
);

type DragItemProps = {
	id?: string;
	index: number;
	children: (dragHandleProps: DraggableProvided['dragHandleProps']) => ReactNode;
	isDraggable: boolean;
};

const DragItem: React.FC<DragItemProps> = ({ index, children, isDraggable, ...props }) => {
	const _id = useId();
	const draggableId = props.id ?? _id;

	return isDraggable ? (
		<Draggable draggableId={draggableId} index={index} disableInteractiveElementBlocking>
			{provided => (
				<div ref={provided.innerRef} {...provided.draggableProps} style={provided.draggableProps.style}>
					{children(provided.dragHandleProps)}
				</div>
			)}
		</Draggable>
	) : (
		<>{children(null)}</>
	);
};
export default {
	Container: DragContainer,
	Item: DragItem,
};
