import type { StoryModel } from 'types/story';
import { createThunkAction, handleStoryNoAccessError } from 'admin/actions/helpers';
import { CREATE_STORY } from 'admin/constants/actions';
import { createStory as createStoryEndpoint } from 'admin/resources';

type CreateStoryParams = {
	name: StoryModel['name'];
	type: StoryModel['type'];
	language: StoryModel['language'];
	tags: StoryModel['tags'];
};

export type CreateStoryResult = {
	storyId: string;
};

export const createStory = createThunkAction<CreateStoryResult, CreateStoryParams>({
	type: CREATE_STORY,
	payloadCreator: async ({ name, type, language, tags }, { getState }) => {
		const { organization, activeTeam } = getState().user;

		const response = await createStoryEndpoint.send({
			organizationId: organization?.id,
			name,
			type,
			language,
			tags,
			teamId: activeTeam || null,
		});

		return { success: true, result: { storyId: response.body.result } };
	},
	onError: params => {
		handleStoryNoAccessError(params.dispatch, params.error as IBackendResponseError);
	},
});
