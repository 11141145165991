import { get, map, minBy, maxBy } from 'lodash';
import cn from 'classnames';
import React, { useCallback, useRef } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { useDidMount } from 'common/components/useDidMount';
import { EnterHandler } from 'react-transition-group/Transition';
import { StoryMediaPlatform, StoryMediaQuery } from 'types/story';
import { useWindowSize } from 'common/components/useWindowSize';
import { DEFAULT_MEDIA_QUERY_PLATFORMS, DEFAULT_MEDIA_QUERY_SIZES } from 'common/constants';
import css from './PreviewStory.scss';

enum Mode {
	cover,
	contain,
}

type Props = {
	mediaQuery: StoryMediaQuery;
	platform: StoryMediaPlatform;
	src: string;
	coverMode?: boolean;
};

const getPreviewWidth = (platform: StoryMediaPlatform) => {
	switch (platform) {
		case DEFAULT_MEDIA_QUERY_PLATFORMS.FULL_HD:
			return `${DEFAULT_MEDIA_QUERY_SIZES.fullHD.min}px`;
		case DEFAULT_MEDIA_QUERY_PLATFORMS.DESKTOP:
			return `${DEFAULT_MEDIA_QUERY_SIZES.desktop.min}px`;
		case DEFAULT_MEDIA_QUERY_PLATFORMS.TABLET:
			return `${DEFAULT_MEDIA_QUERY_SIZES.tablet.min}px`;
		case DEFAULT_MEDIA_QUERY_PLATFORMS.MOBILE_LANDSCAPE:
			return '667px';
		case DEFAULT_MEDIA_QUERY_PLATFORMS.MOBILE:
			return '375px';
		default:
			return '100%';
	}
};

const getCurrentPlatformWidth = (platform: StoryMediaPlatform, mediaQuery: StoryMediaQuery): number => {
	const { maxWidth, minWidth } = mediaQuery.config[platform];

	/**
	 * To avoid viewport issue
	 * 20 - is a random value, but it has to be greater than scrollbar width
	 * @details see this commit and full message 31d47b87e1d634929f57ba50c4ae165eb84d4d95
	 */
	const MARGIN = 20;

	if (maxWidth) {
		return maxWidth - MARGIN;
	}

	if (minWidth) {
		return minWidth + MARGIN;
	}

	const { config: mediaQueryConfig } = mediaQuery;
	const configArray = map(mediaQueryConfig, (value, key) => ({ value, key }));

	const lowestMinWidth = minBy(configArray, ({ value }) => value.minWidth);
	const largestMaxWidth = maxBy(configArray, ({ value }) => value.maxWidth);

	let min = get(lowestMinWidth, 'value.minWidth')!;
	let max = get(largestMaxWidth, 'value.maxWidth')!;

	if (min) {
		min -= 1;
	}
	if (max) {
		max += 1;
	}
	if (min && !max) {
		return min - MARGIN;
	}
	if (!min && max) {
		return max + MARGIN;
	}

	return (max > min ? max : min) - MARGIN;
};

const getIframeStyles = (
	platform: StoryMediaPlatform,
	previewNode: HTMLDivElement,
	mediaQuery: StoryMediaQuery,
	size: Mode
) => {
	// clear all inline style
	previewNode.removeAttribute('style');

	const previewWidth = previewNode.offsetWidth || 0;
	const previewHeight = previewNode.offsetHeight || 0;

	const width = size === Mode.cover ? previewWidth : getCurrentPlatformWidth(platform, mediaQuery);
	const height = size === Mode.cover ? previewHeight : width / DEFAULT_MEDIA_QUERY_SIZES[platform].ratio;

	// scale down to fit in preview
	const scale = {
		x: Math.min(1, previewWidth / width),
		y: Math.min(1, previewHeight / height),
	};

	return {
		left: '50%',
		width: `${width}px`,
		transform: `scale(${Math.min(scale.x, scale.y).toFixed(5)}) translateX(-50%)`,
		height: `${height}px`,
	};
};

const timeout = { enter: 300, exit: 50 };

const PreviewStory: React.FC<Props> = props => {
	const previewRef = useRef<HTMLDivElement | null>(null);
	const iframeRef = useRef<HTMLIFrameElement | null>(null);
	const windowSize = useWindowSize({ debounce: 300 });
	const mode = props.platform === DEFAULT_MEDIA_QUERY_PLATFORMS.DESKTOP ? Mode.cover : Mode.contain;

	const onEnter: EnterHandler<undefined> = useCallback(
		node => {
			previewRef.current!.style.width = '100%';
			if (mode === Mode.contain) previewRef.current!.style.maxWidth = getPreviewWidth(props.platform);

			const iframeStyle = getIframeStyles(props.platform, previewRef.current!, props.mediaQuery, mode);
			const iframe = node;
			iframe.style.left = iframeStyle.left;
			iframe.style.width = iframeStyle.width;
			iframe.style.height = iframeStyle.height;
			iframe.style.transform = iframeStyle.transform;
		},
		[props.platform, props.mediaQuery, mode]
	);

	useDidMount(() => {
		onEnter(iframeRef.current!, true);
	});

	return (
		<div ref={previewRef} className={cn(css.preview, { [css.coverMode]: props.coverMode })}>
			<SwitchTransition>
				<CSSTransition
					key={`${props.platform}-${windowSize.innerWidth}-${windowSize.innerHeight}`}
					timeout={timeout}
					onEnter={onEnter}
					classNames="animate"
				>
					<iframe ref={iframeRef} title="preview" className={css.iframe} src={props.src} />
				</CSSTransition>
			</SwitchTransition>
		</div>
	);
};

export default PreviewStory;
