import _ from 'lodash';
import React, { SyntheticEvent } from 'react';
import classnames from 'classnames';
import { ReduxFieldInputTypes, ReduxFieldMetaTypes } from 'admin/components/common/Form/utils';
import css from 'admin/components/pages/Story/CardEditor/Inspector/PropField/Radio/Radio.scss';
import { InspectorTypes } from 'admin/components/pages/Story/CardEditor/Inspector/types';

const onChange = (props: RadioProps) => (event: SyntheticEvent<HTMLInputElement>) => {
	// if (event.isTrusted) {
	props.input.onChange(event);
	props.eventListeners?.onChange?.(event);
	// }
};

const onClick = (props: RadioProps) => (event: SyntheticEvent<HTMLInputElement>) => {
	const next = (event.target as HTMLInputElement).value;
	const curr = props.input.value || props.defaultValue;

	// if (event.isTrusted) {
	if (next === curr) {
		onChange(props)(event);
	}
	// } else {
	// 	props.eventListeners?.onClick?.(event);
	// }
};

type RadioProps = {
	input: ReduxFieldInputTypes;
	meta: ReduxFieldMetaTypes;
	className?: string;
	prop: boolean | string | any | any[] | number;
	defaultValue?: boolean | string | number;
	eventListeners?: ReturnType<InspectorTypes.GetFieldListeners>;
};

export const Radio = (props: RadioProps) => {
	const { className, input, meta, prop, defaultValue, ...rest } = props;
	const id = `${meta.form}.${input.name}.${prop}`;

	const handleChange = onChange(props);
	const handleClick = onClick(props);
	const restProps = _.omit(rest, ['eventListeners']);

	return (
		<div className={classnames(css.field, css.fieldRadio, className)}>
			<label>
				<div className={classnames(css.fieldRadioInner)}>
					<input
						id={id}
						{...restProps}
						{...input}
						value={prop}
						checked={input.value === '' ? prop === defaultValue : prop === input.value}
						onChange={handleChange}
						onBlur={_.noop} // Important because of default onBlur causes value reassignment to true
						type="radio"
						onClick={handleClick}
					/>
					<span className={classnames(css.fieldRadionBox)} />
				</div>
			</label>
		</div>
	);
};

Radio.defaultProps = {
	className: '',
	defaultValue: undefined,
};

/*
 * Example
 * name - form values key
 * prop - value to assign to form {key: value}

<Field
	name="gender"
	prop="male"
	component={Radio}
/>
*/
