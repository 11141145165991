import React from 'react';

import { Modal, ModalBody, ModalFooter } from 'admin/components/common/Modal';
import type { Props as ModalProps } from 'admin/components/common/Modal';
import { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';
import { MODAL } from 'admin/constants/common';

import Button from 'src/routes/admin/components/common/Button';
import WebhookLogs from './WebhookLogs';

type Props = ModalManagerProvidedProps<MODAL.WEBHOOK_LOGS> & ModalProps;

const WebhookLogsModal = (props: Props) => {
	const { webhookId } = props.data;

	return (
		<Modal
			open={props.open}
			isNested={props.isNested}
			destroyOnClose
			theme={props.theme}
			maskColor="black"
			onCancel={props.close}
		>
			<ModalBody>
				<WebhookLogs webhookId={webhookId} />
			</ModalBody>
			<ModalFooter>
				<Button view="primary" onClick={props.close}>
					Close
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default WebhookLogsModal;
