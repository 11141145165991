import { createSelector } from 'reselect';
import { ClientReducerState } from 'client/reducers';

export const selectEditor = (state: ClientReducerState) => state.editor;

export const selectEditorState = createSelector(selectEditor, editor => editor.state);

export const selectEditorSelection = createSelector(selectEditor, editor => editor.selection);

export const selectEditableElement = createSelector(selectEditorSelection, state => Object.values(state.clicked)?.[0]);

export const selectEditableElementId = createSelector(selectEditableElement, element => element?._id);

export const selectEditableElementNodeId = createSelector(
	selectEditableElement,
	element => element?.uiConfig.nodeProps.id
);

export const selectIsNodeSelected = createSelector(
	[selectEditorSelection, (state: ClientReducerState, nodeId: string) => nodeId],
	(selection, nodeId) => selection.clicked[nodeId] !== undefined
);
