import { SET_STORY_CARD_DATA } from 'client/constants/actions';
import { CardData } from 'types/story';

/**
 * @param payload {Object} Story card data object
 * @return {{type: *, payload: *}}
 */
export function setStoryCardData(payload: CardData) {
	return {
		type: SET_STORY_CARD_DATA,
		payload,
	};
}
