import { useState, useEffect } from 'react';

type ScriptStatus = 'idle' | 'loading' | 'loaded' | 'error';

function loadScript(src: string): Promise<Event> {
	return new Promise((resolve, reject) => {
		const script = document.createElement('script');
		script.src = src;
		script.async = true;
		script.onload = resolve;
		script.onerror = reject;
		document.body.appendChild(script);
	});
}

function useScript(scriptUrl: string): ScriptStatus {
	const [scriptStatus, setScriptStatus] = useState<ScriptStatus>('idle');

	useEffect(() => {
		if (!scriptUrl) {
			setScriptStatus('idle');
			return;
		}

		setScriptStatus('loading');

		loadScript(scriptUrl)
			.then(() => {
				setScriptStatus('loaded');
			})
			.catch(error => {
				console.error(`Error loading script: ${scriptUrl}`, error);
				setScriptStatus('error');
			});
	}, [scriptUrl]);

	return scriptStatus;
}

export default useScript;
