import _ from 'lodash';
import React, { TextareaHTMLAttributes } from 'react';
import cn from 'classnames';
import Textarea from 'react-textarea-autosize';
import { InspectorTypes } from 'admin/components/pages/Story/CardEditor/Inspector/types';
import { ReduxFieldInputTypes, ReduxFieldMetaTypes } from 'admin/components/common/Form/utils';
import css from './TextareaAutosize.scss';

type Props = TextareaHTMLAttributes<any> & {
	input: ReduxFieldInputTypes;
	meta: ReduxFieldMetaTypes;
	className?: string;
	minRows?: number;
	eventListeners?: ReturnType<InspectorTypes.GetFieldListeners>;
};

const TextareaAutosize = (props: Props) => {
	const { eventListeners, meta, input, className, minRows } = props;

	return (
		<Textarea
			{...input}
			placeholder={props.placeholder}
			id={`${meta.form}.${input.name}`}
			className={cn(css.pfTextareaAutosize, className)}
			onFocus={event => {
				input.onFocus(event);
				_.invoke(eventListeners, 'onFocus', event);
			}}
			onBlur={event => {
				const isValueChanged = props.meta.initial !== props.input.value;
				input.onBlur(event);
				if (isValueChanged) {
					_.invoke(eventListeners, 'onBlur', event);
				}
			}}
			onChange={event => {
				input.onChange(event);
				_.invoke(eventListeners, 'onChange', event);
			}}
			onKeyDown={event => {
				_.invoke(input, 'onKeyDown', event);
				_.invoke(eventListeners, 'onKeyDown', event);
			}}
			onClick={props.eventListeners?.onClick}
			minRows={minRows}
		/>
	);
};

TextareaAutosize.defaultProps = {
	className: '',
	minRows: 1,
	eventListeners: {
		onChange: _.noop,
	},
};

export default TextareaAutosize;
