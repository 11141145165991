import produce from 'immer';
import { merge } from 'lodash';
import { MemberPreferences } from 'src/types';
import { createThunkAction } from 'admin/actions/helpers';
import { selectUserId } from 'admin/reducers/user/selectors';
import { GET_MEMBER_PREFERENCES, SET_MEMBER_PREFERENCES } from 'admin/constants/actions';
import {
	getMemberPreferences as getMemberPreferencesEndpoint,
	setMemberPreferences as setMemberPreferencesEndpoint,
} from 'admin/resources';
import { STORIES_FILTER, STORIES_FILTER_DEFAULT } from 'admin/constants/common';

type GetMemberPreferences = {
	memberId?: string;
};

export const getMemberPreferences = createThunkAction<MemberPreferences, GetMemberPreferences>({
	skipLoading: true,
	type: GET_MEMBER_PREFERENCES,
	payloadCreator: async (params, { getState }) => {
		const id = params.memberId || selectUserId(getState());
		const { body } = await getMemberPreferencesEndpoint.params({ memberId: id }).send();
		const preferences = body.result.preferences as MemberPreferences;

		// fix (the following fields are not needed, previously added by mistake)
		if (preferences && 'result' in preferences) delete preferences.result;
		if (preferences && 'success' in preferences) delete preferences.success;

		// fix old format
		const orderBy = preferences?.storiesFilter?.orderBy?.replace(',desc', '') || STORIES_FILTER_DEFAULT.orderBy;

		return {
			...body,
			result: {
				...preferences,
				storiesFilter: {
					...preferences?.storiesFilter,
					orderBy,
				},
			},
		};
	},
});

export type SetMemberPreferences = {
	memberId?: string;
	preferences: Partial<MemberPreferences>;
};

export const setMemberPreferences = createThunkAction<MemberPreferences, SetMemberPreferences>({
	skipLoading: true,
	type: SET_MEMBER_PREFERENCES,
	payloadCreator: async (params, { getState }) => {
		const { user } = getState();
		const id = params.memberId || user.id;
		const preferences = merge({}, user.preferences, params.preferences);

		const reqParams = produce({ preferences }, draft => {
			// Prevent saving 'status' and 'createdBy' fields in storiesFilter on backend side:
			draft.preferences.storiesFilter.status = STORIES_FILTER.status.all;
			draft.preferences.storiesFilter.createdBy = STORIES_FILTER.createdBy.team;

			// deprecated
			delete draft.preferences.editor?.width;
			delete draft.preferences.editor?.platform;
		});

		await setMemberPreferencesEndpoint.params({ memberId: id }).send(reqParams);

		return { success: true, result: preferences };
	},
});
