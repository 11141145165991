/* eslint-disable no-plusplus */

import _ from 'lodash';
import dayjs from 'dayjs';

import type { StoryModel } from 'types/story';
import type { IReportStoryViewGraph, IReportStoryCardByCard, IReportStoryBigPicture } from 'src/types';
import translate from 'utils/translate';
import {
	GET_BIG_PICTURE_STORY_REPORT,
	GET_BY_CARD_STORY_REPORT,
	GET_BY_CARD_CSV_URL,
	GET_STORY_AUDIENCE,
} from 'admin/constants/actions';
import { STORY_REPORT_DATA_INTERVALS } from 'admin/constants/common';
import {
	getReportStoryBigPicture as getReportStoryBigPictureEndpoint,
	getCsvUrlStoryByCard as getCsvUrlStoryByCardEndpoint,
	getReportStoryByCard as getReportStoryByCardEndpoint,
	getStoryAudience as getStoryAudienceEndpoint,
	ApiType,
} from 'admin/resources';
import { createThunkAction } from 'admin/actions/helpers';
import { selectFeatureFlags } from 'admin/reducers/user/selectors';

const handleOverloadError: Parameters<typeof createThunkAction>[0]['onError'] = ({ error }) => {
	if (error && error.method && !error.status) throw new Error(translate('responseError.overload'));
};

type ReportStoryBigPicture = { after?: string; before?: string; interval?: string };

const getDefaultsDateRangeValues = (
	params: ReportStoryBigPicture,
	data: IReportStoryViewGraph[]
): IReportStoryViewGraph[] => {
	const result: IReportStoryViewGraph[] = [];

	switch (params.interval) {
		case STORY_REPORT_DATA_INTERVALS.DAILY: {
			let date = dayjs(params.before);

			for (let i = 0; i < 31; ++i) {
				if (date.isSame(params.after) || date.isBefore(params.after)) {
					break;
				}

				result.push({
					__timestamp: date.format('YYYY-MM-DD HH:mm:ss'),
					count: '0',
				});

				date = date.subtract(1, 'd');
			}

			result.shift();

			break;
		}
		case STORY_REPORT_DATA_INTERVALS.WEEKLY: {
			const startWeek = dayjs(params.before).startOf('isoWeek');
			let date = startWeek.clone();

			for (let i = 0; i < 31; ++i) {
				if (date.isSame(params.after) || date.isBefore(params.after)) {
					break;
				}

				result.push({
					__timestamp: date.format('YYYY-MM-DD HH:mm:ss'),
					count: '0',
				});

				date = date.subtract(1, 'w');
			}

			break;
		}
		case STORY_REPORT_DATA_INTERVALS.MONTHLY: {
			let date = dayjs(params.before).startOf('month');

			for (let i = 0; i < 14; ++i) {
				if (date.isBefore(params.after)) {
					break;
				}

				result.push({
					__timestamp: date.format('YYYY-MM-DD'),
					count: '0',
				});

				date = date.subtract(1, 'M');
			}
			break;
		}
		default:
			break;
	}

	return _.reverse(
		_.reduce(
			result,
			(acc: IReportStoryViewGraph[], item: IReportStoryViewGraph) => {
				const targetIndex = _.findIndex(data, (date: IReportStoryViewGraph) =>
					dayjs(date.__timestamp).isSame(item.__timestamp)
				);

				if (targetIndex >= 0) {
					acc.push(data[targetIndex]);
				} else {
					acc.push(item);
				}

				return acc;
			},
			[]
		)
	);
};

export type GetReportStoryBigPictureResult = {
	story: StoryModel;
	analytics: IReportStoryBigPicture;
};

export const getReportStoryBigPicture = createThunkAction<
	GetReportStoryBigPictureResult,
	{ storyId: string; query?: ReportStoryBigPicture; api: ApiType }
>({
	type: GET_BIG_PICTURE_STORY_REPORT,
	payloadCreator: async params => {
		const query = {
			interval: STORY_REPORT_DATA_INTERVALS.DAILY,
			...params.query,
		};
		const response = await getReportStoryBigPictureEndpoint(params.api)
			.params({ storyId: params.storyId })
			.send(query);
		const { story, analytics } = response.body.result as GetReportStoryBigPictureResult;

		analytics.viewsGraph = getDefaultsDateRangeValues(query, analytics.viewsGraph || []);

		return { ...response.body, result: { story, analytics } };
	},
	retries: 3,
	onError: handleOverloadError,
});

export type GetReportStoryByCardResult = {
	story: StoryModel;
	analytics: IReportStoryCardByCard;
};

export const getReportStoryByCard = createThunkAction<GetReportStoryByCardResult, { storyId: string; api: ApiType }>({
	type: GET_BY_CARD_STORY_REPORT,
	payloadCreator: async ({ storyId, api }) => {
		const response = await getReportStoryByCardEndpoint(api).params({ storyId }).send();
		return response.body;
	},
	retries: 3,
	onError: handleOverloadError,
});

/**
 * Get story audience data
 */
export const getStoryAudience = createThunkAction<{ url: string }, { storyId: string }>({
	type: GET_STORY_AUDIENCE,
	payloadCreator: async ({ storyId }) => {
		const response = await getStoryAudienceEndpoint
			.params({ storyId })
			.send({ mimetype: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		return response.body;
	},
});

export const getCsvUrlStoryByCard = createThunkAction<{ url: string }, { storyId: string }>({
	type: GET_BY_CARD_CSV_URL,
	payloadCreator: async ({ storyId }, { getState }) => {
		const api = selectFeatureFlags(getState())['read-from-new-analytics-db'] ? 'v2r' : 'v1';
		const response = await getCsvUrlStoryByCardEndpoint(api)
			.params({ storyId })
			.send({ mimetype: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		return response.body;
	},
});
