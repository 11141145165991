import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import t from 'utils/translate';
import { SETTINGS_BILLING_PAGE } from 'admin/constants/routes';
import Text from 'admin/components/common/Text';
import Button from 'admin/components/common/Button';
import { Column } from 'admin/components/common/Grid';
import { Modal } from 'admin/components/common/Modal';
import type { Props as ModalProps } from 'admin/components/common/Modal';
import { selectLoadingStateByName } from 'admin/reducers/loading/selectors';
import type { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';
import { CANCEL_SUBSCRIPTION } from 'admin/constants/actions';
import { MODAL } from 'admin/constants/common';
import type { AdminReducerState } from 'admin/reducers';

import css from './InfoModal.scss';

export type InfoModalProps = {
	view?: keyof typeof VIEWS;
	title?: string;
	cancelDate?: string;
	theme?: 'light' | 'dark';
	onSave?: () => void;
};

type Props = ModalManagerProvidedProps<MODAL.INFORMATION> & ModalProps;

const PlanLimit: React.FC<Props> = props => (
	<>
		<div className={css.head}>
			<Text
				size={Text.size.subtitle}
				weight={Text.weight.semibold}
				compact
				text={props.data?.title || t('infoModal.planLimit.title')}
			/>
		</div>
		<div className={css.body}>
			<Text size={Text.size.paragraph} text={t('infoModal.planLimit.text')} />
		</div>
		<Column className={css.footer} justifyContent="flex-start">
			<Link to={SETTINGS_BILLING_PAGE}>
				<Button view="danger" textSize="paragraph" onClick={props.close}>
					{t('infoModal.planLimit.ok')}
				</Button>
			</Link>
			<Button view="secondary-danger" color="danger" onClick={props.close}>
				{t('infoModal.planLimit.cancel')}
			</Button>
		</Column>
	</>
);

const PermissionDenied: React.FC<Props> = ({ data, ...props }) => (
	<>
		<div className={css.head}>
			<Text
				size={Text.size.subtitle}
				weight={Text.weight.semibold}
				compact
				text={t('infoModal.permission.title')}
			/>
		</div>
		<div className={css.body}>
			<Text size={Text.size.paragraph} text={t('infoModal.permission.text')} />
		</div>
		<Column className={css.footer} justifyContent="flex-start">
			<Button view="danger" onClick={props.close} textSize="paragraph">
				{t('infoModal.permission.ok')}
			</Button>
		</Column>
	</>
);

const CancelSubscription: React.FC<Props> = props => {
	const isBusy = useSelector((state: AdminReducerState) =>
		selectLoadingStateByName(state, CANCEL_SUBSCRIPTION.PENDING)
	);

	const onSubmitClick = async () => {
		await props.data?.onSave?.();
		props.close();
	};

	return (
		<>
			<div className={css.head}>
				<Text
					size={Text.size.subtitle}
					weight={Text.weight.semibold}
					compact
					text={t('infoModal.cancelSubscription.title')}
				/>
			</div>
			<div className={css.body}>
				<Text
					size={Text.size.paragraph}
					text={t('infoModal.cancelSubscription.text', { date: props.data?.cancelDate })}
				/>
			</div>
			<Column className={css.footer} justifyContent="flex-start">
				<Button view="primary" textSize="paragraph" loading={isBusy} onClick={onSubmitClick}>
					{t('infoModal.cancelSubscription.ok')}
				</Button>
				<Button view="secondary-danger" color="danger" onClick={props.close}>
					{t('infoModal.cancelSubscription.cancel')}
				</Button>
			</Column>
		</>
	);
};

const ChangePaymentPeriodDenied: React.FC<Props> = ({ data, ...props }) => (
	<>
		<div className={css.head}>
			<Text
				size={Text.size.subtitle}
				weight={Text.weight.semibold}
				compact
				text={t('infoModal.changePaymentPeriod.title')}
			/>
		</div>
		<div className={css.body}>
			<Text size={Text.size.paragraph} text={t('infoModal.changePaymentPeriod.text')} />
		</div>
		<Column className={css.footer} justifyContent="flex-start">
			<Button view="danger" onClick={props.close} textSize="paragraph">
				{t('infoModal.permission.ok')}
			</Button>
		</Column>
	</>
);

const StoryOpCounterOutOfSync: React.FC<Props> = ({ data, ...props }) => (
	<>
		<div className={css.head}>
			<Text
				size={Text.size.subtitle}
				weight={Text.weight.semibold}
				compact
				text={t('infoModal.outOfSync.title')}
			/>
		</div>
		<div className={css.body}>
			<Text size={Text.size.paragraph} text={t('infoModal.outOfSync.text')} />
		</div>
		<Column className={css.footer} justifyContent="flex-start">
			<Button view="danger" onClick={() => window.location.reload()} textSize="paragraph">
				{t('infoModal.outOfSync.ok')}
			</Button>
		</Column>
	</>
);

const WebhookDatabridgeEnable: React.FC<Props> = props => {
	return (
		<>
			<div className={css.head}>
				<Text
					size={Text.size.subtitle}
					weight={Text.weight.semibold}
					compact
					text={t('infoModal.webhookDatabridgeEnable.title')}
				/>
			</div>
			<div className={cn(css.body, css.dimmed)}>
				<Text size={Text.size.paragraph} text={t('infoModal.webhookDatabridgeEnable.text')} />
			</div>
			<Column className={css.footer} justifyContent="flex-start">
				<Button view="primary" textSize="paragraph" onClick={props.data?.onSave}>
					{t('infoModal.webhookDatabridgeEnable.ok')}
				</Button>
				<Button view="secondary-gray" onClick={props.close}>
					{t('infoModal.webhookDatabridgeEnable.cancel')}
				</Button>
			</Column>
		</>
	);
};

const VIEWS = {
	'PERMISSION/DENIED': PermissionDenied,
	'PLAN/REACHED_LIMIT': PlanLimit,
	'PLAN/CHANGE_PAYMENT_PERIOD_DENIED': ChangePaymentPeriodDenied,
	'PLAN/CANCEL_SUBSCRIPTION': CancelSubscription,
	'STORY/OP_COUNTER_OUT_OF_SYNC': StoryOpCounterOutOfSync,
	'WEBHOOK/DATABRIDGE/ENABLE': WebhookDatabridgeEnable,
};

const InfoModal = ({ open, data: d = {}, ...props }: Props) => {
	const data = { view: 'PERMISSION/DENIED', ...d };
	const Component = VIEWS[data.view] ?? 'span';

	return (
		<Modal
			open={open}
			destroyOnClose
			width={parseInt(css.modalWidth, 10)}
			className={css.modal}
			theme={d.theme}
			isNested={props.isNested}
			maskColor={d.theme === 'dark' ? 'black' : 'light'}
			onCancel={props.close}
		>
			<Component {...props} data={data} />
		</Modal>
	);
};

export default InfoModal;
