import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { Popover, PopoverProps } from 'antd';

import { generateId } from 'common/utils/generate-id';
import { SETTINGS_PAGE } from 'admin/constants/routes';
import css from './Tooltip.scss';

type TooltipProps = PopoverProps & {
	isStatic?: boolean; // tooltip won't move with mouse cursor
	stylePreset?: '' | 'dark-1' | 'dark-2';
	isHelp?: boolean;
	disableAnimation?: boolean;
	className?: string;
	children: React.ReactNode;
};

const settingsPreset: Partial<Record<Exclude<TooltipProps['stylePreset'], undefined>, Partial<TooltipProps>>> = {
	'dark-2': {
		overlayInnerStyle: { maxWidth: 168 },
		isHelp: false,
		isStatic: true,
		stylePreset: 'dark-2',
		destroyTooltipOnHide: true,
		mouseEnterDelay: 0.001,
		mouseLeaveDelay: 0.001,
		disableAnimation: true,
		trigger: 'hover',
	},
};

const getTransformChild = (
	children: React.ReactNode,
	props: {
		isStatic?: boolean;
		isHelp: boolean;
		className?: string;
		onMouseMove: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
	}
) => {
	const childrenProps = {
		className: cn(React.isValidElement(children) ? children.props.className : '', props.className, {
			[css.isHelp]: props.isHelp,
		}),
		onMouseMove: props.isStatic ? undefined : props.onMouseMove,
	};

	if (React.isValidElement(children)) {
		return React.cloneElement(children, childrenProps);
	}

	return React.createElement('span', childrenProps, children);
};

const Tooltip: React.FC<TooltipProps> = props => {
	const id = React.useRef(generateId(4));
	const stylePreset = props.stylePreset ?? '';
	const preset = settingsPreset[stylePreset];
	const popoverProps = { isHelp: true, stylePreset, ...preset, ...props };

	const onMouseMove = React.useCallback(async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		const leftStyle = `${e.clientX - 20}px`;
		const topStyle = `${e.clientY + 10}px`;
		const el = document.getElementsByClassName(id.current)[0] as HTMLDivElement;
		if (el) {
			el.style.left = leftStyle;
			el.style.top = topStyle;
		}
	}, []);

	return (
		<Popover
			trigger="hover"
			placement="bottomLeft"
			destroyTooltipOnHide={false}
			mouseEnterDelay={0.3}
			mouseLeaveDelay={0.1}
			{...popoverProps}
			overlayClassName={cn(popoverProps.overlayClassName, id.current, css[popoverProps.stylePreset], {
				[css.noAnimate]: popoverProps.disableAnimation,
			})}
		>
			{getTransformChild(popoverProps.children, {
				isStatic: popoverProps.isStatic,
				isHelp: popoverProps.isHelp,
				className: popoverProps.className,
				onMouseMove,
			})}
		</Popover>
	);
};

export const ThirdPartyCookiesTooltipContent = (
	<>
		In order to use this feature you must connect a site subdomain on the{' '}
		<Link to={SETTINGS_PAGE}>settings page</Link>
	</>
);

export default React.memo(Tooltip);
