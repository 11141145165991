import React, { useEffect, useState } from 'react';
import type { CardTemplate, StoryMediaPlatform, StoryMediaQuery, StorySettingsType } from 'types/story';
import t from 'utils/translate';
import UrlHelper from 'utils/url-helper';
import { CardFacade } from 'utils/facades/card-facade';
import { getAssetURL } from 'utils/assets';
import { fitTextToSize } from 'utils/fit-text-to-size';
import { CARD_TYPE, DEFAULT_MEDIA_QUERY_PLATFORMS } from 'common/constants';
import { useAdminDispatch } from 'admin/reducers';
import Text from 'admin/components/common/Text';
import Button from 'admin/components/common/Button';
import useToggle from 'common/components/useToggle';
import { Column, Grid } from 'admin/components/common/Grid';
import { getCardTemplates, getStoryTemplate } from 'admin/actions/story/templates';
import { getCardType } from 'admin/components/pages/Story/Flow/CreateCardModal/utils';
import Layout from 'admin/components/pages/Stories/CreateStoryModal/ShareComponents/Layout';
import PlatformSelect from 'admin/components/pages/Stories/CreateStoryModal/ShareComponents/PlatformSelect';
import CardThumbnail from 'admin/components/pages/Stories/CreateStoryModal/ShareComponents/CardThumbnail';
import PreviewStory from 'admin/components/pages/Stories/CreateStoryModal/ShareComponents/PreviewStory';
import css from './CreateCardModal.scss';

type Props = {
	storyTemplateId: StorySettingsType['templateId'];
	template?: CardTemplate;
	mediaQuery: StoryMediaQuery;
	onUseClick: (template: CardTemplate[]) => Promise<void>;
	goToGallery: () => void;
};

const getScreenshot = (template: CardTemplate) =>
	getAssetURL(template.metadata.screenshot?.[template.metadata.defaultPlatform]);

export const TemplatePreview: React.FC<Props> = ({ storyTemplateId, template, goToGallery, ...props }) => {
	const dispatch = useAdminDispatch();
	const [platform, setPlatform] = useState<StoryMediaPlatform>(props.mediaQuery.defaultPlatform);
	// template that is in preview
	const [activeTemplate, setActiveTemplate] = useState(template);
	// templates that are selected for create
	const [selectedTemplates, setSelectedTemplates] = useState(template ? [template] : []);
	// templates that are relative to current card or story template
	const [relatedTemplates, setRelatedTemplates] = useState<CardTemplate[]>([]);
	const isBusy = useToggle(false);

	const onUseClick = () => {
		isBusy.set(true);
		props.onUseClick(selectedTemplates).finally(() => isBusy.set(false));
	};

	const onTemplateSelect = (_template: CardTemplate) => {
		setSelectedTemplates(_prev =>
			_prev.some(o => o.id === _template.id)
				? /* exclude */ _prev.filter(o => o.id !== _template.id)
				: /* include */ [..._prev, _template]
		);
	};

	useEffect(() => {
		async function getRelatedTemplates() {
			if (!template && typeof storyTemplateId === 'number') {
				// Get card templates related to story template
				const response = await dispatch(getStoryTemplate({ templateId: storyTemplateId }));
				const relatedCards = response.success && response.result ? response.result.relatedCards : [];
				if (relatedCards.length) {
					setActiveTemplate(relatedCards[0]);
					setSelectedTemplates(relatedCards.slice(0, 1));
					setRelatedTemplates(relatedCards);
				} else {
					goToGallery();
				}
			} else if (template?.categories.length) {
				// Get similar templates to card template
				const tags = encodeURIComponent(template.categories.filter(c => CARD_TYPE[c] === undefined).join(','));
				if (!tags) return;
				const { success, result } = await dispatch(getCardTemplates({ excludeEditor: false, tags }));
				if (success && result) {
					setRelatedTemplates((result.templates as CardTemplate[]).filter(o => o.id !== template.id));
				}
			}
		}

		getRelatedTemplates();
	}, [dispatch, template, storyTemplateId, goToGallery]);

	const isCoverMode = platform === DEFAULT_MEDIA_QUERY_PLATFORMS.DESKTOP;

	return (
		<>
			<Layout.Panel>
				<Grid columns="12" style={{ width: '100%' }}>
					<Column colStart="4" colSpan="6" justifyContent="center">
						<PlatformSelect
							mediaQuery={props.mediaQuery}
							platform={platform}
							onPlatformChange={setPlatform}
						/>
					</Column>

					<Column colStart="10" colSpan="3" justifyContent="flex-end">
						<Button
							onClick={onUseClick}
							smallText
							textWeight={Text.weight.normal}
							disabled={selectedTemplates.length === 0 || isBusy.value}
							loading={isBusy.value}
						>
							{t('story.createCardModal.useTemplate')}
							{selectedTemplates.length > 1 ? ` (${selectedTemplates.length})` : ''}
						</Button>
					</Column>
				</Grid>
			</Layout.Panel>
			<Layout.Aside className={Layout.className.pt35}>
				<div className={css.cardThumbnails}>
					{template ? (
						<CardThumbnail
							type="card"
							title={CardFacade.getCardNameByType(getCardType(template))}
							subtitle={fitTextToSize(template.name, 24, 'end')}
							screenshot={getScreenshot(template)}
							data={template}
							onClick={setActiveTemplate}
							onSelect={onTemplateSelect}
							isSelected={selectedTemplates.some(s => s.id === template.id)}
							isActive={activeTemplate?.id === template.id}
						/>
					) : null}

					{relatedTemplates.length > 0 ? (
						<div className={css.similarTemplates}>
							<Text
								size={Text.size.label}
								transform={Text.transform.uppercase}
								color={Text.color.blackDark}
								compact
							>
								Add related cards
							</Text>
							{relatedTemplates.map(o => (
								<CardThumbnail
									key={o.id}
									type="card"
									title={CardFacade.getCardNameByType(getCardType(o))}
									subtitle={fitTextToSize(o.name, 24, 'end')}
									screenshot={getScreenshot(o)}
									data={o}
									onClick={setActiveTemplate}
									onSelect={onTemplateSelect}
									isSelected={selectedTemplates.some(s => s.id === o.id)}
									isActive={activeTemplate?.id === o.id}
								/>
							))}
						</div>
					) : null}
				</div>
			</Layout.Aside>
			<Layout.Content coverMode={isCoverMode} style={{ backgroundColor: 'var(--ra-color-black-light)' }}>
				{activeTemplate ? (
					<PreviewStory
						coverMode={isCoverMode}
						mediaQuery={props.mediaQuery}
						platform={platform}
						src={UrlHelper.getPublishedUrl({
							clientStoryId: activeTemplate.metadata.clientStoryId,
							cardId: activeTemplate.metadata.cardId,
						})}
					/>
				) : null}
			</Layout.Content>
		</>
	);
};
