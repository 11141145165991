import React, { useContext } from 'react';

export const THEME = {
	LIGHT: 'light',
	DARK: 'dark',
} as const;

export type ThemeContext = { theme: ValuesType<typeof THEME> };
export const Context = React.createContext<ThemeContext>({ theme: THEME.LIGHT });
export const useTheme = () => useContext(Context);
export const ThemeProvider: React.FC<{ children: React.ReactNode; context: ThemeContext }> = props => {
	return <Context.Provider value={props.context}>{props.children}</Context.Provider>;
};
