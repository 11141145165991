import React from 'react';

import t from 'utils/translate';

import { Icon } from 'admin/components/common/Icon';
import { TextField } from 'admin/components/common/Form';

import css from './Search.scss';

interface Props {
	value?: string;
	onSearch: (v: string) => void;
}

const DEFAULT_VALUE = '';

const Search = (props: Props) => {
	const { onSearch } = props;
	const [value, setValue] = React.useState(props.value || DEFAULT_VALUE);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
		onSearch(e.target.value);
	};

	React.useEffect(() => {
		if (props.value !== undefined) setValue(props.value);
	}, [props.value]);

	return (
		<div className={css.search}>
			<TextField
				className={css.searchInput}
				name="search"
				theme="dark"
				placeholder={t('story.mediaGalleryModal.searchInputPlaceholder')}
				onChange={handleChange}
				autoComplete="off"
				value={value}
				suffix={<Icon type="search" width={24} color="white" />}
			/>
		</div>
	);
};

export default Search;
