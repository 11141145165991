import { createThunkAction } from 'admin/actions/helpers';
import { AIImageSize } from 'admin/actions/story/ai/types';
import { getAIUsage as getAIUsageEndpoint } from 'admin/resources';

interface Params {
	organizationId: string;
}

export interface TextUsage {
	usage: number;
	dimension: 'input' | 'output';
	type: 'ai_text';
}

export interface ImageUsage {
	usage: number;
	dimension: AIImageSize;
	type: 'ai_images';
}

interface StoryUsage {
	storyId: string;
	storyName: string;
	teamName: string;
	usage: number;
}

export interface OrganizationAIUsageResult {
	monthly: (TextUsage | ImageUsage)[];
	stories: StoryUsage[];
}

export const getAIUsage = createThunkAction<OrganizationAIUsageResult, Params>({
	payloadCreator: async ({ organizationId }) => {
		const response = await getAIUsageEndpoint.params({ organizationId }).send({});
		return { success: true, result: response.body };
	},
});
