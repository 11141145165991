import _ from 'lodash';
import config from 'application-config';
import { IS_DEVEL } from 'utils/environment';
import { prependUrlProtocol } from 'utils/helpers';
import { PARAMS } from 'common/constants';

function withDynamicHostname(urlParam: string) {
	try {
		const url = new URL(prependUrlProtocol(urlParam, 'https'));
		const pathname = url.pathname === '/' ? '' : url.pathname;
		return `${url.protocol}//${window.location.hostname}${pathname}`;
	} catch (e) {
		return urlParam;
	}
}

export const addEndingSlash = (val = '') => (val.match(/\/$/) ? val : `${val}/`);

const domains = {
	FRONTEND_ADMIN: 'frontend.admin',
	FRONTEND_CLIENT: 'frontend.client',
	BACKEND_ADMIN: 'backend.admin',
	BACKEND_CLIENT: 'backend.client',
};
type ConfigDomain = {
	hostname: string;
	port?: number;
};

type Config = {
	GA_SC_GENERAL: string;
	api: {
		adminBackend: string;
		adminBackendV2: string;
		adminBackendV2R: string;
		clientBackend: string;
	};
	domains: {
		backend: {
			admin: ConfigDomain;
			client: ConfigDomain;
		};
		frontend: {
			admin: ConfigDomain;
			client: ConfigDomain;
		};
	};
	cname: string;
};
/**
 * UrlHelper class to help get resource url for our application
 * Singleton
 */
class UrlHelper {
	config: Config;

	/**
	 * Create UrlHelper instance
	 *
	 * @param configParam {Object} url configuration
	 * @return UrlHelper {Object} instance
	 */
	constructor(configParam: Config) {
		this.config = configParam;
	}

	get cname() {
		return this.config.cname;
	}

	/**
	 * Get backend host url
	 * @return {string}  admin host url
	 */
	get clientBackend() {
		return IS_DEVEL ? this.config.api.clientBackend : withDynamicHostname(this.config.api.clientBackend);
	}

	/**
	 * Get admin host url
	 * @return {string}  admin host url
	 */
	get adminBackend() {
		return this.config.api.adminBackend;
	}

	get adminBackendV2() {
		return this.config.api.adminBackendV2;
	}

	get adminBackendV2R() {
		return this.config.api.adminBackendV2R;
	}

	/**
	 * Get client host url
	 * @return {string}  client host url
	 */
	get client() {
		return this.getDomain(domains.FRONTEND_CLIENT);
	}

	/**
	 * Get admin host url
	 * @return {string}  admin host url
	 */
	get admin() {
		return this.getDomain(domains.FRONTEND_ADMIN);
	}

	/**
	 * @example getDomain('FRONTEND_ADMIN')
	 */
	getDomain(domain: string): string {
		const domainProps = _.get(config.domains, domain);
		return domainProps.port ? `${domainProps.hostname}:${domainProps.port}` : domainProps.hostname;
	}

	/**
	 * Get url of published story
	 */
	getPublishedUrl(props: {
		clientStoryId: string;
		cardId?: string;
		customDomain?: string;
		storycardsDomain?: string;
		noProtocol?: boolean;
		// by default, client app doing a redirect to first card in story. this flag will cancel this behaviour.
		preventRedirect?: boolean;
	}) {
		const params = props.cardId
			? `?cardId=${props.cardId}${props.preventRedirect ? `&${PARAMS.REDIRECT}=0` : ''}`
			: '';
		const clientStoryId = addEndingSlash(props.clientStoryId);

		if (props.customDomain) {
			const [, storyId] = clientStoryId.split('/');
			const domain = addEndingSlash(`${props.customDomain}/${storyId}`);
			const url = `${domain}${params}`;
			return props.noProtocol ? url : prependUrlProtocol(url, 'https');
		}

		if (props.storycardsDomain) {
			const domain = addEndingSlash(`${props.storycardsDomain}/${clientStoryId}`);
			const url = `${domain}${params}`;
			return props.noProtocol ? url : prependUrlProtocol(url, 'https');
		}

		if (IS_DEVEL) {
			return `${props.noProtocol ? '' : 'https://'}story.dev.story-cards.com/${clientStoryId}${params}`;
		}

		const url = `${this.getDomain(domains.FRONTEND_CLIENT)}/${clientStoryId}${params}`;
		return props.noProtocol ? url : prependUrlProtocol(url, 'https');
	}
}

const UrlHelperInstance = new UrlHelper(config);

export const location = {
	client: {
		/**
		 * Is client app embedded in admin dashboard
		 * @desc is [cardEditor | cardPreview | embedPreview]
		 */
		isPreview:
			document.referrer &&
			document.referrer !== window.location.origin &&
			document.referrer.includes(UrlHelperInstance.admin),

		isPreviewRoute: window.location.pathname.match(/preview.html/g) !== null,

		isEmbedDevPreview: window.location.href.match(/preview.html\?embedDev/g) !== null,

		/**
		 * @desc is opened by screenshot API
		 */
		isScreenshot: () => window.location.href.match(/[&|?]screenshot/) !== null,
	},
};

export default UrlHelperInstance;
