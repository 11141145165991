import React, { useContext, useState } from 'react';
import cn from 'classnames';
import { MODAL } from 'admin/constants/common';
import Button from 'admin/components/common/Button';
import { Icon } from 'admin/components/common/Icon';
import { useDidMount } from 'common/components/useDidMount';
import { COMPONENT_STATES, AI_TEXT_ADJUSTMENT_OPTIONS } from 'common/constants';
import { ModalBody } from 'admin/components/common/Modal';
import { Select } from 'admin/components/common/Form/Select';
import { generateText } from 'admin/actions/story/ai/generate-text';
import { useAdminDispatch, useAdminSelector } from 'admin/reducers';
import { TextAreaPure } from 'admin/components/common/Form/TextArea';
import type { GenerateTextParams } from 'admin/actions/story/ai/types';
import { Context as ThemeContext } from 'admin/components/common/Theme';
import { selectCurrentPlatform } from 'admin/reducers/card-editor/selectors';
import { selectEditableElementPath } from 'admin/reducers/card-editor-extra/selectors';
import type { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';
import type { InspectorContextType } from 'admin/components/pages/Story/CardEditor/Inspector/InspectorContext';
import PromptHistory from './PromptHistory';
import css from '../EditorAIModal.scss';

export type GeneratorTextModalData = {
	view: 'text';
	onTextChange: InspectorContextType['onUpdate'];
	generateTextParams?: GenerateTextParams;
};

const text = {
	submitBtn: 'Generate',
	promptPh: 'Generate a title about the rarest animals in the Amazon rainforest.',
	adjust: 'Tone adjustment',
};

type Props = ModalManagerProvidedProps<MODAL.EDITOR_AI> & {
	setIsBusy: React.Dispatch<React.SetStateAction<boolean>>;
};

const GenerateText: React.FC<Props> = props => {
	const { generateTextParams } = props.data as GeneratorTextModalData;
	const dispatch = useAdminDispatch();
	const path = useAdminSelector(selectEditableElementPath);
	const platform = useAdminSelector(selectCurrentPlatform);
	const { theme } = useContext(ThemeContext);
	const [userPrompt, setUserPrompt] = useState(generateTextParams?.prompt || '');
	const [adjustment, setAdjustment] = useState(generateTextParams?.adjustment || AI_TEXT_ADJUSTMENT_OPTIONS[0].value);
	const isGenerateFromParams = generateTextParams !== undefined;

	const onSubmit = async () => {
		props.setIsBusy(true);

		const payload: Parameters<typeof generateText>[0] = {
			prompt: userPrompt,
			action: generateTextParams?.action,
		};

		if (adjustment) {
			payload.action = 'adjust';
			payload.adjustment = adjustment;
		}

		const generatedText = await dispatch(generateText(payload));

		if (generatedText.success && generatedText.result && 'onTextChange' in props.data) {
			props.data.onTextChange({
				value: generatedText.result,
				name: `elements.${path}.children.${COMPONENT_STATES.DEFAULT}.${platform}`,
			});
		}

		props.close();
	};

	useDidMount(() => {
		if (isGenerateFromParams) onSubmit();
	});

	return isGenerateFromParams ? null : (
		<ModalBody>
			<div className={cn(css.fields, css.view1)}>
				<TextAreaPure
					placeholder={text.promptPh}
					rows={3}
					theme={theme}
					autoFocus
					value={userPrompt}
					onChange={e => setUserPrompt(e.target.value)}
				/>
				<div className={css.fieldsFooter}>
					<Select
						className={css.selectStyle}
						label={{ children: text.adjust }}
						options={AI_TEXT_ADJUSTMENT_OPTIONS}
						value={adjustment}
						inline
						theme={theme}
						eventListeners={{
							onChange: setAdjustment,
						}}
						menuPlacement="top"
						maxMenuHeight={200}
					/>

					<Button
						className={css.btnSubmit}
						onClick={onSubmit}
						disabled={userPrompt.trim().length < 2}
						view="primary"
						theme={theme}
					>
						<Icon type="sc-magic" width={26} style={{ marginRight: 6 }} />
						{text.submitBtn}
					</Button>
				</div>
			</div>
			<PromptHistory type="text" onSelect={setUserPrompt} />
		</ModalBody>
	);
};

export default GenerateText;
