import React from 'react';
import { pick } from 'lodash';
import { Field } from 'redux-form';
import { HandleThunkActionCreator } from 'react-redux';
import { StoryModel } from 'types/story';
import { prependUrlProtocol } from 'common/utils/helpers';
import { CardFacade } from 'common/utils/facades/card-facade';
import { postGallery } from 'admin/actions/story/gallery';
import { Hint, MediaField, TextField } from 'admin/components/common/Form';
import { SHARE_TEXT_MAX_LEN } from 'admin/components/common/Form/validations';
import { names } from './utils';
import css from './CardSettingsModal.scss';

const fieldProps = {
	className: css.field,
	type: 'text',
	component: TextField,
	theme: 'dark',
	limit: { max: SHARE_TEXT_MAX_LEN },
	isRequired: true,
	isLabelUppercase: false,
} as const;

type Props = {
	card: CardFacade;
	postGallery: HandleThunkActionCreator<typeof postGallery>;
	storyId: StoryModel['id'];
};

const SeoView = (props: Props) => {
	const { cardId } = props.card;

	const onUpload = async (data: { asset: File; name: string }) => {
		const result = await props.postGallery({ asset: data.asset, storyId: props.storyId });
		return result.result?.asset ? { asset: prependUrlProtocol(result.result.asset, 'https') } : false;
	};

	return (
		<>
			<Field {...fieldProps} name={names.shareTitle(cardId)} label="Share title" />
			<Field {...fieldProps} name={names.shareDescription(cardId)} label="Share description" />
			<Field
				{...fieldProps}
				name={names.shareMsg(cardId)}
				label="Share message"
				description={
					<Hint
						className={css.shareMsgHint}
						theme={fieldProps.theme}
						weight="normal"
						text={
							'Text message to share with WhatsApp, X, Email. Text can use dynamic values:' +
							'\n• $score - user total score' +
							'\n• $answers - user answers count' +
							'\n• $correct - correct user answers count'
						}
					/>
				}
			/>
			<Field
				{...pick(fieldProps, ['className', 'theme', 'isRequired'])}
				name={names.shareImage(cardId)}
				component={MediaField}
				label="Share Image"
				size="large"
				fileType={MediaField.fileTypes.PNG_JPG}
				isLabelUppercase={false}
				previewSize="contain"
				description="Browse your image"
				fileDimensions={{ maxW: 1200, maxH: 630, minW: 200, minH: 200 }}
				eventListeners={{ onUpload }}
			/>
		</>
	);
};

export default SeoView;
