import ErrorBoundary from 'common/components/ErrorBoundary';
import AdminError from './AdminError';

class ErrorBoundaryAdmin extends ErrorBoundary {
	componentDidCatch(error, info) {
		if (error instanceof AdminError && error.notification) {
			return;
		}

		this.logToService(error, info);
	}
}

export default ErrorBoundaryAdmin;
