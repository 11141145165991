import _ from 'lodash';
import theme from 'client/styles/_export.scss';
import { COMPONENT_TYPE, CARD_ROOT_ELEMENT_INDEX as CREI } from 'common/constants';
import { isLayerType } from 'utils/blocks/is-layer-type';
import type { BBInheritanceParent, BBModel, BBSymbolInfo, CardData, ComponentTypes } from 'types/story';
import type { CreateComponentProps } from 'client/components/common/BuildingBlocks/utils/traverse-tree-types';

const INDEX = {
	/*
		Above 201+
		Content 200
		Below 100-199
		BG 0-99
	*/
	[COMPONENT_TYPE.FLOAT_ABOVE]: parseInt(theme.zIndexFloatAbove, 10),
	[COMPONENT_TYPE.FLOAT_BELOW]: parseInt(theme.zIndexFloatBelow, 10),
};

const CHILDREN_MAX = 500;
const baseIndex = (componentType: ComponentTypes) => INDEX[componentType];
const indexMax = (component: BBModel) => Math.max(0, _.size(component.children));
const computedIndex = (_baseIndex: number, _indexMax: number, index: number) => _baseIndex + _indexMax - index;
const findIndexOfGlobal = (component: BBModel, sourceIndex: number, cardElements: CardData['elements']) =>
	_.findIndex(cardElements[sourceIndex]?.children, ['_id', component._id]);

/**
 * Assign z-index to reverse layers (first is above and next is below)
 *
 * In addition cares for a FLA|FLB first level children z-indexes
 * to maintain layers order correctly in case of global BB (storyElements) is exists
 */
export function getElementZIndex({
	element,
	isCardTree,
	cardElements,
	index,
	parent,
	isFFC,
}: {
	element: BBModel & { symbol?: BBSymbolInfo };
	cardElements: CardData['elements'];
	parent: BBInheritanceParent[];
	isCardTree: boolean;
	index: number;
	isFFC: CreateComponentProps['isFFC'];
}) {
	if (!parent.length) {
		return undefined;
	}

	if (isFFC) {
		if (isCardTree && isLayerType(element).global) {
			return undefined; // skipped on render in traverseTree
		}

		const FLA_INDEX = CREI[COMPONENT_TYPE.FLOAT_ABOVE];
		const FLB_INDEX = CREI[COMPONENT_TYPE.FLOAT_BELOW];

		const FLA_MAX = indexMax(cardElements[FLA_INDEX]);
		const FLB_MAX = indexMax(cardElements[FLB_INDEX]);

		const FLOAT_TYPE = _.last(parent)!.type;
		const FLOAT_INDEX = FLOAT_TYPE === COMPONENT_TYPE.FLOAT_ABOVE ? FLA_INDEX : FLB_INDEX;
		const FLOAT_MAX = FLOAT_TYPE === COMPONENT_TYPE.FLOAT_ABOVE ? FLA_MAX : FLB_MAX;
		const ORIG_INDEX = isCardTree ? index : findIndexOfGlobal(element, FLOAT_INDEX, cardElements);

		return computedIndex(baseIndex(FLOAT_TYPE), FLOAT_MAX, ORIG_INDEX);
	}

	return CHILDREN_MAX - index;
}
