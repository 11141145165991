import React, { useCallback } from 'react';
import cn from 'classnames';
import _ from 'lodash';

import { GoogleFontItem } from 'src/types';

import { Icon } from 'admin/components/common/Icon';
import { CheckboxPure } from 'admin/components/common/Form/Checkbox';
import Text from 'admin/components/common/Text';

import css from './GoogleFontListItem.scss';

export type ChangeVariantsParams = {
	family: string;
	variant: string;
};

type Props = {
	data: GoogleFontItem;
	selected?: { family: string; variants: string[] } | undefined;
	isActive?: boolean;
	className?: string;
	isExpanded?: boolean;
	onChangeVariants?: (params: ChangeVariantsParams) => void;
	onClick?: (data: GoogleFontItem) => void;
};

export const GoogleFontListItem = React.memo<Props>(
	({
		isExpanded = false,
		selected = undefined,
		isActive = false,
		onChangeVariants = null,
		className = '',
		data,
		onClick = null,
	}) => {
		const selectedVariants = selected?.variants || [];

		const onItemClick = useCallback(() => {
			onClick?.(data);
		}, [onClick, data]);

		const onCheckboxChange = useCallback(
			(event: React.ChangeEvent<HTMLInputElement>) => {
				if (event.target.value) onChangeVariants?.({ family: data.family, variant: event.target.value });
			},
			[data.family, onChangeVariants]
		);

		return (
			<button
				onClick={onItemClick}
				type="button"
				className={cn(
					css.googleFontListItem,
					isActive && css.isActive,
					!onClick && css.defaultCursor,
					className
				)}
			>
				<div className={css.head}>
					<Text size="subheading" className={css.name} weight="semibold" tag="div">
						<Icon type="google-font" width={22} />
						<span>{data.family}</span>
					</Text>
					<Text tag="div">{`${_.size(data.variants)} styles`}</Text>
				</div>
				{isExpanded && (
					<div className={cn(css.styles, isExpanded && css.opened)}>
						{_.map(data.variants, variant => (
							<CheckboxPure
								key={variant}
								boxIcon="square"
								labelPosition="right"
								label={{
									children: (
										<Text
											text={_.capitalize(variant)}
											weight={Text.weight.normal}
											color={Text.color.black}
											style={{ paddingLeft: 12 }}
										/>
									),
								}}
								value={variant}
								checked={!!selectedVariants?.includes(variant)}
								onChange={onCheckboxChange}
								className={css.variant}
							/>
						))}
					</div>
				)}
			</button>
		);
	},
	(prevProps: Props, nextProps: Props) => {
		return _.isEqual(prevProps.selected, nextProps.selected);
	}
);
