import React, { Component } from 'react';
import { map, find, get, forEach, throttle, capitalize, defer } from 'lodash';
import cn from 'classnames';

import type { CardData } from 'types/story';
import t from 'common/utils/translate';
import { CardFacade } from 'common/utils/facades/card-facade';
import Text from 'admin/components/common/Text';
import { DEFAULT_LAST_CARD_TYPE } from 'admin/components/pages/Story/Flow/CreateCardModal/utils';

import css from './CreateCardModal.scss';

export const ID_PREFIX = 'SC-MASTER-THE-BEST_';

export const ORG_GROUP_POSTFIX = '-ORG';

export const getCardTypeFromGroupId = (groupId: string) => {
	const orgIndex = groupId.indexOf(ORG_GROUP_POSTFIX);
	return (orgIndex >= 0 ? groupId.substr(0, orgIndex) : groupId) as CardData['type'];
};

export const isOrganizationGroup = (groupId: string) => groupId.indexOf(ORG_GROUP_POSTFIX) >= 0;

const filter = {
	organization: 'organization',
	type: 'general',
};

type Props = {
	organizationName?: string;
	defaultType: string;
	scrollingContainerRef: React.RefObject<HTMLDivElement>;
	availableTypes: { general: string[]; organization: string[] };
	enabledTypes: { general: string[]; organization: string[] };
};

type State = {
	activeGroups: string[];
	activeGroupId: string;
	activeCardType: CardData['type'];
};

type FilterItem = { id: string; title: string; items: string[] };

export default class TemplateGalleryFilters extends Component<Props, State> {
	filters: FilterItem[];

	debouncedScroll: (this: Document, ev: Event) => any;

	public readonly state: State;

	constructor(props: Props) {
		super(props);
		const targetFilters = [
			{
				id: filter.type,
				title: filter.type,
				items: props.availableTypes.general,
			},
		];

		if (props.organizationName) {
			targetFilters.unshift({
				id: filter.organization,
				title: props.organizationName,
				items: map(props.availableTypes.organization, (item: string) => `${item}${ORG_GROUP_POSTFIX}`),
			});
		}

		this.filters = targetFilters;

		this.state = {
			activeGroupId: DEFAULT_LAST_CARD_TYPE,
			activeGroups: [`${filter.organization}`, `${filter.type}`],
			activeCardType: DEFAULT_LAST_CARD_TYPE,
		};

		this.debouncedScroll = throttle(this.onScroll, 200);
	}

	componentDidMount() {
		defer(() => {
			if (this.props.defaultType) {
				this.scrollToType(this.props.defaultType, false);
			} else {
				this.onScroll();
			}

			this.props.scrollingContainerRef.current?.addEventListener('scroll', this.debouncedScroll);
		});
	}

	componentWillUnmount() {
		if (this.props.scrollingContainerRef?.current) {
			this.props.scrollingContainerRef.current.removeEventListener('scroll', this.debouncedScroll);
		}
	}

	public get activeCardType() {
		return this.state.activeCardType;
	}

	getItemGroupName(id: string) {
		return get(
			find(this.filters, (f: FilterItem) => f.items.includes(id)),
			'id',
			''
		);
	}

	onScroll = () => {
		if (!this.props.scrollingContainerRef?.current) {
			return;
		}

		const { activeGroupId } = this.state;
		const groups = document.querySelectorAll<HTMLDivElement>(`[id^=${ID_PREFIX}]`);
		const { scrollTop, offsetTop: containerOffsetTop } = this.props.scrollingContainerRef.current;

		forEach(groups, group => {
			const { offsetTop, offsetHeight } = group;
			const attrId = group.getAttribute('id') ?? DEFAULT_LAST_CARD_TYPE;
			const groupId = attrId.replace(`${ID_PREFIX}`, '') as CardData['type'];
			const offset = 250;
			const upperBoundaryCrossed = scrollTop >= offsetTop - containerOffsetTop - offset;
			const lowerBoundaryNotCrossed = scrollTop <= offsetTop - containerOffsetTop + offsetHeight - offset;

			if (upperBoundaryCrossed && lowerBoundaryNotCrossed && groupId !== activeGroupId) {
				this.setState({
					activeGroupId: groupId,
					activeGroups: [this.getItemGroupName(groupId)],
					activeCardType: getCardTypeFromGroupId(groupId),
				});
			}
		});
	};

	onGroupTitleClick = (e: React.MouseEvent<HTMLDivElement>) => {
		const { group } = e.currentTarget.dataset;

		this.setState(prevState => {
			const nextActiveGroups = [...prevState.activeGroups];
			const targetGroupIndex = nextActiveGroups.indexOf(group as string);

			if (targetGroupIndex >= 0) {
				nextActiveGroups.splice(targetGroupIndex, 1);
			} else {
				nextActiveGroups.push(group as string);
			}

			return {
				activeGroups: nextActiveGroups,
			};
		});
	};

	scrollToType = (typeName: string, isSmooth: boolean = true) => {
		const targetElem: HTMLDivElement | null = document.querySelector(`[id=${ID_PREFIX}${typeName}]`);
		const scrollingElem = this.props.scrollingContainerRef?.current;

		if (targetElem && scrollingElem) {
			const extraSpace = 15;
			scrollingElem.scrollTo({
				top: targetElem.offsetTop - scrollingElem.offsetTop - extraSpace,
				behavior: isSmooth ? 'smooth' : 'auto',
			});
		}
	};

	onGroupItemClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		const { name } = e.currentTarget.dataset;

		if (name === this.state.activeGroupId) {
			return;
		}

		this.scrollToType(name as string);
	};

	renderGroups = (group: FilterItem) => {
		const { organizationName, enabledTypes } = this.props;
		const { activeGroups, activeGroupId } = this.state;
		const isGroupActive = activeGroups.indexOf(group.id) >= 0;

		return (
			<div
				className={cn(css.filtersGroup, isGroupActive && css.active, !organizationName && css.alwaysActive)}
				key={`group-${group.id}`}
			>
				<Text
					tag="div"
					data-group={group.id}
					onClick={organizationName ? this.onGroupTitleClick : undefined}
					size={Text.size.subheading}
					className={css.filtersGroupTitle}
				>
					{organizationName ? group.title : t('story.createCardModal.filter')}
				</Text>
				{isGroupActive && (
					<section>
						{map(group.items, (item: CardData['type']) => {
							const name = getCardTypeFromGroupId(item);
							const isDisabled = isOrganizationGroup(item)
								? enabledTypes.organization.indexOf(name) < 0
								: enabledTypes.general.indexOf(name) < 0;

							return (
								<button
									key={`group-item-${item}`}
									onClick={this.onGroupItemClick}
									data-name={item}
									disabled={isDisabled}
									type="button"
									className={cn(css.filtersGroupItem, { [css.active]: item === activeGroupId })}
								>
									{/* {TEMPLATE_CATEGORIES.INTENTION[name] ||
										capitalize(CardFacade.getCardNameByType(name))} */}
									{capitalize(CardFacade.getCardNameByType(name))}
								</button>
							);
						})}
					</section>
				)}
			</div>
		);
	};

	render() {
		return (
			<>
				{this.props.organizationName ? (
					<Text
						size={Text.size.label}
						weight={Text.weight.medium}
						transform={Text.transform.uppercase}
						color={Text.color.blackDark}
						style={{ marginBottom: 12 }}
					>
						{t('story.createCardModal.filter')}
					</Text>
				) : null}
				<div className={css.filters}>{map(this.filters, this.renderGroups)}</div>
			</>
		);
	}
}
