export const CAN_USE_DOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);
export const STORY_ELEMENTS_ID = 'story-elements';
export const CARD_ELEMENTS_ID = 'card-elements';
export const CARD_ROOT_ID = 'card';
export const STORY_ROOT_ID = 'story';

export const EMBED_SCRIPT_MESSAGE_SENDER_ID = 'EMBED_SCRIPT_MESSAGE';
export const STORY_MESSAGE_SENDER_ID = 'STORY_MESSAGE';
export const ANIMATION_SETTINGS_SENDER_ID = 'ANIMATION_SETTINGS_SENDER_ID';
export const ANIMATION_HOC_SENDER_ID = 'ANIMATION_HOC_SENDER_ID';
export const embedingEvent = {
	STORY_IS_READY: 'STORY_IS_READY',
	STORY_IN_VIEWPORT: 'STORY_IN_VIEWPORT',
	STORY_RESIZE: 'STORY_RESIZE',
	CARD_CHANGED: 'CARD_CHANGED',

	PAGE_SCROLL: 'PAGE_SCROLL',
	PAGE_IS_READY: 'PAGE_IS_READY',
} as const;

// localStorage item names
export const lsNames = {
	history: 'sc-history',
};

// Used to disable page scrolling
export const PAGE_SCROLL_LOCK_ATTR = 'data-scroll-lock';

export const CONTENT_CONTAINER_ID = 'sc-content-container';
