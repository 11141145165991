import { reduce } from 'lodash';
import { ISubscriptionPlans, PlanChargeInfo } from 'src/types';
import { createThunkAction } from 'admin/actions/helpers';
import { PLANS_NAMES, PLANS_OPTIONS } from 'admin/constants/common';
import { GET_PLANS, GET_PLAN_CHARGE_INFO } from 'admin/constants/actions';
import { getPlans as getPlansEndpoint, getPlanChargeInfo as getPlanChargeInfoEndpoint } from 'admin/resources';
import { selectActiveOrganizationId } from 'admin/reducers/user/selectors';

export const getPlans = createThunkAction<ISubscriptionPlans>({
	type: GET_PLANS,
	payloadCreator: async () => {
		const response: { body: IBackendBody<{ plans: ISubscriptionPlans }> } = await getPlansEndpoint.send();

		const result = reduce(
			response.body?.result?.plans ?? {},
			(acc, item, key) => {
				acc[key] = item;

				if (PLANS_OPTIONS[key]) {
					acc[key].options = PLANS_OPTIONS[key];
				}

				return acc;
			},
			{} as ISubscriptionPlans
		);

		result[PLANS_NAMES.ENTERPRISE] = {
			ANNUALLY: {
				id: 7777777,
				name: 'Enterprise',
				currency: '$',
				trialPeriodDays: 0,
				recurringChargeAmount: null,
			},
			MONTHLY: {
				id: 7777777,
				name: 'Enterprise',
				currency: '$',
				trialPeriodDays: 0,
				recurringChargeAmount: null,
			},
			options: PLANS_OPTIONS[PLANS_NAMES.ENTERPRISE],
		};

		return { ...response.body, result };
	},
});

interface GetPlanChargeInfoParams {
	organizationId?: string;
	planId: number;
}

export const getPlanChargeInfo = createThunkAction<PlanChargeInfo, GetPlanChargeInfoParams>({
	type: GET_PLAN_CHARGE_INFO,
	payloadCreator: async (params, { getState }) => {
		const organizationId = params.organizationId || selectActiveOrganizationId(getState());
		const response = await getPlanChargeInfoEndpoint.params({ organizationId }).send({ planId: params.planId });
		return response.body;
	},
});
