import React, { useEffect, useMemo, useState, useRef } from 'react';

import t from 'common/utils/translate';
import { requestMainRowData } from 'common/utils/facades/story-facade';
import Text from 'admin/components/common/Text';
import { setActiveTeam } from 'admin/actions/member';
import { useAdminSelector, useAdminDispatch } from 'admin/reducers';
import type { StoriesListItem } from 'admin/reducers/stories-reducer';
import { UPDATE_STORY } from 'admin/constants/actions';
import { Select } from 'admin/components/common/Form';
import { Column } from 'admin/components/common/Grid';
import Button from 'admin/components/common/Button';
import { updateStory } from 'admin/actions/story/update-story';
import { RolesFacade } from 'admin/utils/facades/roles-facade';
import { selectLoadingStateByName } from 'admin/reducers/loading/selectors';
import { Modal, ModalBody, ModalFooter } from 'admin/components/common/Modal';
import { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';
import { selectTeams, selectActiveTeam, selectUser } from 'admin/reducers/user/selectors';
import { MODAL } from 'admin/constants/common';
import css from './MoveStoryModal.scss';

export type MoveStoryModalData = {
	story: StoriesListItem;
};

type Props = ModalManagerProvidedProps<MODAL.MOVE_STORY>;

const MoveStoryModal: React.FC<Props> = ({ open, ...props }) => {
	const dispatch = useAdminDispatch();
	const isBusy = useAdminSelector(state => selectLoadingStateByName(state, UPDATE_STORY.UPDATE));
	const teams = useAdminSelector(selectTeams);
	const user = useAdminSelector(selectUser);
	const activeTeam = useAdminSelector(selectActiveTeam);
	const rolesRef = useRef(new RolesFacade(user));

	const [nextTeamId, setNextTeamId] = useState<string>();
	const [isComplete, setIsComplete] = useState(false);
	const nextTeamName = teams.filter(team => team.id === nextTeamId)?.[0]?.name ?? 'Private';

	const options = useMemo(() => {
		const filteredTeams = teams.filter(team => {
			const canCreateStory =
				rolesRef.current.isOrganizationManager ||
				rolesRef.current.can(RolesFacade.actions.manageOwnStory, team.id) ||
				rolesRef.current.can(RolesFacade.actions.manageOtherStories, team.id);
			return team.id !== activeTeam && canCreateStory;
		});
		return filteredTeams.map(team => ({ value: team.id, label: team.name }));
	}, [teams, activeTeam]);

	const onSave = async () => {
		await dispatch(
			updateStory(
				{ ...requestMainRowData(props.data.story), teamId: nextTeamId },
				{ getStoryOnComplete: false, newTeamId: nextTeamId }
			)
		);
		setIsComplete(true);
	};

	useEffect(
		function resetStateOnClose() {
			if (open) return;
			setNextTeamId(undefined);
			setIsComplete(false);
		},
		[open]
	);

	if (!props.data.story) {
		return null;
	}

	return (
		<Modal
			title={
				<Text
					size="subtitle"
					weight="semibold"
					text={
						isComplete
							? t('moveStoryModal.titleComplete', { name: nextTeamName })
							: t('moveStoryModal.title', { name: props.data.story.name })
					}
				/>
			}
			open={open}
			destroyOnClose
			width={564}
		>
			<ModalBody>
				{isComplete ? (
					<div style={{ height: 40 }} />
				) : (
					<Select
						label={{ children: t('moveStoryModal.select.label') }}
						options={options}
						placeholder={t('moveStoryModal.select.placeholder')}
						value={nextTeamId}
						eventListeners={{ onChange: setNextTeamId }}
					/>
				)}
			</ModalBody>
			<ModalFooter className={css.footer}>
				<Column justifyContent="flex-start">
					{isComplete ? (
						<>
							<Button onClick={props.close} className={css.btn} view="primary">
								{t('moveStoryModal.btn.ok')}
							</Button>
							<Button
								onClick={() => {
									dispatch(setActiveTeam(nextTeamId ?? ''));
									props.close();
								}}
								view="secondary"
							>
								{t('moveStoryModal.btn.go', { name: nextTeamName })}
							</Button>
						</>
					) : (
						<>
							<Button
								loading={isBusy}
								disabled={isBusy}
								onClick={onSave}
								className={css.btn}
								view="primary"
							>
								{t('moveStoryModal.btn.move')}
							</Button>
							<Button onClick={props.close} view="secondary">
								{t('moveStoryModal.btn.cancel')}
							</Button>
						</>
					)}
				</Column>
			</ModalFooter>
		</Modal>
	);
};

export default MoveStoryModal;
