import _ from 'lodash';

const LETTERS_COLOR_MAP = [
	{ letters: ['a', 'b', 'c'], color: '#0097A7' },
	{ letters: ['d', 'e', 'f'], color: '#303F9F' },
	{ letters: ['g', 'h', 'i'], color: '#0288D1' },
	{ letters: ['j', 'k', 'l'], color: '#C2185B' },
	{ letters: ['m', 'n', 'o'], color: '#586D79' },
	{ letters: ['p', 'q', 'r'], color: '#00796B' },
	{ letters: ['p', 'q', 'r'], color: '#00796B' },
	{ letters: ['s', 't'], color: '#512DA8' },
	{ letters: ['u', 'v'], color: '#E5AC73' },
	{ letters: ['w', 'x'], color: '#D32F2F' },
	{ letters: ['y', 'z'], color: '#FBC02D' },
];

export function getUserColorByLetter(letter: string) {
	const result = _.find(LETTERS_COLOR_MAP, (item: { letters: string[]; color: string }) =>
		item.letters.includes(_.toLower(letter))
	);

	return result?.color ?? LETTERS_COLOR_MAP[0].color;
}
