import React from 'react';
import cn from 'classnames';

import css from './FloatingBox.scss';

interface Props extends React.PropsWithChildren {
	className?: string;
}

export const FloatingBox = React.forwardRef<HTMLDivElement, Props>(({ className, children }, ref) => (
	<div className={cn(css.floatingBox, className)}>{children}</div>
));

export default FloatingBox;
