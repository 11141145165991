import type { EditableStateInfo } from 'types/story';
import { SET_EDITABLE_STATE } from 'admin/constants/actions';

/**
 * Store info about an element who is changed his state
 * Used by <StateObserver /> for being able to know when should reset state of an element recursively
 * @param payload.state {String}
 * @param payload.path {String}
 * @param payload.layer {LAYER_TYPE}
 * @param payload.id {String}
 * @param payload.type {String}
 * @param payload.states {Array} available states list
 * @return {{type, payload: {state: string, path, layer}}}
 */
export function setEditableState(payload?: {
	state?: EditableStateInfo['state'];
	path?: EditableStateInfo['source']['path'];
	id?: EditableStateInfo['source']['id'];
	type?: EditableStateInfo['source']['type'];
	layer?: EditableStateInfo['source']['layer'];
	states?: EditableStateInfo['source']['states'];
}) {
	return {
		type: SET_EDITABLE_STATE,
		payload,
	};
}
