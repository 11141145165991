import { get } from 'lodash';
import React from 'react';
import { Radio } from 'antd';
import { change, Field } from 'redux-form';

import t from 'common/utils/translate';
import { STORY_TYPE } from 'common/constants';
import { IS_PROD } from 'common/utils/environment';

import { AdminThunkDispatch, useAdminSelector } from 'admin/reducers';
import { isSuperUserSelector } from 'admin/reducers/user/selectors';
import { LANG_OPTIONS } from 'admin/constants/common';
import { Select } from 'admin/components/common/Form';
import { Icon } from 'admin/components/common/Icon';
import Text from 'admin/components/common/Text';

import { fields, FormValues } from './Form';
import css from './CreateStoryModal.scss';

const cloneStoryType = 'clone' as const;
type CloneStoryType = typeof cloneStoryType;

type MainViewProps = {
	form: string;
	onOk: (type: FormValues['type'] | CloneStoryType) => void;
	dispatch: AdminThunkDispatch;
	values: FormValues;
};

const commonTypes = [STORY_TYPE.STANDALONE, STORY_TYPE.EMBED, STORY_TYPE.WIDGET];

const TemplateType: React.FC<MainViewProps> = ({ values, dispatch, form, onOk }) => {
	const isSuperUser = useAdminSelector(isSuperUserSelector);
	const types = isSuperUser && !IS_PROD ? [...commonTypes, cloneStoryType] : commonTypes;
	const type = get(values, fields.type);

	return (
		<div style={{ width: 800, textAlign: 'center', paddingBottom: 60 }}>
			<Text size={Text.size.subheading} style={{ marginBottom: 44 }}>
				{t('createStoryModal.type.title')}
			</Text>
			<Radio.Group className={css.templateType} value={type}>
				{types.map(value => (
					<Radio.Button
						onClick={() => {
							dispatch(change(form, fields.type, value));
							onOk(value);
						}}
						className={css.templateTypeBtn}
						value={value}
						key={value}
					>
						<Icon
							type={value === cloneStoryType ? 'copy-text' : `sc-${value}`}
							color={value === type ? 'var(--ra-color-brand)' : 'currentColor'}
							{...(value === 'widget' && { width: 96, height: 96 })}
						/>
						<Text size={Text.size.paragraph} transform={Text.transform.capitalize}>
							{t(`createStoryModal.${fields.type}.${value}.title`)}
						</Text>
						<Text size={Text.size.label} color={Text.color.blackDark}>
							{t(`createStoryModal.${fields.type}.${value}.desc`)}
						</Text>
					</Radio.Button>
				))}
			</Radio.Group>

			<br />

			<Field
				name={fields.lang}
				component={Select}
				label={{ children: t(`createStoryModal.fields.${fields.lang}.label`) }}
				options={LANG_OPTIONS}
				inline
			/>
		</div>
	);
};

export default TemplateType;
