import { IActiveMemberTeam, IOrganizationTeamMember } from 'src/types';
import * as actionType from 'admin/constants/actions';
import * as endpoint from 'admin/resources';
import { createThunkAction } from 'admin/actions/helpers';
import { selectActiveOrganizationId } from 'admin/reducers/user/selectors';

interface CreateTeamParams {
	name: string;
	organizationId: string;
}

export type CreateTeamResult = { id: string };

export const createTeam = createThunkAction<CreateTeamResult, CreateTeamParams>({
	type: actionType.CREATE_TEAM,
	payloadCreator: async ({ organizationId, name }) => {
		const response = await endpoint.createTeam.params({ organizationId }).send({ name });
		return response.body;
	},
});

type UpdateTeamParams = {
	teamId: string;
	data: { name: string };
	organizationId: string;
};

export type UpdateTeamResult = UpdateTeamParams;

export const updateTeam = createThunkAction<UpdateTeamResult, UpdateTeamParams>({
	type: actionType.UPDATE_TEAM,
	payloadCreator: async params => {
		await endpoint.updateTeam
			.params({ teamId: params.teamId, organizationId: params.organizationId })
			.send(params.data);
		return { success: true, result: params };
	},
});

type GetTeamMembersParams = {
	teamId: string;
	organizationId: string;
};

export type GetTeamMembersResult = IOrganizationTeamMember[];

export const getTeamMembers = createThunkAction<GetTeamMembersResult, GetTeamMembersParams>({
	type: actionType.GET_TEAM_MEMBERS,
	payloadCreator: async ({ teamId, organizationId }) => {
		const response = await endpoint.getTeamMembers.params({ teamId, organizationId }).send();
		return response.body;
	},
});

type UpdateTeamMemberParams = {
	teamId: string;
	memberId: string;
	data: Pick<IActiveMemberTeam['teamMembers'], 'roles' | 'isManager'>;
};

export type UpdateTeamMemberResult = UpdateTeamMemberParams;

/**
 * Create / Update team member by id
 */
export const updateTeamMember = createThunkAction<UpdateTeamMemberResult, UpdateTeamMemberParams>({
	type: actionType.UPDATE_TEAM_MEMBER,
	payloadCreator: async ({ teamId, memberId, data }, { getState }) => {
		const organizationId = selectActiveOrganizationId(getState());
		await endpoint.updateTeamMember.params({ teamId, memberId, organizationId }).send(data);
		return { success: true, result: { teamId, memberId, data } };
	},
});

type DeleteTeamMemberParams = {
	teamId: string;
	memberId: string;
	organizationId?: string;
};

export type DeleteTeamMemberResult = {
	teamId: string;
	memberId: string;
};

export const deleteTeamMember = createThunkAction<DeleteTeamMemberResult, DeleteTeamMemberParams>({
	type: actionType.DELETE_TEAM_MEMBER,
	payloadCreator: async ({ teamId, memberId, ...params }, { getState }) => {
		const organizationId = params.organizationId ?? selectActiveOrganizationId(getState());
		await endpoint.deleteTeamMember.params({ teamId, memberId, organizationId }).send();
		return { success: true, result: { teamId, memberId } };
	},
});

type DeleteTeamParams = { teamId: string };

export type DeleteTeamResult = DeleteTeamParams;

export const deleteTeam = createThunkAction<DeleteTeamResult, DeleteTeamParams>({
	type: actionType.DELETE_TEAM,
	payloadCreator: async (params, { getState }) => {
		await endpoint.deleteTeam
			.params({
				teamId: params.teamId,
				organizationId: selectActiveOrganizationId(getState()),
			})
			.send();
		return { success: true, result: params };
	},
});
