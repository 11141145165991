/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { connect, ConnectedProps } from 'react-redux';
import { change as changeFormAction, reset as resetFormAction } from 'redux-form';

import t from 'utils/translate';
import { StoryFacade } from 'utils/facades/story-facade';
import IntegrationsFacade, { INTEGRATIONS_PAGE_VIEW, INTEGRATIONS_SCOPE } from 'utils/facades/integrations-facade';
import { useAdminDispatch } from 'admin/reducers';
import Button from 'admin/components/common/Button';
import { useDidMount } from 'common/components/useDidMount';
import { Icon, LAYER_ICONS } from 'admin/components/common/Icon';
import { SETTINGS_BILLING_PAGE } from 'admin/constants/routes';
import Text from 'admin/components/common/Text';
import { FORM_MODEL } from 'admin/constants/common';
import { getStoryWebhook } from 'admin/resources';
import { Script } from 'admin/components/common/Intregrations/Script';
import { CustomTracker } from 'admin/components/common/Intregrations/CustomTracker';
import { GoogleAnalytics } from 'admin/components/common/Intregrations/GoogleAnalytics';
import { GooglePublisherTag } from 'admin/components/common/Intregrations/GooglePublisherTag';
import { Outbrain } from 'admin/components/pages/Story/Flow/StorySettingsModal/Integrations/Outbrain';
import { StorySpreadsheetIntegration, getStorySpreadsheetIntegrations } from 'admin/actions/integrations';
import { Spreadsheet, SpreadsheetProps } from 'admin/components/common/Intregrations/Spreadsheet';

import type { SettingsTabsGeneralProps } from '../types';
import type { Webhook } from './types';
import { Intro } from './Intro';
import { Webhooks } from './Webhooks';
import { UrlParams } from './UrlParams';

import css from './Integrations.scss';

type Props = SettingsTabsGeneralProps & ConnectedProps<typeof connector>;

const VIEWS_MAP = {
	[IntegrationsFacade.views.DEFAULT]: Intro,
	[IntegrationsFacade.views.SCRIPT]: Script,
	[IntegrationsFacade.views.GOOGLE_ANALYTICS]: GoogleAnalytics,
	[IntegrationsFacade.views.CUSTOM_TRACKER]: CustomTracker,
	[IntegrationsFacade.views.WEBHOOKS]: Webhooks,
	[IntegrationsFacade.views.URL_PARAMS]: UrlParams,
	[IntegrationsFacade.views.GOOGLE_PUBLISHER_TAG]: GooglePublisherTag,
	[IntegrationsFacade.views.SPREADSHEET_IMPORT]: (props: Omit<SpreadsheetProps, 'type'>) => (
		<Spreadsheet {...props} type="database" />
	),
	[IntegrationsFacade.views.SPREADSHEET_EXPORT]: (props: Omit<SpreadsheetProps, 'type'>) => (
		<Spreadsheet {...props} type="export_playthroughs" />
	),
	[IntegrationsFacade.views.OUTBRAIN]: Outbrain,
};

const Integrations = (props: Props) => {
	const dispatch = useAdminDispatch();
	const [view, setView] = React.useState<INTEGRATIONS_PAGE_VIEW>(INTEGRATIONS_PAGE_VIEW.DEFAULT);
	const isDisabled = !!props.plan && !props.plan.googleAnalytics;
	const Component: ValuesType<typeof VIEWS_MAP> = VIEWS_MAP[view];
	const { formInfo } = props;
	const storyId = props.story.id;

	const [webhooks, setWebhooks] = useState<Webhook[]>([]);
	const [storySpreadsheets, setStorySpreadsheets] = useState<StorySpreadsheetIntegration[]>([]);

	useDidMount(() => {
		Promise.all([
			getStoryWebhook
				.params({ storyId })
				.send()
				.then(response => (Array.isArray(response.body) ? (response.body as Webhook[]) : []))
				.catch(e => []),

			dispatch(getStorySpreadsheetIntegrations({ storyId }))
				.then(response => response.result ?? [])
				.catch(e => []),
		]).then(([_webhooks, _spreadsheets]) => {
			setWebhooks(_webhooks);
			setStorySpreadsheets(_spreadsheets);
		});
	});

	const onChangeView = (v: INTEGRATIONS_PAGE_VIEW) => {
		setView(v);
	};

	const onBackClick = () => {
		setView(INTEGRATIONS_PAGE_VIEW.DEFAULT);
		props.resetForm(FORM_MODEL.EDIT_STORY);
	};

	const onDiscardClick = () => {
		props.resetForm(FORM_MODEL.EDIT_STORY);
	};

	return (
		<div className={css.integrations}>
			{view !== INTEGRATIONS_PAGE_VIEW.DEFAULT && (
				<button className={css.backBtn} type="button" onClick={onBackClick}>
					<Text className={css.backBtnLabel}>
						{t('story.settings.navigation.integrations')}
						{isDisabled && <Icon className={css.lockIcon} type={LAYER_ICONS.LOCKED} width={22} />}
					</Text>
				</button>
			)}

			<Text size={Text.size.subheading} weight={Text.weight.bold} className={css.title}>
				{t(`common.integrations.views.${IntegrationsFacade.titles[view]}.title`)}
				{isDisabled && <Icon className={css.lockIcon} type={LAYER_ICONS.LOCKED} width={22} />}
			</Text>

			{isDisabled && (
				<Link to={SETTINGS_BILLING_PAGE} className={css.upgradeBtn}>
					<Button view="primary" smallText theme="dark">
						{t('story.settings.upgradeBtn')}
					</Button>
				</Link>
			)}

			<div className={cn(css.content, { [css.disabled]: isDisabled })}>
				{Component && (
					<Component
						formInfo={props.formInfo}
						changeForm={props.changeForm}
						story={props.story}
						formValues={props.formValues}
						onChangeView={onChangeView}
						version={props.version}
						fields={props.fields}
						webhooks={webhooks}
						setWebhooks={setWebhooks}
						scope={INTEGRATIONS_SCOPE.STORY}
						integrations={new StoryFacade(props.formValues).settings.integrations}
						storySpreadsheets={storySpreadsheets}
					/>
				)}
			</div>

			{formInfo &&
				view !== INTEGRATIONS_PAGE_VIEW.DEFAULT &&
				view !== INTEGRATIONS_PAGE_VIEW.WEBHOOKS &&
				view !== INTEGRATIONS_PAGE_VIEW.SPREADSHEET_IMPORT && (
					<div className={css.buttons}>
						<Button
							theme="dark"
							loading={formInfo.submitting}
							disabled={formInfo.asyncValidating || !formInfo.dirty || formInfo.submitting}
							smallText
							type="submit"
						>
							{t('common.integrations.saveBtnLabel')}
						</Button>
						<Button
							theme="dark"
							view="empty"
							disabled={formInfo.asyncValidating || !formInfo.dirty || formInfo.submitting}
							smallText
							onClick={onDiscardClick}
						>
							{t('common.integrations.cancelBtnLabel')}
						</Button>
					</div>
				)}
		</div>
	);
};

const mapDispatchToProps = {
	resetForm: resetFormAction,
	changeForm: changeFormAction,
};

const connector = connect(null, mapDispatchToProps);

export default connector(Integrations);
