import * as React from 'react';
import { Field } from 'redux-form';
import classNames from 'classnames';

import { useUploadVideoProcess } from 'admin/hooks';

import Button from 'admin/components/common/Button';
import { TextField } from 'admin/components/common/Form';
import { UploadVideoField } from 'admin/components/common/UploadVideoField';
import { VIDEO_TITLE_MAX_LEN } from 'admin/components/common/Form/validations';
import { UploadVideoProgress } from 'admin/components/common/UploadVideoProgress';
import { IncompleteUploadVideoMessage } from 'admin/components/common/IncompleteUploadVideoMessage';

import t from 'utils/translate';

import Form, { Values } from './Form';

import css from './CreateForm.scss';

type Props = {
	className?: string;
	onSuccess: () => void;
	controller: AbortController;
};

const CreateForm: React.FC<Props> = props => {
	const { className = '', onSuccess, controller } = props;
	const { upload, isBusy, progress: uploadingProgress, prevUploadInfo } = useUploadVideoProcess({ controller });

	const [video, setVideo] = React.useState<File | null>(null);

	const initialValues = { title: '', video: null };

	const onVideoInputChange = (file: File | null) => {
		setVideo(file);
	};

	const onSubmit = async (values: Values) => {
		if (!video) return;

		upload(video, values.title, onSuccess);
	};

	return (
		<div className={classNames(css.createForm, className)}>
			<Form initialValues={initialValues} videoFile={video} className={css.form} onSubmit={onSubmit}>
				{formProps => (
					<>
						<Field
							className={css.input}
							name="title"
							disabled={isBusy}
							component={TextField}
							autoComplete="off"
							isLabelUppercase={false}
							placeholder={t('createEditVideoModal.fields.title.placeholder')}
							label={t('createEditVideoModal.fields.title.label')}
							isRequired
							limit={{ max: VIDEO_TITLE_MAX_LEN }}
						/>

						{!isBusy && prevUploadInfo?.uploadId && (
							<IncompleteUploadVideoMessage
								theme="light"
								buttonLabel={t('createEditVideoModal.createBtn')}
								className={css.prevUploadInfo}
								uploadInfo={prevUploadInfo}
							/>
						)}

						{isBusy ? (
							<UploadVideoProgress progress={uploadingProgress} theme="light" />
						) : (
							<UploadVideoField
								name="video"
								placeholder={t('uploadVideo.placeholder')}
								onChange={onVideoInputChange}
							/>
						)}

						<div className={css.actionButtons}>
							<Button
								type="submit"
								disabled={!formProps.dirty || formProps.submitting || isBusy}
								smallText
							>
								{t('createEditVideoModal.createBtn')}
							</Button>
						</div>
					</>
				)}
			</Form>
		</div>
	);
};

export default CreateForm;
