import _ from 'lodash';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { EditorMode, StoryMediaPlatform } from 'types/story';
import { adminLog, isCMD } from 'utils/helpers';
import { EventEmitter } from 'utils/event-emitter';
import { getClientFrame } from 'utils/iframe-tunnel';
import { INSPECTOR_ID, MODAL } from 'admin/constants/common';
import { cardEditorSync } from 'admin/actions/card-editor/card-editor-sync';
import { withUndoRedo, WithUndoRedoI } from 'admin/components/pages/Story/CardEditor/UndoRedo/withUndoRedo';
import { FIELD_EDITABLE_ATTR } from 'client/components/common/BuildingBlocks/Fields/constants';
import { DeleteElementFn } from 'admin/components/pages/Story/CardEditor/DeleteElement';
import { ComponentBufferFns } from 'admin/components/pages/Story/CardEditor/ComponentBuffer';
import { AdminReducerState } from 'admin/reducers';
import { selectEditorMode } from 'admin/reducers/card-editor-extra/selectors';
import { DEFAULT_MEDIA_QUERY_PLATFORMS } from 'common/constants';

const log = adminLog.extend('KeyboardListener');
const warn = adminLog.extend('KeyboardListener');
warn.log = console.warn.bind(console);

type EditorKeyboardToggleLayersEvent = { type: 'toggleLayers' };
type EditorKeyboardAddCompEvent = { type: 'toggleAddComponent' };
type EditorKeyboardTogglePreviewEvent = { type: 'togglePreview' };
export type EditorKeyboardSetPlatformEvent = { type: 'setPlatform'; data: StoryMediaPlatform };

export const EditorKeyboardEvEm = new EventEmitter<
	| EditorKeyboardToggleLayersEvent
	| EditorKeyboardAddCompEvent
	| EditorKeyboardTogglePreviewEvent
	| EditorKeyboardSetPlatformEvent
>();

const KEYS = {
	BACKSPACE: 'Backspace',
	DELETE: 'Delete',
	KEY_C: 'KeyC',
	KEY_V: 'KeyV',
	KEY_X: 'KeyX',
	KEY_Z: 'KeyZ',
	KEY_S: 'KeyS',
	KEY_D: 'KeyD',
	KEY_L: 'KeyL',
	KEY_A: 'KeyA',
	KEY_P: 'KeyP',
	Digit1: 'Digit1',
	Digit2: 'Digit2',
	Digit3: 'Digit3',
	Digit4: 'Digit4',
	Digit5: 'Digit5',
};

const forbiddenNodes = ['INPUT', 'TEXTAREA', 'SELECT'];
const isForbiddenTarget = (event: KeyboardEvent) => {
	const target = event.target as HTMLElement;
	const { nodeName, contentEditable } = target;
	const isContentEditable = contentEditable === 'true';

	if (isContentEditable) return true;

	const isEditor = !!event.view?.document?.getElementById(INSPECTOR_ID);
	const isEditableBuildingBlock = target.hasAttribute(FIELD_EDITABLE_ATTR);
	return forbiddenNodes.includes(nodeName) && (isEditor || isEditableBuildingBlock);
};

const EVENT = 'keydown';
const DEBOUNCE = 150;

type OwnProps = {
	buffer: ComponentBufferFns;
	deleteElement: DeleteElementFn;
	cardEditorSync: (params?: Parameters<typeof cardEditorSync>[0]) => void;
};

type Props = OwnProps & WithUndoRedoI & ConnectedProps<typeof connector>;

class KeyboardListener extends React.PureComponent<Props> {
	_onKeyUpDebounced?: (event: KeyboardEvent) => void;

	iframeInterval?: number;

	iframeDocument?: Document;

	componentDidMount() {
		log('didMount:addEventListener: ', { event: EVENT, target: document });
		document.addEventListener(EVENT, this.onKeyUp);
		const contentDocument = getClientFrame()!.frame!.contentDocument!;
		this.onFrameLoad(contentDocument);
	}

	componentWillUnmount() {
		document.removeEventListener(EVENT, this.onKeyUp);

		clearInterval(this.iframeInterval);

		if (this.iframeDocument) {
			this.iframeDocument.removeEventListener(EVENT, this.onKeyUp);
		}
	}

	onFrameLoad = (contentDocument: HTMLDocument) => {
		log('onFrameLoad:addEventListener to: ', { event: EVENT, target: contentDocument });

		clearInterval(this.iframeInterval);

		contentDocument.addEventListener(EVENT, this.onKeyUp);
		this.iframeDocument = contentDocument;
	};

	onKeyUp = (event: KeyboardEvent) => {
		const isBrowserSaveAction = isCMD(event) && event.code === KEYS.KEY_S;
		const isBrowserBookmarkAction = isCMD(event) && event.code === KEYS.KEY_D;
		if (isBrowserSaveAction || isBrowserBookmarkAction) {
			event.preventDefault();
		}

		if (!this._onKeyUpDebounced) {
			this._onKeyUpDebounced = _.debounce(this.onKeyUpDebounced, DEBOUNCE);
		}

		if (this._onKeyUpDebounced) {
			this._onKeyUpDebounced(event);
		}
	};

	onKeyUpDebounced = (event: KeyboardEvent) => {
		if (isForbiddenTarget(event) || this.props.isModalOpen) {
			log('onKeyUpDebounced rejected [forbidden target]');
			return;
		}

		const COMMAND = isCMD(event);
		const SHIFT = event.shiftKey;
		const CODE = event.code;
		const ALT = event.altKey;
		const isDesignMode = this.props.editorMode === EditorMode.DESIGN;

		log('onKeyUp', { COMMAND, SHIFT, CODE });

		switch (CODE) {
			case KEYS.BACKSPACE:
			case KEYS.DELETE:
				if (isDesignMode) this.props.deleteElement();
				break;
			case KEYS.KEY_C:
				if (isDesignMode && COMMAND && !SHIFT) {
					this.props.buffer.copy();
				}
				break;
			case KEYS.KEY_X:
				if (isDesignMode && COMMAND && !SHIFT) {
					this.props.buffer.copy('Cut!');
					this.props.deleteElement();
				}
				break;
			// case KEYS.KEY_R:
			// 	if (ALT) {
			// 		this.props.buffer.replace();
			// 	}
			// 	break;
			case KEYS.KEY_V:
				if (isDesignMode && COMMAND && SHIFT) {
					this.props.buffer.replaceStyles();
				} else if (isDesignMode && COMMAND) {
					this.props.buffer.pasteBefore();
				}
				break;
			case KEYS.KEY_Z:
				if (COMMAND && SHIFT) {
					if (this.props.canRedo) this.props.onRedo();
				} else if (COMMAND) {
					if (this.props.canUndo) this.props.onUndo();
				}
				break;
			case KEYS.KEY_S:
				if (COMMAND) {
					this.props.cardEditorSync();
				}
				break;
			case KEYS.KEY_D:
				if (isDesignMode && COMMAND) {
					this.props.buffer.copy();
					this.props.buffer.pasteBefore();
				}
				break;
			case KEYS.KEY_L:
				EditorKeyboardEvEm.emit('toggleLayers');
				break;
			case KEYS.KEY_A:
				EditorKeyboardEvEm.emit('toggleAddComponent');
				break;
			case KEYS.KEY_P:
				EditorKeyboardEvEm.emit('togglePreview');
				break;
			case KEYS.Digit1:
				if (ALT) EditorKeyboardEvEm.emit('setPlatform', DEFAULT_MEDIA_QUERY_PLATFORMS.MOBILE);
				break;
			case KEYS.Digit2:
				if (ALT) EditorKeyboardEvEm.emit('setPlatform', DEFAULT_MEDIA_QUERY_PLATFORMS.MOBILE_LANDSCAPE);
				break;
			case KEYS.Digit3:
				if (ALT) EditorKeyboardEvEm.emit('setPlatform', DEFAULT_MEDIA_QUERY_PLATFORMS.TABLET);
				break;
			case KEYS.Digit4:
				if (ALT) EditorKeyboardEvEm.emit('setPlatform', DEFAULT_MEDIA_QUERY_PLATFORMS.DESKTOP);
				break;
			case KEYS.Digit5:
				if (ALT) EditorKeyboardEvEm.emit('setPlatform', DEFAULT_MEDIA_QUERY_PLATFORMS.FULL_HD);
				break;
			default:
				break;
		}
	};

	render() {
		return null;
	}
}

const connector = connect((state: AdminReducerState) => ({
	editorMode: selectEditorMode(state),
	isModalOpen: state.modal.id !== MODAL.NONE,
}));

export default withUndoRedo(connector(KeyboardListener));
