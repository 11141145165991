/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, EffectCallback } from 'react';

const useDidMount = (effect: EffectCallback) => {
	useEffect(effect, []);

	return null;
};

export { useDidMount };
