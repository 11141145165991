import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useAdminSelector } from 'admin/reducers';
import { selectUserInfo, selectActiveOrganization } from 'admin/reducers/user/selectors';
import { STORY_PAGE } from 'admin/constants/routes';
import { getUserAvatarLink } from 'admin/utils';
import GlobalStyles from 'common/components/GlobalStyles';

const injectStyleRafRef = { current: 0 };

const Intercom = () => {
	const user = useAdminSelector(selectUserInfo);
	const org = useAdminSelector(selectActiveOrganization);
	const isStoryPage = useRouteMatch<{ storyId?: string }>(`${STORY_PAGE}*`)?.params.storyId !== undefined;

	React.useEffect(() => {
		if (window.Intercom && user.id) {
			window.Intercom('boot', {
				api_base: 'https://api-iam.intercom.io',
				app_id: 'tjm6btkt',
				user_id: user.id,
				name: user.name ?? 'Anonymous',
				email: user.email,
				created_at: Math.floor(+new Date(user.createdAt) / 1000),
				company: { name: org?.name ?? 'Anonymous', id: org?.id ?? 'Anonymous' },
				avatar: { type: 'avatar', image_url: getUserAvatarLink(user.profilePictureFile) },
				hide_default_launcher: isStoryPage,
				custom_launcher_selector: isStoryPage ? '#sc-intercom-launcher' : '',
			});

			injectStyles();

			window.Intercom('onShow', injectStyles);
		}

		return () => {
			if (window.Intercom) window.Intercom('shutdown');
			cancelAnimationFrame(injectStyleRafRef.current);
		};
	}, [user.id, user.email, user.name, user.createdAt, isStoryPage, org?.name, org?.id, user.profilePictureFile]);

	return (
		<GlobalStyles>{`html .intercom-lightweight-app-launcher { background: #666; }
		#sc-intercom-launcher {color: rgba(255, 255, 255, 0.45); transition: color 200ms ease;}
		#sc-intercom-launcher:hover {color: rgba(255, 255, 255, 1)}
	`}</GlobalStyles>
	);
};

function injectStyles() {
	const startTime = performance.now();

	const appendStyle = (iframeDoc: Document) => {
		const styleElement = iframeDoc.createElement('style');
		if (iframeDoc.head.querySelector('style[data-storycards]')) return;
		styleElement.dataset.storycards = 'true';
		styleElement.innerHTML = ` html .intercom-launcher, html .intercom-launcher.intercom-launcher-active {
            background: #666;
        }`;
		iframeDoc.head.appendChild(styleElement);
	};

	const lookupIframe = () => {
		const frame = document.querySelector<HTMLIFrameElement>('iframe.intercom-launcher-frame');
		if (frame?.contentWindow?.document.readyState === 'complete') {
			appendStyle(frame.contentDocument || frame.contentWindow.document);
		} else if (performance.now() - startTime < 5000) {
			injectStyleRafRef.current = requestAnimationFrame(lookupIframe);
		}
	};

	cancelAnimationFrame(injectStyleRafRef.current);

	injectStyleRafRef.current = requestAnimationFrame(lookupIframe);

	return injectStyleRafRef;
}

export default Intercom;
