import produce, { Draft } from 'immer';
import { set } from 'lodash';

import {
	BBMetaType,
	BBModel,
	BBModelTextChild,
	BBSymbolInstance,
	CardData,
	CardFlowEvent,
	StoryModel,
	StorySettingsType,
	StoryVersionType,
	type StoryMediaPlatform,
	BBStates,
} from 'types/story';
import { CARD_TYPE, characterPoints, COMPONENT_STATES, COMPONENT_TYPE } from 'common/constants';
import { Counter } from 'utils/helpers';
import { isSymbol } from 'utils/blocks/symbol';
import { getAssetURL } from 'utils/assets';
import { CountType } from 'utils/facades/navigation-card-facade';
import { findComponentBy } from 'utils/blocks/find-component-by';
import { componentWalkFull } from 'utils/blocks/component-walk-full';
import * as T from './types';

type Keywords = null | T.KeywordsResponse['response']['keywords'];

type BaseData = null | T.StoryBaseResponse['response']['answers'][number];

type Characters = T.CharactersResponse['response'];

type CharacterIdMap = Map<CardFlowEvent['name'], CardFlowEvent['_id']>;

type Images = { [componentId: string]: T.ImageResponse['response'] };

type StoryData = {
	name: StoryModel['name'];
	type: Exclude<StoryModel['type'], null>;
	language: StoryModel['language'];
	tags: StoryModel['tags'];
	storyData: StoryVersionType['data'];
	storySettings: StorySettingsType;
	slug: string;
};

class DataUpdater {
	static updateStoryName(draftStory: Draft<StoryData>, name: string) {
		draftStory.name = name;
	}

	static updateStorySlug(draftStory: Draft<StoryData>, slug: string) {
		draftStory.slug = slug;
	}

	static updateSEO(draftSettings: Draft<StorySettingsType>, keywords: Keywords, base: BaseData) {
		draftSettings.seo = {
			...draftSettings.seo,
			...(keywords ? { keywords: keywords.join(',') } : null),
			...(base ? { title: base.title } : null),
			...(base ? { description: base.description } : null),
		};
	}

	static updateShare(draftSettings: Draft<StorySettingsType>, base: BaseData) {
		draftSettings.share = {
			...draftSettings.share,
			...(base ? { title: base.title } : null),
			...(base ? { description: base.description } : null),
		};
	}

	static updateCardName(draftCard: Draft<CardData>, name: string) {
		draftCard.name = name;
	}

	static updateAnswerIsCorrect(
		draftSettings: Draft<StorySettingsType>,
		params: { cardId: string; answerId: string; isCorrect: boolean }
	) {
		set(draftSettings, ['cards', params.cardId, 'answers', params.answerId, 'isCorrect'], params.isCorrect);
	}

	static updateAnswerCharacterPoints(
		draftSettings: Draft<StorySettingsType>,
		params: {
			cardId: string;
			answerId: string;
			points: T.PersonalityCardResponse['response']['answers'][number]['scores'];
			charactersMap: CharacterIdMap;
		}
	) {
		set(
			draftSettings,
			['cards', params.cardId, 'answers', params.answerId, characterPoints],
			Object.fromEntries(
				[...params.charactersMap.entries()].map(([characterName, characterId]) => [
					characterId,
					params.points[characterName] ?? 0,
				])
			)
		);
	}

	static updateNavigationCharacterEvents(
		draftCards: Draft<CardData[]>,
		params: { storySettings: StorySettingsType; characters: FormattedContent['characters'] }
	): CharacterIdMap {
		const charactersMap: CharacterIdMap = new Map();

		draftCards.forEach(draftCard => {
			const cardSettings = params.storySettings.cards?.[draftCard._id] ?? {};
			const isCharacterCountType = cardSettings.countType === CountType.character;

			if (isCharacterCountType) {
				draftCard.events.forEach(draftEvent => {
					const character = params.characters.find(c => c.id === draftEvent._id);

					if (character) {
						charactersMap.set(character.name, draftEvent._id);
						draftEvent.name = character.name;
					}
				});
			}
		});

		return charactersMap;
	}

	static updateTextChildNode(
		draftComponent: BBModel,
		text: string | undefined,
		params: { platform: StoryMediaPlatform }
	) {
		if (text) {
			draftComponent.children = {
				[COMPONENT_STATES.DEFAULT]: { [params.platform]: text },
			} as BBModelTextChild;
		}
	}

	static updateImageSource(
		draftComponent: BBModel,
		image: T.ImageResponse['response'] | undefined,
		params: { platform: StoryMediaPlatform; state: BBStates }
	) {
		if (image) {
			set(
				draftComponent.uiConfig.componentProps,
				['styles', params.state, params.platform, 'backgroundImage'],
				getAssetURL({ filepath: image.key, hosting: image.hosting })
			);

			// Size of the image and component block is unknown, without image parsing it is not possible to assign
			// correct backgroundPosition which is further assigned to "rect" query aimed to render designed image area.
			// Temporarily it is decided to hardcode "0,0,1024,1024" which is in most fits better than
			// backgroundPosition from configured in the original template
			// TODO: Recommended solution is to parse image as you do when loading image from editor to get valid props
			set(
				draftComponent.uiConfig.componentProps,
				['styles', params.state, params.platform, 'backgroundPosition'],
				'0,0,1024,1024'
			);
		}
	}
}

interface FormattedContent {
	keywords: Keywords;
	base: BaseData;
	characters: Characters;
	images: Images;
	cards: Partial<{
		[CARD_TYPE.TRIVIA]: { [cardId: string]: T.TriviaCardResponse['response'] };
		[CARD_TYPE.POLL]: { [cardId: string]: T.PollCardResponse['response'] };
		[CARD_TYPE.INFO]: { [cardId: string]: T.InfoCardResponse['response'] };
		[CARD_TYPE.PERSONALITY_TEST]: { [cardId: string]: T.PersonalityCardResponse['response'] };
		[CARD_TYPE.TRUE_OR_FALSE]: undefined;
		[CARD_TYPE.THIS_OR_THAT]: undefined;
		[CARD_TYPE.NAVIGATION]: undefined;
		[CARD_TYPE.FORM]: undefined;
		[CARD_TYPE.REGISTRATION]: undefined;
		[CARD_TYPE.SANDBOX]: undefined;
	}>;
}

const getFormattedContent = (content: T.PromptsResponseList): FormattedContent => {
	return [...content]
		.sort((a, b) => a.id - b.id)
		.reduce(
			(acc, item) => {
				switch (item.type) {
					case 'GetKeywords':
						acc.keywords = item.response.keywords;
						break;
					case 'GetStoryBaseData':
						acc.base = item.response.answers?.[0] ?? null;
						break;
					case 'GetPersonalityQuizCharacters':
						acc.characters = acc.characters.concat(item.response);
						break;
					case 'GetInfoCard': {
						const id = item.response.cardId;
						const prev = acc.cards.INFO;
						acc.cards.INFO = { ...prev, [id]: { ...prev?.[id], ...item.response } };
						break;
					}
					case 'GetTriviaCard': {
						const id = item.response.cardId;
						const prev = acc.cards.TRIVIA;
						acc.cards.TRIVIA = { ...prev, [id]: { ...prev?.[id], ...item.response } };
						break;
					}
					case 'GetPollCard': {
						const id = item.response.cardId;
						const prev = acc.cards.POLL;
						acc.cards.POLL = { ...prev, [id]: { ...prev?.[id], ...item.response } };
						break;
					}
					case 'GetPersonalityCard': {
						const id = item.response.cardId;
						const prev = acc.cards.PERSONALITY_TEST;
						acc.cards.PERSONALITY_TEST = { ...prev, [id]: { ...prev?.[id], ...item.response } };
						break;
					}
					case 'GetImage': {
						acc.images[item.response.id] = item.response;
						break;
					}
					default:
						break;
				}
				return acc;
			},
			{ keywords: null, base: null, characters: [], images: {}, cards: {} } as FormattedContent
		);
};

type CardTypes = Exclude<FormattedContent['cards'][keyof FormattedContent['cards']], undefined>[string];
function getCardTitle(card: CardTypes) {
	if (card) {
		if ('title' in card) return card.title;
		if ('question' in card) return card.question;
	}

	return 'No title';
}

const GENERATOR_DEFAULT_CONFIG = {
	storyName: true,
	storySlug: true,
	seo: true,
	share: true,
	navigationCharacters: true,
	/**
	 * Flag to determine which cards data should be updated
	 *
	 * true     - update all cards
	 * false    - no cards update
	 * string[] - update card by id from the given array
	 */
	cards: true as boolean | string[],
};

/**
 * Injects generated content into the story. Any modifications
 * made by this function should be performed through the `DataUpdater`
 * class to ensure proper data management and consistency.
 */
export function injectGeneratedContent(params: {
	story: StoryData;
	content: T.PromptsResponseList;
	config?: Partial<typeof GENERATOR_DEFAULT_CONFIG>;
	platform: StoryMediaPlatform;
	state: BBStates;
}) {
	const { state, platform } = params;
	const config = { ...GENERATOR_DEFAULT_CONFIG, ...params.config };
	const content = getFormattedContent(params.content);

	return produce(params.story, story => {
		if (config.storyName && content.base?.title) DataUpdater.updateStoryName(story, content.base.title);
		if (config.storySlug && content.base?.slug) DataUpdater.updateStorySlug(story, content.base.slug);
		if (config.seo) DataUpdater.updateSEO(story.storySettings, content.keywords, content.base);
		if (config.share) DataUpdater.updateShare(story.storySettings, content.base);

		// count cards by type
		const cardCounter = new Counter<CardData['type']>({ startFrom: -1 });

		const cards = story.storyData.steps.reduce((acc, step) => acc.concat(step.cards), [] as CardData[]);
		const navCards = cards.filter(card => card.type === CARD_TYPE.NAVIGATION);

		const charactersMap = DataUpdater.updateNavigationCharacterEvents(config.navigationCharacters ? navCards : [], {
			storySettings: story.storySettings,
			characters: content.characters,
		});

		cards.forEach(card => {
			cardCounter.increment(card.type);
			const cardType = card.type;
			const cardContent = content.cards[cardType]?.[card._id];
			const cardSettings = story.storySettings.cards?.[card._id] ?? {};
			const isCharacterCountType = cardSettings.countType === CountType.character;

			if (
				!cardContent ||
				!config.cards ||
				(Array.isArray(config.cards) && !config.cards.find(id => card._id === id))
			) {
				return;
			}

			const cardTitle = getCardTitle(cardContent);

			DataUpdater.updateCardName(card, cardTitle);

			// count answers in card
			const answerCounter = new Counter<CardData['_id']>({ startFrom: -1 });

			componentWalkFull({
				elements: card.elements,
				storyElements: story.storyData.elements,
				symbols: story.storyData.symbols ?? {},
				callback: function walk({ component }) {
					if (component.type === COMPONENT_TYPE.ANSWER && 'answers' in cardContent) {
						answerCounter.increment(card._id);

						const answer = cardContent.answers[answerCounter.getCount(card._id)] ?? {};
						if ('correct' in answer) {
							DataUpdater.updateAnswerIsCorrect(story.storySettings, {
								cardId: card._id,
								answerId: component._id,
								isCorrect: answer.correct,
							});
						}

						if ('scores' in answer && isCharacterCountType) {
							DataUpdater.updateAnswerCharacterPoints(story.storySettings, {
								cardId: card._id,
								answerId: component._id,
								points: answer.scores,
								charactersMap,
							});
						}
					}

					const { metaType } = component.uiConfig.componentProps;

					if (!metaType) {
						return;
					}

					let targetComponent: BBModel | undefined = component;

					if (isSymbol(component)) {
						const { masterId, instanceId, childId } = component.symbol;
						const symbol = story.storyData.symbols![masterId];
						const instance = symbol.instance[instanceId] as BBSymbolInstance;

						targetComponent = childId
							? findComponentBy([instance], { path: 'symbol.childId', value: childId })?.component
							: instance;
					}

					// this case should not happen, but added for a save
					if (!targetComponent) {
						return;
					}

					switch (metaType) {
						case BBMetaType.title:
							DataUpdater.updateTextChildNode(targetComponent, cardTitle, { platform });
							break;
						case BBMetaType.subtitle:
							DataUpdater.updateTextChildNode(
								targetComponent,
								'subtitle' in cardContent ? cardContent.subtitle : undefined,
								{ platform }
							);
							break;
						case BBMetaType.questionText:
							DataUpdater.updateTextChildNode(targetComponent, cardTitle, { platform });
							break;
						case BBMetaType.answerText: {
							const answer =
								'answers' in cardContent
									? cardContent.answers[answerCounter.getCount(card._id)] ?? {}
									: null;
							DataUpdater.updateTextChildNode(targetComponent, answer?.title, { platform });
							break;
						}
						case BBMetaType.image:
						case BBMetaType.bgImage:
						case BBMetaType.answerImage:
							DataUpdater.updateImageSource(targetComponent, content.images[targetComponent._id], {
								platform,
								state,
							});
							break;
						default:
							break;
					}
				},
			});
		});
	});
}
