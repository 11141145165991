import produce from 'immer';
import { CSSProperties } from 'react';
import { entries, forEach, forOwn, isUndefined, omitBy } from 'lodash';
import kebabCase from 'lodash/kebabCase';
import type {
	BBModel,
	BBOtherProp,
	BBStates,
	BBStylesProp,
	BBUiConfig,
	StoryMediaPlatform,
	StoryMediaQuery,
	WithStateAndPlatform,
} from 'types/story';
import {
	CARD_ROOT_ELEMENT_INDEX,
	COMPONENT_STATES,
	COMPONENT_STATES_LIST,
	COMPONENT_STATES_ORDERED,
	COMPONENT_TYPE,
} from 'common/constants';
import { CSS_PROPS } from 'common/constants/component-props';
import { memoize } from 'utils/helpers';
import { isLayerType } from 'utils/blocks/is-layer-type';
import { orderMediaQueryConfig } from 'utils/story-media-query';
import { buildImageUrl } from 'utils/build-image-src';
import { bgImage, isGradient, replaceUrlHostname } from 'utils/assets';
import { getCustomCssProp } from 'utils/blocks/get-custom-css-prop';
import { getStateDOMAttr } from 'client/components/common/BuildingBlocks/utils/common';
import type { TraverseTreeParams } from 'client/components/common/BuildingBlocks/utils/traverse-tree-types';

/*------------------------------------------------------------------------
Styles

Order:
1 first, the usual selectors (id) ordered by media queries
2 then state overrides (state data attribute selector)

Selector types:
1. id
2. id + state data attribute selector

How state selector works
- When working in client side, we manage attributes in Component to apply specific state styles to DOM element
- When working in editor:
    -> initialize state in Admin (cardEditorReducer.state)
     -> transmit state to client
      -> assign state selector to "uiConfig.nodeProps.<data-state>"
        -> assigning this attr in DOM
------------------------------------------------------------------------*/

type ParentType = { id: string; states: BBStates[] };

type MapStylesProps = {
	element: BBModel;
	isCardTree: boolean;
	orderedMediaQueryArray: ReturnType<typeof orderMediaQueryConfig>;
	mediaQuery: StoryMediaQuery;
	storycardsDomain: TraverseTreeParams['options']['storycardsDomain'];
	originalTree: TraverseTreeParams['tree'];
	// used to generate selectors based on parent component state
	parent?: ParentType;
};

type MapStylesReturnValue = {
	css: string;
	parent: ParentType;
};

function generateElementCssString({
	element,
	parent = { id: '', states: [] },
	isCardTree,
	orderedMediaQueryArray,
	mediaQuery,
	originalTree,
	storycardsDomain,
}: MapStylesProps): MapStylesReturnValue {
	let css = '';
	const elementStyles = element.uiConfig.componentProps.styles as WithStateAndPlatform<BBStylesProp>;

	// skip story elements in card elements render
	if (isLayerType(element).global && isCardTree) {
		return { css, parent };
	}

	const { id } = element.uiConfig.nodeProps;
	const nextParent = { ...parent };

	const owsStates = COMPONENT_STATES_ORDERED.filter(state =>
		(COMPONENT_STATES_LIST[element.type] ?? []).includes(state)
	);
	const states = parent.states.length ? parent.states : owsStates;
	const uniqStates = [...new Set([COMPONENT_STATES.DEFAULT, ...states])];

	forEach(uniqStates, state => {
		forEach(orderedMediaQueryArray, mq => {
			const { config, key: platform } = mq;
			const mediaProperty = config.minWidth ? 'min-width' : 'max-width';
			const mediaValue = Math.max(config.minWidth ?? 0, config.maxWidth ?? 0);
			const media = `@media (${mediaProperty}: ${mediaValue}px)`;

			// pick element styles for current media query
			let cssObject = elementStyles?.[state]?.[platform] as BBStylesProp | undefined;
			const stateSelector = getStateSelector({ state });

			let selector = `#${id}${stateSelector}`;
			if (parent.id && parent.id !== id && stateSelector) {
				selector = `#${parent.id}${stateSelector} #${id}`;
			}

			if (stateSelector && !parent.id) {
				nextParent.id = id;
				nextParent.states = states;
			}

			if (isCardTree) {
				cssObject = injectStyles({
					to: cssObject || {},
					platform,
					state,
					element,
					originalTree,
				});
			}

			// skip empty object to next
			if (!cssObject) {
				return;
			}

			const payload: MapCssObjectProps = {
				cssObject,
				selector,
				element,
				mediaQuery,
				storycardsDomain,
				isCardTree,
			};

			if (mediaValue) {
				const rules = mapCssObject({ ...payload, addTab: true });
				if (rules) css += `${media} {\n${rules}}\n`;
			} else {
				css += mapCssObject(payload);
			}
		});
	});

	return { css, parent: nextParent };
}

type TCSSValue = string | number;

type TCSSProperty = string;

type TCSSParams = {
	storycardsDomain: TraverseTreeParams['options']['storycardsDomain'];
	mq: StoryMediaQuery['config'];
	/* determine if kebabCase transformation should be applied to the property. For standard CSS properties,
	   it should be set to `true` since they are provided in camel case. However, for custom properties initially
	   defined in kebab case, it should be set to `false`. */
	toKebabCase: boolean;
};

const CSSValueMap = {
	backgroundImage: (v: TCSSValue, p: TCSSParams) => {
		const value = `${v}`;
		if (isGradient(value)) {
			return value;
		}

		const constructedImage = buildImageUrl({
			type: 'card',
			src: replaceUrlHostname(value, p.storycardsDomain),
			mq: p.mq,
			storycardsDomain: p.storycardsDomain,
		});
		return bgImage(constructedImage.src).backgroundImage;
	},
	gridAutoRows: (v: TCSSValue) => `minmax(auto, ${v})`,
	fontFamily: (v: TCSSValue) => wrapFontNamesWithQuotes(`${v}`),
	[CSS_PROPS.custom.__CSS]: (v: TCSSValue) => `-->; ${v}`,
	default: (v: TCSSValue) => v,
};

function CSSValue(property: TCSSProperty, value: TCSSValue, params: TCSSParams): TCSSValue {
	return CSSValueMap[property] ? CSSValueMap[property](value, params) : CSSValueMap.default(value);
}

const memoizedKebabCase = memoize(kebabCase);
function CSSRule(property: TCSSProperty, value: TCSSValue, params: TCSSParams) {
	return `${params.toKebabCase ? memoizedKebabCase(property) : property}: ${CSSValue(property, value, params)};`;
}

function wrapFontNamesWithQuotes(fonts: string): string {
	return fonts
		.split(',')
		.map(font => font.trim())
		.map(font => (font.includes(' ') && !font.startsWith('"') && !font.endsWith('"') ? `"${font}"` : font))
		.join(', ');
}

/**
 * @info 💡If you're adding a new compound selector,
 * you've to add his implementation in "traverseTree" and his @getComputedValue too
 *
 * @description get css selector accordingly to state and environment
 * @param state
 */

function getStateSelector({ state }: { state: BBStates }) {
	return state === COMPONENT_STATES.DEFAULT ? '' : `[${getStateDOMAttr(state)}]`;
}

type InjectStylesProps = {
	to: BBUiConfig['componentProps']['styles'];
	state: BBStates;
	platform: StoryMediaPlatform;
	element: BBModel;
	originalTree: TraverseTreeParams['tree'];
};

/**
 * Inject style stored in other component (source) to the desired one (destination)
 */
function injectStyles({ state, platform, to, element, originalTree }: InjectStylesProps) {
	const cardOtherProps = originalTree[CARD_ROOT_ELEMENT_INDEX[COMPONENT_TYPE.CARD]].uiConfig.componentProps
		.other as WithStateAndPlatform<BBOtherProp>;

	const from = {
		[COMPONENT_TYPE.CONTENT]: {
			height: cardOtherProps?.[state]?.[platform]?.contentHeight,
			minHeight: cardOtherProps?.[state]?.[platform]?.contentMinHeight,
			marginTop: cardOtherProps?.[state]?.[platform]?.contentMarginTop,
			marginBottom: cardOtherProps?.[state]?.[platform]?.contentMarginBottom,
			marginLeft: cardOtherProps?.[state]?.[platform]?.contentMarginLeft,
			marginRight: cardOtherProps?.[state]?.[platform]?.contentMarginRight,
		},
	};

	return produce(to, draft => {
		if (from[element.type]) {
			Object.assign(draft, omitBy(from[element.type], isUndefined));
		}
	});
}

type MapCssObjectProps = {
	mediaQuery: StoryMediaQuery;
	cssObject: CSSProperties;
	selector: string;
	addTab?: boolean;
	element: BBModel;
	storycardsDomain: TraverseTreeParams['options']['storycardsDomain'];
	isCardTree: boolean;
};

function mapCssObject({
	cssObject,
	selector,
	addTab,
	element,
	mediaQuery,
	storycardsDomain,
	isCardTree,
}: MapCssObjectProps) {
	let result = '';
	let rules = '';
	let customPropResult = '';
	const tab = addTab ? '\t' : '';
	const isImg = element.type === COMPONENT_TYPE.IMG;
	const isShare = element.type === COMPONENT_TYPE.SHARE;

	const cssArray = entries(cssObject).sort(([a], [b]) => {
		// Why borderRadius? shorthand property should have less priority against particular property
		if (
			a === CSS_PROPS.custom.__CSS ||
			([CSS_PROPS.borders.borderRadius, CSS_PROPS.counter.unit.borders.borderRadius] as string[]).includes(b)
		)
			return 1;
		if (b === CSS_PROPS.custom.__CSS) return -1;
		return a.localeCompare(b);
	});

	forEach(cssArray, css => {
		let [property, cssValue] = css;
		const customCssProp = getCustomCssProp(element.type, property, { uiConfig: element.uiConfig });

		// unsupported css rules
		if (customCssProp) {
			const { selectorPostfix, cssProperty, selectorPrefix } = customCssProp;
			if (property === CSS_PROPS.custom.__globalBlockDisplay) {
				// this property is assigned per card
				forOwn(cssValue, (value, key) => {
					const prefix = selectorPrefix?.({ cardId: key });
					const rule = CSSRule(cssProperty, value, {
						mq: mediaQuery.config,
						toKebabCase: false,
						storycardsDomain,
					});

					if (rule && !isCardTree) {
						customPropResult += `${tab}${prefix} ${selector} {\n`;
						customPropResult += `${tab}\t${rule}\n`;
						customPropResult += `${tab}}\n`;
					}
				});
			} else {
				const postfix = selectorPostfix?.() || '';
				const prefix = selectorPrefix?.(cssValue as any) || '';
				const rule = CSSRule(cssProperty, cssValue, {
					mq: mediaQuery.config,
					toKebabCase: false,
					storycardsDomain,
				});

				if (rule) {
					customPropResult += `${tab}${prefix} ${selector} ${postfix} {\n`;
					customPropResult += `${tab}\t${rule}\n`;
					customPropResult += `${tab}}\n`;
				}
			}
		} else {
			if (isImg || isShare) {
				// v1.9.0 background image is replaced with an <img>, so have to use img properties
				if (property === 'backgroundSize') property = 'objectFit';
				if (property === 'backgroundPosition') cssValue = '';
				if (property === 'backgroundRepeat') cssValue = '';
				if (property === 'backgroundImage' && !isGradient(cssValue)) cssValue = '';
			}

			if (property === CSS_PROPS.layout.display && !isCardTree) {
				// Do not render regular `display` property for `StoryElements`
				cssValue = '';
			}
		}

		// supported css rules
		if (cssValue !== '' && cssValue !== undefined && !customCssProp) {
			rules += `${tab}\t${CSSRule(property, cssValue, {
				mq: mediaQuery.config,
				toKebabCase: true,
				storycardsDomain,
			})}\n`;
		}
	});

	if (rules) result += `${tab}${selector} {\n${rules}${tab}}\n`;
	if (customPropResult) result += customPropResult;

	return result;
}

export { generateElementCssString };
