export const IS_DEVEL = process.env.NODE_ENV === 'development';

export const IS_PROD = process.env.NODE_ENV === 'production';

export const isIOS = () => /iPhone|iPad|iPod/i.test(navigator.userAgent);

export const isAndroid = () => /Android/i.test(navigator.userAgent);

export const getDeviceType = () => {
	const ua = navigator.userAgent;
	if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
		return 'tablet';
	}
	if (
		/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
	) {
		return 'mobile';
	}
	return 'desktop';
};

export const isTouch = 'ontouchstart' in window;
