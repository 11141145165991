import { useEffect } from 'react';
import type { StoryMediaPlatform, StoryMediaQuery } from 'types/story';
import { getMediaQueryBoundaries } from 'utils/story-media-query';
import { setEditorSize } from 'admin/actions';

const MediaBoundariesObserver = ({ currentMediaQuery, mediaQuery, setEditorSize: setEditorSizeP }: Props) => {
	useEffect(() => {
		const { min, max } = getMediaQueryBoundaries({
			currentMediaQuery,
			mediaQuery,
		});

		setEditorSizeP({ min, max, platform: currentMediaQuery });
	}, [currentMediaQuery, mediaQuery, setEditorSizeP]);

	return null;
};

type Props = {
	setEditorSize: typeof setEditorSize;
	currentMediaQuery: StoryMediaPlatform;
	mediaQuery: StoryMediaQuery;
};

export default MediaBoundariesObserver;
