import { isFunction } from 'lodash';
import React from 'react';
import cn from 'classnames';
import { Icon } from 'admin/components/common/Icon';
import { Label, LabelProps } from '../Label';
import { Errors } from '../Errors';
import { ReduxFieldInputTypes, ReduxFieldMetaTypes } from '../utils';
import css from './Checkbox.scss';

type PureCheckboxProps = {
	id?: string;
	label?: Omit<LabelProps, 'component' | 'theme'>;
	className?: string;
	name?: string;
	value?: string | number | boolean | null;
	checked?: boolean;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	theme?: 'dark' | 'light';
	disabled?: boolean;
	stretch?: boolean;
	boxIcon?: 'round' | 'square';
	boxSize?: number;
	labelPosition?: 'left' | 'right' | 'top';
	alignItems?: 'top' | 'center';
};

type CheckboxProps = Omit<PureCheckboxProps, 'value'> & {
	propValue: PureCheckboxProps['value'];
	input: ReduxFieldInputTypes;
	meta: ReduxFieldMetaTypes;
	defaultChecked?: boolean;
	showErrorsOn?: string;
	fieldClassName?: string;
};

// independent field
export const CheckboxPure = ({
	className,
	label,
	boxIcon = 'round',
	theme = 'light',
	labelPosition = 'left',
	boxSize = 20,
	stretch,
	alignItems = 'center',
	...props
}: PureCheckboxProps) => (
	<label
		className={cn(css.checkbox, css[theme], css[`align-${alignItems}`], css[`label-${labelPosition}`], className, {
			[css.disabled]: props.disabled,
			[css.checked]: props.checked,
			[css.stretch]: stretch,
		})}
	>
		<Label {...label} theme={theme} component="div" className={cn(css.label, label?.className)} />
		<div className={cn(css.checkboxInner)}>
			<input {...props} type="checkbox" value={`${props.value}`} />
			{boxIcon === 'square' ? (
				<Icon
					width={boxSize}
					className={css.checkboxBox}
					type={`${props.checked ? 'check-outline' : 'empty'}-square`}
					color="currentColor"
				/>
			) : (
				<Icon
					width={boxSize}
					className={css.checkboxBox}
					type={`${props.checked ? 'check-outline' : 'empty'}-rounded`}
					color="currentColor"
				/>
			)}
		</div>
	</label>
);

// redux field
export const Checkbox = ({ theme = 'light', showErrorsOn = 'touched', ...props }: CheckboxProps) => {
	const showErrors = isFunction(showErrorsOn)
		? showErrorsOn(props.meta, props.input)
		: props.meta[showErrorsOn || ''];
	const id = `${props.meta.form}.${props.input.name}`;

	return (
		<div className={cn(css.fieldCheckbox, css[theme], props.className, { [css.disabled]: props.disabled })}>
			<CheckboxPure
				id={id}
				label={props.label}
				name={props.input.name}
				disabled={props.disabled}
				value={props.input.value}
				checked={props.input.value === '' ? Boolean(props.defaultChecked) : props.input.value}
				onChange={props.input.onChange}
				onFocus={props.input.onFocus}
				onBlur={props.input.onBlur}
				className={props.fieldClassName}
				boxIcon={props.boxIcon}
				boxSize={props.boxSize}
				labelPosition={props.labelPosition}
				theme={theme}
				stretch={props.stretch}
				alignItems={props.alignItems}
			/>
			<Errors show={showErrors}>{props.meta.error}</Errors>
		</div>
	);
};
