import { nanoid } from 'nanoid';
import { camelCase, set } from 'lodash';
import { ComponentTypes, BBModel } from 'types/story';
import { COMPONENT_DISPLAY_NAME } from 'common/constants';

export const NODE_ID_SEPARATOR = '-';

export const generateId = (len = 16): string => nanoid(len);

/**
 * Generate building block _id field.
 *
 * size = 9 -> ~19,000,000 ids needed to have a 1% probability of at least one collision
 */
export const generateElementId = (): string => nanoid(9);

/**
 * generate building block uiConfig.nodeProps.id field
 * @param id {string} building block _id field value
 * @param componentType {string} building block type. 'fla' & 'flb' are exceptions for support of older versions.
 * @return {string}
 */
export const generateNodeId = (
	id: ReturnType<typeof generateElementId>,
	componentType: ComponentTypes | 'fla' | 'flb'
): string =>
	`${camelCase(
		componentType in COMPONENT_DISPLAY_NAME ? COMPONENT_DISPLAY_NAME[componentType] : componentType
	).toLowerCase()}${NODE_ID_SEPARATOR}${id}`;

export const generateCardId = () => generateId(5);

export const generateCardEventId = () => generateId(5);

export const generateStepId = () => generateId(5);

// update component id with a new one
export const updateId = (component: BBModel) => {
	const id = generateElementId();
	set(component, '_id', id);
	set(component, 'uiConfig.nodeProps.id', generateNodeId(id, component.type));
	return id;
};
