import React, { ReactNode, useEffect } from 'react';
import cn from 'classnames';
import css from './GlobalNotification.scss';

type Props = {
	className?: string;
	type: 'danger';
	children: ReactNode;
	isWide: boolean;
};

const GlobalNotification = (props: Props) => {
	useEffect(() => {
		document.body.setAttribute('show-global-notification', '');

		return () => {
			document.body.removeAttribute('show-global-notification');
		};
	}, []);

	return (
		<div className={cn(css.notification, css[props.type], props.className, { [css.wide]: props.isWide })}>
			<div className={css.container}>{props.children}</div>
		</div>
	);
};

export default GlobalNotification;
