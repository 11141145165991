import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ActionCreators as UndoActionCreators } from 'redux-undo';
import type { CardEditorType } from 'types/story';
import type { AdminReducerState } from 'admin/reducers';
import { CLIENT_IFRAME_ID } from 'common/utils/iframe-tunnel';
import { CARD_ROOT_ELEMENT_INDEX, COMPONENT_TYPE, DEFAULT_MEDIA_QUERY_PLATFORMS, PARAMS } from 'common/constants';
import { ADMIN_PREVIEW_PAGE } from 'client/constants/routes';
import { setEditorSize } from 'admin/actions/card-editor/set-editor-size';
import { selectEditableStateInfo } from 'admin/reducers/card-editor/selectors';
import { selectEditableStoryType } from 'admin/reducers/story-editor/selectors';
import { setEditorCurrentMediaQuery } from 'admin/actions/card-editor/set-editor-current-media-query';
import type { CardEditorContextType } from 'admin/components/pages/Story/CardEditor/CardEditorContext';
import MediaBoundariesObserver from 'admin/components/pages/Story/CardEditor/Editor/MediaBoundariesObserver';
import CardEditorIframeTransmitter from 'admin/components/pages/Story/CardEditor/IframeTransmitter';
import {
	EditorKeyboardEvEm,
	EditorKeyboardSetPlatformEvent,
} from 'admin/components/pages/Story/CardEditor/KeyboardListener';
import css from '../CardEditor.scss';
import Resizer, { clientFrameStyles } from './Resizer';

const { STORY_ID, CARD_ID } = PARAMS;

const getSrc = (storyId, cardId) => `${ADMIN_PREVIEW_PAGE}?${STORY_ID}=${storyId}&${CARD_ID}=${cardId}&editor=true`;

type OwnProps = Pick<CardEditorContextType, 'cardId' | 'storyId' | 'mediaQuery'>;

type Props = ConnectedProps<typeof connector> & OwnProps;

class EditorCanvas extends PureComponent<Props> {
	iframeRef: React.RefObject<HTMLIFrameElement> = React.createRef();

	onIframeResizeThrottle: ReturnType<typeof _.throttle>;

	constructor(props, context) {
		super(props, context);

		this.onIframeResizeThrottle = _.throttle(this.onIframeResize, 100);
	}

	componentDidMount() {
		const platform = this.props.initialPlatform || this.props.mediaQuery.defaultPlatform;

		if (platform !== this.props.mediaQuery.currentMediaQuery) {
			this.props.setEditorCurrentMediaQuery(platform);
		}

		const { currCardId: present, pastCardId: past, futureCardId: future } = this.props;
		const prev = past || future;

		if (prev && present && prev !== present) {
			this.props.clearHistory();
		}

		EditorKeyboardEvEm.addListener('setPlatform', this.setPlatformKeyboardListener);
	}

	componentWillUnmount() {
		this.iframe?.removeListener();
		EditorKeyboardEvEm.removeListener('setPlatform', this.setPlatformKeyboardListener);
	}

	get iframe() {
		const win = this.iframeRef.current?.contentWindow;

		if (!win) return null;

		return {
			contentWindow: win,
			addListener: () => win?.addEventListener('resize', this.onIframeResizeThrottle),
			removeListener: () => win?.removeEventListener('resize', this.onIframeResizeThrottle),
		};
	}

	setPlatformKeyboardListener = (event: EditorKeyboardSetPlatformEvent) => {
		this.props.setEditorCurrentMediaQuery(event.data);
	};

	onIframeLoad = event => {
		event.target.contentDocument.documentElement.setAttribute('data-editor', true);

		this.iframe!.addListener();
		this.onIframeResize();
	};

	onIframeResize = () => {
		const win = this.iframe!.contentWindow;
		this.props.setEditorSize({ width: win?.innerWidth, height: win?.innerHeight });
	};

	onClick = e => {
		if (e.currentTarget === e.target && this.props.cardBlockId) {
			CardEditorIframeTransmitter.initiateElementSelectInClient({
				editableState: this.props.editableState,
				id: this.props.cardBlockId,
			});
		}
	};

	render() {
		const { mediaQuery, initialWidth } = this.props;

		if (mediaQuery.currentMediaQuery === DEFAULT_MEDIA_QUERY_PLATFORMS.DEFAULT) {
			return null;
		}

		return (
			<div className={css.editorCanvas}>
				<MediaBoundariesObserver
					mediaQuery={mediaQuery}
					currentMediaQuery={mediaQuery.currentMediaQuery}
					setEditorSize={this.props.setEditorSize}
				/>
				<Resizer
					storyType={this.props.storyType}
					onContainerClick={this.onClick}
					className={css.resizer}
					mediaQuery={mediaQuery}
					currentMediaQuery={mediaQuery.currentMediaQuery}
					initialWidth={initialWidth ? `${initialWidth}px` : ''}
				>
					{provided => (
						<iframe
							ref={this.iframeRef}
							onLoad={this.onIframeLoad}
							id={CLIENT_IFRAME_ID}
							className={css.iframe}
							src={getSrc(this.props.storyId, this.props.cardId)}
							frameBorder="0"
							title="preview"
							width="100%"
							height="100%"
							style={clientFrameStyles(provided)}
						/>
					)}
				</Resizer>
			</div>
		);
	}
}

const mapStateToProps = (state: AdminReducerState) => ({
	userPreferences: state.user.preferences,
	memberId: state.user.id,
	cardBlockId:
		state.cardEditor.present.data?.elements[CARD_ROOT_ELEMENT_INDEX[COMPONENT_TYPE.CARD]].uiConfig.nodeProps.id,
	pastCardId: (_.last(state.cardEditor.past) as CardEditorType)?.data?._id,
	currCardId: state.cardEditor.present?.data?._id,
	futureCardId: (_.last(state.cardEditor.future) as CardEditorType)?.data?._id,
	initialPlatform: state.cardEditorExtra.size.platform,
	initialWidth: state.cardEditorExtra.size.width,
	editableState: selectEditableStateInfo(state),
	storyType: selectEditableStoryType(state)!,
});

const mapDispatchToProps = {
	setEditorCurrentMediaQuery,
	setEditorSize,
	clearHistory: UndoActionCreators.clearHistory,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(EditorCanvas);
