import React, { useCallback, useEffect } from 'react';

type UseInfinityScrollProps = {
	callback: () => void;
	hasMore: boolean;
	isLoading: boolean;
	containerRef: React.RefObject<HTMLDivElement>;
	offset?: number;
};

const useInfiniteScroll = ({ isLoading, hasMore, callback, containerRef, offset = 5 }: UseInfinityScrollProps) => {
	const handleScroll = useCallback(() => {
		if (!containerRef.current) return;

		const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
		if (scrollTop + clientHeight >= scrollHeight - offset) {
			if (!isLoading && hasMore) {
				callback();
			}
		}
	}, [callback, hasMore, isLoading, containerRef, offset]);

	useEffect(() => {
		const container = containerRef.current;
		container?.addEventListener('scroll', handleScroll);
		return () => container?.removeEventListener('scroll', handleScroll);
	}, [handleScroll, containerRef]);
};

export default useInfiniteScroll;
