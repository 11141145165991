import React from 'react';
import cn from 'classnames';
import { StoryMediaPlatform } from 'types/story';
import { DEFAULT_MEDIA_QUERY_PLATFORMS } from 'common/constants';
import { Icon, IconType } from 'admin/components/common/Icon';

import css from './MediaQueryIcon.scss';

const getType = (platform: string, isDefault: boolean) => {
	switch (platform) {
		case DEFAULT_MEDIA_QUERY_PLATFORMS.FULL_HD:
			return 'sc-full-hd';
		case DEFAULT_MEDIA_QUERY_PLATFORMS.DESKTOP:
			return isDefault ? 'sc-computer-default' : 'sc-computer';
		case DEFAULT_MEDIA_QUERY_PLATFORMS.TABLET:
			return 'sc-tablet';
		case DEFAULT_MEDIA_QUERY_PLATFORMS.MOBILE_LANDSCAPE:
		case DEFAULT_MEDIA_QUERY_PLATFORMS.MOBILE:
			return 'sc-phone-iphone';
		default:
			return 'sc-computer';
	}
};

interface MediaQueryIconProps {
	platform: StoryMediaPlatform;
	defaultPlatform: StoryMediaPlatform;
	noFill?: boolean;
	className?: string;
	width?: number;
	height?: number;
}

const MediaQueryIcon: React.FC<MediaQueryIconProps> = (props: MediaQueryIconProps) => {
	const { platform, defaultPlatform, className, noFill, width, height } = props;
	const isDefault = platform === defaultPlatform;

	return (
		<Icon
			className={cn(
				css.icon,
				className,
				{
					[css.landscape]: platform === 'mobileLandscape',
					// only to support old stories, when any platform could was a default
					[css.default]: platform !== 'desktop' && isDefault,
				},
				noFill && css.noFill
			)}
			width={width}
			height={height}
			type={`${getType(platform, isDefault)}${noFill ? '-no-paddings' : ''}` as IconType}
		/>
	);
};

MediaQueryIcon.defaultProps = {
	noFill: false,
	className: '',
	width: undefined,
	height: undefined,
};

export { MediaQueryIcon };
