import React from 'react';
import cn from 'classnames';
import { get } from 'lodash';
import { Field, change } from 'redux-form';

import translate from 'utils/translate';
import { IntegrationsFacade } from 'common/utils/facades';
import { useAdminDispatch } from 'admin/reducers';
import { Hint, Select, Toggle, type SelectOption } from 'admin/components/common/Form';
import Text from 'admin/components/common/Text';
import {
	ELEMENT_OPTIONS,
	PLATFORM_OPTIONS,
	DARK_MODE_URL_PARAM,
	DARK_MODE_URL_PARAM_VALUE,
	defaultProps,
	DARK_MODE_ELEMENTS,
	ALL_PLATFORMS,
} from 'client/components/pages/Story/DarkMode/constants';

import type { SettingsTabsGeneralProps } from '../types';
import css from './DarkMode.scss';

interface Props extends SettingsTabsGeneralProps {
	className?: string;
}

const t = (path: string, options?: any) => translate(`story.settings.darkMode.${path}`, options);
const pickOptionsByValues =
	(options: SelectOption[]) =>
	(values: string[] = []): SelectOption[] => {
		return values.map(value => options.find(opt => opt.value === value)!).filter(Boolean);
	};
const pickElementOptions = pickOptionsByValues(ELEMENT_OPTIONS);
const defaultElements = pickElementOptions(defaultProps.elements);

const pickPlatformOptions = pickOptionsByValues(PLATFORM_OPTIONS);
const defaultPlatforms = pickPlatformOptions(defaultProps.platforms);

const DarkMode: React.FC<Props> = props => {
	const { className } = props;
	const dispatch = useAdminDispatch();
	const [elements, setElements] = React.useState<SelectOption[]>(defaultElements);
	const [platforms, setPlatforms] = React.useState<SelectOption[]>(defaultPlatforms);
	const isAutoDetectionEnabled = get(props.formValues, props.fields.dmAutoDetection.name(props.version), false);

	const formElements = get(props.formValues, props.fields.dmElements.name(props.version));
	const formPlatforms = get(props.formValues, props.fields.dmPlatforms.name(props.version));
	React.useEffect(() => {
		// reset on form reset
		if (!props.formInfo.dirty) {
			setElements(formElements ? pickElementOptions(formElements) : defaultElements);
			setPlatforms(formPlatforms ? pickPlatformOptions(formPlatforms) : defaultPlatforms);
		}
	}, [formElements, formPlatforms, props.formInfo.dirty]);

	return (
		<div className={cn(className)}>
			<Text className={css.title} size="subheading" weight="bold" text={t('title')} />

			<div className={css.field}>
				<Field
					name={props.fields.dmAutoDetection.name(props.version)}
					component={Toggle}
					theme="dark"
					label={{ children: t('autoDetection'), component: 'label' }}
					stretch
				/>
				<Hint className={css.hint} text={t('autoDetectionDescription')} theme="dark" />
			</div>

			{isAutoDetectionEnabled && (
				<div className={css.field}>
					<Select
						isMulti
						closeMenuOnSelect={false}
						label={{ children: t('platformSelection'), component: 'label' }}
						options={PLATFORM_OPTIONS}
						theme="dark"
						value={platforms}
						eventListeners={{
							onChange: selected => {
								const nextSelected = selected.length ? selected : defaultPlatforms;
								const allPlatformsOptionIndex = nextSelected.findIndex(v => v.value === ALL_PLATFORMS);

								if (nextSelected.length > 1 && allPlatformsOptionIndex !== -1) {
									(nextSelected as SelectOption[]).splice(allPlatformsOptionIndex, 1);
								}

								setPlatforms(nextSelected as SelectOption[]);

								dispatch(
									change(
										props.formInfo.name,
										props.fields.dmPlatforms.name(props.version),
										nextSelected.map((v: SelectOption) => v.value)
									)
								);
							},
						}}
					/>
					<Hint className={css.hintWithMargin} text={t('platformSelectionDescription')} theme="dark" />
				</div>
			)}

			<div className={css.field}>
				<Field
					name={props.fields.dmShowToggle.name(props.version)}
					component={Toggle}
					theme="dark"
					label={{ children: t('showToggle'), component: 'label' }}
					stretch
				/>
				<Hint className={css.hint} text={t('showToggleDescription')} theme="dark" />
			</div>

			<div className={css.field}>
				<Field
					name={props.fields.dmEnableByUrl.name(props.version)}
					component={Toggle}
					theme="dark"
					label={{ children: t('enableByUrl'), component: 'label' }}
					stretch
				/>
				<Hint
					className={css.hint}
					text={
						<div
							// eslint-disable-next-line react/no-danger
							dangerouslySetInnerHTML={{
								__html: t('enableByUrlDescription', {
									query: `${DARK_MODE_URL_PARAM}=${DARK_MODE_URL_PARAM_VALUE}`,
								}),
							}}
						/>
					}
					theme="dark"
				/>
			</div>

			<div className={css.field}>
				<Field
					name={props.fields.dmByNavCard.name(props.version)}
					component={Toggle}
					theme="dark"
					label={{ children: t('byNavCard'), component: 'label' }}
					onChange={value => {
						// update integration urlParams:
						const urlParamsFields = IntegrationsFacade.getFields(props.version).urlParams;
						const urlParamsFieldName = urlParamsFields.params.name;
						const urlParams = get(props.formValues, urlParamsFieldName, []) as string[];

						if (value) {
							if (urlParams.includes(DARK_MODE_URL_PARAM) === false) {
								urlParams.push(DARK_MODE_URL_PARAM);
							}
						} else {
							const index = urlParams.indexOf(DARK_MODE_URL_PARAM);
							if (index !== -1) urlParams.splice(index, 1);
						}

						dispatch(change(props.formInfo.name, urlParamsFieldName, urlParams));
					}}
					stretch
				/>
				<Hint className={css.hint} text={t('byNavCardDescription')} theme="dark" />
			</div>

			<div className={css.field}>
				<Select
					isMulti
					closeMenuOnSelect={false}
					label={{ children: t('elementSelection'), component: 'label' }}
					options={ELEMENT_OPTIONS}
					theme="dark"
					value={elements}
					eventListeners={{
						onChange: selected => {
							const nextSelected = selected.length
								? selected
								: pickElementOptions([DARK_MODE_ELEMENTS.BACKGROUNDS]);

							setElements(nextSelected as SelectOption[]);

							dispatch(
								change(
									props.formInfo.name,
									props.fields.dmElements.name(props.version),
									nextSelected.map((v: SelectOption) => v.value)
								)
							);
						},
					}}
				/>
				<Hint className={css.hintWithMargin} text={t('elementSelectionDescription')} theme="dark" />
			</div>
		</div>
	);
};

export default DarkMode;
