import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import _ from 'lodash';
import classNames from 'classnames';

import type { StoryVersionType } from 'types/story';
import type { AdminReducerState } from 'admin/reducers';
import t from 'utils/translate';
import { StoryFacade } from 'utils/facades/story-facade';
import { selectCurrentVersion } from 'admin/reducers/version-reducer';
import { getFormattedTimestamp } from 'admin/utils/get-formatted-timestamp';
import { selectEditableStory } from 'admin/reducers/story-editor/selectors';
import Text from 'admin/components/common/Text';
import Button from 'admin/components/common/Button';
import { storyRollback } from 'admin/actions';

import css from './Activity.scss';

// eslint-disable-next-line max-len
const confirmMsg = `Attention: This action will replace your latest version with the one you selected. Original one will be stored as new archived version.`;

const connector = connect(
	(state: AdminReducerState) => ({
		versions: selectEditableStory(state)!.storyVersions,
		currentVersion: selectCurrentVersion(state),
		storyId: selectEditableStory(state)!.id,
	}),
	{ storyRollback }
);

type Props = ConnectedProps<typeof connector>;

class Activity extends Component<Props> {
	getVersionFormattedDate = (version: StoryVersionType) => {
		const timestamp = StoryFacade.getVersionTimestamp(version);
		return getFormattedTimestamp(timestamp);
	};

	getVersionName = (version: StoryVersionType, currentVersion: string) => {
		const v = version.version.split('_')[0];

		if (v === currentVersion) {
			return `${_.capitalize(v)} ${t('story.panel.status.draft')}`;
		}

		if (v.includes('unpublished')) {
			return t('story.settings.unpublished');
		}

		if (v === 'published') {
			return t('story.settings.published');
		}

		return '';
	};

	onRollabackItemClick = async e => {
		const { version } = e.currentTarget.dataset;
		const { storyId = '' } = this.props;

		// eslint-disable-next-line no-alert
		if (window.confirm(confirmMsg)) {
			await this.props.storyRollback(storyId, version);
			window.location.reload();
		}
	};

	renderVersionItem = (version: StoryVersionType) => {
		const { currentVersion } = this.props;
		const badge = this.getVersionName(version, currentVersion);

		return (
			<div className={css.versionItem} key={`version-row-${version.version}`}>
				<div className={css.col}>
					<Text size={Text.size.label} weight={Text.weight.bold}>
						{this.getVersionFormattedDate(version)}
					</Text>
				</div>
				<div className={css.col}>
					{badge && (
						<div className={classNames(css.badge, version.version === 'published' && css.published)}>
							<Text size={Text.size.label}>{badge}</Text>
						</div>
					)}
					{version.version !== 'latest' && (
						<div className={css.buttonWrap}>
							<Button
								type="button"
								smallText
								data-version={version.version}
								size="small"
								onClick={this.onRollabackItemClick}
							>
								{t('story.settings.rollback')}
							</Button>
						</div>
					)}
				</div>
			</div>
		);
	};

	render() {
		const { storyId, versions } = this.props;

		if (!versions || !storyId) {
			return null;
		}

		return (
			<div className={css.activity}>
				<Text
					tag="div"
					size={Text.size.subheading}
					weight={Text.weight.bold}
					className={css.title}
					text={t('story.settings.navigation.activity')}
				/>

				{/* Rows */}
				<div className={css.versions}>
					{StoryFacade.getSortedVersions(versions).map(this.renderVersionItem)}
				</div>
			</div>
		);
	}
}

export default connector(Activity);
