// See see _theme.scss -> $ra-font-size
export const FontSize = {
	hero: 'hero',
	title: 'title',
	heading: 'heading',
	subtitle: 'subtitle',
	subheading: 'subheading',
	article: 'article', // 18px
	paragraph: 'paragraph', // 16px
	body: 'body',
	label: 'label',
	description: 'description',
	footnote: 'footnote',
} as const;

// See see Text.scss -> $font-weight
export const FontWeight = {
	none: 'none',
	lighter: 'lighter',
	normal: 'normal',
	medium: 'medium',
	semibold: 'semibold',
	bold: 'bold',
	bolder: 'bolder',
} as const;

// See see Text.scss -> $font-style
export const FontStyle = {
	none: 'none',
	normal: 'normal',
	italic: 'italic',
} as const;

export const TextTransform = {
	none: 'none',
	normal: 'normal',
	uppercase: 'uppercase',
	lowercase: 'lowercase',
	capitalize: 'capitalize',
} as const;

export const TextColor = {
	black: 'black',
	blackDark: 'black-dark',
	blackMid: 'black-mid',
	blackSoft: 'black-soft',
	blackLight: 'black-light',
	white: 'white',
	whiteDark: 'white-dark',
	whiteMid: 'white-mid',
	whiteSoft: 'white-soft',
	whiteLight: 'white-light',
	brand: 'brand',
	brandDark: 'brand-dark',
	brandLight: 'brand-light',
} as const;
