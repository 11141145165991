import cn from 'classnames';
import React from 'react';

import t from 'utils/translate';
import { CurrentUpload, VIDEO_UPLOAD_URL_TTL } from 'admin/hooks';

import Text from 'admin/components/common/Text';

import css from './IncompleteUploadVideoMessage.scss';

interface Props {
	theme?: 'dark' | 'light';
	className?: string;
	buttonLabel?: string;
	uploadInfo: CurrentUpload;
}

export const IncompleteUploadVideoMessage: React.FC<Props> = ({
	className,
	theme = 'dark',
	buttonLabel = 'Upload',
	uploadInfo,
}) => {
	const totalUrls = uploadInfo.urls.length;
	const uploadedUrls = uploadInfo.uploaded.length;
	const timeLeft = formatTimeLeft(Date.now() - Number(uploadInfo.startTime));

	return (
		<div className={cn(css.prevUploadInfo, css[theme], className)}>
			<Text compact size={Text.size.description} style={{ marginBottom: '10px' }}>
				{t('incompleteUploadVideoMessage.text', { label: buttonLabel, time: timeLeft })}
			</Text>

			<Text compact size={Text.size.description}>
				{t('incompleteUploadVideoMessage.fileName', { name: uploadInfo.fileName })}
			</Text>

			<Text compact size={Text.size.description}>
				{t('incompleteUploadVideoMessage.fileSize', { size: formatBytes(uploadInfo.fileSize) })}
			</Text>

			<Text compact size={Text.size.description}>
				{t('incompleteUploadVideoMessage.uploadProgress', {
					progress: `${Math.round((uploadedUrls / totalUrls) * 100)}%`,
				})}
			</Text>
		</div>
	);
};

function formatTimeLeft(milliseconds: number) {
	const hours = Math.floor((VIDEO_UPLOAD_URL_TTL - milliseconds) / 1000 / 60 / 60);

	if (hours === 0) {
		return t('incompleteUploadVideoMessage.lessThanHour');
	}

	return hours === 1
		? `${hours} ${t('incompleteUploadVideoMessage.hour')}`
		: `${hours} ${t('incompleteUploadVideoMessage.hours')}`;
}

function formatBytes(bytes: number, decimals = 2) {
	if (bytes === 0) return '0 Bytes';

	const k = 1000;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

	// Calculate the index for the correct unit (KB, MB, GB, etc.)
	const i = Math.floor(Math.log(bytes) / Math.log(k));

	// Return the value formatted to the specified number of decimals
	return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}
