import type { Action } from 'redux';
import type { AdminReducerState } from 'admin/reducers';
import { RESET_EDITABLE_CARD } from 'admin/constants/actions';

export type ResetEditableCardPayload = {
	preserve: (keyof AdminReducerState['cardEditorExtra'])[];
	cancelDebouncedSync?: boolean;
};

export function isResetEditableCardAction(action: Action): action is ReturnType<typeof resetEditableCard> {
	return action.type === RESET_EDITABLE_CARD;
}

/**
 * Reset redux.cardEditor to it's initial state
 */
export function resetEditableCard({ preserve = [], cancelDebouncedSync }: ResetEditableCardPayload) {
	return { type: RESET_EDITABLE_CARD, payload: { preserve, cancelDebouncedSync } };
}
