import React, { useEffect, useMemo, useState } from 'react';
import { change, getFormSubmitErrors } from 'redux-form';
import { get } from 'lodash';
import { useAdminSelector, useAdminDispatch } from 'admin/reducers';
import { FORM_MODEL } from 'admin/constants/common';
import { Label } from 'admin/components/common/Form';
import { Column } from 'admin/components/common/Grid';
import { Select } from 'admin/components/common/Form/Select';
import { useTheme } from 'admin/components/common/Theme';
import { getStorycardsDomain, StorycardsDomainType } from 'admin/actions/story/storcards-domain';
import { fields } from '../constants';

const selectSubmitErrors = getFormSubmitErrors(FORM_MODEL.EDIT_STORY);

const StorycardsDomain: React.FC<{ storyId: string; domain?: string }> = ({ storyId, domain }) => {
	const dispatch = useAdminDispatch();
	const { theme } = useTheme();
	const submitErrors = useAdminSelector(selectSubmitErrors);
	const [domainList, setDomainList] = useState<StorycardsDomainType[]>([]);
	const error = get(submitErrors, `${fields.storycardsDomain.name()}.domain`);

	const options = useMemo(() => {
		return domainList.map(item => ({ value: item.domain, label: `${item.domain} (${item.provider})` }));
	}, [domainList]);

	useEffect(() => {
		if (!domainList.length) {
			dispatch(getStorycardsDomain())
				.then(r => (r.success && r.result?.length ? setDomainList(r.result) : undefined))
				.catch(console.error);
		}
	}, [storyId, dispatch, domainList]);

	return domainList.length ? (
		<Column direction="column">
			<Label theme={theme}>Storycards Environment</Label>
			<Select
				theme={theme}
				options={options}
				value={domain}
				error={error}
				eventListeners={{
					onChange: value => {
						const storycardsDomain = domainList.find(item => item.domain === value);
						if (storycardsDomain)
							dispatch(change(FORM_MODEL.EDIT_STORY, fields.storycardsDomain.name(), storycardsDomain));
					},
				}}
			/>
		</Column>
	) : null;
};

export default StorycardsDomain;
