import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import set from 'lodash/set';

import t from 'utils/translate';
import { TextField } from 'admin/components/common/Form';
import { FORM_MODEL } from 'admin/constants/common';

import css from './TwoFAConfirmationForm.scss';

export type FormValues = {
	code: string;
	password: string;
};
type Props = {
	id: string;
	onSubmit: (values: FormValues) => void;
	onStateChange: (submitting: boolean, dirty: boolean) => void;
} & InjectedFormProps<FormValues>;

const TwoFAConfirmationForm: React.FC<Props> = props => {
	const { id, submitting, dirty, onStateChange, onSubmit, handleSubmit } = props;
	const formId = id;

	React.useEffect(() => {
		onStateChange(submitting, dirty);
	}, [submitting, dirty, onStateChange]);

	return (
		<form id={formId} className={css.form} onSubmit={handleSubmit(onSubmit)}>
			<div className={css.fields}>
				<Field
					name="code"
					component={TextField}
					autoComplete="one-time-code"
					autoFocus
					isLabelUppercase={false}
					type="text"
					label={t('profile.2fa.confirmationForm.code')}
					isRequired
				/>
				<Field
					name="password"
					component={TextField}
					isLabelUppercase={false}
					type="password"
					label={t('profile.2fa.confirmationForm.password')}
					isRequired
				/>
			</div>
		</form>
	);
};

export default reduxForm({
	validate: (values: FormValues, props: any) => {
		const errors: Partial<FormValues> = {};

		if (!values.password) {
			set(errors, 'password', t('profile.2fa.errors.required'));
		}

		if (!values.code) {
			set(errors, 'code', t('profile.2fa.errors.required'));
		}

		return errors;
	},
	form: FORM_MODEL.TWO_FA_REMOVAL,
})(TwoFAConfirmationForm);
