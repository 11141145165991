import React from 'react';
import { setModal } from 'admin/actions';
import { MODAL } from 'admin/constants/common';
import { useAdminDispatch } from 'admin/reducers';
import { Grid, Column } from 'admin/components/common/Grid';
import { Label } from 'admin/components/pages/Story/CardEditor/Inspector/PropField/Label';
import { Button } from 'admin/components/pages/Story/CardEditor/Inspector/PropField/Button';
import type { InspectorContextType } from 'admin/components/pages/Story/CardEditor/Inspector/InspectorContext';
import type { EditSelectOptionsModalData } from './types';

type Props = {
	onUpdate: InspectorContextType['onUpdate'];
};

interface ShowSelectOptionsModal extends Props, Pick<EditSelectOptionsModalData, 'generatedOptions'> {
	dispatch: ReturnType<typeof useAdminDispatch>;
}

export const showSelectOptionsModal = (props: ShowSelectOptionsModal) =>
	props.dispatch(
		setModal({
			id: MODAL.EDIT_SELECT_OPTIONS,
			data: {
				onUpdate: ({ options, order }) =>
					props.onUpdate({
						value: [options.value, order.value],
						name: [options.name, order.name],
					}),
				// options generate with AI
				generatedOptions: props.generatedOptions,
			},
		})
	);

const EditSelectOptions: React.FC<Props> = props => {
	const dispatch = useAdminDispatch();

	return (
		<Grid columns="2" gap={Grid.gap.medium}>
			<Column alignItems="center">
				<Label style={{ whiteSpace: 'nowrap' }}>Choices</Label>
			</Column>
			<Column alignItems="center" justifyContent="flex-end">
				<Button
					onClick={() =>
						showSelectOptionsModal({
							onUpdate: props.onUpdate,
							dispatch,
						})
					}
				>
					Edit
				</Button>
			</Column>
		</Grid>
	);
};

export default EditSelectOptions;
