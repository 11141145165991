import { IUserOrganization } from 'types/user';
import { AsyncThunkActionReturnType } from 'types/redux-utils';
import { OrganizationDomainResponseResult } from 'types/organization';
import { getOrganizationDomainStatus as getOrganizationDomainStatusEndpoint } from 'admin/resources';
import { GET_ORGANIZATION_DOMAIN_STATUS } from 'admin/constants/actions';
import { createThunkAction } from 'admin/actions/helpers';

interface Params {
	organizationId: string;
	domainId: IUserOrganization['domainId'];
}

export type GetOrganizationDomainStatusAction =
	| {
			type: typeof GET_ORGANIZATION_DOMAIN_STATUS.PENDING;
			payload: undefined;
	  }
	| {
			type: typeof GET_ORGANIZATION_DOMAIN_STATUS.FULFILLED;
			payload: WithRequired<AsyncThunkActionReturnType<typeof getOrganizationDomainStatus>, 'result'>;
	  }
	| {
			type: typeof GET_ORGANIZATION_DOMAIN_STATUS.REJECTED;
			payload: string;
	  };

export const getOrganizationDomainStatus = createThunkAction<OrganizationDomainResponseResult, Params>({
	type: GET_ORGANIZATION_DOMAIN_STATUS,
	payloadCreator: async ({ organizationId, domainId }) => {
		const response = await getOrganizationDomainStatusEndpoint.params({ organizationId, domainId }).send({});
		return response.body;
	},
});
