import { SET_EDITOR_SIZE } from 'admin/constants/actions';
import type { StoryMediaPlatform } from 'types/story';

export type SetEditorSizePayload = Partial<{
	width: number;
	height: number;
	min: number;
	max: number;
	platform: StoryMediaPlatform;
}>;

export function setEditorSize(size: SetEditorSizePayload) {
	return { type: SET_EDITOR_SIZE, payload: size };
}
