import { kebabCase } from 'lodash';
import { COMPONENT_TYPE, IMG_TINT_ATTR } from 'common/constants';
import { CSS_PROPS } from 'common/constants/component-props';
import type { BBUiConfig, ComponentTypes } from 'types/story';
import { FIELD_ERROR_DATA_ATTR } from 'client/components/common/BuildingBlocks/Fields/constants';
import { getTimerColorProperty, timerDataSelector } from 'client/components/common/BuildingBlocks/Timer/Timer';
import { AnswerProgressDataSelector } from 'client/components/common/BuildingBlocks/AnswerProgress/AnswerProgress';
import TextCSS from 'client/components/common/BuildingBlocks/Text/Text.scss';
import SelectCSS from 'client/components/common/BuildingBlocks/Fields/Select/Select.scss';
import {
	COUNTER_DIVIDER_DATA_ATTR,
	COUNTER_LABEL_DATA_ATTR,
	COUNTER_UNIT_DATA_ATTR,
} from 'src/routes/client/components/common/BuildingBlocks/Counter/constants';

type SelectorPostfix = () => string;
type SelectorPrefix = (params?: { cardId?: string }) => string;
type CustomProperties = Record<
	string,
	{
		selectorPostfix?: SelectorPostfix;
		selectorPrefix?: SelectorPrefix;
		cssProperty: string;
	}
>;

const fieldPaddingPropertyMap = {
	[CSS_PROPS.field.style.padding.left]: 'padding-left',
	[CSS_PROPS.field.style.padding.right]: 'padding-right',
	[CSS_PROPS.field.style.padding.top]: 'padding-top',
	[CSS_PROPS.field.style.padding.bottom]: 'padding-bottom',
};

/**
 * Regular CSS properties are applied to original building block node.
 * But there are douses of cases when you may need to apply/read some property to some node that is inside original one,
 * next to it or to original one which is only at some specific css path.
 *
 * This is a list of properties that behave like regular CSS properties, but are handled in a custom way.
 * This may be a non-valid property that will be transformed into a valid one for a certain node,
 * or it may be a valid property that will be applied to the modified selector.
 */
export function getCustomCssProp(
	type: ComponentTypes,
	prop: string,
	extra?: { uiConfig?: BBUiConfig }
): CustomProperties[string] | undefined {
	const common: CustomProperties = {
		[CSS_PROPS.text.lineClamp]: {
			selectorPostfix: () => `.${TextCSS.truncateContainer}`,
			cssProperty: '-webkit-line-clamp',
		},
		[CSS_PROPS.text.lineClampColor]: {
			selectorPostfix: () => `.${TextCSS.truncateBtn}`,
			cssProperty: 'color',
		},
		[CSS_PROPS.effects.pointerEvents]: {
			selectorPrefix: () => 'html:not([data-editor="true"])',
			cssProperty: 'pointer-events',
		},
		[CSS_PROPS.shape.lineThickness]: {
			selectorPostfix: () => '[data-shape="line"]',
			cssProperty: 'height',
		},
		[CSS_PROPS.custom.__globalBlockDisplay]: {
			selectorPrefix: params => `[data-card-id="${params?.cardId}"]`,
			cssProperty: 'display',
		},
		[CSS_PROPS.field.errorStyle.text.color]: {
			selectorPostfix: () => `[${FIELD_ERROR_DATA_ATTR}]`,
			cssProperty: 'color',
		},
		[CSS_PROPS.field.errorStyle.text.fontFamily]: {
			selectorPostfix: () => `[${FIELD_ERROR_DATA_ATTR}]`,
			cssProperty: 'font-family',
		},
		[CSS_PROPS.field.errorStyle.text.fontWeight]: {
			selectorPostfix: () => `[${FIELD_ERROR_DATA_ATTR}]`,
			cssProperty: 'font-weight',
		},
		[CSS_PROPS.field.errorStyle.text.fontSize]: {
			selectorPostfix: () => `[${FIELD_ERROR_DATA_ATTR}]`,
			cssProperty: 'font-size',
		},
		[CSS_PROPS.field.errorStyle.text.lineHeight]: {
			selectorPostfix: () => `[${FIELD_ERROR_DATA_ATTR}]`,
			cssProperty: 'line-height',
		},
		[CSS_PROPS.field.errorStyle.text.textAlign]: {
			selectorPostfix: () => `[${FIELD_ERROR_DATA_ATTR}]`,
			cssProperty: 'text-align',
		},
		[CSS_PROPS.field.errorStyle.text.direction]: {
			selectorPostfix: () => `[${FIELD_ERROR_DATA_ATTR}]`,
			cssProperty: 'direction',
		},
		[CSS_PROPS.field.errorStyle.padding.left]: {
			selectorPostfix: () => `[${FIELD_ERROR_DATA_ATTR}]`,
			cssProperty: 'padding-left',
		},
		[CSS_PROPS.field.errorStyle.padding.right]: {
			selectorPostfix: () => `[${FIELD_ERROR_DATA_ATTR}]`,
			cssProperty: 'padding-right',
		},
		[CSS_PROPS.field.errorStyle.padding.top]: {
			selectorPostfix: () => `[${FIELD_ERROR_DATA_ATTR}]`,
			cssProperty: 'padding-top',
		},
		[CSS_PROPS.field.errorStyle.padding.bottom]: {
			selectorPostfix: () => `[${FIELD_ERROR_DATA_ATTR}]`,
			cssProperty: 'padding-bottom',
		},
		[CSS_PROPS.field.style.text.phColor]: {
			selectorPostfix: () => `::placeholder`,
			cssProperty: 'color',
		},
		[CSS_PROPS.image.tint]: {
			selectorPostfix: () => `[${IMG_TINT_ATTR}]`,
			cssProperty: 'background',
		},
		[CSS_PROPS.field.style.box.fill]: {
			selectorPostfix: () => '[data-checkbox="box"]',
			cssProperty: 'background-color',
		},
		[CSS_PROPS.field.style.box.stroke]: {
			selectorPostfix: () => '[data-checkbox="box"]',
			cssProperty: 'border-color',
		},
		[CSS_PROPS.field.style.box.iconColor]: {
			selectorPostfix: () => '[data-checkbox="box"]::after',
			cssProperty: 'border-color',
		},
		[CSS_PROPS.counter.unit.padding.top]: {
			selectorPostfix: () => `[${COUNTER_UNIT_DATA_ATTR}]`,
			cssProperty: 'padding-top',
		},
		[CSS_PROPS.counter.unit.padding.right]: {
			selectorPostfix: () => `[${COUNTER_UNIT_DATA_ATTR}]`,
			cssProperty: 'padding-right',
		},
		[CSS_PROPS.counter.unit.padding.bottom]: {
			selectorPostfix: () => `[${COUNTER_UNIT_DATA_ATTR}]`,
			cssProperty: 'padding-bottom',
		},
		[CSS_PROPS.counter.unit.padding.left]: {
			selectorPostfix: () => `[${COUNTER_UNIT_DATA_ATTR}]`,
			cssProperty: 'padding-left',
		},
		[CSS_PROPS.counter.unit.background.backgroundColor]: {
			selectorPostfix: () => `[${COUNTER_UNIT_DATA_ATTR}]`,
			cssProperty: 'background',
		},
		[CSS_PROPS.counter.unit.borders.borderWidth]: {
			selectorPostfix: () => `[${COUNTER_UNIT_DATA_ATTR}]`,
			cssProperty: 'border-width',
		},
		[CSS_PROPS.counter.unit.borders.borderRadius]: {
			selectorPostfix: () => `[${COUNTER_UNIT_DATA_ATTR}]`,
			cssProperty: 'border-radius',
		},
		[CSS_PROPS.counter.unit.borders.borderColor]: {
			selectorPostfix: () => `[${COUNTER_UNIT_DATA_ATTR}]`,
			cssProperty: 'border-color',
		},
		[CSS_PROPS.counter.unit.borders.borderStyle]: {
			selectorPostfix: () => `[${COUNTER_UNIT_DATA_ATTR}]`,
			cssProperty: 'border-style',
		},
		[CSS_PROPS.counter.unit.borders.borderBottomRightRadius]: {
			selectorPostfix: () => `[${COUNTER_UNIT_DATA_ATTR}]`,
			cssProperty: 'border-bottom-right-radius',
		},
		[CSS_PROPS.counter.unit.borders.borderTopRightRadius]: {
			selectorPostfix: () => `[${COUNTER_UNIT_DATA_ATTR}]`,
			cssProperty: 'border-top-right-radius',
		},
		[CSS_PROPS.counter.unit.borders.borderBottomLeftRadius]: {
			selectorPostfix: () => `[${COUNTER_UNIT_DATA_ATTR}]`,
			cssProperty: 'border-bottom-left-radius',
		},
		[CSS_PROPS.counter.unit.borders.borderTopLeftRadius]: {
			selectorPostfix: () => `[${COUNTER_UNIT_DATA_ATTR}]`,
			cssProperty: 'border-top-left-radius',
		},
		[CSS_PROPS.counter.gap]: {
			selectorPostfix: () => `[${COUNTER_DIVIDER_DATA_ATTR}]`,
			cssProperty: 'width',
		},
		[CSS_PROPS.counter.labelStyle.text.color]: {
			selectorPostfix: () => `[${COUNTER_LABEL_DATA_ATTR}]`,
			cssProperty: 'color',
		},
		[CSS_PROPS.counter.labelStyle.text.fontFamily]: {
			selectorPostfix: () => `[${COUNTER_LABEL_DATA_ATTR}]`,
			cssProperty: 'font-family',
		},
		[CSS_PROPS.counter.labelStyle.text.fontWeight]: {
			selectorPostfix: () => `[${COUNTER_LABEL_DATA_ATTR}]`,
			cssProperty: 'font-weight',
		},
		[CSS_PROPS.counter.labelStyle.text.fontSize]: {
			selectorPostfix: () => `[${COUNTER_LABEL_DATA_ATTR}]`,
			cssProperty: 'font-size',
		},
		[CSS_PROPS.counter.labelStyle.text.lineHeight]: {
			selectorPostfix: () => `[${COUNTER_LABEL_DATA_ATTR}]`,
			cssProperty: 'line-height',
		},
		[CSS_PROPS.counter.labelStyle.margin.top]: {
			selectorPostfix: () => `[${COUNTER_LABEL_DATA_ATTR}]`,
			cssProperty: 'margin-top',
		},
	};

	switch (type) {
		case COMPONENT_TYPE.ANSWER_PROGRESS:
			common[CSS_PROPS.custom.trackColor] = {
				selectorPostfix: () => `[data-selector="${AnswerProgressDataSelector.progressTrack}"]`,
				cssProperty: 'background-color',
			};
			common[CSS_PROPS.custom.progressColor] = {
				selectorPostfix: () => `[data-selector="${AnswerProgressDataSelector.progress}"]`,
				cssProperty: 'background-color',
			};
			common[CSS_PROPS.custom.progressDir] = {
				selectorPostfix: () => `[data-selector="${AnswerProgressDataSelector.progress}"]`,
				cssProperty: 'transform-origin',
			};
			break;
		case COMPONENT_TYPE.TIMER:
			common[CSS_PROPS.custom.trackColor] = {
				selectorPostfix: () => `[data-selector="${timerDataSelector.track}"]`,
				cssProperty: getTimerColorProperty(extra?.uiConfig),
			};
			common[CSS_PROPS.custom.progressColor] = {
				selectorPostfix: () => `[data-selector="${timerDataSelector.progress}"]`,
				cssProperty: getTimerColorProperty(extra?.uiConfig),
			};
			common[CSS_PROPS.custom.progressDir] = {
				selectorPostfix: () => `[data-selector="${timerDataSelector.progress}"]`,
				cssProperty: 'transform-origin',
			};
			common[CSS_PROPS.svg.strokeWidth] = {
				cssProperty: 'stroke-width',
			};
			break;
		case COMPONENT_TYPE.TEXT_AREA_FIELD:
		case COMPONENT_TYPE.TEXT_FIELD:
		case COMPONENT_TYPE.SELECT: {
			const cssProperties = [
				...Object.values(CSS_PROPS.borders),
				...Object.values(CSS_PROPS.background),
				...Object.values(CSS_PROPS.field.style.padding),
			];

			cssProperties.forEach(property => {
				common[property] = {
					selectorPostfix: () =>
						type === COMPONENT_TYPE.SELECT
							? `.${SelectCSS.field}`
							: type === COMPONENT_TYPE.TEXT_AREA_FIELD
								? 'textarea'
								: 'input',
					cssProperty: fieldPaddingPropertyMap[property] || kebabCase(property),
				};
			});

			break;
		}
		default:
			break;
	}

	return common[prop];
}
