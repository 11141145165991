import type { SpreadsheetRange } from 'types/story';
import { createThunkAction } from 'admin/actions/helpers';
import { GET_STORY_SPREADSHEET_INTEGRATIONS } from 'admin/constants/actions';
import {
	postGoogleOAuth as postGoogleOAuthEndpoint,
	getIntegrationToken as getIntegrationTokenEndpoint,
	getGoogleIntegrations as getUserIntegrationsEndpoint,
	deleteGoogleIntegration as deleteUserIntegrationEndpoint,
	postGoogleCallback as PostGoogleCallbackEndpoint,
	getSpreadsheet as getSpreadsheetEndpoint,
	getStorySpreadsheetIntegrations as getStorySpreadsheetIntegrationsEndpoint,
	postSpreadsheetIntegration as postSpreadsheetIntegrationEndpoint,
	putSpreadsheetRange as putSpreadsheetRangeEndpoint,
	deleteSpreadsheetRange as deleteSpreadsheetRangeEndpoint,
	deleteSpreadsheetIntegration as deleteSpreadsheetIntegrationEndpoint,
} from 'admin/resources';

type PostGoogleOAuth = {
	res: ApiV2Response<{ url: string }>;
	req: { id?: string };
};

export const postGoogleOAuth = createThunkAction<PostGoogleOAuth['res']['body'], PostGoogleOAuth['req']>({
	payloadCreator: async params => {
		const response: PostGoogleOAuth['res'] = await postGoogleOAuthEndpoint.send(params);
		return { success: true, result: response.body };
	},
});

type GetIntegrationToken = {
	res: ApiV2Response<{ accessToken: string }>;
	req: { id: string };
};

export const getIntegrationToken = createThunkAction<GetIntegrationToken['res']['body'], GetIntegrationToken['req']>({
	payloadCreator: async params => {
		const response: GetIntegrationToken['res'] = await getIntegrationTokenEndpoint.params(params).send();
		return { success: true, result: response.body };
	},
});

export type UserIntegrationItem = {
	id: string;
	provider: 'google';
	createdAt: string;
	status: string;
	label: string;
};

type GetUserIntegrations = {
	res: ApiV2Response<UserIntegrationItem[]>;
};

export const getUserIntegrations = createThunkAction<GetUserIntegrations['res']['body']>({
	payloadCreator: async () => {
		const response: GetUserIntegrations['res'] = await getUserIntegrationsEndpoint.send();
		return { success: true, result: response.body };
	},
});

export const deleteUserIntegration = createThunkAction<GetUserIntegrations['res']['body'], { id: string }>({
	payloadCreator: async params => {
		const response: GetUserIntegrations['res'] = await deleteUserIntegrationEndpoint.params(params).send();
		return { success: true, result: response.body };
	},
});

export const postGoogleCallback = createThunkAction<null, { code: string; state: string }>({
	payloadCreator: async params => {
		await PostGoogleCallbackEndpoint.send(params);
		return { success: true };
	},
});

type SheetItem = {
	properties: {
		sheetId: number;
		title: string;
		index: number;
	};
};

export type SpreadsheetDocument = {
	spreadsheetId: string;
	properties: { title: string };
	sheets: Array<SheetItem>;
};

type GetSpreadsheet = {
	res: ApiV2Response<{
		file: object;
		spreadsheet: SpreadsheetDocument;
	}>;
	req: { integrationId: string; fileId: string };
};

export const getSpreadsheet = createThunkAction<GetSpreadsheet['res']['body'], GetSpreadsheet['req']>({
	payloadCreator: async params => {
		const response: GetSpreadsheet['res'] = await getSpreadsheetEndpoint
			.params({ id: params.integrationId, fileId: params.fileId })
			.send();
		return { success: true, result: response.body };
	},
});

export type StorySpreadsheetIntegration = {
	id: string;
	title: Nullable<string>;
	oauthIntegrationId: string;
	oauth: Pick<UserIntegrationItem, 'id' | 'label' | 'status'>;
	storyId: string;
	spreadsheetId: string;
	spreadsheetUrl: Nullable<string>;
	fileId: number;
	createdAt: string;
	updatedAt: string;
	isOutdated: boolean;
	lastWebhookAt: Nullable<string>;
	webhookExpiresAt: Nullable<string>;
	webhookId: Nullable<string>;
	ranges: SpreadsheetRange[];
	type: 'database' | 'export_playthroughs';
	file: {
		id: number;
		hosting: string;
		filepath: string;
		mimetype: string;
		isDeleted: boolean;
		createdAt: string;
		updatedAt: string;
	};
};

type GetStorySpreadsheetIntegrations = {
	req: { storyId: string };
	res: ApiV2Response<StorySpreadsheetIntegration[]>;
};

export const getStorySpreadsheetIntegrations = createThunkAction<
	GetStorySpreadsheetIntegrations['res']['body'],
	GetStorySpreadsheetIntegrations['req']
>({
	type: GET_STORY_SPREADSHEET_INTEGRATIONS,
	payloadCreator: async params => {
		const response: GetStorySpreadsheetIntegrations['res'] = await getStorySpreadsheetIntegrationsEndpoint
			.params(params)
			.send();
		return { success: true, result: response.body };
	},
});

type CreateSpreadsheetIntegration = {
	res: ApiV2Response<{ id: string }>;
	req: {
		integrationId: string;
		data: {
			storyId: string;
			spreadsheet: {
				id: string;
				type: StorySpreadsheetIntegration['type'];
				ranges: { sheetId: number; sheetName: string; label: string; range: string }[];
			};
		};
	};
};

export const createSpreadsheetIntegration = createThunkAction<
	CreateSpreadsheetIntegration['res']['body'],
	CreateSpreadsheetIntegration['req']
>({
	payloadCreator: async params => {
		const response: CreateSpreadsheetIntegration['res'] = await postSpreadsheetIntegrationEndpoint
			.params({ id: params.integrationId })
			.send(params.data);
		return { success: true, result: response.body };
	},
});

type PutSpreadsheetRange = {
	res: ApiV2Response<unknown>;
	req: {
		integrationId: string;
		spreadsheetId: string;
		data: { range: { id?: number; sheetId: number; sheetName: string; label: string; range: string } };
	};
};

export const putSpreadsheetRange = createThunkAction<PutSpreadsheetRange['res']['body'], PutSpreadsheetRange['req']>({
	payloadCreator: async params => {
		const response: PutSpreadsheetRange['res'] = await putSpreadsheetRangeEndpoint
			.params({ id: params.integrationId, spreadsheetId: params.spreadsheetId })
			.send(params.data);
		return { success: true, result: response.body };
	},
});

type DeleteSpreadsheetRange = {
	res: ApiV2Response<unknown>;
	req: {
		integrationId: string;
		spreadsheetId: string;
		rangeId: string;
	};
};

export const deleteSpreadsheetRange = createThunkAction<
	DeleteSpreadsheetRange['res']['body'],
	DeleteSpreadsheetRange['req']
>({
	payloadCreator: async params => {
		const response: DeleteSpreadsheetRange['res'] = await deleteSpreadsheetRangeEndpoint
			.params({ id: params.integrationId, spreadsheetId: params.spreadsheetId, rangeId: params.rangeId })
			.send();
		return { success: true, result: response.body };
	},
});

type DeleteSpreadsheetIntegration = {
	res: ApiV2Response<unknown>;
	req: { integrationId: string; spreadsheetId: string };
};

export const deleteSpreadsheetIntegration = createThunkAction<
	DeleteSpreadsheetIntegration['res']['body'],
	DeleteSpreadsheetIntegration['req']
>({
	payloadCreator: async params => {
		const response: DeleteSpreadsheetIntegration['res'] = await deleteSpreadsheetIntegrationEndpoint
			.params({ id: params.integrationId, spreadsheetId: params.spreadsheetId })
			.send();
		return { success: true, result: response.body };
	},
});
