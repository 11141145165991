import { createThunkAction } from 'admin/actions/helpers';
import { getWidgetViews as getWidgetViewsEndpoint } from 'admin/resources';

interface Params {
	organizationId: string;
}

export interface OrganizationWidgetViewsResult {
	views: number;
}

export const getWidgetViews = createThunkAction<OrganizationWidgetViewsResult, Params>({
	payloadCreator: async ({ organizationId }) => {
		const response = await getWidgetViewsEndpoint.params({ organizationId }).send({});
		return { success: true, result: response.body };
	},
});
