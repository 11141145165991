import React, { useId } from 'react';
import GlobalStyles from 'common/components/GlobalStyles';
import { hexToRgb } from 'admin/utils/color-helpers';

type GetStyleProps = {
	id: string;
	fillHexColor?: string;
	shadowHexColor?: string;
	maxHeight?: string;
};

function getStyle({ id, fillHexColor = '#ffffff', shadowHexColor = '#000000', maxHeight = '100px' }: GetStyleProps) {
	const { r: cR, g: cG, b: cB } = hexToRgb(fillHexColor.replace(/^#/, ''));
	const { r: sR, g: sG, b: sB } = hexToRgb(shadowHexColor.replace(/^#/, ''));

	return `:where(#${id}){
		max-height: ${maxHeight};
		overflow: auto;
		background:
	        linear-gradient(${fillHexColor} 30%, rgba(${cR}, ${cG}, ${cB}, 0)),
		    linear-gradient(rgba(${cR}, ${cG}, ${cB}, 0), ${fillHexColor} 70%) 0 100%,
		    radial-gradient(farthest-side at 50% 0, rgba(${sR}, ${sG}, ${sB}, 0.2), rgba(${sR}, ${sG}, ${sB}, 0)),
		    radial-gradient(
		        farthest-side at 50% 100%,
		        rgba(${sR}, ${sG}, ${sB}, 0.2),
	            rgba(${sR}, ${sG}, ${sB}, 0)) 0 100%;
		background-repeat: no-repeat;
		background-color: ${fillHexColor};
		background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
		background-attachment: local, local, scroll, scroll;
	}`;
}

interface Props extends Pick<GetStyleProps, 'fillHexColor' | 'shadowHexColor' | 'maxHeight'> {
	children: React.ReactNode;
	className?: string;
}

const ScrollBox: React.FC<Props> = ({ className, children, ...props }) => {
	const id = useId();
	const nodeId = `scroll-box-${id.replace(/[^a-zA-Z0-9-]/g, '-')}`;

	return (
		<section id={nodeId} className={className}>
			<GlobalStyles styles={getStyle({ id: nodeId, ...props })} />
			{children}
		</section>
	);
};

export default ScrollBox;
