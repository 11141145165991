import type {
	AddMembersToOrganizationTeamsParams,
	OrganizationBillingInfo,
	OrganizationInvoiceInfo,
	GoogleFontItem,
	SCFontItem,
	IOrganizationIntegrations,
	IOrganizationMember,
	IActiveMember,
	IUserOrganization,
} from 'src/types';
import urlHelper from 'utils/url-helper';
import { getAssetURL } from 'utils/assets';
import { ADMIN_SESSION, PARAMS } from 'common/constants';
import AdminError from 'admin/components/common/ErrorBoundary/AdminError';
import { uploadFileHelper, createThunkAction } from 'admin/actions/helpers';
import { selectActiveOrganizationId, selectActiveOrganization } from 'admin/reducers/user/selectors';
import * as actionType from 'admin/constants/actions';
import * as endpoint from 'admin/resources';

export type GetOrganizationListResult = Array<{
	id: string;
	name: string;
	organizationMembers?: {
		status: 'pending' | 'active';
	};
}>;

export const getOrganizationList = createThunkAction<GetOrganizationListResult, void>({
	type: actionType.GET_ORGANIZATION_LIST,
	payloadCreator: async () => {
		const response = await endpoint.getOrganizationList.send();
		return { success: true, result: response.body };
	},
});

export type GetOrganization = {
	req: { organizationId: string };
	res: IUserOrganization;
};

export const getOrganization = createThunkAction<GetOrganization['res'], GetOrganization['req']>({
	type: actionType.GET_ORGANIZATION,
	payloadCreator: async (params, { getState }) => {
		const organizationId = params.organizationId ?? selectActiveOrganizationId(getState());
		const response = await endpoint.getOrganization.params({ organizationId }).send();
		const teams = (response.body.teams as Record<string, IUserOrganization['teams'][number]>) ?? {};
		return { success: true, result: { ...response.body, teams: Object.values(teams) } };
	},
});

/**
 * get google fonts - make it without reducers because we do not store them in redux
 * (just fetch the data and pass it to the component)
 *
 * Vadim did this endpoint only for one purpose - store google api_key on the backend
 * for max security
 *
 */
export const getOrganizationGoogleFonts = createThunkAction<{ items: GoogleFontItem[]; kind: string }>({
	payloadCreator: async (args, { getState }) => {
		const organizationId = selectActiveOrganizationId(getState());
		const response = await endpoint.getOrganizationGoogleFonts.params({ organizationId }).send({});
		return response.body;
	},
});

interface CreateOrganizationData {
	planId: number;
	name: string;
	paymentMethod: {
		firstName: string;
		lastName: string;
		cardNumber: number;
		securityCode: number;
		expirationMonth: string;
		expirationYear: number;
	};
	billing: {
		company: string;
		address1: string;
		country: string;
		city: string;
		zip: string;
	};
}

export const createOrganization = createThunkAction<{ organizationId: string }, CreateOrganizationData>({
	payloadCreator: async data => {
		const response = await endpoint.createOrganization.send(data);
		return response.body;
	},
});

export type GetOrganizationTeamsOfMemberResult = IActiveMember;

export const getOrganizationTeamsOfMember = createThunkAction<
	GetOrganizationTeamsOfMemberResult,
	{ organizationId: string; memberId: string }
>({
	type: actionType.GET_ORGANIZATION_TEAMS_OF_MEMBER,
	payloadCreator: async params => {
		const response = await endpoint.getOrganizationTeamsOfMember.params(params).send({});
		return response.body;
	},
});

export const acceptInviteToOrganization = createThunkAction<null, { organizationId: string; memberId: string }>({
	type: actionType.ACCEPT_INVITE_TO_ORGANIZATION,
	payloadCreator: async params => {
		const response = await endpoint.acceptInviteToOrganization.params(params).send();
		return response.body;
	},
});

interface SetupInviteToOrganizationParams {
	organizationId: string;
	data: { verificationToken: string; name: string; password: string };
}
export const setupInviteToOrganization = createThunkAction<null, SetupInviteToOrganizationParams>({
	type: actionType.SETUP_INVITE_TO_ORGANIZATION,
	payloadCreator: async ({ organizationId, data }) => {
		const response = await endpoint.setupInviteToOrganization.params({ organizationId }).send(data);
		return response.body;
	},
});

export const resendInviteToOrganization = createThunkAction<null, { memberId: string }>({
	type: actionType.RESEND_INVITE_TO_ORGANIZATION,
	payloadCreator: async ({ memberId }, { getState }) => {
		const organizationId = selectActiveOrganizationId(getState());
		const response = await endpoint.resendInviteToOrganization.params({ organizationId, memberId }).send();
		return response.body;
	},
});

type GetOrganizationMembersPayload = Partial<{ organizationId: string; nameOrEmail: string }>;

export type GetOrganizationMembersResult = IOrganizationMember[];

export const getOrganizationMembers = createThunkAction<GetOrganizationMembersResult, GetOrganizationMembersPayload>({
	type: actionType.GET_ORGANIZATION_MEMBERS,
	payloadCreator: async (params, { getState }) => {
		const organizationId = params.organizationId || selectActiveOrganizationId(getState());
		const query = !params.nameOrEmail ? {} : { nameOrEmail: params.nameOrEmail };
		const response = await endpoint.getOrganizationMembers.params({ organizationId }).send(query);
		return response.body;
	},
});

export const addMembersToOrganizationTeams = createThunkAction<void, AddMembersToOrganizationTeamsParams>({
	type: actionType.ADD_MEMBERS_TO_ORGANIZATION_TEAMS,
	payloadCreator: async payload => {
		const response = await endpoint.addMembersToOrganizationTeams
			.params({ organizationId: payload.organizationId })
			.send(payload.data);
		return response.body;
	},
});

export const validateOrganizationName = createThunkAction<null, { organizationId: string }>({
	type: actionType.VALIDATE_ORGANIZATION_NAME,
	payloadCreator: async ({ organizationId }) => {
		const response = await endpoint.validateOrganizationName.params({ organizationId }).send();
		return response.body;
	},
});

type UpdateOrgSettingsProps = {
	data: {
		name: string;
		customId?: string;
		isTOTPRequired?: boolean;
		metadata?: {
			storyLang?: string;
			integrations?: IOrganizationIntegrations;
			fonts?: Record<string, SCFontItem>;
		};
	};
};

export type UpdateOrganizationSettingsResult = { organizationId: string; data: UpdateOrgSettingsProps['data'] };

export const updateOrganizationSettings = createThunkAction<UpdateOrganizationSettingsResult, UpdateOrgSettingsProps>({
	type: actionType.UPDATE_ORGANIZATION_SETTINGS,
	payloadCreator: async (props, { getState }) => {
		const org = selectActiveOrganization(getState());

		if (!org) {
			throw new AdminError('Organization is not found.', { duration: 3 });
		}

		const { id: organizationId, customId } = org;
		const data: UpdateOrgSettingsProps['data'] = {
			customId,
			...props.data,
			metadata: {
				...org.metadata,
				...props.data.metadata,
				fonts: props.data.metadata?.fonts || org.metadata?.fonts,
				integrations: props.data.metadata?.integrations || org.metadata?.integrations,
			},
		};
		const payload = { organizationId, data };
		await endpoint.updateOrganizationSettings.params({ organizationId }).send(data);
		return { success: true, errors: undefined, result: payload };
	},
});

export const postOrganizationGallery = createThunkAction<{ asset: string }, { asset: File; organizationId: string }>({
	payloadCreator: async ({ asset, organizationId }) => {
		const targetUrl = `${urlHelper.adminBackend}${endpoint.postOrgGallery.url.replace(
			`:${PARAMS.ORGANIZATION_ID}`,
			organizationId
		)}`;
		const sessionId = localStorage.getItem(ADMIN_SESSION) || '';
		const response: IBackendBody<{ filepath: string; hosting: string }> = await uploadFileHelper({
			asset,
			apiUrl: targetUrl,
			sessionId,
		});
		return { ...response, success: true, result: { asset: getAssetURL(response.result) } };
	},
});

export const getOrganizationAdminBillingInfo = createThunkAction<OrganizationBillingInfo | null>({
	type: actionType.GET_ORGANIZATION_ADMIN_BILLING_INFO,
	payloadCreator: async (params, { getState }) => {
		const organizationId = selectActiveOrganizationId(getState());
		const response = await endpoint.getOrganizationAdminBilling.params({ organizationId }).send();
		return response.body;
	},
});

export const getOrganizationAdminInvoicesInfo = createThunkAction<OrganizationInvoiceInfo[] | null>({
	type: actionType.GET_ORGANIZATION_ADMIN_INVOICES_INFO,
	payloadCreator: async (params, { getState }) => {
		const organizationId = selectActiveOrganizationId(getState());
		const response = await endpoint.getOrganizationAdminInvoices.params({ organizationId }).send();
		return response.body;
	},
});

interface UpdateOrganizationBillingParams {
	data: {
		billing: {
			firstName: string;
			lastName: string;
			company: string;
			address1: string;
			country: string;
			city: string;
			zip: string;
		};
	};
}

export const updateOrganizationBilling = createThunkAction<string, UpdateOrganizationBillingParams>({
	type: actionType.UPDATE_ORGANIZATION_BILLING,
	payloadCreator: async ({ data }, { getState }) => {
		const organizationId = selectActiveOrganizationId(getState());
		const response = await endpoint.updateOrganizationBilling.params({ organizationId }).send(data);
		return response.body;
	},
});

interface UpdateOrganizationPaymentParams {
	data: {
		paymentMethod: {
			firstName: string;
			lastName: string;
			cardNumber: number;
			securityCode: number;
			expirationMonth: string;
			expirationYear: number;
		};
	};
}

export const updateOrganizationPayment = createThunkAction<string, UpdateOrganizationPaymentParams>({
	type: actionType.UPDATE_ORGANIZATION_PAYMENT,
	payloadCreator: async ({ data }, { getState }) => {
		const organizationId = selectActiveOrganizationId(getState());
		const response = await endpoint.updateOrganizationPayment.params({ organizationId }).send(data);
		return response.body;
	},
});

type UpdateOrganizationMemberParams = {
	memberId: string;
	data: { isManager: boolean; status: string };
};

export type UpdateOrganizationMemberResult = UpdateOrganizationMemberParams;

export const updateOrganizationMember = createThunkAction<
	UpdateOrganizationMemberResult,
	UpdateOrganizationMemberParams
>({
	type: actionType.UPDATE_ORGANIZATION_MEMBER,
	payloadCreator: async ({ memberId, data }, { getState }) => {
		const organizationId = selectActiveOrganizationId(getState());
		await endpoint.updateOrganizationMember.params({ organizationId, memberId }).send(data);
		return { success: true, result: { memberId, data } };
	},
});

export type RemoveMemberFromOrganizationResult = { memberId: string };

export const removeMemberFromOrganization = createThunkAction<RemoveMemberFromOrganizationResult, { memberId: string }>(
	{
		type: actionType.REMOVE_MEMBER_FROM_ORGANIZATION,
		payloadCreator: async ({ memberId }, { getState }) => {
			const organizationId = selectActiveOrganizationId(getState());
			await endpoint.removeMemberFromOrganization.params({ organizationId, memberId }).send();
			return { success: true, result: { memberId } };
		},
	}
);

export type CancelSubscriptionResult = { organizationId: string };

export const cancelSubscription = createThunkAction<CancelSubscriptionResult>({
	type: actionType.CANCEL_SUBSCRIPTION,
	payloadCreator: async (params, { getState }) => {
		const organizationId = selectActiveOrganizationId(getState());
		const result: { body: IBackendBody<{ organizationId: string }> } = await endpoint.cancelSubscription
			.params({ organizationId })
			.send();
		return result.body;
	},
});

export const upgradeSubscription = createThunkAction<{ organizationId: string }, { planId: number }>({
	type: actionType.UPDATE_SUBSCRIPTION,
	payloadCreator: async ({ planId }, { getState }) => {
		const organizationId = selectActiveOrganizationId(getState());
		const response: { body: IBackendBody<{ organizationId: string }> } = await endpoint.upgradeSubscription
			.params({ organizationId })
			.send({ planId });
		return response.body;
	},
});
