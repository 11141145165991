import React from 'react';
import EditorCanvas from 'admin/components/pages/Story/CardEditor/Editor/EditorCanvas';
import { CardEditorContext } from 'admin/components/pages/Story/CardEditor/CardEditorContext';

export class Editor extends React.PureComponent {
	static ID = 'editor';

	renderEditorCanvas() {
		return (
			<CardEditorContext.Consumer>
				{({ storyId, cardId, mediaQuery }) =>
					storyId &&
					cardId &&
					mediaQuery && <EditorCanvas storyId={storyId} cardId={cardId} mediaQuery={mediaQuery} />
				}
			</CardEditorContext.Consumer>
		);
	}
}
