import React from 'react';
import { useSelector } from 'react-redux';
import { buildImageUrl } from 'utils/build-image-src';
import { getClientFrameNode } from 'common/utils/iframe-tunnel';
import { selectEditableElement } from 'admin/reducers/card-editor-extra/selectors';
import css from './MediaField.scss';

const ImagePreview = (props: { src: string }) => {
	const element = useSelector(selectEditableElement);
	const elementNode = getClientFrameNode(element.uiConfig.nodeProps.id);

	if (!elementNode) return null;

	return (
		<div
			className={css.pfMediaImgPreview}
			style={{ backgroundImage: `url(${buildImageUrl({ type: 'original', src: props.src }).src})` }}
		/>
	);
};

export default ImagePreview;
