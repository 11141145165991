import { isEmpty, omit } from 'lodash';
import { IUser } from 'src/types';
import { GET_ME } from 'admin/constants/actions';
import { me as verifyEndpoint } from 'admin/resources';
import { createThunkAction } from 'admin/actions/helpers';

export type GetMeResult = Pick<IUser, 'id' | 'roles' | 'paymentPlan' | 'verified'>;

export const getMe = createThunkAction<GetMeResult>({
	type: GET_ME,
	skipAlert: true,
	payloadCreator: async () => {
		const response = await verifyEndpoint.send();

		if (response.body.success && isEmpty(response.body.result)) {
			throw new Error('No user data');
		}

		return {
			...response.body,
			result: omit(response.body.result, ['profilePictureFile', 'teams', 'organizations']),
		};
	},
});
