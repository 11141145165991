import { get } from 'lodash';
import { StoryModel } from 'types/story';

export const hasUnpublishedChanges = (story: StoryModel) => {
	const latestUpdatedAt = get(story, 'storyVersions.latest.updatedAt');
	const publishedUpdatedAt = get(story, 'storyVersions.published.updatedAt');

	if (!publishedUpdatedAt) return true;

	const latestUpdatedAtTime = Number(new Date(latestUpdatedAt));
	const publishedUpdatedAtTime = Number(new Date(publishedUpdatedAt));

	return latestUpdatedAtTime - publishedUpdatedAtTime >= 0;
};
