/* eslint-disable react/no-danger */
import * as React from 'react';
import { Field, SubmissionError } from 'redux-form';
import { Link, RouteComponentProps } from 'react-router-dom';
import cn from 'classnames';
import { connect, ConnectedProps } from 'react-redux';

import { AdminReducerState } from 'admin/reducers';

import { LOGIN_PAGE } from 'admin/constants/routes';
import { resetPassword } from 'admin/actions';

import t from 'utils/translate';
import * as constants from 'admin/constants';
import Button from 'admin/components/common/Button';
import { TextField } from 'admin/components/common/Form';
import Text from 'admin/components/common/Text';
import { isMatching, QUERY } from 'admin/components/common/Responsive';
import LoginLayout from 'admin/components/common/LoginLayout';

import Form, { ForgotPasswordFormValues } from './Form';

import css from './ForgotPassword.scss';

type Props = ConnectedProps<typeof connector> & RouteComponentProps;

type State = {
	isSuccessMessageActive: boolean;
};

class ForgotPaswword extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			isSuccessMessageActive: false,
		};
	}

	onSubmit = async (values: ForgotPasswordFormValues) => {
		const result = await this.props.resetPassword({ email: values.email });

		if (result.errors) {
			throw new SubmissionError({ _error: result.errors[0]?.message });
		} else if (result.success) {
			this.setState({ isSuccessMessageActive: true });
		}
	};

	renderForm = () => {
		const { isBusy, submitError } = this.props;
		const isNoDesktop = isMatching(QUERY.NO_DESKTOP);

		return (
			<div className={css.hero}>
				<Text tag="h1" size={isNoDesktop ? 'subheading' : 'heading'} className={css.heroTitle}>
					{t('forgotPassword.title')}
				</Text>
				<Text tag="p" size="label" className={css.heroSubTitle}>
					{t('forgotPassword.subtitle')}
				</Text>

				{submitError && (
					<Text size={Text.size.label} className={css.heroError}>
						{submitError}
						<div className={css.errorIcon}>!</div>
					</Text>
				)}

				<Form className={css.form} onSubmit={this.onSubmit}>
					<div className={css.fields}>
						<Field
							className={css.emailFieldInput}
							name="email"
							component={TextField}
							isLabelUppercase={false}
							autoComplete="off"
							label={t('forgotPassword.email.placeholder')}
							isRequired
						/>
					</div>

					<div className={css.buttons}>
						<Button type="submit" disabled={isBusy}>
							{t('forgotPassword.submit')}
						</Button>

						<Link to={LOGIN_PAGE} className={cn(css.link, isBusy && css.disabled)}>
							<Text size={Text.size.label}>{t('forgotPassword.backBtnLabel')}</Text>
						</Link>
					</div>
				</Form>
			</div>
		);
	};

	renderSuccessMessage = () => {
		const isNoDesktop = isMatching(QUERY.NO_DESKTOP);

		return (
			<div className={css.successMessageContent}>
				<Text
					size={isNoDesktop ? 'subheading' : 'heading'}
					weight={Text.weight.semibold}
					compact
					className={css.title}
				>
					{t('forgotPassword.successTitle')}
				</Text>
				<Text size={Text.size.label} compact className={css.subTitle}>
					{t('forgotPassword.successSubtitle')}
				</Text>

				<Link to={LOGIN_PAGE} className={css.link}>
					<Text size={Text.size.label}>{t('forgotPassword.backBtnLabel')}</Text>
				</Link>
			</div>
		);
	};

	render() {
		const { isSuccessMessageActive } = this.state;

		return <LoginLayout>{isSuccessMessageActive ? this.renderSuccessMessage() : this.renderForm()}</LoginLayout>;
	}
}

const mapState = (state: AdminReducerState) => ({
	isBusy: !!state.loading[`${constants.actions.RESET_PASSWORD.PENDING}`],
	submitError: state.form[`${constants.common.FORM_MODEL.RESET_PASSWORD}`]?.error || '',
});

const mapDispatch = {
	resetPassword,
};

const connector = connect(mapState, mapDispatch);

export default connector(ForgotPaswword);
