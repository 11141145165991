import { set, filter } from 'lodash';
import React from 'react';
import classnames from 'classnames';
import Button from 'admin/components/common/Button';
import { Icon } from 'admin/components/common/Icon';
import { getRangesBefore, getRangesAfter, ScoreRange } from './utils';

import css from './RangeSlider.scss';

type Props = {
	range: ScoreRange;
	index: number;
	ranges: ScoreRange[];
	onChange: (value: ScoreRange[]) => void;
};

/**
 * Delete and extend prev range or if isn't exist next range with a current.
 */
const ScoreRangeDeleteButton = ({ ranges, range, index, onChange }: Props) => {
	return (
		<Button
			title="Delete and extend prev range or if isn't exist next range with a current."
			view="empty"
			color="danger-ds"
			shape="circle"
			size="tiny"
			className={classnames(css.btn, css.btnDelete)}
			onClick={() => {
				const rangesBefore = getRangesBefore(ranges, range);
				const rangesAfter = getRangesAfter(ranges, range);

				let nextScoreRanges = [...ranges];

				if (rangesBefore.length) {
					set(nextScoreRanges, `${index - 1}.max`, range.max);
				} else if (rangesAfter.length) {
					set(nextScoreRanges, `${index + 1}.min`, range.min);
				}

				nextScoreRanges = filter(nextScoreRanges, v => v._id !== range._id);

				onChange(nextScoreRanges);
			}}
			disabled={ranges.length <= 2}
		>
			<Icon type="minus-rounded" color="currentColor" />
		</Button>
	);
};

export default ScoreRangeDeleteButton;
