import * as React from 'react';
import cn from 'classnames';

import Text from 'admin/components/common/Text';
import { Icon } from 'admin/components/common/Icon';
import { IntegrationListItem, INTEGRATIONS_PAGE_VIEW } from 'common/utils/facades/integrations-facade';

import { _t } from '../helpers';

import css from './IntegrationItem.scss';

type Props = {
	item: IntegrationListItem;
	className?: string;
	children?: React.ReactNode;
	isActive?: boolean;
	onClick?: (id: INTEGRATIONS_PAGE_VIEW) => void;
};

export const IntegrationItem: React.FC<Props> = props => {
	const { item, isActive = false, children = undefined, onClick = undefined, className = '' } = props;
	const onItemClick = React.useCallback(() => {
		onClick?.(item.viewId);
	}, [item, onClick]);

	const renderInnerContent = () => (
		<>
			<Icon type={item.icon} color="#CCC" width={32} />
			<Text compact weight="semibold" size="subheading" tag="div">
				{_t(`${item.translateKey}.title`)}
			</Text>
			{children || <Icon className={css.status} type={isActive ? 'sc-check' : 'sc-plus'} width={20} />}
		</>
	);

	if (onClick) {
		return (
			<button
				type="button"
				className={cn(css.integrationItem, className, isActive && css.active)}
				onClick={onItemClick}
			>
				{renderInnerContent()}
			</button>
		);
	}

	return (
		<div className={cn(css.integrationItem, className, css.withMenu, isActive && css.active)}>
			{renderInnerContent()}
		</div>
	);
};
