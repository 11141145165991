import { OTHER_PROPS, UNIFORM_PROPS } from 'common/constants/component-props';

export enum CounterType {
	CountUp = 'countUp', // count from 0 to 10
	CountDown = 'countDown', // count from 10 to 0
}

export enum CounterDivider {
	Colon = 'colon',
	Empty = 'empty',
}

export enum CounterAnimation {
	SwipeDown = 'swipeDown',
	SwipeUp = 'swipeUp',
	Fade = 'fade',
	None = 'none',
}

export enum CounterUnit {
	Year,
	Month,
	Week,
	Day,
	Hour,
	Minute,
	Second,
}

export const UNIT_VALUES = {
	[CounterUnit.Year]: 1000 * 60 * 60 * 24 * 365,
	[CounterUnit.Month]: 1000 * 60 * 60 * 24 * 30,
	[CounterUnit.Week]: 1000 * 60 * 60 * 24 * 7,
	[CounterUnit.Day]: 1000 * 60 * 60 * 24,
	[CounterUnit.Hour]: 1000 * 60 * 60,
	[CounterUnit.Minute]: 1000 * 60,
	[CounterUnit.Second]: 1000,
};

export const UNIT_LABELS = {
	EN: {
		[CounterUnit.Year]: 'Years',
		[CounterUnit.Month]: 'Months',
		[CounterUnit.Week]: 'Weeks',
		[CounterUnit.Day]: 'Days',
		[CounterUnit.Hour]: 'Hours',
		[CounterUnit.Minute]: 'Minutes',
		[CounterUnit.Second]: 'Seconds',
	},
	HE: {
		[CounterUnit.Year]: 'שנים',
		[CounterUnit.Month]: 'חודשים',
		[CounterUnit.Week]: 'שבועות',
		[CounterUnit.Day]: 'ימים',
		[CounterUnit.Hour]: 'שעות',
		[CounterUnit.Minute]: 'דקות',
		[CounterUnit.Second]: 'שניות',
	},
};

export const defaultProps = {
	[UNIFORM_PROPS.counterType]: CounterType.CountDown,
	[UNIFORM_PROPS.counterDate]: null,
	[OTHER_PROPS.counter.counterDivider]: CounterDivider.Colon,
	[OTHER_PROPS.counter.counterUnits]: [CounterUnit.Day, CounterUnit.Hour, CounterUnit.Minute, CounterUnit.Second],
};

export const COUNTER_UNIT_DATA_ATTR = 'data-bb-unit';
export const COUNTER_DIGIT_DATA_ATTR = 'data-bb-digit';
export const COUNTER_DIVIDER_DATA_ATTR = 'data-bb-divider';
export const COUNTER_LABEL_DATA_ATTR = 'data-bb-label';
