import React, { ReactNode } from 'react';
import { FormSubmitHandler, InjectedFormProps, reduxForm } from 'redux-form';

type Values = {};

type OwnProps = {
	onSubmit: (...args: Parameters<FormSubmitHandler<Values>>) => any;
	children: (props: FormProvidedProps) => ReactNode;
};

type FormProps = OwnProps & InjectedFormProps<Values, OwnProps>;

export type FormProvidedProps = Omit<FormProps, 'children' | 'onSubmit'>;

const Form = ({ onSubmit, children, ...props }: FormProps) => {
	return <form onSubmit={props.handleSubmit(onSubmit)}>{children(props)}</form>;
};

export default reduxForm<Values, OwnProps>({})(Form);
