import React, { useContext, useState } from 'react';
import { arrayPush, arrayRemove, ReduxFormContext } from 'redux-form';
import type { GooglePublisherTagItem } from 'src/types';
import { useAdminDispatch } from 'admin/reducers';
import { THEME } from 'admin/components/common/Theme';
import Button from 'admin/components/common/Button';
import { Icon } from 'admin/components/common/Icon';
import { TextField } from 'admin/components/common/Form';
import { Column, Grid } from 'admin/components/common/Grid';
import ParamsTable from 'admin/components/common/Intregrations/ParamsTable';

type CustomTargetingProps = {
	targets?: GooglePublisherTagItem['targets'];
	theme?: ValuesType<typeof THEME>;
	fieldName: string;
};

const CustomTargeting: React.FC<CustomTargetingProps> = props => {
	const dispatch = useAdminDispatch();
	const { form: formName } = useContext(ReduxFormContext);
	const [name, setName] = useState('');
	const [value, setValue] = useState('');

	return (
		<div style={{ width: 450, maxWidth: '100%' }}>
			<Grid columns="14" columnGap={Grid.gap.medium}>
				<Column colSpan="6">
					<TextField
						name="name"
						autoComplete="off"
						isLabelUppercase={false}
						theme={props.theme}
						isRequired
						type="text"
						placeholder="Name"
						value={name}
						onChange={e => setName(e.target.value)}
					/>
				</Column>
				<Column colSpan="6">
					<TextField
						name="value"
						autoComplete="off"
						isLabelUppercase={false}
						theme={props.theme}
						isRequired
						type="text"
						placeholder="Value1, value2..."
						value={value}
						onChange={e => setValue(e.target.value)}
					/>
				</Column>
				<Column colSpan="2" alignItems="center">
					<Button
						view="empty"
						color="primary"
						shape="circle"
						size="small"
						disabled={
							!name || !value || /(\s)/g.test(name) || (props.targets ?? []).some(a => a[0] === name)
						}
						onClick={() => {
							setName('');
							setValue('');
							dispatch(arrayPush(formName, props.fieldName, [name, value]));
						}}
					>
						<Icon type="plus-rounded" color="currentColor" />
					</Button>
				</Column>
			</Grid>
			<ParamsTable
				theme={props.theme!}
				title="Added custom targets"
				hideHeader
				columns={[
					{ label: 'Name', fieldKey: 'name' },
					{ label: 'Value', fieldKey: 'value' },
					{ label: '', fieldKey: 'action' },
				]}
				data={
					props.targets?.map((target, targetIndex) => ({
						name: target[0],
						value: target[1],
						action: (
							<Button
								view="empty"
								color="danger-ds"
								shape="circle"
								size="tiny"
								onClick={() => dispatch(arrayRemove(formName, props.fieldName, targetIndex))}
							>
								<Icon type="minus-rounded" color="currentColor" />
							</Button>
						),
					})) ?? []
				}
			/>
		</div>
	);
};

export default CustomTargeting;
