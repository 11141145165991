/* eslint-disable no-useless-escape */

import React, { FC } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import _ from 'lodash';

import t from 'utils/translate';
import { emailRegExp } from 'utils/regexp';
import { FORM_MODEL } from 'admin/constants/common';

export type ForgotPasswordFormValues = { email: string };

type Props = {
	className?: string;
	children?: React.ReactNode;
	onSubmit: (data: ForgotPasswordFormValues) => void;
};

const Form: FC<Props & InjectedFormProps<ForgotPasswordFormValues, Props>> = props => {
	return (
		<form className={props.className} onSubmit={props.handleSubmit(props.onSubmit)}>
			{props.children}
		</form>
	);
};

export default reduxForm<ForgotPasswordFormValues, Props>({
	validate: values => {
		const errors: Partial<ForgotPasswordFormValues> = {};
		const _t = (label: string) => t(`forgotPassword.${label}`);

		if (!values.email) {
			_.set(errors, 'email', _t('email.required'));
		} else if (!emailRegExp.test(values.email)) {
			_.set(errors, 'email', _t('email.invalid'));
		}

		return errors;
	},
	destroyOnUnmount: true,
	form: FORM_MODEL.RESET_PASSWORD,
})(Form);
