import React, { useEffect, useState } from 'react';
import ScoreRangeSlider from './ScoreRangeSlider';
import ScoreRangeDeleteButton from './ScoreRangeDeleteButton';
import ScoreRangeSplitButton from './ScoreRangeSplitButton';
import { ScoreRange } from './utils';

type Props = {
	scoreRanges: ScoreRange[];
	onUpdate: (ranges: ScoreRange[]) => void;
};

const ScoreRangeSliders: React.FC<Props> = ({ scoreRanges, onUpdate }) => {
	const [ranges, setRanges] = useState(scoreRanges);
	const [isOnAfterChange, setIsOnAfterChange] = useState(false);
	const [isBusy, setIsBusy] = useState(false);

	useEffect(() => {
		if (isOnAfterChange) setIsOnAfterChange(false);
		return () => {
			if (isOnAfterChange) onUpdate(ranges);
		};
	}, [isOnAfterChange, ranges, onUpdate]);

	return (
		<>
			{isBusy && (
				<div
					style={{
						position: 'fixed',
						zIndex: 10_000,
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						background: 'transparent',
					}}
				/>
			)}
			{ranges.map((range, index) => {
				return (
					<ScoreRangeSlider
						key={range._id}
						range={range}
						index={index}
						scoreRanges={ranges}
						onChange={value => {
							setIsBusy(true);
							setIsOnAfterChange(false);
							setRanges(value);
						}}
						onChangeComplete={values => {
							setIsBusy(false);
							setIsOnAfterChange(true);
						}}
						deleteButton={
							<ScoreRangeDeleteButton
								ranges={ranges}
								range={range}
								index={index}
								onChange={value => {
									setRanges(value);
									onUpdate(value);
								}}
							/>
						}
						splitButton={
							<ScoreRangeSplitButton
								ranges={ranges}
								range={range}
								onChange={value => {
									setRanges(value);
									onUpdate(value);
								}}
							/>
						}
					/>
				);
			})}
		</>
	);
};

export default ScoreRangeSliders;
