import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

import { videos } from 'admin/resources';
import { useAdminSelector } from 'admin/reducers';
import { selectActiveOrganizationId } from 'admin/reducers/user/selectors';

import { toast } from 'admin/components/common/MessageContainer/toast';

import { UpdateVideoParams, UpdateVideoResult } from 'src/types';

import { QUERY_KEYS } from '../constants';

export const useUpdateVideo = () => {
	const organizationId = useAdminSelector(selectActiveOrganizationId);
	const client = useQueryClient();
	const {
		mutate: updateVideo,
		error,
		isPending,
		mutateAsync,
		isSuccess,
	} = useMutation({
		mutationFn: async (params: Omit<UpdateVideoParams, 'organizationId'>) => {
			const { title, poster, videoId } = params;
			const response = await videos.update.params({ organizationId, videoId }).send({ title, poster });

			return {
				success: true,
				result: { ...response.body },
			} as IBackendBody<UpdateVideoResult>;
		},
		onSuccess: async (data, variables, context) => {
			/**
			 * invalidate useVideoStatsQuery to get fresh data
			 * we don't invalidate useVideoQuery because we are
			 * receiving an event in pubnub about video update
			 * and invalidate this query from pubnub module
			 */
			return client.invalidateQueries({
				queryKey: [QUERY_KEYS.VIDEO_STATS, organizationId, variables.videoId],
			});
		},
	});

	useEffect(() => {
		if (error) {
			const errorData = JSON.parse((error as Error).message ?? '{}');
			toast.error(errorData.message || (error as Error).message, 3);
		}
	}, [error]);

	return { updateVideo, updateVideoAsync: mutateAsync, error, isSuccess, isPending };
};
