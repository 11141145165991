import React from 'react';
import cn from 'classnames';

import Text from 'admin/components/common/Text';

import { Column, Grid } from 'admin/components/common/Grid';

import css from './SettingsModalLayout.scss';

type MenuItem = {
	label: string;
	isActive: boolean;
	disabled: boolean;
	onClick: undefined | (() => void);
};
type Props = {
	className?: string;
	headerClassName?: string;
	contentClassName?: string;
	menu: Array<MenuItem>;
	renderContent?: () => React.ReactNode;
	renderHeader?: () => React.ReactNode;
};

const SettingsModalLayout: React.FC<Props> = props => {
	const { className = '', headerClassName = '', contentClassName = '', renderContent, renderHeader, menu } = props;

	return (
		<div className={cn(css.inner, className)}>
			<Grid className={css.grid} columns="12" gap="large">
				{/* Aside */}
				<Column colSpan="3" className={css.col1}>
					<div className={css.menu}>
						{menu.map(item => (
							<button
								key={`settings-menu-item-${item.label}`}
								disabled={item.disabled}
								type="button"
								className={cn(css.menuItem, { [css.active]: item.isActive })}
								onClick={item.onClick}
							>
								<Text size="subheading" text={item.label} />
							</button>
						))}
					</div>
				</Column>

				{/* Content */}
				<Column colSpan="9" className={css.col2}>
					<div className={cn(css.header, headerClassName)}>{renderHeader?.()}</div>
					<div className={cn(css.content, contentClassName)}>{renderContent?.()}</div>
				</Column>
			</Grid>
		</div>
	);
};

export default SettingsModalLayout;
