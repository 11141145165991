import { DUPLICATE_STORY } from 'admin/constants/actions';
import { duplicateStory as duplicateStoryEndpoint } from 'admin/resources';
import { createThunkAction, handleStoryNoAccessError } from 'admin/actions/helpers';

export type DuplicateStoryResult = {
	duplicatedStoryId: string;
};

type StoryId = string;

export const duplicateStory = createThunkAction<DuplicateStoryResult, StoryId>({
	type: DUPLICATE_STORY,
	payloadCreator: async storyId => {
		const response = await duplicateStoryEndpoint.params({ storyId }).send();
		return response.body;
	},
	onError: params => {
		handleStoryNoAccessError(params.dispatch, params.error as IBackendResponseError);
	},
});
