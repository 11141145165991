import React, { useMemo, useState } from 'react';
import { Field, change } from 'redux-form';
import { get, first, last } from 'lodash';
import cn from 'classnames';

import { urlRegExp } from 'common/utils/regexp';
import { StoryFacade } from 'utils/facades/story-facade';
import { prependUrlProtocol } from 'common/utils/helpers';
import Text from 'admin/components/common/Text';
import { Toggle } from 'admin/components/common/Form/Toggle';
import { useAdminDispatch, useAdminSelector } from 'admin/reducers';
import { webhookSubscribe, deleteWebhook, sampleWebhook } from 'admin/resources';
import Button from 'admin/components/common/Button';
import { Error, Select, TextField } from 'admin/components/common/Form';
import AdminError from 'admin/components/common/ErrorBoundary/AdminError';
import { selectActiveOrganizationId } from 'admin/reducers/user/selectors';
import { selectEditableStoryId, selectStoryCards } from 'admin/reducers/story-editor/selectors';
import { IntegrationsFacade } from 'common/utils/facades';
import translate from 'common/utils/translate';
import InfoModal from 'admin/components/common/Modal/InfoModal';
import { FORM_MODEL, MODAL } from 'admin/constants/common';
import { Icon } from 'admin/components/common/Icon';
import Tooltip from 'admin/components/common/Tooltip';

import { toast } from 'admin/components/common/MessageContainer/toast';
import WebhookLogs from './WebhookLogs';
import { IntegrationComponentProps, WebhookEventType } from '../types';
import css from './Webhooks.scss';

const webhookEvent = WebhookEventType.ResponseSubmission;
const allCardsOption = { value: '', label: 'All cards' };
const t = (path: string) => translate(`common.integrations.views.webhooks.${path}`);

export const Webhooks: React.FC<IntegrationComponentProps> = props => {
	const dispatch = useAdminDispatch();
	const submitBtnRef = React.useRef<HTMLButtonElement>(null);
	const organizationId = useAdminSelector(selectActiveOrganizationId);
	const storyId = useAdminSelector(selectEditableStoryId);
	const storyCards = useAdminSelector(selectStoryCards);
	const [url, setUrl] = useState('');
	const [urlError, setUrlError] = useState<string | null>(null);
	const fields = IntegrationsFacade.getFields(props.version).webhooks;
	// const [webhookEvent, setWebhookEvent] = useState(webhookEvents[0].value);
	const targetCardsOptions = useMemo(
		() => [allCardsOption, ...storyCards.map(c => ({ value: c._id, label: c.name }))],
		[storyCards]
	);
	const [targetCards, setTargetCards] = useState<readonly (typeof targetCardsOptions)[number][]>([]);
	const [loading, setLoading] = useState<WebhookEventType | string | null>(null);

	const [isDatabridgeModalOpen, setIsDatabridgeModalOpen] = React.useState(false);
	const [isSubmitRequired, setIsSubmitRequired] = React.useState(false);
	const isDataBridgeEnabled = Boolean(get(props.formValues, fields['bridge.enabled'].name, false));
	const isDataBridgeEnabledInitialEffectRef = React.useRef(true);

	const [isLogsModalOpen, setIsLogsModalOpen] = React.useState(false);
	const [currentWebhookId, setCurrentWebhookId] = React.useState<string | null>(null);

	const storyFacade = new StoryFacade(props.story);
	const isStoryPublished = storyFacade.isPublished;

	React.useEffect(() => {
		// need to track data bridge field changes on entire form level, because we are about to invoke
		// its submition in the background:

		if (isDataBridgeEnabledInitialEffectRef.current) {
			isDataBridgeEnabledInitialEffectRef.current = false;
			return;
		}

		if (isDataBridgeEnabled) {
			setIsDatabridgeModalOpen(true);
		} else if (isSubmitRequired) {
			submitBtnRef.current?.click();
		}
	}, [isDataBridgeEnabled, isSubmitRequired]);

	const addWebhook = () => {
		setLoading(webhookEvent);
		const finalUrl = prependUrlProtocol(url, 'https');
		const cardIdObj = targetCards.length ? { cardId: targetCards.map(c => c.value) } : null;
		webhookSubscribe
			.params({ organizationId })
			.send({
				url: finalUrl,
				events: [webhookEvent],
				storyId,
				...cardIdObj,
			})
			.then(response => {
				props.setWebhooks(prev => [
					...prev,
					{
						id: response.body.id,
						type: 'Story',
						url: finalUrl,
						active: true,
						organizationId,
						label: null,
						events: [webhookEvent],
						...cardIdObj,
					},
				]);
				setUrl('');
			})
			.catch(e => {
				throw new AdminError((e as Error).message);
			})
			.finally(() => setLoading(null));
	};

	const sampleWebhookHandler = (webhookId: string) => {
		setLoading(`sample-${webhookId}`);
		sampleWebhook
			.params({ webhookId })
			.send()
			.then(() => {
				toast.success('Webhook test sent successfully.');
			})
			.catch(e => {
				throw new AdminError((e as Error).message);
			})
			.finally(() => setLoading(null));
	};

	const onHistoryBtnClick = (webhookId: string) => {
		setCurrentWebhookId(webhookId);
		setIsLogsModalOpen(true);
	};

	const deleteWebhookHandler = (webhookId: string) => {
		setLoading(`delete-${webhookId}`);
		deleteWebhook
			.params({ webhookId })
			.send()
			.then(() => {
				props.setWebhooks(prev => prev.filter(o => o.id !== webhookId));
			})
			.catch(e => {
				throw new AdminError((e as Error).message);
			})
			.finally(() => setLoading(null));
	};

	return (
		<>
			<section className={css.fields}>
				<div>
					<TextField
						name="name"
						isRequired
						value={url}
						isLabelUppercase={false}
						theme="dark"
						label="URL"
						onChange={e => {
							setUrl(e.target.value);
							setUrlError(null);
						}}
						onBlur={e => {
							if (e.target.value !== '' && !urlRegExp.test(e.target.value))
								setUrlError('Invalid url (e.g. https://storycards.com, storycards.com)');
						}}
						autoComplete="off"
					/>
					{urlError !== null && <Error tag="div">{urlError}</Error>}
				</div>
				{/* <Select
					label={{ children: 'Submission type' }}
					options={webhookEvents}
					value={webhookEvent}
					theme="dark"
					eventListeners={{ onChange: setWebhookEvent }}
				/> */}
				<Select
					isMulti
					closeMenuOnSelect={false}
					label={{ children: 'Target cards' }}
					options={targetCardsOptions}
					defaultValue={allCardsOption}
					value={targetCards.length ? targetCards : undefined}
					theme="dark"
					eventListeners={{
						onChange: (option, action) => {
							setTargetCards(
								first(option) === allCardsOption // replace "all cards" with "particular cards"
									? option.filter(o => o !== allCardsOption)
									: last(option) === allCardsOption // replace "particular cards" with "all cards"
										? []
										: option
							);
						},
					}}
				/>
			</section>

			<Button
				className={css.btnAdd}
				onClick={addWebhook}
				view="primary"
				size="medium"
				loading={loading === webhookEvent}
				disabled={!url || urlError !== null}
			>
				Add
			</Button>

			{props.webhooks.length ? (
				<>
					<Text className={css.itemsTitle} size="subtitle" text={t('myWebhooks')} />
					<Field
						className={css.databridgeToggle}
						name={fields['bridge.enabled'].name}
						component={Toggle}
						theme="dark"
						label={{ children: t('databridgeMode') }}
						stretch
						onChange={() => setIsSubmitRequired(true)}
					/>
					<Text className={css.databridgeHint} size="footnote" text={t('databridgeModeHint')} />
					<InfoModal
						id={MODAL.INFORMATION}
						isNested
						open={isDatabridgeModalOpen}
						close={() => {
							setIsDatabridgeModalOpen(false);
							setIsSubmitRequired(false);
							dispatch(change(FORM_MODEL.EDIT_STORY, fields['bridge.enabled'].name, false));
						}}
						data={{
							view: 'WEBHOOK/DATABRIDGE/ENABLE',
							theme: 'dark',
							onSave: () => {
								setIsDatabridgeModalOpen(false);
								submitBtnRef.current?.click();
							},
						}}
					/>
					{props.webhooks.map(wh => {
						const cards = Array.isArray(wh.cardId)
							? wh.cardId.map(cardId => storyCards.find(card => card._id === cardId)?.name).join(' • ')
							: '';
						let urlWithourProtocol = wh.url.replace(/https?:\/\//, '').replace(/www\./, '');
						urlWithourProtocol = `${urlWithourProtocol[0].toUpperCase()}${urlWithourProtocol.slice(1)}`;

						return (
							<div key={wh.id} className={css.item}>
								<Text
									className={css.itemUrl}
									size={Text.size.paragraph}
									text={urlWithourProtocol}
									ellipsis
								/>
								<Text
									className={css.itemCards}
									size={Text.size.footnote}
									text={Array.isArray(wh.cardId) ? `Cards: ${cards}` : 'All cards'}
								/>

								<Tooltip
									isStatic
									isHelp={false}
									stylePreset="dark-1"
									destroyTooltipOnHide
									content={!isStoryPublished ? t('testBlocked') : null}
									placement="top"
								>
									<Button
										className={cn(css.itemTestBtn, !isStoryPublished && css.itemTestBtnDisabled)}
										loading={loading === `sample-${wh.id}`}
										disabled={loading === `sample-${wh.id}`}
										size="tiny"
										view="primary"
										textSize={Text.size.label}
										onClick={isStoryPublished ? () => sampleWebhookHandler(wh.id) : undefined}
									>
										Test
									</Button>
								</Tooltip>

								<Tooltip
									isStatic
									isHelp={false}
									stylePreset="dark-1"
									destroyTooltipOnHide
									content={t('history')}
									placement="top"
								>
									<Button
										className={css.historyBtn}
										size="auto"
										textSize={Text.size.label}
										view="empty"
										onClick={() => onHistoryBtnClick(wh.id)}
									>
										<Icon type="sc-history" width={20} height={20} />
									</Button>
								</Tooltip>

								<Button
									loading={loading === `delete-${wh.id}`}
									size="auto"
									textSize={Text.size.label}
									view="empty"
									color="danger"
									onClick={() => deleteWebhookHandler(wh.id)}
								>
									<Icon className={css.itemRemoveIcon} type="close" width={20} />
								</Button>
							</div>
						);
					})}
					<button ref={submitBtnRef} type="submit" style={{ display: 'none' }} />

					{currentWebhookId && (
						<WebhookLogs
							id={MODAL.WEBHOOK_LOGS}
							isNested
							theme="dark"
							open={isLogsModalOpen}
							close={() => setIsLogsModalOpen(false)}
							data={{ storyId: props.story.id, webhookId: currentWebhookId }}
						/>
					)}
				</>
			) : null}
		</>
	);
};
