import { pick, isFunction } from 'lodash';
import React from 'react';
import cn from 'classnames';
import { Switch, SwitchProps } from 'antd';
import { Label, LabelProps } from '../Label';
import { Errors } from '../Errors';
import { ReduxFieldInputTypes, ReduxFieldMetaTypes } from '../utils';
import css from './Toggle.scss';

type AntdSwitchProps = Pick<
	SwitchProps,
	| 'autoFocus'
	| 'checked'
	| 'checkedChildren'
	| 'defaultChecked'
	| 'disabled'
	| 'loading'
	| 'size'
	| 'unCheckedChildren'
	| 'onChange'
	| 'onClick'
	| 'className'
>;

type ToggleProps = {
	input: ReduxFieldInputTypes;
	meta: ReduxFieldMetaTypes;
	theme?: 'dark' | 'light';
	className?: string;
	label?: Omit<LabelProps, 'component'>;
	showErrorsOn?: string;
	stretch?: boolean;
	labelPosition?: 'left' | 'right' | 'top';
	alignItems?: 'top' | 'center';
} & AntdSwitchProps;

type PureToggleProps = {
	id?: string;
	checked?: boolean;
	switchProps?: AntdSwitchProps;
	className?: string;
} & Pick<ToggleProps, 'label' | 'labelPosition' | 'stretch' | 'alignItems'>;

export const TogglePure = ({
	label,
	checked,
	id,
	className,
	stretch,
	labelPosition = 'left',
	alignItems = 'center',
	switchProps,
}: PureToggleProps) => (
	<label
		className={cn(css.toggle, css[`label-${labelPosition}`], css[`align-${alignItems}`], className, {
			[css.stretch]: stretch,
		})}
	>
		{label && <Label theme="dark" {...label} component="div" className={cn(css.label, label?.className)} />}
		<div className={cn(css.fieldToggleInner)}>
			<Switch id={id} checked={checked} {...switchProps} />
		</div>
	</label>
);

export const Toggle = (props: ToggleProps) => {
	const {
		className,
		input,
		label,
		meta,
		theme = 'light',
		showErrorsOn = 'touched',
		stretch,
		labelPosition,
		alignItems,
		...rest
	} = props;
	const id = `${meta.form}.${input.name}`;
	const switchProps = pick(
		{
			...rest,
			...input,
		},
		[
			'autoFocus',
			'checked',
			'checkedChildren',
			'defaultChecked',
			'disabled',
			'loading',
			'size',
			'unCheckedChildren',
			'onChange',
			'onClick',
			'className',
		]
	);
	const showErrors = isFunction(showErrorsOn) ? showErrorsOn(meta, input) : meta[showErrorsOn || ''];

	return (
		<div className={cn(css.fieldToggle, css[theme], className, { [css.disabled]: switchProps.disabled })}>
			<TogglePure
				id={id}
				checked={typeof input.value === 'boolean' ? input.value : undefined}
				label={label}
				stretch={stretch}
				labelPosition={labelPosition}
				alignItems={alignItems}
				switchProps={switchProps}
			/>
			<Errors show={showErrors}>{meta.error}</Errors>
		</div>
	);
};

/*
 * Example
 * name - form values key
 * prop - value to assign to form {key: value}

<Field
	name="gender"
	component={Toggle}
	label={{ children: 'Male' }}
/>
*/
