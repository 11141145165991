import type { CardTemplate, StoryTemplateWithoutData } from 'types/story';
import React, { useRef } from 'react';
import { capitalize } from 'lodash';
import t from 'utils/translate';
import { getAssetURL } from 'utils/assets';
import { CardFacade } from 'utils/facades/card-facade';
import { CARD_TYPE } from 'common/constants';
import useInView from 'common/components/useInView';
import { useHover } from 'common/components/useHover';
import useImage from 'common/components/useImage';
import Text from 'admin/components/common/Text';
import { Icon } from 'admin/components/common/Icon';
import Button from 'admin/components/common/Button';
import { Column } from 'admin/components/common/Grid';
import { getCardType } from 'admin/components/pages/Story/Flow/CreateCardModal/utils';
import css from './TemplateItem.scss';

type CardTemplateItem = {
	type: 'card';
	item: Omit<CardTemplate, 'editor'>;
	onPreviewClick: (templateId: string) => void;
	onAddClick?: (templateId: string) => void;
};

type StoryTemplateItem = {
	type: 'story';
	item: StoryTemplateWithoutData;
	onPreviewClick: (templateId: number) => void;
	onAddClick?: (templateId: number) => void;
};

const TemplateItem: React.FC<CardTemplateItem | StoryTemplateItem> = props => {
	const itemRef = useRef<HTMLDivElement | null>(null);
	const isInView = useInView(itemRef);
	const isHover = useHover(itemRef);

	let sourceURL = '';
	if (props.type === 'card') {
		const { metadata: md, coverImageFile } = props.item;
		const { defaultPlatform: platform } = md;
		sourceURL = getAssetURL(
			coverImageFile || md.storySettings.screenshot?.[md.cardId]?.[platform] || md.screenshot?.[platform]
		);
	} else {
		const { story, storyId, coverCardId } = props.item;
		const screenshots = story.published.settings.screenshot ?? {};
		const asset = (coverCardId && screenshots[coverCardId]) || screenshots[storyId];
		sourceURL = getAssetURL(asset?.desktop);
	}

	const isVideoUrl = sourceURL.match(/[.mp4]$/) !== null;
	const image = useImage(sourceURL, { isEnabled: isInView && !isVideoUrl });

	const info1 =
		props.type === 'card' ? (
			<Text size={Text.size.label} transform={Text.transform.uppercase} color={Text.color.blackDark} compact>
				{CardFacade.getCardNameByType(getCardType(props.item))}
			</Text>
		) : (
			<Text compact text={props.item.title} />
		);

	const info2 =
		props.type === 'card' ? (
			<Text className={css.description} compact ellipsis>
				{props.item.name}
			</Text>
		) : (
			<Text className={css.description} color={Text.color.blackDark} size={Text.size.label} compact ellipsis>
				{props.item.story.published.settings.metadata.cardTypes
					.filter(v => v !== CARD_TYPE.NAVIGATION)
					.map(v => capitalize(CardFacade.getCardNameByType(v)))
					.join(', ')}
			</Text>
		);

	return (
		<div className={css.templateItem}>
			<div className={css.templateItemPreview} ref={itemRef}>
				{isVideoUrl ? (
					<video muted className={css.templateItemScreenshot} preload="metadata" autoPlay={isHover}>
						<source src={sourceURL} type="video/mp4" />
					</video>
				) : (
					<div
						className={css.templateItemScreenshot}
						style={{ backgroundImage: image.loaded ? `url(${sourceURL})` : '' }}
					/>
				)}

				<div className={css.templateItemActions}>
					{props.onAddClick ? (
						<Button
							onClick={() =>
								props.type === 'card'
									? props.onAddClick?.(props.item.id)
									: props.onAddClick?.(props.item.id)
							}
							size="medium"
							view="white"
							textSize={Text.size.article}
							textWeight={Text.weight.normal}
						>
							<Icon type="sc-plus" width={12} color="currentColor" />
							{t('createStoryModal.use')}
						</Button>
					) : null}
					<Button
						onClick={() =>
							props.type === 'card'
								? props.onPreviewClick(props.item.id)
								: props.onPreviewClick(props.item.id)
						}
						view="empty"
						color="white"
						size="medium"
						textSize={Text.size.article}
						textWeight={Text.weight.normal}
					>
						<Icon type="sc-layer-show" width={18} color="currentColor" />
						{t('createStoryModal.view')}
					</Button>
				</div>
			</div>

			<div className={css.templateItemInfo}>
				{info1}
				{info2}
			</div>

			{props.type === 'story' ? (
				<Column className={css.templateItemFooter} alignItems="center">
					<Icon type="sc-layer-box" width={18} color="currentColor" />
					<Text size={Text.size.label} compact>
						{props.item.story.published.settings.metadata.totalCards} cards
					</Text>
				</Column>
			) : null}
		</div>
	);
};

export default TemplateItem;
