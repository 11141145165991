import _ from 'lodash';
import React from 'react';
import { Transition } from 'react-transition-group';
import { clientLog, defer } from 'utils/helpers';
import { COMPONENT_TYPE, ANIMATION_TRIGGER } from 'common/constants';
import { BBUiConfig } from 'types/story';
import { EndHandler, TransitionProps } from 'react-transition-group/Transition';

const log = clientLog.extend('TransitionHandler');

type TransitionEndProps = { _id: string; type: string; in: boolean; uiConfig: BBUiConfig };

export type TransitionHandlerContextType = {
	in?: boolean;
	onTransitionEnd: (props: TransitionEndProps) => void;
};

export const CardTransitionContext = React.createContext<Partial<TransitionHandlerContextType>>({ in: undefined });

type Props = Partial<TransitionProps> & {
	progressRef: React.MutableRefObject<{
		in: number;
		out: number;
	}>;
};

type State = {
	animationState: {
		in?: boolean;
		onTransitionEnd: (props: TransitionEndProps) => void;
	};
};

export default class TransitionHandler extends React.PureComponent<Props, State> {
	/*
	 Determines current transition (in / out) state for all Card's components
	 false — transition is not finished
	 true — transition is finished
	 */
	static get DEFAULT_TRANSITION_STATE() {
		return {
			[COMPONENT_TYPE.FLOAT_ABOVE]: false,
			[COMPONENT_TYPE.CONTENT]: false,
			[COMPONENT_TYPE.FLOAT_BELOW]: false,
			[COMPONENT_TYPE.CARD]: false,
		};
	}

	transitionDoneCallback: null | (() => void) = null;

	childrenTransitions = {
		in: TransitionHandler.DEFAULT_TRANSITION_STATE,
		out: TransitionHandler.DEFAULT_TRANSITION_STATE,
	};

	constructor(props: Props) {
		super(props);

		this.state = {
			animationState: {
				in: undefined,
				onTransitionEnd: this.onTransitionEnd,
			},
		};
	}

	/*
	 This callback is passed down to all child components via CardTransitionContext
	 Each child invokes it only after all its direct children have finished current transition state (in / out)
	 See BuildingBlockEnhancer.js
	 */
	onTransitionEnd = ({ _id, type, in: inProp, uiConfig }: TransitionEndProps) => {
		const key = inProp ? 'in' : 'out';
		const current = this.childrenTransitions[key];
		log('onTransitionEnd', {
			key,
			name: _.get(uiConfig, 'editorProps.name'),
			childrenTransitions: this.childrenTransitions,
		});

		if (current[type]) {
			return;
		}

		current[type] = true;

		if (current.FLOAT_ABOVE && current.CONTENT && current.FLOAT_BELOW && current.CARD) {
			log(`onTransitionEnd ${key} COMPLETE`);

			// Update the progress reference.
			this.props.progressRef.current[key] = 1;

			// Invoke the transition `EndHandler` `done` callback
			this.transitionDoneCallback?.();

			// Ensure that both the "in" and "out" transitions have completed.
			if (this.props.progressRef.current.in && this.props.progressRef.current.out) {
				// Use `defer` to ensure that DOM changes have already been applied.
				// This guarantees that the entered component is in the DOM, and the exited component is unmounted.
				// This avoids issue of both components being in the DOM even after both `done` callbacks have fired.
				defer(() => window.dispatchEvent(new Event(ANIMATION_TRIGGER.ON_CARD_EXPOSE_COMPLETE)));
			}
		}
	};

	animate: EndHandler<HTMLElement | undefined> = (node, done) => {
		// setTimeout(, 0) is required here to postpone updates by setState,
		// as far as React works with setState in componentDidMount in this way:
		// Calling setState() in this method will trigger an extra rendering, but it is guaranteed to
		// flush during the same tick. This guarantees that even though the render() will be called twice
		// in this case, the user won’t see the intermediate state.

		if (this.props.in) {
			log('animate in...', node);
			this.transitionDoneCallback = done;
			// Reset the children transitions state.
			this.childrenTransitions.in = TransitionHandler.DEFAULT_TRANSITION_STATE;
			// Reset the progress reference.
			this.props.progressRef.current.in = 0;
			setTimeout(() => {
				this.setState(state => ({ animationState: { ...state.animationState, in: true } }));
				window.dispatchEvent(new Event(ANIMATION_TRIGGER.ON_CARD_EXPOSE_START));
			}, 0);
		} else {
			log('animate out...', node);
			this.transitionDoneCallback = done;
			// Reset the children transitions state.
			this.childrenTransitions.out = TransitionHandler.DEFAULT_TRANSITION_STATE;
			// Reset the progress reference.
			this.props.progressRef.current.out = 0;
			setTimeout(() => {
				this.setState(state => ({ animationState: { ...state.animationState, in: false } }));
			}, 0);
		}
	};

	render() {
		const { children, ...rest } = this.props;

		return (
			<Transition {...rest} addEndListener={this.animate}>
				<CardTransitionContext.Provider value={this.state.animationState}>
					{children as React.ReactNode}
				</CardTransitionContext.Provider>
			</Transition>
		);
	}
}
