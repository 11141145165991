import React, { CSSProperties, forwardRef } from 'react';
import cn from 'classnames';
import css from './Layout.scss';

type Props = {
	children: React.ReactNode;
	className?: string;
	style?: CSSProperties;
};

const Layout: React.FC<Props> = props => <div {...props} className={cn(css.layout, props.className)} />;
const Header: React.FC<Props> = props => <div {...props} className={cn(css.header, props.className)} />;
const Panel: React.FC<Props> = props => <div {...props} className={cn(css.panel, props.className)} />;
const Aside: React.FC<Props> = props => <div {...props} className={cn(css.aside, props.className)} />;
const Content = forwardRef<HTMLDivElement, Props & { coverMode?: boolean }>(({ coverMode, ...props }, ref) => (
	<div {...props} className={cn(css.content, props.className, { [css.coverMode]: coverMode })} ref={ref} />
));
const CenteredContent: React.FC<Props> = props => <div {...props} className={cn(css.cContent, props.className)} />;

/*
Grid:

Layout
	Header Header
	Panel  Panel
	Aside  Content

 Layout
	 Header           Header
	 CenteredContent  CenteredContent
	 CenteredContent  CenteredContent
 */

export default {
	Layout,
	Header,
	Panel,
	Aside,
	Content,
	CenteredContent,
	className: css,
};
