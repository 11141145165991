import { createThunkAction } from 'admin/actions/helpers';
import { auth as authEndpoints } from 'admin/resources';
import {
	GET_TOTP,
	LOGIN,
	POST_TOTP,
	POST_TOTP_RECOVERY_CODES,
	POST_TOTP_REMOVE,
	POST_TOTP_REQUIREMENT,
	POST_TOTP_VERIFY,
} from 'admin/constants/actions';

export type LoginResult = {
	twofactor: boolean;
};

export interface GetTOTPResult {
	enabled: boolean;
	phone: string | null;
	totp: {
		verified: boolean;
		recoveryCodes: string[];
		type: 'app';
		url: string;
	};
}

export interface PostTOTPResult {
	message: string;
}

export type PostTOTPVerifyResult = string;
export type PostTOTPRequirementResult = { required: boolean; enabled: boolean };
export type PostTOTPRecoveryCodesResult = string;
export type PostTOTPRemovalResult = string;

export const auth = {
	get: {
		totp: createThunkAction<GetTOTPResult, void>({
			type: GET_TOTP,
			payloadCreator: async (params, { dispatch }) => {
				const res = await authEndpoints.get.totp.send({});
				return { success: true, result: res.body };
			},
		}),
	},
	post: {
		login: createThunkAction<LoginResult, { email: string; password: string; code?: string }>({
			type: LOGIN,
			payloadCreator: async params => {
				const res = await authEndpoints.post.login.send(params);
				return { success: true, result: res.body };
			},
		}),
		totp: createThunkAction<PostTOTPResult, { type: 'app' }>({
			type: POST_TOTP,
			payloadCreator: async params => {
				const res = await authEndpoints.post.totp.send(params);
				return { success: true, result: res.body };
			},
		}),
		totpRequirement: createThunkAction<PostTOTPRequirementResult, void>({
			type: POST_TOTP_REQUIREMENT,
			payloadCreator: async params => {
				const res = await authEndpoints.post.totpRequirement.send();
				return { success: true, result: res.body };
			},
		}),
		totpVerify: createThunkAction<PostTOTPVerifyResult, { code: string }>({
			type: POST_TOTP_VERIFY,
			payloadCreator: async params => {
				const res = await authEndpoints.post.totpVerify.send(params);
				return { success: true, result: res.body };
			},
		}),
		totpRecoveryCodes: createThunkAction<PostTOTPRecoveryCodesResult, { code: string; password: string }>({
			type: POST_TOTP_RECOVERY_CODES,
			payloadCreator: async params => {
				const res = await authEndpoints.post.totpRecoveryCodes.send(params);
				return { success: true, result: res.body };
			},
		}),
		totpRemove: createThunkAction<PostTOTPRemovalResult, { code: string; password: string }>({
			type: POST_TOTP_REMOVE,
			payloadCreator: async params => {
				const res = await authEndpoints.post.totpRemove.send(params);
				return { success: true, result: res.body };
			},
		}),
	},
};
