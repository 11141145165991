import React, { SyntheticEvent, useContext, CSSProperties } from 'react';
import cn from 'classnames';
import Text from 'admin/components/common/Text';
import Tooltip from 'admin/components/common/Tooltip';
import { PopoverContext } from 'admin/components/pages/Story/CardEditor/Inspector/Popover';
import css from './Select.scss';

export type OptionProps = {
	value: string;
	onClick: (event: SyntheticEvent<HTMLDivElement>, value: string) => void;
	className?: string;
	title?: string;
	label: string | React.ReactNode;
	tooltip?: React.ReactNode;
	isSelected: boolean;
	style?: CSSProperties;
};

const tooltipProps = {
	align: { offset: [-32, 0] },
	placement: 'left' as const,
	stylePreset: 'dark-2' as const,
};

const Option = ({ value, onClick, label, className, title, tooltip, isSelected, style }: OptionProps) => {
	const popoverContent = useContext(PopoverContext);

	return (
		<Tooltip
			{...tooltipProps}
			content={
				tooltip ? (
					<Text size={Text.size.description} color={Text.color.whiteMid}>
						{tooltip}
					</Text>
				) : null
			}
		>
			<div
				title={title}
				className={cn(css.option, className, { [css.selected]: isSelected })}
				onClick={event => {
					onClick(event, value);
					if (popoverContent?.toggle) popoverContent.toggle();
				}}
				style={style}
			>
				{label}
			</div>
		</Tooltip>
	);
};
export { Option };
