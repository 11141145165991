import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import { videos } from 'admin/resources';
import { useAdminSelector } from 'admin/reducers';
import { selectActiveOrganizationId } from 'admin/reducers/user/selectors';

import { toast } from 'admin/components/common/MessageContainer/toast';

import { GetVideoStatsParams, GetVideoStatsResult } from 'src/types';

import { QUERY_KEYS } from '../constants';

export const useVideoStats = (params: Omit<GetVideoStatsParams, 'organizationId'>) => {
	const organizationId = useAdminSelector(selectActiveOrganizationId);
	const { groupByDate, from, videoId, to } = params;
	const { data, isLoading, isFetching, error } = useQuery({
		queryKey: [QUERY_KEYS.VIDEO_STATS, organizationId, videoId, JSON.stringify({ groupByDate, from, to })],
		queryFn: async () => {
			const query = {
				groupByDate,
				from,
				to,
			};

			const response = await videos.getStats.params({ organizationId, videoId }).send(query);

			return {
				success: true,
				result: { ...response.body },
			} as IBackendBody<GetVideoStatsResult>;
		},
		enabled: Boolean(videoId),
	});

	useEffect(() => {
		if (error) {
			const errorData = JSON.parse((error as Error).message ?? '{}');
			toast.error(errorData.message || (error as Error).message, 3);
		}
	}, [error]);

	return { data: data?.result, isLoading, isFetching, error };
};
