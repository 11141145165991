import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import t from 'utils/translate';
import { applyStoryVoluntaryUpdates } from 'admin/actions/story/apply-story-voluntary-updates';
import { setModal } from 'admin/actions/set-modal';
import type { ModalReducerState } from 'admin/reducers/modal-reducer';
import { getFormattedTimestamp } from 'admin/utils/get-formatted-timestamp';
import Text from 'admin/components/common/Text';
import Button from 'admin/components/common/Button';
import { MODAL } from 'admin/constants/common';
import { VIEW_MAP } from 'admin/components/pages/Story/Flow/StorySettingsModal/constants';
import { StoryMigrationAdapter, VoluntaryUpdateList } from 'admin/utils/story-migration-adapter';
import type { SettingsTabsGeneralProps } from 'admin/components/pages/Story/Flow/StorySettingsModal/types';
import css from './Updates.scss';

type Props = SettingsTabsGeneralProps & {
	modal: ModalReducerState<MODAL.STORY_SETTINGS>;
} & ConnectedProps<typeof connector>;

const Updates = ({ modal, ...props }: Props) => {
	const migration = new StoryMigrationAdapter({ story: props.story });
	const updates =
		(modal.data.view === VIEW_MAP.UPDATES ? modal.data.voluntaryUpdateList : null) ??
		migration.storyVoluntaryUpdateList;

	if (!updates.length) {
		return <Text size={Text.size.subheading} weight={Text.weight.semibold} text={t('story.updatesModal.empty')} />;
	}

	return (
		<div className={css.updates}>
			<Text size={Text.size.subheading} weight={Text.weight.semibold} text={t('story.updatesModal.title')} />

			<ul>
				{updates.map(update => (
					<li>
						<Text
							text={getTitle(update)}
							size={Text.size.label}
							weight={Text.weight.semibold}
							compact
							tag="p"
						/>
						<Text text={update.desc} size={Text.size.label} compact tag="p" />
					</li>
				))}
			</ul>

			<div className={css.actions}>
				<Button onClick={props.applyStoryVoluntaryUpdates} view="primary" size="medium">
					{t('story.updatesModal.updateBtn')}
				</Button>
				<Button
					size="medium"
					view="label-only"
					onClick={() => {
						migration.skipUpdate(updates);
						props.setModal();
					}}
				>
					{t('story.updatesModal.skipBtn')}
				</Button>
			</div>
		</div>
	);
};

function getTitle(update: VoluntaryUpdateList[number]) {
	const date = getFormattedTimestamp(update.timestamp, { time: false });
	const { ver } = update;

	return `${date} - v${ver}`;
}

const connector = connect(null, { setModal, applyStoryVoluntaryUpdates });

export default connector(Updates);
