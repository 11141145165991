import React from 'react';
import { Field, formValueSelector } from 'redux-form';
import type { SliderRangeProps } from 'antd/lib/slider';
import type { StorySettingsOfCard } from 'types/story';
import type { InjectedFormProps } from 'redux-form/lib/reduxForm';
import t from 'utils/translate';
import { CardFacade } from 'utils/facades/card-facade';
import { CountType } from 'utils/facades/navigation-card-facade';
import useToggle from 'common/components/useToggle';
import { useAdminSelector } from 'admin/reducers';
import { FORM_MODEL } from 'admin/constants/common';
import { Column, Grid } from 'admin/components/common/Grid';
import { NUM_SELECTED_ANSWERS, SCORE, CARD_TYPE } from 'common/constants';
import { SliderPure } from 'admin/components/common/Form/Slider';
import { TogglePure } from 'admin/components/common/Form/Toggle';
import { selectEditableCardId } from 'admin/reducers/card-editor/selectors';
import { CARD_NAME_MAX_LEN } from 'admin/components/common/Form/validations';
import { Hint, TextField, Toggle, Select } from 'admin/components/common/Form';
import { names, score, showResults, showCorrect, countType, answersMinMax, forceSubmitBtn, text } from './utils';
import css from './CardSettingsModal.scss';

const fieldProps = {
	theme: 'dark',
	isLabelUppercase: false,
} as const;

const countTypeOptions = [
	{ value: CountType.score, label: 'By score range' },
	{ value: CountType.character, label: 'By character points' },
];

const sliderMark = (value: number | string) => ({
	style: { color: 'var(--ra-color-white)' },
	label: value,
});

type RangeType = Parameters<Exclude<SliderRangeProps['onChange'], undefined>>[0];

const valuesSelector = formValueSelector(FORM_MODEL.EDIT_CARD_SETTINGS);

const MultipleAnswers = ({ formChange }: { formChange: InjectedFormProps['change'] }) => {
	const cardId = useAdminSelector(selectEditableCardId)!;
	const minmaxPath = names[answersMinMax](cardId);
	const formMinmax: StorySettingsOfCard['answersMinMax'] = useAdminSelector(s => valuesSelector(s, minmaxPath));
	const hasFormMinmax = Array.isArray(formMinmax);
	const isMultipleAnswers = useToggle(hasFormMinmax);

	const [range, setRange] = React.useState<RangeType | null>(
		isMultipleAnswers.value && hasFormMinmax
			? [formMinmax[0] ?? NUM_SELECTED_ANSWERS.MIN, formMinmax[1] ?? NUM_SELECTED_ANSWERS.MAX]
			: null
	);

	const forceSubmitPath = names[forceSubmitBtn](cardId);
	const forceSubmit: StorySettingsOfCard['forceSubmitBtn'] = useAdminSelector(
		s => isMultipleAnswers.value || valuesSelector(s, forceSubmitPath)
	);

	return (
		<>
			<Column colSpan="2" justifyContent="space-between" wrap="wrap" className={css.field}>
				<TogglePure
					switchProps={{
						onChange: value => {
							const DELETE = '';
							formChange(
								minmaxPath,
								value ? [NUM_SELECTED_ANSWERS.MIN, NUM_SELECTED_ANSWERS.MAX] : DELETE
							);
							setRange(value ? [NUM_SELECTED_ANSWERS.MIN, NUM_SELECTED_ANSWERS.MAX] : null);
							isMultipleAnswers.set(value);
						},
					}}
					checked={isMultipleAnswers.value}
					stretch
					alignItems="top"
					label={{
						children: (
							<div>
								<div>{text('answersMinMax')}</div>
								<Hint theme={fieldProps.theme} text={text('answersMinMaxHint')} weight="normal" />
							</div>
						),
					}}
				/>

				{isMultipleAnswers.value && range && (
					<SliderPure
						className={css.slider}
						sliderProps={{
							range: true,
							marks: {
								[NUM_SELECTED_ANSWERS.MIN]: sliderMark(`Min ${range[0]}`),
								[NUM_SELECTED_ANSWERS.MAX]: sliderMark(`Max ${range[1]}`),
							},
							tooltip: { open: false },
							onChange: setRange,
							onChangeComplete: ([min, max]: Exclude<SliderRangeProps['value'], undefined>) => {
								formChange(minmaxPath, [min, max]);
							},
							min: NUM_SELECTED_ANSWERS.MIN,
							max: NUM_SELECTED_ANSWERS.MAX,
						}}
						value={range}
					/>
				)}
			</Column>

			<Column colSpan="2" justifyContent="space-between" wrap="wrap" className={css.field}>
				<TogglePure
					switchProps={{
						onChange: value => formChange(forceSubmitPath, value),
						disabled: isMultipleAnswers.value,
					}}
					checked={forceSubmit}
					stretch
					alignItems="top"
					label={{ children: <div>{text('forceSubmitBtn')}</div> }}
				/>
			</Column>
		</>
	);
};

type GeneralViewProps = {
	card: CardFacade;
	formChange: InjectedFormProps['change'];
};

const GeneralView = ({ card: cardFacade, formChange }: GeneralViewProps) => (
	<>
		<Field
			{...fieldProps}
			className={css.field}
			type="text"
			name={names.name}
			component={TextField}
			label={t(`story.editCardModal.fields.${names.name}.label`)}
			limit={{ max: CARD_NAME_MAX_LEN }}
			isRequired
		/>
		<Grid columns="2">
			{cardFacade.hasFeature.score && (
				<>
					<Column className={css.field}>
						<Field
							{...fieldProps}
							type="number"
							name={names[score](cardFacade.cardId)}
							component={TextField}
							label={text('score')}
							isRequired
							min={SCORE.MIN}
							max={SCORE.MAX}
							placeholder={`${SCORE.DEFAULT}`}
						/>
					</Column>
					<Column justifyContent="flex-end" className={css.field}>
						<Field
							component={Toggle}
							name={names._setScoreForAll}
							props={{
								...fieldProps,
								className: css.scoreAll,
								stretch: true,
								alignItems: 'top',
								label: { children: <span style={{ fontWeight: 400 }}>{text('scoreAll')}</span> },
							}}
						/>
					</Column>
				</>
			)}

			{cardFacade.hasFeature.correct && cardFacade.type !== CARD_TYPE.SORTABLE_TRIVIA && (
				<Column colSpan="2" className={css.field}>
					<Field
						component={Toggle}
						name={names[showCorrect](cardFacade.cardId)}
						props={{
							...fieldProps,
							stretch: true,
							alignItems: 'top',
							className: css.showCorrect,
							label: {
								children: (
									<div>
										<div>{text(showCorrect)}</div>
										<Hint
											text={text('showCorrectHint')}
											theme={fieldProps.theme}
											weight="normal"
											className={css.hint}
										/>
									</div>
								),
							},
						}}
					/>
				</Column>
			)}

			{cardFacade.hasFeature.results && (
				<Column colSpan="2" className={css.field}>
					<Field
						component={Toggle}
						name={names[showResults](cardFacade.cardId)}
						props={{
							...fieldProps,
							stretch: true,
							alignItems: 'top',
							className: css.showResults,
							label: {
								children: (
									<div>
										<div>{text(showResults)}</div>
										<Hint
											text={text('showResultsHint')}
											theme={fieldProps.theme}
											weight="normal"
											className={css.hint}
										/>
									</div>
								),
							},
						}}
					/>
				</Column>
			)}

			{cardFacade.hasFeature.answerScore && cardFacade.type === CARD_TYPE.PERSONALITY_TEST && (
				<Column colSpan="2" justifyContent="space-between" className={css.field}>
					<Field
						className={css.countType}
						name={names[countType](cardFacade.cardId)}
						component={Select}
						props={{
							...fieldProps,
							options: countTypeOptions,
							defaultValue: countTypeOptions[0],
							inline: true,
							label: {
								children: (
									<div>
										<div>{text(countType)}</div>
										<Hint
											text={text('countTypeHint')}
											theme={fieldProps.theme}
											weight="normal"
											className={css.hint}
										/>
									</div>
								),
								component: 'span',
							},
						}}
					/>
				</Column>
			)}

			{cardFacade.hasFeature.multipleAnswer && cardFacade.type !== CARD_TYPE.SORTABLE_TRIVIA && (
				<MultipleAnswers formChange={formChange} />
			)}
		</Grid>
	</>
);

export default GeneralView;
