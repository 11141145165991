import _ from 'lodash';
import { StoryFacade } from 'utils/facades/story-facade';
import { STORY_EDITOR_SYNC } from 'admin/constants/actions';
import { FORM_MODEL } from 'admin/constants/common';
import { updateLatestStory } from 'admin/actions/story/update-latest-story';
import { updateStory } from 'admin/actions/story/update-story';

export function storyEditorSync() {
	return async (dispatch, getState) => {
		dispatch({ type: STORY_EDITOR_SYNC.SYNC });

		let error;

		try {
			const {
				form,
				version: { current: version },
			} = getState();
			const story = _.get(form, `${FORM_MODEL.EDIT_STORY}.values`);

			// FIXME: update main row only if needed and latest ver also, not always you need to update both of them
			const Story = new StoryFacade(story, version);
			await Promise.all([
				// update story main row (not versioned)
				dispatch(updateStory(Story.requestMainRowData, { getStoryOnComplete: false })),
				// update story data for certain version
				dispatch(updateLatestStory({ ...Story.requestData, getStoryOnComplete: false })),
			]);
		} catch (err) {
			error = err;
			dispatch({ type: STORY_EDITOR_SYNC.FAIL, payload: err });
			console.error(err);
		}

		if (!error) {
			dispatch({ type: STORY_EDITOR_SYNC.SUCCESS });
		}
	};
}
