import { Action } from 'redux';
import { AdminThunkAction } from 'admin/reducers';
import { IUserOrganization } from 'types/user';
import { setDefaultStoryDomain as setDefaultStoryDomainEndpoint } from 'admin/resources';
import { SET_DEFAULT_STORY_DOMAIN, SET_LOADING } from 'admin/constants/actions';

interface Params {
	storyId: string;
	useOrgDomain: boolean;
	domainId: IUserOrganization['domainId'];
}
export function setDefaultStoryDomain({
	storyId,
	domainId,
	useOrgDomain,
}: Params): AdminThunkAction<Action, IBackendBody<{ result: string }>> {
	return async (dispatch, getState) => {
		dispatch({ type: SET_DEFAULT_STORY_DOMAIN.UPDATE });
		dispatch({ type: SET_LOADING, payload: { [SET_DEFAULT_STORY_DOMAIN.UPDATE]: true } });

		try {
			const response = await setDefaultStoryDomainEndpoint.params({ storyId }).send({ domainId, useOrgDomain });
			const success = response?.statusText === 'OK';
			const payload = {
				success,
				domainId,
			};

			dispatch({ type: SET_DEFAULT_STORY_DOMAIN.SUCCESS, payload });
			dispatch({ type: SET_LOADING, payload: { [SET_DEFAULT_STORY_DOMAIN.UPDATE]: false } });
			return response;
		} catch (error) {
			dispatch({ type: SET_DEFAULT_STORY_DOMAIN.FAIL, payload: error });
			dispatch({ type: SET_LOADING, payload: { [SET_DEFAULT_STORY_DOMAIN.UPDATE]: false } });
			return (error as IBackendResponseError)?.response.body;
		}
	};
}
