import { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { selectEditableStoryId } from 'admin/reducers/story-editor/selectors';
import { selectUserInfo } from 'admin/reducers/user/selectors';
import PubnubFacade from 'common/utils/facades/pubnub-facade';
import { STORIES_PAGE } from 'admin/constants/routes';
import { setModal } from 'admin/actions/set-modal';
import { useAdminSelector, useAdminDispatch } from 'admin/reducers';
import { MODAL } from 'admin/constants/common';
import { last } from 'lodash';
import Pubnub from 'pubnub';

enum MessageType {
	join = 'story.join',
}

type PBMessage = Pubnub.FetchMessagesResponse['channels'][string][number];

interface JoinMessageI extends PBMessage {
	message: {
		type: MessageType.join;
		email: string;
		name: string;
	};
}

interface JoinMessageEventI extends Pubnub.MessageEvent {
	message: {
		type: MessageType.join;
		email: string;
		name: string;
	};
}

function isOccupiedBy(msg: PBMessage | undefined, uuid: string): msg is JoinMessageI {
	return msg !== undefined && msg?.message?.type === MessageType.join && msg.uuid !== uuid;
}

function isJoinMessageEvent(msgEvent: Pubnub.MessageEvent): msgEvent is JoinMessageEventI {
	return msgEvent !== undefined && msgEvent?.message?.type === MessageType.join;
}

const CoEditingObserver: FC = () => {
	const dispatch = useAdminDispatch();
	const [pubnub, setPubnub] = useState<PubnubFacade>();
	const { push: historyPush } = useHistory();
	const storyId = useAdminSelector(selectEditableStoryId);
	const { email, name = 'noname', id: userId } = useAdminSelector(selectUserInfo);
	const navigateToStories = useCallback(() => historyPush(STORIES_PAGE), [historyPush]);

	// initialize
	useEffect(() => {
		if (userId) {
			setPubnub(prev => {
				// end all open requests and close the PubNub instance.
				if (prev) prev.close();
				// initialize
				return new PubnubFacade({ uuid: userId });
			});
		}
	}, [userId, name, email]);

	// join/leave
	useEffect(() => {
		const channelName = storyId;

		if (channelName !== undefined) {
			// join/leave handlers
			const subscribeToStoryChannel = async (pubnubFacade: PubnubFacade) => {
				await pubnubFacade.publish({
					channel: channelName,
					message: { email, name, type: MessageType.join } as JoinMessageI['message'],
				});
				pubnubFacade.subscribe(channelName);
				pubnubFacade.addListeners({
					message(messageEvent: Pubnub.MessageEvent) {
						// force kick out
						if (
							isJoinMessageEvent(messageEvent) &&
							messageEvent.message.email &&
							messageEvent.message.email !== email
						) {
							navigateToStories();

							// Display the modal only after a location change to prevent automatic
							// closure by `ModalManager`
							setTimeout(() => {
								dispatch(
									setModal({
										id: MODAL.KICK_OUT_INFO_MODAL,
										data: {
											name: messageEvent.message.name,
											email: messageEvent.message.email,
										},
									})
								);
							}, 100);
						}
					},
				});
			};
			// attempt to join
			const joinToStoryChannel = async (pubnubFacade: PubnubFacade) => {
				const occupants = await pubnubFacade.getChannelOccupants(channelName);

				if (occupants?.length && occupants.some(occupant => occupant.uuid !== userId)) {
					const response = await pubnubFacade.fetchMessaged({ channels: [channelName], count: 1 });
					const channelMsgEvents = response.channels[channelName];
					const lastMsgEvent = last(channelMsgEvents);

					if (isOccupiedBy(lastMsgEvent, pubnubFacade.uuid)) {
						dispatch(
							setModal({
								id: MODAL.STORY_EDITORS_CONFLICT,
								data: {
									name: lastMsgEvent.message.name ?? 'noname',
									email: lastMsgEvent.message.email ?? 'noname',
									onOk: () => subscribeToStoryChannel(pubnubFacade),
									onCancel: navigateToStories,
								},
							})
						);

						return;
					}
				}

				await subscribeToStoryChannel(pubnubFacade);
			};

			if (pubnub) {
				joinToStoryChannel(pubnub);
			}
		}

		return () => {
			if (pubnub && channelName) {
				pubnub.removeListeners();
				pubnub.unsubscribe(channelName);
			}
		};
	}, [pubnub, userId, email, name, storyId, dispatch, navigateToStories]);

	return null;
};

export default CoEditingObserver;
