import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { AdminReducerState } from 'admin/reducers';

import Text from 'admin/components/common/Text';
import Button from 'admin/components/common/Button';
import { Column } from 'admin/components/common/Grid';
import { Errors } from 'admin/components/common/Form';
import { Modal, ModalBody, ModalFooter } from 'admin/components/common/Modal';
import { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';
import { MODAL } from 'admin/constants/common';
import t from 'utils/translate';
import { deleteMember as deleteMemberAction, logout } from 'admin/actions';

import css from './DeleteAccountModal.scss';

const mapStateToProps = ({ user }: AdminReducerState) => ({ user });

const mapDispatchToProps = {
	deleteMember: deleteMemberAction,
	logout,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type OwnProps = ModalManagerProvidedProps<MODAL.DELETE_USER_ACCOUNT>;

type State = {
	confirmPhrase: string;
	error: string;
};

type Props = OwnProps & ConnectedProps<typeof connector>;

class DeleteAccountModal extends Component<Props, State> {
	static DELETE_PHRASE = 'DELETE';

	public readonly state: State = {
		confirmPhrase: '',
		error: '',
	};

	renderTitle = () => (
		<Text
			size={Text.size.subtitle}
			weight={Text.weight.semibold}
			compact
			text={t('profile.deleteAccountModal.title')}
		/>
	);

	onOk = async () => {
		const res = await this.props.deleteMember();

		if (res.success) {
			this.props.logout();
			this.props.close();
		}

		this.setState({ error: res.errors?.[0].message || 'Something went wrong' });
	};

	onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ confirmPhrase: e.target.value, error: '' });
	};

	render() {
		const { close, open } = this.props;
		const { confirmPhrase, error } = this.state;

		return (
			<Modal
				onCancel={() => this.setState({ error: '', confirmPhrase: '' })}
				title={this.renderTitle()}
				open={open}
				destroyOnClose
				width="564px"
				className={css.modal}
			>
				<ModalBody className={css.body}>
					<Text tag="div" className={css.bodyText} size={Text.size.body}>
						{`${t('profile.deleteAccountModal.content')}: `}
						<Text size={Text.size.body} tag="span" weight={Text.weight.semibold}>
							{t('profile.deleteAccountModal.deletePhrase')}
						</Text>
					</Text>
					<input
						className={css.confirmInput}
						placeholder={DeleteAccountModal.DELETE_PHRASE}
						value={confirmPhrase}
						onChange={this.onInputChange}
					/>
					<Errors show={!!error}>{error}</Errors>
				</ModalBody>
				<ModalFooter>
					<Column justifyContent="flex-start">
						<Button view="primary" onClick={close}>
							{t('profile.deleteAccountModal.cancel')}
						</Button>
						<Button
							view="secondary-danger"
							disabled={confirmPhrase !== DeleteAccountModal.DELETE_PHRASE}
							onClick={this.onOk}
						>
							{t('profile.deleteAccountModal.delete')}
						</Button>
					</Column>
				</ModalFooter>
			</Modal>
		);
	}
}

export default connector(DeleteAccountModal);
