import produce from 'immer';
import { get, pick, set } from 'lodash';
import { ActionTypes as UndoActionTypes } from 'redux-undo';
import { actionTypes as FormActionTypes } from 'redux-form';
import { FORM_MODEL } from 'admin/constants/common';
import { GET_STORY, UPDATE_EDITABLE_CARD_DATA, UPDATE_EDITABLE_STORY } from 'admin/constants/actions';
import type { onMessageParams } from 'admin/middleware/state-sync-middleware';
import { storyEditorChange, storyEditorFormChange } from 'admin/actions/story/update-editable-story';

export default {
	channel: 'storycards.admin.story',
	whiteList: [
		/**
		 * Invoked: - get-story.ts
		 * Result:  - update "story-editor-reducer"
		 */
		GET_STORY.FULFILLED,
		/**
		 * Invoked: - update-editable-story.ts + story-editor-sync.ts;
		 *          - card-editor-sync.ts
		 * Result:  - update "story-editor-reducer"
		 */
		UPDATE_EDITABLE_STORY.UPDATE,
		/**
		 * Invoked: - update-editable-card-data.ts + card-editor-sync.ts
		 * Result:  - update "card-editor" reducer
		 *          - [optional] update "story-editor" reducer
		 */
		UPDATE_EDITABLE_CARD_DATA,
		/**
		 * Invoked: - actions "card-editor-undo-redo.ts"
		 * Result:  - update "card-editor" reducer
		 */
		UndoActionTypes.UNDO,
		UndoActionTypes.REDO,
	],
	blackList: [FormActionTypes.REGISTER_FIELD, FormActionTypes.UNREGISTER_FIELD],
	predicate: action => action.meta?.form === FORM_MODEL.EDIT_STORY,
	onMessage: (params: onMessageParams) => {
		const editableStoryId = params.state.storyEditor.story?.id;
		const editableCardId = params.state.cardEditor?.present?.data?._id;

		const senderStoryId = params.sender?.storyId;
		const senderCardId = params.sender?.cardId;

		const isSameStory =
			senderStoryId !== undefined && editableStoryId !== undefined && senderStoryId === editableStoryId;

		const action = {
			...produce(params.action, draftAction => {
				delete draftAction?.meta?.autoSync; // ignore auto save for subscribers
			}),
			__stateSync: true, // mark sync action
		};

		if (!isSameStory) {
			return;
		}

		// match same card editor
		if (senderCardId !== undefined && senderCardId === editableCardId) {
			params.dispatch(action);
			return;
		}

		// match different card editor
		if (senderCardId !== undefined && editableCardId !== undefined && senderCardId !== editableCardId) {
			// update card editor
			params.dispatch({
				type: UPDATE_EDITABLE_CARD_DATA,
				payload: pick(params.sender?.cardEditor, ['storyElements', 'storySettings', 'symbols']),
				__stateSync: true,
			});

			// update editable story
			updateStoryReducer(params);
		}

		// match from Editor to Flow
		if (senderCardId !== undefined && editableCardId === undefined) {
			// update editable story
			updateStoryReducer(params);
		}

		// match from Flow to Editor
		if (senderCardId === undefined && editableCardId !== undefined) {
			// note: story has been updated. what should be updated in editor? card name, what else?
			params.dispatch(action);
		}

		// match Flow to Flow
		if (senderCardId === undefined && editableCardId === undefined) {
			params.dispatch(action);
		}
	},
};

function updateStoryReducer(params: onMessageParams) {
	if (!params.state.storyEditor.story) {
		return;
	}

	const path = `storyVersions.${params.state.version.current}`;
	const story = produce(params.state.storyEditor.story, draftStory => {
		const { storySettings, storyElements, symbols, data: cardData, cardPath } = params.sender?.cardEditor!;

		if (storySettings) set(draftStory, `${path}.settings`, storySettings);
		if (storyElements) set(draftStory, `${path}.data.elements`, storyElements);
		if (symbols) set(draftStory, `${path}.data.symbols`, symbols);
		if (cardData) set(draftStory, cardPath, cardData);
	});
	const data = get(story, path);

	// update form.storyEditor
	params.dispatch({ ...storyEditorFormChange({ path, data }), __stateSync: true });
	// update storyEditor
	params.dispatch({ ...storyEditorChange({ path, data }), __stateSync: true });
}
