/* eslint-disable no-console */
import _ from 'lodash';
import React from 'react';
import { createPortal } from 'react-dom';
import { lruMemoize } from 'reselect';
import produce, { original, isDraft } from 'immer';
import type {
	BBCommonProps,
	BBInheritanceParent,
	BBModel,
	BBOtherProp,
	BBStates,
	BBStylesProp,
	ComputedComponentPropsFn,
	ComputedValueFn,
	EditableStateInfo,
} from 'types/story';
import { clientLog } from 'common/utils/helpers';
import { isSymbol, isSymbolChild } from 'common/utils/blocks/symbol';
import { getSymbolModel } from 'common/utils/blocks/get-symbol-model';
import { orderMediaQueryConfig } from 'common/utils/story-media-query';
import { getMediaKeysInOrderOfInheritance } from 'common/utils/get-media-keys-in-order-of-inheritance';
import {
	CARD_ROOT_ELEMENT_INDEX,
	COMPONENT_STATES,
	COMPONENT_TYPE,
	CONTENT_ALIGN_Y,
	PIN_DEFAULT,
} from 'common/constants';
import { UNIFORM_PROPS } from 'common/constants/component-props';
import { getElementZIndex } from 'client/components/pages/Story/get-element-z-index';
import TraverseTreeDataCollector from './traverse-tree-data-collector';
import type { CreateComponentProps, MapChildrenProps, TraverseTreeParams } from './traverse-tree-types';
import { generateElementCssString } from './generate-element-css-string';
import { TRAVERSE_TREE_TARGETS, CHILDREN_KEY, getStateDOMAttrs } from './common';
import { COMPONENTS } from './components';

const log = clientLog.extend('traverseTree');

type TreeComponentProps = Pick<
	BBCommonProps,
	| 'type'
	| '_id'
	| 'uiConfig'
	| 'mediaQuery'
	| 'currentMediaQuery'
	| 'isCardTree'
	| 'symbol'
	| 'getComputedComponentProps'
	| 'contentAlignY'
	| 'editableModeProps'
	| 'editorMode'
> & { key: string };

const getMediaQueryData = (options: Pick<TraverseTreeParams['options'], 'mediaQuery' | 'currentMediaQuery'>) => {
	const orderedMediaQueryConfig = orderMediaQueryConfig(options.mediaQuery, {
		defaultFirst: true,
		minOrder: 'asc',
		maxOrder: 'desc',
	});

	return {
		orderedMediaQueryConfig,
		mediaKeysInOrderOfInheritance: getMediaKeysInOrderOfInheritance(
			options.mediaQuery.defaultPlatform,
			options.mediaQuery.config[options.currentMediaQuery],
			orderedMediaQueryConfig
		),
	};
};

const getMediaQueryDataMemoized = lruMemoize(
	getMediaQueryData,
	function isEqual(a: Parameters<typeof getMediaQueryData>[0], b: Parameters<typeof getMediaQueryData>[0]) {
		return a.currentMediaQuery === b.currentMediaQuery;
	}
);

/**
 * Render whole tree
 */
export const traverseTree = ({ tree, options }: TraverseTreeParams) => {
	log('traverseTree map');

	const treeType = options.isCardTree ? 'card' : 'story';
	TraverseTreeDataCollector.reset(treeType);

	const treeCSS = { data: '' };
	const isEditor = options.target === TRAVERSE_TREE_TARGETS.EDITOR;
	const { mediaKeysInOrderOfInheritance, orderedMediaQueryConfig } = getMediaQueryDataMemoized({
		mediaQuery: options.mediaQuery,
		currentMediaQuery: options.currentMediaQuery,
	});

	/**
	 * @param element Card element
	 * @param index
	 * @param path path to children in 'Card.elements', like "0.children.1.children.0"
	 * @param idPath path to children in 'Card.elements' by uiConfig.nodeProps.id of element
	 * @param parent collection of parent elements with their properties, which are may be inherited.
	 * @param cssParent
	 * @param isFFC is float 1st level child
	 */
	function createComponent({ element, path, idPath, index, parent = [], cssParent, isFFC }: CreateComponentProps) {
		const { type, uiConfig, children } = element;

		const elementCSS = generateElementCssString({
			element,
			parent: cssParent,
			mediaQuery: options.mediaQuery,
			isCardTree: options.isCardTree,
			orderedMediaQueryArray: orderedMediaQueryConfig,
			originalTree: tree,
			storycardsDomain: options.storycardsDomain,
		});
		treeCSS.data += elementCSS.css;

		const wrappedGetComputedComponentProps = (state: BBStates) => {
			return getComputedComponentProps({ element, mediaKeysInOrderOfInheritance, state });
		};
		const isFloat = type === COMPONENT_TYPE.FLOAT_ABOVE || type === COMPONENT_TYPE.FLOAT_BELOW;
		const state = isEditor ? getElementState(options.state!, idPath) : COMPONENT_STATES.DEFAULT;
		const componentProps = wrappedGetComputedComponentProps(state);
		const props: TreeComponentProps = {
			type,
			key: element._id,
			_id: element._id,
			uiConfig: {
				...uiConfig,
				componentProps: componentProps.value,
				nodeProps: {
					...uiConfig.nodeProps,
					'data-bb': type,
					...(componentProps.value?.styles?.justifySelf === 'stretch' ? { 'data-stretch': 'x' } : null),
					...(componentProps.value?.styles?.alignSelf === 'stretch' ? { 'data-stretch': 'y' } : null),
					style:
						uiConfig.nodeProps.style?.zIndex === undefined
							? Object.assign(uiConfig.nodeProps.style ?? {}, {
									zIndex: getElementZIndex({
										element,
										index,
										parent,
										isCardTree: options.isCardTree,
										cardElements: options.cardElements,
										isFFC,
									}),
								})
							: uiConfig.nodeProps.style,
				},
			},
			mediaQuery: options.mediaQuery,
			currentMediaQuery: options.currentMediaQuery,
			isCardTree: options.isCardTree,
			editorMode: options.editorMode,
			symbol: element.symbol,
			getComputedComponentProps: wrappedGetComputedComponentProps,
		};

		/*
		 This is sort of "unicorn". Some props are assigned to the Card BB,
		  but should be used by Content BB (contentHeight, contentAlignY). consider about better solution.
		 */
		if (type === COMPONENT_TYPE.CONTENT) {
			props.contentAlignY = _.get(
				getComputedValue({
					source: tree[CARD_ROOT_ELEMENT_INDEX[COMPONENT_TYPE.CARD]].uiConfig.componentProps.other,
					sourceKey: 'other',
					mediaKeysInOrderOfInheritance,
					currState: state,
				}),
				'value.contentAlignY',
				CONTENT_ALIGN_Y.top
			);
		}

		const mapChildrenProps: MapChildrenProps = {
			isFFC: isFloat,
			children,
			path,
			idPath,
			cssParent: elementCSS.parent,
			// Create parent collection for a component children
			parent: produce(parent, draft => {
				const prevParent: BBInheritanceParent = {
					_id: props._id,
					path,
					type,
					name: uiConfig.editorProps.name,
					inheritance: { componentProps: componentProps.inheritance as any }, // todo: no any
				};

				draft.push(prevParent);
			}),
		};

		// todo: get rid of type assertion
		const Component = COMPONENTS[type]().view as React.ElementType<TreeComponentProps>;

		if (isEditor) {
			props.editableModeProps = {
				nodeProps: {
					'data-path': path,
					'data-pin': componentProps.value?.other?.pinType || PIN_DEFAULT,
					'data-lock': !uiConfig.editorProps.selectable,
				},
				eventListeners: {
					onMouseDown: options.onMouseDown,
					onFocus: options.onFocus,
					onMouseEnter: options.onMouseEnter,
					onMouseLeave: options.onMouseLeave,
					onMouseDownCapture: options.onMouseDownCapture,
					onDoubleClickCapture: options.onDoubleClickCapture,
				},
				inheritance: {
					componentProps: componentProps.inheritance,
					parent,
				},
			};

			// Assign state attr
			if (state !== COMPONENT_STATES.DEFAULT) {
				const { SELECTED_HOVER, SELECTED, HOVER } = COMPONENT_STATES;
				const compoundSelectors: BBStates[] = [SELECTED_HOVER];
				const isCompoundSelector = compoundSelectors.includes(state);

				if (isCompoundSelector) {
					// 💡If you're adding a new compound selector,
					// you've to add his implementation in "generateElementCssString()" and getComputedValue too
					if (state === SELECTED_HOVER) {
						_.assign(
							props.editableModeProps.nodeProps,
							getStateDOMAttrs({
								[SELECTED]: true,
								[HOVER]: true,
								[SELECTED_HOVER]: true,
							})
						);
					}
				} else {
					_.assign(props.editableModeProps.nodeProps, getStateDOMAttrs({ [state]: true }));
				}
			}
		}

		TraverseTreeDataCollector.add(treeType, {
			_id: props._id,
			type: props.type,
			symbol: props.symbol,
			uiConfig: props.uiConfig,
			path,
			editableModeProps: {
				nodeProps: props.editableModeProps?.nodeProps,
				inheritance: props.editableModeProps?.inheritance,
			},
		});

		return <Component {...props}>{mapChildren(mapChildrenProps)}</Component>;
	}

	function mapChildren({ children, path, idPath, parent, cssParent, isFFC }: MapChildrenProps): any {
		if (children && Array.isArray(children)) {
			return children.map(function map(elementOrigin, index) {
				const element =
					options.symbols && isSymbol(elementOrigin) && !isSymbolChild(elementOrigin)
						? getSymbolModel(elementOrigin, options.symbols)
						: elementOrigin;
				const nextPath = `${path}.${CHILDREN_KEY}.${index}`;
				const nextIdPath = `${idPath}.${getNodeId(element)}`;

				return createComponent({
					element,
					index,
					path: nextPath,
					idPath: nextIdPath,
					parent,
					cssParent,
					isFFC,
				});
			});
		}

		// Render text node "{ state: { platform: '<p>Woven silk pyjamas.</p>' } }"
		return getComputedValue({
			source: children,
			sourceKey: 'textChildren',
			mediaKeysInOrderOfInheritance,
			currState: COMPONENT_STATES.DEFAULT, // Always default, text cannot be changed by state
		}).value;
	}

	const componentsTree = tree.map((element: CreateComponentProps['element'], index: number) => {
		const path = `${index}`;
		const idPath = getNodeId(element);

		return createComponent({ element, index, path, idPath, parent: [], isFFC: false });
	});

	if (isEditor) {
		TraverseTreeDataCollector.sendToEditor();
	}

	return (
		<>
			{componentsTree}
			{createPortal(
				<style data-title={`storycards-${options.isCardTree ? 'card' : 'story'}-css`}>{treeCSS.data}</style>,
				document.head
			)}
		</>
	);
};

/**
 * Get value computed from component state and current media query
 *
 * @param props.source component object or component property value
 * @param props.sourceKey component property key
 * @param props.mediaKeysInOrderOfInheritance media query keys array in order of inheritance
 * @param props.currState current state key
 */
const getComputedValue: ComputedValueFn = props => {
	const { source, sourceKey = '', mediaKeysInOrderOfInheritance, currState } = props;
	const currentStates: BBStates[] = [];

	// 💡If you're adding a new compound state,
	// you've to add his implementation in "generateElementCssString()", and traverseTree too
	const compoundStates = [COMPONENT_STATES.SELECTED_HOVER] as BBStates[];
	if (compoundStates.includes(currState)) {
		switch (currState) {
			case COMPONENT_STATES.SELECTED_HOVER:
				currentStates.push(COMPONENT_STATES.SELECTED, COMPONENT_STATES.HOVER, COMPONENT_STATES.SELECTED_HOVER);
				break;
			default:
				break;
		}
	} else {
		currentStates.push(currState);
	}
	const orderedStates = _.uniq(_.concat(COMPONENT_STATES.DEFAULT, currentStates));

	let value: ReturnType<ComputedValueFn>['value'];
	let inheritance: ReturnType<ComputedValueFn>['inheritance'];
	const hasValue = (v: unknown) => v !== '' && v !== undefined && v !== null;

	if (sourceKey in UNIFORM_PROPS && source !== undefined) {
		// Universal properties (not written for state or media query and without inheritance)
		value = source;
	} else {
		const isOther = sourceKey === 'other';
		const isStyles = sourceKey === 'styles';
		const isAnimation = sourceKey === 'animation';
		const isTextChildren = sourceKey === 'textChildren';
		const canInherit = isStyles || isOther;
		const defaultValue = isStyles || isOther || isAnimation ? {} : undefined;

		_.forEach(orderedStates, state => {
			_.forEach(mediaKeysInOrderOfInheritance, platform => {
				const nextValue = _.get(source, [state, platform], defaultValue);

				if (
					(isTextChildren
						? nextValue === undefined /* accept empty string as valid value to inherit from */
						: !hasValue(nextValue)) ||
					(isAnimation && Object.entries(nextValue!).length === 0)
				) {
					return;
				}

				// log(`Update (${state})`, value, 'to', nextValue);

				// Regular properties (e.g. styles, other).
				// Checks each key in nextValue and store value and inheritance for it
				if (canInherit) {
					// initialize
					if (!value) value = {};

					_.forOwn(nextValue as BBStylesProp | BBOtherProp, (v, k) => {
						// initialize
						if (!inheritance) inheritance = {};

						// Store inheritance
						const ik = inheritance[k] ?? [];
						if (hasValue(v)) {
							ik.push({ secondValue: value[k], value: v, state, mq: platform });
						}
						_.set(inheritance, k, ik);

						// Store computed value
						_.set(value as object, k, v);
					});
				}
				// Irregular properties (e.g. animation or Text BB children).
				// Entire nextValue object should be inherited, not each key|value separately like in 'styles|other'
				else {
					// Store inheritance
					if (!inheritance) inheritance = [];

					if (hasValue(nextValue) && Array.isArray(inheritance)) {
						inheritance.push({
							secondValue: value,
							value: nextValue,
							state,
							mq: platform,
						});
					}

					// Store computed value
					value = nextValue;
				}
			});
		});
	}

	return {
		// @ts-ignore FIXME
		value,
		inheritance,
	};
};

/**
 * Get computed values of Card element "uiConfig.componentProps" object
 *
 * @param element Card element
 * @param state current state of the element
 * @param mediaKeysInOrderOfInheritance ordered story media query keys in order of inheritance
 */
export const getComputedComponentProps: ComputedComponentPropsFn = ({
	element,
	mediaKeysInOrderOfInheritance,
	state,
}) => {
	const inheritance: ReturnType<ComputedComponentPropsFn>['inheritance'] = {
		styles: {},
		other: {},
	};

	const componentProps = produce(element.uiConfig.componentProps, draft => {
		_.forOwn(draft, (value, key) => {
			const computedValue = getComputedValue({
				source: isDraft(value) ? original(value)! : value,
				// @ts-ignore FIXME
				sourceKey: isDraft(key) ? original(key)! : key,
				mediaKeysInOrderOfInheritance,
				currState: state,
			});

			// Store computed value
			draft[key] = computedValue.value;

			// Store inheritance data
			inheritance[key] = computedValue.inheritance;
		});
	});

	return {
		value: componentProps,
		inheritance,
	};
};

/**
 * @param state current state transmitted from admin panel
 * @param source info about element to which applied state
 * @param element building block (element) model
 * @param idPath
 */
function getElementState({ state, source }: EditableStateInfo, idPath = '') {
	if (idPath.indexOf(source.id) > -1) return state;
	return COMPONENT_STATES.DEFAULT;
}

function getNodeId(element: BBModel) {
	return element.uiConfig.nodeProps.id;
}
