import React from 'react';
import { Field } from 'redux-form';

import t from 'utils/translate';
import { prependUrlProtocol } from 'utils/helpers';
import { SEO_TEXT_MAX_LEN, SHARE_TEXT_MAX_LEN } from 'admin/components/common/Form/validations';
import { TextField, MediaField } from 'admin/components/common/Form';
import Text from 'admin/components/common/Text';
import { Grid, Column } from 'admin/components/common/Grid';
import type { SettingsTabsGeneralProps } from '../types';
import css from './Seo.scss';

const Seo: React.FC<SettingsTabsGeneralProps> = props => {
	const { version, fields, story, postGallery } = props;
	const storyId = story.id;

	const onMediaFieldUpload = React.useCallback(
		async (data: { asset: File; name: string }) => {
			const result = await postGallery({ asset: data.asset, storyId });

			return result.result?.asset ? { asset: prependUrlProtocol(result.result.asset, 'https') } : false;
		},
		[storyId, postGallery]
	);

	return (
		<Grid columns="14" columnGap={Grid.gap.large}>
			<Column colSpan="14">
				<Text
					tag="div"
					size="subheading"
					weight="bold"
					className={css.title}
					text={t('story.settings.seoSettings')}
				/>
				<section className={css.section}>
					<div className={css.sectionField}>
						<Field
							name={fields.seoTitle.name(version)}
							autoComplete="off"
							component={TextField}
							label="Meta Title"
							theme="dark"
							isLabelUppercase={false}
							placeholder="My incredible story"
							limit={{ max: SEO_TEXT_MAX_LEN }}
							isRequired
						/>
					</div>

					<div className={css.sectionField}>
						<Field
							name={fields.seoDescription.name(version)}
							autoComplete="off"
							component={TextField}
							label="Meta Description"
							theme="dark"
							isLabelUppercase={false}
							limit={{ max: SEO_TEXT_MAX_LEN }}
							isRequired
						/>
					</div>
					<div className={css.sectionField}>
						<Field
							name={fields.keywords.name(version)}
							autoComplete="off"
							component={TextField}
							label="Keywords"
							theme="dark"
							isLabelUppercase={false}
							limit={{ max: SEO_TEXT_MAX_LEN }}
							isRequired
						/>
					</div>
				</section>
				<Text
					tag="div"
					size="subheading"
					weight="bold"
					className={css.title}
					text={t('story.settings.shareSettings')}
				/>
				<section className={css.section}>
					<div className={css.sectionField}>
						<Field
							name={fields.shareTitle.name(version)}
							autoComplete="off"
							component={TextField}
							label="Share Title"
							theme="dark"
							isLabelUppercase={false}
							placeholder="My incredible story"
							limit={{ max: SHARE_TEXT_MAX_LEN }}
							isRequired
						/>
					</div>

					<div className={css.sectionField}>
						<Field
							name={fields.shareDescription.name(version)}
							autoComplete="off"
							component={TextField}
							label="Share Description"
							theme="dark"
							isLabelUppercase={false}
							limit={{ max: SHARE_TEXT_MAX_LEN }}
							isRequired
						/>
					</div>
					<div className={css.sectionField}>
						<Field
							name={fields.shareImage.name(version)}
							component={MediaField}
							label="Share Image"
							theme="dark"
							size="large"
							fileType={MediaField.fileTypes.PNG_JPG}
							isLabelUppercase={false}
							previewSize="contain"
							description="Browse your image"
							fileDimensions={{ maxW: 1200, maxH: 630, minW: 200, minH: 200 }}
							eventListeners={{ onUpload: onMediaFieldUpload }}
							isRequired
						/>
					</div>
				</section>
			</Column>
		</Grid>
	);
};

export default Seo;
