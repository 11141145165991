import * as React from 'react';
import cn from 'classnames';

import { Icon } from 'admin/components/common/Icon';

import css from './Search.scss';

type Props = {
	className?: string;
	onClear?: () => void;
	onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	placeholder?: string;
	value: string;
};

export const Search: React.FC<Props> = ({
	className = '',
	value,
	onClear = null,
	onSearchChange,
	placeholder = 'Search...',
}) => {
	return (
		<div className={cn(css.search, className)}>
			<div className={css.searchInputWrap}>
				<input type="text" value={value} placeholder={placeholder} onChange={onSearchChange} />
				<button className={css.searchBtn} type="button">
					<Icon className={css.searchIcon} type="search" />
				</button>
				{onClear && <button className={css.clearBtn} onClick={onClear} type="button" />}
			</div>
		</div>
	);
};
