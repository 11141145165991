import React, { useCallback, useRef, useState, useEffect } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { noop } from 'lodash';

import { notNumber } from 'common/utils/regexp';
import { Icon, IconType } from 'admin/components/common/Icon';
import { Errors } from 'admin/components/common/Form/Errors';
import { selectEditableElement } from 'admin/reducers/card-editor-extra/selectors';
import { InspectorTypes } from 'admin/components/pages/Story/CardEditor/Inspector/types';
import { ReduxFieldInputTypes, ReduxFieldMetaTypes } from 'admin/components/common/Form/utils';
import DragController from './DragController';
import { minMax } from './helpers';
import {
	parsePropFieldValue,
	selectedNodeData,
	updateElementInlineStyle,
	KEYS,
	setInlineStyle,
	parseValid,
	getUnit,
} from './utils';
import css from './NumberField.scss';

export enum NumberFieldView {
	highlight = 'highlight',
}

export type PFNumberProps = {
	input: ReduxFieldInputTypes;
	meta: ReduxFieldMetaTypes;
	className?: string;
	autoSelect?: boolean;
	dragStep?: number;
	step?: number;
	shiftStep?: number;
	unit?: boolean;
	decimals?: number;
	min?: number;
	max?: number;
	placeholder?: string;
	showErrorsOn?: string | boolean;
	forcedValue?: string;
	eventListeners?: ReturnType<InspectorTypes.GetFieldListeners>;
	icon?: IconType;
	disabled?: boolean;
	numberView?: NumberFieldView;
	boundWithFields?: string[];
};

/**
 * Component to work with a numeric values in Inspector.
 *
 * @info User should be able to type freely, but save will be triggered only `onBlur` and with a parsed valid value
 */
export const NumberField: React.FC<PFNumberProps> = ({
	className,
	autoSelect = true,
	placeholder,
	decimals = 0,
	unit,
	min,
	max,
	step = 1,
	shiftStep = 10,
	dragStep,
	showErrorsOn = 'touched',
	forcedValue,
	eventListeners = {},
	icon,
	disabled = false,
	input,
	meta,
	numberView,
	// list of fields that should be updated together with an active field and with the same value
	boundWithFields,
}) => {
	const element = useSelector(selectEditableElement);
	const [editableValue, setEditableValue] = useState<string | undefined>();
	const editableData = useRef<ReturnType<typeof selectedNodeData>>(null);
	const isDirty = useRef(false); // is value has been changed
	/* use own "active" instead of "meta.active". Meta value may be erased on form re-initialize, which happens on save.
	Usually "meta.active" is incorrect on navigation between fields on "tab" key press */
	const [active, setActive] = useState(false);
	const inputRef = useRef<HTMLInputElement | null>(null);
	const id = `${meta.form}.${input.name}`;
	const { value, name } = input;
	const nodeId = element.uiConfig.nodeProps.id;

	const handleSave = useCallback(
		(draftTarget: { name: string; value: string }) => {
			const currentValue = parseValid({ value: draftTarget.value, min, max, decimals });
			draftTarget.value = `${currentValue}`;
			setEditableValue(draftTarget.value);
			return eventListeners?.onChange?.({ target: draftTarget });
		},
		[eventListeners, min, max, decimals]
	);

	useEffect(
		function reset() {
			return () => {
				const node = editableData.current?.node;
				const property = editableData.current?.property;
				if (node && property && isDirty.current) {
					property.forEach(prop => updateElementInlineStyle({ node, property: prop, value: '' }));
					editableData.current = null;
					isDirty.current = false;
				}
			};
		},
		[element]
	);

	return (
		<div
			className={cn(css.pfNumber, className, {
				[css.disabled]: disabled,
				[css[`view-${numberView}`]]: numberView !== undefined,
			})}
		>
			{icon && <Icon className={css.icon} type={icon} />}
			{forcedValue ? (
				<input type="text" className={css.pfInput} value={forcedValue} onChange={noop} />
			) : (
				<input
					id={id}
					type="text"
					placeholder={placeholder}
					{...input}
					value={active ? editableValue : input.value}
					className={css.pfInput}
					onFocus={event => {
						editableData.current = selectedNodeData({ id: nodeId, name, boundWithFields });
						setEditableValue(value);
						setActive(true);
						if (autoSelect) event.target.select();
						input.onFocus(event);
					}}
					onChange={event => {
						if (notNumber.test(event.target.value)) return;

						isDirty.current = true;

						editableData.current?.property.forEach(property => {
							if (editableData.current?.node) {
								setInlineStyle({
									/* replace no value(empty string) with a "0". after the value has been deleted,
									 input placeholder displays "0", so the value is expected to be a "0" as well */
									value: event.target.value || '0',
									min,
									max,
									decimals,
									unit,
									node: editableData.current.node,
									property,
								});
							}
						});

						setEditableValue(event.target.value);
					}}
					onBlur={async event => {
						const finalValue = parsePropFieldValue({
							value: editableValue,
							property: editableData.current?.property[0] ?? '',
						});
						const isValueChanged =
							editableValue !== undefined && `${finalValue ?? ''}` !== `${meta.initial ?? ''}`;
						if (isValueChanged) await handleSave({ name, value: editableValue });
						input.onBlur(event);
						setActive(false);
						setEditableValue(undefined);
					}}
					onKeyDown={event => {
						const { key, shiftKey } = event;

						if ([KEYS.ARROW_UP, KEYS.ARROW_DOWN, KEYS.UP, KEYS.DOWN].includes(key)) {
							event.preventDefault();
							const isUp = [KEYS.ARROW_UP, KEYS.UP].includes(key);
							const calculatedStep = (shiftKey ? shiftStep : step) || 1;
							const currNumber = parseFloat(`${editableValue}`) || 0;
							const nextValue = minMax({
								value: +(isUp ? currNumber + calculatedStep : currNumber - calculatedStep).toFixed(
									decimals
								),
								min,
								max,
							}).toString();

							isDirty.current = true;

							editableData.current?.property.forEach(property => {
								if (editableData.current?.node) {
									setInlineStyle({
										value: nextValue,
										min,
										max,
										decimals,
										unit,
										node: editableData.current.node,
										property,
									});
								}
							});

							setEditableValue(nextValue);
						} else if (key === KEYS.ENTER) {
							event.currentTarget.blur();
						}
					}}
					ref={inputRef}
					autoComplete="off"
					draggable={false}
					disabled={disabled}
				/>
			)}
			{inputRef.current !== null && active && editableValue !== undefined && (
				<DragController
					dragStep={dragStep}
					decimals={decimals}
					step={step}
					max={max}
					min={min}
					unit={getUnit(unit)}
					inputRef={inputRef}
					editableValue={editableValue}
					editableData={editableData.current!}
					setEditableValue={setEditableValue}
				/>
			)}
			{typeof showErrorsOn === 'string' && <Errors show={meta[showErrorsOn]}>{meta.error}</Errors>}
		</div>
	);
};

/*
 Example:
	<Field
		name="size"
		component={NumberField}
		validate={[required]}
		className="--custom-class"
		placeholder="size"
		showErrorsOn="touched"
		autoSelect
	/>
*/
