import React from 'react';
import { CSSTransitionProps } from 'react-transition-group/CSSTransition';
import { CSSTransition as ReactCSSTransition } from 'react-transition-group';
import './Transition.scss';

const Container = props => <span style={{ display: 'inline-block' }} {...props} />;

type Props = { container?: boolean } & (
	| CSSTransitionProps
	| ({
			classNames?: 'fade' | 'scale' | 'scale-center' | 'scale-center-y';
	  } & Omit<CSSTransitionProps, 'classNames' | 'timeout'>)
);

const CSSTransition: React.FC<Props> = ({
	children,
	container = false,
	classNames = 'fade',
	mountOnEnter = true,
	unmountOnExit = true,
	timeout = { enter: 350, exit: 300 },
	...props
}: React.ComponentProps<typeof CSSTransition>) => (
	<ReactCSSTransition<undefined>
		{...props}
		timeout={timeout}
		mountOnEnter={mountOnEnter}
		unmountOnExit={unmountOnExit}
		classNames={classNames}
	>
		{container ? <Container>{children}</Container> : children}
	</ReactCSSTransition>
);

export default React.memo(CSSTransition);
