import React, { useEffect, useState } from 'react';
import Button from 'admin/components/common/Button';
import useLoadScript from 'common/components/useLoadScript';

const GCP_PROJECT_NUMBER = '548380376521';

type Props = {
	token: string;
	onChange: (params: { fileId: string }) => Promise<void>;
};

const FilePicker: React.FC<Props> = props => {
	const [isReady, setIsReady] = useState(false);
	const gsiStatus = useLoadScript('https://accounts.google.com/gsi/client');
	const gapiStatus = useLoadScript('https://apis.google.com/js/api.js');
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (gsiStatus === 'loaded' && gapiStatus === 'loaded') {
			window.gapi.load('client:picker', async () => {
				await gapi.client.load('https://www.googleapis.com/discovery/v1/apis/drive/v3/rest');
				setIsReady(true);
			});
		}
	}, [gsiStatus, gapiStatus]);

	const createPicker = () => {
		const picker = new google.picker.PickerBuilder()
			.setAppId(GCP_PROJECT_NUMBER)
			.enableFeature(google.picker.Feature.NAV_HIDDEN)
			.setOAuthToken(props.token)
			.addView(google.picker.ViewId.SPREADSHEETS)
			.addView(new google.picker.DocsUploadView())
			.setCallback(async response => {
				setLoading(true);
				if (response[google.picker.Response.ACTION] === google.picker.Action.PICKED) {
					const document = response[google.picker.Response.DOCUMENTS][0];
					const fileId = document[google.picker.Document.ID];

					await props.onChange({ fileId });
				}
				setLoading(false);
			})
			.build();

		picker.setVisible(true);
	};

	return isReady ? (
		<>
			<Button view="primary" size="medium" onClick={createPicker} loading={loading}>
				Connect spreadsheet
			</Button>
		</>
	) : null;
};

export default FilePicker;
