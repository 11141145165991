import { StorySettingsType } from 'types/story';
import { SET_STORY_SETTINGS } from 'client/constants/actions';

/**
 * @param payload {Object} Story settings object (full or partial state)
 * @return {{type: *, payload: *}}
 */
export function setStorySettings(payload: StorySettingsType) {
	return {
		type: SET_STORY_SETTINGS,
		payload,
	};
}
