import React, { useContext, useState, useMemo } from 'react';

import { MODAL } from 'admin/constants/common';
import cn from 'classnames';
import Button from 'admin/components/common/Button';
import { Icon } from 'admin/components/common/Icon';
import { ModalBody } from 'admin/components/common/Modal';
import { generateText } from 'admin/actions/story/ai/generate-text';
import { useAdminDispatch } from 'admin/reducers';
import { TextAreaPure } from 'admin/components/common/Form/TextArea';
import { Context as ThemeContext } from 'admin/components/common/Theme';
import type { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';
import type { InspectorContextType } from 'admin/components/pages/Story/CardEditor/Inspector/InspectorContext';
import { showSelectOptionsModal } from '../../../FieldSettings/EditSelectOptions';
import { generateOptionsFromString } from '../../../FieldSettings/EditSelectOptions/ModalContent';
import PromptHistory from './PromptHistory';
import css from '../EditorAIModal.scss';

export type GeneratorSelectOptionsModalData = {
	view: 'selectOptions';
	onSelectOptionsChange: InspectorContextType['onUpdate'];
};

const text = {
	submitBtn: 'Generate',
};

type Props = ModalManagerProvidedProps<MODAL.EDITOR_AI> & {
	setIsBusy: React.Dispatch<React.SetStateAction<boolean>>;
};

const GenerateSelectOptions: React.FC<Props> = props => {
	const dispatch = useAdminDispatch();
	const { theme } = useContext(ThemeContext);
	const [userPrompt, setUserPrompt] = useState('');
	const promptPh = useMemo(() => {
		const items = [
			'List the top 100 richest people now.',
			'List the top 10 most popular books of 2023.',
			'Show the 5 best smartphones available now.',
			'Name 20 must-visit cities around the world.',
			'Provide the top 15 healthiest foods to eat daily.',
		];
		const index = Math.floor(Math.random() * items.length);
		return items[index];
	}, []);

	const onSubmit = async () => {
		props.setIsBusy(true);

		const payload: Parameters<typeof generateText>[0] = {
			prompt: userPrompt,
			action: 'list',
		};

		const generatedText = await dispatch(generateText(payload));

		if (generatedText.success && generatedText.result && 'onSelectOptionsChange' in props.data) {
			showSelectOptionsModal({
				dispatch,
				onUpdate: props.data.onSelectOptionsChange,
				generatedOptions: generateOptionsFromString(generatedText.result),
			});
		} else {
			props.close();
		}
	};

	return (
		<ModalBody>
			<div className={cn(css.fields, css.view1)}>
				<TextAreaPure
					placeholder={promptPh}
					rows={3}
					theme={theme}
					autoFocus
					value={userPrompt}
					onChange={e => setUserPrompt(e.target.value)}
				/>
				<div className={css.fieldsFooter}>
					<Button
						className={css.btnSubmit}
						onClick={onSubmit}
						disabled={userPrompt.trim().length < 2}
						view="primary"
						theme={theme}
					>
						<Icon type="sc-magic" width={26} style={{ marginRight: 6 }} />
						{text.submitBtn}
					</Button>
				</div>
			</div>
			<PromptHistory type="text" onSelect={setUserPrompt} />
		</ModalBody>
	);
};

export default GenerateSelectOptions;
