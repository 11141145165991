import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { routes } from 'admin/constants';
import RouterSwitch from 'admin/components/common/RouterSwitch';
import { TransitionSwitch } from 'admin/components/common/TransitionSwitch';

const General = React.lazy(() => import('./General'));
const Members = React.lazy(() => import('./Members'));
const Teams = React.lazy(() => import('./Teams'));
const Domains = React.lazy(() => import('./Domains'));
const Team = React.lazy(() => import('./Team'));
const Billing = React.lazy(() => import('./Billing'));
const Integration = React.lazy(() => import('./Integration'));
const Fonts = React.lazy(() => import('./Fonts'));

const Settings = () => (
	<RouterSwitch>
		<Route path={routes.SETTINGS_BILLING_PAGE} component={Billing} />

		<TransitionSwitch>
			{(status, location) => (
				<React.Suspense fallback={null}>
					<RouterSwitch location={location}>
						<Route exact path={routes.SETTINGS_MEMBERS_PAGE} component={Members} />
						<Route exact path={routes.SETTINGS_TEAMS_PAGE} component={Teams} />
						<Route exact path={routes.SETTINGS_DOMAINS_PAGE} component={Domains} />
						<Route path={routes.SETTINGS_GENERAL_PAGE} component={General} />
						<Route path={routes.SETTINGS_TEAM_PAGE} component={Team} />
						<Route path={routes.SETTINGS_BILLING_PAGE} component={Billing} />
						<Route path={routes.SETTINGS_INTEGRATION_PAGE} component={Integration} />
						<Route path={routes.SETTINGS_FONTS_PAGE} component={Fonts} />

						<Redirect to={routes.SETTINGS_GENERAL_PAGE} />
					</RouterSwitch>
				</React.Suspense>
			)}
		</TransitionSwitch>
	</RouterSwitch>
);

export default Settings;
