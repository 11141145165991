import { divide } from 'lodash';
import React from 'react';
import classnames from 'classnames';

import { SCORE } from 'common/constants';

import Button from 'admin/components/common/Button';
import { Icon } from 'admin/components/common/Icon';

import { ScoreRange } from './utils';
import css from './RangeSlider.scss';

const { MIN: MIN_LIMIT, MIN_RANGE_SIZE: MIN_RANGE } = SCORE.RANGE;

type Props = {
	range: ScoreRange;
	ranges: ScoreRange[];
	onChange: (value: ScoreRange[]) => void;
};

/**
 * Split a range for 2 where are second is a new
 * @constructor
 */
const ScoreRangeSplitButton: React.FC<Props> = ({ ranges, range, onChange }) => {
	const isDisabled = range.max - range.min < MIN_RANGE * 2 - (range.min === MIN_LIMIT ? 0 : 1);

	return (
		<Button
			title="Split a range for 2 where are second is a new"
			view="empty"
			color="primary"
			shape="circle"
			size="tiny"
			disabled={isDisabled}
			className={classnames(css.btn, css.btnAdd)}
			onClick={() => {
				const newRangeLength = Math.floor(divide(range.max - range.min, 2));
				const nextRanges = ranges.reduce<ScoreRange[]>((acc, item) => {
					if (item._id === range._id) {
						const rangeId = +Date.now();
						const r1 = { ...item, max: item.min + newRangeLength };
						const r2 = { _id: `${rangeId}`, min: r1.max + 1, max: item.max };
						acc.push(r1, r2);
					} else {
						acc.push(item);
					}
					return acc;
				}, []);

				onChange(nextRanges);
			}}
		>
			<Icon type="plus-rounded" color="currentColor" />
		</Button>
	);
};

export default ScoreRangeSplitButton;
