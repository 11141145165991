import { shuffle } from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import type { SelectBBOption } from 'types/story';
import { SelectBBOptionsOrder } from 'client/components/common/BuildingBlocks/Fields/constants';

const getDropdownStyle = (isMobile: boolean) =>
	(isMobile
		? {
				position: 'fixed',
				top: 0,
				left: 0,
				bottom: 0,
				right: 0,
				width: '100%',
				maxHeight: `${window.visualViewport?.height || window.innerHeight}px`,
				zIndex: 9999,
			}
		: {}) satisfies React.CSSProperties;

export const useDropdownStyle = ({ isMobile }: { isMobile: boolean }) => {
	const [style, setStyle] = useState(getDropdownStyle(isMobile));

	useEffect(() => {
		const listener = () => setStyle(getDropdownStyle(isMobile));
		window.visualViewport?.addEventListener('resize', listener);
		return () => {
			window.visualViewport?.removeEventListener('resize', listener);
		};
	}, [isMobile]);

	return style;
};

export const useMobileScroll = (props: { isMobile: boolean; isDropdownOpen: boolean }) => {
	const lastPageScroll = useRef([document.documentElement.scrollLeft, document.documentElement.scrollTop]);
	const isEnabled = props.isMobile && props.isDropdownOpen;

	useEffect(() => {
		const style = document.createElement('style');
		style.innerHTML = 'html, body { overflow: hidden !important; height: 100% !important } #root { opacity: 0 }';

		const scrollListener = () => {
			document.documentElement.scrollTop = 0;
		};

		if (isEnabled) {
			lastPageScroll.current = [document.documentElement.scrollLeft, document.documentElement.scrollTop];
			document.body.appendChild(style);
			window.addEventListener('scroll', scrollListener);
		}

		return () => {
			if (isEnabled) {
				document.body.removeChild(style);
				window.removeEventListener('scroll', scrollListener);
				window.scrollTo(lastPageScroll.current[0], lastPageScroll.current[1]);
			}
		};
	}, [isEnabled]);

	return null;
};

export const getOrderedOptions = (options: SelectBBOption[], order: SelectBBOptionsOrder) => {
	switch (order) {
		case SelectBBOptionsOrder.alphabet:
			return options.sort((a, b) =>
				a.label.localeCompare(b.label, navigator.languages[0] || navigator.language, {
					numeric: true,
					ignorePunctuation: true,
				})
			);
		case SelectBBOptionsOrder.random:
			return shuffle(options);
		default:
			return options;
	}
};
