import { Param } from './types';

export const arrayToSearchString = (arr: Param[]): string => {
	if (arr.length === 0) {
		return '';
	}

	return arr.reduce((acc, item, index) => {
		const encodedName = encodeURIComponent(item.name);
		const encodedValue = encodeURIComponent(item.value);
		const separator = index === 0 ? '?' : '&';
		return `${acc}${separator}${encodedName}=${encodedValue}`;
	}, '');
};

// get collection of url search params key value pair from url string
export const stringToArray = (str: string): Param[] => {
	const searchParamsStartIndex = str.indexOf('?');
	const search = searchParamsStartIndex >= 0 ? str.slice(searchParamsStartIndex + 1) : str;
	const paramsArr = searchParamsStartIndex >= 0 ? search.split('&') : [];

	return paramsArr.reduce((acc: Param[], item) => {
		if (item) {
			const [name, value] = item.split('=');
			acc.push({
				name: decodeURIComponent(name),
				value: decodeURIComponent(value),
			});
		}
		return acc;
	}, []);
};
