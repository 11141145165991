import * as React from 'react';
import cn from 'classnames';
import _ from 'lodash';

import { UploadedFont } from 'src/types';

import t from 'common/utils/translate';
import { FONT_FILE_TYPES } from 'utils/assets';
import { getFontFileMetadata } from 'admin/utils/get-font-file-metadata';
import Text from 'admin/components/common/Text';
import { InitialState } from 'admin/components/pages/Settings/Fonts/AddFontsModal/reducer';

import css from './UploadCustomFontBtn.scss';

export type OnUploadParams = Pick<InitialState['uploadedFonts'], 'error' | 'items'>;

type Props = {
	className?: string;
	notAllowed: boolean;
	onFilesUpload: (params: OnUploadParams) => void;
};

export const UploadCustomFontBtn: React.FC<Props> = ({ notAllowed, onFilesUpload: onUpload, className = '' }) => {
	const onFilesUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const { files } = e.target;
		const promises: Promise<UploadedFont>[] = [];
		let isWrongFileType = false;

		if (_.size(files) !== 0) {
			_.forEach(files, (item: File) => {
				const filename = item.name.toLowerCase();
				const ext = filename.split('.').pop() || 'unknown';

				if (!FONT_FILE_TYPES.includes(ext)) {
					isWrongFileType = true;
				} else {
					promises.push(getFontFileMetadata(item));
				}
			});

			if (isWrongFileType) {
				onUpload({ error: { msg: 'Wrong file type' }, items: null });
			} else {
				const results = await Promise.all(promises);

				onUpload({ error: null, items: results });
			}

			_.set(e, 'target.value', null);
		}
	};

	return (
		<div className={cn(css.uploadCustomFontBtn, className)}>
			<label htmlFor="upload">
				<input
					type="file"
					id="upload"
					multiple
					onChange={onFilesUpload}
					accept={FONT_FILE_TYPES.map(ext => `.${ext}`).join(',') || undefined}
					style={{ display: 'none' }}
				/>
				<button className={css.uploadBtn} type="button">
					<Text size="subheading" weight="semibold">
						{t('settings.pages.fonts.uploadFontBtnLabel')}
					</Text>
				</button>
			</label>
		</div>
	);
};
