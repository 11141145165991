import { find, size, trim, noop } from 'lodash';
import React, { ReactNode, useRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { FormSubmitHandler, InjectedFormProps, reduxForm, getFormValues, getFormSyncErrors } from 'redux-form';
import type { StoryMediaPlatform, StoryModel, StoryTemplateWithData, StoryTemplateWithoutData } from 'types/story';
import t from 'common/utils/translate';
import type { AdminReducerState } from 'admin/reducers';
import { DEFAULT_MEDIA_QUERY_PLATFORMS } from 'common/constants';
import { useClickOutside } from 'common/components/useClickOutside';
import { FORM_MODEL, LANG_OPTIONS } from 'admin/constants/common';
import { selectActiveOrganization } from 'admin/reducers/user/selectors';
import { STORY_NAME_MIN_LEN } from 'admin/components/common/Form/validations';

export const fields = {
	name: 'name',
	lang: 'lang',
	type: 'type',
	defaultPlatform: 'defaultPlatform',
	contentType: 'contentType',
	clone: 'clone',
	// contentPrompt: 'contentPrompt',
};

export type FormValues = typeof fields & {
	type: Exclude<StoryModel['type'], null>;
	defaultPlatform: StoryMediaPlatform;
};

export type CreateStoryFromTemplateParams = { template: StoryTemplateWithoutData['id'] | StoryTemplateWithData };

export type CreateStoryFromScratchParams = { type: FormValues['type'] };

export enum StoryContentType {
	static = 'static',
	generic = 'generic',
}

const formValues = getFormValues(FORM_MODEL.CREATE_STORY);

const syncErrors = getFormSyncErrors(FORM_MODEL.CREATE_STORY);

const mapStateToProps = (state: AdminReducerState) => {
	const defaultStoryLang = selectActiveOrganization(state)?.metadata?.storyLang;

	return {
		initialValues: {
			[fields.name]: '',
			[fields.type]: '',
			[fields.lang]: find(LANG_OPTIONS, { value: defaultStoryLang }) ? defaultStoryLang : LANG_OPTIONS[0].value,
			[fields.defaultPlatform]: DEFAULT_MEDIA_QUERY_PLATFORMS.DESKTOP,
			[fields.contentType]: StoryContentType.static,
			[fields.clone]: '',
		},
		formValues: formValues(state) as FormValues,
		formErrors: syncErrors(state) as FormValues,
	};
};

const connector = connect(mapStateToProps);

export type ReduxFormProps = InjectedFormProps<FormValues, PureProps>;

type OwnProps = {
	className?: string;
	onSubmit?: FormSubmitHandler<FormValues, OwnProps>;
	onClickOutside?: () => void;
	children?: (props: Omit<Props, 'className' | 'children' | 'onSubmit' | 'onFormClick'>) => ReactNode;
};

type PureProps = ConnectedProps<typeof connector> & OwnProps;

type Props = PureProps & ReduxFormProps;

const Form = ({ className = '', children, onSubmit, onClickOutside, ...props }: Props) => {
	const formRef = useRef<HTMLFormElement | null>(null);

	useClickOutside(formRef, onClickOutside || noop);

	return (
		<form className={className} onSubmit={props.handleSubmit(onSubmit || noop)} ref={formRef}>
			{children?.(props)}
		</form>
	);
};

const CreateStoryModalForm = reduxForm<FormValues, PureProps>({
	form: FORM_MODEL.CREATE_STORY, //  <------ same form name
	destroyOnUnmount: false, //        <------ preserve form data
	forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
	validate: values => {
		const errors: Partial<FormValues> = {};

		if (!values.name) {
			errors[fields.name] = t(`createStoryModal.fields.${fields.name}.required`);
		} else if (size(trim(values.name)) < STORY_NAME_MIN_LEN) {
			errors[fields.name] = t(`createStoryModal.fields.${fields.name}.length`);
		}

		if (!values.lang) {
			errors[fields.lang] = t(`createStoryModal.fields.${fields.lang}.required`);
		}

		return errors;
	},
})(Form);

export default connector(CreateStoryModalForm);
