import type { IUser, UserFeatureFlags } from 'src/types';
import urlHelper from 'utils/url-helper';
import { ADMIN_SESSION, PARAMS } from 'common/constants';
import * as actionType from 'admin/constants/actions';
import * as endpoint from 'admin/resources';
import { isSuperUserSelector, selectUserId } from 'admin/reducers/user/selectors';
import { createThunkAction, uploadFileHelper } from '../helpers';

export { getMemberPreferences, setMemberPreferences } from './preferences';

type GetMemberParams = {
	memberId: string;
};

export type GetMemberResult = {
	member: IUser['member'];
	stories: IUser['stories'];
};

/**
 * get member's data
 */
export const getMember = createThunkAction<GetMemberResult, GetMemberParams>({
	type: actionType.GET_MEMBER,
	payloadCreator: async ({ memberId }) => {
		const response = await endpoint.getMember.params({ memberId }).send();
		return response.body;
	},
});

type UpdateMemberEmailParams = {
	memberId: string;
	data: { email: string };
};

export type UpdateMemberEmailResult = UpdateMemberEmailParams;

export const updateMemberEmail = createThunkAction<UpdateMemberEmailResult, UpdateMemberEmailParams>({
	type: actionType.UPDATE_MEMBER_EMAIL,
	payloadCreator: async ({ memberId, data }) => {
		await endpoint.updateMemberEmail.params({ memberId }).send(data);
		return { success: true, result: { memberId, data } };
	},
});

type VerifyMemberEmailResendParams = { memberId: string };

export const verifyMemberEmailResend = createThunkAction<undefined, VerifyMemberEmailResendParams>({
	type: actionType.VERIFY_MEMBER_EMAIL_RESEND,
	payloadCreator: async ({ memberId }) => {
		const response = await endpoint.verifyMemberResend.params({ memberId }).send();
		return response.body;
	},
});

type ResetPasswordParams = { email: string };

export const resetPassword = createThunkAction<undefined, ResetPasswordParams>({
	type: actionType.RESET_PASSWORD,
	payloadCreator: async params => {
		const response = await endpoint.resetPassword.send(params);
		return response.body;
	},
});

type RestorePasswordParams = { email: string; password: string; token: string };

export const restorePassword = createThunkAction<undefined, RestorePasswordParams>({
	type: actionType.RESTORE_PASSWORD,
	payloadCreator: async params => {
		const response = await endpoint.restorePassword.send(params);
		return response.body;
	},
});

type UpdateMemberParams = {
	data: { name: string; country: string };
};

export type UpdateMemberResult = UpdateMemberParams['data'];

export const updateMember = createThunkAction<UpdateMemberResult, UpdateMemberParams>({
	type: actionType.UPDATE_MEMBER,
	payloadCreator: async ({ data }, { getState }) => {
		const memberId = selectUserId(getState());
		await endpoint.updateMember.params({ memberId }).send(data);
		return { success: true, result: data };
	},
});

type UpdateMemberPasswordParams = { oldPassword: string; newPassword: string };

export const updateMemberPassword = createThunkAction<undefined, UpdateMemberPasswordParams>({
	type: actionType.UPDATE_MEMBER_PASSWORD,
	payloadCreator: async (params, { getState }) => {
		const memberId = selectUserId(getState());
		const response = await endpoint.updateMemberPassword.params({ memberId }).send(params);
		return response.body;
	},
});

type UpdateMemberAvatarParams = {
	asset: File;
};

export type UpdateMemberAvatarResult = {
	hosting: string;
	mimetype: string;
	filepath: string;
};

export const updateMemberAvatar = createThunkAction<UpdateMemberAvatarResult, UpdateMemberAvatarParams>({
	type: actionType.UPDATE_MEMBER_AVATAR,
	payloadCreator: async ({ asset }, { getState }) => {
		const store = getState();
		const memberId = selectUserId(store);
		const targetUrl = `${urlHelper.adminBackend}${endpoint.updateMemberAvatar.url.replace(
			`:${PARAMS.MEMBER_ID}`,
			memberId
		)}`;
		const sessionId = localStorage.getItem(ADMIN_SESSION) || '';

		return uploadFileHelper({ asset, apiUrl: targetUrl, sessionId, method: 'PATCH' });
	},
});

type CreateMemberParams = {
	data: {
		name: string;
		email: string;
		password: string;
		paymentPlan: string;
	};
};

export const createMember = createThunkAction<null, CreateMemberParams>({
	type: actionType.CREATE_MEMBER,
	payloadCreator: async params => {
		const response = await endpoint.createMember.send(params);
		return response.body;
	},
});

export const deleteMember = createThunkAction({
	type: actionType.DELETE_MEMBER,
	payloadCreator: async (params, { getState }) => {
		const memberId = selectUserId(getState());
		const response = await endpoint.deleteMember.params({ memberId }).send();
		return response.body;
	},
});

export function setActiveTeam(teamId: string) {
	return { type: actionType.SET_ACTIVE_TEAM, payload: teamId };
}

export type GetFeatureFlagsResult = UserFeatureFlags;

export const getFeatureFlags = createThunkAction<GetFeatureFlagsResult, { organizationId: string }>({
	type: actionType.GET_FEATURE_FLAGS,
	skipAlert: true,
	skipLoading: true,
	skipFailed: true,
	skipStarted: true,
	payloadCreator: async ({ organizationId }, { getState }) => {
		const memberId = selectUserId(getState());
		const isSuperUser = isSuperUserSelector(getState());
		const response = await endpoint.getFeatureFlags.params({ memberId }).send({ query: { organizationId } });

		return {
			success: true,
			result: {
				...response.body,
				'create-ai-story': isSuperUser || Boolean(response.body?.['create-ai-story']),
			},
		};
	},
});
