import PubNub from 'pubnub';
import { IS_PROD } from 'utils/environment';

const KEYS = {
	pub: IS_PROD ? 'pub-c-7ce09e41-abc2-41d8-9ef4-a12050b79bb9' : 'pub-c-60c90dad-7173-4df8-9f85-0c4af6fcd97a',
	sub: IS_PROD ? 'sub-c-23e7349a-6310-11e8-b753-ce5efc28367f' : 'sub-c-56824f9e-5cc5-11e8-94f8-7233e189f8a9',
};

class PubnubFacade {
	private readonly instance: PubNub;

	private listeners: PubNub.ListenerParameters = {};

	constructor(config: { uuid: string }) {
		this.instance = new PubNub({
			userId: config.uuid,
			publishKey: KEYS.pub,
			subscribeKey: KEYS.sub,
			restore: true,
			autoNetworkDetection: true,
			listenToBrowserNetworkEvents: true,
			presenceTimeout: 120, // How long the server will consider the client alive for presence (value seconds)
			heartbeatInterval: 30,
		});
	}

	get uuid() {
		return this.instance.getUUID();
	}

	async getChannelOccupants(channelName: string) {
		const now = await this.instance.hereNow({
			channels: [channelName],
			includeUUIDs: true,
		});
		return now.channels[channelName]?.occupants;
	}

	async fetchMessaged({ channels, end = Date.now(), count = 1 }: PubNub.FetchMessagesParameters) {
		return this.instance.fetchMessages({ channels, end, count });
	}

	publish(params: PubNub.PublishParameters) {
		return this.instance.publish(params);
	}

	subscribe(channelName: string | string[]) {
		this.instance.subscribe({
			channels: Array.isArray(channelName) ? channelName : [channelName],
			withPresence: true,
		});
	}

	addListeners(listeners: PubNub.ListenerParameters) {
		this.listeners = listeners;
		this.instance.addListener(this.listeners);
	}

	removeListeners() {
		this.instance.removeListener(this.listeners);
		Object.getOwnPropertyNames(this.listeners).forEach(property => {
			delete this.listeners[property];
		});
	}

	unsubscribe(channelName: string | string[]) {
		this.instance.unsubscribe({ channels: Array.isArray(channelName) ? channelName : [channelName] });
	}

	close() {
		this.removeListeners();
		this.instance.unsubscribeAll();
		this.instance.stop();
	}
}

export default PubnubFacade;
