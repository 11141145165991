import { useState, useEffect } from 'react';

export function useDebouncedState<T>(value: T, delay: number): [T, React.Dispatch<React.SetStateAction<T>>] {
	const [state, setState] = useState(value);
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(state);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [state, delay]);

	return [debouncedValue, setState];
}
