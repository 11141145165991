import { debounce } from 'lodash';
import { COMPONENT_TYPE } from 'common/constants';
import { isLayerType } from 'utils/blocks/is-layer-type';
import { transmitTo, IFRAME_ACTIONS } from 'utils/iframe-tunnel';
import type { BBCommonProps, OverlayTriggersProp, BBEditableModeProps, ComponentTypes } from 'types/story';

interface ComponentProps extends Pick<BBCommonProps, '_id' | 'type' | 'uiConfig' | 'symbol'> {
	editableModeProps: Pick<BBEditableModeProps, 'nodeProps' | 'inheritance'>;
	// dot notated path to component from story/card elements array
	path: string;
}

type TreeDataType = {
	components: ComponentProps[];
	overlays: {
		overlayId: string;
		showTriggers: OverlayTriggersProp['show'];
		hideTriggers: OverlayTriggersProp['hide'];
	}[];
};

export type CollectedTreeData = {
	tree: {
		story: TreeDataType;
		card: TreeDataType;
	};
};

type TreeType = keyof CollectedTreeData['tree'];

const getDefaultData = (): CollectedTreeData => ({
	tree: {
		story: {
			components: [],
			overlays: [],
		},
		card: {
			components: [],
			overlays: [],
		},
	},
});

class TraverseTreeDataCollector {
	private data = getDefaultData();

	add(treeType: TreeType, component: ComponentProps) {
		if (treeType === 'card' && isLayerType(component).global) {
			// skip global element referenced. theirs instances represented at `story` tree
			return;
		}

		this.data.tree[treeType].components.push(component);

		if (component.type === COMPONENT_TYPE.OVERLAY) {
			this.data.tree[treeType].overlays.push({
				overlayId: component._id,
				showTriggers: component.uiConfig.componentProps.overlayTriggers?.show ?? [],
				hideTriggers: component.uiConfig.componentProps.overlayTriggers?.hide ?? [],
			});
		}
	}

	reset(treeType: TreeType) {
		this.data.tree[treeType] = getDefaultData().tree[treeType];
	}

	sendToEditor = debounce(() => {
		transmitTo({
			id: 'TreeDataCollector',
			target: 'admin',
			action: IFRAME_ACTIONS.GET_TRAVERSE_TREE_DATA,
			payload: this.data,
		});
	}, 0);

	findComponentByType(type: ComponentTypes) {
		return [...this.data.tree.story.components, ...this.data.tree.card.components].find(c => c.type === type);
	}

	findComponentsByType(type: ComponentTypes) {
		return [...this.data.tree.story.components, ...this.data.tree.card.components].filter(c => c.type === type);
	}
}

export default new TraverseTreeDataCollector();
