import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import { videos } from 'admin/resources';
import { useAdminSelector } from 'admin/reducers';
import { selectActiveOrganizationId } from 'admin/reducers/user/selectors';

import { toast } from 'admin/components/common/MessageContainer/toast';

import { GetVideoParams, GetVideoResult } from 'src/types';

import { QUERY_KEYS } from '../constants';

export const getUseVideoQueryFn = ({ organizationId, videoId }: GetVideoParams) => {
	return async () => {
		const response = await videos.getById.params({ organizationId, videoId }).send();

		return {
			success: true,
			result: { ...response.body },
		} as IBackendBody<GetVideoResult>;
	};
};
export const getUseVideoQueryKey = ({ organizationId, videoId }: GetVideoParams) => {
	return [QUERY_KEYS.VIDEO_ITEM, organizationId, videoId];
};

export const useVideo = (params: Omit<GetVideoParams, 'organizationId'>, options?: { enabled?: boolean }) => {
	const organizationId = useAdminSelector(selectActiveOrganizationId);
	const { videoId } = params;
	const enabled = options?.enabled ?? true;

	const { data, isLoading, isFetching, error, refetch } = useQuery({
		queryKey: getUseVideoQueryKey({ organizationId, videoId }),
		queryFn: getUseVideoQueryFn({ organizationId, videoId }),
		enabled: Boolean(videoId) && enabled,
	});

	useEffect(() => {
		if (error) {
			const errorData = JSON.parse((error as Error).message ?? '{}');
			toast.error(errorData.message || (error as Error).message, 3);
		}
	}, [error]);

	return { data: data?.result?.transcoding, isLoading, isFetching, refetch, error };
};
