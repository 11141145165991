import React from 'react';
import { FormErrors, FormSubmitHandler, InjectedFormProps, reduxForm } from 'redux-form';

import { FORM_MODEL } from 'admin/constants/common';
import { isRequired } from 'admin/components/common/Form/validations';

import t from 'utils/translate';

export type Values = { title: string };

type ReduxFormProps = InjectedFormProps<Values, Props>;

type Props = {
	className?: string;
	// "videoFile" prop is used at reduxForm.validate
	// eslint-disable-next-line react/no-unused-prop-types
	videoFile: File | null;
	children: (props: Omit<FormProps, 'className' | 'children' | 'onSubmit'>) => React.ReactNode;
	onSubmit: FormSubmitHandler<Values, Props>;
};

export type FormProps = Props & ReduxFormProps;

const Form = (props: FormProps) => {
	return (
		<form className={props.className} onSubmit={props.handleSubmit(props.onSubmit)}>
			{props.children(props)}
		</form>
	);
};

export default reduxForm<Values, Props>({
	validate: (values, props): FormErrors<Values & { video: File | null }> => {
		const errors: FormErrors<Values & { video: File | null }> = {};
		const _t = (label: string) => t(`createEditVideoModal.fields.${label}`);

		if (!isRequired(values.title)) {
			errors.title = _t('title.required');
		}

		if (!isRequired(props.videoFile)) {
			errors.video = _t('video.required');
		}

		return errors;
	},
	destroyOnUnmount: true,
	form: FORM_MODEL.CREATE_VIDEO,
})(Form);
