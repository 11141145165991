import _ from 'lodash';
import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import dayjs from 'dayjs';
import cn from 'classnames';

import { PARAMS } from 'common/constants';
import { SYNC_STATUS, MODAL } from 'admin/constants/common';
import t from 'utils/translate';
import UrlHelper from 'utils/url-helper';
import { StoryFacade } from 'utils/facades/story-facade';
import { getEmbedCode } from 'admin/utils/get-embed-code';
import { STORY_FLOW_PAGE } from 'admin/constants/routes';
import { getStoryCustomDomain } from 'admin/utils/domains';
import { selectEditableStory } from 'admin/reducers/story-editor/selectors';
import { UPDATE_PUBLISHED_STORY, UNPUBLISH_PUBLISHED_STORY, GET_STORY } from 'admin/constants/actions';
import type { AdminReducerState } from 'admin/reducers';
import PopConfirm from 'admin/components/common/PopConfirm';
import type { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';
import { selectActiveOrganizationId, selectValidDomains } from 'admin/reducers/user/selectors';

import { unpublishPublishedStory, updatePublishedStory, setModal } from 'admin/actions';
import {
	selectLoadingStateByName,
	selectSyncCardStatus,
	selectSyncStoryStatus,
} from 'admin/reducers/loading/selectors';

import Text from 'admin/components/common/Text';
import Button from 'admin/components/common/Button';
import { Icon } from 'admin/components/common/Icon';
import PanelModal from 'admin/components/pages/Story/Flow/PanelModal';

import { VIEW_MAP } from 'admin/components/pages/Story/Flow/StorySettingsModal/constants';
import { hasUnpublishedChanges } from './helpers';

import css from './Modal.scss';

const mapStateToProps = (state: AdminReducerState) => ({
	organizationId: selectActiveOrganizationId(state),
	organizations: state.user.organizations,
	story: selectEditableStory(state)!,
	isPublishing: selectLoadingStateByName(state, UPDATE_PUBLISHED_STORY.PENDING),
	isUnPublishing: selectLoadingStateByName(state, UNPUBLISH_PUBLISHED_STORY.PENDING),
	isGettingStory: selectLoadingStateByName(state, GET_STORY.PENDING),
	version: state.version.current,
	domains: selectValidDomains(state),
	syncStoryStatus: selectSyncStoryStatus(state),
	syncCardStatus: selectSyncCardStatus(state),
});

const mapDispatchToProps = {
	updatePublishedStory,
	unpublishPublishedStory,
	setModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type StoryPublishModalData = {
	isCardEditor?: boolean;
};

type ModalProps = ModalManagerProvidedProps<MODAL.STORY_PUBLISH>;

type Props = ConnectedProps<typeof connector> & ModalProps & RouteComponentProps;

type State = {
	isCopiedToClipboard: boolean;
};

class StoryPublishModal extends Component<Props, State> {
	copiedLabelTimeout: number | undefined;

	state = {
		isCopiedToClipboard: false,
	};

	componentWillUnmount() {
		if (this.copiedLabelTimeout) {
			clearTimeout(this.copiedLabelTimeout);
		}
	}

	onCopyBtnClick = () => {
		if (this.state.isCopiedToClipboard) {
			return;
		}

		const { story, domains } = this.props;
		const { clientStoryId, domainId } = story;

		if (document && document.body) {
			const el = document.createElement('textarea');
			const domain = UrlHelper.getPublishedUrl({
				clientStoryId,
				customDomain: getStoryCustomDomain({ domainId, domains }),
				storycardsDomain: story.storycardsDomain?.domain,
			});

			el.value = getEmbedCode(clientStoryId, { domain }).html;

			document.body.appendChild(el);

			el.select();

			document.execCommand('copy');
			document.body.removeChild(el);

			this.setState({ isCopiedToClipboard: true });
			this.copiedLabelTimeout = window.setTimeout(() => this.setState({ isCopiedToClipboard: false }), 3000);
		}
	};

	openVersionsHistory = () => {
		if (this.props.data?.isCardEditor) {
			this.props.history.push(STORY_FLOW_PAGE.replace(`:${PARAMS.STORY_ID}`, this.props.story.id));
		}

		this.props.setModal({ id: MODAL.STORY_SETTINGS, data: { view: VIEW_MAP.ACTIVITY } });
	};

	onPublishClick = () => {
		this.props.updatePublishedStory({ isCardEditor: this.props.data?.isCardEditor });
	};

	renderContent = () => {
		const { story, isPublishing, isUnPublishing, isGettingStory, version, syncStoryStatus, syncCardStatus } =
			this.props;
		const { isCopiedToClipboard } = this.state;
		const Story = new StoryFacade(story, version);
		const isPublished = !!_.find(Story.base.storyVersions, v => v.version === StoryFacade.VERSIONS.published);
		const storyLastUpdateDate = _.get(story, `storyVersions.${isPublished ? 'published' : version}.updatedAt`);
		const domains = [this.props.domains.filter(d => d.id === story.domainId)[0] ?? { type: null, id: null }];
		const isSyncInProgress = [syncStoryStatus, syncCardStatus].some(syncStatus =>
			([SYNC_STATUS.DRAFT, SYNC_STATUS.SYNC] as string[]).includes(syncStatus)
		);

		return (
			<>
				{isPublished && (
					<div className={css.section}>
						<Text size={Text.size.subheading} weight={Text.weight.bold} className={css.title}>
							{story.name}
						</Text>

						{/* Links */}
						{Story.type !== 'widget' && (
							<div className={css.info}>
								<Text size="label" className={css.name} text="Published Story link" />
								<Text size="paragraph" className={css.val}>
									{_.map(domains, domain => {
										const url = UrlHelper.getPublishedUrl({
											clientStoryId: this.props.story.clientStoryId,
											customDomain: domain.type === 'Custom' ? domain.domain : undefined,
											storycardsDomain: this.props.story.storycardsDomain?.domain,
											noProtocol: true,
										});
										const [hostname] = url.split('/');

										return (
											<a
												href={`https://${url}`}
												target="_blank"
												rel="noreferrer"
												className={cn(css.valInner, css.valInnerDomainLink)}
												key={domain.id}
											>
												<span>{hostname}</span>
												<Icon className={css.avatarIcon} type="open-external" width={24} />
											</a>
										);
									})}
								</Text>
							</div>
						)}

						{/* Embed */}
						<div className={css.info}>
							<Text size="label" className={css.name} text="Embed story code" />
							<Text size="paragraph" className={css.val}>
								<div className={cn(css.valInner, css.embed)} onClick={this.onCopyBtnClick}>
									<span>Copy embed code</span>
									<Icon className={css.avatarIcon} type="embed-icon" width={24} />
									{isCopiedToClipboard && (
										<Text
											tag="div"
											size={Text.size.label}
											className={cn(css.tooltip, isCopiedToClipboard && css.active)}
											text={`${t('story.settings.copied')}!`}
										/>
									)}
								</div>
							</Text>
						</div>
					</div>
				)}
				<div className={cn(css.section, isPublished && css.topBorder)}>
					{/* Date */}
					<div className={css.info}>
						<Text size="label" className={css.name}>
							{isPublished ? 'Published ' : 'Updated '}
							{storyLastUpdateDate && `${dayjs(storyLastUpdateDate).format('MMM DD, YYYY [at] HH:mm')}`}
						</Text>
					</div>

					{/* Actions */}
					<PopConfirm
						title="Publish story"
						description="Proceed with story publication?"
						onConfirm={this.onPublishClick}
					>
						<Button
							loading={isPublishing}
							disabled={
								!hasUnpublishedChanges(story) ||
								isSyncInProgress ||
								isPublishing ||
								isUnPublishing ||
								isGettingStory ||
								!Story.cards.length
							}
							className={css.btn}
							view="danger-ds"
							size="medium"
						>
							{t(`story.panel.buttons.${isPublished ? 'republish' : 'publish'}`)}
						</Button>
					</PopConfirm>

					{isPublished && (
						<Button
							loading={isUnPublishing}
							disabled={isPublishing || isUnPublishing || isGettingStory}
							onClick={this.props.unpublishPublishedStory}
							className={css.btn}
							view="secondary-gray"
							size="medium"
						>
							{t('story.publishModal.unpublish')}
						</Button>
					)}

					<Button size="medium" view="label-only" onClick={this.openVersionsHistory} className={css.btn}>
						{t('story.publishModal.versionHistory')}
					</Button>
				</div>
			</>
		);
	};

	render() {
		return (
			<PanelModal className={css.modal} open={this.props.open}>
				{this.renderContent()}
			</PanelModal>
		);
	}
}

export default connector(withRouter(StoryPublishModal));
