import { postAIImage } from 'admin/resources';
import { getAssetURL } from 'common/utils/assets';
import { createThunkAction, handleAPIv2Error } from 'admin/actions/helpers';
import { selectEditableStoryId } from 'admin/reducers/story-editor/selectors';
import type { GenerateImageParams } from './types';

export const generateImage = createThunkAction<string, GenerateImageParams>({
	payloadCreator: async (payload, { getState }) => {
		const state = getState();
		const storyId = selectEditableStoryId(state);

		if (!storyId) {
			return { success: false };
		}

		const postResponse: ApiV2Response<{ hosting: string; key: string }> = await postAIImage
			.params({ storyId })
			.send(payload);

		// const postResponse = {
		// 	body: {
		// 		hosting: 'dev-low-cost-galleryimgixbucket349e0b82-cab4l3150sim',
		// 		key: '2024/01/21/cb7fe763-c792-412b-9060-df26f51a525c.jpeg',
		// 	},
		// };

		if (!postResponse.body) {
			return { success: false };
		}

		return {
			success: true,
			result: getAssetURL({ hosting: postResponse.body.hosting, filepath: postResponse.body.key }, true),
		};
	},
	onError: ({ error }) => {
		handleAPIv2Error(error);
	},
	alertDuration: 15_000,
});
