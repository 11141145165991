import React, { useEffect, useState } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { useAdminDispatch } from 'admin/reducers';
import { Error } from 'admin/components/common/Form';
import { postGoogleCallback } from 'admin/actions/integrations';

const CHANNEL_ID = 'oauth/google/callback';

const ACTION_TYPE = 'oauth/google/callback';

type MessageData = {
	action: typeof ACTION_TYPE;
	payload: {
		code: string;
		state: string;
	};
};

export const useGoogleAuthData = () => {
	const [data, setData] = useState<MessageData['payload'] | null>(null);

	useEffect(() => {
		const channel = new BroadcastChannel(CHANNEL_ID);
		const onmessage = (event: MessageEvent<MessageData>) => {
			if (event.data.action === ACTION_TYPE) {
				setData(event.data.payload);
			}
		};
		channel.addEventListener('message', onmessage);
		return () => {
			channel.removeEventListener('message', onmessage);
		};
	}, []);

	return data;
};

const GoogleOAuth: React.FC<RouteComponentProps> = () => {
	const [error, setError] = useState<string | null>(null);
	const dispatch = useAdminDispatch();

	useEffect(() => {
		const channel = new BroadcastChannel(CHANNEL_ID);
		const url = new URL(window.location.href);
		const state = url.searchParams.get('state');
		const code = url.searchParams.get('code');

		if (code && state) {
			dispatch(postGoogleCallback({ code, state })).then(({ errors }) => {
				if (errors?.length) {
					setError(errors[0].message);
				} else {
					const message: MessageData = {
						action: ACTION_TYPE,
						payload: { code, state },
					};
					channel.postMessage(message);
					window.close();
				}
			});
		} else {
			setError('Missing data.');
		}
	}, [dispatch]);

	return error ? <Error>{error}</Error> : null;
};

export default GoogleOAuth;
