import React from 'react';
import classnames from 'classnames';

import { Context } from 'admin/components/common/Theme';
import css from './CharsCount.scss';

type Props = {
	value?: string;
	max: number;
	className?: string;
};

export const CharsCount: React.FC<Props> = ({ max, value = '', ...props }) => {
	const context = React.useContext(Context);

	return max ? (
		<div className={classnames(css.charsCount, context?.theme && css[context.theme], props.className)}>
			{Math.min(value.length, max)}/{max}
		</div>
	) : null;
};
