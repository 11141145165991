import type {
	CardTemplate,
	CardTemplateWithoutData,
	StoryModel,
	StoryTemplateWithData,
	StoryTemplateWithoutData,
} from 'types/story';
import { GET_CARD_TEMPLATES, GET_STORY_TEMPLATES } from 'admin/constants/actions';
import {
	getCardTemplate as getCardTemplateEndpoint,
	getCardTemplates as getCardTemplatesEndpoint,
	getStoryTemplate as getStoryTemplateEndpoint,
	getStoryTemplates as getStoryTemplatesEndpoint,
} from 'admin/resources';
import { createThunkAction } from 'admin/actions/helpers';
import { TEMPLATE_CATEGORIES } from 'admin/constants/common';
import { selectActiveOrganizationId } from 'admin/reducers/user/selectors';

type GetCardTemplatesResponse = {
	templates: CardTemplateWithoutData[];
	sorted: {
		member: string[];
		organization: string[];
		categories: Record<keyof typeof TEMPLATE_CATEGORIES.INTENTION, string[]>;
	};
};

export const getCardTemplates = createThunkAction<
	GetCardTemplatesResponse,
	{ excludeEditor?: boolean; tags?: string } | void
>({
	type: GET_CARD_TEMPLATES,
	skipStarted: true,
	skipFailed: true,
	skipSucceed: true,
	payloadCreator: async (params, { getState }) => {
		const state = getState();
		const query: { language?: string; organizationId?: string; uiTarget?: StoryModel['type'] } = {
			uiTarget: state.storyEditor.story?.type ?? null,
			...params,
		};

		if (state.storyEditor.story?.language) query.language = state.storyEditor.story.language;
		if (state.user.organization) query.organizationId = selectActiveOrganizationId(state);

		const { body } = await getCardTemplatesEndpoint.params().send(query);

		// note: code snippet to display multiple organization template
		// body.result.templates.forEach((template, index) => {
		// 	if (index % 2 === 0) {
		// 		// eslint-disable-next-line no-param-reassign
		// 		template.organizationId = 'storycards';
		// 		body.result.sorted.organization.push(template.id);
		// 	}
		// });

		return body;
	},
});

export const getCardTemplate = createThunkAction<CardTemplate, { templateId: string }>({
	payloadCreator: async params => {
		const { body } = await getCardTemplateEndpoint.params(params).send();
		return body;
	},
});

export type GetStoryTemplatesParams = {
	page: number;
	term?: string;
	cardTypes?: string;
	includeGlobalTemplates: boolean;
	storyType: StoryModel['type'];
	language: StoryModel['language'];
	organizationId?: StoryModel['organizationId'];
};

export const getStoryTemplates = createThunkAction<StoryTemplateWithoutData[], GetStoryTemplatesParams>({
	type: GET_STORY_TEMPLATES,
	skipStarted: true,
	skipFailed: true,
	skipSucceed: true,
	payloadCreator: async (params, { getState }) => {
		const query: GetStoryTemplatesParams = { ...params };

		// all "off" behaves like all is "on"
		if (!query.includeGlobalTemplates && !query.organizationId) {
			query.includeGlobalTemplates = true;
			query.organizationId = selectActiveOrganizationId(getState());
		}

		const response = await getStoryTemplatesEndpoint.params().send(query);
		return { success: true, result: response.body };
	},
});

export const getStoryTemplate = createThunkAction<
	StoryTemplateWithData & { relatedCards: CardTemplate[] },
	{ templateId: number }
>({
	payloadCreator: async params => {
		const response = await getStoryTemplateEndpoint.params(params).send();
		return { success: true, result: response.body };
	},
});
