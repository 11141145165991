/* eslint-disable consistent-return */

import React, { Component, ChangeEvent, MouseEvent } from 'react';
import cn from 'classnames';
import produce from 'immer';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { InstalledFont, UploadedFont } from 'src/types';

import t from 'common/utils/translate';
import { FONT_FILE_TYPES } from 'utils/assets';
import { getFontFileMetadata } from 'admin/utils/get-font-file-metadata';
import { SETTINGS_BILLING_PAGE } from 'admin/constants/routes';
import { Icon, LAYER_ICONS } from 'admin/components/common/Icon';
import Text from 'admin/components/common/Text';
import Button from 'admin/components/common/Button';
import { Error } from 'admin/components/common/Form';

import { SettingsTabsGeneralProps } from '../../types';
import css from './CustomFonts.scss';

type OwnProps = {
	className?: string;
	onFontsUpload: (fontFile: UploadedFont) => Promise<void>;
	isDisabled?: boolean;
};

type Props = SettingsTabsGeneralProps & OwnProps;

type State = {
	uploadedFonts: UploadedFont[];
	error: string;
	fontFamilyInfo: { [key: string]: { error: string; isUploading: boolean } };
};

export const FONT_WEIGHT_MAP = {
	100: 'Thin',
	200: 'Extra Light',
	300: 'Light',
	400: 'Regular',
	500: 'Medium',
	600: 'Semi Bold',
	700: 'Bold',
	800: 'Extra Bold',
	900: 'Black',
	950: 'Extra Black',
};

class CustomFonts extends Component<Props, State> {
	static defaultProps = {
		className: '',
	};

	state: State = {
		uploadedFonts: [],
		fontFamilyInfo: {},
		error: '',
	};

	getFontFamilyError = (font: UploadedFont) => {
		const { formValues, version } = this.props;
		const installedFonts = _.get(formValues, `storyVersions.${version}.settings.fonts`);
		const filteredInstalledFonts = _.filter(installedFonts, Boolean);
		const fontName = _.trim(font.fontFamily.replace(/ +(?= )/g, ''));
		const isAlreadyInstalled = !!_.find(
			filteredInstalledFonts,
			(f: InstalledFont) => f.fontFamily === fontName && f.weight === font.weight && f.style === font.style
		);

		if (!fontName) {
			return t('story.settings.required');
		}

		if (isAlreadyInstalled) {
			return t('story.settings.alreadyInstalledFontError');
		}

		return '';
	};

	onFilesUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
		if (this.props.isDisabled) {
			return;
		}

		const { files } = e.target;
		const promises: Promise<UploadedFont>[] = [];
		let isWrongFileType = false;

		if (_.size(files) !== 0) {
			_.forEach(files, (item: File) => {
				const filename = item.name.toLowerCase();
				const ext = filename.split('.').pop() || 'unknown';

				if (!FONT_FILE_TYPES.includes(ext)) {
					isWrongFileType = true;
				} else {
					promises.push(getFontFileMetadata(item));
				}
			});

			if (isWrongFileType) {
				this.setState({ error: 'Wrong file type' });
			} else {
				const results = await Promise.all(promises);

				this.setState(prvState => ({
					uploadedFonts: [...prvState.uploadedFonts, ...results],
					error: '',
				}));
			}

			_.set(e, 'target.value', null);
		}
	};

	onUploadedItemRemoveClick = (e: MouseEvent<HTMLButtonElement>) => {
		const { itemId } = e.currentTarget.dataset;

		this.setState(
			produce(draft => {
				_.set(draft, ['fontFamilyErrors', `${itemId}`], '');
				_.set(
					draft,
					'uploadedFonts',
					_.filter(draft.uploadedFonts, (item: UploadedFont) => item.id !== itemId)
				);
			})
		);
	};

	onFontInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { itemIndex = -1, itemId } = e.currentTarget.dataset;
		const { fontFamilyInfo } = this.state;
		const isBusy = fontFamilyInfo[`${itemId}`]?.isUploading;

		if (isBusy) {
			return;
		}

		if (fontFamilyInfo[`${itemId}`] && fontFamilyInfo[`${itemId}`].error) {
			this.setState(
				produce(draft => {
					_.set(draft, ['fontFamilyInfo', `${itemId}`, 'error'], '');
				})
			);
		}

		this.setState(
			produce(draft => {
				_.set(draft, ['uploadedFonts', `${itemIndex}`, 'fontFamily'], e.target.value);
			})
		);
	};

	onFontItemUploadClick = async (e: MouseEvent<HTMLButtonElement>) => {
		const { itemId } = e.currentTarget.dataset;
		const { onFontsUpload } = this.props;
		const { uploadedFonts } = this.state;
		const targetFontIndex = _.findIndex(uploadedFonts, (f: UploadedFont) => f.id === itemId);
		const targetFont = uploadedFonts[targetFontIndex];

		if (targetFont) {
			const fontError = this.getFontFamilyError(targetFont);

			if (fontError) {
				this.setState(
					produce(draft => {
						_.set(draft, ['fontFamilyInfo', `${itemId}`, 'error'], fontError);
					})
				);
			} else {
				this.setState(
					produce(draft => {
						_.set(draft, ['fontFamilyInfo', `${itemId}`, 'isUploading'], true);
					})
				);

				await onFontsUpload(targetFont);

				this.setState(
					produce(draft => {
						_.set(draft, ['fontFamilyInfo', `${itemId}`, 'isUploading'], false);
						_.set(
							draft,
							'uploadedFonts',
							_.filter(draft.uploadedFonts, (item: UploadedFont) => item.id !== itemId)
						);
					})
				);
			}
		}
	};

	renderUploadedItem = (item: UploadedFont, index: number) => {
		const { fontFamilyInfo } = this.state;
		const isBusy = fontFamilyInfo[`${item.id}`]?.isUploading;
		const error = fontFamilyInfo[`${item.id}`]?.error || '';
		const fileName = _.get(item, 'file.name', '');
		const fileSize = `${(_.get(item, 'file.size', 0) / 1024).toFixed(2)} Kib`;
		const fontFamily = _.get(item, 'fontFamily', '');
		const fontStyle = _.get(item, 'style', '');
		const fontWeight = FONT_WEIGHT_MAP[_.get(item, 'weight')];

		return (
			<div key={`font-item-${item.id}`} className={cn(css.uploadedItem, isBusy && css.isBusy)}>
				<div className={css.inner}>
					<Text className={css.name}>{fileName}</Text>
					<div className={cn(css.inputWrap, error && css.isError)}>
						<Text size={Text.size.description} tag="label" htmlFor={item.id}>
							{`${t('story.settings.fontFamily')}: `}
						</Text>
						<input
							type="text"
							value={fontFamily}
							onChange={this.onFontInputChange}
							data-item-index={index}
							data-item-id={item.id}
							id={item.id}
						/>
					</div>
					<Text
						size={Text.size.description}
						tag="label"
						htmlFor={item.id}
						style={{ color: 'var(--ra-color-white-soft)', padding: '2px 0 10px' }}
					>
						In order to set fallback fonts, you need to list font names separated by comma dangles. (E.g.
						Font A, Font B)
					</Text>
					{error && (
						<Text className={css.fontError} size={Text.size.description}>
							{error}
						</Text>
					)}
					<Text size={Text.size.description} className={css.metadata}>{`${t(
						'story.settings.fontSize'
					)}: ${fileSize}`}</Text>
					<Text size={Text.size.description} className={css.metadata}>{`${t(
						'story.settings.fontWeight'
					)}: ${fontWeight}`}</Text>
					<Text size={Text.size.description} className={css.metadata}>{`${t(
						'story.settings.fontStyle'
					)}: ${fontStyle}`}</Text>

					<button
						data-item-id={item.id}
						onClick={this.onUploadedItemRemoveClick}
						type="button"
						disabled={isBusy}
						className={css.removeBtn}
					/>
				</div>

				<Button
					data-item-id={item.id}
					onClick={this.onFontItemUploadClick}
					view="secondary"
					smallText
					disabled={isBusy}
					theme="dark"
				>
					{isBusy ? `${t('story.settings.uploading')}...` : t('story.settings.uploadFontFile')}
				</Button>
			</div>
		);
	};

	renderUploadedFonts = () => {
		return <div className={css.uploadedList}>{_.map(this.state.uploadedFonts, this.renderUploadedItem)}</div>;
	};

	render() {
		const { className } = this.props;
		const { uploadedFonts, error } = this.state;

		return (
			<div className={cn(css.customFonts, className, { [css.disabled]: this.props.isDisabled })}>
				<Text tag="div" size={Text.size.subheading} weight={Text.weight.bold} className={css.title}>
					{t('story.settings.customFonts')}
					{this.props.isDisabled && <Icon className={css.lockIcon} type={LAYER_ICONS.LOCKED} width={22} />}
				</Text>
				{this.props.isDisabled && (
					<Link to={SETTINGS_BILLING_PAGE} className={css.upgradeBtn}>
						<Button view="primary" smallText theme="dark">
							{t('story.settings.upgradeBtn')}
						</Button>
					</Link>
				)}
				<div className={cn(css.inputWrap, error && css.isError)}>
					<Text size={Text.size.label} weight={Text.weight.bold} className={css.label} tag="div">
						{t('story.settings.uploadCustomFonts')}
					</Text>
					{error && (
						<Error tag="div" style={{ paddingBottom: 6 }}>
							{error}
						</Error>
					)}
					<Text size={Text.size.description} className={css.description} tag="div">
						TTF, OTF and WOFF.
					</Text>

					{_.size(uploadedFonts) !== 0 ? (
						this.renderUploadedFonts()
					) : (
						<label htmlFor="upload">
							<input
								disabled={this.props.isDisabled}
								type="file"
								accept={FONT_FILE_TYPES.map(ext => `.${ext}`).join(',') || undefined}
								id="upload"
								multiple
								onChange={this.onFilesUpload}
								style={{ display: 'none' }}
							/>
							<Button
								className={css.uploadBtn}
								view="secondary"
								smallText
								theme="dark"
								disabled={this.props.isDisabled}
							>
								{t('story.settings.upload')}
							</Button>
						</label>
					)}
				</div>
			</div>
		);
	}
}

export default CustomFonts;
