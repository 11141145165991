import _ from 'lodash';
import React, { FC, SyntheticEvent, useCallback, useState } from 'react';
import { change as formChange } from 'redux-form';
import cn from 'classnames';
import { useAdminDispatch } from 'admin/reducers';
import { Icon } from 'admin/components/common/Icon';
import type { ReduxFieldInputTypes, ReduxFieldMetaTypes } from 'admin/components/common/Form/utils';
import type { InspectorTypes } from 'admin/components/pages/Story/CardEditor/Inspector/types';
import { TextField } from 'admin/components/pages/Story/CardEditor/Inspector/PropField/TextField';
import Popover from 'admin/components/pages/Story/CardEditor/Inspector/Popover';
import { Option, OptionProps } from './Option';
import css from './Select.scss';

export type SelectProps = {
	placeholder?: string;
	input: ReduxFieldInputTypes;
	meta: ReduxFieldMetaTypes;
	options: Pick<OptionProps, 'label' | 'value' | 'tooltip' | 'className' | 'title' | 'style'>[];
	afterOptions?: React.ReactNode;
	eventListeners?: ReturnType<InspectorTypes.GetFieldListeners>;
	forcedValue?: string;
	defaultValue?: string;
	hideUnlistedValue?: boolean;
	showSearch?: boolean;
	searchBy?: keyof Pick<OptionProps, 'label' | 'title' | 'value'>;
};

const Select: FC<SelectProps> = props => {
	const dispatch = useAdminDispatch();
	const [search, setSearch] = useState('');
	const { options, eventListeners, searchBy = 'label' } = props;
	const { name } = props.input;
	const formName = props.meta.form;
	const inputValue =
		props.forcedValue !== undefined
			? props.forcedValue
			: props.input.value === '' && props.defaultValue !== undefined
				? props.defaultValue
				: (props.input.value as string);

	const onOptionClick = useCallback(
		(event: SyntheticEvent<HTMLDivElement>, value: string) => {
			// update redux form
			dispatch(formChange(formName, name, value));
			// update client app
			eventListeners?.onChange?.({ name, value });
		},
		[name, dispatch, eventListeners, formName]
	);

	const renderOption = useCallback(
		(option: SelectProps['options'][number]) =>
			option.value !== undefined ? (
				<Option
					key={option.value}
					isSelected={option.value === inputValue}
					label={option.label}
					value={option.value}
					onClick={onOptionClick}
					className={option.className}
					title={option.title}
					tooltip={option.tooltip}
					style={option.style}
				/>
			) : null,
		[onOptionClick, inputValue]
	);

	const showSearch = props.showSearch ?? options.length >= 10;
	const fallbackValue = props.hideUnlistedValue ? undefined : inputValue;
	const label = options.find(o => o.value === inputValue)?.label ?? fallbackValue;
	const hasLabel = label !== '' && label !== null && label !== undefined;
	const filteredOptions =
		search === ''
			? options
			: options.filter(option => {
					const str = option[searchBy]?.toString();
					return str && str.toLowerCase().indexOf(search) > -1;
				});

	return (
		<Popover
			beforeScrollable={
				showSearch ? (
					<div className={css.search}>
						<input
							type="text"
							placeholder="Search..."
							value={search}
							onChange={e => setSearch(e.target.value.toLowerCase())}
							// eslint-disable-next-line jsx-a11y/no-autofocus
							autoFocus
						/>
						<Icon type="search" width={22} color="currentColor" />
					</div>
				) : null
			}
			content={
				<>
					<div className={cn(css.options, { [css.hasSearch]: showSearch })}>
						{_.map(filteredOptions, renderOption)}
					</div>
					{props.afterOptions && <div className={css.afterOptions}>{props.afterOptions}</div>}
				</>
			}
		>
			<div className={css.control}>
				<TextField
					input={{
						...props.input,
						readOnly: true,
						// just dont touch, dont ask why:
						style: {
							position: 'absolute',
							border: 'none',
							width: 0,
							height: 0,
							padding: 0,
							opacity: 0,
							outline: 'none',
							WebkitTransform: 'scale(0.0001)',
							transform: 'scale(0.0001)',
						},
					}}
					meta={props.meta}
					eventListeners={eventListeners}
				/>
				<span
					className={cn(css.label, { [css.placeholder]: props.placeholder !== undefined && !hasLabel })}
					title={typeof label === 'string' ? label : hasLabel ? '' : props.placeholder}
				>
					{label || props.placeholder}
				</span>
			</div>
		</Popover>
	);
};

export default Select;
