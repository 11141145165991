import _ from 'lodash';
import React from 'react';
import { Route } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { gsap } from 'gsap';
import { adminLog } from 'utils/helpers';
import { getRouteByPath } from 'utils/route';
import * as routes from 'admin/constants/routes';

import ConnectorCss from 'admin/components/pages/Story/Flow/Connector/Connector.scss';
import StoryCss from 'admin/components/pages/Story/Story.scss';
import { Location } from 'history';

import './TransitionSwitch.scss';

const log = adminLog.extend('TransitionSwitch');
const error = adminLog.extend('TransitionSwitch');
error.log = console.error.bind(console);

const routesArr = _.values(routes).filter(route => route !== routes.ANY);

/**
 * Default duration for route transition.
 * But this should never happen. Because each route have to call earlier "done()" callback
 * provided by "addEndListener" of "CSSTransition" to finish route "enter|exit" transition
 * @type {number}
 */
const duration = 60 * 1000;

// CSSTransition|Transition status names
const transitionStatus = {
	entering: 'entering',
	entered: 'entered',
	exiting: 'exiting',
	exited: 'exited',
};

const transitionClassNames = {
	appear: 'route-appear',
	appearActive: 'route-active-appear',
	appearDone: 'route-done-appear',
	enter: 'route-enter',
	enterActive: 'route-active-enter',
	enterDone: 'route-done-enter',
	exit: 'route-exit',
	exitActive: 'route-active-exit',
	exitDone: 'route-done-exit',
};

interface PlayProps {
	node?: HTMLElement;
	done: () => void;
	location: Location;
	event: 'onEndListener';
	locationHistory: { prev: string; next: string };
	isAppear?: boolean;
	isEnter?: boolean;
	isExit?: boolean;
}

/**
 * Main function to control route transition
 * Allows to describe custom scenario for every individual route
 * @param props {Object}
 */
function play(props: PlayProps) {
	if (!props.node) {
		error('"node" is undefined', props);
		return;
	}

	// prettier-ignore
	const isAppear = _.has(props, 'isAppear') ? props.isAppear : [
		transitionClassNames.appear,
		transitionClassNames.appearActive,
		transitionClassNames.appearDone,
	].some(cls => props.node?.classList.contains(cls));

	// prettier-ignore
	const isEnter = _.has(props, 'isEnter') ? props.isEnter : [
		transitionClassNames.enter,
		transitionClassNames.enterActive,
		transitionClassNames.enterDone,
	].some(cls => props.node?.classList.contains(cls));

	// prettier-ignore
	const isExit = _.has(props,'isExit') ? props.isExit : [
		transitionClassNames.exit,
		transitionClassNames.exitActive,
		transitionClassNames.exitDone,
	].some(cls => props.node?.classList.contains(cls));

	log(props.location.pathname, {
		isAppear,
		isEnter,
		isExit,
		...props,
	});

	const DEFAULT = {
		onEndListener: ({ done, node, event, location, locationHistory }: PlayProps) => {
			if (!node) {
				done();
				return;
			}

			if (isEnter || isAppear) {
				gsap.fromTo(node, { duration: 0.35, alpha: 0, ease: 'power1.easeIn' }, { alpha: 1, onComplete: done });
			}
			if (isExit) {
				gsap.to(node, {
					duration: 0.05,
					alpha: 0,
					position: 'absolute',
					top: 0,
					right: 0,
					left: 0,
					ease: 'power1.easeOut',
					onComplete: done,
				});
			}
		},
		// onEnter: _props => console.info('««« play', _props),
		// onEntering: _props => console.info('««« play', _props),
		// onEntered: _props => console.info('««« play', _props),
		// onExit: _props => console.info('««« play', _props),
		// onExiting: _props => console.info('««« play', _props),
		// onExited: _props => console.info('««« play', _props),
	};

	const Flow = {
		onEndListener: ({ done, event, location, node, locationHistory }: PlayProps) => {
			if (!node) {
				done();
				return;
			}

			if (isEnter || isAppear) {
				const storyBg = node.querySelector(`.${StoryCss.bg}`);
				const storyContent = node.querySelector(`.${StoryCss.storyContent}`);
				if (storyBg) gsap.set(storyBg, { backgroundColor: 'hsl(0, 0%, 100%)' });
				if (storyContent) gsap.set(storyContent, { alpha: 0 });
				if (storyBg) {
					gsap.to(storyBg, {
						duration: 0.7,
						backgroundColor: 'hsl(0, 0%, 20%)',
						ease: 'power1.easeOut',
						onComplete: () => {
							let start: number | null = null;
							const tick = (timestamp: number) => {
								if (start === null) start = timestamp;
								const progress = timestamp - start;
								const list = node.querySelector(`.${ConnectorCss.root}`);
								const shouldExit = progress >= 5000 || list;

								if (!shouldExit) {
									window.requestAnimationFrame(tick);
									return;
								}

								// list is mounted...
								gsap.to(storyContent, {
									duration: 0.5,
									alpha: 1,
									ease: 'power1.easeIn',
									onComplete: done,
								});
							};
							window.requestAnimationFrame(tick);
						},
					});
				}
			}

			if (isExit) {
				const isEditorNext = getRouteByPath(locationHistory.next, routesArr) === routes.STORY_CARD_EDITOR_PAGE;
				if (isEditorNext) {
					done();
				} else {
					gsap.to(node, { duration: 0.2, alpha: 0, ease: 'power1.easeOut', onComplete: done });
				}
			}
		},
	};

	const Editor = {
		onEndListener: ({ done, event, location, node, locationHistory }: PlayProps) => {
			done();
		},
	};

	const Profile = {
		onEndListener: (p: PlayProps) => {
			if (p.locationHistory.prev.includes(routes.PROFILE_PAGE)) {
				p.done();
			} else {
				DEFAULT.onEndListener(p);
			}
		},
	};

	const Stories = {
		onEndListener: p => {
			if (isAppear || isEnter) {
				DEFAULT.onEndListener(p);
			}
			if (isExit) {
				DEFAULT.onEndListener(p);
			}
		},
	};

	const route = getRouteByPath(props.location.pathname, routesArr);

	switch (route) {
		case routes.STORY_FLOW_PAGE:
			Flow[props.event](props);
			break;
		case routes.STORY_CARD_EDITOR_PAGE:
			Editor[props.event](props);
			break;
		case routes.PROFILE_PAGE:
		case routes.PROFILE_2FA_PAGE:
			Profile[props.event](props);
			break;
		case routes.STORIES_PAGE:
			Stories[props.event](props);
			break;
		default:
			DEFAULT[props.event](props);
			break;
	}
}

const locationHistory = {
	prev: '',
	next: '',
};

type Props = {
	children: (...args: any[]) => any;
};

/**
 * @param children {function}
 * @return {*}
 * @constructor
 */
const TransitionSwitch = ({ children }: Props) => (
	<Route
		render={({ location }) => {
			const { key } = location;

			return (
				<TransitionGroup>
					<CSSTransition
						key={key}
						classNames={transitionClassNames}
						timeout={duration}
						appear
						addEndListener={(node, done) =>
							play({ node, done, location, event: 'onEndListener', locationHistory })
						}
						onEnter={(node, isAppear) => {
							locationHistory.next = location.pathname;
						}}
						// onEntering={(node, isAppear) => play({ node, isAppear, location, event: 'onEntering' })}
						// onEntered={(node, isAppear) => play({ node, isAppear, location, event: 'onEntered' })}
						onExit={node => {
							locationHistory.prev = location.pathname;
						}}
						// onExiting={node => play({ node, location, event: 'onExiting' })}
						// onExited={node => play({ node, location, event: 'onExited' })}
					>
						{status => children(status, location)}
					</CSSTransition>
				</TransitionGroup>
			);
		}}
	/>
);

export { TransitionSwitch, transitionStatus };
