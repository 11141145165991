import { last, first } from 'lodash';
import React from 'react';
import type { SliderRangeProps } from 'antd/lib/slider';
import { SCORE } from 'common/constants';
import Text from 'admin/components/common/Text';
import { SliderPure } from 'admin/components/common/Form/Slider';
import { getRangesBefore, getRangesAfter, RangePropType, ScoreRange } from './utils';

import css from './RangeSlider.scss';

const { MIN: MIN_LIMIT, MAX: MAX_LIMIT, MIN_RANGE_SIZE: MIN_RANGE } = SCORE.RANGE;

const mark = (value: number) => ({
	label: <small>{value}</small>,
});

const isRangeAllowed = (min: number, max: number) =>
	min === MIN_LIMIT ? max - min >= MIN_RANGE : max - min >= MIN_RANGE - 1;

const checkSibling = (sibling: RangePropType, current: EitherField<{ min: number; max: number }>) => {
	const result: {
		allowToMove: boolean;
		sibling: EitherField<{ min: number; max: number }>;
		current: EitherField<{ min: number; max: number }>;
	} = {
		allowToMove: true,
		sibling: current.min ? { max: Infinity } : { min: Infinity },
		current: current.min ? { min: Infinity } : { max: Infinity },
	};

	// move max UP
	if (current.max !== undefined) {
		const currentMax = current.max;
		const siblingMinLimit = sibling.max - (sibling.min === MIN_LIMIT ? MIN_RANGE : MIN_RANGE - 1);
		const siblingNextMin = Math.min(currentMax + 1, siblingMinLimit);
		result.sibling.min = siblingNextMin;
		result.allowToMove = currentMax < siblingNextMin;
		result.current.max = result.allowToMove ? currentMax : siblingNextMin - 1;
	}

	// move min DOWN
	if (current.min !== undefined) {
		const currentMin = current.min;
		const siblingMaxLimit = sibling.min + (sibling.min === MIN_LIMIT ? MIN_RANGE : MIN_RANGE - 1);
		const siblingNextMax = Math.max(currentMin - 1, siblingMaxLimit);
		result.sibling.max = siblingNextMax;
		result.allowToMove = currentMin > siblingNextMax;
		result.current.min = result.allowToMove ? currentMin : siblingNextMax + 1;
	}

	return result;
};

type ScoreRangeSliderProps = {
	range: ScoreRange;
	index: number;
	scoreRanges: ScoreRange[];
	onChange: (value: ScoreRange[]) => void;
	onChangeComplete: SliderRangeProps['onChangeComplete'];
	deleteButton: React.ReactNode;
	splitButton: React.ReactNode;
};

const ScoreRangeSlider: React.FC<ScoreRangeSliderProps> = ({
	range,
	index,
	scoreRanges,
	onChange,
	onChangeComplete,
	deleteButton,
	splitButton,
}) => {
	const marks = {
		[range.min]: mark(range.min),
		[range.max]: mark(range.max),
	};

	const defaultValue = [range.min, range.max];

	const handleOnChange: SliderRangeProps['onChange'] = nextValue => {
		let nextMin = nextValue[0];
		let nextMax = nextValue[1];
		const nextRanges = [...scoreRanges];

		const IS_MIN_CHANGED = nextMin !== defaultValue[0];
		const IS_MAX_CHANGED = nextMax !== defaultValue[1];

		// LOCK MIN_LIMIT
		if (range.min === MIN_LIMIT && IS_MIN_CHANGED) {
			return;
		}

		// LOCK MAX_LIMIT
		if (range.max === MAX_LIMIT && IS_MAX_CHANGED) {
			return;
		}

		// prevent an issue when max and min values are changed in the same time
		if (IS_MAX_CHANGED && IS_MIN_CHANGED) {
			return;
		}

		// adjust range within acceptable limits if isn't
		if (!isRangeAllowed(nextMin, nextMax)) {
			if (IS_MIN_CHANGED) {
				nextMin = Math.max(MIN_LIMIT, nextMax - (MIN_RANGE - 1));
			}
			if (IS_MAX_CHANGED) {
				const arg1 = nextMin + MIN_RANGE;
				nextMax = Math.min(nextMin === MIN_LIMIT ? arg1 : arg1 - 1, MAX_LIMIT);
			}
		}

		// check and change previous range if it needed
		const rangesBefore = getRangesBefore(scoreRanges, range);
		if (rangesBefore.length) {
			// change current and prev range(optional)
			const isPrevRangeOverlap = nextMin <= last(rangesBefore)!.max;
			const siblingIndex = index - 1;

			if (isPrevRangeOverlap) {
				// push sibling
				const result = checkSibling(nextRanges[siblingIndex], { min: nextMin });
				nextMin = result.current.min!;
				nextRanges[siblingIndex] = { ...nextRanges[siblingIndex], max: result.sibling.max! };
			} else if (IS_MIN_CHANGED) {
				// pull sibling
				nextRanges[siblingIndex] = { ...nextRanges[siblingIndex], max: nextMin - 1 };
			}
		}

		// check and change next range if it needed
		const rangesAfter = getRangesAfter(scoreRanges, range);
		if (rangesAfter.length) {
			// change current and next range(optional)
			const isNextRangeOverlap = nextMax >= first(rangesAfter)!.min;
			const siblingIndex = index + 1;

			if (isNextRangeOverlap) {
				// push sibling
				const result = checkSibling(nextRanges[siblingIndex], { max: nextMax });
				nextMax = result.current.max!;
				nextRanges[siblingIndex] = { ...nextRanges[siblingIndex], min: result.sibling.min! };
			} else if (IS_MAX_CHANGED) {
				// pull sibling
				nextRanges[siblingIndex] = { ...nextRanges[siblingIndex], min: nextMax + 1 };
			}
		}

		// update current range
		nextRanges[index] = { ...nextRanges[index], min: nextMin, max: nextMax };

		// store to state
		onChange(nextRanges);
	};

	return (
		<div className={css.sliderW}>
			<div className={css.header}>
				<Text text={`Score ${defaultValue[0]} - ${defaultValue[1]}`} size={Text.size.footnote} />
				<div className={css.buttons}>
					{deleteButton}
					{splitButton}
				</div>
			</div>
			<SliderPure
				className={css.slider}
				sliderProps={{
					range: true,
					marks,
					tooltip: { open: false },
					onChange: handleOnChange,
					onChangeComplete,
				}}
				value={[defaultValue[0], defaultValue[1]]}
			/>
		</div>
	);
};

export default ScoreRangeSlider;
