import React from 'react';
import { SCFontItem, UploadedFont } from 'src/types';
import Text from 'admin/components/common/Text';
import { FONT_WEIGHT_MAP } from 'admin/components/pages/Story/Flow/StorySettingsModal/Fonts/CustomFonts';
import { FontSize } from 'admin/components/common/Text/config';

const CustomFontInfo = ({
	font,
	textSize = 'paragraph',
	className,
}: {
	font: SCFontItem | UploadedFont;
	textSize?: (typeof FontSize)[keyof typeof FontSize];
	className?: string;
}) => (
	<Text size={textSize} className={className}>
		<span>{`${FONT_WEIGHT_MAP[font.weight]} ${font.weight}`}</span>
		<span>{` | Style: ${font.style}`}</span>
		<span>{` | ${font.fileType}`}</span>
		<span>{` | ${(font.size / 1024).toFixed(2)} Kib`}</span>
	</Text>
);

export default CustomFontInfo;
