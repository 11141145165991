import type { OrganizationDomains, OrganizationDomainsResponseResult } from 'types/organization';
import UrlHelper from 'utils/url-helper';
import { getBaseOrganizationDomain } from 'admin/utils/domains';
import { selectActiveOrganization } from 'admin/reducers/user/selectors';
import { GET_ORGANIZATION_DOMAINS } from 'admin/constants/actions';
import { getOrganizationDomains as getOrganizationDomainsEndpoint } from 'admin/resources';
import { createThunkAction } from 'admin/actions/helpers';

interface Params {
	organizationId: string;
}

export const getOrganizationDomains = createThunkAction<OrganizationDomains, Params>({
	type: GET_ORGANIZATION_DOMAINS,
	payloadCreator: async ({ organizationId }, { getState }) => {
		const response = await getOrganizationDomainsEndpoint.params({ organizationId }).send({});
		const responseBody: IBackendBody<OrganizationDomainsResponseResult> = response.body;
		const activeOrganization = selectActiveOrganization(getState());
		const result = [
			getBaseOrganizationDomain({
				hostname: UrlHelper.config.domains.frontend.client.hostname,
				customId: activeOrganization!.customId,
			}),
			...(responseBody.result || []).map(domain => ({
				id: domain.id,
				domain: domain.domain,
				type: 'Custom' as const,
				status: domain.status,
				message: domain.statusMessage,
			})),
		];

		return { ...responseBody, result };
	},
});
