import _ from 'lodash';

export function formatNumber(num: number, round = true): string {
	const pointsMap = {
		K: 1000,
		M: 1000000,
	};
	let result = num.toString();

	_.forEach(pointsMap, (val, key) => {
		if (num / val >= 1) {
			if (round) {
				result =
					(parseFloat((num / val).toFixed(1)) * 10) % 10 === 0
						? `${(num / val).toFixed(0)}${key}`
						: `${(num / val).toFixed(1)}${key}`;
			} else {
				const resultStr = (num / val).toString();
				const periodIndex = resultStr.indexOf('.');
				const afterPeriod = periodIndex !== -1 ? resultStr.substring(periodIndex + 1, periodIndex + 2) : '';
				const cutIndex = afterPeriod === '0' ? periodIndex : periodIndex + 2;

				result = `${periodIndex >= 0 ? resultStr.substring(0, cutIndex) : resultStr}${key}`;
			}
		}
	});

	return result;
}
