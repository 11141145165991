import { get } from 'lodash';
import React from 'react';
import { Radio } from 'antd';
import { change } from 'redux-form';

import t from 'common/utils/translate';

import Text from 'admin/components/common/Text';
import { Icon } from 'admin/components/common/Icon';
import type { AdminThunkDispatch } from 'admin/reducers';

import { fields, FormValues, StoryContentType } from './Form';
import css from './CreateStoryModal.scss';

type MainViewProps = {
	form: string;
	onOk: () => void;
	dispatch: AdminThunkDispatch;
	values: FormValues;
};

const types = [StoryContentType.static, StoryContentType.generic];

const TemplateContent: React.FC<MainViewProps> = ({ values, dispatch, form, onOk }) => {
	const type = get(values, fields.contentType);

	return (
		<div style={{ width: 800, textAlign: 'center', paddingBottom: 60 }}>
			<Text size={Text.size.subheading} style={{ marginBottom: 44 }}>
				{t('createStoryModal.content.title')}
			</Text>
			<Radio.Group className={css.templateType} value={type}>
				{types.map(value => (
					<Radio.Button
						onClick={() => {
							dispatch(change(form, fields.contentType, value));
							onOk();
						}}
						className={css.templateTypeBtn}
						value={value}
						key={value}
					>
						<Icon
							type={value === StoryContentType.static ? 'sc-standalone' : 'sc-magic'}
							color={value === type ? 'var(--ra-color-brand)' : 'currentColor'}
						/>
						<Text
							size={Text.size.paragraph}
							transform={Text.transform.capitalize}
							style={{ whiteSpace: 'nowrap' }}
						>
							{t(`createStoryModal.content.${value}.title`)}
						</Text>
						{/* <Text size={Text.size.label} color={Text.color.blackDark}>
							{t(`createStoryModal.content.${value}.desc`)}
						</Text> */}
					</Radio.Button>
				))}
			</Radio.Group>
		</div>
	);
};

export default TemplateContent;
