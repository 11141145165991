/* eslint-disable no-useless-escape */

import React from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import _ from 'lodash';

import t from 'utils/translate';
import { emailRegExp } from 'utils/regexp';
import { FORM_MODEL } from 'admin/constants/common';

export type LoginFormValues = { email: string; password: string };

type Props = {
	className?: string;
	children?: React.ReactNode;
	onSubmit?: (data: LoginFormValues) => any;
};

const LoginForm = ({
	className = '',
	onSubmit = _.noop,
	...props
}: Props & InjectedFormProps<LoginFormValues, Props>) => {
	return (
		<form className={className} onSubmit={props.handleSubmit(onSubmit)}>
			{props.children}
		</form>
	);
};

export default reduxForm<LoginFormValues, Props>({
	validate: values => {
		const errors: Partial<LoginFormValues> = {};
		const _t = (label: string) => t(`login.${label}`);

		if (!_.get(values, 'email')) {
			_.set(errors, 'email', _t('email.required'));
		} else if (!emailRegExp.test(values.email)) {
			_.set(errors, 'email', _t('email.invalid'));
		}

		if (!_.get(values, 'password')) {
			_.set(errors, 'password', _t('password.required'));
		}

		return errors;
	},
	destroyOnUnmount: true,
	form: FORM_MODEL.LOGIN,
})(LoginForm);
