import React, { createContext, FC, ReactNode, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import { useClickOutside } from 'common/components/useClickOutside';
import { getClientFrame } from 'common/utils/iframe-tunnel';
import useToggle from 'common/components/useToggle';
import { INSPECTOR_ID } from 'admin/constants/common';
import Scrollable from 'admin/components/common/Scrollable';
import { useWindowSize } from 'common/components/useWindowSize';
import css from './Popover.scss';

export const popoverContainerClassname = css.popoverContainer;

type PopoverContextType = ReturnType<typeof useToggle>;

export const PopoverContext = createContext<PopoverContextType | null>(null);

type PopoverContentProps = {
	children: JSX.Element;
	contentRef: React.MutableRefObject<HTMLDivElement | null>;
	contentInnerRef: React.MutableRefObject<HTMLDivElement | null>;
	onClickOutside: () => void;
	className?: string;
	beforeScrollable?: ReactNode;
	childPopoverContainer: ReactNode;
};

const PopoverContent: FC<PopoverContentProps> = props => {
	const clickOutsideParams = useRef({ extraDoc: getClientFrame().contentDocument });
	useClickOutside(props.contentRef, props.onClickOutside, clickOutsideParams.current);

	return (
		<div ref={props.contentRef} className={cn(css.content, props.className)}>
			<div ref={props.contentInnerRef} className={css.contentInner}>
				{props.beforeScrollable}
				<Scrollable className={css.scrollable}>{props.children}</Scrollable>
			</div>
			{props.childPopoverContainer}
		</div>
	);
};

type Props = {
	content: JSX.Element;
	children: ReactNode;
	beforeScrollable?: ReactNode;
	className?: string;
};

/*
Component to display floating content withing InspectorToolbar
 */
const Popover: FC<Props> = props => {
	const isOpen = useToggle(false);
	const [container, setContainer] = useState<HTMLElement | null>(null);
	const contentRef = useRef<HTMLDivElement | null>(null);
	const contentInnerRef = useRef<HTMLDivElement | null>(null);
	const childrenRef = useRef<HTMLDivElement | null>(null);
	const windowSize = useWindowSize({ debounce: 300 });
	const inspectorNode = document.getElementById(INSPECTOR_ID)!;
	const containers = document.querySelectorAll(`.${popoverContainerClassname}`);
	const nextContainer = Array.from(containers)
		.reverse()
		.find(c => !contentRef.current?.contains(c));

	useEffect(() => {
		if (nextContainer) setContainer(nextContainer as HTMLElement);
	}, [nextContainer]);

	useEffect(
		function setContentPosition() {
			if (isOpen.value && container) {
				if (!contentRef.current || !childrenRef.current || !contentInnerRef.current) return;

				const containerBBox = container.getBoundingClientRect();
				const childrenBBox = childrenRef.current.getBoundingClientRect();
				const offsetTop = childrenBBox.top; // children offset
				const offsetBottom = window.innerHeight - childrenBBox.bottom; // children offset
				const placement = offsetTop > offsetBottom ? 'top' : 'bottom'; // content placement
				const offsetX = 5; // content extra offset from children
				const offsetY = placement === 'top' ? 20 : 10; // content extra offset from children

				if (placement === 'top') {
					contentRef.current.style.bottom = `${offsetBottom + childrenBBox.height + offsetY}px`;
				} else {
					contentRef.current.style.top = `${childrenBBox.bottom + offsetY}px`;
				}

				let maxHeight;
				if (placement === 'top') maxHeight = offsetTop - containerBBox.top - offsetY;
				else maxHeight = offsetBottom - offsetY;

				contentRef.current.style.maxHeight = `${maxHeight - 5}px`;
				contentRef.current.style.left = `${containerBBox.left + offsetX}px`;
				contentRef.current.style.right = `${offsetX}px`;
			}
		},
		[isOpen.value, container, windowSize]
	);

	return (
		<PopoverContext.Provider value={isOpen}>
			{/* children */}
			<span ref={childrenRef} onClick={isOpen.toggle}>
				{props.children}
			</span>
			{/* overlay. used to disable scroll in inspector toolbar(sidebar) */}
			{isOpen.value &&
				container &&
				createPortal(
					<div
						className={css.overlay}
						style={{
							top: inspectorNode.offsetTop,
							left: inspectorNode.offsetLeft,
							width: inspectorNode.offsetWidth,
							height: inspectorNode.offsetHeight,
						}}
					/>,
					container
				)}
			{/* floating content */}
			{isOpen.value &&
				container &&
				createPortal(
					<PopoverContent
						className={props.className}
						contentRef={contentRef}
						contentInnerRef={contentInnerRef}
						onClickOutside={isOpen.toggle}
						beforeScrollable={props.beforeScrollable}
						childPopoverContainer={
							<div
								className={popoverContainerClassname}
								style={{
									top: inspectorNode.offsetTop,
									left: inspectorNode.offsetLeft,
									width: inspectorNode.offsetWidth,
									height: inspectorNode.offsetHeight,
								}}
							/>
						}
					>
						{props.content}
					</PopoverContent>,
					container
				)}
		</PopoverContext.Provider>
	);
};

export default Popover;
