import React, { ReactNode, useEffect } from 'react';
import { Field } from 'redux-form';

import t from 'utils/translate';
import Button from 'admin/components/common/Button';
import { Column } from 'admin/components/common/Grid';
import { Select, TextField } from 'admin/components/common/Form';
import { CARD_NAME_MAX_LEN } from 'admin/components/common/Form/validations';

import { fields } from './Form';

const showErrorsOn = {
	name: (meta, input) => meta.submitFailed && !meta.active,
};

const placeholder = {
	name: '',
};

type Props = {
	submitting: boolean;
	totalCards: number;
	stepIndex: string | number;
	isFromScratch: boolean;
	options?: { label: ReactNode; value: string; disabled?: boolean }[];
};

const TemplateSetup = (props: Props) => {
	useEffect(() => {
		placeholder.name = `Card ${+props.stepIndex + 1}.${+props.totalCards + 1}`;
	}, [props.stepIndex, props.totalCards]);

	return (
		<div style={{ width: 800 }}>
			<Field
				name={fields.name}
				component={TextField}
				label={t(`story.createCardModal.fields.${fields.name}.label`)}
				limit={{ max: CARD_NAME_MAX_LEN }}
				autoSelect
				autoFocus
				autoComplete="off"
				showErrorsOn={showErrorsOn.name}
				placeholder={placeholder.name}
				isLabelUppercase={false}
				isRequired
				view={TextField.view.hero}
			/>
			{props.isFromScratch && !!props.options?.length && (
				<Field
					name={fields.cardType}
					component={Select}
					menuPosition="fixed"
					label={{ children: t(`story.createCardModal.fields.${fields.cardType}.label`) }}
					options={props.options}
					isOptionDisabled={opt => opt.disabled}
					inline
				/>
			)}
			<br />
			<Column justifyContent="center">
				<Button type="submit" view="primary" smallText loading={props.submitting}>
					{t('story.createCardModal.next')}
				</Button>
			</Column>
		</div>
	);
};

export { TemplateSetup };
