import { DELETE_STORY } from 'admin/constants/actions';
import { deleteStory as deleteStoryEndpoint } from 'admin/resources';
import { createThunkAction, handleStoryNoAccessError } from 'admin/actions/helpers';

export type DeleteStoryResult = {
	storyId: string;
};

export const deleteStory = createThunkAction<DeleteStoryResult, { storyId: string }>({
	type: DELETE_STORY,
	payloadCreator: async ({ storyId }) => {
		await deleteStoryEndpoint.params({ storyId }).send();
		return { success: true, result: { storyId } };
	},
	onError: params => {
		handleStoryNoAccessError(params.dispatch, params.error as IBackendResponseError);
	},
});
