import React, { lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { routes } from 'admin/constants';

const Story = lazy(() => import('./Story'));

export default () => {
	return (
		<Switch>
			<Route path={routes.REPORTS_STORY_PAGE} component={Story} />
			<Redirect to={routes.STORIES_PAGE} />
		</Switch>
	);
};
