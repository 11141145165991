import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { Space } from 'antd';
import Loader from 'common/components/Loader';
import GoogleButton from 'common/components/GoogleButton';
import { INTEGRATIONS_SCOPE } from 'common/utils/facades/integrations-facade';
import { useAdminSelector, useAdminDispatch } from 'admin/reducers';
import Text from 'admin/components/common/Text';
import Button from 'admin/components/common/Button';
import { Icon } from 'admin/components/common/Icon';
import { Hint } from 'admin/components/common/Form';
import Tooltip from 'admin/components/common/Tooltip';
import { Column, Grid } from 'admin/components/common/Grid';
import { Select } from 'admin/components/common/Form/Select';
import PopConfirm from 'src/routes/admin/components/common/PopConfirm';
import { selectEditableStoryId } from 'admin/reducers/story-editor/selectors';
import { useGoogleAuthData } from 'admin/components/pages/OAuth/GoogleOAuth';
import { toast } from 'admin/components/common/MessageContainer/toast';
import {
	postGoogleOAuth,
	getIntegrationToken,
	UserIntegrationItem,
	getUserIntegrations,
	deleteUserIntegration,
	SpreadsheetDocument,
	getSpreadsheet,
	StorySpreadsheetIntegration,
	getStorySpreadsheetIntegrations,
	createSpreadsheetIntegration,
	deleteSpreadsheetIntegration,
} from 'admin/actions/integrations';
import FilePicker from './FilePicker';
import RangeEdit from './RangeEdit';
import RangeCreate from './RangeCreate';
import css from './Spreadsheet.scss';

const isIntegrationReady = (integration: UserIntegrationItem | null): integration is UserIntegrationItem => {
	return !!integration && integration.status === 'ready';
};

const successMessage = () => toast.success('Saved!');

export type SpreadsheetProps = {
	scope: INTEGRATIONS_SCOPE.STORY;
	type: StorySpreadsheetIntegration['type'];
	className?: string;
};

export const Spreadsheet: React.FC<SpreadsheetProps> = props => {
	const { scope, className, type } = props;
	const theme = scope === INTEGRATIONS_SCOPE.STORY ? 'dark' : 'light';
	const isExport = type === 'export_playthroughs';
	// const fields = IntegrationsFacade.getFields('version' in props ? props.version : undefined);

	const dispatch = useAdminDispatch();
	const authData = useGoogleAuthData();
	const storyId = useAdminSelector(selectEditableStoryId)!;

	const [accessToken, setAccessToken] = useState<Nullable<string>>(null);
	const [userIntegrations, setUserIntegrations] = useState<UserIntegrationItem[]>([]);
	const [userIntegration, setUserIntegration] = useState<Nullable<UserIntegrationItem>>(null);
	const [storySpreadsheets, setStorySpreadsheets] = useState<StorySpreadsheetIntegration[]>([]);
	const [currentFileInfo, setCurrentFileInfo] = useState<Nullable<SpreadsheetDocument>>(null);
	const [loading, setLoading] = useState<Nullable<string>>(null);

	const handleSetUserIntegration = useCallback(
		async (nextIntegration: UserIntegrationItem | null = null) => {
			setAccessToken(null);
			setCurrentFileInfo(null);
			setUserIntegration(nextIntegration);

			if (isIntegrationReady(nextIntegration)) {
				const response = await dispatch(getIntegrationToken({ id: nextIntegration.id }));
				if (response.success) {
					setAccessToken(response.result?.accessToken ?? null);
				}
			}
		},
		[dispatch]
	);

	const handleGetStorySpreadsheetIntegrations = useCallback(async () => {
		const response = await dispatch(getStorySpreadsheetIntegrations({ storyId }));
		setStorySpreadsheets((response.result ?? []).filter(o => o.type === type));
		return response;
	}, [dispatch, storyId, type]);

	const onRangeSaveSuccess = useCallback(async () => {
		successMessage();
		await handleGetStorySpreadsheetIntegrations();
	}, [handleGetStorySpreadsheetIntegrations]);

	// Get user API integrations
	const initialize = useCallback(async () => {
		setLoading('init');
		const userIntegrationsResponse = await dispatch(getUserIntegrations());

		if (userIntegrationsResponse.result?.length) {
			const list = userIntegrationsResponse.result;
			await handleSetUserIntegration(list.find(o => o.provider === 'google'));
			setUserIntegrations(list);
			await handleGetStorySpreadsheetIntegrations();
		}
		setLoading(null);
	}, [dispatch, handleSetUserIntegration, handleGetStorySpreadsheetIntegrations]);

	useEffect(() => {
		initialize();
	}, [initialize, authData]);

	const signIn = async (id?: string) => {
		const oauth = await dispatch(postGoogleOAuth({ id }));
		if (oauth.result?.url) {
			// see GoogleOAuth.tsx component
			window.open(oauth.result.url, 'popupWindow', 'width=500,height=600');
		}
	};

	// get the information about the spreadsheet using google's file id
	const handleGetSpreadsheet = async (params: { fileId: string; oauthId: string }) => {
		setLoading(params.fileId);
		const payload = { integrationId: params.oauthId, fileId: params.fileId };
		const response = await dispatch(getSpreadsheet(payload));
		setCurrentFileInfo(response.result?.spreadsheet ?? null);
		setLoading(null);
		return response;
	};

	const onFileUpload = async (params: { fileId: string }) => {
		if (storySpreadsheets.some(o => o.spreadsheetId === params.fileId)) {
			toast.error('File integration already exists');
			return;
		}

		const fileInfoResponse = await handleGetSpreadsheet({ ...params, oauthId: userIntegration!.id });
		if (!fileInfoResponse.success) {
			toast.error(`Failed to get file info. [${fileInfoResponse.errors?.[0].message}]`);
			return;
		}

		// create file integration
		const createSpreadsheetIntegrationResponse = await dispatch(
			createSpreadsheetIntegration({
				integrationId: userIntegration!.id,
				data: {
					storyId,
					spreadsheet: {
						id: params.fileId,
						type,
						ranges:
							type === 'database'
								? []
								: [
										{
											sheetId: fileInfoResponse.result!.spreadsheet.sheets[0].properties.sheetId,
											sheetName: fileInfoResponse.result!.spreadsheet.sheets[0].properties.title,
											range: 'A1',
											label: 'Starting cell',
										},
									],
					},
				},
			})
		);

		if (createSpreadsheetIntegrationResponse.success) {
			toast.success('Spreadsheet integrated successfully');
			await handleGetStorySpreadsheetIntegrations();
		}
	};

	const editableSheet = storySpreadsheets.find(o => o.spreadsheetId === currentFileInfo?.spreadsheetId);

	const sheetOptions = useMemo(
		() => currentFileInfo?.sheets.map(o => ({ value: o.properties.sheetId, label: o.properties.title })) ?? [],
		[currentFileInfo]
	);

	const names = useMemo(() => {
		return storySpreadsheets.flatMap(sheet => sheet.ranges.map(range => range.label));
	}, [storySpreadsheets]);

	const validateName = useCallback(
		(name: string) => (!name ? 'Required' : names.indexOf(name) > -1 ? 'Already exists' : undefined),
		[names]
	);

	const validateValue = useCallback(
		// (value: string) => (/^[A-Za-z]\d+(:[A-Za-z]\d+)?$/.test(value) ? undefined : 'Invalid'),
		(value: string) => (value.trim() ? undefined : 'Required'),
		[]
	);

	return (
		<div className={cn(css.root, className)}>
			<Hint
				theme="dark"
				text="You can seamlessly showcase content and texts from your Google Sheets directly on your product.
				 Each time you update the text in Google Sheets, it automatically reflects in real-time on the product
				  in Storycards, eliminating the need for any additional publishing steps."
				className={css.hint}
			/>
			<Space size="large" direction="vertical">
				{/* Switch existed google account integrations */}
				{userIntegrations.length > 0 && (
					<section style={{ width: 320 }}>
						<Select
							label={{ children: 'Select Google account' }}
							options={userIntegrations.map(i => ({
								value: i.id,
								label: (
									<Space size="small">
										{i.status !== 'ready' && (
											<Icon width={16} type="sc-alert" style={{ verticalAlign: '-0.2em' }} />
										)}
										{i.label}
									</Space>
								),
							}))}
							value={userIntegration?.id}
							placeholder="Select integration"
							theme={theme}
							eventListeners={{
								onChange: async value => {
									const nextIntegration = userIntegrations.find(i => i.id === value) ?? null;
									await handleSetUserIntegration(nextIntegration);
								},
							}}
							renderMenuAfterComponents={() => {
								return (
									<Button view="label-only" onClick={() => signIn()} style={{ margin: '8px auto' }}>
										<GoogleButton>Add Google account</GoogleButton>
									</Button>
								);
							}}
						/>
					</section>
				)}
				{userIntegration?.status === 'ready' && accessToken ? (
					<FilePicker token={accessToken} onChange={onFileUpload} />
				) : null}
			</Space>

			{/* Spreadsheet integration files */}
			{userIntegration && storySpreadsheets.length > 0 && isIntegrationReady(userIntegration) && (
				<section>
					<Text size="article" weight="semibold" text="Integrated files:" className={css.sectionTitle} />
					<Grid columns="2" className={css.sheetList}>
						{storySpreadsheets.map(item => {
							const onEditClick = () =>
								handleGetSpreadsheet({ fileId: item.spreadsheetId, oauthId: item.oauth.id });
							const isSelected = item.spreadsheetId === currentFileInfo?.spreadsheetId;
							const alertMsg =
								item.webhookExpiresAt && +new Date(item.webhookExpiresAt) < Date.now()
									? 'Real-time updates paused, webhook expired.'
									: item.isOutdated
										? 'Current data is outdated, syncing file updates...'
										: null;

							return (
								<Column key={item.id} colSpan="2" justifyContent="space-between" className={css.sheet}>
									<Column justifyContent="flex-start" columnGap="small">
										{loading === item.spreadsheetId && <Loader size="tiny" color="currentColor" />}
										{alertMsg ? (
											<Tooltip
												isStatic
												stylePreset="dark-1"
												destroyTooltipOnHide
												content={alertMsg}
											>
												<Icon type="sc-alert" color="var(--ra-color-error-ds)" width={16} />
											</Tooltip>
										) : null}
										<Text
											className={css.sheetName}
											size={Text.size.body}
											color={isSelected ? 'brand-light' : undefined}
											onClick={onEditClick}
										>
											{`${item.title ?? item.fileId}`}
											<em>{item.oauth.label.split(' ')[0]}</em>
										</Text>
									</Column>
									<Column alignItems="center" columnGap="small" className={css.sheetActions}>
										{item.spreadsheetUrl && (
											<a href={item.spreadsheetUrl} target="_blank" rel="noopener noreferrer">
												<Icon type="open-external" color="currentColor" width={24} />
											</a>
										)}
										<PopConfirm
											title="Delete intergration"
											description="Are you sure to delete this integration?"
											onConfirm={async () => {
												const response = await dispatch(
													deleteSpreadsheetIntegration({
														integrationId: userIntegration!.id,
														spreadsheetId: item.id,
													})
												);
												if (response.success) {
													successMessage();
													setStorySpreadsheets(prev => prev.filter(o => o.id !== item.id));
												}
											}}
										>
											<Button
												view="empty"
												shape="circle"
												size="tiny"
												color="danger"
												textSize={Text.size.label}
												textWeight={Text.weight.normal}
											>
												<Icon type="x-rounded" color="currentColor" />
											</Button>
										</PopConfirm>
									</Column>
								</Column>
							);
						})}
					</Grid>
				</section>
			)}

			{/* Spreadsheet integration range editor */}
			{userIntegration && currentFileInfo && editableSheet && (
				<section className={cn(css.variables, { [css.isExport]: isExport })}>
					{isExport ? (
						<div className={css.tHead}>Export to sheet:</div>
					) : (
						<Grid columns="14" columnGap={Grid.gap.small}>
							<Column colSpan="6" className={css.tHead}>
								Variable name
							</Column>
							<Column colSpan="3" className={css.tHead}>
								Cell
							</Column>
							<Column colSpan="4" className={css.tHead}>
								Sheet
							</Column>
						</Grid>
					)}
					{editableSheet.ranges
						.sort((a, b) => a.id - b.id)
						.map(range => (
							<RangeEdit
								key={`${range.id}-${range.sheetId}-${range.label}-${range.range}`}
								integrationId={userIntegration.id}
								storySheetIntegrationId={editableSheet.id}
								spreadsheet={currentFileInfo}
								sheetOptions={sheetOptions}
								range={range}
								theme={theme}
								onSave={onRangeSaveSuccess}
								validateName={validateName}
								validateValue={validateValue}
								isExport={isExport}
							/>
						))}
					{!isExport && (
						<RangeCreate
							key={`create-range-${editableSheet.id}`}
							integrationId={userIntegration.id}
							storySheetIntegrationId={editableSheet.id}
							spreadsheet={currentFileInfo}
							sheetOptions={sheetOptions}
							theme={theme}
							onSave={onRangeSaveSuccess}
							validateName={validateName}
							validateValue={validateValue}
						/>
					)}
				</section>
			)}

			{accessToken === null && userIntegration === null ? (
				<section>
					<Button view="label-only" onClick={() => signIn()} loading={loading === 'init'}>
						<GoogleButton>Sign in with Google</GoogleButton>
					</Button>
				</section>
			) : null}

			{userIntegration !== null && userIntegration.status !== 'ready' ? (
				<section>
					<Button size="medium" onClick={() => signIn(userIntegration?.id)}>
						Refresh token
					</Button>
				</section>
			) : null}

			{userIntegration !== null ? (
				<div
					style={{
						margin: '32px -50px 0',
						borderTop: '1px solid var(--ra-color-white-soft)',
						padding: '0 50px',
					}}
				>
					<PopConfirm
						title="Remove intergration"
						description={
							<div style={{ maxWidth: 240 }}>Google integrations will be removed across all stories</div>
						}
						okText="Remove"
						cancelText="Cancel"
						onConfirm={async () => {
							await dispatch(deleteUserIntegration({ id: userIntegration.id }));
							await initialize();
						}}
						okButtonProps={{ type: 'primary', danger: true, size: 'small' }}
						cancelButtonProps={{ type: 'text', size: 'small' }}
					>
						<Button
							view="label-only"
							color="danger"
							textSize={Text.size.label}
							textWeight={Text.weight.normal}
						>{`Remove "${userIntegration.label.split(' ')[0]}"`}</Button>
					</PopConfirm>
				</div>
			) : null}
		</div>
	);
};
