import React from 'react';
import { Field } from 'redux-form';

import translate from 'common/utils/translate';
import { TextArea } from 'admin/components/common/Form';
import IntegrationsFacade, {
	INTEGRATIONS_PAGE_VIEW,
	INTEGRATIONS_SCOPE,
} from 'common/utils/facades/integrations-facade';

import css from './Script.scss';

const t = (path: string) => translate(`common.integrations.views.${path}`);

type Props = ({ scope: INTEGRATIONS_SCOPE.STORY; version: string } | { scope: INTEGRATIONS_SCOPE.ORGANIZATION }) & {
	className?: string;
};

export const Script: React.FC<Props> = props => {
	const fields = IntegrationsFacade.getFields('version' in props ? props.version : undefined).script;
	const theme = props.scope === INTEGRATIONS_SCOPE.STORY ? 'dark' : 'light';

	return (
		<div className={props.className}>
			<div className={css.sectionField}>
				<Field
					name={fields.headScript.name}
					autoComplete="off"
					component={TextArea}
					label={{
						children: t(`${IntegrationsFacade.titles[INTEGRATIONS_PAGE_VIEW.SCRIPT]}.headScriptLabel`),
					}}
					resize="vertical"
					theme={theme}
				/>
			</div>

			<div className={css.sectionField}>
				<Field
					name={fields.bodyScript.name}
					autoComplete="off"
					component={TextArea}
					label={{
						children: t(`${IntegrationsFacade.titles[INTEGRATIONS_PAGE_VIEW.SCRIPT]}.bodyScriptLabel`),
					}}
					resize="vertical"
					theme={theme}
					description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Omnis, ratione."
				/>
			</div>
		</div>
	);
};
