import { ADMIN_SESSION } from 'common/constants';
import { LOGOUT } from 'admin/constants/actions';
import { AdminThunkAction } from 'admin/reducers';

export type LogoutAction =
	| { type: typeof LOGOUT.PENDING; payload?: undefined }
	| { type: typeof LOGOUT.FULFILLED; payload?: undefined }
	| { type: typeof LOGOUT.REJECTED; payload: string };

export function logout(): AdminThunkAction<LogoutAction> {
	return async dispatch => {
		try {
			dispatch({ type: LOGOUT.PENDING });
			localStorage.removeItem(ADMIN_SESSION);
			dispatch({ type: LOGOUT.FULFILLED });
		} catch (e) {
			dispatch({ type: LOGOUT.REJECTED, payload: (e as Error).message });
		}
	};
}
