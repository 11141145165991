import React, { lazy, Suspense } from 'react';
import { ConfigProvider, ThemeConfig } from 'antd';
import { Provider } from 'react-redux';
import { QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter, Route } from 'react-router-dom';

import store from 'admin/store';
import { OAUTH, ROOT } from 'admin/constants/routes';
import RouterSwitch from 'admin/components/common/RouterSwitch';
import ErrorBoundary from 'admin/components/common/ErrorBoundary';
import Debugger from 'common/components/Debugger';
import OAuth from 'admin/components/pages/OAuth';
import { translator } from 'utils/translate';
import queryClient from 'admin/queries/client';
import { IS_DEVEL } from 'utils/environment';

import themeBase from 'admin/styles/_export.scss';

import App from './App';

translator.source = 'admin';

// Dynamically import React Query Devtools
const ReactQueryDevtools = IS_DEVEL
	? lazy(() => import('@tanstack/react-query-devtools').then(mod => ({ default: mod.ReactQueryDevtools })))
	: null;

function getConfirmation(message, callback) {
	const allowTransition = window.confirm(message); // eslint-disable-line no-alert
	callback(allowTransition);
}

const theme: ThemeConfig = {
	token: {
		colorPrimary: themeBase.baseColorBrand,
		colorText: themeBase.baseTextColor,
		fontFamily: themeBase.baseFontFamily,
		fontSize: 14,
		lineHeight: parseFloat(themeBase.baseLineHeight),
		borderRadius: parseInt(themeBase.baseBorderRadius, 10),
		borderRadiusOuter: parseInt(themeBase.baseBorderRadius, 10),
		borderRadiusXS: parseInt(themeBase.baseBorderRadius, 10),
	},
	components: {
		Slider: {
			// ...
		},
	},
};

const Root = () => {
	return (
		<Provider store={store}>
			<QueryClientProvider client={queryClient}>
				<ConfigProvider theme={theme}>
					<ErrorBoundary>
						<BrowserRouter getUserConfirmation={getConfirmation}>
							<RouterSwitch>
								<Route path={OAUTH} component={OAuth} />
								<Route path={ROOT} component={App} />
							</RouterSwitch>
						</BrowserRouter>
						<Debugger />
					</ErrorBoundary>
				</ConfigProvider>
				{ReactQueryDevtools && (
					<Suspense fallback={null}>
						<ReactQueryDevtools initialIsOpen={false} />
					</Suspense>
				)}
			</QueryClientProvider>
		</Provider>
	);
};

export default Root;
