import { isFunction } from 'lodash';
import React from 'react';
import cn from 'classnames';
import { Slider as AntdSlider } from 'antd';
import { SliderSingleProps, SliderRangeProps } from 'antd/lib/slider';
import { Errors } from '../Errors';
import { Label, LabelProps } from '../Label';
import { ReduxFieldInputTypes, ReduxFieldMetaTypes } from '../utils';
import css from './Slider.scss';

type AntdSliderProps = Omit<SliderSingleProps | SliderRangeProps, 'onAfterChange'>;

type SliderProps = {
	input: ReduxFieldInputTypes;
	meta: ReduxFieldMetaTypes;
	theme?: 'dark' | 'light';
	className?: string;
	label?: LabelProps;
	showErrorsOn?: string;
} & AntdSliderProps;

type PureSliderProps = {
	value: AntdSliderProps['value'];
	defaultValue?: AntdSliderProps['defaultValue'];
	id?: string;
	label?: SliderProps['label'];
	className?: string;
	sliderProps?: AntdSliderProps;
};

export const SliderPure = ({ label, ...props }: PureSliderProps) => (
	<label className={cn(css.slider, props.className)}>
		{label && <Label theme="dark" htmlFor={props.id} {...label} className={cn(css.label, label.className)} />}
		<div className={cn(css.inner)}>
			<AntdSlider
				{...(props.sliderProps as SliderSingleProps)}
				id={props.id}
				defaultValue={props.defaultValue as number}
				value={props.value as number}
			/>
		</div>
	</label>
);

export const Slider = ({
	className,
	input,
	label,
	meta,
	theme = 'light',
	showErrorsOn = 'touched',
	...props
}: SliderProps) => {
	const id = `${meta.form}.${input.name}`;
	const showErrors = isFunction(showErrorsOn) ? showErrorsOn(meta, input) : meta[showErrorsOn || ''];

	return (
		<div className={cn(css.fieldSlider, css[theme], className, { [css.disabled]: props.disabled })}>
			<SliderPure id={id} value={input.value} defaultValue={input.value} label={label} sliderProps={props} />
			<Errors show={showErrors}>{meta.error}</Errors>
		</div>
	);
};

/*
 * Example
 * name - form values key
 * prop - value to assign to form {key: value}

<Field
	name="minMax"
	component={Slider}
	label={{ children: 'Range', component: 'label' }}
	range
	min={1}
	max={10}
/>
*/
