import { change } from 'redux-form';
import type { Dispatch, SetStateAction } from 'react';
import type { IOrganizationIntegrations } from 'src/types';
import { INTEGRATIONS_PAGE_VIEW, INTEGRATIONS_SCOPE } from 'utils/facades/integrations-facade';
import type { StorySpreadsheetIntegration } from 'admin/actions/integrations';
import type { SettingsTabsGeneralProps } from '../types';

export enum WebhookEventType {
	AnswerSubmission = 'AnswerSubmission',
	FormSubmission = 'FormSubmission',
	ResponseSubmission = 'ResponseSubmission',
}

export interface Webhook {
	id: string;
	url: string;
	type: string;
	active: boolean;
	label: string | null;
	events: WebhookEventType[];
	organizationId: string;
	storyId?: string | null;
	cardId?: string[] | null;
	secret?: string | null;
	updatedAt?: string;
	createdAt?: string;
}

export type IntegrationComponentProps = Pick<
	SettingsTabsGeneralProps,
	'fields' | 'version' | 'formInfo' | 'formValues' | 'story'
> & {
	onChangeView: (view: INTEGRATIONS_PAGE_VIEW) => void;
	changeForm: typeof change;
	webhooks: Webhook[];
	storySpreadsheets: StorySpreadsheetIntegration[];
	setWebhooks: Dispatch<SetStateAction<Webhook[]>>;
	scope: INTEGRATIONS_SCOPE;
	version: string;
	integrations: IOrganizationIntegrations | undefined;
};
