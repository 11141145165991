/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */

export enum INTEGRATIONS_NAMES {
	GA = 'ga',
	SCRIPT = 'script',
	CUSTOM_TRACKER = 'customTracker',
	WEBHOOKS = 'webhooks',
	URL_PARAMS = 'urlParams',
	GOOGLE_PUBLISHER_TAG = 'googlePublisherTag',
	SPREADSHEET_IMPORT = 'spreadsheetImport',
	SPREADSHEET_EXPORT = 'spreadsheetExport',
	OUTBRAIN = 'outbrain',
}

// export enum INTEGRATIONS_FIELD {
// 	GA_UNIVERSAL = 'gaUniversal',
// 	GA4 = 'ga4',
// 	HEAD_SCRIPT = 'headScript',
// 	BODY_SCRIPT = 'bodyScript',
// 	CUSTOM_TRACKER_URL = 'customTrackerUrl',
// 	URL_PARAMS_LIST = 'params',
// 	GOOGLE_PUBLISHER_TAG_LG = 'large',
// 	GOOGLE_PUBLISHER_TAG_SM = 'small',
// 	GOOGLE_PUBLISHER_TAG_ANDROID = 'android',
// 	GOOGLE_PUBLISHER_TAG_IOS = 'ios',
// 	GOOGLE_PUBLISHER_TAG_FIRST = 'showInitially',
// 	GOOGLE_PUBLISHER_TAG_BETWEEN = 'showBetweenCards',
// 	GOOGLE_PUBLISHER_TAG_TIMEOUT = 'timeout',
// 	GOOGLE_PUBLISHER_MOBILE_STRETCH = 'stretch',
// 	GOOGLE_PUBLISHER_MOBILE_STRETCH_HEIGHT = 'stretchHeight',
// 	GOOGLE_PUBLISHER_CONTAINER_ID = 'containerId',
// 	OUTBRAIN_WIDGET_ID = 'widgetId',
// 	OUTBRAIN_VISIBLE = 'visible',
// 	OUTBRAIN_CARDS = 'cards',
// }

export enum INTEGRATIONS_PAGE_VIEW {
	DEFAULT = 'DEFAULT',
	SCRIPT = 'SCRIPT',
	GOOGLE_ANALYTICS = 'GOOGLE_ANALYTICS',
	CUSTOM_TRACKER = 'CUSTOM_TRACKER',
	WEBHOOKS = 'WEBHOOKS',
	URL_PARAMS = 'URL_PARAMS',
	GOOGLE_PUBLISHER_TAG = 'GOOGLE_PUBLISHER_TAG',
	SPREADSHEET_IMPORT = 'SPREADSHEET_IMPORT',
	SPREADSHEET_EXPORT = 'SPREADSHEET_EXPORT',
	OUTBRAIN = 'OUTBRAIN',
}

export enum INTEGRATIONS_SCOPE {
	STORY,
	ORGANIZATION,
}

const INTEGRATIONS_PAGE_TITLE_MAP = {
	[INTEGRATIONS_PAGE_VIEW.DEFAULT]: 'default',
	[INTEGRATIONS_PAGE_VIEW.SCRIPT]: INTEGRATIONS_NAMES.SCRIPT,
	[INTEGRATIONS_PAGE_VIEW.GOOGLE_ANALYTICS]: INTEGRATIONS_NAMES.GA,
	[INTEGRATIONS_PAGE_VIEW.CUSTOM_TRACKER]: INTEGRATIONS_NAMES.CUSTOM_TRACKER,
	[INTEGRATIONS_PAGE_VIEW.WEBHOOKS]: INTEGRATIONS_NAMES.WEBHOOKS,
	[INTEGRATIONS_PAGE_VIEW.URL_PARAMS]: INTEGRATIONS_NAMES.URL_PARAMS,
	[INTEGRATIONS_PAGE_VIEW.GOOGLE_PUBLISHER_TAG]: INTEGRATIONS_NAMES.GOOGLE_PUBLISHER_TAG,
	[INTEGRATIONS_PAGE_VIEW.SPREADSHEET_IMPORT]: INTEGRATIONS_NAMES.SPREADSHEET_IMPORT,
	[INTEGRATIONS_PAGE_VIEW.SPREADSHEET_EXPORT]: INTEGRATIONS_NAMES.SPREADSHEET_EXPORT,
	[INTEGRATIONS_PAGE_VIEW.OUTBRAIN]: INTEGRATIONS_NAMES.OUTBRAIN,
};

export const CUSTOM_TRACKER_OPTIONS = {
	STORY_NAME: { value: '$STORY_NAME', label: 'Story name' },
	STORY_URL: { value: '$STORY_URL', label: 'Story URL' },
	CARD_NAME: { value: '$CARD_NAME', label: 'Card name' },
	PLATFORM: { value: '$PLATFORM', label: 'Platform' },
	REFERRER: { value: '$REFERRER', label: 'Referrer' },
	RESOLUTION: { value: '$RESOLUTION', label: 'Resolution' },
} as const;

export type IntegrationListItem = {
	id: INTEGRATIONS_NAMES;
	viewId: INTEGRATIONS_PAGE_VIEW;
	icon:
		| 'google-sheet-import'
		| 'google-sheet-export'
		| 'google-analytics'
		| 'script'
		| 'custom-tracker'
		| 'sync-progress'
		| 'outbrain'
		| 'google-publisher-tag';
	/* Path to integration field in story settings or in organization settings.
	   `version` param is required to generate path to story settings. */
	path: (version?: string) => string;
	translateKey: string;
	fields: string[];
	/* Where integration item can be added.
	   For example, integration can be added to organization settings or to particular story. */
	scope: INTEGRATIONS_SCOPE[];
};

const INTEGRATIONS_LIST = [
	{
		id: INTEGRATIONS_NAMES.GA,
		viewId: INTEGRATIONS_PAGE_VIEW.GOOGLE_ANALYTICS,
		translateKey: INTEGRATIONS_PAGE_TITLE_MAP[INTEGRATIONS_PAGE_VIEW.GOOGLE_ANALYTICS],
		icon: 'google-analytics',
		path: version =>
			version ? `storyVersions.${version}.settings.integrations.${INTEGRATIONS_NAMES.GA}` : INTEGRATIONS_NAMES.GA,
		fields: ['gaUniversal', 'ga4'],
		scope: [INTEGRATIONS_SCOPE.STORY, INTEGRATIONS_SCOPE.ORGANIZATION],
	},
	{
		id: INTEGRATIONS_NAMES.SPREADSHEET_IMPORT,
		viewId: INTEGRATIONS_PAGE_VIEW.SPREADSHEET_IMPORT,
		translateKey: INTEGRATIONS_PAGE_TITLE_MAP[INTEGRATIONS_PAGE_VIEW.SPREADSHEET_IMPORT],
		icon: 'google-sheet-import',
		path: version => '',
		fields: [],
		scope: [INTEGRATIONS_SCOPE.STORY],
	},
	{
		id: INTEGRATIONS_NAMES.SPREADSHEET_EXPORT,
		viewId: INTEGRATIONS_PAGE_VIEW.SPREADSHEET_EXPORT,
		translateKey: INTEGRATIONS_PAGE_TITLE_MAP[INTEGRATIONS_PAGE_VIEW.SPREADSHEET_EXPORT],
		icon: 'google-sheet-export',
		path: version => '',
		fields: [],
		scope: [INTEGRATIONS_SCOPE.STORY],
	},
	{
		id: INTEGRATIONS_NAMES.SCRIPT,
		viewId: INTEGRATIONS_PAGE_VIEW.SCRIPT,
		translateKey: INTEGRATIONS_PAGE_TITLE_MAP[INTEGRATIONS_PAGE_VIEW.SCRIPT],
		icon: 'script',
		path: version =>
			version
				? `storyVersions.${version}.settings.integrations.${INTEGRATIONS_NAMES.SCRIPT}`
				: INTEGRATIONS_NAMES.SCRIPT,
		fields: ['headScript', 'bodyScript'],
		scope: [INTEGRATIONS_SCOPE.STORY, INTEGRATIONS_SCOPE.ORGANIZATION],
	},
	{
		id: INTEGRATIONS_NAMES.CUSTOM_TRACKER,
		viewId: INTEGRATIONS_PAGE_VIEW.CUSTOM_TRACKER,
		translateKey: INTEGRATIONS_PAGE_TITLE_MAP[INTEGRATIONS_PAGE_VIEW.CUSTOM_TRACKER],
		icon: 'custom-tracker',
		path: version =>
			version
				? `storyVersions.${version}.settings.integrations.${INTEGRATIONS_NAMES.CUSTOM_TRACKER}`
				: INTEGRATIONS_NAMES.CUSTOM_TRACKER,
		fields: ['customTrackerUrl'],
		scope: [INTEGRATIONS_SCOPE.STORY, INTEGRATIONS_SCOPE.ORGANIZATION],
	},
	{
		id: INTEGRATIONS_NAMES.WEBHOOKS,
		viewId: INTEGRATIONS_PAGE_VIEW.WEBHOOKS,
		translateKey: INTEGRATIONS_PAGE_TITLE_MAP[INTEGRATIONS_PAGE_VIEW.WEBHOOKS],
		icon: 'sync-progress',
		path: version =>
			version
				? `storyVersions.${version}.settings.integrations.${INTEGRATIONS_NAMES.WEBHOOKS}`
				: INTEGRATIONS_NAMES.WEBHOOKS,
		fields: ['bridge.enabled'],
		scope: [INTEGRATIONS_SCOPE.STORY],
	},
	{
		id: INTEGRATIONS_NAMES.URL_PARAMS,
		viewId: INTEGRATIONS_PAGE_VIEW.URL_PARAMS,
		translateKey: INTEGRATIONS_PAGE_TITLE_MAP[INTEGRATIONS_PAGE_VIEW.URL_PARAMS],
		icon: 'script',
		path: version =>
			version
				? `storyVersions.${version}.settings.integrations.${INTEGRATIONS_NAMES.URL_PARAMS}`
				: INTEGRATIONS_NAMES.URL_PARAMS,
		fields: ['params', 'gameIds'],
		scope: [INTEGRATIONS_SCOPE.STORY],
	},
	{
		id: INTEGRATIONS_NAMES.GOOGLE_PUBLISHER_TAG,
		viewId: INTEGRATIONS_PAGE_VIEW.GOOGLE_PUBLISHER_TAG,
		translateKey: INTEGRATIONS_PAGE_TITLE_MAP[INTEGRATIONS_PAGE_VIEW.GOOGLE_PUBLISHER_TAG],
		icon: 'google-publisher-tag',
		path: version =>
			version
				? `storyVersions.${version}.settings.integrations.${INTEGRATIONS_NAMES.GOOGLE_PUBLISHER_TAG}`
				: INTEGRATIONS_NAMES.GOOGLE_PUBLISHER_TAG,
		fields: [
			'large',
			'small',
			'android',
			'ios',
			'showInitially',
			'showBetweenCards',
			'timeout',
			'stretch',
			'stretchHeight',
			'containerId',
		],
		scope: [INTEGRATIONS_SCOPE.STORY, INTEGRATIONS_SCOPE.ORGANIZATION],
	},
	{
		id: INTEGRATIONS_NAMES.OUTBRAIN,
		viewId: INTEGRATIONS_PAGE_VIEW.OUTBRAIN,
		translateKey: INTEGRATIONS_PAGE_TITLE_MAP[INTEGRATIONS_PAGE_VIEW.OUTBRAIN],
		icon: 'outbrain',
		path: version =>
			version
				? `storyVersions.${version}.settings.integrations.${INTEGRATIONS_NAMES.OUTBRAIN}`
				: INTEGRATIONS_NAMES.OUTBRAIN,
		fields: ['visible', 'cards', 'desktopWidgetId', 'mobileWidgetId'],
		scope: [INTEGRATIONS_SCOPE.STORY],
	},
] as const satisfies IntegrationListItem[];

export type IntegrationByName<T extends INTEGRATIONS_NAMES> = (typeof INTEGRATIONS_LIST)[number] & { id: T };
export type IntegrationsFields<T extends INTEGRATIONS_NAMES> = Record<
	IntegrationByName<T>['fields'][number],
	{ name: string }
>;
export type Integrations<T extends INTEGRATIONS_NAMES> = {
	[K in T]: IntegrationsFields<K>;
};

class IntegrationsFacade {
	public items = INTEGRATIONS_LIST;
	public views = INTEGRATIONS_PAGE_VIEW;
	public titles = INTEGRATIONS_PAGE_TITLE_MAP;

	public getFieldById(id: INTEGRATIONS_PAGE_VIEW) {
		return this.items.find(item => item.viewId === id);
	}

	public getFields(version: string | undefined = undefined) {
		const result = this.items.reduce((acc, item) => {
			const fields = item.fields.reduce(
				(accumulator, field: IntegrationListItem['fields'][number]) => {
					accumulator[field] = { name: `${item.path(version)}.${field}` };
					return accumulator;
				},
				{} as IntegrationsFields<typeof item.id>
			);

			acc[item.id] = fields;
			return acc;
		}, {} as Integrations<INTEGRATIONS_NAMES>);

		return result;
	}
}

export default new IntegrationsFacade();
