import React from 'react';

import t from 'utils/translate';
import Text from 'admin/components/common/Text';
import Button from 'admin/components/common/Button';
import TwoFAConfirmationForm, { type FormValues } from 'admin/components/common/TwoFAConfirmationForm';
import { Column, Grid } from 'admin/components/common/Grid';
import { Modal, ModalBody, ModalFooter } from 'admin/components/common/Modal';
import { MODAL } from 'admin/constants/common';
import type { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';
import { toast } from 'admin/components/common/MessageContainer/toast';
import { useAdminDispatch } from 'admin/reducers';
import { auth } from 'admin/actions';

import css from './MobileAppAuthenticatorRemovalModal.scss';

type Props = ModalManagerProvidedProps<MODAL.TWO_FA>;

const MobileAppAuthenticatorRemovalModal: React.FC<Props> = props => {
	const { open, close } = props;
	const [submitting, setSubmitting] = React.useState(false);
	const [dirty, setDirty] = React.useState(false);
	const dispatch = useAdminDispatch();
	const formId = React.useId();
	const title = (
		<Text
			size={Text.size.subtitle}
			weight={Text.weight.semibold}
			compact
			text={t('profile.2fa.modal.removal.title')}
		/>
	);

	const onFormSubmit = async (values: FormValues) => {
		const response = await dispatch(auth.post.totpRemove({ code: values.code, password: values.password }));

		if (response.success) {
			await dispatch(auth.get.totp());
			toast.success(t('profile.2fa.modal.removal.success'));

			dispatch(auth.post.totpRequirement());
			close();
		}
	};

	const handleFormStateChange = (s: boolean, d: boolean) => {
		setSubmitting(s);
		setDirty(d);
	};

	return (
		<Modal title={title} open={open} destroyOnClose width="640px">
			<ModalBody>
				<Grid columns="12" columnGap="large">
					<Column colSpan="12">
						<Text className={css.description} size="paragraph">
							{t('profile.2fa.modal.removal.description')}
						</Text>
						<TwoFAConfirmationForm
							id={formId}
							onSubmit={onFormSubmit}
							onStateChange={handleFormStateChange}
						/>
					</Column>
				</Grid>
			</ModalBody>

			<ModalFooter>
				<Column justifyContent="flex-start">
					<Button form={formId} disabled={!dirty || submitting} view="primary" type="submit">
						{t('profile.2fa.modal.removal.remove')}
					</Button>
					<Button disabled={submitting} view="secondary" onClick={close}>
						{t('profile.2fa.modal.removal.cancel')}
					</Button>
				</Column>
			</ModalFooter>
		</Modal>
	);
};

export default MobileAppAuthenticatorRemovalModal;
