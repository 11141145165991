import { toast, ToastType, ToastProps } from 'admin/components/common/MessageContainer/toast';

type NotificationType = { type: ToastType } & Pick<ToastProps, 'duration' | 'onClose'>;

type NotificationParams =
	| false
	| {
			type?: NotificationType['type'];
			duration?: NotificationType['duration'];
			onClose?: NotificationType['onClose'];
	  };

const DEFAULT_DURATION: Record<NotificationType['type'], number> = {
	error: 3,
	info: 0.5,
	warn: 0.5,
	success: 0.5,
};

/**
 * Custom error handling used in Admin panel
 *
 *  @info
 *  AdminError expected to be caught by ErrorBoundary, it must be thrown from reachable section, which is not:
 *  - Event handlers
 *  - Asynchronous code
 *
 * @class AdminError
 * @extends Error
 *
 * @example
 *  throw new AdminError('Error!')                                   // with default notification
 *  throw new AdminError('Error!', { type: 'error', duration: 0.5 }) // with custom notification
 *  throw new AdminError('Error!', false)                            // turn off notification
 *
 * @param {String}          message                     - message content
 * @param {Object|Boolean}  [notification]              - config to show custom ui message
 * @param {String}          [notification.type]         - info|warn|error|success
 * @param {Number}          [notification.duration]     - duration to show notification in seconds
 * @param {Function}        [notification.onClose]      - callback
 */
export default class AdminError extends Error {
	notification: NotificationType = { type: 'error' };

	name: string = 'AdminError';

	constructor(message: ToastProps['content'], notification: NotificationParams = {}) {
		// Pass remaining arguments (including vendor specific ones) to parent constructor
		super(message);

		// Maintains proper stack trace for where our error was thrown (only available on V8)
		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, AdminError);
		}

		if (notification) {
			this.notification = { ...this.notification, ...notification };

			if (this.notification.duration === undefined) {
				this.notification.duration = DEFAULT_DURATION[this.notification.type];
			}

			if (typeof toast[this.notification.type] === 'function') {
				toast[this.notification.type](message, this.notification.duration, this.notification.onClose);
			}
		}
	}
}
