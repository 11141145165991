import React, { PureComponent } from 'react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import _ from 'lodash';

import { orderMediaQueryConfig } from 'common/utils/story-media-query';

import config from '../config';

import css from './Ruler.scss';

type Props = {
	className?: string;
	currentMediaQuery: string;
	cbRef?: {};
	isFullScreen?: boolean;
	el: {};
	mediaQuery: {
		defaultPlatform: string;
		config: any;
		currentMediaQuery?: string;
	};
};

export default class Ruler extends PureComponent<Props> {
	static defaultProps = {
		className: '',
		cbRef: null,
		isFullScreen: false,
	};

	get el() {
		return this.props.el;
	}

	getMQLimits = mediaQuery => {
		const orderedMediaQueryArray = orderMediaQueryConfig(mediaQuery);
		const limits = _.map(orderedMediaQueryArray, (mq, i) => {
			const updatedMQ = _.cloneDeep(mq);

			if (!updatedMQ.config.minWidth) {
				updatedMQ.config.minWidth =
					i !== orderedMediaQueryArray.length - 1 ? orderedMediaQueryArray[i + 1].config.maxWidth! + 1 : 0;
			}
			if (!updatedMQ.config.maxWidth) {
				updatedMQ.config.maxWidth =
					i !== 0 ? orderedMediaQueryArray[i - 1].config.minWidth! - 1 : Number.MAX_VALUE;
			}

			return updatedMQ;
		});

		return limits;
	};

	renderDivisions = () => {
		const arrayLength = (window.innerWidth + 100 - (window.innerWidth % 100)) / 10;
		const { isFullScreen } = this.props;

		return _.map(Array.from({ length: arrayLength }), (item, index) => (
			<div
				className={classNames(css.division, {
					[css.division50]: (index * 10) % 50 === 0,
					[css.division100]: (index * 10) % 100 === 0,
					[css.hidden]: isFullScreen && index === 0,
				})}
				style={{ left: `${index * 10}px` }}
				key={`div-${index}`}
			>
				{(index * 10) % 100 === 0 && <p className={css.label}>{index * 10}</p>}
			</div>
		));
	};

	renderLimits = () => {
		const { mediaQuery } = this.props;
		const mediaQueriesWithLimits = this.getMQLimits(mediaQuery);

		return (
			<div className={css.limits}>
				{_.map(mediaQueriesWithLimits, (mq, index) => {
					const minWidth = _.get(mq, 'config.minWidth');
					const maxWidth = _.get(mq, 'config.maxWidth');
					const limits = maxWidth !== Number.MAX_VALUE ? `${minWidth}px - ${maxWidth}px` : `${minWidth}+ px`;
					const key = mq.key === 'default' ? `default.${index}` : mq.key;

					return (
						<div className={css.limitMark} key={key} style={{ left: `${minWidth}px` }}>
							<p className={css.limitMarkTitleWrap}>
								<span className={css.limitMarkTitle}>{`${_.get(mq, 'config.uiName')}: `}</span>
								<span>{limits}</span>
							</p>
						</div>
					);
				})}
			</div>
		);
	};

	renderDevices = () => {
		return (
			<div className={css.devices}>
				{_.map(config.points, (devices, key) => (
					<div className={css.devicesMark} style={{ left: `${key}px` }} key={key} />
				))}
			</div>
		);
	};

	renderCurrentMQ = () => {
		const { mediaQuery } = this.props;
		const currentMediaQuery = _.get(mediaQuery, 'currentMediaQuery', this.props.currentMediaQuery);
		const mediaQueriesWithLimits = this.getMQLimits(mediaQuery);
		const currentMQLimits = _.find(mediaQueriesWithLimits, ['key', currentMediaQuery]);

		if (!currentMQLimits) return null;

		const start = currentMQLimits.config.minWidth ?? 0;
		const end = currentMQLimits.config.maxWidth ?? 0;
		const width = end !== Number.MAX_VALUE ? end - start : window.innerWidth;

		return <div className={css.currentMediaQuery} style={{ left: `${start}px`, width }} />;
	};

	render() {
		const { className, cbRef } = this.props;

		return ReactDOM.createPortal(
			<div className={classNames(css.ruler, className)}>
				{/* @ts-expect-error ts-migrate FIXME */}
				<div ref={cbRef} className={css.rulerInner}>
					{this.renderDivisions()}
					{this.renderLimits()}
					{this.renderDevices()}
					{this.renderCurrentMQ()}
				</div>
			</div>,
			// @ts-expect-error ts-migrate FIXME
			this.el
		);
	}
}
