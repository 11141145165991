import { isFinite } from 'lodash';
import React, { createRef, ReactNode } from 'react';
import classNames from 'classnames';

import css from './ScrollBar.scss';

type Props = {
	children: ReactNode;
	className?: string;
	contentWrapRef: React.RefObject<HTMLDivElement>;
	contentRef: React.RefObject<HTMLDivElement>;
	onProgressUpdate?: (progress: number) => void;
	onScroll?: (distance: number) => void;
	onScrollingRatioUpdate: (ratio: number) => void;
	showScrollBarAlways?: boolean;
	darkMode?: boolean;
};

type State = {
	scrollingMaxDistance: number;
	scrollingRatio: number;
	isScrolling: boolean;
};

class ScrollBar extends React.Component<Props, State> {
	scrollHandleRef = createRef<HTMLDivElement>();

	scrollTrackRef = createRef<HTMLDivElement>();

	scrollingDistance = 0;

	targetDistance = 0;

	isWheelListenerRegistered = false;

	scrollingFlagTimeoutID?: ReturnType<typeof setTimeout>;

	startDragPosY = 0;

	startHandlerPosY = 0;

	currentHandlerPosY = 0;

	scrollingRatioMin = 0.2;

	state = {
		scrollingMaxDistance: 1,
		scrollingRatio: 1,
		isScrolling: false,
	};

	componentDidMount() {
		this.updateScrollingDistance(0);
		this.onWindowResize();
		window.addEventListener('resize', this.onWindowResize);

		if (this.props.contentWrapRef && this.props.contentWrapRef.current) {
			this.props.contentWrapRef.current.addEventListener('wheel', this.onMouseWheel);
		}
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		if (this.props.children !== prevProps.children) {
			this.onWindowResize();
		}

		if (this.props.contentWrapRef !== prevProps.contentWrapRef) {
			if (this.props.contentWrapRef.current && !this.isWheelListenerRegistered) {
				this.props.contentWrapRef.current.addEventListener('wheel', this.onMouseWheel);
				this.isWheelListenerRegistered = true;
			}
		}

		if (this.state.scrollingRatio !== prevState.scrollingRatio) {
			this.props.onScrollingRatioUpdate(this.state.scrollingRatio);
		}
	}

	componentWillUnmount() {
		clearTimeout(this.scrollingFlagTimeoutID);

		document.removeEventListener('mousemove', this.onDocumentMouseListener);
		document.removeEventListener('mouseup', this.onDocumentMouseListener);

		window.removeEventListener('resize', this.onWindowResize);
		this.props.contentWrapRef.current?.removeEventListener('wheel', this.onMouseWheel);
	}

	update = () => {
		this.onWindowResize();
	};

	updateScrollingDistance = (distance: number) => {
		const { contentRef, onProgressUpdate, onScroll } = this.props;

		if (!contentRef) {
			return;
		}

		const scrollTrackH = this.scrollTrackRef.current?.offsetHeight ?? 0;
		const scrollHandlerH = this.scrollHandleRef.current?.offsetHeight ?? 0;
		const scrollHandlerMaxDistance = scrollTrackH - scrollHandlerH;

		const { scrollingMaxDistance } = this.state;
		const updatedScrollingProgress = distance / scrollingMaxDistance;
		const handleTargetY = updatedScrollingProgress * scrollHandlerMaxDistance;
		const content = contentRef.current;
		const scrollHandle = this.scrollHandleRef.current;

		if (content) {
			content.style.transform = `translate3d(0, -${updatedScrollingProgress * scrollingMaxDistance}px, 0)`;
		}

		if (scrollHandle) {
			scrollHandle.style.transform = `translate3d(0, ${handleTargetY}px, 0)`;
			this.currentHandlerPosY = handleTargetY;
		}

		const isUpdated = this.scrollingDistance !== distance;
		this.scrollingDistance = distance;

		if (isUpdated) {
			onProgressUpdate?.(updatedScrollingProgress);
			onScroll?.(distance);

			if (!this.state.isScrolling) {
				this.setState({ isScrolling: true });
			}

			clearTimeout(this.scrollingFlagTimeoutID);
			this.scrollingFlagTimeoutID = setTimeout(this.resetScrollingFlag, 3000);
		}
	};

	resetScrollingFlag = () => {
		this.setState({ isScrolling: false });
	};

	onWindowResize = () => {
		const { contentWrapRef, contentRef } = this.props;

		if (contentWrapRef && contentRef && contentWrapRef.current && contentRef.current) {
			const ratio = contentWrapRef.current.offsetHeight / contentRef.current.offsetHeight;
			this.setState({
				scrollingMaxDistance: Math.max(
					contentRef.current.offsetHeight - contentWrapRef.current.offsetHeight,
					0
				),
				scrollingRatio: Math.min(isFinite(ratio) ? ratio : 0, 1),
			});
		}
	};

	onContainerMouseListener: React.MouseEventHandler<HTMLDivElement> = e => {
		switch (e.type) {
			case 'mouseenter': {
				if (!this.state.isScrolling) {
					this.setState({ isScrolling: true });
				}

				clearTimeout(this.scrollingFlagTimeoutID);
				break;
			}

			case 'mouseleave': {
				clearTimeout(this.scrollingFlagTimeoutID);
				this.scrollingFlagTimeoutID = setTimeout(this.resetScrollingFlag, 3000);
				break;
			}

			default: {
				break;
			}
		}
	};

	onScrollHandleMouseDown: React.MouseEventHandler<HTMLDivElement> = e => {
		this.startDragPosY = e.screenY;
		this.startHandlerPosY = this.currentHandlerPosY;

		document.addEventListener('mousemove', this.onDocumentMouseListener);
		document.addEventListener('mouseup', this.onDocumentMouseListener);
	};

	onDocumentMouseListener = (e: MouseEvent) => {
		const currentPosY = e.screenY;
		const scrollHandle = this.scrollHandleRef.current;

		switch (e.type) {
			case 'mousemove': {
				if (scrollHandle) {
					const { scrollingMaxDistance } = this.state;
					const diff = currentPosY - this.startDragPosY;
					const handleTargetY = this.startHandlerPosY + diff;

					const scrollTrackH = this.scrollTrackRef.current?.offsetHeight ?? 0;
					const scrollHandlerH = this.scrollHandleRef.current?.offsetHeight ?? 0;
					const scrollHandlerMaxDistance = scrollTrackH - scrollHandlerH;
					const updatedScrollingProgress = handleTargetY / scrollHandlerMaxDistance;
					let distance = updatedScrollingProgress * scrollingMaxDistance;

					if (distance < 0) {
						distance = 0;
					}

					if (distance > scrollingMaxDistance) {
						distance = scrollingMaxDistance;
					}

					this.updateScrollingDistance(distance);
				}
				break;
			}

			case 'mouseup': {
				document.removeEventListener('mousemove', this.onDocumentMouseListener);
				document.removeEventListener('mouseup', this.onDocumentMouseListener);
				break;
			}

			default: {
				break;
			}
		}
	};

	onMouseWheel = (e: WheelEvent) => {
		e.preventDefault();

		const { scrollingMaxDistance } = this.state;
		const { deltaY } = e;

		this.targetDistance =
			this.scrollingDistance +
			(this.targetDistance < 0 || this.targetDistance > scrollingMaxDistance ? deltaY / 2 : deltaY);

		if (this.targetDistance < 0) {
			this.targetDistance = 0;
		}

		if (this.targetDistance > scrollingMaxDistance) {
			this.targetDistance = scrollingMaxDistance;
		}

		this.updateScrollingDistance(this.targetDistance);
	};

	render() {
		const { className, showScrollBarAlways, darkMode } = this.props;
		const { scrollingRatio, isScrolling } = this.state;

		return (
			<div
				className={classNames(css.scrollBar, className, {
					[css.darkMode]: darkMode,
					[css.hide]: showScrollBarAlways ? false : scrollingRatio === 1 || !isScrolling,
				})}
				onMouseEnter={this.onContainerMouseListener}
				onMouseLeave={this.onContainerMouseListener}
			>
				<div className={css.scrollTrack} ref={this.scrollTrackRef} />
				<div
					className={css.scrollHandle}
					onMouseDown={this.onScrollHandleMouseDown}
					style={{ height: `${Math.max(scrollingRatio, this.scrollingRatioMin) * 100}%` }}
					ref={this.scrollHandleRef}
				/>
			</div>
		);
	}
}

export default ScrollBar;
