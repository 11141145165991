import { nanoid } from 'nanoid';
import { Action, Middleware } from 'redux';
import { adminLog } from 'utils/helpers';
import { AdminReducerState, AdminThunkDispatch } from 'admin/reducers';
import { CardEditorType } from 'types/story';

type StateSync = { __stateSync?: boolean };

type StateSyncAction<A extends Action = Action> = A & { meta?: Record<string, any> } & StateSync;

type MessageData = {
	action?: StateSyncAction;
	sender?: {
		storyId?: string;
		cardId?: string;
		cardEditor: CardEditorType;
		__wid: string;
	};
};

type MiddlewareType = Middleware<{}, AdminReducerState, AdminThunkDispatch<StateSyncAction>>;

type onMessageParams = {
	action: StateSyncAction;
	sender: MessageData['sender'];
	state: AdminReducerState;
	dispatch: AdminThunkDispatch<StateSyncAction & Required<StateSync>>;
};

type Config = {
	channel: string;
	// sender filter
	whiteList?: string[];
	blackList?: string[];
	predicate?: (action: StateSyncAction) => boolean;
	// receiver
	onMessage?: (p: onMessageParams) => void;
};

const __wid: string = nanoid();

const log = adminLog.extend('middleware:state-sync');

/**
 * Get action validator function
 */
const getActionValidatorFn = ({
	whiteList,
	blackList,
	predicate,
}: Pick<Config, 'whiteList' | 'blackList' | 'predicate'>) => {
	const isSyncAction = (action: StateSyncAction) => action.__stateSync;
	const isValidActionType = (action: StateSyncAction) => 'type' in action;

	return (action: StateSyncAction) => {
		const isValid = !isSyncAction(action) && isValidActionType(action);

		if (!isValid) {
			return false;
		}

		if (Array.isArray(blackList)) {
			if (blackList.includes(action.type)) return false;
		}

		if (Array.isArray(whiteList)) {
			if (whiteList.includes(action.type)) return true;
			if (!predicate) return false;
		}

		if (predicate) {
			return predicate(action);
		}

		return isValid;
	};
};

/**
 * Middleware to sync redux state across browser tabs/windows.
 */
const stateSyncMiddleware = (config: Config): MiddlewareType => {
	if (!('BroadcastChannel' in window)) {
		return store => next => action => next(action);
	}

	log('CREATE', { __wid });
	const channel = new BroadcastChannel(config.channel);
	const isActionAllowed = getActionValidatorFn({
		whiteList: config.whiteList,
		blackList: config.blackList,
		predicate: config.predicate,
	});
	let channelListener;

	return store => {
		// Channel listener
		if (!channelListener) {
			channelListener = (event: MessageEvent<MessageData>) => {
				if (event.data?.sender?.__wid === __wid || !event.data?.action) {
					// ignore messages from current window and invalid messages
					return;
				}

				const payload = {
					action: event.data.action,
					sender: event.data.sender,
					state: store.getState(),
					dispatch: store.dispatch,
				};

				// log('GET', event.data.action.type, payload);

				// dispatch received actions
				if (config.onMessage) {
					config.onMessage(payload);
				} else {
					store.dispatch({ ...event.data.action, __stateSync: true });
				}
			};

			channel.onmessage = channelListener;
		}

		// Post messages
		return next => (action: unknown) => {
			// call next() to update state at this stage
			const returnValue = next(action);

			try {
				if (isActionAllowed(action as StateSyncAction)) {
					const state = store.getState(); // state after dispatch
					const data: MessageData = {
						action: action as StateSyncAction,
						sender: {
							__wid,
							storyId: state.storyEditor.story?.id,
							cardId: state.cardEditor?.present.data?._id,
							cardEditor: state.cardEditor?.present,
						},
					};

					// post message to other tabs
					// log('POST', action.type, data);
					channel.postMessage(data);
				}
			} catch (error) {
				console.error(error);
			}

			return returnValue;
		};
	};
};

export { onMessageParams, StateSyncAction };

export default stateSyncMiddleware;
