import React from 'react';

import t from 'common/utils/translate';
import { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';

import Text from 'admin/components/common/Text';
import Button from 'admin/components/common/Button';
import { Modal } from 'admin/components/common/Modal';
import { MODAL } from 'admin/constants/common';
import Layout from 'admin/components/pages/Stories/CreateStoryModal/ShareComponents/Layout';

import Create from './Create';

const CreateVideoModal: React.FC<ModalManagerProvidedProps<MODAL.CREATE_VIDEO>> = ({ close, ...props }) => {
	const requestsController = React.useRef(new AbortController());

	const onCloseModal = () => {
		requestsController.current.abort('CANCELED_BY_USER');
		close();
	};

	return (
		<Modal open={props.open} fullScreen title={null} destroyOnClose transitionName="">
			<Layout.Layout>
				<Layout.Header>
					<div />
					<Text compact size={Text.size.subheading} text={t(`createEditVideoModal.create.title`)} />
					<Button view="label-only" onClick={onCloseModal} textWeight={Text.weight.normal}>
						{t('createEditVideoModal.discard')}
					</Button>
				</Layout.Header>

				<Layout.CenteredContent>
					<Create controller={requestsController.current} onSuccess={onCloseModal} />
				</Layout.CenteredContent>
			</Layout.Layout>
		</Modal>
	);
};

export default CreateVideoModal;
