import _ from 'lodash';

import type { StoryMediaPlatform, StoryMediaQuery, StorySingleMediaQueryConfig } from 'types/story';
import type { EmbedStoryParams } from 'src/sdk';

import { scrollFix } from 'client/components/pages/Story/ScrollbarFix';
import { templateString } from './template-string';

/**
 * Order media query config of Story
 *
 * @param config {Object} Story.data.mediaQuery.config
 * @param defaultPlatform {String} Story.data.mediaQuery.defaultPlatform
 * @param defaultFirst {Boolean} Story.data.mediaQuery.defaultPlatform is first
 * @param minOrder {String} The sort orders of iteratees
 * @param maxOrder {String} The sort orders of iteratees
 * @return {Array}
 */
export function orderMediaQueryConfig(
	{ config, defaultPlatform }: StoryMediaQuery,
	{
		defaultFirst = false,
		minOrder = 'desc',
		maxOrder = 'desc',
	}: { defaultFirst?: boolean; minOrder?: 'asc' | 'desc'; maxOrder?: 'asc' | 'desc' } = {}
) {
	// Transform to array with media query config key
	let mediaQueryOrderedArray = Object.entries(config).map(([k, v]) => ({ key: k as StoryMediaPlatform, config: v }));

	// Order by (eg. min1000 min1200 default max900 max800)
	type C = (typeof mediaQueryOrderedArray)[number];
	const [defaultPlatformConfig, minPlatformsConfig, maxPlatformsConfig]: [C[], C[], C[]] = [[], [], []];

	mediaQueryOrderedArray.forEach(o => {
		if (o.config.maxWidth) {
			maxPlatformsConfig.push(o);
		}
		if (o.config.minWidth) {
			minPlatformsConfig.push(o);
		}
		if (o.key === defaultPlatform) {
			defaultPlatformConfig.push(o);
		}
	});

	const sortedMinPlatforms = _.orderBy(minPlatformsConfig, ['config.minWidth'], [minOrder]);
	const sortedMaxPlatforms = _.orderBy(maxPlatformsConfig, ['config.maxWidth'], [maxOrder]);

	if (defaultFirst) {
		mediaQueryOrderedArray = [...defaultPlatformConfig, ...sortedMinPlatforms, ...sortedMaxPlatforms];
	} else {
		mediaQueryOrderedArray = [...sortedMinPlatforms, ...defaultPlatformConfig, ...sortedMaxPlatforms];
	}

	return mediaQueryOrderedArray;
}

export const getPlatformRangesInfo = (config: StorySingleMediaQueryConfig, mediaQuery: StoryMediaQuery) => {
	const { maxWidth, minWidth } = config;
	const maxTemplate = templateString('{{ value }}px and less');
	const minTemplate = templateString('{{ value }}px and more');
	const rangeTemplate = templateString('{{ min }}px – {{ max }}px');

	if (maxWidth) {
		return maxTemplate({ value: maxWidth });
	}

	if (minWidth) {
		return minTemplate({ value: minWidth });
	}

	const getDefaultRange = () => {
		const { config: mediaQueryConfig } = mediaQuery;
		const configArray = _.map(mediaQueryConfig, (value, key) => ({ value, key }));

		const lowestMinWidth = _.minBy(configArray, ({ value }) => value.minWidth);
		const largestMaxWidth = _.maxBy(configArray, ({ value }) => value.maxWidth);

		let min = _.get(lowestMinWidth, 'value.minWidth');
		let max = _.get(largestMaxWidth, 'value.maxWidth');

		if (min) {
			min -= 1;
		}
		if (max) {
			max += 1;
		}
		if (min && !max) {
			return maxTemplate({ value: min });
		}
		if (max && !min) {
			return minTemplate({ value: max });
		}

		return rangeTemplate({ min, max });
	};

	return getDefaultRange();
};

/**
 * Get current media query in Story.data.mediaQuery.config depends on window size
 *
 * @param mediaQuery {Object} Story.data.mediaQuery
 * @param mediaQuery.defaultPlatform {String}
 * @param mediaQuery.config {Object}
 * @param targetWindow {Object} document window
 * @param embedViewport {Object} viewport size of the iframe parent (used when story is embed to other website)
 * @return {string}
 */
export const getCurrentMediaQueryKey = ({
	mediaQuery,
	targetWindow = window,
	embedViewport,
}: {
	mediaQuery: StoryMediaQuery;
	targetWindow?: Window;
	embedViewport?: EmbedStoryParams['viewport'];
}): StoryMediaPlatform => {
	let mq = mediaQuery.defaultPlatform;

	const orderedMediaQueryConfig = orderMediaQueryConfig(mediaQuery, {
		defaultFirst: true,
		minOrder: 'asc',
		maxOrder: 'desc',
	});

	const isScrollFixed = scrollFix.check();
	const scrollbarWidth = window.innerWidth - document.body.clientWidth;
	const viewportWidthOrigin = embedViewport ? embedViewport.w : targetWindow.innerWidth;
	const viewportWidth = viewportWidthOrigin - (isScrollFixed ? scrollbarWidth : 0);

	_.forEach(orderedMediaQueryConfig, ({ config: value, key }) => {
		const mediaValue = Math.max(value.minWidth ?? 0, value.maxWidth ?? 0);

		if (
			(value.minWidth !== null && viewportWidth >= mediaValue) ||
			(value.maxWidth !== null && viewportWidth <= mediaValue)
		) {
			mq = key;
		}
	});

	return mq;
};

/**
 * @param mediaQuery Story.data.mediaQuery
 * @param currentMediaQuery media query key(name)
 * @param containerWidth
 */
export const getMediaQueryBoundaries = ({
	mediaQuery,
	currentMediaQuery,
	containerWidth = window.innerWidth,
}: {
	mediaQuery: StoryMediaQuery;
	currentMediaQuery: StoryMediaPlatform;
	containerWidth?: number;
}) => {
	const orderedMediaQueryArray = orderMediaQueryConfig(mediaQuery);
	const currentIndex = _.findIndex(orderedMediaQueryArray, { key: currentMediaQuery });

	let min: number | null = _.get(orderedMediaQueryArray[currentIndex], 'config.minWidth');
	let max: number | null = _.get(orderedMediaQueryArray[currentIndex], 'config.maxWidth');

	if (!min) {
		min = !_.isEmpty(orderedMediaQueryArray[currentIndex + 1])
			? _.get(orderedMediaQueryArray[currentIndex + 1], 'config.maxWidth', 0) + 1
			: 320;
	}

	if (!max) {
		max = !_.isEmpty(orderedMediaQueryArray[currentIndex - 1])
			? _.get(orderedMediaQueryArray[currentIndex - 1], 'config.minWidth', 0) - 1
			: containerWidth;
	}

	return {
		min,
		max,
		isWidest: orderedMediaQueryArray[0].key === currentMediaQuery,
		containerWidth,
	};
};
