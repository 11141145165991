import produce from 'immer';
import type { StorySettingsType, StoryVersionType } from 'types/story';
import { updateLatestStory as updateLatestStoryEndpoint } from 'admin/resources';
import { UPDATE_LATEST_STORY } from 'admin/constants/actions';
import { getStory } from 'admin/actions/story/get-story';
import { handleStoryNoAccessError, createThunkAction } from 'admin/actions/helpers';
import { selectCurrentVersion } from 'admin/reducers/version-reducer';
import { StoryFacade } from 'utils/facades';
import { selectOpCounter } from 'admin/reducers/story-editor/selectors';
import { getResponseErrors } from 'common/resources/api-response';
import { MODAL } from 'admin/constants/common';
import { setModal } from 'admin/actions/set-modal';

const defaultParams = {
	getStoryOnComplete: true,
};

export type UpdateLatestStoryParams = {
	storyId: string;
	storyData: StoryVersionType['data'];
	settings: StorySettingsType;
	getStoryOnComplete?: boolean;
};

export type UpdateLatestStoryAction =
	| {
			type: typeof UPDATE_LATEST_STORY.PENDING;
			payload: IBackendBody;
	  }
	| {
			type: typeof UPDATE_LATEST_STORY.FULFILLED;
			payload: IBackendBody;
	  }
	| {
			type: typeof UPDATE_LATEST_STORY.REJECTED;
			payload: IBackendBody;
	  };

export const onUpdateVersionError: Parameters<typeof createThunkAction>[0]['onError'] = params => {
	const responseError = params.error as IBackendResponseError;

	handleStoryNoAccessError(params.dispatch, responseError);

	const error = getResponseErrors(responseError)?.[0];
	if (error?.errorType === 'StoryOpCounterOutOfSync') {
		params.dispatch(
			setModal({
				id: MODAL.INFORMATION,
				data: {
					view: 'STORY/OP_COUNTER_OUT_OF_SYNC',
					title: error.message,
				},
			})
		);
	}
};

/**
 * POST latest story to BE
 * SUCCESS:
 *  1) update "updateAt" field in "story-editor-reducer";
 *  2) [optional] get story
 */
export const updateLatestStory = createThunkAction<void, UpdateLatestStoryParams>({
	type: UPDATE_LATEST_STORY,
	retries: 3,
	retriesTimeout: 1000,
	alertDuration: 5000,
	payloadCreator: async (_params, { getState, dispatch }) => {
		const params = { ...defaultParams, ..._params };
		const state = getState();
		const version = selectCurrentVersion(state);
		const opCounter = selectOpCounter(state);

		if (version !== StoryFacade.VERSIONS.latest) {
			return { success: false };
		}

		const payload = {
			storyData: produce(params.storyData, draft => {
				if (process.env.VERSION) draft.appVersion = process.env.VERSION;
			}),
			settings: params.settings,
			opCounter,
		};

		await updateLatestStoryEndpoint.params({ storyId: params.storyId }).send(payload);

		if (_params.getStoryOnComplete) {
			await dispatch(getStory({ storyId: params.storyId }));
		}

		return { success: true };
	},
	onError: onUpdateVersionError,
});
