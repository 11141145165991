import { selectEditableStoryId } from 'admin/reducers/story-editor/selectors';
import { selectCurrentVersion } from 'admin/reducers/version-reducer';
import React, { Component, MouseEvent } from 'react';
import { change as changeForm, autofill as autofillFormField } from 'redux-form';
import { connect, ConnectedProps } from 'react-redux';
import _ from 'lodash';

import { AdminReducerState } from 'admin/reducers';
import { UploadedFont, Font, InstalledFont } from 'src/types';
import { getOrganizationGoogleFonts } from 'admin/actions';

import t from 'utils/translate';
import Text from 'admin/components/common/Text';

import { FORM_MODEL } from 'admin/constants/common';
import CustomFontInfo from 'admin/components/pages/Settings/Fonts/CustomFontInfo';
import { SettingsTabsGeneralProps } from '../types';
import CustomFonts from './CustomFonts';
import GoogleFonts from './GoogleFonts';

import css from './Fonts.scss';

const mapStateToProps = (state: AdminReducerState) => ({
	storyId: selectEditableStoryId(state)!,
	formValues: state.form[FORM_MODEL.EDIT_STORY]?.values ?? {},
	version: selectCurrentVersion(state),
});

const mapDispatchToProps = {
	changeForm,
	autofillFormField,
	getOrganizationGoogleFonts,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & SettingsTabsGeneralProps;

class Fonts extends Component<Props> {
	onFontFilesUpload = async (font: UploadedFont) => {
		const { storyId, version, postGallery, fields } = this.props;

		const result = await postGallery({ asset: font.file, storyId });

		this.props.changeForm(FORM_MODEL.EDIT_STORY, `${fields.fonts.name(version)}.${font.id}`, {
			..._.omit(font, ['file']),
			fontFamily: _.trim(font.fontFamily.replace(/ +(?= )/g, '')),
			url: result.result?.asset ?? '',
		});
	};

	onAddGoogleFont = async (font: Font) => {
		const { formValues, version, fields } = this.props;
		const variants = font.variants || [];
		const storyFonts = _.get(formValues, `storyVersions.${version}.settings.fonts`);
		const targetFont = _.find(storyFonts, { fontFamily: font.fontFamily });
		let updateFontId = font.id;

		// if this font was already added - just update existing
		if (targetFont) {
			updateFontId = targetFont.id;
		}

		this.props.changeForm(FORM_MODEL.EDIT_STORY, `${fields.fonts.name(version)}.${updateFontId}`, {
			...font,
			url: `${font.fontFamily.split(' ').join('+')}:${variants.join(',')}`,
		});
	};

	onRemoveFontBtnClick = (e: MouseEvent<HTMLButtonElement>) => {
		const { itemId } = e.currentTarget.dataset;
		const { fields, version } = this.props;

		this.props.autofillFormField(FORM_MODEL.EDIT_STORY, `${fields.fonts.name(version)}.${itemId}`, undefined);
	};

	renderStoryFonts = (filter: 'custom' | 'google') => {
		const { formValues, version } = this.props;
		const fonts = _.get(formValues, `storyVersions.${version}.settings.fonts`, {}) as InstalledFont[];
		const isGoogleFonts = filter === 'google';
		const title = t(`story.settings.${isGoogleFonts ? 'addedGoogleFonts' : 'installedFonts'}`);

		/**
		 * problem with redux form when we try to delete item from the form
		 * (in this case item still in the form but with value "undefined")
		 *
		 * */
		const filteredFonts = _.filter(fonts, f => f && f.fontType === filter);

		return (
			<div className={css.storyFonts}>
				<Text
					tag="div"
					size={Text.size.subheading}
					weight={Text.weight.bold}
					className={css.title}
					text={title}
				/>

				{_.size(filteredFonts) !== 0 ? (
					<div className={css.storyFontsList}>
						{_.map(
							filteredFonts,
							(font: InstalledFont) =>
								font && (
									<div key={`added-google-font-${font.id}`} className={css.storyFontItem}>
										<Text className={css.fontTitle}>{font.fontFamily}</Text>
										{isGoogleFonts ? (
											<Text size={Text.size.description} className={css.fontInfo}>
												<span>{`${font.variants?.join(', ')}`}</span>
											</Text>
										) : (
											<CustomFontInfo
												font={font}
												textSize="description"
												className={css.fontInfo}
											/>
										)}

										<button
											data-item-id={font.id}
											className={css.removeBtn}
											type="button"
											onClick={this.onRemoveFontBtnClick}
										/>
									</div>
								)
						)}
					</div>
				) : (
					<Text size={Text.size.label} className={css.noFontsTitle}>
						{t(`story.settings.${isGoogleFonts ? 'noAddedGoogleFontsYet' : 'noInstalledFontsYet'}`)}
					</Text>
				)}
			</div>
		);
	};

	render() {
		return (
			<div className={css.fonts}>
				<CustomFonts
					className={css.custom}
					{...this.props}
					isDisabled={!!this.props.plan && !this.props.plan.customFonts}
					onFontsUpload={this.onFontFilesUpload}
				/>

				<GoogleFonts
					getFonts={this.props.getOrganizationGoogleFonts}
					className={css.google}
					onAddFont={this.onAddGoogleFont}
				/>

				{this.renderStoryFonts('google')}
				{this.renderStoryFonts('custom')}
			</div>
		);
	}
}

// @ts-expect-error ts-migrate FIXME
export default connector(Fonts);
