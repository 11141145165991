import { createSelector } from 'reselect';

import { AdminReducerState } from 'admin/reducers';

export const selectLoading = (state: AdminReducerState) => state.loading;

export const selectSyncStoryStatus = createSelector(selectLoading, loading => loading.syncStoryStatus);

export const selectSyncCardStatus = createSelector(selectLoading, loading => loading.syncCardStatus);

export const selectLoadingStateByName = createSelector(
	[selectLoading, (_, name: string | string[]) => name],
	(loading, name) => {
		return Array.isArray(name) ? name.some(n => Boolean(loading[n])) : Boolean(loading[name]);
	}
);
