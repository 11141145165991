export function formatFromGb(
	sizeInGb: number,
	withRounding = true,
	style: 'compact' | 'default' = 'default',
	approximateRounding = false
) {
	const size = approximateRounding ? 1000 : 1024;
	const sizeInMb = sizeInGb * size; // Convert GB to MB
	const gap = style === 'compact' ? '' : ' ';

	if (sizeInMb === 0) {
		return `${sizeInMb}`;
	}

	if (sizeInMb < size) {
		return `${sizeInMb.toFixed(0)}${gap}MB`; // Display as whole MB
	}

	if (sizeInGb < size) {
		return `${sizeInGb.toFixed(withRounding ? 2 : 0)}${gap}GB`;
	}

	const sizeInTb = sizeInGb / size; // Convert GB to TB
	return `${sizeInTb.toFixed(withRounding ? 2 : 0)}${gap}TB`;
}
