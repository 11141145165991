import React from 'react';
import cn from 'classnames';
import { CardTemplate } from 'types/story';
import { fitTextToSize } from 'common/utils/fit-text-to-size';
import Text from 'admin/components/common/Text';
import { Icon } from 'admin/components/common/Icon';
import { Column, Grid } from 'admin/components/common/Grid';
import css from './CardThumbnail.scss';

type CardTemplateThumbnail = {
	type: 'card';
	data: CardTemplate;
	isSelected: boolean;
	onClick: (template: CardTemplate) => void;
	onSelect: (template: CardTemplate) => void;
};

type StoryCardTemplateThumbnail = {
	type: 'story';
	cardId: string;
	onClick: (cardId: string) => void;
};

type ThumbProps = (CardTemplateThumbnail | StoryCardTemplateThumbnail) & {
	title: string;
	subtitle: string;
	screenshot: string;
	isActive?: boolean;
};

const CardThumbnail: React.FC<ThumbProps> = ({ title, subtitle, screenshot, ...props }) => {
	return (
		<Grid
			className={cn(css.thumb, {
				[css.active]: props.isActive,
				[css.selected]: props.type === 'card' ? props.isSelected : false,
			})}
			columns="12"
			gap={Grid.gap.small}
			onClick={() => {
				switch (props.type) {
					case 'card':
						props.onClick(props.data);
						break;
					case 'story':
						props.onClick(props.cardId);
						break;
					default:
						break;
				}
			}}
		>
			<Column colSpan="4" justifyContent="stretch">
				<div className={css.thumbCover} style={{ backgroundImage: `url(${screenshot})` }} />
			</Column>

			<Column colSpan="6">
				<Text
					size={Text.size.footnote}
					transform={Text.transform.uppercase}
					color={Text.color.blackMid}
					compact
					ellipsis
				>
					{title}
				</Text>
				<Text className={css.thumbName} size={Text.size.label} compact>
					{fitTextToSize(subtitle, 24, 'end')}
				</Text>
			</Column>

			{props.type === 'card' && (
				<Column
					colSpan="2"
					alignItems="center"
					justifyContent="flex-end"
					onClick={event => {
						if (props.type === 'card') {
							event.stopPropagation(); // prevent thumb onClick
							props.onSelect(props.data);
						}
					}}
				>
					<Icon
						className={css.thumbCheck}
						type={props.isSelected ? 'check-outline-rounded' : 'empty-rounded'}
						width={16}
						color={props.isSelected ? 'var(--ra-color-brand-light)' : '#fff'}
					/>
				</Column>
			)}
		</Grid>
	);
};

export default CardThumbnail;
