import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useUploadVideoProcess } from 'admin/hooks';

import Text from 'admin/components/common/Text';
import Button from 'admin/components/common/Button';
import { Column } from 'admin/components/common/Grid';
import { TextField } from 'admin/components/common/Form';
import { UploadVideoField } from 'admin/components/common/UploadVideoField';
import { UploadVideoProgress } from 'admin/components/common/UploadVideoProgress';
import { IncompleteUploadVideoMessage } from 'admin/components/common/IncompleteUploadVideoMessage';

import t from 'utils/translate';

import { ViewProps } from './types';

import css from './MediaGalleryModal.scss';

const UploadView: React.FC<ViewProps> = ({ setValue, closeModal, onUploadSuccess }) => {
	const [title, setTitle] = useState('');
	const [video, setVideo] = useState<File | null>(null);
	const isValid = useMemo(() => Boolean(title) && Boolean(video), [title, video]);
	const requestsController = React.useRef(new AbortController());

	const { upload, progress, isBusy, prevUploadInfo } = useUploadVideoProcess({
		controller: requestsController.current,
	});

	useEffect(() => {
		setValue?.(null);

		/**
		 * each view represents ability to select value by different way
		 * so when user open the view we need to reset selected value from
		 * the previous visited view
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const controller = requestsController?.current;

		return () => {
			// abort all active requests with file parts
			if (isBusy) controller.abort('CANCELED_BY_USER');
		};
	}, [isBusy]);

	const onTitleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setTitle(e.target.value);
	}, []);

	const onVideoInputChange = useCallback((file: File | null) => {
		setVideo(file);
	}, []);

	const onUploadBtnClick = () => {
		if (video) upload(video, title, () => onUploadSuccess?.());
	};

	const onCancelBtnClick = () => {
		requestsController.current.abort('CANCELED_BY_USER');
		closeModal?.();
	};

	return (
		<div className={css.upload}>
			<div className={css.title}>
				<Text compact size={Text.size.subheading} weight={Text.weight.semibold} tag="h4">
					{t('story.mediaGalleryModal.upload.title')}
				</Text>
			</div>

			<div className={css.uploadForm}>
				<TextField
					className={css.titleInput}
					value={title}
					disabled={isBusy}
					label={t('story.mediaGalleryModal.upload.titleInputLabel')}
					isLabelUppercase={false}
					placeholder={t('story.mediaGalleryModal.upload.titleInputPlaceholder')}
					limit={{ max: 40 }}
					theme="dark"
					isRequired
					onChange={onTitleInputChange}
				/>

				{!isBusy && prevUploadInfo?.uploadId && (
					<IncompleteUploadVideoMessage className={css.prevUploadInfo} uploadInfo={prevUploadInfo} />
				)}

				{isBusy ? (
					<UploadVideoProgress theme="dark" progress={progress} />
				) : (
					<UploadVideoField
						name="video"
						theme="dark"
						placeholder={t('uploadVideo.placeholder')}
						onChange={onVideoInputChange}
					/>
				)}

				<Column className={css.uploadViewBtns} justifyContent="flex-start">
					<Button
						type="button"
						view="primary"
						textSize="label"
						onClick={onUploadBtnClick}
						disabled={!isValid || isBusy}
						className={css.button}
					>
						{t('story.mediaGalleryModal.upload.uploadBtnLabel')}
					</Button>
					<Button onClick={onCancelBtnClick} textSize="label" view="secondary-gray" className={css.button}>
						{t('story.mediaGalleryModal.discard')}
					</Button>
				</Column>
			</div>
		</div>
	);
};

export default UploadView;
