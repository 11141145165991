export interface GeneratedCommon {
	id: number;
	jobId: string;
	createdAt: string;
	updatedAt: string;
}

export interface KeywordsResponse extends GeneratedCommon {
	type: 'GetKeywords';
	response: { keywords: Array<string> };
}

export interface StoryBaseResponse extends GeneratedCommon {
	type: 'GetStoryBaseData';
	response: { answers: Array<{ title: string; description: string; slug: string }> };
}

export interface CharactersResponse extends GeneratedCommon {
	type: 'GetPersonalityQuizCharacters';
	response: Array<{
		name: string;
		description: string;
		// corresponding character event id
		id: string;
	}>;
}

export interface InfoCardResponse extends GeneratedCommon {
	type: 'GetInfoCard';
	response: {
		title: string;
		subtitle: string;
		cardId: string;
	};
}

export interface TriviaCardResponse extends GeneratedCommon {
	type: 'GetTriviaCard';
	response: {
		question: string;
		difficulty: string;
		answers: Array<{ title: string; correct: boolean }>;
		cardId: string;
	};
}

export interface PollCardResponse extends GeneratedCommon {
	type: 'GetPollCard';
	response: {
		question: string;
		answers: Array<{ title: string }>;
		cardId: string;
	};
}

export interface PersonalityCardResponse extends GeneratedCommon {
	type: 'GetPersonalityCard';
	response: {
		question: string;
		answers: Array<{ title: string; scores: { [characterName: string]: number } }>;
		cardId: string;
	};
}

export interface ImageResponse extends GeneratedCommon {
	type: 'GetImage';
	response: {
		hosting: string;
		key: string;
		cardId: string;
		id: string;
	};
}

export type PromptsResponseList = (
	| KeywordsResponse
	| StoryBaseResponse
	| CharactersResponse
	| InfoCardResponse
	| TriviaCardResponse
	| PollCardResponse
	| PersonalityCardResponse
	| ImageResponse
)[];

export type GenerateStoryContentParams = {
	userPrompt: string;
	storyTemplateId: number;
	onStatusChange: (statusMsg: string) => void;
};

export type GenerateStoryContentResult = null | {
	prompts: PromptsResponseList;
	generator: { id: string; jobId: string };
};

export type GenerateCardContentParams = {
	prompt?: string;
	onStatusChange: (statusMsg: string) => void;
	replaceImages: boolean;
};

export enum GenerateStoryStatus {
	starting = 'starting',
	generating = 'generating',
	completed = 'completed',
	failed = 'failed',
}

export interface GeneratorJob {
	id: string;
	instructions: null;
	generatorId: string;
	statuses: string[];
	status: GenerateStoryStatus;
	createdAt: string;
	updatedAt: string;
	prompts: PromptsResponseList;
}

export interface GenerateStoryContentResponse {
	id: string;
	context: null;
	organizationId: string;
	memberId: string;
	userPrompt: string;
	createdAt: string;
	updatedAt: string;
	jobs: GeneratorJob[];
}

export interface GeneratorPromptsParams {
	type: 'image' | 'text' | 'card';
}

export interface GenerateTextParams {
	prompt: string;
	// omit following params to generate a brand-new text
	action?: 'rewrite' | 'reduce' | 'extend' | 'adjust' | 'list';
	adjustment?: string;
}

export enum AIImageQuality {
	standard = 'standard',
	hd = 'hd',
}

export enum AIImageStyle {
	conceptArt = 'concept art',
	mockArt = 'mock art',
	gothicVictorian = 'gothic victorian',
	pixelArt = 'pixel art',
	image2d = '2d',
	image3d = '3d',
	realistic = 'realistic',
	markerDrawing = 'marker drawing',
	pencilDrawing = 'pencil drawing',
	oilPainting = 'oil painting',
	watercolorPainting = 'watercolor painting',
	acrylicPainting = 'acrylic painting',
	surrealism = 'surrealism',
	impressionism = 'imperssionism',
	pastel = 'pastel',
	digitalPainting = 'digital painting',
	abstractArt = 'abstract art',
	panelPainting = 'panel painting',
	glassPainting = 'glass painting',
	cubism = 'cubism',
	disney = 'disney',
	pixar = 'pixar',
	anime = 'anime',
	macroLens = 'macro lens',
	fishEyeLens = 'fish eye lens',
	birdsEyePerspective = 'birds eye perspective',
	thirdPersonPerspective = 'third person perspective',
}

export enum AIImageSize {
	square = '1024x1024',
	portrait = '1024x1792',
	landscape = '1792x1024',
}

export interface GenerateImageParams {
	prompt: string;
	quality: AIImageQuality;
	style?: AIImageStyle;
	size: AIImageSize;
}
