import { clamp } from 'lodash';
import type { ComponentTypes } from 'types/story';
import { getClientFrame } from 'utils/iframe-tunnel';
import { COMPONENT_TYPE } from 'common/constants';
import { OTHER_PROPS } from 'common/constants/component-props';
import { propFieldsConfig } from 'admin/components/pages/Story/CardEditor/Inspector/config/prop-fields-config';
import { getCustomCssProp } from 'utils/blocks/get-custom-css-prop';
import CardEditorIframeTransmitter from 'admin/components/pages/Story/CardEditor/IframeTransmitter';

export const KEYS = {
	ARROW_UP: 'ArrowUp',
	ARROW_DOWN: 'ArrowDown',
	UP: 'Up',
	DOWN: 'Down',
	ENTER: 'Enter',
};

/*
 Get client node, editable property by id and field path
 */
export function selectedNodeData(props: {
	id: string;
	name: string;
	boundWithFields?: string[];
}): { property: string[]; node: HTMLElement | null } | null {
	const nodeId = props.id;
	const clientFrame = getClientFrame();

	if (!clientFrame?.contentDocument) {
		return null;
	}

	const node = clientFrame.contentDocument.getElementById(nodeId);
	const property = props.name.split('.').pop();
	const boundProperties = props.boundWithFields?.map(field => field.split('.').pop()!);

	if (property === undefined) {
		return null;
	}

	const propAlias: Record<string, string> = {
		[OTHER_PROPS.contentLayout.contentHeight]: 'height',
		[OTHER_PROPS.contentLayout.contentMinHeight]: 'height',
		[OTHER_PROPS.contentLayout.contentMarginTop]: 'marginTop',
		[OTHER_PROPS.contentLayout.contentMarginBottom]: 'marginBottom',
		[OTHER_PROPS.contentLayout.contentMarginLeft]: 'marginLeft',
		[OTHER_PROPS.contentLayout.contentMarginRight]: 'marginRight',
	};

	const contentNode = clientFrame.contentDocument.querySelector(`[data-bb="${COMPONENT_TYPE.CONTENT}"]`);

	const nodeAlias = {
		[OTHER_PROPS.contentLayout.contentHeight]: contentNode,
		[OTHER_PROPS.contentLayout.contentMinHeight]: contentNode,
		[OTHER_PROPS.contentLayout.contentMarginTop]: contentNode,
		[OTHER_PROPS.contentLayout.contentMarginBottom]: contentNode,
		[OTHER_PROPS.contentLayout.contentMarginLeft]: contentNode,
		[OTHER_PROPS.contentLayout.contentMarginRight]: contentNode,
	};

	return {
		property: [propAlias[property] ?? property].concat((boundProperties ?? []).map(p => propAlias[p] ?? p)),
		node: nodeAlias[property] ?? node,
	};
}

// get value transformed by `parse` func from `propFieldsConfig`
export const parsePropFieldValue = ({ property, value }: { property: string; value: string | number | undefined }) => {
	if (value === undefined || value === '') return value;
	const parseFn = propFieldsConfig[property]?.parse;
	return parseFn ? parseFn(`${value}`, property) : value;
};

export function updateElementInlineStyle(props: { property: string; value: string; node: HTMLElement }) {
	const customProp = getCustomCssProp(props.node.dataset.bb as ComponentTypes, props.property);
	const value = parsePropFieldValue({ property: props.property, value: props.value });

	if (customProp) {
		const postfix = customProp.selectorPostfix?.();
		const targetNode = postfix ? (props.node.querySelector(postfix) as HTMLElement) : props.node;
		if (targetNode) {
			targetNode.style[customProp.cssProperty] = value;
		}
	} else {
		// update client value
		props.node.style[props.property] = value; // eslint-disable-line no-param-reassign
	}

	CardEditorIframeTransmitter.forceSelectionHintUpdate();
}

export const getUnit = (unit?: boolean) => (unit ? 'px' : '');

interface SharedFieldProps {
	unit?: boolean;
	decimals?: number;
	min?: number;
	max?: number;
}

// Parse current input value and transform it to possible valid value for a save
export const parseValid = (props: { value: string } & Omit<SharedFieldProps, 'unit'>): number | string => {
	const value = parseFloat(`${props.value}`);

	if (Number.isNaN(value)) {
		return ''; // empty string makes value removal
	}

	return parseFloat(clamp(value, props.min ?? value, props.max ?? value).toFixed(props.decimals));
};

interface SetInlineStyleI extends SharedFieldProps {
	value: string;
	property: string;
	node: HTMLElement | null;
}

// Get editable node and assign current field value into node inline styles
export const setInlineStyle = ({ node, property, ...props }: SetInlineStyleI) => {
	const nextValue = parseValid(props);
	if (!node || !property) {
		return;
	}

	const nextValueWithUnit = `${nextValue}${getUnit(props.unit)}`;
	if (nextValueWithUnit === node.style[property]) {
		return;
	}

	updateElementInlineStyle({ node, property, value: nextValueWithUnit });
};
