import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import {
	LOGIN_PAGE,
	VERIFY_MEMBER_PAGE,
	ACCOUNT_SETUP_PAGE,
	ACCOUNT_SETUP_PLAN_PAGE,
	STORIES_PAGE,
	TWO_FA_ENFORCEMENT_PAGE,
	PROFILE_2FA_PAGE,
	NOT_FOUND,
} from 'admin/constants/routes';
import Loader from 'common/components/Loader';
import { parseURL } from 'common/utils/parse-url';
import { IS_DEVEL } from 'common/utils/environment';
import { useAdminSelector } from 'admin/reducers';
import {
	isSuperUserSelector,
	selectHasOrganizations,
	selectUserInfo,
	isUserVerifiedSelector,
	selectIsTwoFASetupAwaiting,
} from 'admin/reducers/user/selectors';

type Props = RouteProps & { component: any; superuser?: boolean };

const PrivateRoute = ({ component: PrivateComponent, superuser = false, ...routerProps }: Props) => {
	const userInfo = useAdminSelector(selectUserInfo);
	const isSuperUser = useAdminSelector(isSuperUserSelector);
	const isVerified = useAdminSelector(isUserVerifiedSelector);
	const isTwoFASetupAwaiting = useAdminSelector(selectIsTwoFASetupAwaiting);
	const hasOrganizations = useAdminSelector(selectHasOrganizations);

	const isLoggedId = !!userInfo.email;

	let hasAccess = isLoggedId;
	let redirectTo = LOGIN_PAGE;
	let params: { verified?: boolean; forceLogout?: boolean } = {};
	const path = routerProps.location?.pathname ?? '';
	const newMemberSetupEmail = parseURL(routerProps.location?.search || '').searchObject.email ?? '';
	const isSetupComplete = isLoggedId && isVerified && hasOrganizations;
	const isVerificationRequired =
		isLoggedId && !isVerified && path !== VERIFY_MEMBER_PAGE && path !== ACCOUNT_SETUP_PAGE;
	const isPaymentRequired = isLoggedId && isVerified && !hasOrganizations && path !== ACCOUNT_SETUP_PLAN_PAGE;

	if (isSetupComplete && path === VERIFY_MEMBER_PAGE && newMemberSetupEmail === userInfo.email) {
		hasAccess = false;
		redirectTo = STORIES_PAGE;
	} else if (isVerificationRequired) {
		hasAccess = false;
		redirectTo = ACCOUNT_SETUP_PAGE;
		params = { verified: false };
	} else if (isPaymentRequired) {
		hasAccess = false;
		redirectTo = ACCOUNT_SETUP_PLAN_PAGE;
	} else if (isTwoFASetupAwaiting && path !== PROFILE_2FA_PAGE) {
		hasAccess = false;
		redirectTo = TWO_FA_ENFORCEMENT_PAGE;
	}

	// get access only for a superuser
	if (superuser) {
		hasAccess = (isSuperUser || IS_DEVEL) && isLoggedId;
		redirectTo = NOT_FOUND;
	}

	const Component = (router: RouteProps) => {
		const { location, ...other } = router;

		return hasAccess ? (
			<PrivateComponent {...other} location={params.forceLogout ? { ...location, state: params } : location} />
		) : (
			<Redirect to={{ pathname: redirectTo, state: { from: router.location, ...params } }} />
		);
	};

	return (
		<React.Suspense
			fallback={
				<Loader fullFrame bgColor="var(--ra-color-white-mid)" color="var(--ra-color-black-mid)" size="medium" />
			}
		>
			<Route {...routerProps} render={Component} />
		</React.Suspense>
	);
};

export default PrivateRoute;
