import React, { FC } from 'react';
import t from 'utils/translate';
import Text from 'admin/components/common/Text';
import Button from 'admin/components/common/Button';
import { Column } from 'admin/components/common/Grid';
import { Modal } from 'admin/components/common/Modal';
import { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';
import { MODAL } from 'admin/constants/common';
import css from './KickOutInfoModal.scss';

export type KickOutInfoModalData = {
	name: string;
	email: string;
};

const KickOutInfoModal: FC<ModalManagerProvidedProps<MODAL.KICK_OUT_INFO_MODAL>> = ({ open, data, close }) => (
	<Modal open={open} destroyOnClose width={parseInt(css.modalWidth, 10)} className={css.modal}>
		<div className={css.head}>
			<Text size="subtitle" weight="semibold" compact text={t('kickOutInfoModal.title', { name: data.name })} />
		</div>
		<div className={css.body}>{/* <Text size={Text.size.paragraph} text={t('kickOutInfoModal.text')} /> */}</div>
		<div>
			<Column justifyContent="flex-start">
				<Button view="danger" onClick={close}>
					{t('kickOutInfoModal.ok')}
				</Button>
			</Column>
		</div>
	</Modal>
);

export default KickOutInfoModal;
