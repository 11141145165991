import type { ThunkDispatch } from 'redux-thunk';
import { Action, combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';

import user from './user/reducer';
import story from './story/reducer';
import card from './card/reducer';
import editor from './editor/reducer';
import settings from './settings/reducer';

const rootReducer = combineReducers({
	user,
	story,
	card,
	editor,
	settings,
	form: formReducer,
});

export type ClientReducerState = ReturnType<typeof rootReducer>;

export type ClientThunkDispatch<A extends Action = Action> = ThunkDispatch<ClientReducerState, void, A>;

export type ClientThunkAction<A extends Action = Action, R = void> = (
	dispatch: ClientThunkDispatch<A>,
	getState: () => ClientReducerState
) => Promise<R>;

export const useClientDispatch = () => useDispatch<ClientThunkDispatch>();
export const useClientSelector: TypedUseSelectorHook<ClientReducerState> = useSelector;

export default rootReducer;
