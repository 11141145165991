import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { Field } from 'redux-form';
import { get } from 'lodash';
import cn from 'classnames';

import t from 'utils/translate';
import UrlHelper from 'utils/url-helper';
import { IS_DEVEL } from 'utils/environment';
import { prependUrlProtocol } from 'utils/helpers';
import { StoryFacade } from 'utils/facades/story-facade';
import FeaturesFacade from 'utils/facades/features-facade';

import { FORM_MODEL } from 'admin/constants/common';
import Text from 'admin/components/common/Text';
import type { AdminReducerState } from 'admin/reducers';
import { Icon } from 'admin/components/common/Icon';
import Tooltip from 'admin/components/common/Tooltip';
import { Grid, Column } from 'admin/components/common/Grid';
import { getStoryCustomDomain } from 'admin/utils/domains';
import { SETTINGS_DOMAINS_PAGE } from 'admin/constants/routes';
import { STORY_NAME_MAX_LEN } from 'admin/components/common/Form/validations';
import { selectActiveOrganization, selectValidDomains } from 'admin/reducers/user/selectors';
import { Hint, Label, MediaField, Radio, TextField, Toggle } from 'admin/components/common/Form';

import type { SettingsTabsGeneralProps } from '../types';
import StorySettingsCss from '../StorySettingsModal.scss';
import EmbedCode from './EmbedCode';
import StorycardsDomain from './StorycardsDomain';
import css from './Settings.scss';

const AddDomainComponent: React.FC<{ canAddDomain: boolean; className?: string; children: React.ReactNode }> = ({
	canAddDomain,
	...props
}) => {
	return canAddDomain ? <Link to={SETTINGS_DOMAINS_PAGE} {...props} /> : <div {...props} />;
};

type Props = ConnectedProps<typeof connector> & SettingsTabsGeneralProps;

const theme = 'dark';

class Settings extends Component<Props> {
	features = this.props.organization ? new FeaturesFacade({ organization: this.props.organization }) : undefined;

	get hasPublished() {
		return Boolean(new StoryFacade(this.props.story).getVersion(StoryFacade.VERSIONS.published));
	}

	get embedDomain() {
		const { formValues, domains, story } = this.props;
		return UrlHelper.getPublishedUrl({
			clientStoryId: formValues?.clientStoryId,
			customDomain: getStoryCustomDomain({ domainId: formValues?.domainId, domains }),
			storycardsDomain: story.storycardsDomain?.domain,
		});
	}

	formatStoryUrl = () => {
		const { story, formValues } = this.props;
		const { organizationId } = story;

		const val = formValues?.clientStoryId;
		const startIndex = val && val.lastIndexOf('/') >= 0 ? val.lastIndexOf('/') + 1 : val?.length ?? 0;

		return `${organizationId}/${val ? val.substr(startIndex) : ''}`;
	};

	onMediaFieldUpload = async (data: { asset: File; name: string }) => {
		const { story, postGallery } = this.props;

		const result = await postGallery({ asset: data.asset, storyId: story.id });

		return result.result?.asset ? { asset: prependUrlProtocol(result.result.asset, 'https') } : false;
	};

	renderDomains = () => {
		const canAddDomain = Boolean(this.props.organization?.plan?.customDomains);
		const orgDomainId = this.props.organization?.domainId;
		const storycardsDomain = get(this.props.formValues, `${this.props.fields.storycardsDomain.name()}.domain`);
		const storyType = this.props.story.type;

		return (
			<div className={css.domains}>
				<Text text="Domain" size="label" weight="semibold" />
				{/* Valid organization domains list */}
				{this.props.domains?.map(domain => (
					<label className={cn(css.domain, storyType === 'widget' && css.disabled)} key={domain.id}>
						<Text
							className={css.domainUrl}
							text={UrlHelper.getPublishedUrl({
								clientStoryId: this.props.formValues?.clientStoryId,
								customDomain: domain.type === 'Custom' ? domain.domain : undefined,
								storycardsDomain,
								noProtocol: true,
							})}
						/>
						<Text
							size="footnote"
							className={css.domainType}
							text={
								orgDomainId !== null && orgDomainId === domain.id
									? 'Default by organization'
									: domain.type
							}
						/>
						<Field
							className={css.domainBtn}
							name={this.props.fields.domainId.name()}
							propValue={domain.id}
							component={Radio}
							disabled={storyType === 'widget'}
						/>
					</label>
				))}

				{/* Add domain */}
				{storyType !== 'widget' && (
					<AddDomainComponent canAddDomain={canAddDomain} className={css.domain}>
						<Text
							className={css.domainUrl}
							text={t(`story.settings.${canAddDomain ? 'addCustomDomain' : 'customDomain'}`)}
						/>
						{canAddDomain ? (
							<div className={css.domainAddIcon} />
						) : (
							<>
								<Text size="footnote" className={css.domainType} text="Custom" />

								<Tooltip
									isStatic
									stylePreset="dark-1"
									placement="top"
									content={
										<Text size="footnote">
											<span>{t('story.settings.customDomainDescrPt1')}</span>
											<Link className={css.tooltipLink} to={SETTINGS_DOMAINS_PAGE}>
												{t('story.settings.customDomainDescrPt2')}
											</Link>
										</Text>
									}
								>
									<div className={css.domainLockIcon} />
								</Tooltip>
							</>
						)}
					</AddDomainComponent>
				)}
			</div>
		);
	};

	render() {
		const { story, version, fields } = this.props;
		const { id: storyId, type: storyType } = story;

		if (!storyId) {
			return null;
		}

		return (
			<Grid columns="14" columnGap={Grid.gap.large}>
				<Column colSpan="14">
					<Column colSpan="14" justifyContent="space-between" alignItems="baseline">
						<Text
							tag="div"
							size={Text.size.subheading}
							weight={Text.weight.bold}
							className={css.title}
							text={t('story.settings.navigation.general')}
						/>
						<Text size={Text.size.label} className={css.lang}>
							<Icon type="sc-lang" width={14} color="var(--ra-color-white-mid)" />
							{story.language}
						</Text>
					</Column>
					<section className={css.section}>
						<div className={css.sectionField}>
							<Field
								name={fields.name.name()}
								autoComplete="off"
								component={TextField}
								label={t('story.settings.storyTitle')}
								theme={theme}
								isLabelUppercase={false}
								limit={{ max: STORY_NAME_MAX_LEN }}
								isRequired
							/>
						</div>
						{storyType !== 'widget' && (
							<div className={css.sectionField}>
								<Field
									name={fields.clientStoryId.name()}
									autoComplete="off"
									component={TextField}
									label={
										<span>
											Custom id{' '}
											<Hint
												text={`(letters, numbers, "-" and "_" only)`}
												theme={theme}
												tag="span"
											/>
										</span>
									}
									theme={theme}
									isLabelUppercase={false}
									format={this.formatStoryUrl}
									isRequired
								/>

								{this.renderDomains()}
							</div>
						)}
						{storyType !== 'widget' && (
							<>
								<div className={css.sectionField}>
									<Field
										name={fields.favicon.name(version)}
										component={MediaField}
										label="Favicon"
										theme={theme}
										isLabelUppercase={false}
										fileType={MediaField.fileTypes.ALL_IMAGES_TYPES}
										defaultValue={this.props.organization?.favicon ?? ''}
										description="ICO, PNG, GIF or JPG"
										eventListeners={{
											onUpload: this.onMediaFieldUpload,
										}}
										fileDimensions={{ maxW: 144, maxH: 144 }}
										sizeInfoLabel="Recommended a multiple of 48px square (48x48, 96x96, 144x144)"
										isRequired
									/>
								</div>
								<div className={css.sectionField}>
									<Field
										name={fields.webclip.name(version)}
										component={MediaField}
										label="Webclip"
										theme={theme}
										fileType={MediaField.fileTypes.ONLY_PNG}
										isLabelUppercase={false}
										description={t('story.settings.iphoneScreenIcon')}
										fileDimensions={{ maxW: 256, maxH: 256 }}
										eventListeners={{
											onUpload: this.onMediaFieldUpload,
										}}
										isRequired
									/>
								</div>
							</>
						)}
						{this.hasPublished && (
							<div className={css.sectionField}>
								<EmbedCode
									params={{ domain: this.embedDomain }}
									clientStoryId={this.props.formValues?.clientStoryId}
									storyId={this.props.story.id}
								/>
							</div>
						)}
						{(this.props.isSuperUser || IS_DEVEL) && (
							<div className={cn(css.sectionField, StorySettingsCss.superuserSection)}>
								<StorycardsDomain
									storyId={storyId}
									domain={this.props.formValues.storycardsDomain?.domain}
								/>
								<Column justifyContent="space-between">
									<Label theme={theme}>Organization ID</Label>
									<Text size={Text.size.label}>{story.organizationId}</Text>
								</Column>
								<Column justifyContent="space-between">
									<Label theme={theme}>Team ID</Label>
									<Text size={Text.size.label}>{story.team?.id}</Text>
								</Column>
								<Column justifyContent="space-between">
									<Label theme={theme}>Team name</Label>
									<Text size={Text.size.label}>{story.team?.name}</Text>
								</Column>
								<Column justifyContent="space-between">
									<Label theme={theme}>Created by</Label>
									<Text size={Text.size.label}>{story.member.name}</Text>
								</Column>
								<Column justifyContent="space-between">
									<Label theme={theme}>Story version</Label>
									<Text size={Text.size.label}>{story.storyVersions.latest.data.appVersion}</Text>
								</Column>
								<Column justifyContent="space-between">
									<Label theme={theme}>App version</Label>
									<Text size={Text.size.label}>{document.body.dataset.version}</Text>
								</Column>
								<Column justifyContent="space-between">
									<Label
										theme={theme}
										htmlFor={`${FORM_MODEL.EDIT_STORY}.${fields.cardEditorAutoSync.name(version)}`}
									>
										Enable auto-save
									</Label>
									<Field
										checked={new StoryFacade(this.props.formValues).isCardEditorAutoSyncEnabled}
										name={fields.cardEditorAutoSync.name(version)}
										component={Toggle}
										theme={theme}
									/>
								</Column>
							</div>
						)}
					</section>
				</Column>
			</Grid>
		);
	}
}

const mapState = (state: AdminReducerState) => ({
	organization: selectActiveOrganization(state),
	domains: selectValidDomains(state),
});

const connector = connect(mapState);

export default connector(Settings);
