import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import _ from 'lodash';
import { InjectedFormProps, reset as resetAction, change as changeAction, initialize, reduxForm } from 'redux-form';
import { compose } from 'redux';

import type { IOrganizationIntegrations } from 'src/types';
import type { AdminReducerState } from 'admin/reducers';
import type { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';

import t from 'utils/translate';
import { gaRegExp } from 'utils/regexp';
import IntegrationsFacade, { INTEGRATIONS_PAGE_VIEW, INTEGRATIONS_SCOPE } from 'utils/facades/integrations-facade';
import { Icon } from 'admin/components/common/Icon';
import Text from 'admin/components/common/Text';
import Button from 'admin/components/common/Button';
import { Modal } from 'admin/components/common/Modal';
import { selectActiveOrganization } from 'admin/reducers/user/selectors';
import { updateOrganizationSettings } from 'admin/actions';
import { Column } from 'admin/components/common/Grid';
import { FORM_MODEL, MODAL } from 'admin/constants/common';
import { Script } from 'admin/components/common/Intregrations/Script';
import { CustomTracker } from 'admin/components/common/Intregrations/CustomTracker';
import { GoogleAnalytics } from 'admin/components/common/Intregrations/GoogleAnalytics';
import { GooglePublisherTag, validateGPTIntegration } from 'admin/components/common/Intregrations/GooglePublisherTag';

import { SelectIntegration } from 'admin/components/pages/Settings/Integration/EditIntegrationModal/SelectIntegration';
import { _t } from '../helpers';
import css from './EditIntegrationModal.scss';

export type EditIntegrationModalData = {
	view: INTEGRATIONS_PAGE_VIEW | null;
};

type ModalProps = ModalManagerProvidedProps<MODAL.EDIT_INTEGRATION>;

type Props = ModalProps & InjectedFormProps<IOrganizationIntegrations, ModalProps> & ConnectedProps<typeof connector>;

const EditIntegrationModal: React.FC<Props> = props => {
	const { open, data, close, activeOrganization, handleSubmit } = props;
	const [view, setView] = React.useState<INTEGRATIONS_PAGE_VIEW>(data.view || INTEGRATIONS_PAGE_VIEW.DEFAULT);
	const currentViewIcon = React.useMemo(() => IntegrationsFacade.getFieldById(view)?.icon, [view]);
	const isNotDefaultView = view !== INTEGRATIONS_PAGE_VIEW.DEFAULT;

	React.useEffect(
		function onUpdateSettings() {
			if (activeOrganization?.metadata?.integrations) {
				props.initializeForm(FORM_MODEL.ORGANIZATION_INTEGRATIONS, activeOrganization.metadata?.integrations);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[activeOrganization?.metadata]
	);

	React.useEffect(
		function onChangeDataView() {
			if (data.view && data.view !== view) {
				setView(data.view as INTEGRATIONS_PAGE_VIEW);
			}
		},
		[data.view, view]
	);

	const onViewChange = (val: INTEGRATIONS_PAGE_VIEW) => {
		setView(val);
	};

	const onAfterClose = () => {
		setView(INTEGRATIONS_PAGE_VIEW.DEFAULT);
	};

	const onFormSubmit = async (values: IOrganizationIntegrations) => {
		const requestData = {
			name: activeOrganization?.name as string,
			metadata: {
				integrations: values,
			},
		};
		const response = await props.updateOrganizationSettings({ data: requestData });

		if (response.success) {
			close();
		}
	};

	const onCancelClick = () => {
		if (data.view) {
			close();
		} else {
			setView(INTEGRATIONS_PAGE_VIEW.DEFAULT);
		}

		props.resetForm(FORM_MODEL.ORGANIZATION_INTEGRATIONS);
	};

	const renderComponent = () => {
		const commonProps = {
			scope: INTEGRATIONS_SCOPE.ORGANIZATION,
		} as const;

		switch (view) {
			case INTEGRATIONS_PAGE_VIEW.DEFAULT:
				return (
					<SelectIntegration
						{...commonProps}
						organization={activeOrganization!}
						integrations={props.formValues}
						onViewChange={onViewChange}
					/>
				);

			case INTEGRATIONS_PAGE_VIEW.SCRIPT:
				return <Script {...commonProps} />;

			case INTEGRATIONS_PAGE_VIEW.GOOGLE_ANALYTICS:
				return <GoogleAnalytics {...commonProps} />;

			case INTEGRATIONS_PAGE_VIEW.CUSTOM_TRACKER:
				return <CustomTracker {...commonProps} integrations={props.formValues} />;

			case INTEGRATIONS_PAGE_VIEW.GOOGLE_PUBLISHER_TAG:
				return <GooglePublisherTag {...commonProps} integrations={props.formValues} />;

			default:
				return null;
		}
	};

	return (
		<Modal
			open={open}
			destroyOnClose
			width={parseInt(css.modalWidth, 10)}
			className={css.modal}
			afterClose={onAfterClose}
		>
			<form onSubmit={handleSubmit(onFormSubmit)}>
				<div className={css.head}>
					<Text tag="div" size={Text.size.hero} weight={Text.weight.semibold} compact className={css.title}>
						{isNotDefaultView && currentViewIcon && <Icon type={currentViewIcon} width={54} color="#CCC" />}
						<span>{_t(`${IntegrationsFacade.titles[view]}.title`)}</span>
					</Text>
				</div>

				<div className={css.body}>
					<div className={css.bodyContent}>{renderComponent()}</div>
				</div>

				{isNotDefaultView && (
					<div className={css.footer}>
						<Column justifyContent="flex-start">
							<Button
								view="primary"
								type="submit"
								disabled={props.submitting || props.pristine}
								loading={props.submitting}
								className={css.actionBtn}
							>
								<Text size={Text.size.paragraph}>{t('settings.pages.integration.updateBtnLabel')}</Text>
							</Button>

							<Button view="secondary" onClick={onCancelClick} className={css.actionBtn}>
								<Text size={Text.size.paragraph}>{t('settings.pages.integration.cancelBtnLabel')}</Text>
							</Button>
						</Column>
					</div>
				)}
			</form>
		</Modal>
	);
};

const mapState = (state: AdminReducerState) => {
	return {
		activeOrganization: selectActiveOrganization(state),
		formValues: _.get(state, ['form', FORM_MODEL.ORGANIZATION_INTEGRATIONS, 'values']) as IOrganizationIntegrations,
	};
};

const mapDispatch = {
	updateOrganizationSettings,
	initializeForm: initialize,
	changeForm: changeAction,
	resetForm: resetAction,
};

const connector = connect(mapState, mapDispatch);

interface IErrors {
	[key: string]: string | IErrors;
}

export default compose<typeof EditIntegrationModal>(
	reduxForm({
		destroyOnUnmount: false,
		validate: (values: IOrganizationIntegrations) => {
			const errors: IErrors = {};
			const integrationFields = IntegrationsFacade.getFields();
			const gaValue = _.get(values, integrationFields.ga.gaUniversal.name);
			const ga4Value = _.get(values, integrationFields.ga.ga4.name);
			const customTrackerUrlValue: string | undefined = _.get(
				values,
				integrationFields.customTracker.customTrackerUrl.name
			);

			if (gaValue && !gaRegExp.test(gaValue)) {
				_.set(
					errors,
					integrationFields.ga.gaUniversal.name,
					_t(`${IntegrationsFacade.titles[INTEGRATIONS_PAGE_VIEW.GOOGLE_ANALYTICS]}.invalidGaId`)
				);
			}

			if (ga4Value && !gaRegExp.test(ga4Value)) {
				_.set(
					errors,
					integrationFields.ga.ga4.name,
					_t(`${IntegrationsFacade.titles[INTEGRATIONS_PAGE_VIEW.GOOGLE_ANALYTICS]}.invalidGaId`)
				);
			}

			if (customTrackerUrlValue && !customTrackerUrlValue.startsWith('https://')) {
				_.set(
					errors,
					integrationFields.customTracker.customTrackerUrl.name,
					_t(`${IntegrationsFacade.titles[INTEGRATIONS_PAGE_VIEW.CUSTOM_TRACKER]}.invalidCustomTrackerUrl`)
				);
			}

			const gptIntegrationErrors = validateGPTIntegration({ form: values });
			gptIntegrationErrors.forEach(item => _.set(errors, item.path, item.value));

			return errors;
		},
		form: FORM_MODEL.ORGANIZATION_INTEGRATIONS,
	}),
	connector
)(EditIntegrationModal);
