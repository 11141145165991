import { isEmpty, omit, reduce, get, round } from 'lodash';
import { EventEmitter } from 'utils/event-emitter';

export const SELECTION_TYPES = {
	clicked: 'clicked',
	hovered: 'hovered',
} as const;

/*
 Selection is split to stages:
 1. Outer (default)
 2. Inner
 When you select a block your default stage is "Outer" and you cannot select child blocks.
 But on "dbclick" the stage changes to "Inner" and you as like dive into container
 then you can see child blocks and select them.
 */
export const SELECTION_STAGE = {
	OUTER: 'outer',
	INNER: 'inner',
} as const;

type SelectionHintForceUpdateEvent = { type: 'forceUpdate' };

export const SelectionHintEvEm = new EventEmitter<SelectionHintForceUpdateEvent>();

/**
 * Get bounds & position info about selected node
 * @param node {Node} selected dom node reference
 * @return {{top: number, left: number, width: number, position: 'fixed' | 'absolute', height: number}}
 */
export function getSelectionPosition(node) {
	const isFixed = window.getComputedStyle(node).position === 'fixed';
	const { scrollTop, scrollLeft } = document.documentElement;

	const { top, left } = node.getBoundingClientRect();
	const { offsetWidth: width, offsetHeight: height } = node;

	return {
		position: isFixed ? 'fixed' : 'absolute',
		left: round(isFixed ? left : left + scrollLeft),
		top: round(isFixed ? top : top + scrollTop),
		width: round(width),
		height: round(height),
	};
}

/**
 * Get bounds & position info about multiple selected nodes
 * @param group {Object} where key is an id of selected node, value is his data
 * @return {{}}
 */
export function getSelectionGroupPosition(group) {
	/* eslint-disable no-param-reassign */
	const callback = (memo, value, key) => {
		const node = document.getElementById(key);
		const { position, left, top, width, height } = getSelectionPosition(node);
		const right = left + width;
		const bottom = top + height;

		if (isEmpty(memo)) {
			Object.assign(memo, {
				position,
				left,
				top,
				width,
				height,
				right,
				bottom,
			});
		} else {
			// is absolute - default. is fixed - if some element is fixed
			memo.position = memo.position === 'fixed' || position === 'fixed' ? 'fixed' : 'absolute';

			memo.left = Math.min(memo.left, left);
			memo.top = Math.min(memo.top, top);

			memo.right = Math.max(memo.right, right);
			memo.bottom = Math.max(memo.bottom, bottom);

			memo.width = memo.right - memo.left;
			memo.height = memo.bottom - memo.top;
		}

		return memo;
	};
	/* eslint-enable no-param-reassign */

	return omit(reduce(group, callback, {}), ['right', 'bottom']);
}

/**
 *
 * @param e {Event|SyntheticEvent} an event which takes place in the DOM
 * @returns {Boolean}
 */
export function isUserAction(e) {
	return (
		e.isTrusted || get(e, 'nativeEvent.isTrusted') || (e.screenX && e.screenX !== 0 && e.screenY && e.screenY !== 0)
	);
}
