import _ from 'lodash';
import type { Action } from 'redux';
import type { AdminThunkAction } from 'admin/reducers';
import { AUTO_SYNC_TARGET } from 'admin/constants/common';
import { UPDATE_EDITABLE_CARD_DATA } from 'admin/constants/actions';
import type { BBModel, CardData, CardEditorType, StorySettingsType, StorySymbols } from 'types/story';
import produce from 'immer';
import { CARD_ROOT_ELEMENT_INDEX, COMPONENT_STATES, COMPONENT_TYPE } from 'common/constants';
import { getClientFrame } from 'utils/iframe-tunnel';

type PartialUpdate = {
	type: 'partial';
	value: { path: string[]; value: (string | number)[] };
};

type FullUpdate = {
	type: 'full';
	value: {
		data?: CardData;
		storyElements?: BBModel[];
		storySettings?: StorySettingsType;
		symbols?: StorySymbols;
		cardPath?: string;
	};
};

export type UpdateEditableCardDataPayload = (PartialUpdate | FullUpdate) & {
	// disable automatic save
	disableSync?: boolean;
	// automatically save immediately, with no timeout
	autoSyncImmediate?: boolean;
};

/**
 * @param payload {Object}
 * or
 * @param {String[]}            [payload.path]              -  path to where update
 * @param {*[]}                 [payload.value]             - data to update at path
 * or
 * @param {String}              [payload.cardPath]          - path to card in story object
 * @param {Object}              [payload.data]              - card data
 * @param {Object}              [payload.storyElements]     - story element
 * @param {Object}              [payload.storySettings]     - story settings
 * @param {Object}              [payload.symbols]           - story symbols
 *
 * @param {Object}              [payload.disableSync]       - disable automatic save
 * @param {Object}              [payload.autoSyncImmediate] - automatically save immediately, with no timeout
 *
 * @example
 *  updateEditableCardData({ type: 'full', value: { data: Object, storyElements: Object, storySettings: Object }})
 *  updateEditableCardData({ type: 'partial', value: { path: ['data.elements...fontSize'], value: ['12px'] }})
 *
 * @return {{type: *, payload: *}}
 */
export function updateEditableCardData({
	disableSync = false,
	autoSyncImmediate = false,
	...payload
}: UpdateEditableCardDataPayload): AdminThunkAction<Action, any> {
	return async (dispatch, getState) => {
		const cardEditor = getState().cardEditor.present;
		const { value } = injectContentHeight(cardEditor, payload) as typeof payload;
		const currentCardId = cardEditor.data?._id;
		const nextCardId = _.get(value, 'data._id');
		const isCardChanged = currentCardId && nextCardId && currentCardId !== nextCardId;

		dispatch({
			type: UPDATE_EDITABLE_CARD_DATA,
			payload: value,
			meta: {
				// communicate with an auto-sync-middleware
				autoSync: isCardChanged || disableSync ? false : AUTO_SYNC_TARGET.CARD_EDITOR,
				autoSyncImmediate,
			},
		});
	};
}

export function getContentBBHeight(currentMediaQuery: CardEditorType['currentMediaQuery']) {
	const index = CARD_ROOT_ELEMENT_INDEX[COMPONENT_TYPE.CARD];
	const state = COMPONENT_STATES.DEFAULT;
	const { contentDocument, contentWindow } = getClientFrame();

	if (!contentWindow || !contentDocument) {
		return null;
	}

	const contentNode = contentDocument.querySelector(`[data-bb="${COMPONENT_TYPE.CONTENT}"]`) as HTMLDivElement;
	const { height, minHeight } = contentWindow.getComputedStyle(contentNode);

	return {
		path: `elements.${index}.uiConfig.componentProps.other.${state}.${currentMediaQuery}.contentHeight`,
		height,
		minHeight,
	};
}

/**
 * Inject height of the Content element into payload if provided or into cardEditor
 */
export function injectContentHeight(cardEditor: CardEditorType, payload?: PartialUpdate | FullUpdate) {
	const contentHeight = getContentBBHeight(cardEditor.currentMediaQuery);

	if (!contentHeight) return payload || cardEditor;

	if (payload) {
		switch (payload.type) {
			case 'partial':
				return produce(payload, _payload => {
					_payload.value.path.push(contentHeight.path);
					_payload.value.value.push(contentHeight.height);
				});
			case 'full':
				return produce(payload, _payload => {
					if (_payload.value.data) {
						_.set(_payload.value.data, contentHeight.path, contentHeight.height);
					} else {
						_.set(
							_payload.value,
							'data',
							produce(cardEditor.data as CardData, _data => {
								_.set(_data, contentHeight.path, contentHeight.height);
							})
						);
					}
				});
			default:
				return payload;
		}
	}

	return produce(cardEditor, draft => {
		_.set(draft.data!, contentHeight.path, contentHeight.height);
	});
}
