import React from 'react';
import { get } from 'lodash';
import { Field } from 'redux-form';
import cn from 'classnames';
import { Collapse } from 'antd';

import type { StoryModel } from 'types/story';
import type { IGooglePublisherTagIntegration, IOrganizationIntegrations } from 'src/types';
import { GPT_AD_DEFAULT_CLOSING_FN, GPT_AD_DEFAULT_CONTAINER_ID } from 'common/constants';
import { TextField } from 'admin/components/common/Form/TextField';
import { Toggle } from 'admin/components/common/Form/Toggle';
import { Column, Grid } from 'admin/components/common/Grid';
import Tooltip from 'admin/components/common/Tooltip';
import IntegrationsFacade, {
	INTEGRATIONS_NAMES as NAME,
	INTEGRATIONS_SCOPE,
} from 'common/utils/facades/integrations-facade';
import translate from 'common/utils/translate';
import CustomTargeting from './CustomTargeting';

import css from './GooglePublisherTag.scss';

type Props = ({ scope: INTEGRATIONS_SCOPE.STORY; version: string } | { scope: INTEGRATIONS_SCOPE.ORGANIZATION }) & {
	className?: string;
	integrations?: IOrganizationIntegrations;
};

const normalizeToNumber = (value: string) => (value === '' ? undefined : parseInt(value, 10));

const t = (path: string) => translate(`common.integrations.views.${NAME.GOOGLE_PUBLISHER_TAG}.${path}`);

export const GooglePublisherTag: React.FC<Props> = props => {
	const { scope, className } = props;
	const theme = scope === INTEGRATIONS_SCOPE.STORY ? 'dark' : 'light';
	const fields = IntegrationsFacade.getFields('version' in props ? props.version : undefined).googlePublisherTag;

	const renderGroup = (id: keyof Pick<typeof fields, 'large' | 'small' | 'android' | 'ios'>) => {
		return (
			<div className={css.group}>
				<Grid columns="12" columnGap={Grid.gap.medium}>
					<Column colSpan="8">
						<Field
							name={`${fields[id].name}.path`}
							autoComplete="off"
							component={TextField}
							label={t(`path.${id}`)}
							isLabelUppercase={false}
							theme={theme}
							isRequired
							placeholder="/6355419/Travel/Europe"
						/>
					</Column>
					<Column colSpan="2">
						<Field
							name={`${fields[id].name}.size[0]`}
							autoComplete="off"
							component={TextField}
							label={t('width')}
							isLabelUppercase={false}
							theme={theme}
							isRequired
							type="number"
							normalize={normalizeToNumber} // store
							placeholder={id === 'large' ? 750 : 300}
						/>
					</Column>
					<Column colSpan="2">
						<Field
							name={`${fields[id].name}.size[1]`}
							autoComplete="off"
							component={TextField}
							label={t('height')}
							isLabelUppercase={false}
							theme={theme}
							isRequired
							type="number"
							normalize={normalizeToNumber} // store
							placeholder={id === 'large' ? 200 : 250}
						/>
					</Column>
				</Grid>
				{!!props.integrations?.googlePublisherTag?.[id]?.path && (
					<Collapse
						// defaultActiveKey={['0']}
						ghost
						items={[
							{
								key: '1',
								label: (
									<div>
										Custom targeting{' '}
										<a
											/* eslint-disable-next-line max-len */
											href="https://developers.google.com/publisher-tag/reference#googletag.PubAdsService_setTargeting"
											target="_blank"
											rel="noopener noreferrer"
										>
											[?]
										</a>
									</div>
								),
								children: (
									<CustomTargeting
										targets={props.integrations?.googlePublisherTag?.[id]?.targets}
										theme={theme}
										fieldName={`${fields[id].name}.targets`}
									/>
								),
							},
							{
								key: '2',
								label: (
									<div>
										{t('closeFn')}
										<Tooltip
											isStatic
											stylePreset="dark-1"
											destroyTooltipOnHide
											content={t('closeFnHint')}
										>
											{` [?]`}
										</Tooltip>
									</div>
								),
								children: (
									<Field
										name={`${fields[id].name}.closeFn`}
										autoComplete="off"
										component={TextField}
										theme={theme}
										type="text"
										placeholder={GPT_AD_DEFAULT_CLOSING_FN}
										isRequired
									/>
								),
							},
						]}
					/>
				)}
			</div>
		);
	};

	return (
		<div className={cn(css.root, className)}>
			{renderGroup('large')}
			{renderGroup('small')}
			{renderGroup('android')}
			{renderGroup('ios')}
			<div className={css.group}>
				<Grid columns="12">
					<Column colSpan="8">
						<Field
							name={fields.timeout.name}
							autoComplete="off"
							component={TextField}
							label={t('timeout')}
							isLabelUppercase={false}
							theme={theme}
							type="number"
							normalize={normalizeToNumber} // store
							placeholder="15"
							isRequired
						/>
					</Column>
				</Grid>
			</div>
			<div className={css.group}>
				<Grid columns="12">
					<Column colSpan="8">
						<Field
							name={fields.containerId.name}
							autoComplete="off"
							component={TextField}
							label={t('containerId')}
							isLabelUppercase={false}
							theme={theme}
							type="text"
							placeholder={GPT_AD_DEFAULT_CONTAINER_ID}
							isRequired
						/>
					</Column>
				</Grid>
			</div>
			<Field
				className={css.toggle}
				name={fields.showInitially.name}
				component={Toggle}
				theme={theme}
				label={{ children: t('first'), theme }}
				stretch
			/>
			<br />
			<Field
				className={css.toggle}
				name={fields.showBetweenCards.name}
				component={Toggle}
				theme={theme}
				label={{ children: t('between'), theme }}
				stretch
			/>
			<br />
			<Field
				className={css.toggle}
				name={fields.stretch.name}
				component={Toggle}
				theme={theme}
				label={{ children: t('stretch'), theme }}
				stretch
				defaultChecked
			/>
			<br />
			<Field
				className={css.toggle}
				name={fields.stretchHeight.name}
				component={Toggle}
				theme={theme}
				label={{ children: t('stretchHeight'), theme }}
				stretch
				defaultChecked
			/>
		</div>
	);
};

type IsStoryModel<T> = T extends StoryModel ? true : false;

export const validateGPTIntegration = <T extends object>(
	props: IsStoryModel<T> extends true ? { form: T; version: string } : { form: T }
) => {
	const integrationFields = IntegrationsFacade.getFields(
		'version' in props ? props.version : undefined
	).googlePublisherTag;
	const large: IGooglePublisherTagIntegration['large'] | undefined = get(props.form, integrationFields.large.name);
	const small: IGooglePublisherTagIntegration['small'] | undefined = get(props.form, integrationFields.small.name);
	const errors: Array<{ path: string; value: string }> = [];

	[large, small].forEach((item, index) => {
		const basePath = (index === 0 ? integrationFields.large : integrationFields.small).name;
		const sizePath = `${basePath}.size`;
		const [width, height] = item?.size ?? [];
		if (item?.path) {
			if (!Number.isInteger(width)) errors.push({ path: `${sizePath}[0]`, value: t('required') });
			if (!Number.isInteger(height)) errors.push({ path: `${sizePath}[1]`, value: t('required') });
		} else if (Number.isInteger(width) || Number.isInteger(height)) {
			errors.push({ path: `${basePath}.path`, value: t('required') });
		}
	});

	return errors;
};
