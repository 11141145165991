import React from 'react';
import Text from 'admin/components/common/Text';
import { Modal } from 'admin/components/common/Modal';
import t from 'utils/translate';
import type { EditSortableBoxOrderModalProps } from './types';

const ModalContent = React.lazy(() => import('./ModalContent'));

const EditSortableBoxOrderModal: React.FC<EditSortableBoxOrderModalProps> = props => {
	return (
		<Modal
			theme="dark"
			maskColor="black"
			open={props.open}
			destroyOnClose
			width={540}
			transitionName=""
			title={
				<Text
					size={Text.size.subtitle}
					weight={Text.weight.semibold}
					compact
					text={t('story.editOrderModal.title')}
				/>
			}
		>
			<React.Suspense fallback={null}>
				<ModalContent {...props} />
			</React.Suspense>
		</Modal>
	);
};

export default EditSortableBoxOrderModal;
