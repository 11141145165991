import { useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';

import { videos } from 'admin/resources';
import { useAdminSelector } from 'admin/reducers';
import { selectActiveOrganizationId } from 'admin/reducers/user/selectors';

import { toast } from 'admin/components/common/MessageContainer/toast';

import { CancelVideoParams, CancelVideoResult } from 'src/types/videos';

export const useCancelVideo = () => {
	const organizationId = useAdminSelector(selectActiveOrganizationId);

	const { mutate, error, isPending, mutateAsync, isSuccess } = useMutation({
		mutationFn: async (params: Omit<CancelVideoParams, 'organizationId'>) => {
			const { videoId } = params;
			await videos.cancel.params({ organizationId, videoId }).send();

			return {
				success: true,
				result: { videoId },
			} as IBackendBody<CancelVideoResult>;
		},
	});

	useEffect(() => {
		if (error) {
			const errorData = JSON.parse((error as Error).message ?? '{}');
			toast.error(errorData.message || (error as Error).message, 3);
		}
	}, [error]);

	return { cancelVideo: mutate, cancelVideoAsync: mutateAsync, error, isSuccess, isPending };
};
