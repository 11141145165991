import React from 'react';
import { MODAL } from 'admin/constants/common';
import Text from 'admin/components/common/Text';
import { useAdminSelector, useAdminDispatch } from 'admin/reducers';
import Button from 'admin/components/common/Button';
import { setActiveTeam } from 'admin/actions/member';
import { Column } from 'admin/components/common/Grid';
import type { StoriesListItem } from 'admin/reducers/stories-reducer';
import { Modal, ModalBody, ModalFooter } from 'admin/components/common/Modal';
import type { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';
import { selectTeams } from 'admin/reducers/user/selectors';

export type SwitchTeamModalData = {
	story: StoriesListItem;
	onOk: () => void;
	onCancel: () => void;
};

type Props = ModalManagerProvidedProps<MODAL.SWITCH_TEAM>;

const text = {
	title: 'Switch Team Confirmation',
	subtitle: (t: string) => `The duplicated story is in a different team. Would you like to switch to "${t}" team?`,
	yes: 'Switch Team',
	no: 'No, Stay Here',
};

const SwitchTeamModal: React.FC<Props> = props => {
	const dispatch = useAdminDispatch();
	const teams = useAdminSelector(selectTeams);
	const nextTeamId = props.data.story.teamId;
	const nextTeam = teams.find(team => team.id === nextTeamId);

	if (!props.data.story || !nextTeam) {
		return null;
	}

	const onOk = () => {
		dispatch(setActiveTeam(nextTeamId ?? ''));
		props.data.onOk();
		props.close();
	};

	const onCancel = () => {
		props.data.onCancel();
		props.close();
	};

	return (
		<Modal
			title={<Text size="subtitle" weight="semibold" text={text.title} />}
			open={props.open}
			destroyOnClose
			width={564}
		>
			<ModalBody>
				<Text size="subheading" text={text.subtitle(nextTeam.name)} />
			</ModalBody>
			<ModalFooter>
				<Column justifyContent="flex-start" columnGap="medium">
					<Button onClick={onOk} view="primary">
						{text.yes}
					</Button>
					<Button onClick={onCancel} view="secondary">
						{text.no}
					</Button>
				</Column>
			</ModalFooter>
		</Modal>
	);
};

export default SwitchTeamModal;
