import { AdminThunkAction } from 'admin/reducers';
import { storyRollback as storyRollbackEndpoint } from 'admin/resources';
import { STORY_ROLLBACK } from 'admin/constants/actions';

type StoryRollbackAction = {
	type: ValuesType<typeof STORY_ROLLBACK>;
	payload?: string | typeof Error;
};

export function storyRollback(storyId: string, version: string): AdminThunkAction<StoryRollbackAction> {
	return async (dispatch, getState) => {
		if (!storyId) {
			return;
		}

		dispatch({ type: STORY_ROLLBACK.START, payload: storyId });

		try {
			await storyRollbackEndpoint.params({ storyId }).send({ version });
			dispatch({ type: STORY_ROLLBACK.SUCCESS });
		} catch (error) {
			dispatch({ type: STORY_ROLLBACK.FAIL, payload: error as any });
			console.error(error);
		}
	};
}
