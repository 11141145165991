import { pick } from 'lodash';
import { DEFAULT_PARAMS, EmbedStoryParams } from 'src/sdk';
import { IS_DEVEL } from 'utils/environment';
import UrlHelper, { addEndingSlash } from 'utils/url-helper';
import { prependUrlProtocol } from 'utils/helpers';

export function getEmbedCode(
	clientStoryId: string,
	customParams: EmbedStoryParams & Partial<{ fixedSize: boolean; width: number; height: number }> = {}
) {
	const sdkVersion = `${process.env.BUILD_ID || '0'}-${Math.round(Math.random())}`;
	const { isStretchHeight, fixedSize, width, height } = customParams;
	const params: EmbedStoryParams = pick({ ...DEFAULT_PARAMS, ...customParams }, Object.keys(DEFAULT_PARAMS));
	const paramsKeys = Object.keys(params) as Array<keyof typeof params>;
	const providedParams = paramsKeys
		.reduce((memo: string[], key) => {
			const isDefaultDomain = key === 'domain' && Boolean(params[key]?.includes?.(UrlHelper.client));

			if (isDefaultDomain) {
				// Avoid passing the default domain as a parameter to minimize embed code size,
				// as the sdk-prod script already includes it.
				return memo;
			}

			if (params[key] !== DEFAULT_PARAMS[key] && params[key] !== undefined) {
				memo.push(`${key}: ${typeof params[key] === 'string' ? `'${params[key]}'` : params[key]}`);
			}

			return memo;
		}, [])
		.join(',');

	const domain = addEndingSlash(params.domain || `${UrlHelper.client}/${clientStoryId}`);
	const src = IS_DEVEL ? 'src/sdk' : prependUrlProtocol(`${domain}sdk-prod.js?ver=${sdkVersion}`, 'https');
	const id = `story-${clientStoryId}`;
	const dataId = clientStoryId;

	const css = {
		width: fixedSize ? `${width}px` : '',
		height: fixedSize ? `${isStretchHeight ? '100vh' : `${height}px`}` : `${isStretchHeight ? '100vh' : ''}`,
	};
	const styleAttr = `${css.width ? `width: ${css.width};` : ''}${css.height ? `height: ${css.height};` : ''}`;

	return {
		// prettier-ignore
		// eslint-disable-next-line max-len,prettier/prettier
		html: `<div id="${id}"${styleAttr ? ` style="${styleAttr}"` : ''}></div><script src="${src}" onload="(() => embedStory('${clientStoryId}', {${providedParams}}))()" data-id="${dataId}" async></script>`,
		// prettier-ignore
		// eslint-disable-next-line max-len
		js: `const c = document.createElement('div'); c.id = '${id}'; c.style.width = '${css.width}'; c.style.height = '${css.height}'; const s = document.createElement('script'); s.onload = () => window.embedStory('${clientStoryId}', {${providedParams}}); s.src = '${src}'; s.dataset.id = '${dataId}'; s.async = true; const t = document.body; t.append(c); t.append(s); `,
	};
}
