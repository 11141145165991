import React from 'react';
import cn from 'classnames';

import css from './RoundProgressLoader.scss';

type Props = { theme: 'dark' | 'light'; progress: number; className?: string };

export const RoundProgressLoader: React.FC<Props> = ({ theme, progress, className = '' }) => {
	const radius = 12;
	const circumference = 2 * Math.PI * radius;
	const strokeDashoffset = circumference - (progress / 100) * circumference;

	return (
		<div className={cn(css.roundProgress, css[theme], className)}>
			<svg className={css.loader} width="28" height="28" viewBox="0 0 28 28">
				<circle
					strokeLinecap="butt"
					strokeDasharray={circumference}
					strokeDashoffset={strokeDashoffset}
					cx="14"
					cy="14"
					r={radius}
					strokeWidth="3"
					fill="none"
				/>
			</svg>
		</div>
	);
};
