// routes:
import { get } from 'lodash';

export const ANY = '*';
export const ROOT = '/';
export const ADMIN_PREVIEW_PAGE = `${ROOT}preview.html`;
export const ERROR_PAGE = `${ROOT}404.html`;

const STORIES_DOMAIN = get(window, 'STORIES_DOMAIN') as string | undefined;
const STORY_BASE_PATH = get(window, 'STORY_BASE_PATH') as string | undefined;
const STORY_JSON_PATH = get(window, 'STORY_JSON_PATH') as string | undefined;
const STORY_METADATA_PATH = get(window, 'STORY_METADATA_PATH') as string | undefined;
export const STORY_PATH = {
	domain: `/${STORIES_DOMAIN}`,
	base: `/${
		window.location.hostname === STORIES_DOMAIN
			? STORY_BASE_PATH
			: typeof STORY_BASE_PATH === 'string'
				? STORY_BASE_PATH.split('/')[1]
				: ''
	}`,
	story: `/${STORY_JSON_PATH}`,
	metadata: `/${STORY_METADATA_PATH}`,
};
export const CARD_SHARE_PATH = `${STORY_PATH.base}/c/:cardId/`;
