import React from 'react';
import cn from 'classnames';
import _ from 'lodash';

import { Icon } from 'admin/components/common/Icon';
import { Errors } from 'admin/components/common/Form/Errors';
import { ReduxFieldInputTypes, ReduxFieldMetaTypes } from 'admin/components/common/Form/utils';
import { InspectorTypes } from 'admin/components/pages/Story/CardEditor/Inspector/types';
import css from './TextField.scss';

const onFocus =
	(props: Required<Pick<PFTextProps, 'autoSelect' | 'input'>>) => (event: React.FocusEvent<HTMLInputElement>) => {
		if (props.autoSelect) {
			event.target.select();
		}
		props.input.onFocus(event);
	};

const onBlur =
	(props: Required<Pick<PFTextProps, 'meta' | 'input' | 'eventListeners' | 'saveOn'>>) =>
	(event: React.FocusEvent<HTMLInputElement>) => {
		const isValueChanged = props.meta.initial !== props.input.value;
		props.input.onBlur(event);
		if (isValueChanged && props.saveOn === 'blur') {
			props.eventListeners.onBlur?.(event);
		}
	};

const debounceOnChange = (
	props: Required<Pick<PFTextProps, 'eventListeners'>>,
	event: React.ChangeEvent<HTMLInputElement>
) => {
	props.eventListeners.onChange?.(event);
};

const debouncedOnChange = _.debounce(debounceOnChange, 300);

const onChange =
	(props: Required<Pick<PFTextProps, 'input' | 'eventListeners' | 'saveOn'>>) =>
	(event: React.ChangeEvent<HTMLInputElement>) => {
		props.input.onChange(event);

		if (props.saveOn === 'change') debouncedOnChange(props, event);
	};

export type PFTextProps = {
	input: ReduxFieldInputTypes & { readOnly?: boolean; style?: Record<string, string | number> };
	meta: ReduxFieldMetaTypes;
	className?: string;
	autoSelect?: boolean;
	placeholder?: string;
	showErrorsOn?: string | boolean;
	eventListeners?: ReturnType<InspectorTypes.GetFieldListeners>;
	clearable?: boolean;
	saveOn?: 'change' | 'blur';
	defaultValue?: string;
	fieldRef?: React.MutableRefObject<HTMLInputElement | null>;
};

export const TextField = ({
	saveOn = 'change',
	autoSelect = true,
	showErrorsOn = 'touched',
	eventListeners = {},
	fieldRef,
	...props
}: PFTextProps) => {
	const { className, input, meta, placeholder } = props;
	const handleFocus = onFocus({ input, autoSelect });
	const handleBlur = onBlur({ input, meta, eventListeners, saveOn });
	const handleChange = onChange({ input, eventListeners, saveOn });
	const id = `${meta.form}.${input.name}`;
	const inputValue =
		props.input.value === '' && props.defaultValue !== undefined
			? props.defaultValue
			: (props.input.value as string);

	return (
		<div className={cn(css.pfText, className)}>
			<input
				id={id}
				type="text"
				placeholder={placeholder}
				{...input}
				value={inputValue}
				className={css.pfInput}
				onFocus={handleFocus}
				onBlur={handleBlur}
				onChange={handleChange}
				onClick={eventListeners?.onClick}
				autoComplete="off"
				style={{
					direction: /[\u0590-\u05FF\u0600-\u06FF]/.test(input.value) /* Hebrew/Arabic characters */
						? 'rtl'
						: 'ltr',
					...input.style,
				}}
				ref={fieldRef}
			/>
			{props.clearable && (
				<button
					data-name={input.name}
					className={css.clearBtn}
					type="button"
					onClick={() => {
						// see `PropField/index.tsx`
						const field = document.querySelector(`input[type="hidden"][name="${input.name}"]`);
						if (field) (field as HTMLInputElement).click();
					}}
				>
					<Icon type="x-rounded" />
				</button>
			)}
			<Errors show={typeof showErrorsOn === 'string' ? meta[showErrorsOn] : showErrorsOn}>{meta.error}</Errors>
		</div>
	);
};

/*
 Example:
	<Field
		name="email"
		component={TextField}
		validate={[required, email]}
		className="--custom-class"
		placeholder="email"
		showErrorsOn="touched"
		autoSelect
	/>
*/
