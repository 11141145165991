import { useMemo, useEffect } from 'react';
import { debounce } from 'lodash';

const useDebounced = <T extends (...args: any[]) => void>(callback: T, delay: number = 300) => {
	const debouncedCallback = useMemo(() => debounce(callback, delay), [callback, delay]);

	useEffect(() => {
		return () => debouncedCallback.cancel();
	}, [debouncedCallback]);

	return debouncedCallback;
};

export default useDebounced;
