import React from 'react';
import cn from 'classnames';
import css from './Label.scss';

export type LabelProps = {
	component?: React.ElementType | string;
	theme?: 'dark' | 'light';
	className?: string;
	children?: React.ReactNode[] | React.ReactNode;
	htmlFor?: string;
	weight?: 'normal' | 'semibold';
};

export const Label: React.FC<LabelProps> = ({
	component: Component = 'label',
	theme = 'light',
	weight = 'semibold',
	...props
}) =>
	props.children ? (
		<Component {...props} className={cn(css.inputLabel, css[theme], css[`weight-${weight}`], props.className)} />
	) : null;
