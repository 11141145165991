import React from 'react';
import { createPortal } from 'react-dom';
import { connect, ConnectedProps } from 'react-redux';
import { getClientFrame } from 'common/utils/iframe-tunnel';
import type { AdminReducerState } from 'admin/reducers';
import { selectEditableElement } from 'admin/reducers/card-editor-extra/selectors';
import { hasValue, minMax } from './helpers';
import { selectedNodeData, updateElementInlineStyle } from './utils';
import css from './NumberField.scss';

type State = {
	dragStart: boolean;
	dragging: boolean;
	deltaY: number;
	startY: number;
	startValue?: number;
};

interface Props extends ConnectedProps<typeof connector> {
	unit?: string;
	step?: number;
	dragStep?: number;
	decimals?: number;
	min?: number;
	max?: number;
	inputRef: React.MutableRefObject<HTMLInputElement | null>;
	editableValue: string;
	editableData: ReturnType<typeof selectedNodeData>;
	setEditableValue: React.Dispatch<React.SetStateAction<string | undefined>>;
}

class DragController extends React.Component<Props, State> {
	inputRef = this.props.inputRef.current;

	tolerance = 10;

	state: State = {
		dragStart: false,
		dragging: false,
		deltaY: 0,
		startY: 0,
	};

	componentDidMount() {
		this.inputRef?.addEventListener('mousedown', this.onMouseDown);

		document.addEventListener('mousemove', this.onMouseMove);

		document.addEventListener('mouseup', this.onMouseUp);

		getClientFrame().contentDocument?.addEventListener('mouseup', this.onMouseUp);
	}

	componentWillUnmount() {
		this.inputRef?.removeEventListener('mousedown', this.onMouseDown);

		document.removeEventListener('mousemove', this.onMouseMove);

		document.removeEventListener('mouseup', this.onMouseUp);

		getClientFrame().contentDocument?.removeEventListener('mouseup', this.onMouseUp);
	}

	get editorWidthRatio() {
		return this.props.editorWidth / this.props.editorWidthMin;
	}

	onMouseDown = (event: MouseEvent) => {
		/*
		 Why multiplied to ratio?
		   After changing the width of the platform, the value in the field is unchanged,
		   while the actual value has changed due to the "VW" units,
		   so the multiplication here helps to offset from the actual value
		 */
		const startValue = parseFloat(`${+this.props.editableValue * this.editorWidthRatio}`);

		if (!hasValue(startValue)) return;

		this.setState({
			dragStart: true,
			startY: event.pageY,
			startValue,
		});
	};

	onMouseMove = (event: MouseEvent) => {
		if (!this.state.dragStart) return;

		event.preventDefault();

		const shouldAllowDragging = Math.abs(event.pageY - this.state.startY) >= this.tolerance;

		if (shouldAllowDragging && !this.state.dragging) {
			this.setState({ dragging: true, startY: event.pageY });
		}

		if (!this.state.dragging) return;

		this.setState(
			prevState => ({ deltaY: prevState.startY - event.pageY }),
			this.updatedEditableElementInlineStyle
		);
	};

	onMouseUp = (event: MouseEvent) => {
		if (!this.state.dragStart) {
			return;
		}

		if (this.state.dragging) {
			// call select, then blur, to initiate update store by NumberField onBlur event
			this.inputRef?.select();
			this.inputRef?.blur();
		}

		const resetElementInlineStyle = this.state.dragging
			? () => {
					const node = this.props.editableData?.node;
					const property = this.props.editableData?.property;
					if (node) {
						setTimeout(() => {
							if (node && property) {
								property.forEach(prop => updateElementInlineStyle({ property: prop, value: '', node }));
							}
						}, 0); // run asynchronously after finishing current execution block in browser stack
					}
				}
			: undefined;

		this.setState({ dragStart: false, dragging: false, deltaY: 0, startY: 0 }, resetElementInlineStyle);
	};

	updatedEditableElementInlineStyle = () => {
		const node = this.props.editableData?.node;
		const property = this.props.editableData?.property;

		if (!node || !property) return;

		const dragStep = (this.props.dragStep || this.props.step) ?? 1;
		const accelerate = dragStep * 0.5;
		const nextValue = minMax({
			value: +(parseFloat(`${this.state.startValue ?? '0'}`) + this.state.deltaY * accelerate * dragStep).toFixed(
				this.props.decimals
			),
			min: this.props.min,
			max: this.props.max,
		});

		const nextValueWithUnit = `${nextValue}${this.props.unit}`;

		property.forEach(prop => {
			if (nextValueWithUnit === node.style[prop]) return;

			updateElementInlineStyle({ property: prop, value: nextValueWithUnit, node });
		});

		this.props.setEditableValue(nextValue.toString());
	};

	render() {
		return this.state.dragging && createPortal(<div className={css.overlay} />, document.body);
	}
}

const mapStateToProps = (state: AdminReducerState) => ({
	element: selectEditableElement(state),
	editorWidth: state.cardEditorExtra.size.width,
	editorWidthMin: state.cardEditorExtra.size.min,
});

const connector = connect(mapStateToProps);

export default connector(DragController);
