import React, { RefObject } from 'react';
import cn from 'classnames';
import type { BBCommonProps, BBEditableModeProps } from 'types/story';
import withCardTransitionContext from 'client/components/common/BuildingBlocks/BuildingBlockEnhancer';
import css from 'client/components/common/BuildingBlocks/Shape/Shape.scss';

export const SHAPE_TYPE = {
	RECT: 'rect',
	CIRCLE: 'circle',
	CIRCLE_SVG: 'circle-svg',
	LINE: 'line',
	LINE_SVG: 'line-svg',
} as const;

export const SHAPE_LINE_DIRECTION = {
	HORIZONTAL: 'h',
	VERTICAL: 'v',
} as const;

export const ANIMATE_DIRECTION = {
	HORIZONTAL: 'h',
	VERTICAL: 'v',
} as const;

export const Rect = () => (
	<svg xmlns="http://www.w3.org/2000/svg" version="1.1">
		<rect x1="0" y1="0" width="100%" height="100%" className={css.rect} />
	</svg>
);

export const Circle = () => (
	<svg xmlns="http://www.w3.org/2000/svg" version="1.1">
		<ellipse cx="50%" cy="50%" rx="50%" ry="50%" className={css.circle} />
	</svg>
);

type LineProps = {
	size?: string;
	direction: (typeof SHAPE_LINE_DIRECTION)[keyof typeof SHAPE_LINE_DIRECTION];
};

export const Line = ({ direction, size = '1' }: LineProps) => (
	<svg xmlns="http://www.w3.org/2000/svg" version="1.1">
		{direction === SHAPE_LINE_DIRECTION.HORIZONTAL ? (
			<line
				style={{ strokeWidth: size }}
				x1="-5000"
				x2="5000"
				y1="0"
				y2="1"
				className={css.line}
				shapeRendering="auto"
			/>
		) : (
			<line
				style={{ strokeWidth: size }}
				x1="0"
				x2="1"
				y1="-5000"
				y2="5000"
				className={css.line}
				shapeRendering="auto"
			/>
		)}
	</svg>
);

type ShapeProps = BBCommonProps;

class Shape extends React.Component<ShapeProps> {
	get type() {
		return this.props.uiConfig.componentProps.other.shapeType ?? SHAPE_TYPE.RECT;
	}

	get lineDir() {
		return this.props.uiConfig.componentProps.other.lineDir!;
	}

	renderShape = () => {
		switch (this.type) {
			case SHAPE_TYPE.RECT:
				return <Rect />;
			case SHAPE_TYPE.CIRCLE:
				return null;
			case SHAPE_TYPE.CIRCLE_SVG: // has issues. not used but reserved for future needs
				return <Circle />;
			case SHAPE_TYPE.LINE:
				return <div data-shape="line" className={cn(css.lineBlock, css[`line-block-${this.lineDir}`])} />;
			case SHAPE_TYPE.LINE_SVG: // has issues. not used but reserved for future needs
				return <Line direction={this.lineDir} size={this.props.uiConfig.componentProps.other.lineSize} />;
			default:
				return <Rect />;
		}
	};

	renderEditable() {
		const props = { ...this.props.editableModeProps?.nodeProps };
		return this.renderDefault(props);
	}

	renderDefault(props: Partial<BBEditableModeProps['nodeProps']> = {}) {
		const { nodeProps } = this.props.uiConfig;

		return (
			<div
				{...nodeProps}
				{...this.props.stateAttrs}
				{...this.props.eventListeners}
				{...props}
				className={cn(css.shape, css[`shape-${this.type}`], nodeProps.className, props.className)}
				ref={this.props.containerRef as RefObject<HTMLDivElement>}
			>
				{this.renderShape()}
			</div>
		);
	}

	render() {
		return this.props.isEditableMode ? this.renderEditable() : this.renderDefault();
	}
}

export default withCardTransitionContext(Shape);
