import React from 'react';
import _ from 'lodash';

import type { IOrganizationIntegrations, IUserOrganization } from 'src/types';
import { isObjWithEmptyFields } from 'utils/helpers';
import IntegrationsFacade, { INTEGRATIONS_PAGE_VIEW, INTEGRATIONS_SCOPE } from 'utils/facades/integrations-facade';
import { Search } from 'admin/components/common/Search';
import Text from 'admin/components/common/Text';

import { _t } from 'admin/components/pages/Settings/Integration/helpers';

import { IntegrationItem } from 'admin/components/pages/Settings/Integration/IntegrationItem';
import css from './SelectIntegration.scss';

type Props = {
	organization: IUserOrganization | undefined;
	onViewChange: (val: INTEGRATIONS_PAGE_VIEW) => void;
	integrations: IOrganizationIntegrations | undefined;
};

export const SelectIntegration: React.FC<Props> = props => {
	const [search, setSearch] = React.useState('');
	const targetIntegrationsList = React.useMemo(
		() =>
			IntegrationsFacade.items.filter(intItem => {
				const name = _t(`${intItem.translateKey}.title`);

				return (
					(intItem.scope as INTEGRATIONS_SCOPE[]).includes(INTEGRATIONS_SCOPE.ORGANIZATION) &&
					name.toLowerCase().includes(search.toLowerCase())
				);
			}),
		[search]
	);

	const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(e.target.value);
	};

	const onSearchClear = () => {
		setSearch('');
	};

	const onIntegrationItemClick = (viewId: INTEGRATIONS_PAGE_VIEW) => {
		props.onViewChange(viewId);
	};

	const renderIntegrationList = () => {
		return (
			<div className={css.integrationsList}>
				{targetIntegrationsList.length !== 0 ? (
					targetIntegrationsList.map(item => {
						const active = !isObjWithEmptyFields(
							_.get(props.organization?.metadata?.integrations, item.id)
						);

						return (
							<IntegrationItem
								isActive={active}
								item={item}
								key={item.id}
								className={css.integrationItem}
								onClick={onIntegrationItemClick}
							/>
						);
					})
				) : (
					<Text className={css.noItemsLabel}>
						{_t(`${IntegrationsFacade.titles[INTEGRATIONS_PAGE_VIEW.DEFAULT]}.noItems`)}
					</Text>
				)}
			</div>
		);
	};

	return (
		<div className={css.selectIntegration}>
			<Search
				value={search}
				onSearchChange={onSearchChange}
				placeholder={`${_t(
					`${IntegrationsFacade.titles[INTEGRATIONS_PAGE_VIEW.DEFAULT]}.searchPlaceholder`
				)}...`}
				className={css.search}
				onClear={onSearchClear}
			/>

			{renderIntegrationList()}
		</div>
	);
};
