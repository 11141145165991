import { captureMessage } from '@sentry/react';
import type { CardEditorType, StoryVersionType } from 'types/story';
import { StoryFacade } from 'utils/facades/story-facade';
import { createThunkAction } from 'admin/actions/helpers';
import { UPDATE_PUBLISHED_STORY } from 'admin/constants/actions';
import { processStory } from 'admin/actions/story/get-story';
import { updatePublishedStory as updatePublishedStoryEndpoint } from 'admin/resources';
import { injectContentHeight } from 'admin/actions/card-editor/update-editable-card-data';
import { getMergeOfEditableStoryWithCardEditor } from 'admin/actions/card-editor/card-editor-sync';
import { storyEditorChange, storyEditorFormChange } from 'admin/actions/story/update-editable-story';
import { selectOpCounter } from 'admin/reducers/story-editor/selectors';
import { selectCurrentVersion } from 'admin/reducers/version-reducer';
import { onUpdateVersionError } from './update-latest-story';

export type UpdatePublishedStoryParams = {
	isCardEditor?: boolean;
};

export const updatePublishedStory = createThunkAction<StoryVersionType, UpdatePublishedStoryParams>({
	type: UPDATE_PUBLISHED_STORY,
	payloadCreator: async (params, { getState, dispatch }) => {
		const state = getState();
		const version = selectCurrentVersion(state);
		const opCounter = selectOpCounter(state);

		if (!state.storyEditor.story) {
			return { success: false };
		}

		// process data #1 (pick from card editor + updateGlobalElements + inject content height)
		const storyEditor = params.isCardEditor
			? getMergeOfEditableStoryWithCardEditor(
					state.storyEditor.story,
					injectContentHeight(state.cardEditor.present) as CardEditorType,
					version
				)
			: state.storyEditor.story;

		// process data #2 (see processStory())
		const Story = new StoryFacade(storyEditor, version);
		let { settings, storyId, data: storyData } = Story;

		try {
			({ data: storyData, settings, storyId } = processStory(Story, { parseSystemFontsUsage: true }));
		} catch (e) {
			console.error(e);
			captureMessage('updatePublishedStory:processStory() is failed');
		}

		// configure payload
		const payload = {
			storyData,
			settings,
			opCounter,
		};

		await updatePublishedStoryEndpoint.params({ storyId }).send(payload);

		/*
		 Configure and update state with a "published-like" version to avoid request of getStory after publish.
		 Note: This part can be omitted if backend will be configured to return published version data in response.
		 */
		const published = Story.base.storyVersions[StoryFacade.VERSIONS.published] as StoryVersionType | undefined;
		const updatedAt = new Date().toISOString();
		const versionPath = `storyVersions.${StoryFacade.VERSIONS.published}`;
		const nextPublished: StoryVersionType = {
			...published,
			storyId,
			updatedAt,
			data: payload.storyData,
			settings: payload.settings,
			changelog: published?.changelog || null,
			createdAt: published?.createdAt || updatedAt,
			version: published?.version || StoryFacade.VERSIONS.published,
		};

		// update form.storyEditor
		dispatch(storyEditorFormChange({ path: versionPath, data: nextPublished }));
		// update storyEditor
		dispatch(storyEditorChange({ path: versionPath, data: nextPublished }));

		return { success: true, result: nextPublished };
	},
	onError: onUpdateVersionError,
});
