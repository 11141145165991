import React, { FC } from 'react';

import Popover from 'admin/components/pages/Story/CardEditor/Inspector/Popover';
import type { InspectorTypes } from 'admin/components/pages/Story/CardEditor/Inspector/types';
import type { ReduxFieldInputTypes, ReduxFieldMetaTypes } from 'admin/components/common/Form/utils';
import { TextField } from 'admin/components/pages/Story/CardEditor/Inspector/PropField/TextField';
import FillPreview from 'admin/components/pages/Story/CardEditor/Inspector/PropField/FillPreview';
import ColorPicker from 'admin/components/pages/Story/CardEditor/Inspector/PropField/ColorPicker';
import StorySwatches from 'admin/components/pages/Story/CardEditor/Inspector/PropField/StorySwatches';

import css from './ColorField.scss';

type Props = {
	input: ReduxFieldInputTypes;
	forcedValue?: string;
	meta: ReduxFieldMetaTypes;
	eventListeners?: ReturnType<InspectorTypes.GetFieldListeners>;
};

const ColorField: FC<Props> = ({ forcedValue = '', ...props }) => {
	const { eventListeners, input } = props;
	const { name: inputName, onChange: inputOnChange } = input;
	const inputValue = props.input.value || forcedValue;
	const onChange = (value: string) => {
		inputOnChange(value);
		eventListeners?.onChange?.({ target: { value, name: inputName } });
	};

	return (
		<Popover
			content={
				<StorySwatches route="admin">
					{swatches => <ColorPicker onChange={onChange} rgba={inputValue} reset swatches={swatches} />}
				</StorySwatches>
			}
		>
			<div className={css.control}>
				<TextField
					input={{
						...input,
						value: input.value || forcedValue,
						readOnly: true,
						style: {
							position: 'absolute',
							opacity: 0,
							right: '-9999px',
							display: 'none',
						},
					}}
					meta={props.meta}
					eventListeners={eventListeners}
				/>
				<FillPreview value={inputValue} />
			</div>
		</Popover>
	);
};

export { ColorField };
