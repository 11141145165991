import React, { Component, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import cn from 'classnames';
import _ from 'lodash';
import type { StoryMediaQuery, StoryModel } from 'types/story';
import type { AdminReducerState } from 'admin/reducers';
import { EventEmitter } from 'common/utils/event-emitter';
import { DEFAULT_MEDIA_QUERY_SIZES } from 'common/constants';
import { getMediaQueryBoundaries } from 'common/utils/story-media-query';
import { Editor } from 'admin/components/pages/Story/CardEditor/Editor/Editor';

import Ruler from './Ruler';
import devicesConfig from './config';

import css from './Resizer.scss';

type ResizerSetDisabledEvent = { type: 'setDisabled'; data: boolean };
type ResizerGetScaledEvent = { type: 'getScale' };
export type ResizerSetScaleEvent = { type: 'setScale'; data: number };
type ResizerToggleScaleEvent = { type: 'toggleScale'; data: boolean };
type ResizerSetCardHeightEvent = { type: 'setCardHeight'; data: number };

export const ResizerEvEm = new EventEmitter<
	| ResizerSetDisabledEvent
	| ResizerSetScaleEvent
	| ResizerGetScaledEvent
	| ResizerToggleScaleEvent
	| ResizerSetCardHeightEvent
>();

function getInitialPlatformWidth(props: {
	platform: Props['currentMediaQuery'];
	min: number;
	max: number;
	containerWidth: number;
}) {
	const containerOffset = 0;

	switch (props.platform) {
		case 'desktop':
		case 'tablet':
			return `${Math.max(props.min, Math.min(props.containerWidth - containerOffset, props.max))}px`;
		case 'mobileLandscape':
			return `${Math.min(667, props.max)}px`;
		case 'mobile':
			return `${Math.min(375, props.max)}px`;
		default:
			return '';
	}
}

enum HeightType {
	aspectRatio = 'aspect-ratio',
	content = 'content',
}

const SIDES = {
	left: 'left',
	right: 'right',
} as const;

const defaultProps = {
	className: '',
	initialWidth: '',
	onStartChangeSize: _.noop,
	onEndChangeSize: _.noop,
	onChangingSize: _.noop,
	onContainerClick: _.noop,
};

type State = {
	disabled: boolean;
	isResizing: boolean;
	currentWidth: number;
	updatedWidth: number;
	error: boolean;
	resizeSide: null | (typeof SIDES)[keyof typeof SIDES];
	isFullScreen: boolean;
	isScaleEnabled: boolean; // is enabled scale of #client-frame or either <Resizer> children
	boundaries: { min: number; max: number };
};

type Props = {
	currentMediaQuery: AdminReducerState['cardEditor']['present']['currentMediaQuery'];
	mediaQuery: {
		defaultPlatform: StoryMediaQuery['defaultPlatform'];
		config: StoryMediaQuery['config'];
	};
	className?: string;
	onStartChangeSize?: () => void;
	onEndChangeSize?: (width: string) => void;
	onChangingSize?: (props: { width: number }) => void;
	onContainerClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
	initialWidth?: string;
	canvasWrapperClassName?: string;
	children: (provided: { boundaries: { min: number; max: number }; scale: number }) => ReactNode;
	storyType: StoryModel['type'];
} & typeof defaultProps;

export default class Resizer extends Component<Props, State> {
	static defaultProps = defaultProps;

	static DRAG_HANDLER_EVENT = 'Resizer/onDragHandlerMouseEvent';

	state: State = {
		disabled: false,
		isResizing: false,
		currentWidth: 0,
		updatedWidth: 0,
		error: false,
		resizeSide: null,
		isFullScreen: false,
		isScaleEnabled: true,
		boundaries: { min: 320, max: this.containerWidth },
	};

	containerRef = React.createRef<HTMLDivElement>();

	siteCanvasRef = React.createRef<HTMLDivElement>();

	rulerRef = React.createRef<HTMLDivElement>();

	startDragPoint = { posX: 0 };

	el = document.querySelector('.resizer') as HTMLElement;

	heightType =
		this.props.storyType === 'embed' || this.props.storyType === 'widget'
			? HeightType.content
			: HeightType.aspectRatio;

	componentDidMount() {
		ResizerEvEm.addListener('setDisabled', this.setDisabled);
		ResizerEvEm.addListener('getScale', this.getScaleListener);
		ResizerEvEm.addListener('toggleScale', this.toggleScaleListener);
		if (this.heightType === HeightType.content) {
			ResizerEvEm.addListener('setCardHeight', this.cardHeightListener);
		}

		const { min, max } = this.getBoundaryValues(true);
		const width =
			this.props.initialWidth ||
			getInitialPlatformWidth({
				platform: this.props.currentMediaQuery,
				min,
				max,
				containerWidth: this.containerWidth,
			}) ||
			`${min}px`;

		if (this.siteCanvasRef.current) {
			this.setCanvasSize({ width });
		}

		this.setBoundaries({ min, max });

		if (this.siteCanvasRefOffsetLeft === 0 && !this.state.isFullScreen) {
			this.setState({ isFullScreen: true });
		}

		window.addEventListener('resize', this.onResize);

		// Register global event to resize by CardEditor/ZoomSlider component
		window.addEventListener(Resizer.DRAG_HANDLER_EVENT, this.onWindowDragHandlerMouseEvent);
	}

	componentDidUpdate(prevProps: Props) {
		ResizerEvEm.emit('setScale', this.scale);

		if (prevProps.currentMediaQuery !== this.props.currentMediaQuery) {
			const { min, max } = this.getBoundaryValues(true);
			const width =
				getInitialPlatformWidth({
					platform: this.props.currentMediaQuery,
					min,
					max,
					containerWidth: this.containerWidth,
				}) || `${min}px`;

			this.setCanvasSize({ width });

			if (this.props.onEndChangeSize) {
				this.props.onEndChangeSize(width);
			}

			this.setBoundaries({ min, max });
		}
	}

	componentWillUnmount() {
		ResizerEvEm.removeListener('setDisabled', this.setDisabled);
		ResizerEvEm.removeListener('getScale', this.getScaleListener);
		ResizerEvEm.removeListener('toggleScale', this.toggleScaleListener);
		ResizerEvEm.removeListener('setCardHeight', this.cardHeightListener);
		window.removeEventListener(Resizer.DRAG_HANDLER_EVENT, this.onWindowDragHandlerMouseEvent);
		window.removeEventListener('resize', this.onResize);
		document.removeEventListener('mousemove', this.onDocumentMouseEvent);
		document.removeEventListener('mouseup', this.onDocumentMouseEvent);
	}

	get siteCanvasRefOffsetLeft() {
		return this.siteCanvasRef.current ? this.siteCanvasRef.current.getBoundingClientRect().left : 0;
	}

	get containerWidth() {
		return (
			this.containerRef?.current?.offsetWidth ||
			document.getElementById(Editor.ID)?.offsetWidth ||
			window.innerWidth
		);
	}

	get scale() {
		return this.containerWidth / this.state.boundaries.min;
	}

	// toggle scale of #client-frame or either <Resizer> children
	toggleScaleListener = (event: ResizerToggleScaleEvent) => this.setState({ isScaleEnabled: event.data });

	// request current scale
	getScaleListener = () => ResizerEvEm.emit('setScale', this.scale);

	setDisabled = (event: ResizerSetDisabledEvent) => {
		this.setState({ disabled: event.data });
	};

	cardHeightListener = (event: ResizerSetCardHeightEvent) => {
		this.setCanvasSize({ height: event.data });
	};

	setCanvasSize = (params: EitherField<{ width: string | number; height: number }>) => {
		const canvas = this.siteCanvasRef.current;
		if (!canvas) return;

		if (params.width) {
			const providedWidth = typeof params.width === 'number' ? params.width : parseInt(params.width, 10);
			const width = Math.min(providedWidth, this.containerWidth);
			const { ratio } = DEFAULT_MEDIA_QUERY_SIZES[this.props.currentMediaQuery];
			canvas.style.width = `${width}px`;
			if (this.heightType === HeightType.aspectRatio) {
				canvas.style.height = `${width / ratio}px`;
			}
		}

		if (params.height && this.heightType === HeightType.content) {
			canvas.style.height = `${params.height}px`;
		}
	};
	/**
	 * pureValue param need for getting pure min or max without checking
	 * if they fit in containerWidth
	 * @param {Boolean} pureValues
	 */
	getBoundaryValues = (pureValues = false) => {
		const { currentMediaQuery, mediaQuery } = this.props;
		const boundaries = getMediaQueryBoundaries({
			mediaQuery,
			currentMediaQuery,
			containerWidth: this.containerWidth,
		});

		return {
			min: pureValues ? boundaries.min : Math.min(boundaries.min, boundaries.containerWidth),
			max: pureValues ? boundaries.max : Math.min(boundaries.max, boundaries.containerWidth),
			isWidest: boundaries.isWidest,
			containerWidth: boundaries.containerWidth,
		};
	};

	/**
	 * @param boundaries optional, passed only for optimization reason to omit same calculations twice
	 */
	setBoundaries = (boundaries?: { min: number; max: number }) => {
		const { min, max } = boundaries || this.getBoundaryValues(true);
		const isError = min > this.containerWidth;
		this.setState({ boundaries: { min, max }, error: isError });
	};

	onResize = () => {
		if (!this.siteCanvasRef.current || !this.containerRef.current) {
			return;
		}

		const canvasWidth = this.siteCanvasRef.current.offsetWidth;
		const { containerWidth } = this;

		const isShouldFit = canvasWidth > containerWidth && !this.state.error; // zoom out
		const isShouldFill =
			canvasWidth < containerWidth &&
			canvasWidth < this.state.boundaries.min &&
			this.state.boundaries.min <= containerWidth;

		if (isShouldFit || isShouldFill) {
			this.setCanvasSize({ width: containerWidth });
			if (this.props.onEndChangeSize) {
				this.props.onEndChangeSize(this.siteCanvasRef.current.style.width);
			}
		}

		this.setBoundaries();
	};

	onDocumentMouseEvent = e => {
		const { currentWidth, resizeSide } = this.state;
		const { onEndChangeSize, onChangingSize } = this.props;
		const { min, max } = this.getBoundaryValues();
		const { points } = devicesConfig;

		let pbTargetPosX = 0;
		let targetWidth: number;
		let pageX = 0;

		switch (e.type) {
			case 'mousemove':
			case 'mouseup':
				pageX = _.get(e, 'pageX');
				pbTargetPosX = (pageX - this.startDragPoint.posX) * 2;

				if (resizeSide === SIDES.left) {
					targetWidth = Math.max(min, Math.min(max, currentWidth - pbTargetPosX));
				} else {
					targetWidth = Math.max(min, Math.min(max, currentWidth + pbTargetPosX));
				}

				_.forEach(points, (devices, point) => {
					// @ts-expect-error ts-migrate FIXME
					const inRange = _.inRange(point, min, max + 1);
					const isShouldSnap = Math.abs(parseFloat(point) - targetWidth) < 5 && inRange;
					if (isShouldSnap) {
						targetWidth = parseFloat(point);
					}
				});

				if (this.siteCanvasRef.current) {
					this.setCanvasSize({ width: targetWidth });
				}

				this.setState({ updatedWidth: targetWidth }, () => {
					if (this.rulerRef.current) {
						this.rulerRef.current.style.left = `${this.siteCanvasRefOffsetLeft}px`;
					}

					if (this.siteCanvasRefOffsetLeft === 0 && !this.state.isFullScreen) {
						this.setState({ isFullScreen: true });
					} else if (this.siteCanvasRefOffsetLeft !== 0 && this.state.isFullScreen) {
						this.setState({ isFullScreen: false });
					}

					if (onChangingSize) {
						onChangingSize({ width: this.state.updatedWidth });
					}
				});

				if (e.type === 'mouseup') {
					document.body.classList.remove(css.resizing);
					this.setState({ isResizing: false, resizeSide: null }, () => {
						if (this.rulerRef.current) {
							this.rulerRef.current.style.left = `${this.siteCanvasRefOffsetLeft}px`;
						}

						if (this.siteCanvasRefOffsetLeft === 0 && !this.state.isFullScreen) {
							this.setState({ isFullScreen: true });
						} else if (this.siteCanvasRefOffsetLeft !== 0 && this.state.isFullScreen) {
							this.setState({ isFullScreen: false });
						}

						if (onEndChangeSize && this.siteCanvasRef.current) {
							onEndChangeSize(this.siteCanvasRef.current.style.width);
						}
					});

					this.startDragPoint.posX = pbTargetPosX;

					document.removeEventListener('mousemove', this.onDocumentMouseEvent);
					document.removeEventListener('mouseup', this.onDocumentMouseEvent);
				}
				break;

			default:
				break;
		}
	};

	onDragHandlerMouseEvent = e => {
		if (this.state.disabled) return;

		const { onStartChangeSize } = this.props;
		const { side } = e.currentTarget.dataset;
		let pageX;

		switch (e.type) {
			case 'mousedown':
				document.addEventListener('mousemove', this.onDocumentMouseEvent);
				document.addEventListener('mouseup', this.onDocumentMouseEvent);

				pageX = _.get(e, 'pageX');

				document.body.classList.add(css.resizing);
				this.setState(
					{
						isResizing: true,
						resizeSide: side,
						updatedWidth: this.siteCanvasRef.current?.offsetWidth || 0,
						currentWidth: Math.min(this.containerWidth, this.siteCanvasRef.current?.offsetWidth || 0),
					},
					() => {
						if (this.rulerRef.current) {
							this.rulerRef.current.style.left = `${this.siteCanvasRefOffsetLeft}px`;
						}

						if (this.siteCanvasRefOffsetLeft === 0 && !this.state.isFullScreen) {
							this.setState({ isFullScreen: true });
						} else if (this.siteCanvasRefOffsetLeft !== 0 && this.state.isFullScreen) {
							this.setState({ isFullScreen: false });
						}

						this.startDragPoint.posX = pageX;
						if (onStartChangeSize) {
							onStartChangeSize();
						}
					}
				);
				break;

			default:
				break;
		}
	};

	onWindowDragHandlerMouseEvent = e => {
		this.onDragHandlerMouseEvent({
			type: 'mousedown',
			pageX: e.detail.pageX,
			currentTarget: {
				dataset: SIDES.left,
			},
		});
	};

	renderToolTips = () => {
		const { updatedWidth } = this.state;
		const vpWidth = this.containerWidth;
		const devices = devicesConfig.points[updatedWidth];
		const left = vpWidth * 0.5 + updatedWidth * 0.5;

		return (
			<>
				<div className={css.tooltip} style={{ left: `${left}px` }}>
					{`${updatedWidth}px`}
				</div>
				<div className={css.devicesTooltip} style={{ left: `${left}px` }}>
					{_.map(devices, device => (
						<div className={css.device} key={device}>
							{device}
						</div>
					))}
				</div>
			</>
		);
	};

	render() {
		const { children, mediaQuery, className, canvasWrapperClassName } = this.props;
		const { error, isResizing, isFullScreen, boundaries, isScaleEnabled } = this.state;

		return (
			children && (
				<div
					ref={this.containerRef}
					className={cn(css.resizer, className, {
						[css.error]: error,
						[css.disabled]: this.state.disabled,
					})}
					onClick={this.props.onContainerClick}
				>
					<div
						ref={this.siteCanvasRef}
						className={cn(css.siteCanvas, canvasWrapperClassName, {
							[css.hideOverflow]: isScaleEnabled && this.scale < 1,
						})}
					>
						<div
							className={cn(css.dragHandler, css.left)}
							onMouseDown={this.onDragHandlerMouseEvent}
							data-side={SIDES.left}
						/>
						<div
							className={cn(css.dragHandler, css.right)}
							onMouseDown={this.onDragHandlerMouseEvent}
							data-side={SIDES.right}
						/>
						{children({ boundaries, scale: isScaleEnabled ? this.scale : 1 })}
					</div>
					{isResizing && ReactDOM.createPortal(this.renderToolTips(), this.el)}
					{isResizing && (
						<Ruler
							el={this.el}
							currentMediaQuery={this.props.currentMediaQuery}
							mediaQuery={mediaQuery}
							isFullScreen={isFullScreen}
							cbRef={this.rulerRef}
							className={css.ruler}
						/>
					)}
				</div>
			)
		);
	}
}

export function clientFrameStyles(p: Parameters<Props['children']>[0]) {
	return {
		minWidth: p.boundaries.min,
		transformOrigin: 'top left',
		transform: p.scale < 1 ? `scale(${p.scale})` : '',
	};
}
