import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import set from 'lodash/set';

import t from 'utils/translate';
import Text from 'admin/components/common/Text';
import Button from 'admin/components/common/Button';
import { TextField } from 'admin/components/common/Form';
import { Column, Grid } from 'admin/components/common/Grid';
import { Modal, ModalBody, ModalFooter } from 'admin/components/common/Modal';
import { FORM_MODEL, MODAL } from 'admin/constants/common';
import type { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';
import { useAdminDispatch, useAdminSelector } from 'admin/reducers';
import { setModal, auth } from 'admin/actions';
import { selectUserAuth } from 'admin/reducers/user/selectors';

import css from './MobileAppAuthenticatorModal.scss';

const QRCode = React.lazy(() => import('./qrcode'));

type FormValues = {
	code: string;
};
type Props = ModalManagerProvidedProps<MODAL.TWO_FA> & InjectedFormProps<FormValues>;

const MobileAppAuthenticatorModal: React.FC<Props> = props => {
	const { open, close, handleSubmit, submitting, dirty } = props;
	const { totp } = useAdminSelector(selectUserAuth);
	const dispatch = useAdminDispatch();
	const formId = React.useId();
	const title = (
		<Text
			size={Text.size.subtitle}
			weight={Text.weight.semibold}
			compact
			text={t('profile.2fa.modal.mobileApp.title')}
		/>
	);

	const onFormSubmit = async (values: FormValues) => {
		const response = await dispatch(auth.post.totpVerify({ code: values.code }));

		dispatch(auth.post.totpRequirement());

		if (response.success) {
			dispatch(
				setModal({
					id: MODAL.TWO_FA_BACKUP_CODES,
					data: { codes: totp?.totp.recoveryCodes!, title: t('profile.2fa.modal.backupCodes.successTitle') },
				})
			);
		}
	};

	return (
		<Modal title={title} open={open} destroyOnClose width="864px">
			<ModalBody>
				<Grid columns="12" columnGap="large">
					<Column colSpan="7">
						<Text className={css.stepTitle} size="paragraph">
							{t('profile.2fa.modal.mobileApp.step1')}
						</Text>
						<Text className={css.stepTitle} size="paragraph">
							{t('profile.2fa.modal.mobileApp.step2')}
						</Text>
						<div className={css.qrCode}>
							<React.Suspense fallback={null}>
								<QRCode value={totp?.totp.url!} />
							</React.Suspense>
						</div>
						<Text className={css.stepTitle} size="paragraph">
							{t('profile.2fa.modal.mobileApp.step3')}
						</Text>
						<form id={formId} className={css.form} onSubmit={handleSubmit(onFormSubmit)}>
							<Field
								name="code"
								component={TextField}
								autoComplete="off"
								isLabelUppercase={false}
								type="text"
								label={t('profile.2fa.modal.mobileApp.codeLabel')}
								isRequired
							/>
						</form>
					</Column>
					<Column alignItems="flex-end" colSpan="5">
						<div className={css.aside}>
							<Text className={css.title} weight={Text.weight.semibold}>
								{t('profile.2fa.modal.mobileApp.aside.title')}
							</Text>
							<Text className={css.text} size="paragraph">
								{t('profile.2fa.modal.mobileApp.aside.desc')}
							</Text>
							<div className={css.links}>
								<a
									className={css.link}
									// eslint-disable-next-line max-len
									href="https://support.google.com/accounts/answer/1066447?hl=en&co=GENIE.Platform%3DAndroid&oco=0"
									target="_blank"
									rel="noreferrer"
								>
									<Text size="paragraph" tag="span" text="Google Authenticator" />
								</a>
								<a
									className={css.link}
									href="https://www.microsoft.com/en-us/security/mobile-authenticator-app"
									target="_blank"
									rel="noreferrer"
								>
									<Text size="paragraph" tag="span" text="Microsoft Authenticator" />
								</a>
							</div>
						</div>
					</Column>
				</Grid>
			</ModalBody>

			<ModalFooter>
				<Column justifyContent="flex-start">
					<Button form={formId} disabled={!dirty || submitting} view="primary" type="submit">
						{t('profile.2fa.modal.mobileApp.next')}
					</Button>
					<Button disabled={submitting} view="secondary" onClick={close}>
						{t('profile.2fa.modal.mobileApp.cancel')}
					</Button>
				</Column>
			</ModalFooter>
		</Modal>
	);
};

export default reduxForm({
	validate: (values: FormValues, props: any) => {
		const errors: Partial<FormValues> = {};

		if (!values.code) {
			set(errors, 'code', t('profile.2fa.errors.required'));
		}

		return errors;
	},
	form: FORM_MODEL.TWO_FA_CODE,
})(MobileAppAuthenticatorModal);
