/* eslint-disable consistent-return */

import React, { Component, MouseEvent, ChangeEvent } from 'react';
import cn from 'classnames';
import produce from 'immer';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { HandleThunkActionCreator } from 'react-redux';

import { getOrganizationGoogleFonts } from 'admin/actions';
import t from 'utils/translate';
import Text from 'admin/components/common/Text';
import Button from 'admin/components/common/Button';

import { GoogleFontItem, Font } from 'src/types';

import css from './GoogleFonts.scss';

type OwnProps = {
	className?: string;
	onAddFont: (font: Font) => void;
	getFonts: HandleThunkActionCreator<typeof getOrganizationGoogleFonts>;
};

type Props = OwnProps;

type State = {
	selectedFont: GoogleFontItem | undefined;
	selectedFontParams: { [key: string]: string[] };
	value: string;
	googleFontsList: GoogleFontItem[];
};

class GoogleFonts extends Component<Props, State> {
	static defaultProps = {
		className: '',
	};

	state: State = {
		selectedFont: undefined,
		value: '-1',
		selectedFontParams: {},
		googleFontsList: [],
	};

	async componentDidMount() {
		const result = await this.props.getFonts();
		const googleFontsList = result.result?.items;

		if (result.success && googleFontsList) {
			this.setState({ googleFontsList });
		}
	}

	onFontSelect = (e: ChangeEvent<HTMLSelectElement>) => {
		const { googleFontsList: fonts } = this.state;
		const selectedFont = _.find(fonts, { family: e.target.value });

		this.setState({
			selectedFont,
			value: e.target.value,
			// @ts-expect-error ts-migrate FIXME
			selectedFontParams: { variants: [_.head(selectedFont.variants)] },
		});
	};

	onSelectedFontParamClick = (e: MouseEvent<HTMLButtonElement>) => {
		const { type, item = '' } = e.currentTarget.dataset;
		const { selectedFontParams } = this.state;
		const currentParam = selectedFontParams[`${type}`] || [];
		let updatedParam = currentParam;

		if (currentParam.includes(item)) {
			updatedParam = _.filter(currentParam, (cp: string) => cp !== item);
		} else {
			updatedParam = item ? [...currentParam, item] : currentParam;
		}

		this.setState(
			produce((draft: State) => {
				_.set(draft, ['selectedFontParams', `${type}`], updatedParam);
			})
		);
	};

	onAddFontClick = (e: MouseEvent<HTMLButtonElement>) => {
		const { selectedFont, selectedFontParams } = this.state;
		const resultFont: Font = {
			id: nanoid(4),
			fontFamily: selectedFont?.family || '',
			size: 0,
			fontType: 'google',
			fileType: '-',
			weight: 0,
			style: '-',
			...selectedFontParams,
		};

		this.props.onAddFont(resultFont);

		this.setState({ value: '-1', selectedFont: undefined, selectedFontParams: {} });
	};

	renderSelectedFont = () => {
		const { selectedFont, selectedFontParams } = this.state;

		if (!selectedFont) {
			return null;
		}

		return (
			<>
				<div className={css.selected}>
					{_.map(_.get(selectedFont, 'variants', []), (v: string) => (
						<button
							type="button"
							data-item={v}
							data-type="variants"
							onClick={this.onSelectedFontParamClick}
							key={`variant-${v}`}
							className={cn(css.variant, selectedFontParams.variants?.includes(v) && css.active)}
						>
							{_.capitalize(v)}
							<span className={css.indicator} />
						</button>
					))}
				</div>
				<Button
					disabled={_.size(selectedFontParams.variants) === 0}
					className={css.uploadBtn}
					view="secondary"
					smallText
					onClick={this.onAddFontClick}
					theme="dark"
				>
					{t('story.settings.addFont')}
				</Button>
			</>
		);
	};

	render() {
		const { className } = this.props;
		const { googleFontsList: fonts } = this.state;

		return (
			<div className={cn(css.googleFonts, className)}>
				<Text
					tag="div"
					size={Text.size.subheading}
					weight={Text.weight.bold}
					className={css.title}
					text={t('story.settings.googleFonts')}
				/>

				<div className={css.selectWrap}>
					<label htmlFor="google-font-selector">
						<Text size={Text.size.label} weight={Text.weight.bold}>
							{t('story.settings.chooseFont')}
						</Text>
					</label>
					<div className={css.select}>
						<select id="google-font-selector" value={this.state.value} onChange={this.onFontSelect}>
							<option value="-1">{`${t('story.settings.selectGoogleFont')}...`}</option>

							{_.map(fonts, (gf: GoogleFontItem) => (
								<option key={gf.family} value={gf.family}>
									{gf.family}
								</option>
							))}
						</select>
					</div>
				</div>

				{this.renderSelectedFont()}
			</div>
		);
	}
}

export default GoogleFonts;
