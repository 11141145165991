import type { CSSProperties } from 'react';
import type { BBUiConfig } from 'types/story';
import { CSS_PROPS, OTHER_PROPS } from 'common/constants/component-props';
import css from './Box.scss';

export const BOX_TYPE = {
	FRAME: css.FRAME as 'FRAME',
	STACK: css.STACK as 'STACK',
	GRID: css.GRID as 'GRID',
} as const;

export type BoxType = ValuesType<typeof BOX_TYPE>;

export const defaultProps = {
	[OTHER_PROPS.box.boxType]: BOX_TYPE.FRAME,
	[CSS_PROPS.layout.flexWrap]: 'nowrap' as CSSProperties['flexWrap'],
	[CSS_PROPS.layout.alignItems]: 'stretch' as CSSProperties['alignItems'],
	[CSS_PROPS.layout.flexDirection]: 'column' as CSSProperties['flexDirection'],
	[CSS_PROPS.layout.justifyContent]: 'start' as CSSProperties['justifyContent'],
};

export const isHorizontalStack = (fDir: CSSProperties['flexDirection'], boxType: BoxType | undefined) =>
	(boxType ?? defaultProps.boxType) === BOX_TYPE.STACK && (fDir ?? defaultProps.flexDirection) === 'row';

export const isVerticalStack = (fDir: CSSProperties['flexDirection'], boxType: BoxType | undefined) =>
	(boxType ?? defaultProps.boxType) === BOX_TYPE.STACK && (fDir ?? defaultProps.flexDirection) === 'column';

export const isFlexNowrap = (flexWrap: CSSProperties['flexWrap']) => (flexWrap ?? defaultProps.flexWrap) === 'nowrap';

export const isAlignItemsStretch = (a: CSSProperties['alignItems']) => (a ?? defaultProps.alignItems) === 'stretch';

export const parseAlignItems = (a: CSSProperties['alignItems']) => a?.replace('flex-', '');

export const parseJustifyContent = (j: CSSProperties['justifyContent']) => j?.replace('flex-', '');

export const getClassNames = ({ uiConfig }: { uiConfig: BBUiConfig }) => {
	const { componentProps } = uiConfig;
	const customBg = componentProps.styles.backgroundColor || componentProps.styles.backgroundImage;
	const {
		display,
		alignItems = defaultProps.alignItems,
		flexDirection = defaultProps.flexDirection,
		flexWrap = defaultProps.flexWrap,
	} = componentProps.styles ?? {};
	const { boxType = defaultProps.boxType } = componentProps.other ?? {};

	return {
		[css.box]: true,
		[css.customBg]: !!customBg,
		[css.stretchItems]: alignItems === 'stretch',
		[css.vertical]: isVerticalStack(flexDirection, boxType),
		[css.horizontal]: isHorizontalStack(flexDirection, boxType),
		[css.nowrap]: isFlexNowrap(flexWrap),
		/**
		 * In previous versions of the Box component, the display property was set always to grid and by toggling
		 * component visibility through the Layers panel, it was possible to set the display property to grid at
		 * story json. To override this, the display property is set to flex here with the highest priority.
		 * */
		[css.forceFlex]: boxType === BOX_TYPE.STACK && !!display && display !== 'none' && display !== 'flex',
		[css.forceGrid]: boxType !== BOX_TYPE.STACK && !!display && display !== 'none' && display !== 'grid',
	};
};
