import React from 'react';
import cn from 'classnames';
import { get, set } from 'lodash';
import produce from 'immer';

import { CSS_PROPS } from 'common/constants/component-props';
import { useAdminSelector } from 'admin/reducers';
import { DATA_SELECTOR } from 'admin/constants/common';
import { selectEditableCardForm } from 'admin/reducers/card-editor/selectors';
import type { InspectorTypes } from 'admin/components/pages/Story/CardEditor/Inspector/types';
import type { ReduxFieldInputTypes, ReduxFieldMetaTypes } from 'admin/components/common/Form/utils';
import { Errors } from 'admin/components/common/Form/Errors';
import { Icon, IconType } from 'admin/components/common/Icon';
import css from './IconSelect.scss';

const defaultProps = {
	showErrorsOn: 'touched',
};

type OptionType = {
	value: string;
	label?: string | React.ReactNode;
	icon?: IconType;
	isDefault?: boolean;
	// provide to enable toggling of value
	toggleValue?: string;
};

export type IconSelectProps = {
	input: ReduxFieldInputTypes;
	meta: ReduxFieldMetaTypes;
	className?: string;
	showErrorsOn?: string;
	options: OptionType[];
	eventListeners?: Pick<ReturnType<InspectorTypes.GetFieldListeners>, 'onChange' | 'onReset' | 'onClick'>;
	activeIconStyle?: 'fill' | 'color';
} & typeof defaultProps;

const useCurrentValue = (name: ReduxFieldInputTypes['name']) => {
	const formValues = useAdminSelector(selectEditableCardForm);
	const currentValue = get(formValues, name);
	const fieldName = name.substring(name.lastIndexOf('.') + 1);

	if (CSS_PROPS.text.textAlign === fieldName || CSS_PROPS.field.errorStyle.text.textAlign === fieldName) {
		const direction = get(formValues, `${name.substring(0, name.lastIndexOf('.'))}.direction`);
		if (currentValue === 'start') return direction === 'ltr' ? 'left' : 'right';
		if (currentValue === 'end') return direction === 'ltr' ? 'right' : 'left';
	}

	return currentValue;
};

const IconSelect = (props: IconSelectProps) => {
	const {
		className = '',
		input,
		meta,
		showErrorsOn,
		options = [],
		eventListeners,
		activeIconStyle = 'color',
	} = props;
	const id = `${meta.form}.${input.name}`;
	const currentValue = useCurrentValue(input.name);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, option: OptionType) => {
		const newValue = 'toggleValue' in option && option.value === currentValue ? option.toggleValue : option.value;
		eventListeners?.onChange?.({ target: { name: input.name, value: newValue } });
		eventListeners?.onClick?.(
			produce(event, draft => {
				set(draft, 'target.name', input.name);
			})
		);
	};

	return (
		<div className={className}>
			<div className={cn(css.icons)} data-selector={DATA_SELECTOR.ICON_SELECT}>
				{options.map(option => {
					const isActive = currentValue ? currentValue === option.value : option.isDefault;
					return (
						<button
							id={id}
							data-value={option.value}
							data-selector={
								isActive ? DATA_SELECTOR.ICON_SELECT_ACTIVE : DATA_SELECTOR.ICON_SELECT_INACTIVE
							}
							className={cn(css.iconBtn, css[activeIconStyle], { [css.noIcon]: !option.icon })}
							onClick={event => handleClick(event, option)}
							type="button"
							key={option.value}
						>
							{option.icon && <Icon className={cn(css.icon)} type={option.icon} />}
							{option.label && <span className={css.label}>{option.label}</span>}
						</button>
					);
				})}
			</div>
			<Errors show={meta[showErrorsOn]}>{meta.error}</Errors>
		</div>
	);
};

IconSelect.defaultProps = defaultProps;

export default IconSelect;
