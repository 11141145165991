import { useState, useEffect } from 'react';

export const useHover = ref => {
	const [isHover, setIsHover] = useState(false);
	const node = ref.current;

	useEffect(() => {
		if (!node) {
			return;
		}

		const on = () => {
			setIsHover(true);
		};

		const off = () => {
			setIsHover(false);
		};

		const outsideOff = e => {
			if (node && !node.contains(e.target)) {
				setIsHover(false);
			}
		};

		node.addEventListener('mouseenter', on);
		node.addEventListener('mouseleave', off);
		node.addEventListener('mousemove', on);
		node.addEventListener('focus', on);
		node.addEventListener('blur', off);
		document.addEventListener('mousemove', outsideOff);

		// eslint-disable-next-line consistent-return
		return () => {
			node.removeEventListener('mouseenter', on);
			node.removeEventListener('mouseleave', off);
			node.removeEventListener('mousemove', on);
			node.removeEventListener('focus', on);
			node.removeEventListener('blur', off);
			document.removeEventListener('mousemove', outsideOff);
		};
	}, [node]);

	return isHover;
};
