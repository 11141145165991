import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import { videos } from 'admin/resources';
import { useAdminSelector } from 'admin/reducers';
import { PAGINATE_VIDEOS_PAGE_SIZE } from 'admin/constants/common';
import { selectActiveOrganizationId } from 'admin/reducers/user/selectors';

import { toast } from 'admin/components/common/MessageContainer/toast';

import { GetVideosParams, GetVideosResult } from 'src/types';

import { QUERY_KEYS } from '../constants';

export const usePaginateVideos = (params: Omit<GetVideosParams, 'organizationId'>) => {
	const organizationId = useAdminSelector(selectActiveOrganizationId);
	const { size = PAGINATE_VIDEOS_PAGE_SIZE, term } = params;

	const { data, error, hasNextPage, fetchNextPage, isFetchingNextPage, isFetching } = useInfiniteQuery({
		queryKey: [QUERY_KEYS.PAGINATE_VIDEOS, organizationId, term].filter(Boolean),
		queryFn: async ({ pageParam }) => {
			const query = {
				size,
				page: pageParam,
				...(term && { term }),
			};

			const response = await videos.paginate.params({ organizationId }).send(query);

			return response.body as GetVideosResult;
		},
		initialPageParam: 1,
		select: result => {
			const flatArray = result.pages.flatMap(page => page.transcodings);
			const uniqueById = [...new Map(flatArray.map(item => [item.id, item])).values()];

			return {
				...result,
				items: uniqueById,
			};
		},
		getNextPageParam: (lastPage, pages) => (lastPage.transcodings.length < size ? undefined : pages.length + 1),
	});

	useEffect(() => {
		if (error) {
			const errorData = JSON.parse((error as Error).message ?? '{}');
			toast.error(errorData.message || (error as Error).message, 3);
		}
	}, [error]);

	return { data, isFetching, fetchNextPage, error, hasNextPage, isFetchingNextPage };
};
