import React from 'react';
import { useDidMount } from 'common/components/useDidMount';

interface InViewCallback {
	(entries: IntersectionObserverEntry[], observer: IntersectionObserver): boolean;
}

type Props = {
	rootMargin?: string;
	threshold?: number | number[];
	once?: boolean;
	/**
	 * @return Boolean return `isIntersecting` value
	 */
	callback?: InViewCallback;
};

export default function useInView(
	ref: React.MutableRefObject<any>,
	{ rootMargin = '0px', threshold, once, callback }: Props = {}
) {
	const [isIntersecting, setIntersecting] = React.useState(false);

	useDidMount(() => {
		if (!('IntersectionObserver' in window) || !('IntersectionObserverEntry' in window)) {
			setIntersecting(true);
			return;
		}

		const observer = new IntersectionObserver(
			(entries, _observer) => {
				const result = callback
					? /* custom */ callback(entries, _observer)
					: /* default */ entries.some(e => e.isIntersecting);
				setIntersecting(prev => {
					if (once && prev) return prev;
					return result;
				});
			},
			{ rootMargin, threshold }
		);

		if (ref.current) observer.observe(ref.current);

		// eslint-disable-next-line consistent-return
		return () => {
			if (ref.current) observer.unobserve(ref.current);
		};
	});

	return isIntersecting;
}
