import React from 'react';
import cn from 'classnames';
import Text from 'admin/components/common/Text';
import t from 'utils/translate';
import css from './BackButton.scss';

const BackButton = (props: { onClick: () => void; className?: string }) => (
	<button onClick={props.onClick} className={cn(css.backBtn, props.className)} type="button">
		<span />
		<Text tag="p" size={Text.size.paragraph} weight={Text.weight.bold} compact text={t('common.back')} />
	</button>
);

export default BackButton;
