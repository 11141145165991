import { debounce, throttle } from 'lodash';
import { useEffect, useState } from 'react';

function getSize() {
	return {
		innerHeight: window.innerHeight,
		innerWidth: window.innerWidth,
		outerHeight: window.outerHeight,
		outerWidth: window.outerWidth,
	};
}

type Config = {
	debounce?: number;
	throttle?: number;
};

export function useWindowSize(config: Config = {}) {
	const [windowSize, setWindowSize] = useState(getSize());

	useEffect(() => {
		function handleResize() {
			setWindowSize(getSize());
		}

		let listener = handleResize;
		if (config.debounce) listener = debounce(handleResize, config.debounce);
		else if (config.throttle) listener = throttle(handleResize, config.throttle);

		window.addEventListener('resize', listener);
		return () => {
			window.removeEventListener('resize', listener);
		};
	}, [config.debounce, config.throttle]);

	return windowSize;
}
