import { Endpoint, RequestMethod, Resource } from 'common/resources/rabbi-resources';
import { PARAMS } from 'common/constants';
import UrlHelper from 'utils/url-helper';
import { apiRequest } from './interceptors';

const { POST, GET } = RequestMethod;
const { STORY_ID, CARD_ID, ORGANIZATION_ID } = PARAMS;

const backendResource = new Resource(UrlHelper.clientBackend, {
	headers: { Accept: 'application/json' },
	requestInterceptors: [apiRequest],
	responseInterceptors: [],
});

const registerGuest = new Endpoint(backendResource, `/organization/:${ORGANIZATION_ID}/register-guest`, {
	type: POST,
});
const me = new Endpoint(backendResource, `/organization/:${ORGANIZATION_ID}/me`, { type: GET });
const game = new Endpoint(backendResource, `/game/:${STORY_ID}`, { type: GET });
const gameStart = new Endpoint(backendResource, `/game/:${STORY_ID}/start`, { type: POST });
const gameCheckpoint = new Endpoint(backendResource, `/game/:${STORY_ID}/checkpoint/:${CARD_ID}`, { type: POST });
const onSendAnswer = new Endpoint(backendResource, `/story/:${STORY_ID}/answer`, { type: POST });
const onSendSortableAnswer = new Endpoint(backendResource, `/story/:${STORY_ID}/sortable`, { type: POST });
const onSendForm = new Endpoint(backendResource, `/story/:${STORY_ID}/card/:${CARD_ID}/form`, { type: POST });
const onEvent = new Endpoint(backendResource, `/event`, { type: POST });
const startActivity = new Endpoint(backendResource, '/startActivity');
const sendAnswer = new Endpoint(backendResource, '/sendAnswer');
const getCardVotes = new Endpoint(backendResource, `/story/:${STORY_ID}/card/:${CARD_ID}/votes`, { type: GET });
const formRegister = new Endpoint(backendResource, '/formRegister');
const logout = new Endpoint(backendResource, '/auth/logout', { type: POST });
const loginGoogle = new Endpoint(backendResource, '/auth/google', { type: GET });
const getPhoneVerificationCode = new Endpoint(backendResource, '/auth/sms/code', { type: POST });
const verifyPhoneCode = new Endpoint(backendResource, '/auth/sms/verify', { type: POST });
const sendVideoEvent = new Endpoint(backendResource, `/story/:${STORY_ID}/video-event`, { type: POST });

export {
	registerGuest,
	me,
	game,
	gameStart,
	gameCheckpoint,
	onSendAnswer,
	onSendSortableAnswer,
	onEvent,
	startActivity,
	sendAnswer,
	getCardVotes,
	formRegister,
	onSendForm,
	logout,
	loginGoogle,
	getPhoneVerificationCode,
	verifyPhoneCode,
	sendVideoEvent,
};
