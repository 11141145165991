import cn from 'classnames';
import { isObject, map } from 'lodash';
import React, { HTMLAttributes } from 'react';
import { DATA_SELECTOR } from 'admin/constants/common';
import css from './Errors.scss';

const Error: React.FC<HTMLAttributes<HTMLLIElement> & { tag?: React.ElementType | string }> = ({
	children,
	tag: Tag = 'li',
	...props
}) => (
	<Tag className={css.error} {...props}>
		{children}
	</Tag>
);

type ErrorsProps = {
	show: boolean;
	solid?: boolean;
	children?: React.ReactNode;
	className?: string;
};

const Errors: React.FC<ErrorsProps> = ({ children: errors, show, className = '', solid }) =>
	!show || !errors ? null : (
		<ul className={cn(css.errors, className, { [css.solid]: solid })} data-selector={DATA_SELECTOR.FORM_ERROR}>
			{isObject(errors) ? map(errors, error => <Error key={error}>{error}</Error>) : <Error>{errors}</Error>}
		</ul>
	);

const errorsSelector = `[data-selector="${DATA_SELECTOR.FORM_ERROR}}"]`;

export { Errors, Error, errorsSelector };
