import { unpublishPublishedStory as unpublishPublishedStoryEndpoint } from 'admin/resources';
import { UNPUBLISH_PUBLISHED_STORY } from 'admin/constants/actions';
import { StoryFacade } from 'utils/facades/story-facade';
import { createThunkAction } from 'admin/actions/helpers';
import { getStory } from 'admin/actions/story/get-story';

export const unpublishPublishedStory = createThunkAction<void, any>({
	type: UNPUBLISH_PUBLISHED_STORY,
	payloadCreator: async (params, { getState, dispatch }) => {
		const { version, storyEditor } = getState();

		if (!storyEditor.story) return { success: false };

		const Story = new StoryFacade(storyEditor.story, version.current);
		const { storyId } = Story;

		await unpublishPublishedStoryEndpoint.params({ storyId }).send();
		dispatch(getStory({ storyId }));

		return { success: true };
	},
});
