import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import dayjs from 'dayjs';

import { videos } from 'admin/resources';
import { useAdminSelector } from 'admin/reducers';
import { selectActiveOrganizationId } from 'admin/reducers/user/selectors';

import { toast } from 'admin/components/common/MessageContainer/toast';

import { GetVideosUsageParams, GetVideosUsageResult } from 'src/types';

import { QUERY_KEYS } from '../constants';

export const useVideosUsage = (params: Partial<Omit<GetVideosUsageParams, 'organizationId'>>) => {
	const organizationId = useAdminSelector(selectActiveOrganizationId);
	const { from, to } = params;
	const { data, isLoading, isFetching, error } = useQuery({
		queryKey: [QUERY_KEYS.VIDEOS_USAGE, organizationId],
		queryFn: async () => {
			const query = {
				from: from ?? dayjs('1970-01-01').format('YYYY-MM-DD'),
				to: to ?? dayjs().add(1, 'day').format('YYYY-MM-DD'),
			};

			const response = await videos.getUsage.params({ organizationId }).send(query);

			return {
				success: true,
				result: { ...response.body },
			} as IBackendBody<GetVideosUsageResult>;
		},
	});

	useEffect(() => {
		if (error) {
			const errorData = JSON.parse((error as Error).message ?? '{}');
			toast.error(errorData.message || (error as Error).message, 3);
		}
	}, [error]);

	return { data: data?.result, isLoading, isFetching, error };
};
