import React, { useContext } from 'react';
import { Field, ReduxFormContext, change } from 'redux-form';
import { filter, size } from 'lodash';
import cn from 'classnames';

import type { IOrganizationIntegrations } from 'types/organization';
import { prependUrlProtocol } from 'utils/helpers';
import { useAdminDispatch } from 'admin/reducers';
import t from 'utils/translate';
import IntegrationsFacade, {
	CUSTOM_TRACKER_OPTIONS,
	INTEGRATIONS_NAMES,
	INTEGRATIONS_SCOPE,
} from 'utils/facades/integrations-facade';
import { Select, TextField } from 'admin/components/common/Form';
import { Icon } from 'admin/components/common/Icon';
import Button from 'admin/components/common/Button';
import ParamsTable from 'admin/components/common/Intregrations/ParamsTable';

import { arrayToSearchString, stringToArray } from './helpers';

import css from './CustomTracker.scss';

type Props = ({ scope: INTEGRATIONS_SCOPE.STORY; version: string } | { scope: INTEGRATIONS_SCOPE.ORGANIZATION }) & {
	className?: string;
	integrations?: IOrganizationIntegrations;
};

const options = Object.values(CUSTOM_TRACKER_OPTIONS);
const _t = (path: string, opt?: Record<string, string | number>) =>
	t(`common.integrations.views.${INTEGRATIONS_NAMES.CUSTOM_TRACKER}.${path}`, opt);
const prependHttps = (s: string) => {
	const host = s.replace(/^https?:\/\//i, '');
	return host ? prependUrlProtocol(host, 'https') : '';
};

export const CustomTracker: React.FC<Props> = props => {
	const { scope, className } = props;
	const currentCustomTrackerUrl = props.integrations?.customTracker?.customTrackerUrl ?? '';
	const theme = scope === INTEGRATIONS_SCOPE.STORY ? 'dark' : 'light';
	const fields = IntegrationsFacade.getFields('version' in props ? props.version : undefined).customTracker;
	const fieldName = fields.customTrackerUrl.name;

	const dispatch = useAdminDispatch();
	const { form: formName } = useContext(ReduxFormContext);
	const params = React.useMemo(() => {
		const area = document.createElement('textarea');
		area.innerHTML = currentCustomTrackerUrl ?? '';
		const encodedStr = area.innerText;
		return stringToArray(encodedStr);
	}, [currentCustomTrackerUrl]);
	const [paramName, setParamName] = React.useState('');
	const [paramValue, setParamValue] = React.useState<string>('');
	const [error, setError] = React.useState<{ name: string; value: string }>({ name: '', value: '' });

	const onParamNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const val = e.currentTarget.value;

		if (/(\s)/g.test(val)) {
			setError({ ...error, name: _t('fields.paramName.errors.invalid') });
		} else if (params.find(p => p.name === val)) {
			setError({ ...error, name: _t('fields.paramName.errors.alreadyExist') });
		} else if (error.name) {
			setError({ ...error, name: '' });
		}

		setParamName(e.currentTarget.value);
	};

	const onAddParamClick = () => {
		const urlWithoutSearchParams =
			currentCustomTrackerUrl.indexOf('?') >= 0
				? currentCustomTrackerUrl.slice(0, currentCustomTrackerUrl.indexOf('?'))
				: currentCustomTrackerUrl;

		if (error.name === '' || error.value === '') {
			const newParams = [...params, { name: paramName, value: paramValue }];

			dispatch(change(formName, fieldName, `${urlWithoutSearchParams}${arrayToSearchString(newParams)}`));

			setParamValue('');
			setParamName('');
		}
	};

	const onDeleteParamBtnClick = (targetIndex: number) => {
		const urlWithoutSearchParams =
			currentCustomTrackerUrl.indexOf('?') >= 0
				? currentCustomTrackerUrl.slice(0, currentCustomTrackerUrl.indexOf('?'))
				: currentCustomTrackerUrl;
		const newParams = filter(params, (p, index) => index !== targetIndex);

		dispatch(change(formName, fieldName, `${urlWithoutSearchParams}${arrayToSearchString(newParams)}`));
	};

	const formatCustomUrl = () => {
		const endIndex =
			currentCustomTrackerUrl && currentCustomTrackerUrl.indexOf('?') >= 0
				? currentCustomTrackerUrl.indexOf('?')
				: -1;

		return prependHttps(endIndex >= 0 ? currentCustomTrackerUrl.slice(0, endIndex) : currentCustomTrackerUrl);
	};

	const normalizeCustomUrl = (value: any, previousValue: any) => {
		const prevSearchParamsStartIndex = previousValue?.indexOf('?');
		const currSearchParamsStartIndex = value?.indexOf('?');
		// check case when user manually type '?' into input
		const currSearch =
			currSearchParamsStartIndex >= 0 ? value.slice(currSearchParamsStartIndex + 1, size(value)) : '';
		const prevSearch =
			prevSearchParamsStartIndex >= 0
				? previousValue.slice(prevSearchParamsStartIndex + 1, size(previousValue))
				: '';

		if (currSearchParamsStartIndex >= 0) {
			// check case when user manually type '?' into input
			return currSearch === '' ? previousValue : value;
		}

		return value ? `${prependHttps(value)}?${prevSearch}` : '';
	};

	const onChangeParamValue = (value: string) => {
		setParamValue(value);
	};

	const renderParamsTable = () => {
		return (
			<ParamsTable
				title={_t('tableTitle')}
				theme={theme}
				columns={[
					{ label: _t('colNameTitle'), fieldKey: 'name' },
					{ label: _t('colValueTitle'), fieldKey: 'value' },
					{ label: ' ', fieldKey: 'action' },
				]}
				data={params.map((p, i) => ({
					...p,
					action: (
						<Button
							view="empty"
							color="danger-ds"
							shape="circle"
							size="tiny"
							onClick={() => onDeleteParamBtnClick(i)}
						>
							<Icon type="minus-rounded" color="currentColor" />
						</Button>
					),
				}))}
			/>
		);
	};

	const renderInputParam = () => {
		return (
			<div className={css.inputParam}>
				<TextField
					name="name"
					isRequired
					value={paramName}
					isLabelUppercase={false}
					error={error.name}
					theme={theme}
					label={_t('fields.paramName.label')}
					onChange={onParamNameChange}
					autoComplete="off"
				/>
				<Select
					label={{ children: _t('fields.paramValue.label') }}
					options={options}
					value={paramValue}
					placeholder={_t('fields.paramValue.placeholder')}
					isCreatable
					theme={theme}
					eventListeners={{
						onChange: onChangeParamValue,
					}}
				/>

				<Button
					view="empty"
					color="primary"
					shape="circle"
					size="small"
					style={{ marginTop: 34 }}
					disabled={!paramName || !paramValue || error.name !== '' || error.value !== ''}
					onClick={onAddParamClick}
				>
					<Icon type="plus-rounded" color="currentColor" />
				</Button>
			</div>
		);
	};

	return (
		<div className={cn(className)}>
			<div>
				<Field
					name={fieldName}
					autoComplete="off"
					component={TextField}
					label={_t('fields.url.label')}
					isLabelUppercase={false}
					placeholder=""
					format={formatCustomUrl} // view
					theme={theme}
					normalize={normalizeCustomUrl} // store
					isRequired
				/>
			</div>

			<div>
				{renderInputParam()}
				{renderParamsTable()}
			</div>
		</div>
	);
};
