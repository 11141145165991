import React, { createContext } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { CardData, EditableStateInfo, StorySettingsType, StorySymbols } from 'types/story';
import { clientLog } from 'common/utils/helpers';
import { CardFacade } from 'common/utils/facades/card-facade';
import { ITraverseTreeOptionsListeners } from 'client/components/common/BuildingBlocks/utils/traverse-tree-types';
import IframeMessageReceiver from 'client/components/pages/Story/IframeMessageReceiver';
import TreeRenderer from 'client/components/pages/Story/TreeRenderer';
import { setStoryCardData, setCard, setEditableState, setStorySettings, setStorySymbols } from 'client/actions';

export const CardRendererContext = createContext<{ card: CardData; nextDefaultCardId: string } | null>(null);

const log = clientLog.extend('CardRenderer');

const connector = connect(null, { setStoryCardData, setCard, setEditableState, setStorySettings, setStorySymbols });

type State = {
	data?: CardData;
	symbols?: StorySymbols;
};

type Props = ConnectedProps<typeof connector> & {
	isEditor: boolean;
	data: CardData;
	treeOptions:
		| { target: 'default'; isCardTree: true }
		| ({ target: 'editor'; isCardTree: true } & ITraverseTreeOptionsListeners);
	symbols: StorySymbols;
	nextDefaultCardId: string;
};

class CardRenderer extends React.PureComponent<Props, State> {
	static getDerivedStateFromProps(props: Props, state: State) {
		const currentCardId = state.data?._id;
		const nextCardId = props.data._id;
		const isInitialRender = Boolean(!currentCardId && nextCardId);
		const isCardChanged = currentCardId && currentCardId !== nextCardId;

		// Update state from props only in two cases
		// 1. data provided from props 1st time (initial render)
		// 2. navigated to another card
		if (isInitialRender || isCardChanged) {
			props.setCard(props.data);
			return { data: props.data, ...(isInitialRender ? { symbols: props.symbols } : null) };
		}

		return null;
	}

	state: State = {
		/*
		 Card is rendered from state, not from props, because of,
		 there is also an option to update current card data in admin from one of the CardEditor components
		 and show updates in preview. To provide updates only here and to avoid wasted rendering a number of parents,
		 all these updates pushed here to state. (@see updateCardFromEditor)
		*/
		data: undefined,
		symbols: undefined,
	};

	/**
	 * Update card from admin panel, by CardEditor components
	 * @param data? {Object}
	 * @param settings? {Object}
	 * @param symbols? {Object}
	 * @param editableState {Object} editable element state model from admin
	 */
	updateCardFromEditor = ({
		data,
		settings,
		symbols,
		editableState,
	}: {
		data: CardData;
		settings: StorySettingsType;
		symbols: StorySymbols;
		editableState: EditableStateInfo;
	}) => {
		log('updateCardFromEditor', { data, settings, editableState, symbols });

		this.setState(
			prevState => {
				const nextState = {
					data: data || prevState.data || this.props.data,
					symbols: prevState.symbols,
				};

				if (symbols) {
					nextState.symbols = symbols;
				}

				return nextState;
			},
			() => {
				this.props.setEditableState(editableState);

				if (data) {
					this.props.setCard(data);
					this.props.setStoryCardData(data);
				}

				if (symbols) {
					this.props.setStorySymbols(symbols);
				}

				if (settings) {
					this.props.setStorySettings(settings);
				}
			}
		);
	};

	render() {
		const cardData = this.state.data as CardData;
		const card = new CardFacade(cardData);
		const { elements } = card;
		const { isEditor, nextDefaultCardId } = this.props;
		log(`render » type » %c${card.data.type}`, 'font-weight: bold;');

		return (
			<CardRendererContext.Provider value={{ card: cardData, nextDefaultCardId }}>
				<TreeRenderer symbols={this.state.symbols} elements={elements} treeOptions={this.props.treeOptions} />
				{isEditor && <IframeMessageReceiver isCard updateCard={this.updateCardFromEditor} />}
			</CardRendererContext.Provider>
		);
	}
}

export default connector(CardRenderer);
