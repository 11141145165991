import { isBoolean, startsWith, trim, words } from 'lodash';
import { emailRegExp, telE164RegExp } from 'utils/regexp';
import { FieldBBErrorType } from 'client/components/common/BuildingBlocks/Fields/constants';

const minWords = (v: string, min: number) => words(v).length >= min;
const minChars = (v: string, min: number) => v.length >= min;
const noValue = (v: string | undefined) => v === '' || v === undefined;
export const isPhoneNumber = (v: string) =>
	startsWith(v, '+972')
		? /* israel phone number +9720123456789 */ /^(\+972)\d{10}$/.test(v)
		: startsWith(v, '+')
			? /* international format */ telE164RegExp.test(v)
			: /* not international format */ /^\d{9,14}$/.test(v);

// true === valid
export const validations = {
	[FieldBBErrorType.required]: (v: string | boolean) => (isBoolean(v) ? v : trim(v) !== ''),
	[FieldBBErrorType.email]: v => noValue(v) || emailRegExp.test(v),
	[FieldBBErrorType.tel]: v => noValue(v) || isPhoneNumber(v),
	[FieldBBErrorType.fullName]: v => noValue(v) || (minWords(v, 2) && words(v).every(w => minChars(w, 2))),
};
