import React from 'react';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import cn from 'classnames';
import { DatePicker as AntdDatePicker } from 'antd';
import type { DatePickerProps as AntdDatePickerProps } from 'antd';

import type { InspectorTypes } from 'admin/components/pages/Story/CardEditor/Inspector/types';
import type { ReduxFieldInputTypes, ReduxFieldMetaTypes } from 'admin/components/common/Form/utils';
import css from './Date.scss';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(customParseFormat);

export type PFDatePickerProps = {
	input: ReduxFieldInputTypes;
	meta: ReduxFieldMetaTypes;
	defaultValue?: Date;
	className?: string;
	showErrorsOn?: string | boolean;
	eventListeners?: ReturnType<InspectorTypes.GetFieldListeners>;
} & Pick<AntdDatePickerProps, 'minDate' | 'maxDate' | 'mode' | 'format'>;

export const DatePicker: React.FC<PFDatePickerProps> = ({
	className = '',
	showErrorsOn = 'touched',
	input,
	meta,
	eventListeners,
	...props
}) => {
	const format = props.format ?? 'YYYY-MM-DD HH:mmZ';
	const formValue = input.value === '' && props.defaultValue ? props.defaultValue : input.value;
	const currentValue = formValue ? dayjs(formValue) : null;

	return (
		<div className={cn(css.pfDate, className)}>
			<AntdDatePicker
				{...props}
				id={`${meta.form}.${input.name}`}
				showTime
				format={format}
				variant="borderless"
				value={currentValue as any}
				onChange={(date, dateStr) => {
					const dateISOStr = date?.toISOString() ?? '';

					input.onChange(dateISOStr);
					eventListeners?.onChange?.({ target: { value: dateISOStr, name: input.name } });
				}}
			/>
		</div>
	);
};
