/* eslint-disable no-param-reassign */
import { SyntheticEvent } from 'react';
import _ from 'lodash';
import produce from 'immer';

import { adminLog } from 'utils/helpers';
import type { CardData, CardFlowEvent, CardFlowEventType, StoryStep } from 'types/story';
import { CONNECTION_TYPES } from 'common/constants';
import { generateStepId } from 'utils/generate-id';
import flowVars from 'admin/components/pages/Story/Flow/_vars.scss';

import type { CurvePointType, PointName } from './SvgComponents/types';
import { POINT_NAME } from './constants';

import listCss from '../List/List.scss';

const log = adminLog.extend('Flow:ConnectorSvg:utils'); //eslint-disable-line

const MAIN_SCROLL_CONTAINER = '#root';

/**
 * Get coordinates of target from top left position of main scroll container
 */
const getTargetCoordinates = (target: HTMLElement) => {
	const mainScrollContainer = document.querySelector(MAIN_SCROLL_CONTAINER) as HTMLElement;
	// default this is window.pageXOffset &&  window.pageYOffset
	const { scrollLeft, scrollTop } = mainScrollContainer;

	const rect = target.getBoundingClientRect();
	const left = rect.left + scrollLeft;
	const top = rect.top + scrollTop - parseInt(flowVars.STORY_FLOW_OFFSET_TOP, 10);
	return {
		x1: left,
		x2: left + rect.width,
		y1: top,
		y2: top + rect.height,
		cy: top + rect.height / 2,
		cx: left + rect.width / 2,
		boundingClientRect: rect,
	};
};

const checkEventType = ({ type }: SyntheticEvent) => ({
	MOUSEDOWN: type === 'mousedown',
	MOUSEMOVE: type === 'mousemove',
	MOUSEUP: type === 'mouseup',
});

class Connection {
	_id: string;

	type: CardFlowEventType;

	connected: boolean = false;

	p1: CurvePointType;

	p2: CurvePointType;

	constructor(props: { id: string; type?: CardFlowEventType }) {
		this._id = props.id; // p1.pointId
		this.type = props.type || CONNECTION_TYPES.ANY;

		this.p1 = {
			cx: _.get(props, [POINT_NAME.p1, 'cx']),
			cy: _.get(props, [POINT_NAME.p1, 'cy']),
			data: {},
		};
		this.p2 = {
			cx: _.get(props, [POINT_NAME.p2, 'cx']),
			cy: _.get(props, [POINT_NAME.p2, 'cy']),
			data: {},
		};

		this.setConnected(); // is p1 & p2 connected to some point?
	}

	setConnected(value = false) {
		const {
			data: { pointId: p1 },
		} = this[POINT_NAME.p1];
		const {
			data: { pointId: p2 },
		} = this[POINT_NAME.p2];

		this.connected = p1 && p2 ? true : value;
	}

	setPoint(pointName: PointName, value: Partial<CurvePointType>) {
		this[pointName] = { ...this[pointName], ...value };
		this.setConnected();
	}

	updatePointCoordinates(pointName: PointName) {
		const pointToUpdate = this[pointName];
		const { cx, cy } = getTargetCoordinates(pointToUpdate.data.node!);

		_.set(this, `${pointName}.cx`, cx);
		_.set(this, `${pointName}.cy`, cy);
	}

	createPoint({ pointId, pointName }: { pointId: string; pointName: PointName }) {
		const pointNode = document.querySelector<HTMLElement>(`.${listCss.point}[data-point-id="${pointId}"]`);

		if (!pointNode) {
			throw new Error(`Point "{ name: ${pointName}, id: ${pointId} }" does not exists in DOM`);
		}

		const pointCoords = getTargetCoordinates(pointNode);

		this.setPoint(pointName, {
			cx: pointCoords.cx,
			cy: pointCoords.cy,
			data: {
				...pointNode.dataset,
				node: pointNode,
			},
		});
	}
}

const getPointsByStep = <T extends Record<any, any>>({ points, stepId }: { points: T; stepId: string }) => {
	return _.filter(points, point => point.stepId === stepId);
};

const getConnectionsByStepId = ({
	connections,
	stepId,
}: {
	connections: Record<string, Connection>;
	stepId: string;
}) => {
	const connectionsByStepId: { connection: Connection; name: PointName }[] = [];

	_.forEach(connections, connection => {
		const { [POINT_NAME.p1]: P1, [POINT_NAME.p2]: P2 } = connection;

		if (_.get(P1, 'data.stepId') === stepId) {
			connectionsByStepId.push({ connection, name: POINT_NAME.p1 });
		}
		if (_.get(P2, 'data.stepId') === stepId) {
			connectionsByStepId.push({ connection, name: POINT_NAME.p2 });
		}
	});

	return connectionsByStepId;
};

const updateConnectionIn = (
	{ connection, name: pointName }: { connection: Connection; name: PointName },
	destination: Record<string, Connection>
) => {
	// update connection
	connection.updatePointCoordinates(pointName);
	// update next connection with an updated connection
	_.set(destination, connection._id, connection);
};

const updatePointIn = (
	point: ReturnType<typeof getPointsBySelector>[string],
	destination: ReturnType<typeof getPointsBySelector>
) => {
	const updatedCoordinates = getTargetCoordinates(point.node);
	const destinationPoint = _.find(destination, _point => _point.pointId === point.pointId);
	_.assign(destinationPoint, updatedCoordinates);
};

/**
 * Check is "point" in visible "step" area
 */
const isListPointInView = (point: CurvePointType) => {
	const stepId = _.get(point, ['data', 'stepId']);
	if (!stepId) {
		return true;
	}

	const list = document.getElementById(stepId) as HTMLElement;
	const { top, bottom } = list.getBoundingClientRect();
	const pointTop = point.cy; // OR -> point.data.node.getBoundingClientRect().top;
	const listTop = top - parseInt(flowVars.STORY_FLOW_OFFSET_TOP, 10);

	return pointTop > listTop && pointTop < bottom;
};

/**
 * Calculating values to decide how should look a connection, according to "is his p1 || p2 in view"
 */
const isConnectionInView = (connection: Connection) => {
	let isStartPointInView = true;
	let isEndPointInView = true;

	if (connection.connected) {
		isStartPointInView = isListPointInView(connection[POINT_NAME.p1]);
		isEndPointInView = isListPointInView(connection[POINT_NAME.p2]);
	}

	return {
		isStartPointInView,
		isEndPointInView,
	};
};

/**
 * @param connection {Connection} A Connection instance
 * @param isStartPointInView {Boolean}
 * @param isEndPointInView {Boolean}
 * @param curveStyle {Object} style object to **mutate**
 */
const updateConnectionOutOfView = ({
	connection,
	isStartPointInView,
	isEndPointInView,
	curveStyle,
}: {
	connection: Connection;
	isStartPointInView: boolean;
	isEndPointInView: boolean;
	curveStyle: Partial<CSSStyleDeclaration>;
}) => {
	const showStart = isStartPointInView && !isEndPointInView;
	const showEnd = isEndPointInView && !isStartPointInView;

	const startStepId = connection[POINT_NAME.p1].data.stepId;
	const endStepId = connection[POINT_NAME.p2].data.stepId;

	if (!startStepId || !endStepId) return;

	const startStep = document.getElementById(startStepId) as HTMLElement;
	const endStep = document.getElementById(endStepId) as HTMLElement;
	const MAGIC = 0;

	if (showStart) {
		// log('updateConnectionOutOfView -> showStart');
		const { offsetTop: top, offsetLeft: left } = endStep;
		const bottom = top + endStep.offsetHeight;

		const isEndUp = connection[POINT_NAME.p1].cy > connection[POINT_NAME.p2].cy;
		if (isEndUp) {
			// case #1: show only START and end is UP
			// Y = step.top | X = p2.step.left
			connection[POINT_NAME.p2].cy = top - MAGIC;
			connection[POINT_NAME.p2].cx = left;
		} else {
			// case #2: show only START and end is DOWN
			// Y = step.bottom | X = p2.step.left
			connection[POINT_NAME.p2].cy = bottom + MAGIC;
			connection[POINT_NAME.p2].cx = left;
		}
		curveStyle.stroke = 'url("#fade-to-end")';
	}

	if (showEnd) {
		// log('updateConnectionOutOfView -> showEnd');
		const { offsetTop: top, offsetLeft: left } = startStep;
		const bottom = top + startStep.offsetHeight;
		const right = left + startStep.offsetWidth;

		const isStartUp = connection[POINT_NAME.p1].cy < connection[POINT_NAME.p2].cy;
		if (isStartUp) {
			// case #3: show only END and start is UP
			// Y = step.top | X = p1.step.right
			connection[POINT_NAME.p1].cy = top - MAGIC;
			connection[POINT_NAME.p1].cx = right;
		} else {
			// case #4: show only END and start is DOWN
			// Y = step.bottom | X = p1.step.right
			connection[POINT_NAME.p1].cy = bottom - MAGIC;
			connection[POINT_NAME.p1].cx = right;
		}
		curveStyle.stroke = 'url("#fade-to-start")';
	}
};

const selectors = {
	STEP: `.${listCss.step}`, // draggable
	STEP_INNER: `.${listCss.stepInner}`, // scrollable
	POINT: `.${listCss.point}`,
	START_POINT: `.${listCss.pointStart}`,
	END_POINT: `.${listCss.pointEnd}`,
	CARD: `.${listCss.card}`,
};

/**
 * Get point nodes by selector in some node
 */
function getPointsBySelector(listNode: HTMLElement, pointSelector: string) {
	type NodeDataset = { pointId: string; stepId: string; cardId?: string };

	const result: Record<string, { node: HTMLElement } & NodeDataset & ReturnType<typeof getTargetCoordinates>> = {};
	const pointNodes = listNode.querySelectorAll<HTMLElement>(pointSelector);

	_.forEach(pointNodes, (node, i) => {
		result[i] = {
			...getTargetCoordinates(node),
			...(node.dataset as NodeDataset),
			node,
		};
	});

	return result;
}

const mapStepsEvents = (
	steps: StoryStep[],
	cb: (p: {
		step: StoryStep;
		stepIndex: number;
		card: CardData;
		cardIndex: number;
		event: CardFlowEvent;
		eventIndex: number;
	}) => void
) => {
	_.forEach(steps, (step, stepIndex) => {
		_.forEach(step.cards, (card, cardIndex) => {
			_.forEach(card.events, (event, eventIndex) => {
				cb({
					step,
					stepIndex,
					card,
					cardIndex,
					event,
					eventIndex,
				});
			});
		});
	});
};

/**
 * @param value transform(Npx, Npx);
 */
const cssTransformLockY = (value?: string) => {
	if (!value) {
		return value;
	}
	return value.split(',')[0].concat(', 0px)');
};

const createStep = (): StoryStep => {
	return {
		_id: generateStepId(),
		cards: [],
	};
};

const getDataWithAdditionalEmptyStep = (data: StoryStep[]) => {
	const lastStepIndex = _.size(data);
	const lastStep = data[lastStepIndex - 1];
	const lastStepsCardsLength = _.size(_.get(lastStep, 'cards'));
	if (lastStepsCardsLength || _.isEmpty(data)) {
		// create additional empty working step
		return produce(data, draft => {
			draft.push(createStep());
		});
	}
	return data;
};

export {
	getTargetCoordinates,
	checkEventType,
	Connection,
	getConnectionsByStepId,
	updateConnectionIn,
	getPointsByStep,
	updatePointIn,
	isListPointInView,
	isConnectionInView,
	updateConnectionOutOfView,
	MAIN_SCROLL_CONTAINER,
	selectors,
	getPointsBySelector,
	mapStepsEvents,
	cssTransformLockY,
	createStep,
	getDataWithAdditionalEmptyStep,
};
