import { get, lowerCase, words, capitalize } from 'lodash';
import cn from 'classnames';
import { BBStates } from 'types/story';
import React, { useCallback, useMemo } from 'react';
import { isLayerType } from 'common/utils/blocks/is-layer-type';
import {
	CARD_TYPE,
	COMPONENT_STATES,
	COMPONENT_STATES_LIST,
	COMPONENT_TYPE,
	DEFAULT_EDITABLE_STATE_INFO,
} from 'common/constants';
import { useAdminSelector, useAdminDispatch } from 'admin/reducers';
import { Icon } from 'admin/components/common/Icon';
import {
	selectEditableCard,
	selectEditableStateInfo,
	selectEditableStoryElements,
	selectEditableStorySettings,
} from 'admin/reducers/card-editor/selectors';
import { selectEditableElement } from 'admin/reducers/card-editor-extra/selectors';
import Text from 'admin/components/common/Text';
import { CSSTransition } from 'admin/components/common/Transition';
import CardEditorIframeTransmitter from 'admin/components/pages/Story/CardEditor/IframeTransmitter';
import { setEditableState } from 'admin/actions';
import css from './StateSettings.scss';

export const getStateLabel = (value: string) => lowerCase(words(value.replace('State', '')).join(' '));

type OptionProps = {
	option: { label?: string; value?: string };
	state: string;
	onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const Option = ({ option, state, onClick }: OptionProps) => (
	<button className={cn(css.btn)} type="button" data-value={option.value} onClick={onClick}>
		<CSSTransition timeout={{ enter: 100, exit: 0 }} in={state === option.value}>
			<Icon type="sc-check" width={14} className={css.btnIcon} />
		</CSSTransition>
		{capitalize(option.label)}
	</button>
);

const StateSettings: React.FC = () => {
	const dispatch = useAdminDispatch();
	const card = useAdminSelector(selectEditableCard)!;
	const element = useAdminSelector(selectEditableElement);
	const storyElements = useAdminSelector(selectEditableStoryElements);
	const storySettings = useAdminSelector(selectEditableStorySettings);
	const editableState = useAdminSelector(selectEditableStateInfo);
	const showCorrect = storySettings.cards?.[card._id]?.showCorrect;

	const stateOptions = useMemo(() => {
		const { SELECTED, SELECTED_HOVER, CORRECT, INCORRECT } = COMPONENT_STATES;
		const isSingleDefault = states => states.length === 1 && states[0] === COMPONENT_STATES.DEFAULT;
		const availableStates = get(editableState, 'source.states') || COMPONENT_STATES_LIST[element.type] || [];
		const isAnswer = [editableState.source.type, element.type].includes(COMPONENT_TYPE.ANSWER);

		const states = availableStates.filter(s => {
			switch (card.type) {
				case CARD_TYPE.TRIVIA:
				case CARD_TYPE.TRUE_OR_FALSE:
					if (isAnswer) {
						return showCorrect ? s !== SELECTED && s !== SELECTED_HOVER : s !== CORRECT && s !== INCORRECT;
					}
					return true;
				case CARD_TYPE.POLL:
				case CARD_TYPE.THIS_OR_THAT:
				case CARD_TYPE.PERSONALITY_TEST:
					return isAnswer ? s !== CORRECT && s !== INCORRECT : true;
				case CARD_TYPE.SORTABLE_TRIVIA:
					return s !== SELECTED;
				case CARD_TYPE.SORTABLE_POLL:
					return isAnswer ? s === COMPONENT_STATES.DEFAULT || s === COMPONENT_STATES.HOVER : true;
				default:
					return true;
			}
		});

		if (!states.length || isSingleDefault(states)) {
			return false;
		}

		return states.map(value => ({
			value,
			label: getStateLabel(value),
		}));
	}, [editableState, showCorrect, card.type, element.type]);

	const handleUpdateState = useCallback(
		async (state: BBStates) => {
			const nextEditableState =
				state === COMPONENT_STATES.DEFAULT
					? DEFAULT_EDITABLE_STATE_INFO
					: {
							state,
							source: editableState.source.id
								? {
										id: editableState.source.id,
										path: editableState.source.path,
										type: editableState.source.type,
										layer: editableState.source.layer,
										states: editableState.source.states,
									}
								: {
										id: element.uiConfig.nodeProps.id,
										path: element.path,
										type: element.type,
										layer: element.uiConfig.layer,
										states: COMPONENT_STATES_LIST[element.type],
									},
						};

			// update state reducer
			dispatch(setEditableState({ state: nextEditableState.state, ...nextEditableState.source }));

			// transmit story elements with a new state
			if (isLayerType({ uiConfig: { layer: nextEditableState.source.layer } }).global) {
				CardEditorIframeTransmitter.transmitStoryElementsToClient({
					editableState: nextEditableState,
					elements: storyElements,
				});

				return;
			}
			// transmit card elements with a new state
			CardEditorIframeTransmitter.transmitCardDataToClient({ editableState: nextEditableState, data: card });
		},
		[
			editableState,
			storyElements,
			dispatch,
			card,
			element.type,
			element.path,
			element.uiConfig.layer,
			element.uiConfig.nodeProps.id,
		]
	);

	const onOptionClick = useCallback(
		(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
			handleUpdateState(event.currentTarget.dataset.value as BBStates),
		[handleUpdateState]
	);

	if (!stateOptions) {
		const { state, source } = editableState;
		if (state && source.id) {
			return <>{getStateLabel(state)}</>;
		}

		return null;
	}

	return (
		<div className={css.states}>
			{stateOptions.map(option => (
				<Option
					key={`state-option-${option.value}`}
					option={option}
					state={editableState.state}
					onClick={onOptionClick}
				/>
			))}
			<Text size="footnote" className={css.note}>
				After choosing a block&apos;s state, you can customize how the blocks inside it look for that state.
			</Text>
		</div>
	);
};

export default StateSettings;
