/* eslint-disable react/no-danger */
import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';

import t from 'utils/translate';
import Button from 'admin/components/common/Button';
import Text from 'admin/components/common/Text';
import { isMatching, QUERY } from 'admin/components/common/Responsive';
import LoginLayout from 'admin/components/common/LoginLayout';
import { useAdminSelector } from 'admin/reducers';
import { selectIsTwoFASetupAwaiting, selectUserName } from 'admin/reducers/user/selectors';
import { routes } from 'admin/constants';
import css from './TwoFAEnforcement.scss';

type Props = RouteComponentProps<{}, {}, { from?: string }>;

const TwoFAEnforcement = (props: Props) => {
	const { location, history } = props;
	const name = useAdminSelector(selectUserName);
	const isTwoFASetupAwaiting = useAdminSelector(selectIsTwoFASetupAwaiting);
	const isNoDesktop = isMatching(QUERY.NO_DESKTOP);

	const handleNextBtnClick = async () => {
		history.push(routes.PROFILE_2FA_PAGE);
	};

	React.useEffect(() => {
		if (!isTwoFASetupAwaiting) {
			history.push(location.state?.from || routes.ROOT);
		}
	}, [isTwoFASetupAwaiting, history, location]);

	return (
		<LoginLayout>
			<div className={css.hero}>
				<Text
					size={isNoDesktop ? Text.size.subheading : Text.size.heading}
					tag="h1"
					compact
					className={css.heroTitle}
				>
					{t('2faEnforcement.title', { name })}
				</Text>
			</div>
			<Text size={Text.size.body} className={css.text}>
				{t('2faEnforcement.text_1')}
			</Text>
			<Text size={Text.size.body} className={css.text}>
				{t('2faEnforcement.text_2')}
			</Text>
			<Button onClick={handleNextBtnClick}>{t('2faEnforcement.next')}</Button>
		</LoginLayout>
	);
};

export default TwoFAEnforcement;
