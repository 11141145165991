// app dependencies
import 'admin/styles/main.scss';

// app
import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/en';
import { IS_DEVEL } from 'utils/environment';
import Root from 'admin/Root';

// Extend Dayjs with the ISO week plugin
dayjs.extend(isoWeek);
// Enable the relativeTime plugin
dayjs.extend(relativeTime);
// Set the locale to English
dayjs.locale('en');

if (!IS_DEVEL) {
	Sentry.init({
		dsn: 'https://e6ced77cc723478fad969f5f3ba00b06@o969560.ingest.sentry.io/5920728',
		integrations: [
			new Sentry.BrowserTracing(),
			new Sentry.Replay({
				mutationBreadcrumbLimit: 750,
				mutationLimit: 1500,
				maskAllText: false,
				maskAllInputs: true,
			}),
		],
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: process.env.NODE_ENV === 'testing' ? 1 : 0.75,
		replaysSessionSampleRate: 0,
		replaysOnErrorSampleRate: 1.0,
		release: `story-cards@${process.env.VERSION}`,
		environment: process.env.NODE_ENV,
		denyUrls: [
			// Browser extensions
			/extensions\//i,
			/^chrome:\/\//i,
			/extension:/i,
		],
	});
}

if (process.env.DEBUG !== undefined) {
	localStorage.setItem('debug', process.env.DEBUG);
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
	// <React.StrictMode>
	<Root />
	// </React.StrictMode>
);
