import { SET_MODAL } from 'admin/constants/actions';
import { MODAL } from 'admin/constants/common';
import { ModalReducerState } from 'admin/reducers/modal-reducer';

type Payload = ModalReducerState<MODAL>;

export function setModal(payload: Payload = { id: MODAL.NONE, data: {}, autofocus: true }) {
	return {
		type: SET_MODAL,
		payload: { id: payload.id, data: payload.data ?? {}, autofocus: payload.autofocus ?? true },
	};
}
