import { WrappedFieldMetaProps, WrappedFieldInputProps } from 'redux-form';

import { INPUT_MASK } from 'admin/constants/common';

export type ReduxFieldMetaTypes = WrappedFieldMetaProps;

export type ReduxFieldInputTypes = WrappedFieldInputProps;

export type ShowErrorsOn =
	| Exclude<keyof ReduxFieldMetaTypes, 'dispatch' | 'error' | 'initial' | 'warning' | 'form'>
	| ((meta: ReduxFieldMetaTypes, input: ReduxFieldInputTypes) => boolean);

export const limitValue = (value: string, max: number) => `${value ?? ''}`.substring(0, max);

export const maskValue = (val: string, type: string) => {
	let result: string[] = [];
	let unmaskedVal: string = '';
	let cardType: string = 'unknown';

	switch (type) {
		case INPUT_MASK.CARD: {
			cardType = getCreditCardType(val);
			unmaskedVal = unmaskValue(val, type);

			if (unmaskedVal.match(getCardLengthRegExp(cardType))) {
				result = getCardMask(cardType, unmaskedVal);
			} else {
				result = getCardMask(cardType, unmaskedVal.slice(0, -1));
			}
			break;
		}

		case INPUT_MASK.EXP_DATE: {
			unmaskedVal = unmaskValue(val, type);
			result = getExpDateMask(unmaskedVal);
			break;
		}

		case INPUT_MASK.NUMBER: {
			result = [unmaskValue(val, type)];
			break;
		}

		case INPUT_MASK.WS_NAME: {
			result = [unmaskValue(val, type)];
			break;
		}

		default:
			break;
	}

	return result.join('');
};

export const getCreditCardType = (creditCardNumber: string) => {
	// start without knowing the credit card type
	let result = 'unknown';

	// first check for MasterCard
	if (/^5[1-5]/.test(creditCardNumber)) {
		result = 'mastercard';
	}
	// then check for Visa
	else if (/^4/.test(creditCardNumber)) {
		result = 'visa';
	}
	// then check for AmEx
	else if (/^3[47]/.test(creditCardNumber)) {
		result = 'amex';
	}
	// then check for Diners
	else if (/3(?:0[0-5]|[68][0-9])[0-9]{11}/.test(creditCardNumber)) {
		result = 'diners';
	}
	// then check for Discover
	else if (/6(?:011|5[0-9]{2})[0-9]{12}/.test(creditCardNumber)) {
		result = 'discover';
	}

	return result;
};

const getCardMask = (type: string, val: string) => {
	const result: string[] = [];
	const spaceIndex1 = 4;
	const spaceIndex2 = 10;

	for (let i = 0; i < val.length; i += 1) {
		if (type === 'amex' || type === 'diners') {
			if (i === spaceIndex1 || i === spaceIndex2) {
				result.push('-');
			}
			result.push(val[i]);
		} else {
			if (i > 0 && i % 4 === 0) {
				result.push('-');
			}
			result.push(val[i]);
		}
	}

	return result;
};

const getExpDateMask = (val: string) => {
	const result: string[] = [];

	for (let i = 0; i < val.length; i += 1) {
		if (i > 0 && i % 2 === 0) {
			result.push('/');
		}
		result.push(val[i]);
	}

	return result;
};

export const unmaskValue = (val: string, type: (typeof INPUT_MASK)[keyof typeof INPUT_MASK]) => {
	switch (type) {
		case 'card':
			return val.replace(new RegExp(/(-|\s)/, 'g'), '');

		case 'exp-date':
			return val.replace(new RegExp(/(\/|\D)/, 'g'), '');

		case 'number':
			return val.replace(new RegExp(/(\D)/, 'g'), '');

		case 'ws-name':
			return val.replace(new RegExp(/[^a-zA-Z- \d]/, 'g'), '');

		default:
			return val;
	}
};

export const getCardLengthRegExp = (type: string, isExactDigitsNumber: boolean = false) => {
	switch (type) {
		case 'amex':
			return isExactDigitsNumber ? new RegExp(/^\d{15}$/g) : new RegExp(/^\d{0,15}$/g);
		case 'diners':
			return isExactDigitsNumber ? new RegExp(/^\d{14}$/g) : new RegExp(/^\d{0,14}$/g);
		default:
			return isExactDigitsNumber ? new RegExp(/^\d{16}$/g) : new RegExp(/^\d{0,16}$/g);
	}
};

interface GetErrorDataProps {
	input?: ReduxFieldInputTypes;
	meta?: ReduxFieldMetaTypes;
	error?: string;
	showErrorsOn?: ShowErrorsOn;
}

export function getErrorData(props: GetErrorDataProps) {
	const message = props.meta ? props.meta.error : props.error;
	const hasMessage = !!message;
	return {
		message,
		show:
			props.meta && props.input && props.showErrorsOn && hasMessage
				? typeof props.showErrorsOn === 'function'
					? props.showErrorsOn(props.meta, props.input)
					: !!props.meta[props.showErrorsOn]
				: hasMessage,
	};
}
