import React from 'react';
import { Field } from 'redux-form';
import { CardFacade } from 'common/utils/facades/card-facade';
import { TextField } from 'admin/components/common/Form';
import { SEO_TEXT_MAX_LEN } from 'admin/components/common/Form/validations';
import { names } from './utils';
import css from './CardSettingsModal.scss';

const fieldProps = {
	className: css.field,
	type: 'text',
	component: TextField,
	theme: 'dark',
	limit: { max: SEO_TEXT_MAX_LEN },
	isRequired: true,
	isLabelUppercase: false,
};

const SeoView = ({ card: cardFacade }: { card: CardFacade }) => (
	<>
		<Field {...fieldProps} name={names.seoTitle(cardFacade.cardId)} label="Meta title" />
		<Field {...fieldProps} name={names.seoDescription(cardFacade.cardId)} label="Meta description" />
		<Field {...fieldProps} name={names.seoKeywords(cardFacade.cardId)} label="Meta keywords" />
	</>
);

export default SeoView;
