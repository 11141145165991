import React, { useContext, useState, useMemo } from 'react';
import cn from 'classnames';
import { sleep } from 'utils/helpers';
import { MODAL } from 'admin/constants/common';
import Button from 'admin/components/common/Button';
import { Icon } from 'admin/components/common/Icon';
import { getClientFrameNode } from 'utils/iframe-tunnel';
import { ModalBody } from 'admin/components/common/Modal';
import { CSS_PROPS } from 'common/constants/component-props';
import { generateImage } from 'admin/actions/story/ai/generate-image';
import { useAdminDispatch, useAdminSelector } from 'admin/reducers';
import { TextAreaPure } from 'admin/components/common/Form/TextArea';
import { Select } from 'admin/components/common/Form';
import { AIImageStyle, AIImageSize, AIImageQuality } from 'admin/actions/story/ai/types';
import { Context as ThemeContext } from 'admin/components/common/Theme';
import { getPFCompPropName } from 'admin/components/pages/Story/CardEditor/Inspector/PropField';
import { selectCurrentPlatform, selectCurrentState } from 'admin/reducers/card-editor/selectors';
import type { InspectorContextType } from 'admin/components/pages/Story/CardEditor/Inspector/InspectorContext';
import { selectEditableElementPath, selectEditableElementNodeId } from 'admin/reducers/card-editor-extra/selectors';
import type { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';
import PromptHistory from './PromptHistory';
import css from '../EditorAIModal.scss';

export type GeneratorImageModalData = {
	view: 'image';
	onImageChange: ReturnType<InspectorContextType['getFieldListeners']>['onUpload'];
};

const text = {
	submitBtn: 'Generate',
	promptPh: 'Generate an artwork of a medieval castle surrounded by autumn-colored trees.',
	imageStyle: 'Image style',
};

const styleOptions = [
	{ value: AIImageStyle.realistic, label: 'Realistic' },
	{ value: AIImageStyle.conceptArt, label: 'Concept art' },
	{ value: AIImageStyle.mockArt, label: 'Mock art' },
	{ value: AIImageStyle.gothicVictorian, label: 'Gothic victorian' },
	{ value: AIImageStyle.pixelArt, label: 'Pixel art' },
	{ value: AIImageStyle.image2d, label: '2D' },
	{ value: AIImageStyle.image3d, label: '3D' },
	{ value: AIImageStyle.markerDrawing, label: 'Marker drawing' },
	{ value: AIImageStyle.pencilDrawing, label: 'Pencil drawing' },
	{ value: AIImageStyle.oilPainting, label: 'Oil painting' },
	{ value: AIImageStyle.watercolorPainting, label: 'Watercolor painting' },
	{ value: AIImageStyle.acrylicPainting, label: 'Acrylic painting' },
	{ value: AIImageStyle.surrealism, label: 'Surrealism' },
	{ value: AIImageStyle.impressionism, label: 'Impressionism' },
	{ value: AIImageStyle.pastel, label: 'Pastel' },
	{ value: AIImageStyle.digitalPainting, label: 'Digital painting' },
	{ value: AIImageStyle.abstractArt, label: 'Abstract art' },
	{ value: AIImageStyle.panelPainting, label: 'Panel painting' },
	{ value: AIImageStyle.glassPainting, label: 'Glass painting' },
	{ value: AIImageStyle.cubism, label: 'Cubism' },
	{ value: AIImageStyle.disney, label: 'Disney' },
	{ value: AIImageStyle.pixar, label: 'Pixar' },
	{ value: AIImageStyle.anime, label: 'Anime' },
	{ value: AIImageStyle.macroLens, label: 'Macro lens' },
	{ value: AIImageStyle.fishEyeLens, label: 'Fish eye lens' },
	{ value: AIImageStyle.birdsEyePerspective, label: 'Birds eye perspective' },
	{ value: AIImageStyle.thirdPersonPerspective, label: 'Third person perspective' },
];

function determineSize(width: number, height: number, tolerance: number = 0.15): AIImageSize {
	const ratio = width / height;
	const lowerBound = 1 - tolerance; // Lower bound for square consideration
	const upperBound = 1 + tolerance; // Upper bound for square consideration

	if (ratio >= lowerBound && ratio <= upperBound) {
		return AIImageSize.square;
	}
	if (width > height) {
		return AIImageSize.landscape;
	}

	return AIImageSize.portrait;
}

function determineQuality(width: number, height: number): AIImageQuality {
	return width > 1000 || height > 1000 ? AIImageQuality.hd : AIImageQuality.standard;
}

type Props = ModalManagerProvidedProps<MODAL.EDITOR_AI> & {
	setIsBusy: React.Dispatch<React.SetStateAction<boolean>>;
};

const GenerateImage: React.FC<Props> = props => {
	const dispatch = useAdminDispatch();
	const id = useAdminSelector(selectEditableElementNodeId);
	const path = useAdminSelector(selectEditableElementPath);
	const currentState = useAdminSelector(selectCurrentState);
	const currentMediaQuery = useAdminSelector(selectCurrentPlatform);
	const { theme } = useContext(ThemeContext);
	const [userPrompt, setUserPrompt] = useState('');
	const [styleType, setStyleType] = useState<AIImageStyle>(AIImageStyle.realistic);

	const formatParams = useMemo(() => {
		const params = {
			quality: AIImageQuality.standard,
			size: AIImageSize.square,
		};

		const element = getClientFrameNode(id);

		if (element) {
			const { offsetWidth, offsetHeight } = element;
			params.quality = determineQuality(offsetWidth, offsetHeight);
			params.size = determineSize(offsetWidth, offsetHeight);
		}

		return params;
	}, [id]);

	const onSubmit = async () => {
		props.setIsBusy(true);

		const generatedImage = await dispatch(generateImage({ prompt: userPrompt, style: styleType, ...formatParams }));

		if (generatedImage.success && generatedImage.result && 'onImageChange' in props.data) {
			await props.data.onImageChange?.({
				asset: generatedImage.result,
				name: getPFCompPropName(
					CSS_PROPS.background.backgroundImage,
					'styles'
				)({ path, currentMediaQuery, currentState }),
			});
			await sleep(1000);
		}

		props.close();
	};

	return (
		<div>
			<ModalBody>
				<div className={cn(css.fields, css.view1)}>
					<TextAreaPure
						placeholder={text.promptPh}
						rows={3}
						theme={theme}
						autoFocus
						value={userPrompt}
						onChange={e => setUserPrompt(e.target.value)}
					/>
					<div className={css.fieldsFooter}>
						<Select
							className={css.selectStyle}
							label={{ children: text.imageStyle }}
							options={styleOptions}
							value={styleType}
							inline
							theme={theme}
							eventListeners={{
								onChange: setStyleType,
							}}
							menuPlacement="top"
							maxMenuHeight={200}
						/>
						<Button
							className={css.btnSubmit}
							onClick={onSubmit}
							disabled={userPrompt.trim().length < 2}
							view="primary"
							theme={theme}
						>
							<Icon type="sc-magic" width={26} style={{ marginRight: 6 }} />
							{text.submitBtn}
						</Button>
					</div>
				</div>
				<PromptHistory type="image" onSelect={setUserPrompt} />
			</ModalBody>
		</div>
	);
};

export default GenerateImage;
