import type {
	BBModel,
	BBSymbolInfo,
	BBSymbolInstance,
	BBSymbolLink,
	SelectedBBSingle,
	StorySymbols,
} from 'types/story';
import { findComponentsBy } from 'utils/blocks/find-components-by';
import { findComponentBy } from 'utils/blocks/find-component-by';

type BBType = BBModel | BBSymbolInstance | SelectedBBSingle | BBSymbolLink;

// is symbol check type guard
export const isSymbol = <T extends BBType>(value: T): value is T & BBSymbolLink =>
	value?.symbol !== undefined && 'masterId' in value.symbol;

// is symbol link check type guard
export const isSymbolLink = (value: BBType): value is BBSymbolLink => !('_id' in value) && isSymbol(value);

/**
 * @param node {!object} bb node model
 * @returns {boolean}
 */
export const isSymbolChild = (node: BBType): node is BBSymbolInstance => node.symbol?.childId !== undefined;

/**
 * @param children element children
 */
export const getNestedSymbols = children => findComponentsBy<BBSymbolInstance>(children, isSymbol);

/**
 * @param children {Array} array of elements
 * @returns {{component: *, index: number}|boolean|*}
 */
export const hasSymbol = children => findComponentBy(children, { path: 'symbol.masterId', has: true });

/**
 * @param children {Array} array of elements
 * @returns {{component: *, index: number}|boolean|*}
 */
export const hasSymbolChild = children => findComponentBy(children, { path: 'symbol.childId', has: true });

/**
 * Get symbol instance model by his reference in card tree
 *
 * @param {Object} ref - symbol instance reference
 * @param {String} ref.masterId
 * @param {String} ref.instanceId
 * @param {Object} symbols - story symbols collection
 * @returns {Object|undefined} - Instance object model
 */
export const getInstanceByRef = (ref: BBSymbolInfo | undefined, symbols: StorySymbols = {}) => {
	if (!ref) return null;
	return symbols[ref.masterId]?.instance[ref.instanceId];
};

// has symbol link type guard
export const hasSymbolLink = <T extends object>(value: T): value is T & BBSymbolLink => 'symbol' in value;
