import _ from 'lodash';

function parseURL(url) {
	const parser = document.createElement('a') as HTMLAnchorElement;
	const searchObject: { [key: string]: string } = {};
	let field: string;
	let value: string | undefined;

	// Let the browser do the work
	parser.href = url;
	// Convert query string to object
	const queries = _.split(parser.search.replace(/^\?/, ''), '&');
	for (let i = 0; i < queries.length; i += 1) {
		[field, value] = queries[i].split('=');
		if (field !== '') {
			searchObject[field] = value === undefined ? '' : decodeURIComponent(value);
		}
	}
	return {
		protocol: parser.protocol,
		host: parser.host,
		hostname: parser.hostname,
		port: parser.port,
		pathname: parser.pathname,
		search: parser.search,
		searchObject,
		hash: parser.hash,
	};
}

function searchObjectToString(searchObject: Record<string, string>) {
	return _.transform<string, string[]>(
		searchObject,
		(result, value, key) => {
			const param = `${result.length ? '&' : '?'}${key}${value ? `=${value}` : ''}`;
			result.push(param);
		},
		[]
	).join('');
}

export { parseURL, searchObjectToString };
