import React, { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AdminReducerState } from 'admin/reducers';
import { redo, undo } from 'admin/actions/card-editor/card-editor-undo-redo';
import CardEditorIframeTransmitter from 'admin/components/pages/Story/CardEditor/IframeTransmitter';

export interface WithUndoRedoI {
	onUndo: () => void;
	onRedo: () => void;
	canUndo: boolean;
	canRedo: boolean;
	undoLength: number;
	redoLength: number;
}

const withUndoRedo = <P extends WithUndoRedoI = WithUndoRedoI>(Component: React.ComponentType<P>) => {
	const WithUndoRedo: FC<Omit<P, keyof WithUndoRedoI> & ConnectedProps<typeof connector>> = props => (
		<Component
			{...(props as P)}
			onUndo={props.onUndo}
			onRedo={props.onRedo}
			canUndo={props.canUndo}
			canRedo={props.canRedo}
			undoLength={props.undoLength}
			redoLength={props.redoLength}
		/>
	);

	WithUndoRedo.displayName = `WithUndoRedo(${Component.displayName || Component.name})`;

	const connector = connect(
		({ cardEditor }: AdminReducerState) => {
			const undoLength = cardEditor.past.length;
			const redoLength = cardEditor.future.length;
			return {
				canUndo: undoLength > 0,
				canRedo: redoLength > 0,
				undoLength,
				redoLength,
			};
		},
		(dispatch: any, ownProps) => ({
			onUndo: () =>
				dispatch(
					undo(
						CardEditorIframeTransmitter.transmitCardDataToClient,
						CardEditorIframeTransmitter.transmitStoryElementsToClient
					)
				),
			onRedo: () =>
				dispatch(
					redo(
						CardEditorIframeTransmitter.transmitCardDataToClient,
						CardEditorIframeTransmitter.transmitStoryElementsToClient
					)
				),
		})
	);

	// @ts-expect-error FIXME
	return connector(WithUndoRedo);
};

export { withUndoRedo };
