import React, { useState } from 'react';
import { Field, InjectedFormProps } from 'redux-form';
import { Link, Route, RouteComponentProps } from 'react-router-dom';
import { capitalize } from 'lodash';
import cn from 'classnames';

import t from 'common/utils/translate';
import { useDidMount } from 'common/components/useDidMount';
import Logo from 'common/components/StoryCardsLogo';
import useToggle from 'common/components/useToggle';
import { getResponseErrorMsg } from 'common/resources/api-response';

import {
	OAUTH_CONSENT,
	OAUTH_ERROR,
	OAUTH_GOOGLE_CALLBACK,
	OAUTH_LOGIN,
	RESET_PASSWORD_PAGE,
} from 'admin/constants/routes';
import Form, { LoginFormValues } from 'admin/components/pages/Login/LoginForm';
import { isMatching, QUERY } from 'admin/components/common/Responsive';
import { toast } from 'admin/components/common/MessageContainer/toast';
import RouterSwitch from 'admin/components/common/RouterSwitch';
import { TextField } from 'admin/components/common/Form';
import Button from 'admin/components/common/Button';
import Text from 'admin/components/common/Text';
import { OAuthConsent, OAuthConsentUpdate, OAuthLogin, OAuthVerify } from 'admin/resources';
import GoogleOAuth from './GoogleOAuth';
import css from './OAuth.scss';

const LOGIN_CHALLENGE = 'login_challenge';
const CONSENT_CHALLENGE = 'consent_challenge';
const scopeMap = new Map([
	['offline', 'Maintain access to data you have given it access to'],
	['story:read:analytics', 'Read story analytics data'],
]);
const errors = {
	unexpected: () => toast.error('Ooops, something went wrong...', 5),
	handled: error => {
		console.error(error);
		toast.error(getResponseErrorMsg(error), 5);
	},
};

const PageLayout = (props: { children: React.ReactNode; className?: string }) => (
	<div className={cn(css.root, props.className)}>
		<div className={css.body}>
			<Logo color="brand" className={css.logo} isMinimal={isMatching(QUERY.NO_DESKTOP)} />
			{props.children}
		</div>
	</div>
);

type LoginResponse = {
	subject: string;
	requestUrl: null | string;
	redirectTo: null | string;
	skip: boolean;
};

const LoginPage = (props: RouteComponentProps & InjectedFormProps<LoginFormValues, RouteComponentProps>) => {
	const isPasswordVisible = useToggle(false);
	const isNoDesktop = isMatching(QUERY.NO_DESKTOP);
	const [user, setUser] = useState<null | Partial<LoginResponse>>(null);
	const [view, setView] = useState<'chooseAccount' | 'login' | null>(null);
	const isSubmitting = useToggle(false);

	// verify user
	useDidMount(() => {
		async function verifyUser() {
			try {
				const challenge = new URL(window.location.href).searchParams.get(LOGIN_CHALLENGE);
				const response = await OAuthVerify.send({ challenge });
				const { result } = response.body as IBackendBody<LoginResponse>;

				if (!result) throw new Error('Something went wrong.');

				setUser(result);
				setView(result.skip && result.subject ? 'chooseAccount' : 'login');
			} catch (e) {
				errors.handled(e);
				setUser({});
				setView('login');
			}
		}

		verifyUser();
	});

	// login
	const onSubmit = async (values: LoginFormValues) => {
		isSubmitting.set(true);

		try {
			const challenge = new URL(window.location.href).searchParams.get(LOGIN_CHALLENGE);
			const response = await OAuthLogin.send({ challenge, ...values });
			const { result } = response.body as IBackendBody<{ redirectTo: null | string }>;

			if (result?.redirectTo) window.location.assign(result.redirectTo);
			else errors.unexpected();
		} catch (e) {
			errors.handled(e);
		}

		isSubmitting.set(false);
	};

	switch (view) {
		case 'chooseAccount':
			return user?.subject ? (
				<PageLayout className={css.login}>
					<Text
						weight="semibold"
						size={isNoDesktop ? 'subheading' : 'heading'}
						className={css.heroTitle}
						text="Choose an account"
					/>
					<button
						className={css.userBtn}
						type="button"
						onClick={() => {
							if (user?.redirectTo) window.location.assign(user.redirectTo);
						}}
					>
						<span>{user.subject[0].toUpperCase()}</span>
						{user.subject}
					</button>
					<button
						className={css.userBtn}
						type="button"
						onClick={() => {
							if (user?.requestUrl) window.location.assign(`${user.requestUrl}&prompt=login`);
						}}
					>
						<span />
						Use another account
					</button>
				</PageLayout>
			) : null;
		case 'login':
			return (
				<PageLayout className={css.login}>
					<Form onSubmit={onSubmit}>
						<Text
							weight="semibold"
							size={isNoDesktop ? 'subheading' : 'heading'}
							className={css.heroTitle}
							text="Hello, who’s this?"
						/>
						<div className={css.fields}>
							<Field
								className={css.emailFieldInput}
								name="email"
								component={TextField}
								isLabelUppercase={false}
								autoComplete="off"
								label={t('login.email.placeholder')}
								isRequired
							/>
							<div className={css.passwordField}>
								<Field
									name="password"
									component={TextField}
									isLabelUppercase={false}
									autoComplete="off"
									type={isPasswordVisible.value ? 'text' : 'password'}
									label={t('login.password.placeholder')}
									isRequired
								/>
								<button
									className={css.showPasswordBtn}
									type="button"
									onClick={isPasswordVisible.toggle}
								>
									{isPasswordVisible.value ? 'Hide' : 'Show'}
								</button>
							</div>
						</div>
						<div className={css.buttons}>
							<Button
								type="submit"
								textSize="label"
								loading={isSubmitting.value}
								disabled={isSubmitting.value}
							>
								{t('login.submit')}
							</Button>

							<Link to={RESET_PASSWORD_PAGE} className={css.link}>
								<Text size="label">{t('login.forgotPassword')}</Text>
							</Link>
						</div>
					</Form>
				</PageLayout>
			);
		default:
			return null;
	}
};

interface ConsentData {
	skip: boolean;
	redirectTo: null | string;
	requestedScope: string[];
	clientUri: string | null;
	clientName: string | null;
	subject: string | null; // email
}
const ConsentPage = (props: RouteComponentProps) => {
	const isSubmitting = useToggle(false);
	const [data, setData] = useState<Pick<
		ConsentData,
		'requestedScope' | 'clientName' | 'clientUri' | 'subject'
	> | null>(null);
	const isNoDesktop = isMatching(QUERY.NO_DESKTOP);

	useDidMount(() => {
		async function getConsent() {
			try {
				const challenge = new URL(window.location.href).searchParams.get(CONSENT_CHALLENGE);
				const response = await OAuthConsent.send({ challenge });
				const { result } = response.body as IBackendBody<ConsentData>;

				if (result?.skip && result.redirectTo) window.location.assign(result.redirectTo);
				else if (result)
					setData({
						requestedScope: result.requestedScope,
						clientName: result.clientName,
						clientUri: result.clientUri,
						subject: result.subject,
					});
				else errors.unexpected();
			} catch (e) {
				errors.handled(e);
			}
		}

		getConsent();
	});

	const onAllowClick = async () => {
		isSubmitting.set(true);

		try {
			const challenge = new URL(window.location.href).searchParams.get(CONSENT_CHALLENGE);
			const response = await OAuthConsentUpdate.send({ challenge });
			const { result } = response.body as IBackendBody<{ redirectTo: string | null }>;

			if (result?.redirectTo) window.location.assign(result.redirectTo);
			else errors.unexpected();
		} catch (e) {
			errors.handled(e);
		}

		isSubmitting.set(false);
	};

	if (!data) return null;

	return (
		<PageLayout className={css.consent}>
			<Text
				weight="semibold"
				size={isNoDesktop ? 'subheading' : 'heading'}
				className={css.consentTitle}
				text="Welcome"
			/>
			<Text
				size={isNoDesktop ? 'subheading' : 'heading'}
				className={css.consentTitle}
				text={data.subject ?? 'NoName'}
			/>

			<div className={css.consentContent}>
				<Text size="paragraph" weight="semibold" className={css.client}>
					<a href={data.clientUri ?? '#'} target="_blank" rel="noreferrer noopener">
						{data.clientName ?? 'NoName'}
					</a>{' '}
					wants to
				</Text>

				<ul className={css.consentScope}>
					{data.requestedScope?.map(scope => (
						<li key={scope}>
							{scopeMap.get(scope) || scope}
							<i>!</i>
						</li>
					))}
				</ul>
			</div>

			<Text weight="semibold" size="label">
				Allow {data.clientName ?? 'NoName'} to do this?
			</Text>

			<Text size="footnote" className={css.consentFootnote}>
				By clicking the &quot;Get started&quot; button, you agree to the{' '}
				{/* eslint-disable-next-line react/jsx-no-target-blank */}
				<a href="https://storycards.com/terms.pdf" target="_blank">
					Terms and Conditions
				</a>{' '}
				and {/* eslint-disable-next-line react/jsx-no-target-blank */}
				<a href="https://storycards.com/privacy-policy" target="_blank">
					Privacy Policy
				</a>
				. You also agree to receive product-related marketing emails from Storycards, which you can unsubscribe
				from at any time.
			</Text>

			<div className={css.buttons}>
				<Button
					type="button"
					onClick={onAllowClick}
					textSize="label"
					loading={isSubmitting.value}
					disabled={isSubmitting.value}
				>
					Allow
				</Button>
				<Button view="label-only" color="primary" textSize="label" onClick={() => props.history.goBack()}>
					Cancel
				</Button>
			</div>
		</PageLayout>
	);
};

const ErrorPage = () => {
	const isNoDesktop = isMatching(QUERY.NO_DESKTOP);
	const { searchParams } = new URL(window.location.href);

	return (
		<PageLayout>
			<Text
				tag="h1"
				weight="semibold"
				size={isNoDesktop ? 'subheading' : 'heading'}
				text={capitalize(searchParams.get('error') ?? '')}
				style={{ marginBottom: 24 }}
			/>
			<Text
				weight="normal"
				size={isNoDesktop ? 'article' : 'subheading'}
				text={capitalize(searchParams.get('error_description') ?? '')}
			/>
		</PageLayout>
	);
};

const OAuth = () => (
	<RouterSwitch>
		<Route exact path={OAUTH_LOGIN} component={LoginPage} />
		<Route exact path={OAUTH_CONSENT} component={ConsentPage} />
		<Route exact path={OAUTH_ERROR} component={ErrorPage} />
		<Route exact path={OAUTH_GOOGLE_CALLBACK} component={GoogleOAuth} />
	</RouterSwitch>
);

export default OAuth;
