import { GoogleFontItem, UploadedFont } from 'src/types';

export type InitialState = {
	search: string;
	view: 'google-font-list' | 'custom-fonts' | 'candidate-google-font';
	currentGFCandidate: GoogleFontItem | null;
	googleFontsList: GoogleFontItem[];
	uploadedFonts: { error: { msg: string; id?: string } | null; items: UploadedFont[] | null };
};
export type Action =
	| { type: 'set-search'; payload: InitialState['search'] }
	| { type: 'set-view'; payload: InitialState['view'] }
	| { type: 'set-gf-list'; payload: InitialState['googleFontsList'] }
	| { type: 'set-uploaded-fonts'; payload: InitialState['uploadedFonts'] }
	| { type: 'set-gf-candidate'; payload: InitialState['currentGFCandidate'] };

export const initialState: InitialState = {
	search: '',
	view: 'google-font-list',
	currentGFCandidate: null,
	googleFontsList: [],
	uploadedFonts: { error: null, items: null },
};

export function reducer(state: InitialState, action: Action) {
	switch (action.type) {
		case 'set-search':
			return { ...state, search: action.payload };
		case 'set-view':
			return { ...state, view: action.payload };
		case 'set-gf-candidate':
			return { ...state, currentGFCandidate: action.payload };
		case 'set-gf-list':
			return { ...state, googleFontsList: action.payload };
		case 'set-uploaded-fonts':
			return { ...state, uploadedFonts: action.payload };
		default:
			return state;
	}
}
