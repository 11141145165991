import React, { useState } from 'react';
import cn from 'classnames';
import { createPortal } from 'react-dom';
import { MODAL } from 'admin/constants/common';
import Text from 'admin/components/common/Text';
import { Modal } from 'admin/components/common/Modal';
import AIStatusLoader from 'admin/components/common/Loading/AIStatus';
import CSSTransition from 'admin/components/common/Transition/CSSTransition';
import type { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';
import GenerateCardContent, { GeneratorCardModalData } from './View/GenerateCardContent';
import GenerateText, { GeneratorTextModalData } from './View/GenerateText';
import GenerateImage, { GeneratorImageModalData } from './View/GenerateImage';
import GenerateSelectOptions, { GeneratorSelectOptionsModalData } from './View/GenerateSelectOptions';
import css from './EditorAIModal.scss';

const viewMap: Record<
	EditorAISettingsModalData['view'],
	typeof GenerateCardContent | typeof GenerateText | typeof GenerateImage | typeof GenerateSelectOptions
> = {
	card: GenerateCardContent,
	text: GenerateText,
	image: GenerateImage,
	selectOptions: GenerateSelectOptions,
};

const loaderTitle: Record<EditorAISettingsModalData['view'], string> = {
	card: 'Crafting a unique card',
	image: 'Crafting a unique image',
	text: 'Crafting a unique text',
	selectOptions: 'Generating choices',
};

const modalTitle: Record<EditorAISettingsModalData['view'], string> = {
	card: 'Generate card content',
	image: 'Generate image',
	text: 'Generate text',
	selectOptions: 'Generate choices',
};

export type EditorAISettingsModalData =
	| GeneratorCardModalData
	| GeneratorTextModalData
	| GeneratorImageModalData
	| GeneratorSelectOptionsModalData;

type Props = ModalManagerProvidedProps<MODAL.EDITOR_AI>;

const EditorAISettingsModal: React.FC<Props> = props => {
	const [isBusy, setIsBusy] = useState(false);
	const [loadingStatus, setLoadingStatus] = useState<string | undefined>();
	const ViewComponent = viewMap[props.data.view];
	const title = modalTitle[props.data.view];

	return (
		<>
			{createPortal(
				<CSSTransition timeout={{ enter: 500, exit: 0 }} in={isBusy}>
					<AIStatusLoader status={loadingStatus} title={loaderTitle[props.data.view]} view="card" />
				</CSSTransition>,
				document.body
			)}
			<Modal
				theme="dark"
				maskColor="black"
				open={props.open}
				destroyOnClose
				width={540}
				className={cn(css.modal, {
					[css.hidden]: isBusy || (props.data.view === 'text' && !!props.data.generateTextParams),
				})}
				transitionName=""
				title={
					title ? <Text size={Text.size.subtitle} weight={Text.weight.semibold} compact text={title} /> : null
				}
			>
				<ViewComponent {...props} setIsBusy={setIsBusy} setLoadingStatus={setLoadingStatus} />
			</Modal>
		</>
	);
};

export default EditorAISettingsModal;
