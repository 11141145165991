import * as React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import produce from 'immer';
import { nanoid } from 'nanoid';

import { GoogleFontItem, SCFontItem } from 'src/types';

import Button from 'admin/components/common/Button';
import { Column } from 'admin/components/common/Grid';

import { GoogleFontListItem, ChangeVariantsParams } from '../GoogleFontListItem';

import css from './CandidateGoogleFont.scss';

export type SelectedGoogleFontItem = {
	family: string;
	variants: string[];
};

type Props = {
	className?: string;
	fontData: GoogleFontItem;
	selected: SCFontItem | undefined;
	isBusy: boolean;
	onSave: (font: SCFontItem) => void;
	onCancel: () => void;
};

export const CandidateGoogleFont: React.FC<Props> = props => {
	const { className = '', onSave, isBusy, onCancel, selected, fontData } = props;
	const [currentSelected, setCurrentSelected] = React.useState<SelectedGoogleFontItem>({
		family: fontData.family,
		variants: selected?.variants || [fontData.variants[0]],
	});

	const onGoogleFontVariantsChange = ({ family, variant }: ChangeVariantsParams) => {
		const nextSelected = produce(currentSelected, draft => {
			const variantIndex = _.indexOf(draft.variants, variant);

			if (variantIndex >= 0) {
				draft.variants.splice(variantIndex, 1);
			} else {
				draft.variants.push(variant);
			}
		});

		setCurrentSelected(nextSelected);
	};

	const onSaveClick = () => {
		const resultFont: SCFontItem = {
			id: nanoid(4),
			fontFamily: fontData?.family || '',
			size: 0,
			fontType: 'google',
			fileType: '-',
			weight: 0,
			style: '-',
			variants: currentSelected.variants,
			url: `${fontData.family.split(' ').join('+')}:${currentSelected.variants.join(',')}`,
		};
		onSave(resultFont);
	};

	return (
		<div className={cn(css.selectedGoogleFont, className)}>
			<GoogleFontListItem
				selected={currentSelected}
				isExpanded
				onChangeVariants={onGoogleFontVariantsChange}
				data={fontData}
				key={fontData.family}
			/>

			<div className={css.actions}>
				<Column justifyContent="flex-start">
					<Button
						view="primary"
						disabled={isBusy || _.size(currentSelected.variants) === 0}
						loading={isBusy}
						onClick={onSaveClick}
						className={css.actionBtn}
					>
						Save
					</Button>

					<Button view="secondary" onClick={onCancel} className={css.actionBtn}>
						Back
					</Button>
				</Column>
			</div>
		</div>
	);
};
