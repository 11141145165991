import React, { createRef, ReactNode } from 'react';
import cn from 'classnames';
import ScrollBar from './ScrollBar';
import css from './Scrollable.scss';

type Props = {
	className?: string;
	contentClassName?: string;
	noRelative?: boolean;
	onScroll?: (distance: number) => void;
	darkMode?: boolean;
	children: ReactNode;
};

type State = {
	isRefsInitialized: boolean;
	hasOverflow: boolean;
};

class Scrollable extends React.Component<Props, State> {
	contentWrapRef = createRef<HTMLDivElement>();

	contentRef = createRef<HTMLDivElement>();

	scrollBarRef = createRef<ScrollBar>();

	state = {
		isRefsInitialized: false,
		hasOverflow: false,
	};

	componentDidMount() {
		setTimeout(() => {
			this.setState({ isRefsInitialized: true });
		}, 0);
	}

	get scrollTop() {
		return this.scrollBarRef.current?.scrollingDistance;
	}

	get offsetHeight() {
		return this.contentWrapRef.current?.offsetHeight;
	}

	scroll = params => {
		this.scrollBarRef.current?.updateScrollingDistance(params.top);
	};

	getBoundingClientRect = () => {
		return this.contentWrapRef.current?.getBoundingClientRect();
	};

	querySelector = (selector: string) => {
		return this.contentWrapRef.current?.querySelector(selector);
	};

	querySelectorAll = (selector: string) => {
		return this.contentWrapRef.current?.querySelectorAll(selector);
	};

	update = () => {
		this.scrollBarRef.current?.update();
	};

	onScrollingRatioUpdate = (value: number) => {
		this.setState(
			{
				hasOverflow: value !== 1,
			},
			() => {
				if (!this.state.hasOverflow && this.contentRef?.current) {
					this.contentRef.current.style.transform = 'translate3d(0, 0, 0)';
				}
			}
		);
	};

	render() {
		const { className, contentClassName, children, noRelative, darkMode } = this.props;
		const { isRefsInitialized, hasOverflow } = this.state;
		const isDesktop = true; // !isTouchable();

		return (
			<div
				className={cn(
					css.scrollable,
					!isDesktop && css.mobile,
					!hasOverflow && css.noOverflow,
					noRelative && css.noRelative,
					className,
					{
						[css.darkMode]: darkMode,
					}
				)}
				ref={this.contentWrapRef}
			>
				<div className={cn(css.scrollableContent, contentClassName)} ref={this.contentRef}>
					{children}
				</div>
				{isRefsInitialized && isDesktop && children && (
					<ScrollBar
						contentWrapRef={this.contentWrapRef}
						contentRef={this.contentRef}
						onScroll={this.props.onScroll}
						onScrollingRatioUpdate={this.onScrollingRatioUpdate}
						ref={this.scrollBarRef}
						darkMode={darkMode}
					>
						{children}
					</ScrollBar>
				)}
			</div>
		);
	}
}

export default Scrollable;
