import React, { HTMLAttributes, ReactNode, useMemo } from 'react';
import cn from 'classnames';
import { Modal as ModalAntD, ModalProps } from 'antd';
import { useAdminDispatch } from 'admin/reducers';
import { setModal } from 'admin/actions';

import { ThemeProvider, THEME } from 'admin/components/common/Theme';
import css from './Modal.scss';

const MASK_COLOR = {
	dark: 'dark',
	light: 'light',
	black: 'black',
	transparent: 'transparent',
} as const;

export type Props = Omit<ModalProps, 'visible'> & {
	theme?: ValuesType<typeof THEME>;
	maskColor?: (typeof MASK_COLOR)[keyof typeof MASK_COLOR];
	maskStyle?: object;
	children?: ReactNode;
	className?: string;
	useThemeClass?: boolean;
	fullScreen?: boolean;
	isNested?: boolean; // in case of nested modals, it should not close the main modal (that one which is in redux)
};

const Modal: React.FC<Props> = ({
	theme = THEME.LIGHT,
	maskStyle = {},
	maskColor = MASK_COLOR.light,
	useThemeClass = true,
	children,
	onCancel,
	fullScreen = false,
	isNested = false,
	...props
}: Props) => {
	const dispatch = useAdminDispatch();
	const themeContext = useMemo(() => ({ theme }), [theme]);

	const onCancelHandler = React.useCallback(
		event => {
			if (typeof onCancel === 'function') onCancel(event);
			if (!isNested) dispatch(setModal());
		},
		[isNested, onCancel, dispatch]
	);

	const fullScreenProps = fullScreen
		? {
				centered: false,
				width: '100%',
				mask: false,
			}
		: null;

	return (
		<ModalAntD
			centered
			footer={null}
			closable={false}
			wrapClassName={cn(css.modal, {
				[css[theme]]: Boolean(useThemeClass && themeContext.theme),
				[css.fullScreen]: fullScreen,
			})}
			maskTransitionName=""
			{...props}
			onCancel={onCancelHandler}
			styles={{
				mask: getMask({ maskStyle, maskColor }),
			}}
			{...fullScreenProps}
		>
			<ThemeProvider context={themeContext}>{children}</ThemeProvider>
		</ModalAntD>
	);
};

type ModalBodyProps = {
	className?: string;
	children: React.ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const ModalBody = ({ className, ...props }: ModalBodyProps) => (
	<div className={cn(css.modalBody, className)} {...props} />
);

ModalBody.defaultProps = {
	className: '',
};

type ModalFooterProps = {
	className?: string;
	children: React.ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const ModalFooter = ({ className, ...props }: ModalFooterProps) => (
	<div className={cn(css.modalFooter, className)} {...props} />
);

ModalFooter.defaultProps = {
	className: '',
};

function getMask(props: { maskStyle: object; maskColor: (typeof MASK_COLOR)[keyof typeof MASK_COLOR] }) {
	let backgroundColor = '';

	switch (props.maskColor) {
		case 'light':
			backgroundColor = 'hsla(0, 0%, 97%, 0.9)';
			break;
		case 'dark':
			backgroundColor = 'hsla(0, 0%, 33%, 0.9)';
			break;
		case 'black':
			backgroundColor = 'hsla(0, 0%, 0%, 0.6)';
			break;
		case 'transparent':
			backgroundColor = 'hsla(0, 0%, 0%, 0)';
			break;
		default:
			backgroundColor = 'hsla(0, 0%, 33%, 0.9)';
			break;
	}

	return { ...props.maskStyle, backgroundColor };
}

export { Modal, ModalBody, ModalFooter };
