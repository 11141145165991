import { noop } from 'lodash';
import React, { AllHTMLAttributes } from 'react';
import classnames from 'classnames';
import { FontSize, FontWeight, FontStyle, TextTransform, TextColor } from './config';
import css from './Text.scss';

interface Props extends Omit<AllHTMLAttributes<HTMLElement>, 'size'> {
	tag?: string;
	text?: string;
	size?: ValuesType<typeof FontSize>;
	color?: ValuesType<typeof TextColor>;
	weight?: ValuesType<typeof FontWeight>;
	fontStyle?: ValuesType<typeof FontStyle>;
	transform?: ValuesType<typeof TextTransform>;
	compact?: boolean;
	refCB?: (...args: any[]) => any;
	children?: any;
	ellipsis?: boolean;
}

/**
 * General Text component which includes all text styles variations
 * accordingly to Rabbi Guidelines (https://github.com/rabbiagency/guidelines)
 */
const Text = ({
	tag = 'div',
	text = '',
	size = 'paragraph',
	weight = 'none',
	fontStyle = 'none',
	transform = 'none',
	compact = false,
	className,
	children = [],
	refCB = noop,
	ellipsis,
	color,
	...rest
}: Props) => {
	const classNames = classnames(css[size], css[weight], css[fontStyle], css[transform], className, {
		[css.compact]: compact,
		'text-ellipsis': ellipsis,
		[css[`color-${color}`]]: color !== undefined,
	});
	const Component: any = tag;

	return (
		<Component className={classNames} ref={refCB} {...rest}>
			{text || children}
		</Component>
	);
};

Text.size = FontSize;
Text.color = TextColor;
Text.weight = FontWeight;
Text.fontStyle = FontStyle;
Text.transform = TextTransform;

export default Text;
