import React from 'react';
import { Field, FormName, change } from 'redux-form';
import cn from 'classnames';

import { TextField, Select, type SelectOption } from 'admin/components/common/Form';
import { Toggle } from 'admin/components/common/Form/Toggle';
import IntegrationsFacade, { INTEGRATIONS_NAMES as NAME } from 'common/utils/facades/integrations-facade';
import translate from 'common/utils/translate';

import { IOrganizationIntegrations } from 'src/types';
import { useAdminSelector } from 'src/routes/admin/reducers';
import { selectStoryCards } from 'src/routes/admin/reducers/story-editor/selectors';
import { first, last } from 'lodash';
import css from './Outbrain.scss';

type Props = {
	version: string;
	className?: string;
	integrations?: IOrganizationIntegrations;
	changeForm: typeof change;
};

const allCardsOption = { value: '', label: 'All cards' };
const t = (path: string) => translate(`common.integrations.views.${NAME.OUTBRAIN}.${path}`);

export const Outbrain: React.FC<Props> = props => {
	const { className, integrations } = props;
	const theme = 'dark';
	const fields = IntegrationsFacade.getFields(props.version).outbrain;
	const storyCards = useAdminSelector(selectStoryCards);
	const targetCardsOptions = React.useMemo(
		() => [allCardsOption, ...storyCards.map(c => ({ value: c._id, label: c.name }))],
		[storyCards]
	);

	const [targetCards, setTargetCards] = React.useState<readonly SelectOption[]>(
		integrations?.outbrain?.cards?.reduce((acc, cardId) => {
			const card = storyCards.find(c => c._id === cardId);
			if (card) acc.push({ value: card._id, label: card.name });
			return acc;
		}, [] as SelectOption[]) || []
	);

	return (
		<div className={cn(css.root, className)}>
			<Field
				className={css.toggle}
				name={fields.visible.name}
				component={Toggle}
				theme={theme}
				label={{ children: t('visible'), theme }}
				stretch
			/>
			<br />
			<FormName>
				{provided => (
					<Select
						isMulti
						closeMenuOnSelect={false}
						label={{ children: t('cards') }}
						options={targetCardsOptions}
						defaultValue={allCardsOption}
						theme={theme}
						value={targetCards.length ? targetCards : undefined}
						eventListeners={{
							onChange: selected => {
								const nextValue =
									first(selected) === allCardsOption
										? selected.filter(o => o !== allCardsOption)
										: last(selected) === allCardsOption
											? []
											: selected;

								setTargetCards(nextValue);

								props.changeForm(
									provided.form,
									`${fields.cards.name}`,
									(nextValue.length ? nextValue : [allCardsOption]).map(v => v.value).filter(Boolean)
								);
							},
						}}
					/>
				)}
			</FormName>
			<br />
			<Field
				name={`${fields.desktopWidgetId.name}`}
				autoComplete="off"
				isRequired
				component={TextField}
				label={t('desktopWidgetId')}
				isLabelUppercase={false}
				theme={theme}
			/>
			<br />
			<Field
				name={`${fields.mobileWidgetId.name}`}
				autoComplete="off"
				isRequired
				component={TextField}
				label={t('mobileWidgetId')}
				isLabelUppercase={false}
				theme={theme}
			/>
		</div>
	);
};
