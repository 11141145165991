import React, { useEffect, useState } from 'react';
import { StoryMediaPlatform, StoryTemplateWithData } from 'types/story';
import t from 'common/utils/translate';
import UrlHelper from 'common/utils/url-helper';
import { getAssetURL } from 'common/utils/assets';
import { CARD_TYPE, DEFAULT_MEDIA_QUERY_PLATFORMS } from 'common/constants';
import Loader from 'common/components/Loader';
import { useAdminDispatch, useAdminSelector } from 'admin/reducers';
import Text from 'admin/components/common/Text';
import Button from 'admin/components/common/Button';
import { Grid, Column } from 'admin/components/common/Grid';
import { getStoryTemplate } from 'admin/actions/story/templates';
import Layout from 'admin/components/pages/Stories/CreateStoryModal/ShareComponents/Layout';
import CardThumbnail from 'admin/components/pages/Stories/CreateStoryModal/ShareComponents/CardThumbnail';
import PlatformSelect from 'admin/components/pages/Stories/CreateStoryModal/ShareComponents/PlatformSelect';
import PreviewStory from 'admin/components/pages/Stories/CreateStoryModal/ShareComponents/PreviewStory';
import { segmentAnalytics } from 'src/routes/admin/utils';
import { selectActiveOrganizationId } from 'src/routes/admin/reducers/user/selectors';

type Props = {
	onOk: (params: { template: StoryTemplateWithData }) => void;
	templateId: number;
};

const TemplatePreview: React.FC<Props> = ({ templateId, ...props }) => {
	const dispatch = useAdminDispatch();
	const activeOrganizationId = useAdminSelector(selectActiveOrganizationId);
	const [template, setTemplate] = useState<StoryTemplateWithData | null>(null);
	const [platform, setPlatform] = useState<StoryMediaPlatform>(DEFAULT_MEDIA_QUERY_PLATFORMS.DESKTOP);
	const [cardId, setCardId] = useState('');

	useEffect(() => {
		dispatch(getStoryTemplate({ templateId })).then(response => {
			if (response.success && response.result) {
				segmentAnalytics.track({
					event: 'story.view_template',
					properties: {
						id: response.result.id,
						name: response.result.title,
						isPublic: response.result.organizationId === null,
						organizationId: activeOrganizationId,
					},
				});
				setTemplate(response.result);
			}
		});
	}, [dispatch, templateId, activeOrganizationId]);

	const mediaQuery = template?.story.published.data.mediaQuery;
	const hasData = template && mediaQuery;
	const isCoverMode = platform === DEFAULT_MEDIA_QUERY_PLATFORMS.DESKTOP;

	return (
		<>
			<Layout.Panel>
				<Grid columns="12" style={{ width: '100%' }}>
					<Column colStart="4" colSpan="6" justifyContent="center">
						{hasData ? (
							<PlatformSelect
								platform={platform}
								onPlatformChange={setPlatform}
								mediaQuery={mediaQuery}
							/>
						) : null}
					</Column>
					<Column colStart="10" colSpan="3" justifyContent="flex-end">
						<Button
							onClick={hasData ? () => props.onOk({ template }) : undefined}
							smallText
							textWeight={Text.weight.normal}
						>
							{t('createStoryModal.useTemplate')}
						</Button>
					</Column>
				</Grid>
			</Layout.Panel>
			<Layout.Aside className={Layout.className.pt35}>
				{hasData
					? template.story.published.data.steps.map(step => {
							return step.cards.map(card => {
								return card.type === CARD_TYPE.NAVIGATION ? null : (
									<CardThumbnail
										key={card._id}
										type="story"
										title={card.type}
										subtitle={card.name}
										screenshot={getAssetURL(
											template.story.published.settings.screenshot?.[card._id]?.[
												mediaQuery.defaultPlatform
											]
										)}
										cardId={card._id}
										isActive={cardId === card._id}
										onClick={setCardId}
									/>
								);
							});
						})
					: null}
			</Layout.Aside>
			<Layout.Content coverMode={isCoverMode} style={{ backgroundColor: 'var(--ra-color-black-light)' }}>
				{hasData ? (
					<PreviewStory
						coverMode={isCoverMode}
						mediaQuery={mediaQuery}
						platform={platform}
						src={UrlHelper.getPublishedUrl({
							clientStoryId: template.story.clientStoryId,
							cardId,
							preventRedirect: true,
						})}
					/>
				) : (
					<Loader fullScreen color="currentColor" bgColor="var(--ra-color-white-dark)" size="medium" />
				)}
			</Layout.Content>
		</>
	);
};

export default TemplatePreview;
