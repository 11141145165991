import { postAIText } from 'admin/resources';
import { createThunkAction, handleAPIv2Error } from 'admin/actions/helpers';
import { selectEditableStoryId } from 'admin/reducers/story-editor/selectors';
import { GenerateTextParams } from './types';

export const generateText = createThunkAction<string, GenerateTextParams>({
	payloadCreator: async (payload, { getState }) => {
		const state = getState();
		const storyId = selectEditableStoryId(state);

		if (!storyId) {
			return { success: false };
		}

		const postResponse = await postAIText.params({ storyId }).send(payload);

		return {
			success: true,
			result: postResponse.body?.text,
		};
	},
	onError: ({ error }) => {
		handleAPIv2Error(error);
	},
	alertDuration: 15_000,
});
