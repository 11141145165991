import React from 'react';
import Text from 'admin/components/common/Text';
import { Modal } from 'admin/components/common/Modal';
import type { EditSelectOptionsModalProps } from './types';

const ModalContent = React.lazy(() => import('./ModalContent'));

const EditSelectOptionsModal: React.FC<EditSelectOptionsModalProps> = props => {
	return (
		<Modal
			theme="dark"
			maskColor="black"
			open={props.open}
			destroyOnClose
			width={640}
			transitionName=""
			title={<Text size={Text.size.subtitle} weight={Text.weight.semibold} compact text="Edit choices" />}
		>
			<React.Suspense fallback={null}>
				<ModalContent {...props} />
			</React.Suspense>
		</Modal>
	);
};

export default EditSelectOptionsModal;
