import type { CardFlowEvent } from 'types/story';

export type RangePropType = {
	_id: string;
	min: number;
	max: number;
};

export type ScoreRange = RangePropType & { original?: CardFlowEvent };

export const isNotCurrent = (range: ScoreRange, rangeCurrent: ScoreRange) => range._id !== rangeCurrent._id;
export const isLowerThanMin = (range: ScoreRange, rangeCurrent: ScoreRange) => range.max <= rangeCurrent.min;
export const isGreaterThanMax = (range: ScoreRange, rangeCurrent: ScoreRange) => range.min >= rangeCurrent.max;
export const getRangesBefore = (ranges: ScoreRange[], range: ScoreRange) =>
	ranges.filter(v => isLowerThanMin(v, range) && isNotCurrent(v, range));
export const getRangesAfter = (ranges: ScoreRange[], range: ScoreRange) =>
	ranges.filter(v => isGreaterThanMax(v, range) && isNotCurrent(v, range));
