import { ActionCreators as UndoActionCreators } from 'redux-undo';
import { AUTO_SYNC_TARGET } from 'admin/constants/common';
import {
	TransmitCardDataToClient,
	TransmitStoryElementsToClient,
} from 'admin/components/pages/Story/CardEditor/IframeTransmitter';
import { selectCardEditor, selectEditableStateInfo } from 'admin/reducers/card-editor/selectors';
import { AdminThunkAction } from 'admin/reducers';

export function undo(
	transmitCardDataToClient: TransmitCardDataToClient,
	transmitStoryElementsToClient: TransmitStoryElementsToClient
): AdminThunkAction {
	return async (dispatch, getState) => {
		await dispatch({
			...UndoActionCreators.undo(),
			meta: { autoSync: AUTO_SYNC_TARGET.CARD_EDITOR },
		});
		const state = getState();
		const cardEditor = selectCardEditor(state);
		const editableState = selectEditableStateInfo(state);
		transmitCardDataToClient({ data: cardEditor.data!, symbols: cardEditor.symbols, editableState });
		transmitStoryElementsToClient({ elements: cardEditor.storyElements, editableState });
	};
}

export function redo(
	transmitCardDataToClient: TransmitCardDataToClient,
	transmitStoryElementsToClient: TransmitStoryElementsToClient
): AdminThunkAction {
	return async (dispatch, getState) => {
		await dispatch({
			...UndoActionCreators.redo(),
			meta: { autoSync: AUTO_SYNC_TARGET.CARD_EDITOR },
		});
		const state = getState();
		const cardEditor = selectCardEditor(state);
		const editableState = selectEditableStateInfo(state);
		transmitCardDataToClient({ data: cardEditor.data!, symbols: cardEditor.symbols, editableState });
		transmitStoryElementsToClient({ elements: cardEditor.storyElements, editableState });
	};
}
