import React from 'react';
import cn from 'classnames';

import { Modal } from 'admin/components/common/Modal';
import css from './PanelModal.scss';

type Props = {
	zIndex?: number;
	className?: string;
	open: boolean;
	children: React.ReactNode;
};

function PanelModal(props: Props) {
	const { open } = props;

	return (
		<Modal
			key={`PanelModal-${open}`}
			open={open}
			title={null}
			centered={false}
			destroyOnClose
			transitionName=""
			className={cn(css.modal, props.className)}
			width={parseInt(css.modalWidth, 10)}
			maskColor="transparent"
			zIndex={props.zIndex}
			theme="dark"
		>
			<div className={css.content}>{props.children}</div>
		</Modal>
	);
}

export default PanelModal;
