import React, { useState, useEffect } from 'react';
import { toast } from 'admin/components/common/MessageContainer/toast';

const NetworkStatus = () => {
	const [isOnline, setIsOnline] = useState(true);

	useEffect(() => {
		const onlineListener = () => {
			setIsOnline(true);
			toast.success("You're online! 😎", 3);
		};
		const offlineListener = () => {
			setIsOnline(false);
			toast.error("You're offline! 😴", 3);
		};

		window.addEventListener('online', onlineListener, false);
		window.addEventListener('offline', offlineListener, false);
		return () => {
			window.removeEventListener('online', onlineListener);
			window.removeEventListener('offline', offlineListener);
		};
	}, []);

	return isOnline ? null : (
		<div
			style={{
				position: 'fixed',
				// bottom: 6,
				// right: 16,
				right: 0,
				left: 0,
				bottom: 0,
				borderBottom: '4px solid #ea4543',
				padding: '0 16px 6px 0',
				zIndex: 9999,
				pointerEvents: 'none',
				color: '#ea4543',
				fontSize: '1.2rem',
				fontFamily: 'monospace',
				textAlign: 'right',
			}}
		>
			OFFLINE
		</div>
	);
};

export default NetworkStatus;
