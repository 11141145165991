import { emailRegExp, fullName } from 'utils/regexp';

export const isRequired = v => !!v;
export const isEmail = v => emailRegExp.test(v);
export const isFullName = v => fullName.test(v);
export const minLength = (v: string, min: number) => v && v.length >= min;
export const maxLength = (v: string, max: number) => v && v.length <= max;

export const USERNAME_MAX_LEN = 70;
export const VIDEO_TITLE_MAX_LEN = 40;
export const TEAM_MAX_LEN = 34;
export const WORKSPACE_MAX_LEN = 25;
export const STORY_NAME_MIN_LEN = 2;
export const STORY_NAME_MAX_LEN = 65;
export const AI_PROMPT_MAX_LEN = 500;
export const CARD_NAME_MIN_LEN = 1;
export const CARD_NAME_MAX_LEN = 65;
export const EVENT_NAME_MAX_LEN = 25;
export const SEO_TEXT_MAX_LEN = 120;
export const SHARE_TEXT_MAX_LEN = 120;
export const LAYER_NAME_MIN_LEN = 1;
export const LAYER_NAME_MAX_LEN = 50;
