import React, { useRef } from 'react';
import { Field } from 'redux-form';

import t from 'utils/translate';
import { AdminThunkDispatch } from 'admin/reducers';
import Text from 'admin/components/common/Text';
import { TextField } from 'admin/components/common/Form/TextField';
import PropField, { PropFieldType } from 'admin/components/pages/Story/CardEditor/Inspector/PropField';
import { LabelWithTooltip } from 'admin/components/pages/Story/CardEditor/Inspector/InspectorToolbar/LabelWithTooltip';
import Button from 'admin/components/common/Button';
import { Column } from 'admin/components/common/Grid';
import { Modal, ModalBody, ModalFooter } from 'admin/components/common/Modal';
import { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';
import { Hint } from 'admin/components/common/Form/Hint';
import { FORM_MODEL, MODAL } from 'admin/constants/common';

import { FieldConfig } from '../types';
import Form, { type FormProvidedProps } from './Form';

import css from './PropSettingsModal.scss';

const modalText = (path: string): string => t(`story.propSettingsModal.${path}`);

const defaultForm = FORM_MODEL.EDIT_CARD;

export type PropSettingsModalData = {
	propSettings: {
		title?: string;
		label?: string;
		hint?: string | JSX.Element;
		fieldConfig: FieldConfig | { name: 'symbolName' };
		fieldProps?: PropFieldType['fieldProps'] | undefined;
		labelProps?: PropFieldType['labelProps'];
		onCancel: (data: FormProvidedProps) => unknown;
		onSubmit: (values: any, formProps: any) => unknown;
		initialValues?: FormProvidedProps['initialValues'];
		form?: typeof defaultForm | typeof FORM_MODEL.CREATE_SYMBOL;
		destroyOnUnmount?: boolean;
	};
};

type Props = ModalManagerProvidedProps<MODAL.PROP_SETTINGS>;

const PropSettingsModal = ({ open, data, ...props }: Props) => {
	const { propSettings } = data;
	const fieldGrid = { disabled: true };
	const isSymbolField = propSettings.fieldConfig.name === 'symbolName';
	const formPropsRef = useRef<FormProvidedProps>();

	const onCancel = (formProps: FormProvidedProps) => () => {
		propSettings.onCancel(formProps);
		props.close();
	};

	const onSubmit = async (values: any, dispatch: AdminThunkDispatch, formProps: any) => {
		await propSettings.onSubmit(values, formProps);

		props.close();
	};

	const renderFormContent = (formProps: FormProvidedProps) => {
		formPropsRef.current = formProps;

		return (
			<>
				<ModalBody>
					{isSymbolField && (
						<Field
							className={css.symbolFieldProp}
							type="text"
							name={propSettings.fieldConfig.name}
							component={TextField}
							theme="dark"
							label={propSettings.label}
							autoFocus
							isRequired
							isLabelUppercase={false}
							autoSelect
						/>
					)}
					{propSettings.fieldProps && (
						<PropField
							className={css.fieldProp}
							inheritance={(propSettings.fieldConfig as FieldConfig).inheritance}
							fieldProps={propSettings.fieldProps}
							labelProps={{
								children: propSettings.label ? (
									<LabelWithTooltip field={propSettings.fieldConfig as FieldConfig}>
										{propSettings.label}
									</LabelWithTooltip>
								) : null,
							}}
							grid={fieldGrid}
						/>
					)}
					{propSettings.hint && <Hint className={css.hint} text={propSettings.hint} />}
				</ModalBody>

				<ModalFooter>
					<Column justifyContent="flex-start">
						<Button view="primary" type="submit">
							{modalText('save')}
						</Button>
						<Button view="secondary-gray" onClick={onCancel(formProps)}>
							{modalText('discard')}
						</Button>
					</Column>
				</ModalFooter>
			</>
		);
	};

	return (
		<Modal
			onCancel={() => propSettings.onCancel(formPropsRef.current!)}
			maskColor="black"
			theme="dark"
			open={open}
			destroyOnClose
			width={parseInt(css.modalWidth, 10)}
			className={css.modal}
			transitionName=""
			title={
				propSettings.title ? (
					<Text size={Text.size.subtitle} weight={Text.weight.semibold} compact text={propSettings.title} />
				) : null
			}
		>
			<div
				onSubmit={event => {
					/**
					 * NOTE: workaround 🤷
					 * Because of we have form inside form we need to stop submit
					 * propagation from current form to main card editor form.
					 */
					event.stopPropagation();
				}}
			>
				<Form
					form={propSettings.form ?? defaultForm}
					destroyOnUnmount={propSettings.destroyOnUnmount ?? false}
					initialValues={propSettings.initialValues}
					onSubmit={onSubmit}
				>
					{renderFormContent}
				</Form>
			</div>
		</Modal>
	);
};

export default PropSettingsModal;
