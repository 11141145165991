import { toast } from 'admin/components/common/MessageContainer/toast';

export const copyText = (text: string) => {
	const textArea = document.createElement('textarea');
	textArea.value = text;

	document.body.appendChild(textArea);
	textArea.focus();
	textArea.select();

	try {
		document.execCommand('copy');
		toast.success('Copied!');
	} catch (err) {
		toast.error('Oops, unable to copy');
	}

	document.body.removeChild(textArea);
};
