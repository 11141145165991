import React from 'react';
import cn from 'classnames';
import { Field, change } from 'redux-form';
import get from 'lodash/get';

import translate from 'utils/translate';
import { Hint, Toggle } from 'admin/components/common/Form';
import { useAdminDispatch } from 'admin/reducers';
import Text from 'admin/components/common/Text';

import { FORM_MODEL } from 'src/routes/admin/constants/common';
import type { SettingsTabsGeneralProps } from '../types';
import css from './GDPR.scss';

interface Props extends SettingsTabsGeneralProps {
	className?: string;
}

const t = (path: string) => translate(`story.settings.gdpr.${path}`);

const GDPR: React.FC<Props> = props => {
	const { className } = props;
	const dispatch = useAdminDispatch();

	return (
		<div className={cn(className)}>
			<Text className={css.title} size="subheading" weight="bold" text={t('title')} />
			<Text className={css.description} size="label" text={t('description')} />

			<div className={css.field}>
				<Field
					name={props.fields.gdprEnabled.name(props.version)}
					component={Toggle}
					theme="dark"
					label={{ children: t('enablePlugin'), component: 'label' }}
					stretch
					onChange={value => {
						if (!value) {
							dispatch(
								change(
									FORM_MODEL.EDIT_STORY,
									props.fields.gdprInteractionsDisabled.name(props.version),
									false
								)
							);
						}
					}}
				/>
				<Hint text={t('enablePluginDescription')} theme="dark" />
			</div>

			<div className={css.field}>
				<Field
					name={props.fields.gdprInteractionsDisabled.name(props.version)}
					component={Toggle}
					theme="dark"
					label={{ children: t('disableInteractions'), component: 'label' }}
					stretch
					disabled={!get(props.formValues, props.fields.gdprEnabled.name(props.version))}
				/>
				<Hint text={t('disableInteractionsDescription')} theme="dark" />
			</div>
		</div>
	);
};

export default GDPR;
