import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';

import { videos } from 'admin/resources';
import { useAdminSelector } from 'admin/reducers';
import { selectActiveOrganizationId } from 'admin/reducers/user/selectors';

import { toast } from 'admin/components/common/MessageContainer/toast';

import { UploadVideoCompleteParams } from 'src/types';

export const useUploadVideoComplete = () => {
	const organizationId = useAdminSelector(selectActiveOrganizationId);

	const { mutate, error, isPending, mutateAsync, isSuccess } = useMutation({
		mutationFn: async (params: Omit<UploadVideoCompleteParams, 'organizationId'>) => {
			const { id, key, parts } = params;
			const response = await videos.uploadComplete
				.params({ organizationId, videoUploadCompleteId: id })
				.send({ key, parts });

			return {
				success: true,
				result: { ...response.body },
			} as IBackendBody<any>;
		},
	});

	useEffect(() => {
		if (error) {
			const errorData = JSON.parse((error as Error).message ?? '{}');
			toast.error(errorData.message || (error as Error).message, 3);
		}
	}, [error]);

	return { uploadVideoComplete: mutate, uploadVideoCompleteAsync: mutateAsync, error, isSuccess, isPending };
};
