import { IUserOrganization } from 'types/user';
import { createThunkAction } from 'admin/actions/helpers';
import { REMOVE_ORGANIZATION_DOMAIN } from 'admin/constants/actions';
import { removeOrganizationDomain as removeOrganizationDomainEndpoint } from 'admin/resources';

interface Params {
	organizationId: string;
	domainId: IUserOrganization['domainId'];
}

export const removeOrganizationDomain = createThunkAction<Params, Params>({
	type: REMOVE_ORGANIZATION_DOMAIN,
	payloadCreator: async ({ organizationId, domainId }) => {
		const payload = { organizationId, domainId };
		await removeOrganizationDomainEndpoint.params(payload).send();
		return { success: true, result: payload };
	},
});
