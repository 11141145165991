import { has, set, get } from 'lodash';
import type { BBModel, BBSymbolLink } from 'types/story';

type SearchParams = {
	path: string;
	value?: any;
	has?: boolean;
};

type ReturnValue<T> =
	| undefined
	| {
			component: T;
			index: number;
			path: string;
			parent: T[];
	  };

/**
 * @param components
 * @param searchParams
 * @param searchParams.path    - https://lodash.com/docs/4.17.15#get
 * @param [searchParams.value] - searchable value
 * @param [searchParams.has]   - Checks if path is a direct property of object.
 * @param [replaceWith]        - replace found component with a received one
 *                                                (Warning: modifies original)
 * @return {{component: *, index: number}|undefined}
 */
export function findComponentBy<T extends (BBModel | BBSymbolLink)[]>(
	components: T,
	searchParams: SearchParams,
	replaceWith?: any
): ReturnValue<T[number]> {
	return search<T>(components, searchParams, replaceWith);
}

function search<T extends (BBModel | BBSymbolLink)[]>(
	components: T,
	searchParams: SearchParams,
	replaceWith?: any,
	path = ''
): ReturnValue<T[number]> {
	if (!Array.isArray(components) || !components.length) {
		return undefined;
	}

	for (let i = 0; i < components.length; i += 1) {
		const current = components[i];
		const currentPath = path ? `${path}.${i}` : `${i}`;
		let searchResult = searchParams.has ? has(current, searchParams.path) : get(current, searchParams.path);

		if (searchResult && (searchParams.has || searchResult === searchParams.value)) {
			if (replaceWith) {
				set(components, [i], replaceWith);
			}
			return {
				component: current,
				index: i,
				path: currentPath,
				parent: components,
			};
		}

		if (current && 'children' in current && Array.isArray(current.children) && current.children.length) {
			searchResult = search(current.children, searchParams, replaceWith, `${currentPath}.children`);
			if (searchResult) return searchResult;
		}
	}
	return undefined;
}
