import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import t from 'utils/translate';
import Features from 'utils/facades/features-facade';
import { SETTINGS_BILLING_PAGE } from 'admin/constants/routes';
import Notification from 'admin/components/common/GlobalNotification';
import { AdminReducerState } from 'admin/reducers';
import { selectActiveOrganization } from 'admin/reducers//user/selectors';
import { getUsage } from 'admin/components/common/User/Usage';
import { Icon } from 'admin/components/common/Icon';
import Text from 'admin/components/common/Text';
import Button from 'admin/components/common/Button';
import css from './BIllingWarning.scss';

type Props = ConnectedProps<typeof connector>;

const BillingWarning = ({ organization: org }: Props) => {
	// #1 case
	const views = org ? getUsage(org).pageviews : { current: 0, max: 0 };
	const isReachedLimit = !!views.max && views.current >= views.max;

	// #2 case
	const isCancelled = !!org && new Features({ organization: org }).isCanceled;

	const hasWarning = isReachedLimit || isCancelled;

	if (!hasWarning) {
		return null;
	}

	const notificationName = isCancelled ? 'accountSuspended' : 'pageViewsLimit';

	return (
		<Notification type="danger" isWide={isReachedLimit}>
			<div className={css.content}>
				<Icon type="sc-alert" width={40} />
				<Text size="paragraph" className={css.text} compact>
					<b>{t(`notification.${notificationName}.t1`)}</b>
					<br />
					<span>{t(`notification.${notificationName}.t2`)}</span>
				</Text>
				<Link to={SETTINGS_BILLING_PAGE}>
					<Button view="secondary-white" size="medium" type="button" theme="dark">
						{t(`notification.${notificationName}.btn`)}
					</Button>
				</Link>
			</div>
		</Notification>
	);
};

const connector = connect((state: AdminReducerState) => ({
	organization: selectActiveOrganization(state),
}));

export default connector(BillingWarning);
