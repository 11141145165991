import { SET_EDITOR_CURRENT_MEDIA_QUERY } from 'admin/constants/actions';

/**
 * @param currentMediaQuery {Object}
 * @return {{type: *, payload: *}}
 */
export function setEditorCurrentMediaQuery(currentMediaQuery) {
	return {
		type: SET_EDITOR_CURRENT_MEDIA_QUERY,
		payload: currentMediaQuery,
	};
}
