import React from 'react';
import { get } from 'lodash';

import IntegrationsFacade, { INTEGRATIONS_SCOPE } from 'common/utils/facades/integrations-facade';
import { FORM_MODEL } from 'admin/constants/common';
import { UrlParamsView, Params } from './UrlParamsView';

import { IntegrationComponentProps } from '../types';

type Props = IntegrationComponentProps;

export const UrlParams: React.FC<Props> = props => {
	const fields = IntegrationsFacade.getFields(props.version).urlParams;
	const fieldName = fields.params.name;

	const onChangeFormValues = (value: Params) => {
		props.changeForm(FORM_MODEL.EDIT_STORY, fieldName, value);
	};

	return (
		<UrlParamsView
			currentParams={get(props.formValues, fieldName, [])}
			scope={INTEGRATIONS_SCOPE.STORY}
			onAddParamClick={onChangeFormValues}
			onDeleteParamClick={onChangeFormValues}
		/>
	);
};
