import { IUserOrganization } from 'types/user';
import { toast } from 'admin/components/common/MessageContainer/toast';
import { retryOrganizationDomainConnection as retryOrganizationDomainConnectionEndpoint } from 'admin/resources';
import { RETRY_ORGANIZATION_DOMAIN_CONNECTION } from 'admin/constants/actions';
import { createThunkAction } from 'admin/actions/helpers';

interface Params {
	organizationId: string;
	domainId: IUserOrganization['domainId'];
}

export const retryOrganizationDomainConnection = createThunkAction<Params, Params>({
	type: RETRY_ORGANIZATION_DOMAIN_CONNECTION,
	payloadCreator: async ({ organizationId, domainId }) => {
		const payload = { organizationId, domainId };
		await retryOrganizationDomainConnectionEndpoint.params({ organizationId, domainId }).send();
		toast.success('Retry is complete. Please, wait for a while...', 4);
		return { success: true, result: payload };
	},
});
