import React, { CSSProperties } from 'react';
import cn from 'classnames';
import css from './Divider.scss';

type Props = {
	className?: string;
	style?: CSSProperties;
};

const Divider: React.FC<Props> = ({ className = '', ...props }) => {
	return <div className={cn(css.divider, className)} {...props} />;
};

export default Divider;
