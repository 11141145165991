import React, { Component, CSSProperties } from 'react';

import classNames from 'classnames';
import { size as _size, isFunction } from 'lodash';
import t from 'common/utils/translate';
import ChangeIcon from 'common/components/Icon/Change';
import ImageUploadIcon from 'common/components/Icon/UploadImage';
import Text from 'admin/components/common/Text';
import { Icon } from 'admin/components/common/Icon';
import { Errors } from 'admin/components/common/Form/Errors';

import { ReduxFieldInputTypes, ReduxFieldMetaTypes } from '../utils';

import css from './MediaField.scss';

const errMsgPath = 'form.mediaFieldErrors';

enum FileTypes {
	ALL_IMAGES_TYPES = 'ALL_IMAGES_TYPES',
	ONLY_PNG = 'ONLY_PNG',
	ONLY_JPG = 'ONLY_JPG',
	PNG_JPG = 'PNG_JPG',
	ICO_ONLY = 'ICO_ONLY',
}

enum ErrorTypes {
	UPLOAD_FAILED = 'UPLOAD_FAILED',
	FILE_SIZE_LIMIT = 'FILE_SIZE_LIMIT',
	FILE_WIDTH_HEIGHT_LARGE = 'FILE_WIDTH_HEIGHT_LARGE',
	FILE_WIDTH_HEIGHT_SMALL = 'FILE_WIDTH_HEIGHT_SMALL',
}

const convertFileName = (val: string) => {
	const targetVal = val.lastIndexOf('/') !== -1 ? val.substring(val.lastIndexOf('/') + 1) : val;

	if (
		targetVal === MediaField.errors.FILE_SIZE_LIMIT ||
		targetVal === MediaField.errors.FILE_WIDTH_HEIGHT_SMALL ||
		targetVal === MediaField.errors.FILE_WIDTH_HEIGHT_LARGE
	) {
		return '';
	}

	if (_size(targetVal) > 25) {
		return `${targetVal.substring(0, 8)}...${targetVal.substring(targetVal.length - 10)}`;
	}

	return targetVal;
};

type Props = {
	input: ReduxFieldInputTypes;
	meta: ReduxFieldMetaTypes;
	showErrorsOn?: keyof ReduxFieldMetaTypes | ((meta: ReduxFieldMetaTypes, input: ReduxFieldInputTypes) => boolean);
	label: string;
	defaultValue?: string;
	description?: string;
	size?: 'small' | 'large';
	previewSize?: 'contain' | 'size';
	theme?: 'light' | 'dark';
	fileType?: FileTypes;
	fileSizeLimit?: number; // Mb
	fileDimensions?: { maxW: number; maxH: number; minW?: number; minH?: number };
	sizeInfoLabel?: string;
	clearable?: boolean;
	eventListeners?: {
		onUpload: (data: { asset: File; name: string }) => Promise<Error | { asset: string }>;
	};
};

type State = {
	preview: string;
	isBusy: boolean;
	fileName: string;
};

class MediaField extends Component<Props, State> {
	static fileTypes = FileTypes;

	static errors = ErrorTypes;

	static errorsMsg = {
		[MediaField.errors.UPLOAD_FAILED]: t(`${errMsgPath}.${MediaField.errors.UPLOAD_FAILED}`),
		[MediaField.errors.FILE_SIZE_LIMIT]: t(`${errMsgPath}.${MediaField.errors.FILE_SIZE_LIMIT}`),
		[MediaField.errors.FILE_WIDTH_HEIGHT_LARGE]: t(`${errMsgPath}.${MediaField.errors.FILE_WIDTH_HEIGHT_LARGE}`),
		[MediaField.errors.FILE_WIDTH_HEIGHT_SMALL]: t(`${errMsgPath}.${MediaField.errors.FILE_WIDTH_HEIGHT_SMALL}`),
	};

	static defaultProps = {
		previewSize: 'size',
		size: 'small',
		theme: 'dark',
		fileSizeLimit: 8,
		fileType: FileTypes.ALL_IMAGES_TYPES,
		showErrorsOn: 'touched',
		clearable: true,
	};

	state = {
		isBusy: false,
		preview: this.props.input.value,
		fileName: convertFileName(this.props.input.value),
	};

	componentDidUpdate(prevProps: Props) {
		const currentValue = this.props.input.value;

		if (typeof currentValue === 'string') {
			if (
				prevProps.input.value !== currentValue &&
				(Object.values(ErrorTypes) as string[]).includes(currentValue) === false
			) {
				setTimeout(() => this.setState({ preview: currentValue }), 0);
			}
		}
	}

	getAccept = (fileType: FileTypes) => {
		switch (fileType) {
			case MediaField.fileTypes.ALL_IMAGES_TYPES:
				return 'image/*';
			case MediaField.fileTypes.ONLY_PNG:
				return 'image/png';
			case MediaField.fileTypes.ONLY_JPG:
				return 'image/jpeg';
			case MediaField.fileTypes.PNG_JPG:
				return 'image/png, image/jpeg';
			case MediaField.fileTypes.ICO_ONLY:
				return 'image/x-icon';
			default:
				return 'image/*';
		}
	};

	getPreviewStyles = () => {
		const { preview } = this.state;
		const { previewSize } = this.props;
		const result: Pick<CSSProperties, 'backgroundImage' | 'backgroundSize'> = {};

		if (preview) {
			result.backgroundImage = `url('${preview}')`;
		}

		if (previewSize === 'size') {
			result.backgroundSize = '32px 32px';
		}

		if (previewSize === 'contain') {
			result.backgroundSize = 'contain';
		}

		return result;
	};

	checkFileDimensions = (fileSrc: string): Promise<string> => {
		const img = new Image();
		const { fileDimensions } = this.props;
		const maxW = fileDimensions?.maxW ?? 0;
		const maxH = fileDimensions?.maxW ?? 0;
		const minW = fileDimensions?.minW ?? 0;
		const minH = fileDimensions?.minH ?? 0;

		return new Promise(resolve => {
			img.onload = () => {
				if (fileDimensions && (img.width > maxW || img.height > maxH)) {
					resolve(MediaField.errors.FILE_WIDTH_HEIGHT_LARGE);
					return;
				}

				if (fileDimensions && (img.width < minW || img.height < minH)) {
					resolve(MediaField.errors.FILE_WIDTH_HEIGHT_SMALL);
					return;
				}

				resolve('');
			};

			img.src = fileSrc;
		});
	};

	onCancelImageClick = () => {
		const { input, defaultValue } = this.props;

		if (defaultValue) {
			this.setState({ preview: defaultValue, fileName: convertFileName(defaultValue) });
			input.onChange(defaultValue);
		} else {
			this.setState({ preview: '', fileName: '' });
			input.onChange('');
		}
	};

	onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { input, fileSizeLimit } = this.props;
		const file = e.target.files instanceof FileList && e.target.files[0];

		if (file) {
			const reader = new FileReader();

			this.setState({ isBusy: true });

			reader.onload = async evt => {
				const preview = (evt.target?.result as string) ?? '';
				this.setState({ preview });

				const isFileSizeExceeded = fileSizeLimit && file.size > fileSizeLimit * 1024 * 1024;
				if (isFileSizeExceeded) {
					input.onBlur(e);
					input.onChange(MediaField.errors.FILE_SIZE_LIMIT);
					return;
				}

				const fileDimensionsError = await this.checkFileDimensions(preview);
				if (fileDimensionsError) {
					input.onBlur(evt);
					input.onChange(fileDimensionsError);
				} else {
					const result = await this.props.eventListeners?.onUpload?.({ name: input.name, asset: file });

					if (!result || !('asset' in result)) {
						input.onChange(MediaField.errors.UPLOAD_FAILED);
					} else {
						input.onChange(result.asset);
					}
				}
			};

			reader.readAsDataURL(file); // convert to base64 string

			this.setState({
				isBusy: false,
				fileName: convertFileName(file.name),
			});
		}
	};

	render() {
		const {
			meta,
			input,
			size = 'small',
			theme = 'dark',
			label,
			description,
			fileType,
			fileDimensions,
			showErrorsOn,
			clearable,
		} = this.props;
		const { preview, isBusy, fileName } = this.state;
		const id = `${meta.form}.${input.name}`;
		const sizeInfo =
			this.props.sizeInfoLabel || (fileDimensions ? `${fileDimensions.maxW}x${fileDimensions.maxH}px` : '');

		// file upload could be delayed (organization general settings page) and in meanwhile value is data-url
		// only after successful upload value will be updated with real url, so we need to check if value is url:
		const hasLinkToOriginal = fileName && typeof input.value === 'string' && input.value.match(/^http?s:\/\//);
		const fileNameTextProps = {
			tag: hasLinkToOriginal ? 'a' : 'div',
			size: Text.size.label,
			text: hasLinkToOriginal ? 'Download original' : description,
			href: hasLinkToOriginal && typeof input.value === 'string' ? input.value.split('?').shift() : undefined,
			target: hasLinkToOriginal ? '_blank' : undefined,
			rel: hasLinkToOriginal ? 'noreferrer noopener' : undefined,
		};

		const showErrors = isFunction(showErrorsOn) ? showErrorsOn(meta, input) : meta[showErrorsOn || ''];
		const isCancelDisabled =
			this.props.input.value.replace(/\?.*/, '') === this.props.defaultValue?.replace(/\?.*/, '');

		return (
			<div
				className={classNames(
					css.mediaField,
					meta.error && css.error,
					css[size],
					css[theme],
					isBusy && css.busy,
					preview && css.hasPreview
				)}
			>
				<div className={css.inputWrap}>
					<label htmlFor={id}>
						{preview ? (
							<div className={css.preview} style={this.getPreviewStyles()}>
								<div className={css.changeIconBtn}>
									<ChangeIcon />
								</div>
							</div>
						) : (
							<div className={css.uploadImageIcon}>
								<ImageUploadIcon
									width={size === 'large' ? '68px' : '28px'}
									height={size === 'large' ? '68px' : '28px'}
									color={
										theme === 'dark' ? 'var(--ra-color-white-dark)' : 'var(--ra-color-black-mid)'
									}
								/>
							</div>
						)}
						<svg className={css.preloader} viewBox="25 25 50 50">
							<circle
								cx="50"
								cy="50"
								r="20"
								fill="none"
								strokeWidth="2"
								strokeMiterlimit="10"
								strokeDasharray="100 25"
							/>
						</svg>
						<input
							type="file"
							accept={this.getAccept(fileType!)}
							id={id}
							name={input.name}
							onChange={this.onFileChange}
						/>
						{size === 'large' && (
							<div className={css.info}>
								<Text {...fileNameTextProps} />
								<Text size="label" tag="p" text={sizeInfo} />
								<Errors className={css.error} show={showErrors}>
									{meta.error}
								</Errors>
							</div>
						)}
						{size === 'large' && clearable && (
							<button
								type="button"
								onClick={this.onCancelImageClick}
								className={css.cancelIconBtn}
								disabled={isCancelDisabled}
							>
								<Icon type="x-rounded" width={24} color="var(--ra-color-white-dark)" />
							</button>
						)}
					</label>
				</div>
				{size === 'small' ? (
					<div className={css.info}>
						<Text className={css.label} weight="bold" size="label" tag="p" text={label} />
						<Text {...fileNameTextProps} />
						<Text size="label" tag="p" text={sizeInfo} />
						<Errors className={css.error} show={showErrors}>
							{meta.error}
						</Errors>

						{clearable && (
							<button
								type="button"
								onClick={this.onCancelImageClick}
								className={css.cancelIconBtn}
								disabled={isCancelDisabled}
							>
								<Icon type="x-rounded" width={24} color="var(--ra-color-white-dark)" />
							</button>
						)}
					</div>
				) : (
					<Text className={css.label} weight="bold" size="label" tag="div" text={label} />
				)}
			</div>
		);
	}
}

export default MediaField;
