import _ from 'lodash';
import vars from './_vars.scss';

const addDataAttribute = (attr, value, accumulator) => {
	if (_.isUndefined(value)) {
		return;
	}
	_.set(accumulator, `data${PREFIX}${attr}`, value);
};

const scssListToArray = (list = '') => list.split(',').map(val => val.trim());

const PREFIX = vars.prefix;

export { PREFIX, scssListToArray, addDataAttribute };
