import type { StoryModel } from 'types/story';
import { getStorycardsDomain as getDomainEndpoint, putStorycardsDomain as putDomainEndpoint } from 'admin/resources';
import { createThunkAction } from 'admin/actions/helpers';
import { GET_STORYCARDS_DOMAIN, PUT_STORYCARDS_DOMAIN } from 'admin/constants/actions';

export type StorycardsDomainType = Exclude<StoryModel['storycardsDomain'], undefined>;

export const getStorycardsDomain = createThunkAction<StorycardsDomainType[]>({
	type: GET_STORYCARDS_DOMAIN,
	payloadCreator: async () => {
		const response = await getDomainEndpoint.params().send();
		return { success: true, result: response.body };
	},
});

type Domain = StorycardsDomainType['domain'];

type PutStorycardsDomainParams = { storyId: string; domain: Domain };

export const putStorycardsDomain = createThunkAction<Domain, PutStorycardsDomainParams>({
	type: PUT_STORYCARDS_DOMAIN,
	payloadCreator: async ({ storyId, domain }) => {
		await putDomainEndpoint.params({ storyId }).send({ domain });
		return { success: true, result: domain };
	},
});
