import { createSelector } from 'reselect';
import type { AdminReducerState } from 'admin/reducers';
import { TEAM_MEMBER_ROLES } from 'admin/constants/common';
import { RolesFacade } from 'admin/utils/facades/roles-facade';

// =========================== Member ==========================
export const selectUser = (state: AdminReducerState) => state.user;

export const selectActiveTeam = createSelector(selectUser, u => u.activeTeam);

export const selectFeatureFlags = createSelector(selectUser, u => u.features);

export const selectUserId = createSelector(selectUser, u => u.id);

export const selectUserInfo = createSelector(selectUser, u => u.member);

export const selectIsUserInitialized = createSelector(selectUser, u => u.isInitialized);

export const selectUserName = createSelector(selectUserInfo, m => m.name);

export const selectUserPreferences = createSelector(selectUser, u => u.preferences);

export const isUserVerifiedSelector = createSelector(selectUser, u => u.verified);

export const userRolesSelector = createSelector(selectUser, u => u.roles);

export const isSuperUserSelector = createSelector(selectUser, user => {
	return RolesFacade.isSuperUser(user);
});

export const selectUserAuth = createSelector(selectUser, u => u.auth);

export const selectIsTwoFASetupAwaiting = createSelector(
	selectUserAuth,
	({ totp: { enforcement } }) => enforcement.required && !enforcement.enabled
);

export const selectUserRoles = createSelector(selectUser, u => new RolesFacade(u));

// =========================== Organization ==========================
export const selectOrganizations = createSelector(selectUser, u => u.organizations);

export const selectHasOrganizations = createSelector(selectOrganizations, list => list.length > 0);

export const selectActiveOrganization = createSelector(selectUser, o => o.organization);

export const selectActiveOrganizationId = createSelector(selectActiveOrganization, org => org?.id ?? '');

export const selectIsOrgTOTPRequired = createSelector(selectActiveOrganization, o => o?.isTOTPRequired ?? false);

export const isOrgManagerSelector = createSelector(selectUser, user => RolesFacade.isOrganizationManager(user));

export const isEnterprisePlanSelector = createSelector(selectActiveOrganization, activeOrganization => {
	return !!activeOrganization?.plan?.googleAnalytics;
});

const selectOrgAuxData = createSelector(selectUser, u => u.organizationAuxData);

export const selectActiveMembers = createSelector(selectOrgAuxData, state => state.activeMembers);

export const selectActiveMember = createSelector(selectOrgAuxData, state => state.activeMember);

export const selectActiveTeamMembers = createSelector(selectOrgAuxData, state => state.activeTeamMembers);

export const selectOrganizationDomains = createSelector(selectOrgAuxData, state => state.domains);

export const selectValidDomains = createSelector(selectOrganizationDomains, domains =>
	domains.filter(domain => domain.status === 'valid')
);

// =========================== Team ==========================
export const selectTeams = createSelector(selectActiveOrganization, o => o?.teams ?? []);

export const selectUserTeamsWithManageMembersRole = createSelector(
	[selectTeams, isOrgManagerSelector],
	(teams, isOrgManager) =>
		isOrgManager
			? teams
			: teams.filter(t => 'teamMembers' in t && t.teamMembers.roles?.includes(TEAM_MEMBER_ROLES.MANAGER_MEMBERS))
);

export const selectUserManagerTeams = createSelector([selectTeams, isOrgManagerSelector], (teams, isOrgManager) =>
	isOrgManager ? teams : teams.filter(t => 'teamMembers' in t && t.teamMembers.isManager)
);
