import _ from 'lodash';
import React, { FocusEventHandler } from 'react';
import classnames from 'classnames';
import ReactSelect, {
	ActionMeta,
	MenuPosition,
	OnChangeValue,
	Props as ReactSelectProps,
	components,
	MenuListProps,
} from 'react-select';
import Creatable from 'react-select/creatable';

import { Label, LabelProps } from '../Label';
import { Errors } from '../Errors';
import { getErrorData, ReduxFieldInputTypes, ReduxFieldMetaTypes, ShowErrorsOn } from '../utils';
import css from './Select.scss';

export const optionByValue = (options: Option[], value?: string | number | Array<Option>) =>
	Array.isArray(value) ? value : _.find(options, option => option.value === value) || null;

export type Option = {
	value?: string | number;
	label?: React.ReactNode;
	// creatable
	__isNew__?: boolean;
};

export type SelectProps<O extends Option = Option, M extends boolean = false> = {
	options: O[];
	input?: ReduxFieldInputTypes;
	meta?: ReduxFieldMetaTypes;
	placeholder?: string;
	isSearchable?: boolean;
	isCreatable?: boolean;
	isMulti?: M;
	closeMenuOnSelect?: boolean;
	defaultValue?: O;
	value?: any;
	theme?: 'dark' | 'light';
	className?: string;
	label?: LabelProps;
	inline?: boolean;
	menuPosition?: MenuPosition;
	showErrorsOn?: ShowErrorsOn;
	error?: string;
	eventListeners?: {
		onChange?: (
			value: M extends true ? OnChangeValue<O, true> : Exclude<OnChangeValue<O, false>, null>['value'],
			action: ActionMeta<unknown>['action'],
			name?: ActionMeta<unknown>['name']
		) => void;
		onBlur?: FocusEventHandler<HTMLInputElement>;
		onFocus?: FocusEventHandler<HTMLInputElement>;
	};
	renderMenuAfterComponents?: () => React.ReactNode;
} & Partial<Pick<ReactSelectProps, 'menuPlacement' | 'maxMenuHeight' | 'noOptionsMessage'>>;

export function Select<O extends Option = Option, M extends boolean = false>(props: SelectProps<O, M>) {
	const {
		input,
		options,
		meta,
		isMulti,
		className = '',
		label = { component: 'div' },
		inline = false,
		theme = 'light',
		menuPosition = 'absolute',
		showErrorsOn = 'touched',
		error = '',
		closeMenuOnSelect = true,
		isSearchable = false,
		isCreatable = false,
		placeholder = 'Select...',
		eventListeners = {},
		renderMenuAfterComponents = null,
		noOptionsMessage = undefined,
		...rest
	} = props;
	const [inputValue, setInputValue] = React.useState('');
	const [createdOptions, setCreatedOptions] = React.useState<Option[]>([]);
	const id = meta && input ? `${meta.form}.${input.name}` : '';
	const errorData = getErrorData({ meta, input, error, showErrorsOn });

	const onBlur: React.FocusEventHandler<HTMLInputElement> = event => {
		eventListeners?.onBlur?.(event);
	};

	const onFocus: React.FocusEventHandler<HTMLInputElement> = event => {
		input?.onFocus(input.value);
		eventListeners?.onFocus?.(event);
	};

	const handleInputChange = (value: string) => {
		setInputValue(value);
	};

	const onChange = (
		option: OnChangeValue<Option, false> | OnChangeValue<Option, true>,
		{ action, name }: ActionMeta<unknown>
	) => {
		const nextValue = Array.isArray(option)
			? (option as OnChangeValue<Option, true>)
			: (option as OnChangeValue<Option, false>)?.value;

		if (isCreatable && action === 'create-option' && option) {
			setCreatedOptions(prev => [...prev, ...(Array.isArray(option) ? [...option] : [option])]);
		}

		input?.onChange(nextValue);
		// FIXME: fix type
		eventListeners?.onChange?.(nextValue as any, action, name);
	};

	return (
		<div
			className={classnames(css.field, css.fieldText, className, css[theme], {
				[css.inline]: inline,
				[css.error]: errorData.show,
			})}
		>
			<Label className={css.label} htmlFor={id} {...label} />
			<label className={css.fieldInner}>
				{isCreatable ? (
					<Creatable
						{...input}
						{...rest}
						isMulti={!!isMulti}
						closeMenuOnSelect={closeMenuOnSelect}
						inputValue={inputValue}
						className={css.select}
						classNamePrefix="creatable"
						id={id}
						placeholder={placeholder}
						value={optionByValue([...options, ...createdOptions], input?.value || props.value)}
						options={options}
						onFocus={onFocus}
						menuPosition={menuPosition}
						onInputChange={handleInputChange}
						onBlur={onBlur}
						onChange={onChange}
						noOptionsMessage={noOptionsMessage}
					/>
				) : (
					<ReactSelect
						{...input}
						{...rest}
						className={css.select}
						classNamePrefix="select"
						isSearchable={isSearchable}
						isMulti={isMulti}
						closeMenuOnSelect={closeMenuOnSelect}
						id={id}
						placeholder={placeholder}
						value={optionByValue(options, input?.value || (props.value ?? rest.defaultValue?.value))}
						options={options}
						onFocus={onFocus}
						menuPosition={menuPosition}
						onBlur={onBlur}
						onChange={onChange}
						noOptionsMessage={noOptionsMessage}
						components={{
							MenuList: (_props: MenuListProps<Option>) => {
								return (
									<components.MenuList {..._props}>
										{_props.children}
										{renderMenuAfterComponents?.()}
									</components.MenuList>
								);
							},
						}}
					/>
				)}
			</label>
			<Errors className={css.errors} show={errorData.show}>
				{errorData.message}
			</Errors>
		</div>
	);
}

/* Example:

<Field
	name="username"
	component={Select}
	validate={[required, lettersOnly]}
    // validate array recommended to create out of render method to avoid wasted renders
	className="--custom-class"
	label={{ children: 'username', component: 'h1', className: ''--custom-label-class }}
	limit={{ max: 50, className: ''--custom-limit-class }}
	reset
	placeholder="username"
	showErrorsOn={false}
	autoSelect
	inline
/>
*/
