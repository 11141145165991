import css from './ConnectorSvg.scss';

const POINT_NAME = {
	p1: 'p1',
	p2: 'p2',
} as const;

const POINT_STATE = {
	connected: css.connected,
	connecting: css.connecting,
	highlight: css.highlight,
	showStart: css.showStart,
	showEnd: css.showEnd,
};

export { POINT_NAME, POINT_STATE };
