/* eslint-disable max-len */
export const notLetterOrNumber = new RegExp(/[^A-Za-z0-9]/, 'g');

// not integer, not float, not negative
export const notNumber = new RegExp(/[^-?\d+.?\d*$]/, 'g');

export const numberRegExp = new RegExp(/^[0-9]*$/, 'g');

export const gaRegExp = new RegExp(/^(G|UA|YT|MO)-[a-zA-Z0-9-]+$/i);

export const emailRegExp = /^\b[\w.-]+@[\w.-]+\.\w{2,4}\b$/;

export const telE164RegExp = /^\+[1-9]\d{10,14}$/;

export const passwordRegExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*+/[\]";:><,.-]).{8,}$/;

// {@link https://regexr.com/38l9b}
export const rgbaRegExp =
	/rgba\x28([012]\d\d|\d\d{0,1})(\s*?,\s*?)([012]\d\d|\d\d{0,1})(\s*?,\s*?)([012]\d\d|\d\d{0,1})(\s*?,\s*?)(0?\.\d+|[01])\x29/g;

export const fullName = /^([a-zA-Z]+)+([']*.)+([ ][a-zA-Z])+.*/;

/**
 * ✅ www.demo.com
 * ✅ http://demo.com
 * ✅ http://foo.co.uk/
 * ✅ http://regexr.com/foo.html?q=bar
 * ✅ https://mediatemple.net
 * ✅ https://ports.work.too:1337
 * ✅ http://127.0.0.1
 * ✅ http://127.0.0.1/something
 * ✅ http://127.0.0.1/something-else
 * ✅ http://127.0.0.1/something/else#anchored
 * ✅ http://127.0.0.1/something/else?and=more&even=more
 * ✅ https://foo.i.bar.co.il/something_Else
 * ✅ httpabrakadabra.co//
 *
 * ❌http:google.com
 * ❌http://no-tld-here-folks
 * ❌http://potato.54.211.192.240/
 */
export const urlRegExp =
	/^(https?:\/\/)?([\da-z.-]+\.[a-z.]{2,6}|[\d.]+)([/:?=&#]{1}[\da-z.-]+)*([/?]?[\da-zA-Z._-]*)*$/gi;

// {@link https://regexr.com/3evse}
export const domainRegExp = /^(?=^.{1,253}$)(([a-z\d]([a-z\d-]{0,62}[a-z\d])*[.]){1,3}[a-z]{1,61})$/;

// valid key of key/value pair in url search parameters
export const queryStringKey = /^[A-Za-z_][A-Za-z0-9_]*$/;
