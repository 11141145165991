import React from 'react';
import cn from 'classnames';
import css from './SwitchButton.scss';

type SwitchButtonProps = {
	position?: 'left' | 'right';
	leftButtonText: React.ReactNode;
	rightButtonText: React.ReactNode;
	size?: string;
	className?: string;
	btnClassName?: string;
	btnActiveClassName?: string;
	onSwitch?: (position: 'left' | 'right') => void;
};

const SwitchButton: React.FC<SwitchButtonProps> = ({
	position,
	leftButtonText,
	rightButtonText,
	size,
	onSwitch,
	className,
	btnClassName,
	btnActiveClassName,
}) => {
	const [activePosition, setActivePosition] = React.useState<'left' | 'right'>(position ?? 'left');

	const handleSwitch = () => {
		const nextPosition = activePosition === 'left' ? 'right' : 'left';
		setActivePosition(nextPosition);
		onSwitch?.(nextPosition);
	};

	// set position from props
	if (position !== undefined && position !== activePosition) {
		setActivePosition(position);
	}

	return (
		<div className={cn(css.switch, className, [css[activePosition]])} style={{ ['--size' as string]: size }}>
			<button
				className={cn(css.btn, btnClassName, activePosition === 'left' && btnActiveClassName)}
				type="button"
				onClick={handleSwitch}
			>
				{leftButtonText}
			</button>
			<button
				className={cn(css.btn, btnClassName, activePosition === 'right' && btnActiveClassName)}
				type="button"
				onClick={handleSwitch}
			>
				{rightButtonText}
			</button>
		</div>
	);
};

export default SwitchButton;
