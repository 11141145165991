// @flow
import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { useDidMount } from 'common/components/useDidMount';
import { useWillUnmount } from 'common/components/useWillUnmount';

import css from './VirtualizedList.scss';

type Props<T> = {
	className?: string;
	items: T[];
	itemHeight?: number;
	renderItem: (v: T, index: number) => React.ReactNode;
	itemsGap?: number;
	overItemsCount?: number;
};

export const VirtualizedList = <T,>(props: React.PropsWithChildren<Props<T>>) => {
	const { itemHeight = 80, items, renderItem, className, itemsGap = 10, overItemsCount = 10 } = props;

	const contentWrapRef = React.useRef<HTMLDivElement>(null);

	const [scrollTop, setScrollTop] = React.useState(0);

	useDidMount(() => {
		if (contentWrapRef.current) {
			contentWrapRef.current.addEventListener('scroll', handleScroll);
		}
	});

	useWillUnmount(() => {
		if (contentWrapRef.current) {
			contentWrapRef.current.removeEventListener('scroll', handleScroll);
		}
	});

	React.useEffect(
		function onChangeItemsLength() {
			if (contentWrapRef.current) {
				contentWrapRef.current.scrollTo(0, 0);
			}
		},
		[items]
	);

	const handleScroll = (e: Event) => {
		setScrollTop(_.get(e, 'target.scrollTop', 0));
	};

	const listHeight = React.useMemo(
		() => items.length * itemHeight + items.length * itemsGap,
		[items, itemHeight, itemsGap]
	);

	const getItemStyles = (index: number): React.CSSProperties => {
		return {
			height: `${itemHeight}px`,
			top: index === 0 ? index * itemHeight : index * (itemHeight + itemsGap),
			left: 0,
			position: 'absolute',
		};
	};

	const checkIfItemVisible = (index: number) => {
		const margin = index === 0 ? 0 : itemsGap;
		const elemPosition = index * (itemHeight + margin);

		return (
			elemPosition > scrollTop - overItemsCount * itemHeight &&
			elemPosition + itemHeight < scrollTop + overItemsCount * itemHeight
		);
	};

	const renderListItem = (data: T, index: number) => {
		const style = getItemStyles(index);

		return (
			checkIfItemVisible(index) && (
				<div key={`item-${index}`} style={style} className={css.itemWrapper}>
					{renderItem(data, index)}
				</div>
			)
		);
	};

	return (
		<div className={cn(css.listContent, className)} ref={contentWrapRef}>
			<div className={css.list} style={{ height: listHeight }}>
				{_.map(items, renderListItem)}
			</div>
		</div>
	);
};
