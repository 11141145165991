import React from 'react';
import cn from 'classnames';

import type { CardFlowEvent } from 'types/story';
import Text from 'admin/components/common/Text';
import Button from 'admin/components/common/Button';
import { SliderPure } from 'admin/components/common/Form/Slider';
import { Icon } from 'admin/components/common/Icon';

import css from './RateEvents.scss';

interface Props {
	className?: string;
	events: CardFlowEvent[];
	index: number;
	onChange: (index: number, value: number) => void;
	onRemove: (index: number) => void;
}

const RateEvent: React.FC<Props> = props => {
	const { className, events, index, onChange, onRemove } = props;
	const measureSpanRef = React.useRef<HTMLSpanElement>(null);
	const inputRef = React.useRef<HTMLInputElement>(null);
	const event = events[index];

	React.useEffect(() => {
		if (measureSpanRef.current && inputRef.current) {
			const { current: measureSpan } = measureSpanRef;
			const { current: input } = inputRef;
			const width = measureSpan.offsetWidth;

			input.style.width = `${width}px`;
		}
	}, [event.rate]);

	return (
		<div className={cn(css.event, index === 0 && css.disabled, className)}>
			<Text className={css.eventName} text={event.name} />
			<div className={css.eventControls}>
				<SliderPure
					className={css.slider}
					sliderProps={{
						tooltip: { open: false },
						onChange: value => onChange(index, value),
					}}
					value={event.rate}
				/>
				<div className={css.eventRate} onClick={() => inputRef.current?.focus()}>
					<span className={css.measureSpan} ref={measureSpanRef}>
						{event.rate}
					</span>
					<input
						className={css.input}
						type="number"
						value={event.rate}
						ref={inputRef}
						onChange={e => onChange(index, +e.target.value)}
						onFocus={e => e.target.select()}
					/>
					<span className={css.percent}>%</span>
				</div>
			</div>
			<Button
				className={css.eventRemoveBtn}
				title="Delete and increase prev rate or if isn't exist next rate with a current."
				view="empty"
				color="danger-ds"
				shape="circle"
				size="tiny"
				onClick={() => onRemove(index)}
			>
				<Icon className={css.eventRemoveIcon} type="minus-rounded" />
			</Button>
		</div>
	);
};

export default RateEvent;
