import React from 'react';
import { useHistory } from 'react-router';
import t from 'common/utils/translate';
import NotFound from 'common/components/NotFound';
import Button from 'admin/components/common/Button';
import { ROOT } from 'admin/constants/routes';

export default () => {
	const history = useHistory();
	return (
		<NotFound
			title={t('notFound.title')}
			actions={<Button onClick={() => history.push(ROOT)}>{t('notFound.backBtnLabel')}</Button>}
		/>
	);
};
