import { SET_STORY_SYMBOLS } from 'client/constants/actions';
import { StorySymbols } from 'types/story';

/**
 * @param payload {Object} Story settings object (full or partial state)
 * @return {{type: *, payload: *}}
 */
export function setStorySymbols(payload: StorySymbols) {
	return {
		type: SET_STORY_SYMBOLS,
		payload,
	};
}
