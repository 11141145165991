import cn from 'classnames';
import React from 'react';

import t from 'utils/translate';

import Text from 'admin/components/common/Text';

import { RoundProgressLoader } from './RoundProgressLoader';

import css from './UploadVideoProgress.scss';

interface Props {
	theme?: 'dark' | 'light';
	className?: string;
	height?: number;
	progress: number;
}

export const UploadVideoProgress: React.FC<Props> = props => {
	const { className = '', progress, height = 209, theme = 'light' } = props;

	return (
		<div className={cn(css.videoUploadProgress, css[theme], className)} style={{ height }}>
			<RoundProgressLoader theme={theme} className={css.roundProgress} progress={progress} />

			<Text size={Text.size.description} compact weight="semibold" className={css.progressTitle}>
				{t('uploadVideo.progressTitle')}
			</Text>
			<Text color={theme === 'dark' ? 'white-mid' : 'black-mid'} size={Text.size.footnote} compact>
				{t('uploadVideo.progressHint')}
			</Text>

			<div className={css.lineProgress}>
				<Text compact size={Text.size.footnote} className={css.percentage}>
					{Math.round(progress)}%
				</Text>
				<div className={css.progressBarBg}>
					<div className={css.progressBar} style={{ transform: `scaleX(${Math.min(progress / 100, 1)})` }} />
				</div>
			</div>
		</div>
	);
};
