export default {
	points: {
		1920: ['Full HD'],
		1792: ['MacBook Pro (16")'],
		1680: ['MacBook Pro (15")'],
		1470: ['MacBook Air (13.6")'],
		1440: ['MacBook Pro/Air (13")'],
		1400: ['MacBook Pro (earlier 15" models)'],
		1366: ['MacBook Air (11")', 'iPad Pro landscape'],
		1280: ['Retina MacBook (12")', 'MacBook Pro (earlier 13" models)'],
		1032: ['iPad Pro 13" (M4)'],
		1024: ['iPad Air 13" (M2)'],
		1205: ['Samsung Galaxy Tab S9 (Landscape)'],
		834: ['iPad Pro 11" (M4)'],
		820: ['iPad Air 11" (M2), iPad (10th Gen)'],
		753: ['Samsung Galaxy Tab S9 (Portrait)'],
		674: ['Samsung Galaxy Z Fold4'],
		430: ['iPhone 14/15 Pro Max/Plus'],
		412: [
			'Samsung Galaxy A51/A71',
			'Samsung Galaxy Z flip 4/5',
			'Samsung Galaxy Note 20/Note 20 Ultra',
			'Google Pixel 7/7 Pro',
			'Xiaomi 12 Pro',
		],
		// 393: ['iPhone 14/15 Pro'],
		390: ['iPhone 12/13/14', 'iPhone 12/13 Pro'],
		// 384: ['Samsung Galaxy S22 Ultra/S24 Ultra'],
		375: ['iPhone X/11 Pro/8/7/6/SE', 'iPhone 12 mini'],
		360: ['Samsung Galaxy S22/S23/S24', 'Samsung Galaxy S23+/S24+', 'Huawei P10/P20', 'Google Pixel'],
		320: ['iPhone 5', 'iPhone SE 1st gen'],
	},
};
