import React, { useState } from 'react';

import { useAdminDispatch } from 'admin/reducers';
import type { StoryModel } from 'types/story';
import Text from 'admin/components/common/Text';
import Button from 'admin/components/common/Button';
import { Column } from 'admin/components/common/Grid';
import { Modal, ModalBody, ModalFooter } from 'admin/components/common/Modal';
import { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';
import t from 'utils/translate';
import { deleteStory } from 'admin/actions/story/delete-story';
import { MODAL } from 'admin/constants/common';

import css from './DeleteStoryModal.scss';

export type DeleteStoryModalData = { storyId: StoryModel['id']; teamId: StoryModel['teamId'] };

const DeleteStoryModal: React.FC<ModalManagerProvidedProps<MODAL.DELETE_STORY>> = props => {
	const dispatch = useAdminDispatch();
	const [isBusy, setIsBusy] = useState(false);
	const { open, close } = props;
	const Title = (
		<Text size={Text.size.subtitle} weight={Text.weight.semibold} compact text={t('deleteStoryModal.title')} />
	);

	const onOk = async () => {
		const id = props.data?.storyId;

		if (id !== undefined) {
			setIsBusy(true);
			await dispatch(deleteStory({ storyId: id }));
			setIsBusy(false);
		}

		close();
	};

	return (
		<Modal title={Title} open={open} destroyOnClose width={564} className={css.modal}>
			<ModalBody className={css.body}>
				<Text size={Text.size.paragraph} text={t('deleteStoryModal.text')} />
			</ModalBody>
			<ModalFooter>
				<Column justifyContent="flex-start">
					<Button view="primary" onClick={close}>
						{t('deleteStoryModal.discard')}
					</Button>
					<Button view="secondary-danger" onClick={onOk} loading={isBusy}>
						{t('deleteStoryModal.confirm')}
					</Button>
				</Column>
			</ModalFooter>
		</Modal>
	);
};

export default DeleteStoryModal;
