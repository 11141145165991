import { Action } from 'redux';
import { ClientThunkAction } from 'client/reducers';
import { verifyPhoneCode as verifyPhoneCodeEndpoint } from 'client/resources';
import { VERIFY_PHONE_CODE } from 'client/constants/actions';

interface Params {
	organizationId: string;
	phone: string;
	code: string;
}
export function verifyPhoneCode({ organizationId, phone, code }: Params): ClientThunkAction<Action, any> {
	return async dispatch => {
		try {
			const response = await verifyPhoneCodeEndpoint.params().send({ organizationId, phone, code });
			const success = response?.statusText === 'OK';
			const payload = {
				success,
				organizationId,
				phone,
				code,
			};

			dispatch({ type: VERIFY_PHONE_CODE.SUCCESS, payload });
			return response;
		} catch (error) {
			dispatch({ type: VERIFY_PHONE_CODE.FAIL, payload: error });
			return (error as any)?.response.body;
		}
	};
}
