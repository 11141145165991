import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { round } from 'lodash';
import { Slider as AntdSlider } from 'antd';
import { useAdminSelector } from 'admin/reducers';
import type { SliderRangeProps, SliderSingleProps } from 'antd/lib/slider';

import { Errors } from 'admin/components/common/Form/Errors';
import { selectEditableElement } from 'admin/reducers/card-editor-extra/selectors';
import type { InspectorTypes } from 'admin/components/pages/Story/CardEditor/Inspector/types';
import type { ReduxFieldInputTypes, ReduxFieldMetaTypes } from 'admin/components/common/Form/utils';
import { selectedNodeData, setInlineStyle, updateElementInlineStyle } from '../NumberField/utils';
import css from './Slider.scss';

type AntdSliderProps = SliderSingleProps | SliderRangeProps;

type Value = AntdSliderProps['value'];

const onChange = (props: PFSliderProps) => (value: Value) => {
	props.input.onChange(value);
	props.eventListeners?.onChange?.({ target: { value, name: props.input.name } });
};

type Mark = Exclude<AntdSliderProps['marks'], undefined>[keyof Exclude<AntdSliderProps['marks'], undefined>];
type Marks = Record<string | number, (value: Value) => Mark>;

export type PFSliderProps = Pick<AntdSliderProps, 'tooltip' | 'range' | 'min' | 'max'> & {
	marks?: Marks;
	input: ReduxFieldInputTypes;
	meta: ReduxFieldMetaTypes;
	defaultValue?: string | number | [number, number];
	className?: string;
	showErrorsOn?: string | boolean;
	eventListeners?: ReturnType<InspectorTypes.GetFieldListeners>;
	unit?: boolean;
	decimals?: number;
	step?: number;
};

export const sliderWhiteMark = (value: ReactNode) => ({
	style: { color: 'var(--ra-color-white)' },
	label: value,
});

export const sliderMark = (value: number | string) => ({
	label: value,
	style: {
		top: -17,
		left: 'auto',
		right: 'calc(100% + 10px)',
		pointerEvents: 'none' as const,
		transform: 'none',
	},
});

export const Slider: FC<PFSliderProps> = ({
	className = '',
	showErrorsOn = 'touched',
	input,
	meta,
	eventListeners,
	unit,
	decimals,
	...props
}) => {
	const element = useAdminSelector(selectEditableElement);
	const editableData = useRef<ReturnType<typeof selectedNodeData>>(null);
	const formValue = input.value === '' && props.defaultValue ? props.defaultValue : input.value;
	const [isEditing, setIsEditing] = useState(false);
	const [editableValue, setEditableValue] = useState(formValue);
	const currentValue: Value = isEditing ? editableValue : formValue;

	useEffect(() => {
		editableData.current = selectedNodeData({ id: element.uiConfig.nodeProps.id, name: input.name });
		return () => {
			editableData.current?.property.forEach(property => {
				if (editableData.current?.node) {
					// reset inline value
					updateElementInlineStyle({ node: editableData.current.node, property, value: '' });
				}
			});
		};
	}, [input.name, element]);

	return (
		<div className={cn(css.pfSlider, className, { [css.withMark]: props.marks })}>
			{/* @ts-expect-error: having a problem to distinguish between SliderSingleProps | SliderRangeProps */}
			<AntdSlider
				{...props}
				marks={
					props.marks
						? Object.entries(props.marks).reduce((acc, [key, value]) => {
								acc[key] = value(currentValue);
								return acc;
							}, {})
						: undefined
				}
				onChangeComplete={(value: Exclude<Value, undefined>) => {
					setIsEditing(false);
					onChange({ ...props, input, meta, eventListeners })(value);
				}}
				onChange={(value: Exclude<Value, undefined>) => {
					if (editableData.current) {
						editableData.current?.property.forEach(property => {
							if (editableData.current?.node) {
								setInlineStyle({
									value: `${value ?? 0}`,
									min: props.min,
									max: props.max,
									decimals,
									unit,
									node: editableData.current.node,
									property,
								});
							}
						});
					}

					setIsEditing(true);
					setEditableValue(decimals !== undefined && !Array.isArray(value) ? round(value, decimals) : value);
				}}
				id={`${meta.form}.${input.name}`}
				value={currentValue}
			/>
			<Errors show={typeof showErrorsOn === 'string' ? meta[showErrorsOn] : showErrorsOn}>{meta.error}</Errors>
		</div>
	);
};
