import React from 'react';
import _ from 'lodash';

import css from './Responsive.scss';

export const QUERY = {
	DESKTOP: css.desktop,
	DESKTOP_FHD: css.desktopFHD,
	NO_DESKTOP: css.nonDesktop,
	TABLET: css.tablet,
	TABLET_P: css.tabletPortrait,
	TABLET_L: css.tabletLandscape,
	MOBILE: css.mobile,
	MOBILE_P: css.mobilePortrait,
	MOBILE_L: css.mobileLandscape,
	NO_MOBILE: css.nonMobile,
} as const;

type Props = {
	query: (typeof QUERY)[keyof typeof QUERY] | (typeof QUERY)[keyof typeof QUERY][];
	children: React.ReactNode | React.ReactNode[];
};

export const Responsive: React.FC<Props> = ({ children, query }: React.ComponentProps<typeof Responsive>) => {
	const [isMatch, setMatch] = React.useState(isMatching(query));
	const onWindowResize = React.useCallback(() => setMatch(isMatching(query)), [query]);

	React.useEffect(() => {
		window.addEventListener('resize', onWindowResize);
		return function cleanup() {
			window.removeEventListener('resize', onWindowResize);
		};
	}, [isMatch, onWindowResize]);

	return <>{isMatch && children}</>;
};

export const isMatching = (query: Props['query']) => {
	let array: boolean[] = [];

	if (_.isArray(query)) {
		array = _.map(query, (item: (typeof QUERY)[keyof typeof QUERY]) => !!window.matchMedia(item).matches);

		return array.some((o: boolean) => o);
	}

	return !!window.matchMedia(query).matches;
};
