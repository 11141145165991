import cn from 'classnames';
import React from 'react';

import Text from 'admin/components/common/Text';
import { Icon } from 'admin/components/common/Icon';
import { Label } from 'admin/components/common/Form/Label';
import { toast } from 'admin/components/common/MessageContainer/toast';

import t from 'utils/translate';

import css from './UploadVideoField.scss';

interface Props {
	theme?: 'dark' | 'light';
	className?: string;
	height?: number;
	name: string;
	label?: string;
	placeholder?: string;
	onChange: (file: File | null) => void;
}

const VIDEO_FILES_SUPPORTED_TYPES = ['video/mp4', 'video/webm'];

export const UploadVideoField: React.FC<Props> = props => {
	const { className = '', name, label = '', height = 209, theme = 'light', placeholder = '' } = props;
	const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
	const [isDragOver, setIsDragOver] = React.useState(false);
	const inputRef = React.useRef<HTMLInputElement>(null);
	const dragAreaRef = React.useRef<HTMLLabelElement>(null);

	const onDragOver = () => {
		setIsDragOver(true);
	};

	const onDragLeave = () => {
		setIsDragOver(false);
	};
	const onDrop = (e: React.DragEvent<HTMLLabelElement>) => {
		processFile(e.dataTransfer.files[0]);
		setIsDragOver(false);
	};

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		processFile(e.target.files?.[0]);
		setIsDragOver(false);
	};

	const processFile = (file: File | undefined) => {
		if (file) {
			if (VIDEO_FILES_SUPPORTED_TYPES.includes(file.type)) {
				setSelectedFile(file);
				props.onChange(file);
			} else {
				toast.error(t('uploadVideo.unsupportedFileType'), 3);
			}
		}
	};

	const onDeleteVideoBtnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		e.stopPropagation();

		setSelectedFile(null);

		props.onChange(null);

		if (inputRef.current) inputRef.current.value = '';
	};

	const renderNoFileView = () => {
		return (
			<div className={css.noFileView}>
				<Icon type="reload" color={theme === 'dark' ? 'gray' : 'var(--ra-color-black-mid)'} />

				<Text compact size="description" className={css.placeholder}>
					{placeholder}
				</Text>
			</div>
		);
	};

	const renderVideoPreview = () => {
		return (
			<div className={css.videoPreview}>
				<button type="button" onClick={onDeleteVideoBtnClick} className={css.deleteBtn} />

				<Text size="description" className={css.fileName}>
					{selectedFile?.name}
				</Text>
			</div>
		);
	};

	return (
		<div className={cn(css.uploadVideoInput, css[theme], className)}>
			<Label className={css.label} component="div">
				{label}
			</Label>
			<label
				className={cn(css.content, isDragOver && css.hovered)}
				onDragLeave={onDragLeave}
				onDragOver={onDragOver}
				onDrop={onDrop}
				ref={dragAreaRef}
				style={{ height }}
			>
				{selectedFile ? renderVideoPreview() : renderNoFileView()}
				<input
					ref={inputRef}
					name={name}
					onChange={onChange}
					accept={VIDEO_FILES_SUPPORTED_TYPES.join(', ')}
					type="file"
					className={css.input}
				/>
			</label>
		</div>
	);
};
