import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import _ from 'lodash';
import produce from 'immer';

import { UploadedFont } from 'src/types';

import t from 'utils/translate';
import Button from 'admin/components/common/Button';
import { Column, Grid } from 'admin/components/common/Grid';
import { CheckboxPure } from 'admin/components/common/Form/Checkbox';
import { Icon } from 'src/routes/admin/components/common/Icon';
import { Errors } from 'admin/components/common/Form';
import CustomFontInfo from 'admin/components/pages/Settings/Fonts/CustomFontInfo';
import { InitialState } from '../reducer';
import css from './UploadedFontsList.scss';

type Props = {
	data: InitialState['uploadedFonts'];
	className?: string;
	onSave: (data: InitialState['uploadedFonts']) => void;
	onCancel: () => void;
};

export const UploadedFontsList: React.FC<Props> = ({ onSave, onCancel, className = '', ...props }) => {
	const [data, setData] = useState(props.data);
	const [excludedItems, setExcludedItems] = useState<string[]>([]);

	useEffect(() => {
		setData(ps => ({ ...ps, error: props.data.error }));
	}, [props.data.error]);

	const onRename = useCallback(
		(value: string, index: number) => {
			setData(prevState =>
				produce(prevState, acc => {
					if (acc.items?.[index]) acc.items[index].fontFamily = value;
				})
			);
		},
		[setData]
	);

	const renderCustomFontItem = (f: UploadedFont, index: number) => {
		const isExcluded = !excludedItems.includes(f.id);
		return (
			<Grid columns="12" className={css.item} key={f.id}>
				{data.error?.id === f.id && (
					<Column colSpan="12">
						<Errors className={css.errors} show>
							{data.error?.msg}
						</Errors>
					</Column>
				)}
				<Column colSpan="11">
					<div className={css.itemHeader}>
						<Icon type="custom-font" width={24} />
						<label className={css.field}>
							<input type="text" value={f.fontFamily} onChange={e => onRename(e.target.value, index)} />
							<span>
								<span>{f.fontFamily}</span>
								<Icon type="edit" width={22} color="rgba(0, 0, 0, 0.2)" />
							</span>
						</label>
					</div>
					<CustomFontInfo font={f} />
				</Column>
				<Column justifyContent="flex-end" alignItems="center">
					<CheckboxPure
						boxSize={26}
						labelPosition="right"
						boxIcon="square"
						checked={isExcluded}
						onChange={() => {
							setExcludedItems(ps => (isExcluded ? [...ps, f.id] : ps.filter(id => id !== f.id)));
						}}
					/>
				</Column>
			</Grid>
		);
	};

	return (
		<div className={cn(css.uploadedFontsList, className)}>
			<div className={css.list}>{_.map(data.items, renderCustomFontItem)}</div>
			<div className={css.footer}>
				<Column justifyContent="flex-end">
					<Button
						disabled={excludedItems.length === data.items?.length || !data.items?.length}
						view="primary"
						onClick={() => {
							onSave({
								...data,
								items: data.items!.filter(item => !excludedItems.includes(item.id)),
							});
						}}
						className={css.actionBtn}
					>
						{t('settings.pages.fonts.updateBtnLabel')}
					</Button>

					<Button view="secondary" onClick={onCancel} className={css.actionBtn}>
						{t('settings.pages.fonts.cancelBtnLabel')}
					</Button>
				</Column>
			</div>
		</div>
	);
};
