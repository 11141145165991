import React from 'react';
import { Radio } from 'antd';
import { DEFAULT_MEDIA_QUERY_PLATFORMS } from 'common/constants';
import { StoryMediaPlatform, StoryMediaQuery } from 'types/story';
import { orderMediaQueryConfig } from 'common/utils/story-media-query';
import { MediaQueryIcon } from 'admin/components/common/MediaQueryIcon';
import css from './PlatformSelect.scss';

type Props = {
	mediaQuery: StoryMediaQuery;
	platform: StoryMediaPlatform;
	onPlatformChange: (platform: StoryMediaPlatform) => void;
};

const PlatformSelect: React.FC<Props> = props => {
	const orderedMediaQuery = orderMediaQueryConfig(props.mediaQuery);

	return (
		<div className={css.platformSelect}>
			<Radio.Group
				className={css.group}
				value={props.platform}
				onChange={e => {
					props.onPlatformChange(e.target.value);
				}}
			>
				{orderedMediaQuery.map(({ key }) =>
					key === DEFAULT_MEDIA_QUERY_PLATFORMS.FULL_HD ? null : (
						<Radio.Button key={key} className={css.button} value={key}>
							<MediaQueryIcon className={css.icon} platform={key} defaultPlatform="default" />
						</Radio.Button>
					)
				)}
			</Radio.Group>
		</div>
	);
};

export default PlatformSelect;
