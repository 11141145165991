import { debounce, mapValues } from 'lodash';
import type { Middleware, Action, MiddlewareAPI } from 'redux';
import type { AdminReducerState, AdminThunkDispatch } from 'admin/reducers';
import { initialState as cardEditorInitialState } from 'admin/reducers/card-editor/reducer';
import { storyInitialState as storyEditorInitialState } from 'admin/reducers/story-editor/reducer';
import { cardEditorSync } from 'admin/actions/card-editor/card-editor-sync';
import { storyEditorSync } from 'admin/actions/story/story-editor-sync';
import { selectStoryFacade } from 'admin/reducers/story-editor/selectors';
import { AUTO_SYNC_TARGET, SYNC_STATUS } from 'admin/constants/common';
import { isResetEditableCardAction } from 'admin/actions/card-editor/reset-edtiable-card';
import { CARD_EDITOR_SYNC, STORY_EDITOR_SYNC, STORY_EDITOR_UNMOUNT } from 'admin/constants/actions';

interface ActionType extends Action {
	payload?: unknown;
	meta?: {
		autoSync?: keyof typeof AUTO_SYNC_TARGET;
		autoSyncImmediate?: boolean;
	};
}

type SyncProps = {
	action: ActionType;
	next: (action: unknown) => unknown;
	prevStore: AdminReducerState;
	store: MiddlewareAPI<AdminThunkDispatch<ActionType>, AdminReducerState>;
};

const sync = ({ prevStore, store, next, action }: SyncProps) => {
	switch (action.meta?.autoSync) {
		case AUTO_SYNC_TARGET.CARD_EDITOR:
			if (prevStore.cardEditor.present !== store.getState().cardEditor.present) {
				return store.dispatch(cardEditorSync());
			}
			store.dispatch({ type: CARD_EDITOR_SYNC.SUCCESS });
			break;
		case AUTO_SYNC_TARGET.STORY_EDITOR:
			if (prevStore.storyEditor !== store.getState().storyEditor) {
				return store.dispatch(storyEditorSync());
			}
			store.dispatch({ type: STORY_EDITOR_SYNC.SUCCESS });
			break;
		default:
			break;
	}

	return false;
};

type Config = Partial<Record<keyof typeof AUTO_SYNC_TARGET, number>>;
type MiddlewareType = Middleware<{}, AdminReducerState, AdminThunkDispatch<ActionType>>;
export const autoSyncMiddleware = (config: Config = {}): MiddlewareType => {
	const debouncers = mapValues(config, option => {
		if (typeof option === 'number') {
			return debounce(sync, option);
		}

		return debounce(sync, 0);
	});

	// @ts-expect-error `action: ActionType` is incompatible with `action: unknown` defined at `Middleware`
	return store => next => async (action: ActionType) => {
		const state = store.getState();
		const { syncStoryStatus } = state.loading;

		if (isResetEditableCardAction(action) && action.payload.cancelDebouncedSync) {
			// cancel debouncer in order to save manually by <Sync> at <CardEditor>
			await debouncers[AUTO_SYNC_TARGET.CARD_EDITOR]?.cancel();
		}

		if (action.type === STORY_EDITOR_UNMOUNT && syncStoryStatus === SYNC_STATUS.DRAFT) {
			// immediately invoke debouncer
			await debouncers[AUTO_SYNC_TARGET.STORY_EDITOR]?.flush();
		}

		if (!action.meta || !action.meta.autoSync) {
			return next(action);
		}

		const { meta = {} } = action;
		const syncFnPayload = { action, next, store, prevStore: state };
		const syncFn = meta.autoSyncImmediate ? sync : meta.autoSync ? debouncers[meta.autoSync] : undefined;

		const result = next(action);

		const { isCardEditorAutoSyncEnabled } = selectStoryFacade(state);
		if (syncFn && meta.autoSync === AUTO_SYNC_TARGET.CARD_EDITOR && isCardEditorAutoSyncEnabled) {
			const isCardEditorInitialized = state.cardEditor.present !== cardEditorInitialState;
			// skip "sync" state update until reducer has data
			if (isCardEditorInitialized) {
				store.dispatch({ type: CARD_EDITOR_SYNC.DRAFT });
				syncFn(syncFnPayload);
			}
		}

		if (syncFn && meta.autoSync === AUTO_SYNC_TARGET.STORY_EDITOR) {
			const isStoryEditorInitialized = state.storyEditor !== storyEditorInitialState;
			// skip "sync" state update until reducer has data
			if (isStoryEditorInitialized) {
				store.dispatch({ type: STORY_EDITOR_SYNC.DRAFT });
				syncFn(syncFnPayload);
			}
		}

		return result;
	};
};
