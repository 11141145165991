import React, { useState, useEffect } from 'react';
import { getPlainTextFromHtml } from 'utils/helpers';
import Text from 'admin/components/common/Text';
import { useAdminDispatch } from 'admin/reducers';
import type { GeneratorPromptsParams } from 'admin/actions/story/ai/types';
import { getGeneratorPrompts, GeneratorPrompts } from 'admin/actions/story/ai/get-generator-prompts';
import css from '../EditorAIModal.scss';

type Props = {
	type: GeneratorPromptsParams['type'];
	onSelect: (prompt: string, id: string) => void;
};

const PromptHistory: React.FC<Props> = props => {
	const dispatch = useAdminDispatch();
	const [prompts, setPrompts] = useState<GeneratorPrompts>([]);

	useEffect(() => {
		dispatch(getGeneratorPrompts({ type: props.type })).then(response => {
			setPrompts(response.result ?? []);
		});
	}, [dispatch, props.type]);

	return prompts.length ? (
		<div className={css.promptHistory}>
			<Text size={Text.size.label} weight={Text.weight.semibold} color={Text.color.white}>
				Recent prompts
			</Text>
			<div className={css.promptHistoryList}>
				{prompts.map(item => (
					<Text
						tag="button"
						type="button"
						className={`${css.promptHistoryItem} text-ellipsis`}
						key={`${item.id}-${item.prompt}`}
						size={Text.size.label}
						color={Text.color.whiteDark}
						onClick={() => props.onSelect(item.prompt, item.id)}
						title={item.prompt}
					>
						{getPlainTextFromHtml(item.prompt)}
					</Text>
				))}
			</div>
		</div>
	) : null;
};

export default PromptHistory;
