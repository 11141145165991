import React, { Dispatch } from 'react';
import type { Action } from 'redux';
import { PublishedStoryJSON } from 'types/story';
import { Field, setSubmitFailed } from 'redux-form';
import { useDidMount } from 'common/components/useDidMount';
import { useAdminDispatch, useAdminSelector } from 'admin/reducers';
import { Column } from 'admin/components/common/Grid';
import Button from 'admin/components/common/Button';
import Text from 'admin/components/common/Text';
import { TextField } from 'admin/components/common/Form';
import { createStory } from 'admin/actions/story/create-story';
import { selectLoading } from 'admin/reducers/loading/selectors';
import { updateLatestStory } from 'admin/actions/story/update-latest-story';
import { StorycardsDomainType, putStorycardsDomain } from 'admin/actions/story/storcards-domain';
import t from 'utils/translate';
import { fields } from './Form';

const showErrorsOn = {
	clone: (meta, input) => meta.submitFailed && !meta.active,
};

type MainViewProps = {
	onOk: (storyId: string) => void;
	dispatch: Dispatch<Action>;
	syncErrors?: { clone?: string };
	form: string;
	url: string;
};

const TemplateName: React.FC<MainViewProps> = props => {
	const dispatch = useAdminDispatch();
	const isBusy = useAdminSelector(selectLoading).busy;
	const inputWrapRef = React.createRef<HTMLInputElement>();

	useDidMount(() => {
		if (inputWrapRef.current) {
			inputWrapRef.current.querySelector('input')?.focus();
		}
	});

	return (
		<div style={{ width: 800, textAlign: 'center' }}>
			<Field
				name={fields.clone}
				component={TextField}
				label="Clone story from URL"
				autoSelect
				showErrorsOn={showErrorsOn.clone}
				autoComplete="off"
				placeholder="Published story URL"
				isLabelUppercase={false}
				isRequired
				view={TextField.view.hero}
			/>
			<br />
			<Column justifyContent="center">
				<Button
					type="submit"
					loading={isBusy}
					onClick={async () => {
						if (props?.syncErrors?.[fields.clone]) {
							props.dispatch(setSubmitFailed(props.form, fields.clone));
							return;
						}

						try {
							const url = `${props.url}${props.url.endsWith('/') ? '' : '/'}story.json`;
							const story: PublishedStoryJSON = await fetch(url).then(r => r.json());

							// create new story
							const createStoryResponse = await dispatch(
								createStory({
									name: story.name,
									type: story.type,
									language: story.language,
									tags: story.tags,
								})
							);

							if (createStoryResponse.result) {
								const hostname = new URL(url).hostname as StorycardsDomainType['domain'];

								if (hostname === 'stories.sc' || hostname === 'storycards.co') {
									await dispatch(
										putStorycardsDomain({
											storyId: createStoryResponse.result.storyId,
											domain: 'storycards.co',
										})
									);
								}

								const updateLatestResponse = await dispatch(
									updateLatestStory({
										storyId: createStoryResponse.result.storyId,
										storyData: story.storyVersion.data,
										settings: story.storyVersion.settings,
									})
								);

								if (updateLatestResponse.success) {
									props.onOk(createStoryResponse.result.storyId);
								}
							}
						} catch (e) {
							props.dispatch(setSubmitFailed(props.form, fields.clone));
						}
					}}
					textSize={Text.size.label}
				>
					{t('createStoryModal.create')}
				</Button>
			</Column>
		</div>
	);
};

export default TemplateName;
