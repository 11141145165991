import { createThunkAction } from 'admin/actions/helpers';
import { UPDATE_ORGANIZATION_DOMAIN } from 'admin/constants/actions';
import { updateOrganizationDomain as updateOrganizationDomainEndpoint } from 'admin/resources';

interface Params {
	organizationId: string;
	domain: string;
}
export const updateOrganizationDomain = createThunkAction<{ id: number }, Params>({
	type: UPDATE_ORGANIZATION_DOMAIN,
	payloadCreator: async ({ organizationId, domain }) => {
		const response = await updateOrganizationDomainEndpoint.params({ organizationId }).send({ domain });
		return response.body;
	},
});
