import { AdminThunkAction } from 'admin/reducers';
import { updateStory as updateStoryEndpoint } from 'admin/resources';
import { UPDATE_STORY, SET_LOADING } from 'admin/constants/actions';
import { getStory } from 'admin/actions/story/get-story';
import { RequestMainRowData } from 'utils/facades/story-facade';

const defaultOptions = {
	getStoryOnComplete: true,
};

type Options = {
	getStoryOnComplete?: boolean;
	newTeamId?: string; // in case of move story to another team
};

/**
 * Update story the main story row (not versioned)
 * @param storyId
 * @param data
 * @param options {Object}
 * @return {Function}
 */
export function updateStory({ storyId, ...data }: RequestMainRowData, options: Options): AdminThunkAction {
	const _options: Options = { ...defaultOptions, ...options };

	return async (dispatch, getState) => {
		dispatch({ type: UPDATE_STORY.UPDATE });
		dispatch({ type: SET_LOADING, payload: { [UPDATE_STORY.UPDATE]: true } });

		try {
			const response = await updateStoryEndpoint.params({ storyId }).send(data);
			const success = response?.statusText === 'OK';
			const payload = {
				success,
				result: {
					storyId,
					...(options.newTeamId ? { newTeamId: options.newTeamId } : null),
				},
			};

			dispatch({ type: UPDATE_STORY.SUCCESS, payload });

			if (_options.getStoryOnComplete) dispatch(getStory({ storyId }));
		} catch (error) {
			dispatch({ type: UPDATE_STORY.FAIL, payload: error });
			console.error(error);
		}

		dispatch({ type: SET_LOADING, payload: { [UPDATE_STORY.UPDATE]: false } });
	};
}
