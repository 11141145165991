import { matchPath } from 'react-router-dom';
import type {
	BBAnimationProp,
	BBModel,
	BBModelTextChild,
	BBOtherProp,
	BBStylesProp,
	BBSymbolInstance,
	BBSymbolLink,
	StorySymbols,
	WithStateAndPlatform,
} from 'types/story';
import { STORY_PAGE } from 'admin/constants/routes';

/*
 * Get complete symbol model, by merge of symbol master data with an instance
 *
 * fixme: refactor types after 'style' & 'other' will be refactored to WithStateAndPlatform at story.ts
 */

type Other = WithStateAndPlatform<BBOtherProp>;
type Styles = WithStateAndPlatform<BBStylesProp>;
type Animation = Exclude<BBAnimationProp, undefined> | undefined;

type MergeComponentPropResult = Styles | Other | Animation;
type MergeComponentPropType = (Styles | Other | Animation) | ValuesType<Styles | Other | Exclude<Animation, undefined>>;

const mergeComponentProp = <R extends MergeComponentPropResult, T extends MergeComponentPropType>(
	master: T,
	instance: T
) =>
	mergePropWithStateAndPlatform<R, T>({
		master,
		instance,
		merge: params => ({ ...params.master, ...params.instance }),
	});

const mergeTextChildren = <R extends BBModelTextChild, T extends BBModelTextChild | ValuesType<BBModelTextChild>>(
	master: T,
	instance: T
) =>
	mergePropWithStateAndPlatform<R, T>({
		master,
		instance,
		merge: params => params.instance || params.master,
	});

const mergePropWithStateAndPlatform = <R extends object | undefined, T extends object | undefined>({
	master,
	instance,
	step = 'state',
	merge,
}: {
	master: T;
	instance: T;
	step?: 'state' | 'platform';
	merge: (props: { master: T; instance: T }) => T;
}) => {
	const result = {};
	const keys = Object.keys({ ...master, ...instance });
	const isPlatformLevel = step === 'platform';
	for (let index = keys.length - 1; index >= 0; index -= 1) {
		const key = keys[index];
		if (isPlatformLevel) {
			result[key] = merge({ master: master?.[key], instance: instance?.[key] });
		} else {
			result[key] = mergePropWithStateAndPlatform<R, T>({
				master: master?.[key],
				instance: instance?.[key],
				step: 'platform',
				merge,
			});
		}
	}
	return result as R;
};

const mergeOptional = <T extends object, TKey>(
	o1: T,
	o2: T,
	key: TKey extends keyof T ? TKey : never,
	mergeFn?: (o1: T[typeof key], o2: T[typeof key]) => unknown
) => (o1[key] || o2[key] ? { [key]: mergeFn ? mergeFn(o1[key], o2[key]) : { ...o1[key], ...o2[key] } } : null);

const cache = {
	// currently cache cannot work at client side, because objects passed through window.postMessage are always new
	isEnabled: matchPath(window.location.pathname, { path: STORY_PAGE, exact: false, strict: false }) !== null,
	master: new WeakSet<BBModel>(),
	instance: new WeakMap<BBSymbolInstance, BBSymbolInstance>(),
};

function mergeSymbol(master: BBModel, instance: BBSymbolInstance, cacheEnabled = cache.isEnabled): BBSymbolInstance {
	if (cacheEnabled && cache.master.has(master) && cache.instance.has(instance)) {
		return cache.instance.get(instance)!;
	}

	const mUiConfig = master.uiConfig;
	const iUiConfig = instance.uiConfig;

	const mNodeProps = mUiConfig.nodeProps;
	const iNodeProps = iUiConfig.nodeProps;

	const mComponentProps = mUiConfig.componentProps;
	const iComponentProps = iUiConfig.componentProps;

	const result = {
		_id: instance._id,
		type: instance.type,
		uiConfig: {
			...mUiConfig,
			...iUiConfig,
			nodeProps: {
				...mNodeProps,
				...iNodeProps,
				...mergeOptional(mNodeProps, iNodeProps, 'style'),
			},
			editorProps: {
				...mUiConfig.editorProps,
				...iUiConfig.editorProps,
			},
			componentProps: {
				...mComponentProps,
				...iComponentProps,
				...mergeOptional(mComponentProps, iComponentProps, 'animation', (m, i) =>
					mergeComponentProp(m as Animation, i as Animation)
				),
				...mergeOptional(mComponentProps, iComponentProps, 'fieldError'),
				...mergeOptional(mComponentProps, iComponentProps, 'onSwipe'),
				styles: mergeComponentProp(
					mComponentProps.styles as Styles,
					iComponentProps.styles as Styles
				) as BBStylesProp,
				other: mergeComponentProp(
					mComponentProps.other as Other,
					iComponentProps.other as Other
				) as BBOtherProp,
			},
		},
		children: Array.isArray(master.children)
			? master.children.map((masterChild, index) => mergeSymbol(masterChild, instance.children[index]))
			: mergeTextChildren(master.children, instance.children as BBModelTextChild),
		symbol: instance.symbol,
	};

	if (cacheEnabled) {
		if (!cache.master.has(master)) cache.master.add(master);
		if (!cache.instance.has(instance)) cache.instance.set(instance, result);
	}

	return result;
}

function getSymbolModel(element: BBSymbolLink, symbols: StorySymbols) {
	const { masterId, instanceId } = element.symbol;
	const { master, instance } = symbols[masterId];
	return mergeSymbol(master, instance[instanceId]);
}

export { mergeSymbol, getSymbolModel };
