import { UPDATE_ANSWERS } from 'client/constants/actions';

type Votes = { [answerId: string]: number };

type RegularAnswerPayload = {
	cardId: string;
	answerId: string;
	votes?: Votes; // published version only
};

type PollMultipleAnswerPayload = {
	cardId: string;
	answerId: string;
	data: { textContent?: string; embedCode?: string };
};

type SubmitCardAnswersPayload = {
	cardId: string;
	submitted: boolean;
	votes?: Votes; // published version only
};

type SortableTriviaAnswerPayload = {
	cardId: string;
	answerId: string;
	submitted: boolean;
	data: { isCorrect: boolean; textContent?: string };
};

type SortablePollAnswerPayload = {
	cardId: string;
	answerId: string;
	answerIds: string[];
	data: { textContent: string };
};

type SortablePollSubmitPayload = {
	cardId: string;
	submitted: boolean;
};

type UpdateVotesOnlyPayload = {
	cardId: string;
	votes: Votes;
};

export type UpdateUserAnswersPayload =
	| RegularAnswerPayload
	| PollMultipleAnswerPayload
	| SubmitCardAnswersPayload
	| SortableTriviaAnswerPayload
	| SortablePollAnswerPayload
	| SortablePollSubmitPayload
	| UpdateVotesOnlyPayload;

// Store selected answer ids and data
export function updateAnswers(payload: UpdateUserAnswersPayload) {
	return {
		type: UPDATE_ANSWERS,
		payload,
	};
}
