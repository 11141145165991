import type { BBModel, StorySymbols, StoryVersionType } from 'types/story';
import { isSymbol } from 'utils/blocks/symbol';
import { componentWalk } from 'utils/blocks/component-walk';
import { getSymbolModel } from 'utils/blocks/get-symbol-model';
import { isLayerType } from 'utils/blocks/is-layer-type';
import { findComponentBy } from 'utils/blocks/find-component-by';

/**
 * @callback callback
 * @param {CallbackProps} props
 */

/**
 * @typedef CallbackProps
 * @property {object} component - component object model
 * @property {number} index     - component index among parent children
 * @property {string} path      - dot-notated path to component from components root array
 */

type Props = {
	// card elements
	elements: BBModel[];
	symbols: StorySymbols;
	// provide story elements in order to take component data from there for story element references
	storyElements?: StoryVersionType['data']['elements'];
	initialPath?: string;
	callback: (p: {
		component: BBModel;
		index: number;
		path: string;
		parent?: BBModel;
		// Stop main componentWalk
		stopAll: () => void;
		// Stop current walk callback recursion
		stopCurrent: () => void;
	}) => void;
	// Optional callback used for debugging purposes to be called on each iteration within componentWalk.
	debugIterationCallback?: (props: Parameters<Parameters<typeof componentWalk>[1]>[0]) => void;
};

/**
 * Walk components depth-first and call a callback on each,
 * Every component, even if it's a symbol, will be represented as fully merged with master & instance
 */
export function componentWalkFull({
	elements,
	symbols,
	storyElements,
	callback: _callback,
	initialPath,
	debugIterationCallback,
}: Props) {
	const stop = {
		all: false,
		current: false,
		stopCurrent: () => {
			stop.current = true;
		},
		stopAll: () => {
			stop.all = true;
		},
	};

	let storyElementScopeId: null | string = null;

	componentWalk<BBModel>(
		elements,
		function walk(providedPayload) {
			const payload = { ...providedPayload };
			const runCallback = () => _callback({ ...payload, stopCurrent: stop.stopCurrent, stopAll: stop.stopAll });

			if (debugIterationCallback) {
				debugIterationCallback(payload);
			}

			if (isSymbol(payload.component) && symbols && !payload.component._id) {
				try {
					payload.component = getSymbolModel(payload.component, symbols);
					walk(payload);
					return;
				} catch (e) {
					console.error((e as Error).message, {
						path: payload.path,
						symbol: { ...payload.component.symbol },
					});
					runCallback();
					return;
				}
			}

			const isStoryElementReference = storyElements !== undefined && isLayerType(payload.component).global;
			const isStoryElementChild = payload.parent?._id === storyElementScopeId;
			/**
			 * If `storyElements` is provided and in `elements` found component which is a "reference" of story element
			 * then "original" story element is passed to callback, because it contains actual story element and his
			 * children data. In addition, don't need to lookup for origin story element model when `walk` in children.
			 */
			if (isStoryElementReference && !isStoryElementChild) {
				const c = findComponentBy(storyElements, { path: '_id', value: payload.component._id });
				if (c) {
					payload.component = c.component;
					storyElementScopeId = c.component._id;
				}
			}

			stop.current = false;

			runCallback();

			if (!stop.all && !stop.current) {
				componentWalk<BBModel>(payload.component.children, walk, payload.path, payload.component);
			}
		},
		initialPath
	);
}
