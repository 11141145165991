import type { BBModel } from 'types/story';
import { componentWalk } from 'utils/blocks/component-walk';

type Predicate<T> = [key: string, value: any] | ((o: T) => boolean);

export function findComponentsBy<Result = BBModel>(collection: BBModel[], predicate: Predicate<BBModel>) {
	const result: { component: Result; path: string }[] = [];

	if (Array.isArray(predicate)) {
		const [key, value] = predicate;
		componentWalk(collection, function walk({ component, path, index }) {
			if (component?.[key] === value) result.push({ component: component as unknown as Result, path });
			componentWalk(component.children, walk, path);
		});
	}

	if (typeof predicate === 'function') {
		componentWalk(collection, function walk({ component, path }) {
			if (predicate(component)) result.push({ component: component as unknown as Result, path });
			componentWalk(component.children, walk, path);
		});
	}

	return result;
}
