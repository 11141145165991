import React from 'react';
import cn from 'classnames';
import { capitalize } from 'lodash';
import { CardFacade } from 'utils/facades/card-facade';
import { CARD_TYPE } from 'common/constants';
import Text from 'admin/components/common/Text';
import { Icon } from 'admin/components/common/Icon';
import Layout from 'admin/components/pages/Stories/CreateStoryModal/ShareComponents/Layout';
import { FormValues } from './Form';
import css from './CreateStoryModal.scss';

const Option: React.FC<{
	checked: boolean;
	label: string;
	value: string | null;
	onClick: (value: string | null) => void;
}> = props => {
	return (
		<div
			className={cn(css.filterOption, { [css.checked]: props.checked })}
			onClick={() => props.onClick(props.value)}
		>
			<Icon
				type={props.checked ? 'check-outline-rounded' : 'empty-rounded'}
				width={18}
				color={props.checked ? 'var(--ra-color-brand-light)' : '#fff'}
			/>
			<Text size={Text.size.label} compact>
				{props.label}
			</Text>
		</div>
	);
};

const cardOptions = Object.values(CARD_TYPE)
	.filter(val => val !== CARD_TYPE.NAVIGATION && val !== CARD_TYPE.SANDBOX)
	.map(value => ({
		label: capitalize(CardFacade.getCardNameByType(value)),
		value,
	}));

type Props = {
	storyType: FormValues['type'];
	cardTypes: string[];
	onCardTypeFilterChange: (value: string) => void;
	onOrganizationFilterChange: (value: string | null) => void;
	organizationOptions?: { label: string; value: string | null; checked: boolean }[];
};

const TemplateGalleryFilters: React.FC<Props> = props => {
	if (props.storyType === 'widget' && props.organizationOptions?.length === 0) {
		return null;
	}

	return (
		<>
			<Text
				size={Text.size.label}
				transform={Text.transform.uppercase}
				weight={Text.weight.semibold}
				style={{ marginBottom: 40 }}
				compact
				className={Layout.className.pt35}
			>
				Filter
			</Text>
			<div className={css.filters}>
				{props.organizationOptions?.length ? (
					<section>
						<Text
							tag="h3"
							size={Text.size.label}
							color={Text.color.blackDark}
							weight={Text.weight.normal}
							compact
						>
							Type
						</Text>
						{props.organizationOptions.map(option => (
							<Option
								key={`${option.label}-${option.value}`}
								checked={option.checked}
								label={option.label}
								value={option.value}
								onClick={props.onOrganizationFilterChange}
							/>
						))}
					</section>
				) : null}

				{props.storyType !== 'widget' && (
					<section>
						<Text
							tag="h3"
							size={Text.size.label}
							color={Text.color.blackDark}
							weight={Text.weight.normal}
							compact
						>
							Cards included
						</Text>

						{cardOptions.map(option => (
							<Option
								key={option.value}
								value={option.value}
								label={option.label}
								onClick={value => (value === null ? undefined : props.onCardTypeFilterChange(value))}
								checked={props.cardTypes.includes(option.value)}
							/>
						))}
					</section>
				)}
			</div>
		</>
	);
};

export default TemplateGalleryFilters;
