import { IUserOrganization, IUserOrganizationPlan } from 'types/user';

export default class Features {
	org: IUserOrganization;
	plan: IUserOrganizationPlan | null;
	seatsCount: IUserOrganization['seatsCount'];
	teamsCount: IUserOrganization['teamsCount'];
	storiesCount: IUserOrganization['storiesCount'];

	constructor(props: { organization: IUserOrganization }) {
		this.org = props.organization;
		this.plan = props.organization.plan;
		this.seatsCount = props.organization.seatsCount ?? 0;
		this.teamsCount = props.organization.teamsCount ?? 0;
		this.storiesCount = props.organization.storiesCount ?? 0;
	}

	get isCanceled() {
		const { subscriptionStatus: status, gracePeriodEndsAt } = this.org;
		return (
			!!status &&
			(status === 'CANCELED' ||
				(status === 'CANCEL_ON_RENEWAL' &&
					typeof gracePeriodEndsAt === 'string' &&
					+new Date(gracePeriodEndsAt) < Date.now()))
		);
	}

	get canAddTeam() {
		const maxTeams = this.plan?.maxTeams;

		if (this.isCanceled || !this.plan?.teamsEnabled) return false;
		if (typeof maxTeams !== 'number') return true;

		return maxTeams > this.teamsCount;
	}

	get canAddStory() {
		const maxStories = this.plan?.maxStories;

		if (this.isCanceled) return false;
		if (typeof maxStories !== 'number') return true;

		return maxStories > this.storiesCount;
	}

	get canAddMember() {
		const memberSeats = this.plan?.memberSeats;

		if (this.isCanceled) return false;
		if (typeof memberSeats !== 'number') return true;

		return memberSeats > this.seatsCount;
	}

	is(key: keyof Omit<IUserOrganizationPlan, 'id' | 'name' | 'updatedAt' | 'createdAt'>) {
		return Boolean(this.plan?.[key]);
	}
}
