import { get, set } from 'lodash';
import React, { ReactNode } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { FORM_MODEL } from 'admin/constants/common';
import t from 'common/utils/translate';
import type { AdminReducerState } from 'admin/reducers';
import type { CardData } from 'types/story';

export const fields = {
	name: 'name',
	eventName: (index: number) => `events.${index}.name`,
	urlNavigationParam: 'settings.urlNavigationParam',
};

export type Values = CardData;

type PureProps = ConnectedProps<typeof connector> & {
	children: (form: ChildrenFormProp) => ReactNode;
	onSubmit: (values: Values) => void;
};
type FormProp = InjectedFormProps<Values, PureProps>;
type Props = PureProps & FormProp;
export type ChildrenFormProp = FormProp & Pick<PureProps, 'formData'>;

/**
 * Render children and pass all form props as form
 */
const Form = ({ children, onSubmit, ...form }: Props) => (
	<form onSubmit={form.handleSubmit(onSubmit)}>{children(form)}</form>
);

const EditCardModalForm = reduxForm<Values, PureProps>({
	validate: values => {
		const errors = {};

		if (!values[fields.name]) {
			errors[fields.name] = t(`story.editCardModal.fields.${fields.name}.required`);
		}

		values.events?.forEach((e, i) => {
			if (!e.name?.trim?.()) set(errors, fields.eventName(i), t('story.editCardModal.fields.eventName.required'));
		});

		return errors;
	},
	form: FORM_MODEL.EDIT_CARD,
})(Form);

const mapStateToProps = (state: AdminReducerState) => ({
	formData: get(state, `form.${FORM_MODEL.EDIT_CARD}.values`) as CardData,
});

const connector = connect(mapStateToProps);

export default connector(EditCardModalForm);
