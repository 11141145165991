import React, { FC } from 'react';

import t from 'utils/translate';
import { fitTextToSize } from 'utils/fit-text-to-size';
import useToggle from 'common/components/useToggle';
import Text from 'admin/components/common/Text';
import { Column } from 'admin/components/common/Grid';
import { Modal } from 'admin/components/common/Modal';
import Button from 'admin/components/common/Button';
import { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';
import { MODAL } from 'admin/constants/common';
import css from './EditorsConflictModal.scss';

export type EditorsConflictModalData = {
	email: string;
	name: string;
	onOk: () => void;
	onCancel: () => void;
};

const EditorsConflictModal: FC<ModalManagerProvidedProps<MODAL.STORY_EDITORS_CONFLICT>> = ({ close, data, open }) => {
	const isBusy = useToggle(false);

	const onOkClick = async () => {
		isBusy.set(true);
		await data.onOk();
		isBusy.set(false);
		close();
	};

	const onCancelClick = () => {
		data.onCancel();
		close();
	};

	return (
		<Modal
			width={parseInt(css.modalWidth, 10)}
			open={open}
			destroyOnClose
			className={css.modal}
			onCancel={data.onCancel}
		>
			<div className={css.head}>
				<Text size="subtitle" weight="semibold" compact text={t('editorsConflictModal.title')} />
			</div>

			<div className={css.body}>
				<Text size="paragraph" text={t('editorsConflictModal.text', { name: data.email })} />
			</div>

			<div className={css.footer}>
				<Column justifyContent="flex-start">
					<Button view="danger" onClick={onOkClick} className={css.actionBtn} loading={isBusy.value}>
						{t('editorsConflictModal.confirm', { name: fitTextToSize(data.name, 16) })}
					</Button>

					<Button view="secondary" onClick={onCancelClick} className={css.actionBtn}>
						{t('editorsConflictModal.skip')}
					</Button>
				</Column>
			</div>
		</Modal>
	);
};

export default EditorsConflictModal;
