import { createThunkAction } from 'admin/actions/helpers';
import { UPDATE_ORGANIZATION_HOMEPAGE } from 'admin/constants/actions';
import { putOrganizationHomepage as updateOrganizationHomepageEndpoint } from 'admin/resources';

interface Params {
	organizationId: string;
	favicon: string;
}
export const updateOrganizationHomepage = createThunkAction<{ id: number }, Params>({
	type: UPDATE_ORGANIZATION_HOMEPAGE,
	payloadCreator: async ({ organizationId, favicon }) => {
		const response = await updateOrganizationHomepageEndpoint.params({ organizationId }).send({ favicon });
		return response.body;
	},
});
