import { merge, set } from 'lodash';
import produce from 'immer';
import type { Action } from 'redux';
import * as Sentry from '@sentry/react';

import type { MemberPreferences } from 'src/types/user';
import { INIT, SET_LOADING } from 'admin/constants/actions';

import { segmentAnalytics } from 'admin/utils/segment-analytics';
import type { AdminThunkAction } from 'admin/reducers';
import { RolesFacade } from 'admin/utils/facades/roles-facade';

import { toast } from 'admin/components/common/MessageContainer/toast';
import { logout } from './logout';
import { getMe } from './get-me';
import { getMember, getMemberPreferences, setMemberPreferences, setActiveTeam, getFeatureFlags } from './member';
import { getOrganizationList, getOrganization } from './organization/organization';
import { getOrganizationDomains } from './organization/get-organization-domains';
import { auth } from './auth';

export function init(isFromLogin: boolean = false): AdminThunkAction<Action, void> {
	return async (dispatch, getState) => {
		try {
			dispatch({ type: SET_LOADING, payload: { [INIT.PENDING]: true } });
			dispatch({ type: INIT.PENDING });

			const me = await dispatch(getMe());
			const memberId = me.result?.id;

			if (!me.success || !memberId) {
				throw new Error(me.errors?.[0].message || 'Not authorized');
			}

			if (isFromLogin) {
				segmentAnalytics.identify({ userId: memberId });
			}

			const { result: member } = await dispatch(getMember({ memberId }));
			if (member) Sentry.setUser({ email: member.member.email, id: member.member.id });

			const preferencesResponse = await dispatch(getMemberPreferences({ memberId }));
			const { preferences: userPreferences } = getState().user;

			const preferences =
				preferencesResponse.success && preferencesResponse.result
					? merge(userPreferences, preferencesResponse.result)
					: userPreferences;

			const { result: organizations = [] } = await dispatch(getOrganizationList());

			const lastOrganizationId = preferences?.last?.organization;
			const isLastOrgExist = !!lastOrganizationId && organizations.some(o => o.id === lastOrganizationId);
			const organizationId = isLastOrgExist ? lastOrganizationId : organizations[0].id;

			if (!organizationId) {
				const e = 'You are not a member of any organization. Please create your own or request an invitation.';
				toast.error(e, 7);
				dispatch(logout());
				throw new Error(e);
			}

			const { result: organization } = await dispatch(getOrganization({ organizationId }));

			if (!organization) {
				const e = 'Organization not found';
				toast.error(e, 7);
				dispatch(logout());
				throw new Error(e);
			}

			const lastTeamId = preferences?.last?.team;
			type TeamType = ArrayType<typeof organization.teams>;
			const isLastTeamExist =
				RolesFacade.isPrivateTeam(lastTeamId) ||
				organization.teams.findIndex((team: TeamType) => team.id === lastTeamId) >= 0;
			const teamID = lastTeamId === undefined || !isLastTeamExist ? '' : lastTeamId;

			dispatch(setActiveTeam(teamID));
			dispatch(getFeatureFlags({ organizationId }));
			dispatch(getOrganizationDomains({ organizationId }));

			/**
			 * if user was deleted from team or organization which id was in user.preferences.last
			 * we have an issue with active team and organization in UI. So here we added checks to avoid
			 * this problem. if active team or organization not found we set empty string(for team) and first org
			 * in array of organizations and update user preferences
			 */

			if (!isLastTeamExist || !isLastOrgExist) {
				const newPreferences = produce<Partial<MemberPreferences>>(preferences, draft => {
					if (!isLastTeamExist) {
						set(draft, 'last.team', '');
					}

					if (!isLastOrgExist) {
						set(draft, 'last.organization', organizationId);
					}
				});

				dispatch(setMemberPreferences({ memberId, preferences: newPreferences }));
			}

			// check 2FA enforcement:
			await dispatch(auth.post.totpRequirement());

			dispatch({ type: INIT.FULFILLED });
			dispatch({ type: SET_LOADING, payload: { [INIT.PENDING]: false } });
		} catch (error) {
			dispatch({ type: INIT.REJECTED, payload: (error as any)?.message });
			dispatch({ type: SET_LOADING, payload: { [INIT.PENDING]: false } });
		}
	};
}
