import { TEAM_MEMBER_ROLES } from 'admin/constants/common';
import type { IUser } from 'src/types';
import Features from 'utils/facades/features-facade';

const ACTIONS = {
	publish: 'publish',
	// create own story
	manageOwnStory: 'manage_own_story',
	// edit, move, duplicate any team story
	manageOtherStories: 'manage_other_stories',
	watchAnalytics: 'watch_analytics',
	manageMembers: 'manage_members',
	delete: 'delete',
	design: 'design',
} as const;

export class RolesFacade {
	user: IUser;

	static actions = ACTIONS;

	static isPrivateTeam(teamId: string | null | undefined): boolean {
		return teamId === '' || teamId === null;
	}

	static isSuperUser(user: IUser): boolean {
		return user.roles?.includes('superuser') ?? false;
	}

	static isOrganizationManager(user: IUser): boolean {
		if (!user) return false;
		return RolesFacade.isSuperUser(user) || user.organization?.organizationMembers?.isManager || false;
	}

	constructor(user: IUser) {
		this.user = user;
	}

	private get activeTeamRoles() {
		const teamId = this.user.activeTeam;
		const currentTeam = this.organization?.teams.find(team => team.id === teamId);
		return currentTeam && 'teamMembers' in currentTeam ? currentTeam.teamMembers.roles ?? [] : [];
	}

	private get organization() {
		return this.user.organization;
	}

	public get isSubscriptionCanceled() {
		return !!this.organization && new Features({ organization: this.organization }).isCanceled;
	}

	public get isOrganizationManager(): boolean {
		return RolesFacade.isOrganizationManager(this.user);
	}

	public can = (action: ValuesType<typeof ACTIONS>, teamID?: string | '' | null) => {
		const targetTeamRoles = teamID !== undefined ? this.getTeamRoles(`${teamID}`) : this.activeTeamRoles;

		if (RolesFacade.isPrivateTeam(teamID ?? this.user.activeTeam)) {
			return true;
		}

		switch (action) {
			case RolesFacade.actions.design:
				return this.isOrganizationManager || targetTeamRoles.includes(TEAM_MEMBER_ROLES.STORY_DESIGN);
			case RolesFacade.actions.publish:
				return this.isOrganizationManager || targetTeamRoles.includes(TEAM_MEMBER_ROLES.PUBLISH);
			case RolesFacade.actions.manageOwnStory:
				return this.isOrganizationManager || targetTeamRoles.includes(TEAM_MEMBER_ROLES.STORY_MANAGE_OWN);
			case RolesFacade.actions.manageOtherStories:
				return this.isOrganizationManager || targetTeamRoles.includes(TEAM_MEMBER_ROLES.STORY_MANAGE_OTHERS);
			case RolesFacade.actions.delete:
				return this.isOrganizationManager || targetTeamRoles.includes(TEAM_MEMBER_ROLES.STORY_DELETE);
			case RolesFacade.actions.watchAnalytics:
				return this.isOrganizationManager || targetTeamRoles.includes(TEAM_MEMBER_ROLES.ANALYTICS);
			case RolesFacade.actions.manageMembers:
				return targetTeamRoles.includes(TEAM_MEMBER_ROLES.MANAGER_MEMBERS);
			default:
				return false;
		}
	};

	public getTeamRoles = (teamId: string) => {
		const targetTeam = this.user.organization?.teams.find(team => team.id === teamId);
		return targetTeam && 'teamMembers' in targetTeam ? targetTeam.teamMembers.roles ?? [] : [];
	};
}
