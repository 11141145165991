import { pick } from 'lodash';
import type { IProfilePictureFile } from 'types/user';
import { getAssetURL, replaceUrlHostname } from 'utils/assets';

export function getUserAvatarLink(profilePictureFile?: IProfilePictureFile) {
	if (profilePictureFile) {
		return replaceUrlHostname(getAssetURL(pick(profilePictureFile, ['filepath', 'hosting']), true));
	}

	return '';
}
