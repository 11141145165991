import React from 'react';
import cn from 'classnames';
import t from 'utils/translate';
import { INPUT_MASK } from 'admin/constants/common';
import { Label } from 'admin/components/common/Form/Label';
import { Errors } from 'admin/components/common/Form/Errors';
import { CharsCount } from 'admin/components/common/Form/CharsCount';
import {
	ReduxFieldInputTypes,
	ReduxFieldMetaTypes,
	maskValue,
	limitValue,
	getErrorData,
	ShowErrorsOn,
} from 'admin/components/common/Form/utils';
import css from './TextField.scss';

enum View {
	hero = 'hero',
}

enum LimitPosition {
	in = 'in',
	out = 'out',
}

type InputHTMLAttributes = Pick<
	React.InputHTMLAttributes<HTMLInputElement>,
	| 'onChange'
	| 'onBlur'
	| 'onKeyDown'
	| 'onKeyUp'
	| 'className'
	| 'id'
	| 'placeholder'
	| 'readOnly'
	| 'disabled'
	| 'autoComplete'
	| 'autoFocus'
	| 'name'
	| 'type'
>;

type CustomProps = {
	mask?: (typeof INPUT_MASK)[keyof typeof INPUT_MASK];
	suffix?: React.ReactNode;
	isLabelUppercase?: boolean;
	value?: string;
	error?: string;
	label?: string;
	theme?: 'dark' | 'light';
	description?: string;
	limit?: { max?: number; position?: LimitPosition; show?: boolean };
	isRequired?: boolean;
	autoSelect?: boolean;
	showErrorsOn?: ShowErrorsOn;
	view?: View;
};

type ReduxWrappedFieldProps = {
	input?: ReduxFieldInputTypes;
	meta?: ReduxFieldMetaTypes;
};

type Props = InputHTMLAttributes & CustomProps & ReduxWrappedFieldProps;

/**
 * Form input component that can be used both as independent pure field or
 * passed to component prop of redux-form 'Field'
 *
 * @example independent
 *      <TextField
 *        name="username"
 *        label="Username"
 *        placeholder="username..."
 *        onChange={onChangeHandler}
 *        value={username}
 *      />
 *
 * @example redux-form
 *     <Field
 *        component={TextField}
 *        name="username"
 * 		  label="Username"
 * 		  placeholder="username..."
 * 		  onChange={onChangeHandler}
 * 		  value={username}
 * 	    />
 */
export const TextField = ({
	className,
	input,
	label,
	meta,
	onChange: onPureInputChange,
	onBlur: onPureInputBlur,
	value = '',
	id = '',
	name = '',
	mask,
	description,
	placeholder,
	error = '',
	theme = 'light',
	isLabelUppercase = true,
	view,
	isRequired,
	readOnly,
	limit: limitProp,
	suffix,
	autoSelect = false,
	showErrorsOn = 'touched',
	type = 'text',
	...props
}: Props) => {
	const limit = { show: true, ...limitProp };
	const targetValue = input ? `${input.value === undefined ? '' : input.value}` : value;
	const inputId = meta && input ? `${meta.form}.${input.name}` : id || name;
	const inputValue = limit.max ? limitValue(targetValue, limit.max) : targetValue;
	const errorData = getErrorData({ meta, input, error, showErrorsOn });

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (props.disabled) return;

		if (input) {
			if (mask) input.onChange(maskValue(e.target.value, mask));
			else input.onChange(e.target.value);
		} else {
			onPureInputChange?.(e);
		}
	};

	return (
		<div
			className={cn(css.inputField, css[theme], className, {
				[css.error]: errorData.show,
				[css.readOnly]: readOnly,
				[css.hero]: view === View.hero,
				[css.limitIn]: limit.position === LimitPosition.in, // position of <CharsCount />
			})}
		>
			<label className={css.inputLabel} htmlFor={inputId}>
				{label ? (
					<Label
						theme={theme}
						className={cn(css.labelText, { [css.upper]: isLabelUppercase })}
						component="div"
					>
						{label}
						{!isRequired && <span>{t('form.optional')}</span>}
					</Label>
				) : null}
				<div className={css.inputW}>
					<input
						name={name}
						{...input}
						{...props}
						type={type}
						onFocus={event => {
							input?.onFocus(event);
							if (autoSelect) event.target.select();
						}}
						onBlur={event => {
							input?.onBlur(event);
							onPureInputBlur?.(event);
						}}
						onChange={onChange}
						value={inputValue}
						id={inputId}
						placeholder={placeholder}
					/>
					{suffix && <div className={css.suffix}>{suffix}</div>}
					{limit.show && limit?.max !== undefined && inputValue !== undefined && (
						<CharsCount max={limit.max} value={inputValue} className={cn(css.fieldCharsCount)} />
					)}
				</div>
				<Errors className={css.error} show={errorData.show}>
					{errorData.message}
				</Errors>
				{description && <span className={css.description}>{description}</span>}
			</label>
		</div>
	);
};

TextField.view = View;

TextField.limitPosition = LimitPosition;

TextField.mask = {
	card: INPUT_MASK.CARD,
	expDate: INPUT_MASK.EXP_DATE,
	workspaceName: INPUT_MASK.WS_NAME,
	number: INPUT_MASK.NUMBER,
};
