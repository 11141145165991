/**
 * Checks if `text` is longer than allowed `size` and cuts it to fit in, if true.
 * Otherwise, returns text as is.
 *
 * @param text Text to fit
 * @param size maximum allowed length
 * @param position where to place '...'
 * @returns string
 * @example
 * cutText('storycards', 5); // sto...ds
 */
export const fitTextToSize = (text: string = '', size: number, position: 'middle' | 'end' = 'middle') => {
	if (text.length <= size) {
		return text; // Return the original text if it's already within the size limit
	}

	// Subtract 3 to accommodate the '...'
	const maxLength = Math.max(1, size - 3);

	// Truncate the text based on the specified position
	if (position === 'middle') {
		const halfLength = Math.floor(maxLength / 2);
		const firstHalf = text.slice(0, halfLength);
		const secondHalf = text.slice(text.length - halfLength);
		return `${firstHalf}...${secondHalf}`;
	}

	return `${text.slice(0, maxLength)}...`;
};
