import { useEffect, useMemo, useState } from 'react';

const defaultOptions = {
	// image load will start only if isEnabled = true
	isEnabled: true,
	// image load will start only if initial size isn't provided or size <= 0
	initialSize: { width: 0, height: 0 },
};

const useImage = (
	url: string,
	{
		isEnabled = defaultOptions.isEnabled,
		initialSize = defaultOptions.initialSize,
	}: Partial<typeof defaultOptions> = defaultOptions
) => {
	const [size, setSize] = useState(initialSize);
	const [loaded, setLoaded] = useState(initialSize.width > 0 && initialSize.height > 0);
	const [failed, setFailed] = useState(false);
	const result = useMemo(() => ({ size, loaded, failed }), [size, loaded, failed]);

	useEffect(() => {
		if (loaded || !isEnabled) {
			return () => null;
		}

		if (!url) {
			setFailed(true);
			return () => null;
		}

		const img = new Image();

		const onLoad = (event: Event) => {
			if (event.target) {
				const { width, height } = event.target as HTMLImageElement;
				setSize({ height, width });
				setLoaded(true);
			}
		};
		img.onload = onLoad;

		const onError = () => {
			setLoaded(false);
			setFailed(true);
		};
		img.onerror = onError;

		img.src = url;

		return () => {
			img.removeEventListener('load', onLoad);
			img.removeEventListener('error', onError);
		};
	}, [url, isEnabled, loaded]);

	return result;
};

export default useImage;
