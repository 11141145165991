import React from 'react';

import { IOrganizationIntegrations } from 'src/types/organization';
import t from 'common/utils/translate';
import { queryStringKey } from 'common/utils/regexp';
import ParamsTable from 'admin/components/common/Intregrations/ParamsTable';
import { INTEGRATIONS_NAMES, INTEGRATIONS_SCOPE } from 'common/utils/facades/integrations-facade';
import { Icon } from 'admin/components/common/Icon';
import Button from 'admin/components/common/Button';
import { Hint, TextField } from 'admin/components/common/Form';
import css from './UrlParamsView.scss';

export type Params = Exclude<IOrganizationIntegrations['urlParams'], undefined>['params'];

type Props = {
	className?: string;
	currentParams: Params;
	onAddParamClick: (nextParams: Params) => void;
	onDeleteParamClick: (nextParams: Params) => void;
	scope: INTEGRATIONS_SCOPE;
};

const text = (path: string, options?: Record<string, string | number>) =>
	t(`common.integrations.views.${INTEGRATIONS_NAMES.URL_PARAMS}.${path}`, options);

const URL_PARAMS_LIMIT = 5;

/**
 * @description A ready component to render in both story or organization settings
 */
export const UrlParamsView: React.FC<Props> = props => {
	const { scope, className, currentParams = [] } = props;
	const theme = scope === INTEGRATIONS_SCOPE.STORY ? 'dark' : 'light';

	const [newParam, setNewParam] = React.useState('');
	const [error, setError] = React.useState<string>();
	const isDisabled = error !== undefined || !newParam || currentParams.length >= URL_PARAMS_LIMIT;

	const onParamNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const val = e.currentTarget.value;

		if (val.length > 0 && !queryStringKey.test(val)) {
			setError(text('fields.paramName.errors.invalid'));
		} else if (currentParams.includes(val)) {
			setError(text('fields.paramName.errors.alreadyExist'));
		} else if (error !== null /* reset error if it exists */) {
			setError(undefined);
		}

		setNewParam(e.currentTarget.value);
	};

	const onAddParamClick = () => {
		props.onAddParamClick([...currentParams, newParam]); // add
		setNewParam(''); // reset
	};

	const onDeleteParamBtnClick = (paramToDelete: string) => {
		props.onDeleteParamClick(currentParams.filter(param => param !== paramToDelete));
	};

	return (
		<div className={className}>
			<Hint text={text('subtitle', { limit: URL_PARAMS_LIMIT })} theme="dark" />

			{/* Inputs */}
			<div className={css.inputParam}>
				<TextField
					name="name"
					isRequired
					value={newParam}
					isLabelUppercase={false}
					error={error}
					theme={theme}
					label={text('fields.paramName.label')}
					onChange={onParamNameChange}
					autoComplete="off"
					onKeyDown={event => {
						if (!isDisabled && event.code === 'Enter') {
							onAddParamClick();
							// prevent form to submit
							event.preventDefault();
						}
					}}
				/>

				<Button
					view="empty"
					color="primary"
					shape="circle"
					size="small"
					style={{ marginTop: 34 }}
					disabled={isDisabled}
					onClick={onAddParamClick}
				>
					<Icon type="plus-rounded" color="currentColor" />
				</Button>
			</div>

			{/* Current params list */}
			<ParamsTable
				theme={theme}
				title={text('tableTitle')}
				hideHeader
				columns={[
					{ label: '', fieldKey: 'name' },
					{ label: '', fieldKey: 'action' },
				]}
				data={currentParams.map(param => ({
					name: param,
					action: (
						<Button
							view="empty"
							color="danger-ds"
							shape="circle"
							size="tiny"
							onClick={() => onDeleteParamBtnClick(param)}
						>
							<Icon type="minus-rounded" color="currentColor" />
						</Button>
					),
				}))}
			/>
		</div>
	);
};
