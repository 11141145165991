import { nanoid } from 'nanoid';
import opentype from 'opentype.js';
import { get, toLower, isString } from 'lodash';

import { UploadedFont } from 'types/fonts';
import { toast } from 'admin/components/common/MessageContainer/toast';

const getLongest = (arr: string[]) =>
	arr
		.filter(isString)
		.map(word => word.replace(/\.?\x7F/, ''))
		.filter(Boolean)
		.reduce((longest, currentWord) => {
			return currentWord.length > longest.length ? currentWord : longest;
		}, '');

export const getFontFileMetadata = (file: File): Promise<UploadedFont> => {
	return new Promise(resolve => {
		const reader = new FileReader();

		reader.onload = () => {
			try {
				const font = opentype.parse(reader.result, { lowMemory: true });
				const tables = font?.tables;
				const filename = file.name.toLowerCase();
				const ext = filename.split('.').pop() || 'unknown';

				resolve({
					file,
					id: nanoid(4),
					fontType: 'custom',
					size: file.size,
					fileType: ext,
					fontFamily:
						getLongest([
							get(tables, 'name.preferredFamily.en', ''), // old format
							get(tables, 'name.fontFamily.en', ''), // new format
						]) || get(tables, 'name.fullName.en', filename), // default fallback
					weight: get(tables, 'os2.usWeightClass', 400),
					style: toLower(get(tables, 'name.fontSubfamily.en', '')).includes('italic') ? 'Italic' : 'Normal',
				});
			} catch (err) {
				toast.error(`Failed to upload font: ${(err as Error).message}`);
				console.error(err);
			}
		};

		reader.readAsArrayBuffer(file);
	});
};
