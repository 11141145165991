/* eslint-disable react/no-danger, react/jsx-no-target-blank */
import * as React from 'react';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import { Field, SubmissionError } from 'redux-form';
import { connect, ConnectedProps } from 'react-redux';
import cn from 'classnames';
import { get, toLower } from 'lodash';

import { AdminReducerState } from 'admin/reducers';
import { selectUserInfo } from 'admin/reducers/user/selectors';

import { auth, init } from 'admin/actions';

import t from 'utils/translate';
import * as constants from 'admin/constants';
import { RESET_PASSWORD_PAGE } from 'admin/constants/routes';
import Button from 'admin/components/common/Button';
import { Column } from 'admin/components/common/Grid';
import { TextField } from 'admin/components/common/Form';
import Text from 'admin/components/common/Text';
import LoginLayout from 'admin/components/common/LoginLayout';
import { isMatching, QUERY } from 'admin/components/common/Responsive';
import { selectLoadingStateByName } from 'admin/reducers/loading/selectors';

import LoginForm, { LoginFormValues } from './LoginForm';
import TwoFACodeForm, { TwoFACodeFormValues } from './TwoFACodeForm';

import css from './Login.scss';

type Props = ConnectedProps<typeof connector> & RouteComponentProps;

type State = {
	isPasswordShown: boolean;
	isTwoFACoderRequired: boolean;
	email: string;
	password: string;
};

class Login extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			isPasswordShown: false,
			isTwoFACoderRequired: false,
			email: '',
			password: '',
		};
	}

	onLoginFormSubmit = async (values: LoginFormValues) => {
		let errorMessage = t('responseError.badRequest');
		const data = {
			email: toLower(values.email).trim(),
			password: values.password,
		};

		const response = await this.props.login(data);

		if (response.errors) {
			if (response.errors[0].message.includes('Invalid credentials')) {
				errorMessage = t('login.unauthorized');
			}

			throw new SubmissionError({ _error: errorMessage });
		} else if (response.result?.twofactor) {
			this.setState({
				isTwoFACoderRequired: true,
				email: values.email,
				password: values.password,
			});
		} else {
			await this.props.init(true);
		}
	};

	onTwoFACodeFormSubmit = async (values: TwoFACodeFormValues) => {
		let errorMessage = t('responseError.badRequest');
		const data = {
			email: this.state.email,
			password: this.state.password,
			code: values.code,
		};

		const response = await this.props.login(data);

		if (response.errors) {
			if (response.errors[0].message.includes('Invalid credentials')) {
				errorMessage = t('login.unauthorized');
			}

			throw new SubmissionError({ _error: errorMessage });
		} else {
			await this.props.init(true);
		}
	};

	onShowPsdClick = () => {
		this.setState(state => ({ isPasswordShown: !state.isPasswordShown }));
	};

	renderLoginForm = (): React.ReactNode => {
		const { isBusy } = this.props;
		const { isPasswordShown } = this.state;

		return (
			<>
				<div className={css.fields}>
					<Field
						className={css.emailFieldInput}
						name="email"
						component={TextField}
						isLabelUppercase={false}
						autoComplete="off"
						label={t('login.email.placeholder')}
						isRequired
					/>
					<div className={css.passwordField}>
						<Field
							name="password"
							component={TextField}
							isLabelUppercase={false}
							autoComplete="off"
							type={isPasswordShown ? 'text' : 'password'}
							label={t('login.password.placeholder')}
							isRequired
						/>
						<button className={css.showPasswordBtn} type="button" onClick={this.onShowPsdClick}>
							{isPasswordShown ? 'Hide' : 'Show'}
						</button>
					</div>
				</div>
				<div className={css.buttons}>
					<Button type="submit" loading={isBusy} disabled={isBusy}>
						{t('login.submit')}
					</Button>

					<Link to={RESET_PASSWORD_PAGE} className={cn(css.link, isBusy && css.disabled)}>
						<Text size={Text.size.label}>{t('login.forgotPassword')}</Text>
					</Link>
				</div>
			</>
		);
	};

	renderLoginContent = (): React.ReactNode => {
		const { submitError } = this.props;
		const isNoDesktop = isMatching(QUERY.NO_DESKTOP);

		return (
			<>
				<div className={css.hero}>
					<Text
						tag="h1"
						compact
						size={isNoDesktop ? Text.size.subheading : Text.size.heading}
						className={css.heroTitle}
					>
						{t('login.title')}
					</Text>
					{submitError && (
						<Text size={Text.size.label} className={css.heroError}>
							{submitError}
							<div className={css.errorIcon}>!</div>
						</Text>
					)}

					<LoginForm className={css.form} onSubmit={this.onLoginFormSubmit}>
						{this.renderLoginForm()}
					</LoginForm>

					<Column justifyContent="center">
						<Text size={Text.size.label}>
							Don’t have an account? <a href="https://storycards.com/pricing">Sign up</a>
						</Text>
					</Column>
				</div>
			</>
		);
	};

	renderTwoFACodeContent = (): React.ReactNode => {
		const { isBusy } = this.props;

		return (
			<>
				<div className={css.hero}>
					<div className={css.heroTitle}>
						<Text tag="h1" compact size={Text.size.heading}>
							{t('login.twoFactor.title')}
						</Text>
						<Text compact size={Text.size.label} className={css.heroSubtitle}>
							{t('login.twoFactor.subtitle')}
						</Text>
					</div>
					<TwoFACodeForm className={css.form} onSubmit={this.onTwoFACodeFormSubmit}>
						<div className={css.fields}>
							<Field
								name="code"
								component={TextField}
								isLabelUppercase={false}
								autoComplete="off"
								label={t('login.twoFactor.code')}
								isRequired
							/>
						</div>
						<div className={css.buttons}>
							<Button type="submit" loading={isBusy} disabled={isBusy}>
								{t('login.submit')}
							</Button>
						</div>
					</TwoFACodeForm>
					<Column justifyContent="center">
						<Text size={Text.size.label}>
							{t('login.twoFactor.needRecover')}{' '}
							<a href="https://storycards.com/recovery">{t('login.twoFactor.tryRecovery')}</a>
						</Text>
					</Column>
				</div>
			</>
		);
	};

	render() {
		const { location } = this.props;
		const { isTwoFACoderRequired } = this.state;
		const from = get(location, 'state.from', { pathname: constants.routes.ROOT });

		if (this.props.userInfo.email && !this.props.isBusy) {
			return <Redirect to={from} />;
		}

		return (
			<LoginLayout>
				{isTwoFACoderRequired === false ? this.renderLoginContent() : this.renderTwoFACodeContent()}
			</LoginLayout>
		);
	}
}

const mapState = (state: AdminReducerState) => ({
	isBusy: selectLoadingStateByName(state, constants.actions.INIT.PENDING),
	submitError: state.form[`${constants.common.FORM_MODEL.LOGIN}`]?.error || '',
	userInfo: selectUserInfo(state),
});

const mapDispatch = {
	login: auth.post.login,
	init,
};

const connector = connect(mapState, mapDispatch);

export default connector(Login);
