import React from 'react';
import cn from 'classnames';
import { Popconfirm, PopconfirmProps } from 'antd';
import css from './PopConfirm.scss';

interface Props extends PopconfirmProps {
	theme?: 'light';
}

const PopConfirm = ({ theme = 'light', okText = 'Yes', cancelText = 'No', ...props }: Props) => (
	<Popconfirm
		destroyTooltipOnHide
		okText={okText}
		cancelText={cancelText}
		{...props}
		overlayClassName={cn(props.overlayClassName, css[theme])}
	/>
);

export default PopConfirm;
