import _ from 'lodash';
import React, { useState } from 'react';
import { Field, setSubmitFailed } from 'redux-form';
import { useDidMount } from 'common/components/useDidMount';
import type { AdminThunkDispatch } from 'admin/reducers';
import { Column } from 'admin/components/common/Grid';
import Button from 'admin/components/common/Button';
import Text from 'admin/components/common/Text';
import { TextField } from 'admin/components/common/Form';
import { AI_PROMPT_MAX_LEN, STORY_NAME_MAX_LEN } from 'admin/components/common/Form/validations';
import t from 'utils/translate';
import { fields } from './Form';

const showErrorsOn = {
	name: (meta, input) => meta.submitFailed && !meta.active,
};

const randomName = () => {
	const names = [
		'exciting',
		'thrilling',
		'impressive',
		'breathtaking',
		'fascinating',
		'notable',
		'phenomenal',
		'surprising',
		'wonderful',
		'unique',
	];
	return names[_.random(names.length - 1)];
};

type MainViewProps = {
	username?: string;
	onOk: () => Promise<void>;
	dispatch: AdminThunkDispatch;
	syncErrors?: { name?: string };
	form: string;
	isGenericContent: boolean;
};

const TemplateName: React.FC<MainViewProps> = props => {
	const nameInputWrapRef = React.createRef<HTMLInputElement>();
	const [placeholder] = useState(`${props.username ? `${props.username}'s` : 'Your'} ${randomName()} story`);

	useDidMount(() => {
		if (nameInputWrapRef.current) {
			nameInputWrapRef.current.querySelector('input')?.focus();
		}
	});

	return (
		<div style={{ width: 800, textAlign: 'center' }}>
			<Field
				name={fields.name}
				component={TextField}
				label={
					props.isGenericContent ? 'Create story about...' : t(`createStoryModal.fields.${fields.name}.label`)
				}
				autoSelect
				showErrorsOn={showErrorsOn.name}
				autoComplete="off"
				limit={{ max: props.isGenericContent ? AI_PROMPT_MAX_LEN : STORY_NAME_MAX_LEN }}
				placeholder={props.isGenericContent ? 'What is the story about?' : placeholder}
				isLabelUppercase={false}
				isRequired
				view={TextField.view.hero}
			/>
			<br />
			<Column justifyContent="center">
				<Button
					type="submit"
					onClick={() => {
						if (props?.syncErrors?.[fields.name]) {
							props.dispatch(setSubmitFailed(props.form, fields.name));
						} else {
							props.onOk();
						}
					}}
					textSize={Text.size.label}
				>
					{t(props.isGenericContent ? 'createStoryModal.generate' : 'createStoryModal.create')}
				</Button>
			</Column>
		</div>
	);
};

export default TemplateName;
