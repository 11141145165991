import React, { useEffect, useState } from 'react';

import Text from 'admin/components/common/Text';
import { TextField } from 'admin/components/common/Form';

import t from 'utils/translate';

import { ViewProps } from './types';

import css from './MediaGalleryModal.scss';

const supportedVideoPlatformUrls = [
	'https://www.youtube.com/watch?v=video_id',
	'https://www.youtube.com/embed/video_id',
	'http://youtu.be/video_id',
	'https://vimeo.com/0000000',
	'https://player.vimeo.com/video/0000000',
	'https://player.vimeo.com/external/000000000.hd.mp4?s=000&profile_id=000',
];

const FromURLView: React.FC<ViewProps> = ({ setValue, isValueValid, currentValue, fromUrlType = 'videoUrl' }) => {
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		setValue?.(null);
		setError(null);
		/**
		 * each view represents ability to select value by different way
		 * so when user open the view we need to reset selected value from
		 * the previous visited view
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fromUrlType]);

	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValue?.({ src: e.target.value });
		setError(null);
	};

	return (
		<div className={css.fromUrl}>
			<div className={css.title}>
				<Text compact size={Text.size.subheading} weight={Text.weight.semibold} tag="h4">
					{t(`story.mediaGalleryModal.${fromUrlType}.title`)}
				</Text>
			</div>

			{fromUrlType === 'videoPlatform' ? (
				<div className={css.notes}>
					<Text compact size={Text.size.description}>
						{t(`story.mediaGalleryModal.${fromUrlType}.noteInfoHeader`)}
					</Text>
					<ul>
						{supportedVideoPlatformUrls.map(link => (
							<li key={link}>
								<Text compact size={Text.size.description}>
									{link}
								</Text>
							</li>
						))}
					</ul>
				</div>
			) : (
				<div className={css.notes}>
					<Text compact size={Text.size.description}>
						{t(`story.mediaGalleryModal.${fromUrlType}.noteInfoHeader`)}
					</Text>
					<ul>
						<li>
							<Text compact size={Text.size.description}>
								{t(`story.mediaGalleryModal.${fromUrlType}.noteListItem`)}
							</Text>
						</li>
						<li>
							<Text compact size={Text.size.description}>
								{t(`story.mediaGalleryModal.${fromUrlType}.noteListItem2`)}
							</Text>
						</li>
					</ul>
					<Text compact size={Text.size.description}>
						{t(`story.mediaGalleryModal.${fromUrlType}.noteInfoFooter`)}
					</Text>
				</div>
			)}

			<TextField
				value={currentValue?.src}
				theme="dark"
				onChange={onInputChange}
				label={t(`story.mediaGalleryModal.${fromUrlType}.inputLabel`)}
				isLabelUppercase={false}
				isRequired
				onBlur={e => {
					const { value } = e.target;

					if (value === '') setError(t(`story.mediaGalleryModal.${fromUrlType}.required`));
					else if (!isValueValid) setError(t(`story.mediaGalleryModal.${fromUrlType}.validHLSUrlError`));
				}}
				error={error || ''}
			/>
		</div>
	);
};

export default FromURLView;
