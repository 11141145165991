import React, { useState } from 'react';
import { useAdminDispatch } from 'admin/reducers';
import Button from 'admin/components/common/Button';
import { Icon } from 'admin/components/common/Icon';
import { THEME } from 'admin/components/common/Theme';
import { Column, Grid } from 'admin/components/common/Grid';
import { Select, TextField } from 'admin/components/common/Form';
import { putSpreadsheetRange, SpreadsheetDocument } from 'admin/actions/integrations';
import css from './Spreadsheet.scss';

type Props = {
	theme: ValuesType<typeof THEME>;
	integrationId: string;
	storySheetIntegrationId: string;
	spreadsheet: SpreadsheetDocument;
	onSave: () => Promise<unknown>;
	sheetOptions: { value: number; label: string }[];
	validateName: (name: string) => string | undefined;
	validateValue: (value: string) => string | undefined;
};

const RangeEdit: React.FC<Props> = props => {
	const dispatch = useAdminDispatch();
	const [name, setName] = useState('');
	const [value, setValue] = useState('');
	const [isBusy, setIsBusy] = useState(false);
	const [sheet, setSheet] = useState(props.spreadsheet.sheets?.[0]?.properties);

	const nameError = !name ? undefined : props.validateName(name);
	const isDisabled = isBusy || !sheet || !value || !name || nameError !== undefined;

	const onSheetChange = (sheetId: number) => {
		const nextSheet = props.spreadsheet.sheets.find(s => s.properties.sheetId === sheetId);
		if (nextSheet) setSheet(nextSheet.properties);
	};

	const onAddClick = async () => {
		setIsBusy(true);

		const response = await dispatch(
			putSpreadsheetRange({
				integrationId: props.integrationId,
				spreadsheetId: props.storySheetIntegrationId,
				data: {
					range: { sheetId: sheet.sheetId, sheetName: sheet.title, label: name, range: value.toUpperCase() },
				},
			})
		);

		if (response.success) {
			setName('');
			setValue('');

			props.onSave();
		}

		setIsBusy(false);
	};

	const onEnterClick = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter' && !isDisabled) {
			event.preventDefault();
			onAddClick();
		}
	};

	return (
		<Grid columns="14" columnGap={Grid.gap.small} className={css.range}>
			<Column colSpan="6">
				<TextField
					name="name"
					autoComplete="off"
					isLabelUppercase={false}
					theme={props.theme}
					isRequired
					type="text"
					placeholder="Add name"
					value={name}
					onChange={e => setName(e.target.value)}
					onKeyDown={onEnterClick}
					error={nameError}
				/>
			</Column>

			<Column colSpan="3">
				<TextField
					name="value"
					autoComplete="off"
					isLabelUppercase={false}
					theme={props.theme}
					isRequired
					type="text"
					placeholder="A1"
					value={value}
					onChange={e => setValue(e.target.value)}
					onKeyDown={onEnterClick}
					error={value ? props.validateValue(value) : undefined}
				/>
			</Column>

			<Column colSpan="4">
				<Select
					theme={props.theme}
					options={props.sheetOptions}
					value={sheet?.sheetId}
					placeholder="Select"
					eventListeners={{ onChange: onSheetChange }}
				/>
			</Column>

			<Column colSpan="1" alignItems="center" className={css.rangeActions}>
				<Button
					view="empty"
					color="primary"
					shape="circle"
					size="tiny"
					loading={isBusy}
					onClick={onAddClick}
					disabled={isDisabled}
				>
					<Icon type="plus-rounded" color="currentColor" />
				</Button>
			</Column>
		</Grid>
	);
};

export default RangeEdit;
