/* eslint-disable no-useless-escape,react/default-props-match-prop-types */

import React from 'react';
import classNames from 'classnames';

import t from 'utils/translate';

import css from './PasswordFieldChecker.scss';

const VIEW = {
	VERTICAL: 'vertical',
	HORIZONTAL: 'horizontal',
} as const;

const defaultProps = {
	className: '',
	value: '',
	view: VIEW.HORIZONTAL,
};

type Props = {
	className?: string;
	value?: string;
	view?: (typeof VIEW)[keyof typeof VIEW];
};

const PasswordFieldChecker: React.FC<Props> = ({
	className = '',
	value = '',
	view = VIEW.HORIZONTAL,
}: React.ComponentProps<typeof PasswordFieldChecker>) => {
	const patterns = {
		lower: /[a-z]/,
		upper: /[A-Z]/,
		special: /[#?!@$%^&*+/\[\]";:><,.-]/,
		number: /[0-9]/,
		count: /.{8,}/,
	};

	return (
		<div className={classNames(css.checkPassField, css[view], className)}>
			<div className={css.checkPassFieldCol}>
				<div
					className={classNames(css.checkPassFieldItem, {
						[css.active]: patterns.lower.test(value),
					})}
				>
					{t('accountSetup.checkPassField.lower')}
				</div>
				<div
					className={classNames(css.checkPassFieldItem, {
						[css.active]: patterns.upper.test(value),
					})}
				>
					{t('accountSetup.checkPassField.upper')}
				</div>
				<div
					className={classNames(css.checkPassFieldItem, {
						[css.active]: patterns.number.test(value),
					})}
				>
					{t('accountSetup.checkPassField.number')}
				</div>
			</div>
			<div className={css.checkPassFieldCol}>
				<div
					className={classNames(css.checkPassFieldItem, {
						[css.active]: patterns.special.test(value),
					})}
				>
					{t('accountSetup.checkPassField.special')}
				</div>
				<div
					className={classNames(css.checkPassFieldItem, {
						[css.active]: patterns.count.test(value),
					})}
				>
					{t('accountSetup.checkPassField.count')}
				</div>
			</div>
		</div>
	);
};

PasswordFieldChecker.defaultProps = defaultProps;

export default PasswordFieldChecker;
