import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import { videos } from 'admin/resources';
import { useAdminSelector } from 'admin/reducers';
import { selectActiveOrganizationId } from 'admin/reducers/user/selectors';

import { toast } from 'admin/components/common/MessageContainer/toast';

import { GetVideoOriginalFileLinkParams, GetVideoOriginalFileLinkResult } from 'src/types';

import { QUERY_KEYS } from '../constants';

const MINUTE = 1000 * 60;

export const useVideoOriginalFileLink = (params: Omit<GetVideoOriginalFileLinkParams, 'organizationId'>) => {
	const organizationId = useAdminSelector(selectActiveOrganizationId);
	const { videoId } = params;
	const { data, isLoading, isFetching, error } = useQuery({
		queryKey: [QUERY_KEYS.VIDEO_ORIGINAL_FILE_LINK, organizationId, videoId],
		queryFn: async () => {
			const response = await videos.getOriginalFileLink.params({ organizationId, videoId }).send();

			return {
				success: true,
				result: { ...response.body },
			} as IBackendBody<GetVideoOriginalFileLinkResult>;
		},
		enabled: Boolean(videoId),
		staleTime: Infinity,
		gcTime: 2 * 60 * 60 * 1000, // 2h
		refetchInterval: 55 * MINUTE,
	});

	useEffect(() => {
		if (error) {
			const errorData = JSON.parse((error as Error).message ?? '{}');
			toast.error(errorData.message || (error as Error).message, 3);
		}
	}, [error]);

	return { data: data?.result, isLoading, isFetching, error };
};
