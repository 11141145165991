import React, { useContext, useState, useCallback, useRef } from 'react';
import cn from 'classnames';

import { MODAL } from 'admin/constants/common';
import Button from 'admin/components/common/Button';
import { StoryFacade } from 'common/utils/facades/story-facade';
import { useWillUnmount } from 'common/components/useWillUnmount';
import { Icon } from 'admin/components/common/Icon';
import { Label } from 'admin/components/common/Form/Label';
import { processStory } from 'admin/actions/story/get-story';
import { CheckboxPure } from 'admin/components/common/Form/Checkbox';
import { TextAreaPure } from 'admin/components/common/Form/TextArea';
import { useAdminDispatch, useAdminSelector } from 'admin/reducers';
import { selectCurrentVersion } from 'admin/reducers/version-reducer';
import { ModalBody } from 'admin/components/common/Modal';
import { Context as ThemeContext } from 'admin/components/common/Theme';
import AdminError from 'admin/components/common/ErrorBoundary/AdminError';
import { generateCardContent } from 'admin/actions/story/ai/generate-story-content';
import { updateEditableCardData } from 'admin/actions/card-editor/update-editable-card-data';
import {
	selectEditableCardId,
	selectCardEditor,
	selectCurrentPlatform,
	selectCurrentState,
} from 'admin/reducers/card-editor/selectors';
import { injectGeneratedContent } from 'admin/actions/story/ai/inject-generated-story-content';
import { selectStoryGenerator, selectEditableStory } from 'admin/reducers/story-editor/selectors';
import { getMergeOfEditableStoryWithCardEditor } from 'admin/actions/card-editor/card-editor-sync';
import CardEditorIframeTransmitter from 'admin/components/pages/Story/CardEditor/IframeTransmitter';
import type { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';
import PromptHistory from './PromptHistory';
import css from '../EditorAIModal.scss';

export type GeneratorCardModalData = {
	view: 'card';
};

const text = {
	newPromptBtn: 'Generate',
	oldPromptBtn: 'Regenerate',
	promptPh: 'Type your prompt or paste a URL',
	errorMsg: 'Failed to generate card content.',
	changeView: 'Use a different prompt',
};

const generatorSelectOptions = [
	// { label: 'Text', value: 'text' as const }, // text is generated always at backend
	{ label: 'Image', value: 'image' as const },
];

const generatorOptionsRecord = Object.fromEntries(generatorSelectOptions.map(o => [o.value, true])) as Record<
	ArrayType<typeof generatorSelectOptions>['value'],
	boolean
>;

const useUpdatedAt = (updatedAt: string) => {
	const updatedAtRef = useRef({ prev: updatedAt, next: updatedAt });
	if (updatedAtRef.current.prev !== updatedAt) {
		updatedAtRef.current.next = updatedAt;
	}
	return updatedAtRef.current;
};

type Props = ModalManagerProvidedProps<MODAL.EDITOR_AI> & {
	setIsBusy: React.Dispatch<React.SetStateAction<boolean>>;
	setLoadingStatus: React.Dispatch<React.SetStateAction<string | undefined>>;
};

enum View {
	oldPrompt,
	newPrompt,
}

const GenerateCardContent: React.FC<Props> = props => {
	const dispatch = useAdminDispatch();
	const generatorId = useAdminSelector(selectStoryGenerator)?.id;
	const editableStory = useAdminSelector(selectEditableStory)!;
	const cardId = useAdminSelector(selectEditableCardId)!;
	const cardEditor = useAdminSelector(selectCardEditor);
	const version = useAdminSelector(selectCurrentVersion);
	const state = useAdminSelector(selectCurrentState);
	const platform = useAdminSelector(selectCurrentPlatform);

	const { theme } = useContext(ThemeContext);
	const [userPrompt, setUserPrompt] = useState('');
	const [view, setView] = useState(generatorId ? View.oldPrompt : View.newPrompt);
	const [generatorOptions, setGeneratorOptions] = useState(generatorOptionsRecord);
	const promptInputRef = useRef<HTMLTextAreaElement | null>(null);
	const intervalRef = useRef<ReturnType<typeof setInterval>>();
	const updatedAt = useUpdatedAt(new StoryFacade(editableStory).updatedAt);

	const onSubmit = async () => {
		props.setIsBusy(true);

		// Retrieve the latest story data, including changes from the card editor
		const mergedStory = getMergeOfEditableStoryWithCardEditor(editableStory, cardEditor, version);

		// Process "story" data to obtain "story" settings, including updated information about elements
		// and their parameters. This data will serve as the foundation for generating the content of the "card"
		const mergedProcessedStory = processStory(new StoryFacade(mergedStory), { parseSystemFontsUsage: false });

		// update card editor + POST latest story
		await dispatch(
			updateEditableCardData({
				type: 'full',
				value: { storySettings: mergedProcessedStory.settings },
				autoSyncImmediate: true,
			})
		);

		const updateCard = async () => {
			const generatedContent = await dispatch(
				generateCardContent({
					prompt: view === View.oldPrompt ? undefined : userPrompt,
					onStatusChange: props.setLoadingStatus,
					replaceImages: generatorOptions.image,
				})
			);

			if (!generatedContent.success || !generatedContent.result) {
				props.close();
				return;
			}

			const regeneratedStory = injectGeneratedContent({
				story: {
					name: mergedStory.name,
					type: mergedStory.type!,
					language: mergedStory.language,
					tags: mergedStory.tags,
					slug: mergedStory.clientStoryId.split('/')[1],

					storyData: mergedProcessedStory.data,
					storySettings: mergedProcessedStory.settings,
				},
				content: generatedContent.result,
				config: { cards: [cardId] },
				state,
				platform,
			});

			const regeneratedCard = StoryFacade.findCardInSteps(cardId, regeneratedStory.storyData.steps)?.card;

			if (!regeneratedCard) {
				throw new AdminError(text.errorMsg);
			}

			CardEditorIframeTransmitter.transmitStoryElementsToClient({
				elements: regeneratedStory.storyData.elements,
				editableState: cardEditor.state,
			});

			CardEditorIframeTransmitter.transmitCardDataToClient({
				data: regeneratedCard,
				symbols: regeneratedStory.storyData.symbols,
				editableState: cardEditor.state,
			});

			await dispatch(
				updateEditableCardData({
					type: 'full',
					value: {
						storySettings: regeneratedStory.storySettings,
						storyElements: regeneratedStory.storyData.elements,
						symbols: regeneratedStory.storyData.symbols,
						data: regeneratedCard,
					},
				})
			);
		};

		// Execute a recurring check on the 'updatedAt' value to determine
		// if the latest story version has been successfully updated
		intervalRef.current = setInterval(() => {
			if (updatedAt.prev !== updatedAt.next) {
				clearInterval(intervalRef.current);
				updateCard().then(props.close).catch(props.close);
			}
		}, 1000);
	};

	const onCheckboxChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const option = event.target.value;
		setGeneratorOptions(prevState => ({ ...prevState, [option]: !prevState[option] }));
	}, []);

	useWillUnmount(() => {
		clearInterval(intervalRef.current);
	});

	return (
		<ModalBody>
			<div className={cn(css.fields, css[`view-${view}`])}>
				<TextAreaPure
					placeholder={text.promptPh}
					rows={3}
					theme={theme}
					autoFocus
					value={userPrompt}
					onChange={e => setUserPrompt(e.target.value)}
					ref={promptInputRef}
				/>
				<div className={css.fieldsFooter}>
					<div className={css.fieldsInclude}>
						<Label theme={theme}>Include</Label>
						{generatorSelectOptions.map(option => (
							<CheckboxPure
								key={`include-option-${option.value}`}
								boxSize={24}
								theme={theme}
								labelPosition="right"
								label={{ children: option.label, weight: 'normal' }}
								value={option.value}
								checked={generatorOptions[option.value]}
								onChange={onCheckboxChange}
							/>
						))}
					</div>
					<Button
						className={css.btnSubmit}
						onClick={onSubmit}
						view="primary"
						theme={theme}
						disabled={view === View.newPrompt && userPrompt.trim().length < 2}
					>
						<Icon type="sc-magic" width={26} style={{ marginRight: 6 }} />
						{view === View.newPrompt ? text.newPromptBtn : text.oldPromptBtn}
					</Button>
				</div>
			</div>
			{view === View.oldPrompt && (
				<Button
					view="empty"
					size="small"
					textSize="label"
					theme={theme}
					onClick={() => {
						setView(View.newPrompt);
						promptInputRef.current?.focus();
					}}
					style={{ padding: 0 }}
				>
					<Icon type="sc-rotate-right-thick" width={13} style={{ marginRight: 6 }} color="currentColor" />
					{text.changeView}
				</Button>
			)}
			{view === View.newPrompt && <PromptHistory type="card" onSelect={setUserPrompt} />}
		</ModalBody>
	);
};

export default GenerateCardContent;
