import { QueryClient } from '@tanstack/react-query';

const MINUTE = 1000 * 60;

export default new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 1 * MINUTE,
			gcTime: 5 * MINUTE,
			refetchOnWindowFocus: false,
		},
	},
});
