/* eslint-disable react/no-danger */
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';
import { Field, SubmissionError } from 'redux-form';
import _ from 'lodash';

import t from 'utils/translate';
import { parseURL } from 'utils/parse-url';
import type { AdminReducerState } from 'admin/reducers';
import { selectLoadingStateByName } from 'admin/reducers/loading/selectors';

import { restorePassword } from 'admin/actions';
import * as constants from 'admin/constants';
import { LOGIN_PAGE } from 'admin/constants/routes';
import Button from 'admin/components/common/Button';
import Text from 'admin/components/common/Text';
import { TextField } from 'admin/components/common/Form';
import PasswordFieldChecker from 'admin/components/common/PasswordFieldChecker';
import { isMatching, QUERY } from 'admin/components/common/Responsive';
import LoginLayout from 'admin/components/common/LoginLayout';

import Form, { RestorePasswordValues } from './Form';

import css from './RestorePassword.scss';

type Props = ConnectedProps<typeof connector> & RouteComponentProps;

type State = {
	isPasswordShown: boolean;
};

class RestorePaswword extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			isPasswordShown: false,
		};
	}

	get urlSearchObject() {
		return parseURL(this.props.location.search).searchObject;
	}

	onSubmit = async (values: RestorePasswordValues) => {
		const email = decodeURIComponent(_.get(this.urlSearchObject, 'email', ''));
		const token = _.get(this.urlSearchObject, 'token', '');
		const { history } = this.props;
		const data = {
			token,
			email,
			password: values.password,
		};

		const result = await this.props.restorePassword(data);

		if (result.errors) {
			throw new SubmissionError({ _error: result.errors[0]?.message });
		} else if (result.success) {
			history.push(LOGIN_PAGE);
		}
	};

	onShowPsdClick = () => {
		this.setState(state => ({ isPasswordShown: !state.isPasswordShown }));
	};

	renderForm = () => {
		const { isBusy } = this.props;
		const { formData } = this.props;
		const { isPasswordShown } = this.state;

		return (
			<>
				<div className={css.fields}>
					<div className={css.passwordField}>
						<Field
							name="password"
							component={TextField}
							autoComplete="off"
							type={isPasswordShown ? 'text' : 'password'}
							label={t('restorePassword.password.placeholder')}
							isLabelUppercase={false}
							isRequired
						/>
						<button className={css.showPasswordBtn} type="button" onClick={this.onShowPsdClick}>
							{isPasswordShown ? 'Hide' : 'Show'}
						</button>
					</div>
				</div>
				<PasswordFieldChecker value={_.get(formData, 'password', '')} className={css.checkPass} />
				<Button type="submit" disabled={isBusy}>
					{t('restorePassword.submit')}
				</Button>
			</>
		);
	};

	render() {
		const { submitError } = this.props;
		const isNoDesktop = isMatching(QUERY.NO_DESKTOP);

		return (
			<LoginLayout>
				<div className={css.hero}>
					<Text
						size={isNoDesktop ? Text.size.subheading : Text.size.heading}
						tag="h1"
						compact
						className={css.heroTitle}
					>
						{t('restorePassword.title')}
					</Text>

					{submitError && (
						<Text size={Text.size.label} className={css.heroError}>
							{submitError}
							<div className={css.errorIcon}>!</div>
						</Text>
					)}

					<Form className={css.form} onSubmit={this.onSubmit}>
						{this.renderForm()}
					</Form>
				</div>
			</LoginLayout>
		);
	}
}

const mapState = (state: AdminReducerState) => ({
	isBusy: selectLoadingStateByName(state, constants.actions.SETUP_INVITE_TO_ORGANIZATION.PENDING),
	formData: state.form[`${constants.common.FORM_MODEL.RESTORE_PASSWORD}`]?.values || {},
	submitError: state.form[`${constants.common.FORM_MODEL.RESTORE_PASSWORD}`]?.error || '',
});

const mapDispatch = {
	restorePassword,
};

const connector = connect(mapState, mapDispatch);

export default connector(RestorePaswword);
