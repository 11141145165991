import { set, get } from 'lodash';
import React, { ReactNode, useEffect } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { CardData, StorySettingsType } from 'types/story';
import t from 'common/utils/translate';
import { FORM_MODEL } from 'admin/constants/common';
import MediaField from 'admin/components/common/Form/MediaField';
import { names } from './utils';

export interface Values {
	name: CardData['name'];
	settings: { cards: StorySettingsType['cards'] };
	_setScoreForAll: boolean; // "_" means that this value won't be stored, used only internally
}

interface OwnProps {
	// "cardId" prop is used at reduxForm.validate
	// eslint-disable-next-line react/no-unused-prop-types
	cardId: CardData['_id'];
	// "cardType" prop is used at reduxForm.validate
	// eslint-disable-next-line react/no-unused-prop-types
	cardType: CardData['type'];
	children: (props: Props) => ReactNode;
	onSubmit: (values: Values) => void;
	setError: (error: boolean) => void;
}

export interface Props extends OwnProps, InjectedFormProps<Values, OwnProps> {}

const Form = (props: Props) => {
	const { setError, invalid } = props;

	useEffect(() => {
		setError(invalid);
	}, [invalid, setError]);

	return (
		<form autoComplete="off" onSubmit={props.handleSubmit(props.onSubmit)}>
			{props.children(props)}
		</form>
	);
};

export default reduxForm<Values, OwnProps>({
	form: FORM_MODEL.EDIT_CARD_SETTINGS,
	destroyOnUnmount: true,
	validate: (values, props) => {
		const errors: Record<string, string> = {};

		if (!values.name) {
			errors[names.name] = t(`story.createCardModal.fields.${names.name}.required`);
		}

		const shareImg = {
			path: names.shareImage(props.cardId),
			value: get(values, names.shareImage(props.cardId)),
		};

		if (shareImg.value === MediaField.errors.FILE_SIZE_LIMIT) {
			set(errors, shareImg.path, MediaField.errorsMsg[MediaField.errors.FILE_SIZE_LIMIT]);
		} else if (shareImg.value === MediaField.errors.FILE_WIDTH_HEIGHT_LARGE) {
			set(errors, shareImg.path, MediaField.errorsMsg[MediaField.errors.FILE_WIDTH_HEIGHT_LARGE]);
		} else if (shareImg.value === MediaField.errors.FILE_WIDTH_HEIGHT_SMALL) {
			set(errors, shareImg.path, MediaField.errorsMsg[MediaField.errors.FILE_WIDTH_HEIGHT_SMALL]);
		} else if (shareImg.value === MediaField.errors.UPLOAD_FAILED) {
			set(errors, shareImg.path, MediaField.errorsMsg[MediaField.errors.UPLOAD_FAILED]);
		}

		return errors;
	},
})(Form);
