import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import _, { find, omit } from 'lodash';
import produce from 'immer';
import cn from 'classnames';

import { AdminReducerState } from 'admin/reducers';
import { SCFontItem, GoogleFontItem } from 'src/types';

import t from 'utils/translate';
import Text from 'admin/components/common/Text';

import { Modal } from 'admin/components/common/Modal';
import { toast } from 'admin/components/common/MessageContainer/toast';
import { postOrganizationGallery } from 'admin/actions/organization/organization';
import { selectActiveOrganization } from 'admin/reducers/user/selectors';
import { selectLoadingStateByName } from 'admin/reducers/loading/selectors';
import { updateOrganizationSettings, getOrganizationGoogleFonts } from 'admin/actions';
import { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';
import { useDidMount } from 'common/components/useDidMount';
import { Search } from 'admin/components/common/Search';
import { VirtualizedList } from 'admin/components/common/VirtualizedList';
import { LOGIN, UPDATE_ORGANIZATION_SETTINGS } from 'admin/constants/actions';
import { MODAL } from 'admin/constants/common';

import { GoogleFontListItem } from './GoogleFontListItem';
import { CandidateGoogleFont } from './CandidateGoogleFont';
import { OnUploadParams, UploadCustomFontBtn } from './UploadCustomFontBtn';
import { UploadedFontsList } from './UploadedFontsList';
import { initialState, reducer } from './reducer';

import css from './AddFontsModal.scss';

type Props = ModalManagerProvidedProps<MODAL.ADD_FONTS> & ConnectedProps<typeof connector>;

const AddFontsModal: React.FC<Props> = props => {
	const [state, dispatch] = React.useReducer(reducer, initialState);
	const { googleFontsList, view, uploadedFonts, currentGFCandidate, search } = state;
	const { open, isOrgSettingsUpdating, activeOrganization } = props;
	const organizationFonts = activeOrganization?.metadata?.fonts;
	const targetGFList = React.useMemo(() => {
		const val = _.toLower(search);
		return _.filter(googleFontsList, gFont => _.toLower(gFont.family).includes(val));
	}, [search, googleFontsList]);

	useDidMount(() => {
		async function getFontsList() {
			const result = await props.getOrganizationGoogleFonts();
			const gfList = result.result?.items;

			if (result?.success && gfList) {
				dispatch({ type: 'set-gf-list', payload: gfList });
			}
		}

		getFontsList();
	});

	const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch({ type: 'set-search', payload: e.target.value });
	};

	const onSearchClear = () => {
		dispatch({ type: 'set-search', payload: '' });
	};

	const onAfterClose = () => {
		dispatch({ type: 'set-view', payload: 'google-font-list' });
	};

	const onGoogleFontItemClick = (data: GoogleFontItem) => {
		dispatch({ type: 'set-gf-candidate', payload: data });
		dispatch({ type: 'set-view', payload: 'candidate-google-font' });
	};

	const onCandidateGFCancelClick = () => {
		dispatch({ type: 'set-gf-candidate', payload: null });
		dispatch({ type: 'set-view', payload: 'google-font-list' });
	};

	const onUploadedFontsListCancelClick = () => {
		dispatch({ type: 'set-uploaded-fonts', payload: { error: null, items: null } });
		dispatch({ type: 'set-view', payload: 'google-font-list' });
	};

	const onCustomFontsUploaded = (payload: OnUploadParams) => {
		dispatch({ type: 'set-uploaded-fonts', payload });
		dispatch({ type: 'set-view', payload: 'custom-fonts' });
	};

	const onSaveUploadedFonts = async (data: typeof uploadedFonts) => {
		const validateName = {
			required: v => !v?.trim(),
			isExists: v => find(organizationFonts, ['fontFamily', v]),
		};

		let error: typeof uploadedFonts.error = null;
		const promises: ReturnType<Props['postOrganizationGallery']>[] = [];

		data.items?.forEach(font => {
			if (validateName.required(font.fontFamily)) {
				error = { msg: 'Name is required', id: font.id };
			} else if (validateName.isExists(font.fontFamily)) {
				error = { msg: 'Name is already in use', id: font.id };
			}

			promises.push(props.postOrganizationGallery({ asset: font.file, organizationId: activeOrganization!.id }));
		});

		dispatch({ type: 'set-uploaded-fonts', payload: { ...data, error } });

		if (error) return;

		try {
			const response = await Promise.all(promises);
			const items = response.map((r, i) => {
				if (r instanceof Error) {
					throw new Error(r.message);
				}

				if (!r.result?.asset) {
					throw new Error('Upload error: "missing asset"');
				}

				const font = data.items![i];
				return {
					...omit(font, ['file']),
					fontFamily: _.trim(font.fontFamily.replace(/ +(?= )/g, '')),
					url: r.result.asset,
				};
			});

			await handleSave(items);
		} catch (e) {
			dispatch({ type: 'set-uploaded-fonts', payload: { ...data, error: { msg: (e as any).message } } });
		}
	};

	const handleSave = async (fonts: SCFontItem | SCFontItem[]) => {
		const orgFonts = activeOrganization?.metadata?.fonts;

		const metadata = produce(activeOrganization?.metadata ?? {}, draft => {
			(Array.isArray(fonts) ? fonts : [fonts]).forEach((font, index) => {
				const targetOrgFont = _.find(orgFonts, f => f.fontFamily === font.fontFamily);
				const id = targetOrgFont ? targetOrgFont.id : font.id;

				_.set(draft, ['fonts', id], { ...font, id });
			});
		});

		const response = await props.updateOrganizationSettings({
			data: {
				name: activeOrganization?.name as string,
				metadata: metadata || {},
			},
		});

		if (response.success) {
			dispatch({ type: 'set-view', payload: 'google-font-list' });
			toast.success('Success', 1.5);
		}
	};

	const renderContent = () => {
		switch (view) {
			case 'candidate-google-font':
				return (
					currentGFCandidate && (
						<CandidateGoogleFont
							onSave={handleSave}
							onCancel={onCandidateGFCancelClick}
							fontData={currentGFCandidate}
							isBusy={isOrgSettingsUpdating}
							selected={_.find(organizationFonts, f => f.fontFamily === currentGFCandidate.family)}
						/>
					)
				);
			case 'custom-fonts':
				return (
					<UploadedFontsList
						onSave={onSaveUploadedFonts}
						onCancel={onUploadedFontsListCancelClick}
						data={uploadedFonts}
					/>
				);
			case 'google-font-list':
				return (
					<>
						<Search
							value={search}
							onSearchChange={onSearchChange}
							placeholder={`${t('settings.pages.fonts.searchGoogleFontPlaceholder')}...`}
							className={css.search}
							onClear={onSearchClear}
						/>

						<UploadCustomFontBtn
							className={css.uploadCustomFont}
							notAllowed={false}
							onFilesUpload={onCustomFontsUploaded}
						/>

						<div className={css.googleFontList}>
							<VirtualizedList
								itemHeight={89}
								itemsGap={13}
								items={targetGFList}
								renderItem={font => (
									<GoogleFontListItem
										onClick={onGoogleFontItemClick}
										data={font}
										isActive={!!_.find(organizationFonts, f => f.fontFamily === font.family)}
										key={font.family}
									/>
								)}
							/>
						</div>
					</>
				);
			default:
				return null;
		}
	};

	return (
		<Modal
			open={open}
			destroyOnClose
			width={parseInt(css.modalWidth, 10)}
			className={css.modal}
			afterClose={onAfterClose}
		>
			<div>
				<div className={css.head}>
					<Text tag="div" size={Text.size.hero} weight={Text.weight.semibold} compact className={css.title}>
						{t('settings.pages.fonts.addFonts')}
					</Text>
				</div>

				<div className={cn(css.body, css[view])}>{renderContent()}</div>
			</div>
		</Modal>
	);
};

const mapState = (state: AdminReducerState) => {
	return {
		activeOrganization: selectActiveOrganization(state),
		isOrgSettingsUpdating: selectLoadingStateByName(state, [UPDATE_ORGANIZATION_SETTINGS.PENDING, LOGIN.PENDING]),
	};
};

const mapDispatch = {
	updateOrganizationSettings,
	getOrganizationGoogleFonts,
	postOrganizationGallery,
};

const connector = connect(mapState, mapDispatch);

export default connector(AddFontsModal);
