import { LAYER_TYPE } from 'common/constants';

/**
 * @param element {Object} BuildingBlock object
 * @return {{global: boolean, fixed: boolean, float: boolean, content: boolean}}
 */
export function isLayerType(element) {
	const layer = element?.uiConfig?.layer;

	return {
		global: layer === LAYER_TYPE.FIXED_GLOBAL,
		fixed: layer === LAYER_TYPE.FIXED,
		float: layer === LAYER_TYPE.FLOAT,
		content: layer === LAYER_TYPE.CONTENT,
	};
}
