import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import _ from 'lodash';

import type { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';
import type { MemberPreferences } from 'src/types';
import t from 'utils/translate';
import Text from 'admin/components/common/Text';
import { Column } from 'admin/components/common/Grid';
import { Modal, ModalFooter } from 'admin/components/common/Modal';
import { deleteTeam, setActiveTeam, setMemberPreferences } from 'admin/actions';
import Button from 'admin/components/common/Button';
import { AdminReducerState } from 'admin/reducers';
import { SETTINGS_TEAMS_PAGE } from 'admin/constants/routes';
import { Select } from 'admin/components/common/Form';
import {
	selectUserPreferences,
	selectActiveOrganizationId,
	selectUserId,
	selectTeams,
	selectActiveTeamMembers,
} from 'admin/reducers/user/selectors';
import { MODAL } from 'admin/constants/common';

import produce from 'immer';
import css from './RemoveTeamModal.scss';

const VIEW = {
	usersInTeam: 0,
	storiesInTeam: 1,
	availableToDelete: 2,
} as const;

export type RemoveTeamModalData = { teamId: string; teamName?: string };

type Props = ConnectedProps<typeof connector> & RouteComponentProps & ModalManagerProvidedProps<MODAL.DELETE_TEAM>;
type State = {
	isKeepStoriesView: boolean;
	newTeamId: string;
};

const _t = (path: string, params?: { [key: string]: string | number }) =>
	t(`settings.pages.team.removeTeamModal.${path}`, params);

class RemoveTeamModal extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			isKeepStoriesView: false,
			newTeamId: '',
		};
	}

	get view(): (typeof VIEW)[keyof typeof VIEW] {
		const { storiesLength, userId, teamMembers } = this.props;
		const isIAmASingleUser = teamMembers.length === 1 && teamMembers[0].memberId === userId;
		let view: (typeof VIEW)[keyof typeof VIEW] = VIEW.availableToDelete;

		if (teamMembers.length > 0 && !isIAmASingleUser) {
			view = VIEW.usersInTeam;
		} else if (storiesLength > 0) {
			view = VIEW.storiesInTeam;
		}

		return view;
	}

	onAfterClose = () => {
		this.setState({ isKeepStoriesView: false });
	};

	onOkClick = async () => {
		this.props.close();
	};

	deleteTeamAndStories = async (data?: { newTeamId: string }) => {
		const { userId, userPreferences } = this.props;

		await this.props.deleteTeam({ teamId: this.props.data.teamId });

		if (userPreferences.last.team === this.props.data.teamId) {
			const nextPreferences = produce<Partial<MemberPreferences>>(userPreferences, draft => {
				_.set(draft, 'last.team', '');
			});

			this.props.setMemberPreferences({ memberId: userId, preferences: nextPreferences });
			this.props.setActiveTeam('');
		}

		this.onOkClick();

		this.props.history.push(SETTINGS_TEAMS_PAGE);
	};

	moveStoriesAndDeleteTeam = () => {
		return this.deleteTeamAndStories({ newTeamId: this.state.newTeamId });
	};

	onChangeNewTeam = (value: string) => {
		this.setState({ newTeamId: value });
	};

	toggleIsKeepStoriesView = () => {
		this.setState(prevState => ({ isKeepStoriesView: !prevState.isKeepStoriesView }));
	};

	renderHeadTitle = () => {
		const { isKeepStoriesView } = this.state;
		const {
			data: { teamName },
		} = this.props;
		const label = isKeepStoriesView
			? _t(`keepStories.title`, { teamName: `"${teamName}"` })
			: _t(`views[${this.view}].title`, { teamName: `"${teamName}"` });

		return (
			<div className={css.head}>
				<Text size={Text.size.subtitle} weight={Text.weight.semibold} compact text={label} />
			</div>
		);
	};

	renderContent = () => {
		const { teams, data } = this.props;
		const { isKeepStoriesView, newTeamId } = this.state;
		type TeamType = ArrayType<typeof teams>;
		const filteredTeams = _.filter(teams, (team: TeamType) => team.id !== data.teamId);
		const options = _.map(filteredTeams, (team: TeamType) => ({
			value: team.id,
			label: team.name,
		}));
		const {
			data: { teamName },
		} = this.props;

		if (isKeepStoriesView) {
			return (
				<div className={css.body}>
					<Select
						label={{ children: _t('keepStories.selectLabel') }}
						options={options}
						placeholder={_t('keepStories.selectPlaceholder')}
						value={newTeamId}
						eventListeners={{
							onChange: this.onChangeNewTeam,
						}}
					/>
				</div>
			);
		}

		if (this.view === VIEW.storiesInTeam) {
			return (
				<div className={css.body}>
					<Text
						size={Text.size.paragraph}
						text={_t(`views[${this.view}].text`, { teamName: `"${teamName}"` })}
					/>
				</div>
			);
		}

		return null;
	};

	renderFooter = () => {
		const { isKeepStoriesView, newTeamId } = this.state;

		if (isKeepStoriesView) {
			return (
				<ModalFooter>
					<Column justifyContent="flex-start">
						<Button view="primary" onClick={this.toggleIsKeepStoriesView}>
							{_t('keepStories.discard')}
						</Button>
						<Button disabled={!newTeamId} view="secondary-danger" onClick={this.moveStoriesAndDeleteTeam}>
							{_t('keepStories.ok')}
						</Button>
					</Column>
				</ModalFooter>
			);
		}

		switch (this.view) {
			case VIEW.usersInTeam: {
				return (
					<ModalFooter>
						<Column justifyContent="flex-start">
							<Button view="primary" onClick={this.onOkClick}>
								{_t(`views[${this.view}].ok`)}
							</Button>
						</Column>
					</ModalFooter>
				);
			}

			case VIEW.storiesInTeam: {
				return (
					<ModalFooter>
						<Column justifyContent="flex-start">
							<Button view="primary" onClick={this.toggleIsKeepStoriesView}>
								{_t(`views[${this.view}].discard`)}
							</Button>
							<Button view="secondary-danger" onClick={() => this.deleteTeamAndStories()}>
								{_t(`views[${this.view}].ok`)}
							</Button>
						</Column>
					</ModalFooter>
				);
			}

			default: {
				return (
					<ModalFooter>
						<Column justifyContent="flex-start">
							<Button view="primary" onClick={this.props.close}>
								{t('membersComponent.removeFromWorkspaceModal.discard')}
							</Button>

							<Button view="secondary-danger" onClick={() => this.deleteTeamAndStories()}>
								{t('membersComponent.removeFromWorkspaceModal.confirm')}
							</Button>
						</Column>
					</ModalFooter>
				);
			}
		}
	};

	render() {
		const { open } = this.props;

		return (
			<Modal
				width={parseInt(css.modalWidth, 10)}
				afterClose={this.onAfterClose}
				open={open}
				destroyOnClose
				className={css.modal}
			>
				{this.renderHeadTitle()}
				{this.renderContent()}
				{this.renderFooter()}
			</Modal>
		);
	}
}

const mapState = (state: AdminReducerState) => {
	return {
		userPreferences: selectUserPreferences(state),
		storiesLength: state.stories.teamStories.length,
		teamMembers: selectActiveTeamMembers(state),
		teams: selectTeams(state),
		userId: selectUserId(state),
		organizationId: selectActiveOrganizationId(state),
	};
};

const mapDispatchToProps = {
	deleteTeam,
	setActiveTeam,
	setMemberPreferences,
};

const connector = connect(mapState, mapDispatchToProps);

export default withRouter(connector(RemoveTeamModal));
