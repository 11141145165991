import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { StoryTemplateWithoutData } from 'types/story';
import { useAdminSelector, useAdminDispatch } from 'admin/reducers';
import Button from 'admin/components/common/Button';
import { CARD_TYPE } from 'common/constants';
import Loader from 'common/components/Loader';
import Text from 'admin/components/common/Text';
import { Search } from 'admin/components/common/Search';
import useDebounced from 'common/components/useDebounced';
import useInfiniteScroll from 'common/components/useInfiniteScroll';
import { GET_STORY_TEMPLATES } from 'admin/constants/actions';
import { selectLoadingStateByName } from 'admin/reducers/loading/selectors';
import { selectActiveOrganization } from 'admin/reducers/user/selectors';
import { getStoryTemplates, GetStoryTemplatesParams } from 'admin/actions/story/templates';
import Layout from 'admin/components/pages/Stories/CreateStoryModal/ShareComponents/Layout';
import GalleryItem from 'admin/components/pages/Stories/CreateStoryModal/ShareComponents/TemplateItem';
import TemplateGalleryFilters from './TemplateGalleryFilters';
import type { CreateStoryFromTemplateParams, FormValues } from './Form';
import { createStoryFormValuesSelector } from './helpers';
import css from './CreateStoryModal.scss';

type Props = {
	language: FormValues['lang'];
	storyType: FormValues['type'];
	onPreviewClick: (templateId: number) => void;
	onOk: (params?: CreateStoryFromTemplateParams) => void;
	// set "true" to hide component nodes, but keep component state
	hidden: boolean;
};

const pageSize = 15;

const TemplateGallery: React.FC<Props> = ({ language, storyType, onOk, ...props }) => {
	// app state
	const activeOrganization = useAdminSelector(selectActiveOrganization)!;
	const isLoading = useAdminSelector(state => selectLoadingStateByName(state, GET_STORY_TEMPLATES.PENDING));
	const formValues = useAdminSelector(createStoryFormValuesSelector) as FormValues;

	// component state
	const dispatch = useAdminDispatch();
	const [templates, setTemplates] = useState<StoryTemplateWithoutData[]>([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [requestPage, setRequestPage] = useState(0);
	const [cardTypes, setCardTypes] = useState<string[]>(formValues.type === 'widget' ? [CARD_TYPE.INFO] : []);
	const [includeGlobalTemplates, setIncludeGlobalTemplates] = useState(false);
	const [organizationId, setOrganizationId] = useState('');
	const [hasMore, setHasMore] = useState(true);
	const isInitialized = useRef(templates.length > 0);
	const containerRef = useRef<HTMLDivElement>(null);
	const isLoadMorePending = useRef(false);

	const getTemplates = useCallback(
		async (params: GetStoryTemplatesParams) => {
			const response = await dispatch(getStoryTemplates(params));
			if (response.success) {
				const responseTemplates = response.result ?? [];

				setTemplates(prevTemplates => [
					...(params.page === 0 ? [] : prevTemplates),
					...responseTemplates.filter(o => !!o.story?.published),
				]);

				setHasMore(responseTemplates.length === pageSize);
			}

			isLoadMorePending.current = false;
		},
		[dispatch]
	);

	const getTemplatesDebounced = useDebounced(getTemplates, 300);

	const loadMoreTemplates = useCallback(() => {
		if (isLoading || isLoadMorePending.current || !hasMore) return;
		isLoadMorePending.current = true;
		setRequestPage(prevPage => prevPage + 1);
	}, [isLoading, hasMore]);

	useEffect(() => {
		getTemplatesDebounced({
			page: requestPage,
			includeGlobalTemplates,
			language,
			storyType,
			...(cardTypes.length ? { cardTypes: encodeURIComponent(cardTypes.join(',')) } : null),
			...(searchTerm ? { term: searchTerm } : null),
			...(organizationId ? { organizationId } : null),
		});

		if (!isInitialized.current) {
			isInitialized.current = true;
		}
	}, [
		getTemplatesDebounced,
		requestPage,
		cardTypes,
		searchTerm,
		language,
		storyType,
		includeGlobalTemplates,
		organizationId,
	]);

	useInfiniteScroll({
		callback: loadMoreTemplates,
		hasMore,
		isLoading,
		containerRef,
		offset: 60,
	});

	const resetPagination = useCallback(() => {
		setRequestPage(0);
		setTemplates([]);
	}, []);

	const onOrganizationFilterChange = useCallback(
		(value: string | null) => {
			resetPagination();

			switch (value) {
				case activeOrganization.id:
					setOrganizationId(v => (v === value ? '' : value));
					break;
				default:
					setIncludeGlobalTemplates(v => !v);
					break;
			}
		},
		[activeOrganization.id, resetPagination]
	);

	const onCardTypeFilterChange = useCallback(
		(value: string) => {
			resetPagination();
			const checked = cardTypes.includes(value);
			setCardTypes(prevState => (checked ? prevState.filter(v => v !== value) : [...prevState, value]));
		},
		[cardTypes, resetPagination]
	);

	const onSearchChange = (value: string) => {
		resetPagination();
		setSearchTerm(value);
	};

	const organizationFilterOptions = useMemo(
		() =>
			activeOrganization.id === 'storycards'
				? []
				: [
						{
							label: activeOrganization.name,
							value: activeOrganization.id,
							checked: organizationId === activeOrganization.id,
						},
						{ label: 'Storycards', value: null, checked: includeGlobalTemplates },
					],
		[activeOrganization, includeGlobalTemplates, organizationId]
	);

	const onAddClick = useCallback(
		(templateId: number) => {
			return onOk({ template: templateId });
		},
		[onOk]
	);

	if (props.hidden) {
		return null;
	}

	return (
		<>
			<Layout.Panel>
				<Search
					value={searchTerm}
					onSearchChange={e => onSearchChange(e.target.value)}
					placeholder="Search story templates..."
					className={Layout.className.colTwoThirds}
					onClear={() => onSearchChange('')}
				/>
			</Layout.Panel>
			<Layout.Aside>
				<TemplateGalleryFilters
					onCardTypeFilterChange={onCardTypeFilterChange}
					onOrganizationFilterChange={onOrganizationFilterChange}
					organizationOptions={organizationFilterOptions}
					cardTypes={cardTypes}
					storyType={formValues.type}
				/>

				<Button smallText onClick={() => onOk()}>
					Start from scratch
				</Button>
			</Layout.Aside>
			<Layout.Content className={Layout.className.pt35} ref={containerRef}>
				<div className={css.gallery}>
					{templates.map(template => {
						return (
							<GalleryItem
								key={template.id}
								type="story"
								item={template}
								onPreviewClick={props.onPreviewClick}
								onAddClick={onAddClick}
							/>
						);
					})}
				</div>
				{isLoading ? (
					<Loader fullScreen color="currentColor" bgColor="var(--ra-color-white-dark)" size="medium" />
				) : templates.length === 0 && isInitialized.current ? (
					<Text
						size={Text.size.article}
						color={Text.color.blackDark}
						text="No results..."
						style={{ textAlign: 'center' }}
					/>
				) : null}
			</Layout.Content>
		</>
	);
};

export default TemplateGallery;
