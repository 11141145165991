import React, { useRef } from 'react';
import { change } from 'redux-form';
import { size, pickBy } from 'lodash';

import { StoryFacade } from 'utils/facades/story-facade';
import { useAdminDispatch } from 'admin/reducers';
import { Select } from 'admin/components/common/Form/Select';
import IntegrationsFacade from 'utils/facades/integrations-facade';
import { CheckboxPure } from 'admin/components/common/Form/Checkbox';
import Text from 'admin/components/common/Text';
import { Icon } from 'admin/components/common/Icon';
import { Hint } from 'admin/components/common/Form/Hint';
import { FORM_MODEL } from 'admin/constants/common';
import type { SettingsTabsGeneralProps } from '../../types';
import css from './RestrictionSelector.scss';

type Props = Pick<SettingsTabsGeneralProps, 'version' | 'formValues'>;

enum RestrictionType {
	'cookies' = 'cookies',
	'urlParams' = 'urlParams',
}

const restrictionTypeOptions: { value: keyof typeof RestrictionType; label: string }[] = [
	{ value: 'cookies', label: 'Restriction by cookies' },
	{ value: 'urlParams', label: 'Restriction by URL parameters' },
];

const GAME_IDS_DEFAULT = {};

const RestrictionSelector: React.FC<Props> = ({ version, formValues }) => {
	const dispatch = useAdminDispatch();
	const story = React.useMemo(() => new StoryFacade(formValues), [formValues]);
	const { params, gameIds: gameIdsOrigin } = story.settings.integrations?.urlParams ?? {};
	// get `gameIds`, but drop ones that are not included in `params`
	const gameIds = pickBy(gameIdsOrigin, (value, key) => params?.includes(key));
	const [hasUrlParams, hasRestrictedUrlParams] = [Boolean(size(params)), Boolean(size(gameIds))];
	const isRestrictedByUrlParams = hasUrlParams && hasRestrictedUrlParams;
	const [restrictionType, setRestrictionType] = React.useState<keyof typeof RestrictionType>(
		isRestrictedByUrlParams ? RestrictionType.urlParams : RestrictionType.cookies
	);
	const [paramValues, setParamValues] = React.useState<{ [param: string]: boolean }>(gameIds);
	const { current: fieldName } = useRef({ gameIds: IntegrationsFacade.getFields(version).urlParams.gameIds.name });

	const updateField = React.useCallback(
		(field: string, value: any) => {
			dispatch(change(FORM_MODEL.EDIT_STORY, field, value));
		},
		[dispatch]
	);

	const selectorEventListeners = {
		onChange: (value: keyof typeof RestrictionType) => {
			setRestrictionType(value);
			switch (value) {
				case RestrictionType.urlParams:
					if (params?.length) updateField(fieldName.gameIds, GAME_IDS_DEFAULT);
					break;
				case RestrictionType.cookies:
					setParamValues(GAME_IDS_DEFAULT);
					updateField(fieldName.gameIds, undefined);
					break;
				default:
					break;
			}
		},
	};

	const handleParamChange = (checked: boolean, param: string) => {
		const nextParamValues = { ...paramValues };

		if (checked) nextParamValues[param] = checked;
		else delete nextParamValues[param];

		setParamValues(nextParamValues);
		updateField(fieldName.gameIds, nextParamValues);
	};

	return (
		<>
			<Select
				className={css.selector}
				menuPosition="fixed"
				options={restrictionTypeOptions}
				value={restrictionType}
				theme="dark"
				eventListeners={selectorEventListeners}
			/>

			{restrictionType === RestrictionType.urlParams && hasUrlParams ? (
				<div className={css.urlParamsSettings}>
					<Text className={css.label} size="label" weight="medium" text="Select parameters" />
					<div className={css.params}>
						{params?.map(param => (
							<div className={css.paramInputWrap} key={`${param}`}>
								<CheckboxPure
									theme="dark"
									stretch
									label={{ children: param }}
									checked={Boolean(paramValues[param])}
									onChange={e => handleParamChange(e.target.checked, param)}
									disabled={size(paramValues) >= 2 && !paramValues[param]}
								/>
							</div>
						))}
					</div>
				</div>
			) : restrictionType === RestrictionType.urlParams && !hasUrlParams ? (
				<Hint
					className={css.hint}
					text={
						<>
							<Icon type="sc-alert" width={16} />
							Add URL parameters first at the integrations tab, and then you can select parameters to
							restrict
						</>
					}
					theme="dark"
				/>
			) : null}
		</>
	);
};

export default RestrictionSelector;
