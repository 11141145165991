import React from 'react';
import cn from 'classnames';
import { Player } from '@lottiefiles/react-lottie-player';
import Text from 'admin/components/common/Text';
import { CSSTransition } from 'admin/components/common/Transition';
import wandDark from 'common/assets/lottie/magic-wand--dark.json';
import wandLight from 'common/assets/lottie/magic-wand--light.json';
import css from './AIStatus.scss';

type AILoaderProps = {
	status?: string;
	title?: string;
	view: 'story' | 'card';
	className?: string;
};

const config: Record<
	AILoaderProps['view'],
	{
		width: number;
		titleSize: ValuesType<typeof Text.size>;
		titleColor: ValuesType<typeof Text.color>;
		statusSize: ValuesType<typeof Text.size>;
		statusColor: ValuesType<typeof Text.color>;
		src: string;
	}
> = {
	story: {
		width: 150,
		titleSize: Text.size.heading,
		titleColor: Text.color.black,
		statusSize: Text.size.subheading,
		statusColor: Text.color.blackMid,
		src: wandDark,
	},
	card: {
		width: 120,
		titleSize: Text.size.heading,
		titleColor: Text.color.white,
		statusSize: Text.size.subheading,
		statusColor: Text.color.whiteDark,
		src: wandLight,
	},
} as const;

const AIStatus: React.FC<AILoaderProps> = ({ status = 'Magic takes time…', title, view, className }) => {
	const currentConfig = config[view];

	return (
		<div className={cn(css.loader, className, { [css.story]: view === 'story', [css.editor]: view === 'card' })}>
			<div className={css.loaderBody}>
				<Player
					src={currentConfig.src}
					background="transparent"
					controls={false}
					direction={1}
					speed={1.25}
					loop
					autoplay
					keepLastFrame
					style={{ width: currentConfig.width }}
				/>
				{title ? (
					<Text
						text={title}
						size={currentConfig.titleSize}
						weight={Text.weight.semibold}
						color={currentConfig.titleColor}
					/>
				) : null}
				<CSSTransition key={status} in classNames="fadeInOut" timeout={{ enter: 0, exit: 150 }}>
					<Text
						className={css.aiStatus}
						text={status}
						size={currentConfig.statusSize}
						weight={Text.weight.normal}
						color={currentConfig.statusColor}
						style={{ textAlign: 'center', maxWidth: 600 }}
					/>
				</CSSTransition>
			</div>
		</div>
	);
};

export default AIStatus;
