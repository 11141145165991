import React, { ReactElement } from 'react';
import { Field } from 'redux-form';

import t from 'utils/translate';
import { StoryFacade } from 'utils/facades/story-facade';
import Text from 'admin/components/common/Text';
import Tooltip, { ThirdPartyCookiesTooltipContent } from 'admin/components/common/Tooltip';

import { Hint, Toggle } from 'admin/components/common/Form';
import { Column, Grid } from 'admin/components/common/Grid';
import RestrictionSelector from './RestrictionSelector';
import type { SettingsTabsGeneralProps } from '../types';
import StorySettingsCss from '../StorySettingsModal.scss';
import css from './Restrictions.scss';

const tooltipParams = {
	enabled: (content: string | ReactElement) => ({
		isStatic: true,
		placement: 'topLeft' as const,
		stylePreset: 'dark-1' as const,
		content,
	}),
	disabled: {
		open: false,
	},
};

const Restrictions = (props: SettingsTabsGeneralProps) => {
	const canUseThirdPartyCookies = new StoryFacade(props.story).canUseThirdPartyCookies();
	const isOncePerUser = new StoryFacade(props.formValues).settings.restrictions?.oncePerUser;

	return (
		<Grid columns="14" columnGap={Grid.gap.large}>
			<Column colSpan="14">
				<Text
					tag="div"
					size={Text.size.subheading}
					weight={Text.weight.bold}
					className={css.title}
					text={t('story.settings.navigation.restrictions')}
				/>
				<section className={css.section}>
					<Tooltip
						{...(canUseThirdPartyCookies
							? tooltipParams.disabled
							: tooltipParams.enabled(ThirdPartyCookiesTooltipContent))}
					>
						<div className={css.sectionField}>
							<Field
								disabled={!canUseThirdPartyCookies}
								name={props.fields.oncePerUser.name(props.version)}
								component={Toggle}
								theme="dark"
								label={{ children: 'Once per user', component: 'label' }}
								stretch
							/>
							<Hint
								text="Enabling this option restricts users from revisiting viewed cards."
								theme="dark"
							/>
						</div>
					</Tooltip>

					{isOncePerUser ? (
						<div className={css.sectionField}>
							<RestrictionSelector version={props.version} formValues={props.formValues} />
						</div>
					) : (
						<div className={css.sectionField}>
							<Field
								name={props.fields.isAutoRedirect.name(props.version)}
								component={Toggle}
								theme="dark"
								label={{ children: 'Auto-Redirect' }}
								defaultChecked
								stretch
							/>
							<Hint
								theme="dark"
								text={
									<>
										Enabling this option activates automatic redirect to first card in story
										<br /> upon page load
									</>
								}
							/>
						</div>
					)}

					{props.isSuperUser && (
						<div className={StorySettingsCss.superuserSection}>
							<div className={css.sectionField}>
								<Field
									name={props.fields.isDisabledApi.name(props.version)}
									component={Toggle}
									theme="dark"
									label={{ children: 'Static story' }}
									stretch
								/>
								<Hint
									theme="dark"
									text={
										<>
											Enabling this option prevents sending requests to the database
											<br /> and disables statistics.
										</>
									}
								/>
							</div>
							<div className={css.sectionField}>
								<Field
									name={props.fields.isHideCredit.name(props.version)}
									component={Toggle}
									theme="dark"
									label={{ children: 'Hide credit' }}
									stretch
								/>
								<Hint theme="dark" text="Enabling this option will hide storycards credit component" />
							</div>
						</div>
					)}
				</section>
			</Column>
		</Grid>
	);
};

export default Restrictions;
