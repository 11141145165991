import React from 'react';
import { ChangeAction, Field } from 'redux-form';
import { map } from 'lodash';
import type { CardFlowEvent } from 'types/story';
import { CardEvent } from 'common/utils/facades/templates/card-event';
import Text from 'admin/components/common/Text';
import Button from 'admin/components/common/Button';
import { createTemplateName } from 'admin/utils/create-template-name';
import { Context as ThemeContext } from 'admin/components/common/Theme';
import { TextField } from 'admin/components/common/Form';
import { fields } from 'admin/components/pages/Story/Flow/EditCardModal/Form';
import { Icon } from 'src/routes/admin/components/common/Icon';
import css from './EditCardModal.scss';

type Props = {
	events: CardFlowEvent[];
	change: ChangeAction;
};

const CharacterEvents: React.FC<Props> = props => {
	const context = React.useContext(ThemeContext);

	const addEvent = () => {
		props.change('events', [
			...props.events,
			CardEvent({ name: createTemplateName({ name: 'Character', exclude: map(props.events, o => o.name) }) }),
		]);
	};

	return (
		<div className={css.chars}>
			{props.events.map((event, index) => (
				<div className={css.eventWrap} key={`url-param-event-${event._id}`}>
					<Field
						key={`char-event-editor-${event._id}`}
						name={fields.eventName(index)}
						component={TextField}
						isRequired
						props={{
							theme: context?.theme,
							isLabelUppercase: false,
							isRequired: true,
						}}
					/>
					<button
						type="button"
						className={css.removeBtn}
						onClick={() =>
							props.change(
								'events',
								props.events.filter((_, i) => i !== index)
							)
						}
					>
						<Icon className={css.removeBtnIcon} type="minus-rounded" />
					</button>
				</div>
			))}
			<Button className={css.addBtn} view="secondary-gray" onClick={() => addEvent()}>
				<div className={css.addBtnLabel}>
					<Icon className={css.addBtnLabelIcon} type="plus-rounded" />
					<Text size="label" weight="normal" text="Add Character" />
				</div>
			</Button>
		</div>
	);
};

export default CharacterEvents;
